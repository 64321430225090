import { Box, Button, Prompt, Subtitle } from '@tabeo/scarf'
import Layout from 'components/Layout'
import tickSvg from 'components/Signup/assets/tick.svg'
import useAuthGuard from 'hooks/useAuthGuard'
import useFlow from 'hooks/useFlow'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'resources/Auth'
import { useMerchant } from 'resources/Merchant'

function Approved() {
  useAuthGuard()

  const {
    data: merchant,
    resource: { setCustomField },
  } = useMerchant()
  const {
    data: { user },
  } = useAuth()

  const history = useHistory()

  const { loading } = useFlow({ merchant, user })

  const { t } = useTranslation()
  return (
    <Layout maxWidth="460px" bg={['white', 'sky.2']} isLoading={loading}>
      <Box variant="content" textAlign="center">
        <img src={tickSvg} className="mx-auto" />
        <Subtitle mb={3} color="sea.3">
          {t('Welcome, {{firstName}}', { firstName: user?.firstName })}
        </Subtitle>
        <Prompt mb={6}>
          {t(
            'We’re so glad you could join us. We designed our platform to be simple and intuitive, but if you ever need any help, we’re just a phone call away.'
          )}
        </Prompt>
        <Button
          onClick={() => {
            setCustomField('didVisitFinishPage', true)
            history.push('/new-transaction')
          }}
        >
          {t('Start using Tabeo')}
        </Button>
      </Box>
    </Layout>
  )
}

export default Approved
