import { themeGet } from '@styled-system/theme-get'
import styled, { css } from 'styled-components'
import View from './View'
import variants from '../utils/variants'

export type BoxProps = {
  variant?: 'content' | 'normal'
}

const Box = styled(View)<BoxProps>`
  ${variants({
    variant: {
      content: {
        p: [0, 10],
        border: [0, 1],
        bg: ['transparent', 'white'],
        boxShadow: ['none', 1],
        borderRadius: [0, 1],
      },
      normal: {
        p: 5,
        border: 1,
        bg: 'white',
        boxShadow: undefined,
        borderRadius: 1,
      },
    },
  })}
  ${(p) =>
    p.variant === 'content' &&
    css`
      @media (max-width: ${themeGet('breakpoints.0')}) {
        position: relative;
        padding-bottom: 40px;
        &:not(:last-of-type):after {
          width: 86%;
          height: 1px;
          background-color: ${themeGet('colors.sky.1')};
          content: '';
          position: absolute;
          bottom: 0;
          left: 7%;
        }
      }
    `}
`

Box.defaultProps = {
  variant: 'normal',
  width: '100%',
}

export default Box
