/* eslint-disable camelcase */
import { Attributes } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import values from './values'

function ImplantAnswers({
  productAnswers: {
    why_do_you_want_implants: whyDoYouWantImplant,
    how_many_teeth_to_replace: howManyTeethToReplace,
    when_do_you_want_to_start: whenDoYouWantToStart,

    // Legacy
    how_many_implants: howManyImplants,
    how_many_missing_teeth: howManyMissingTeeth,
    had_braces_before: hadBracesBefore,
    pain_or_discomfort: painOrDiscomfort,
  },
}) {
  const { t } = useTranslation()
  return (
    <Attributes
      sections={[
        [
          {
            label: t('Reason for implant'),
            value: whyDoYouWantImplant?.value
              .map(v => {
                if (v === 'other' && whyDoYouWantImplant.freeTextValue) {
                  return `${values.whyDoYouWantImplant[v]}: ${whyDoYouWantImplant.freeTextValue}`
                }

                return values.whyDoYouWantImplant[v]
              })
              .join(', '),
          },
          {
            label: t('Teeth to replace'),
            value: values.howManyTeethToReplace[howManyTeethToReplace],
          },
          {
            label: t('Start'),
            value: values.whenDoYouWantToStart[whenDoYouWantToStart],
          },

          // Legacy
          {
            label: t('Implants'),
            value: values.howManyImplants[howManyImplants],
          },
          {
            label: t('Missing teeth'),
            value: values.howManyMissingTeeth[howManyMissingTeeth],
          },
          {
            label: t('Had braces before'),
            value: values.hadBracesBefore[hadBracesBefore],
          },
          {
            label: t('Pain or discomfort'),
            value: values.painOrDiscomfort[painOrDiscomfort],
          },
        ],
      ]}
    />
  )
}

ImplantAnswers.propTypes = {
  productAnswers: PropTypes.object.isRequired,
}

export default ImplantAnswers
