import React from 'react'
import PropTypes from 'prop-types'

import { View, Prompt } from '@tabeo/scarf'
import styled from 'styled-components'

const Item = styled(View)`
  border: 1px solid ${p => p.theme.colors.sky[1]};
  padding: 6px 8px;
  outline: none;
  cursor: pointer;
  flex: 1 0;
  align-self: stretch;
  text-align: center;
  line-height: 1 !important;
  &:first-of-type {
    border-radius: 4px 0 0 4px;
  }
  &:last-of-type {
    border-radius: 0 4px 4px 0;
  }
  &:not(:last-of-type) {
    border-right: none;
  }
  &[data-selected='true'] {
    border: 1px solid ${p => p.theme.colors.sky[1]};
    background: ${p => p.theme.colors.primary[5]};
  }
  &[data-selected='true'] + & {
    border-left: none;
  }
`

function TabbedRadioItem({ item, selected, ...rest }) {
  return (
    <Item data-selected={selected} bg="sky.2" {...rest}>
      <Prompt color="ink.0">{item.label}</Prompt>
    </Item>
  )
}

TabbedRadioItem.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.bool,
}

export default TabbedRadioItem
