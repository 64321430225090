'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

require('../tslib.es6-1ef680b2.js');
require('i18next');
require('../i18n/index.js');
var config = require('../config.js');

function getSymbol(countryCode) {
    var COUNTRY = config.getConfig().COUNTRY;
    var symbols = {
        gb: '£',
        nl: '€',
        it: '€',
        fr: '€',
        es: '€',
        de: '€',
    };
    return symbols[countryCode || COUNTRY] || '£';
}
function getThousandSeparator(countryCode) {
    var COUNTRY = config.getConfig().COUNTRY;
    var symbols = {
        gb: ',',
        nl: '.',
        it: '.',
        fr: ' ',
        es: '.',
        de: '.',
    };
    return symbols[countryCode || COUNTRY] || ',';
}
function getDecimalSeparator(countryCode) {
    var COUNTRY = config.getConfig().COUNTRY;
    var symbols = {
        gb: '.',
        nl: ',',
        it: ',',
        fr: ',',
        es: ',',
        de: ',',
    };
    return symbols[countryCode || COUNTRY] || '.';
}
var format = function (input, symbolParam, places, thousandParam, decimalParam) {
    if (places === void 0) { places = 2; }
    if (input !== 0 && !input) {
        return '';
    }
    var symbol = symbolParam || getSymbol();
    var thousand = thousandParam || getThousandSeparator();
    var decimal = decimalParam || getDecimalSeparator();
    // eslint-disable-next-line no-cond-assign
    places = !Number.isNaN(Number((places = Math.abs(places)))) ? places : 2;
    var number = input / 100;
    var negative = number < 0 ? '-' : '';
    // @ts-ignore
    var i = "".concat(parseInt((number = Math.abs(+number || 0).toFixed(places)), 10));
    var j;
    // eslint-disable-next-line no-cond-assign
    j = (j = i.length) > 3 ? j % 3 : 0;
    return (symbol +
        negative +
        (j ? i.substr(0, j) + thousand : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1".concat(thousand)) +
        (places
            ? decimal +
                // @ts-ignore
                Math.abs(number - i)
                    .toFixed(places)
                    .slice(2)
            : ''));
};
var accountingFormat = function (value) {
    // eslint-disable-next-line no-nested-ternary
    return (value > 0 ? '+' : value < 0 ? '-' : '') + format(Math.abs(value));
};
var parse = function (price) {
    if (price === undefined || price === '') {
        return '';
    }
    var float = parseFloat(price.replace(/[^0-9-.]/g, ''));
    var int = parseInt((float * 100).toFixed(0), 10);
    return int;
};

exports.accountingFormat = accountingFormat;
exports.format = format;
exports.getDecimalSeparator = getDecimalSeparator;
exports.getSymbol = getSymbol;
exports.getThousandSeparator = getThousandSeparator;
exports.parse = parse;
