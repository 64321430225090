import { Feed, Panel, View } from '@tabeo/scarf'
import wonIcon from 'components/ActionPanel/assets/approved-gray.svg'
import qualifiedIcon from 'components/ActionPanel/assets/approved.svg'
import openIcon from 'components/ActionPanel/assets/review.svg'
import failedIcon from 'components/ActionPanel/assets/x-grey.svg'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const { DateCaption, TitleCaption: Caption } = Feed

const types = {
  OPEN: 'open',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  QUALIFIED: 'qualified',
}
function Title({ item, lead: l }) {
  const { t } = useTranslation()
  switch (item.type) {
    case types.OPEN:
      return (
        <Caption>
          {t('{{leadFirstName}} registered interest through your website', {
            leadFirstName: l.firstName,
          })}
        </Caption>
      )
    case types.QUALIFIED:
      return (
        <Caption>
          {t('{{leadFirstName}} was marked as qualified', {
            leadFirstName: l.firstName,
          })}
        </Caption>
      )
    case types.SUCCEEDED:
      return (
        <Caption>
          {t('{{leadFirstName}}’s case has converted into a treatment', {
            leadFirstName: l.firstName,
          })}
        </Caption>
      )
    case types.FAILED:
      return (
        <Caption>
          {t('{{leadFirstName}}’s case did not convert into a treatment', {
            leadFirstName: l.firstName,
          })}
        </Caption>
      )
    default:
      return null
  }
}

Title.propTypes = {
  item: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
}

function Icon({ item, ...rest }) {
  let icon

  switch (item.type) {
    case types.OPEN:
      icon = openIcon
      break
    case types.QUALIFIED:
      icon = qualifiedIcon
      break
    case types.SUCCEEDED:
      icon = wonIcon
      break
    case types.FAILED:
      icon = failedIcon
      break
    default:
      return null
  }

  return <View as="img" src={icon} {...rest} />
}

Icon.propTypes = {
  item: PropTypes.object.isRequired,
}

function ActivityFeed({ data: l, ...rest }) {
  const sent = {
    id: 'open',
    type: 'open',
    date: l.createdAt,
    data: {},
  }
  const { t } = useTranslation()

  const activityMap = l.stateMachineTransitions.reduce((acc, s) => {
    const normalizedState = s.to

    if (Object.values(types).includes(normalizedState)) {
      return {
        ...acc,
        [normalizedState]: {
          id: s.uuid,
          type: normalizedState,
          date: s.at,
          data: {
            ...s,
          },
        },
      }
    }

    return acc
  }, {})

  const history = [sent, ...Object.values(activityMap)].sort((a, b) =>
    a.date < b.date ? 1 : -1
  )

  return (
    <Panel title={t('Activity')} {...rest}>
      <Feed
        history={history}
        renderIcon={h => <Icon item={h} width="24px" height="24px" />}
        renderTitle={h => <Title item={h} lead={l} />}
        renderDate={h => (
          <DateCaption>
            {moment(h.date).format('DD MMM YYYY, hh:mm a')}
          </DateCaption>
        )}
      />
    </Panel>
  )
}

ActivityFeed.propTypes = {
  data: PropTypes.object,
}

export default ActivityFeed
