import { TabbedPanel, View } from '@tabeo/scarf'
import PricingChanges from 'components/PricingChanges'
import TermsUpdate from 'components/TermsUpdate'
import StripeTerminalProvider from 'components/terminal/StripeTerminalProvider'
import useCountry from 'hooks/useCountry'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import Bills from 'resources/Bills'
import { useLead } from 'resources/Lead'
import LinkPayments from 'resources/LinkPayments'
import Offers from 'resources/Offers'
import { usePlans } from 'resources/Plans'
import Subscriptions from 'resources/Subscriptions'
import TerminalPayments from 'resources/TerminalPayments'
import { useMerchant } from '../../resources/Merchant'
import BillForm from './BillForm'
import BillOverview from './BillOverview'
import Layout from './Layout'
import LeadNotification from './LeadNotification'
import LinkPaymentForm from './LinkPaymentForm'
import LinkPaymentOverview from './LinkPaymentOverview'
import PayNowForm from './PayNowForm'
import PayNowOverview from './PayNowOverview'
import PayOverTimeForm from './PayOverTimeForm'
import PayOverTimeOverview from './PayOverTimeOverview'
import PlanForm from './PlanForm'
import PlanOverview from './PlanOverview'
import SuccessModal from './SuccessModal'
import TerminalPaymentForm from './TerminalPaymentForm'
import TerminalPaymentOverview from './TerminalPaymentOverview'

function NewTransaction(props) {
  const { data: merchant } = useMerchant()
  const hasRiskProfiles = !!merchant.merchantRiskProfiles?.length

  const { t } = useTranslation()
  const country = useCountry()
  const { sector, tradingName } = merchant

  const {
    match: {
      params: { leadId },
    },
  } = props

  const modal = React.useRef()

  const [state, setState] = React.useState({
    activeTab: null,
    formValues: {},
  })

  const titleMap = {
    [t('Finance')]: 'pay_over_time',
    [t('Card')]: 'pay_now',
    [t('Plan')]: 'plan',
    [t('Link')]: 'link_payment',
    [t('Bill')]: 'bill',
    [t('Terminal')]: 'terminal_payment',
  }
  const handleTabChange = (index, title) => {
    setState(state => ({
      ...state,
      activeTab: titleMap[title],
    }))
  }

  const handleFormChange = ({ values, valid }) => {
    setState(state => ({
      ...state,
      valid,
      formValues: values,
    }))
  }

  const handleOfferCreation = async payload => {
    const response = await Offers.create({
      ...payload,
      leadId,
    })
    setState(state => ({
      ...state,
      formValues: {},
    }))
    return response
  }

  const handleBillCreation = async payload => {
    const response = await Bills.create({ ...payload })
    setState(state => ({
      ...state,
      formValues: {},
    }))
    return response
  }

  const handleTerminalPaymentCreation = async payload => {
    const response = await TerminalPayments.create({ ...payload })
    setState(state => ({
      ...state,
      formValues: {},
    }))
    return response
  }

  const handleLinkPaymentCreation = async payload => {
    const response = await LinkPayments.create({ ...payload })
    setState(state => ({
      ...state,
      formValues: {},
    }))
    return response
  }

  const {
    activeTab,
    formValues: {
      price,
      title,
      otherTitle,
      consumerFirstName,
      paymentOption = {},
      petName,
      planId,
      scheduledAt,
      amount,
      possiblePaymentOptions,
      merchantClinician,
    },
  } = state

  const { data: plans } = usePlans(() => {
    if (state.activeTab !== 'plan') {
      throw new Error('Not relevant')
    }
  })

  // Plan preview props
  const plan = plans?.items?.find?.(i => i.id === planId) ?? {}

  // pre-popultate forms with lead details
  const { data: lead } = useLead()
  const isLeadView = leadId && lead && activeTab !== 'plan'

  const initialConsumerValues = isLeadView
    ? {
        consumerFirstName: lead.firstName,
        consumerLastName: lead.lastName,
        consumerGender: lead.gender,
        consumerEmail: lead.email,
        consumerPhoneNumber: lead.phoneNumber,
      }
    : {
        consumerFirstName: state.formValues?.consumerFirstName,
        consumerLastName: state.formValues?.consumerLastName,
        consumerGender: state.formValues?.consumerGender,
        consumerEmail: state.formValues?.consumerEmail,
        consumerPhoneNumber: state.formValues?.consumerPhoneNumber,
      }

  const initialValues = {
    ...initialConsumerValues,

    price: state.formValues?.price,
    title: state.formValues?.title,
    otherTitle: state.formValues?.otherTitle,
    clinicianName: state.formValues?.clinicianName,

    merchantClinician: state.formValues?.merchantClinician,
    merchantTreatment: state.formValues?.merchantTreatment,
    treatmentCategory: state.formValues?.treatmentCategory,
  }

  const tabs = [
    merchant.flags.is_terminal_payment_capable && (
      <TerminalPaymentForm
        title={t('Terminal')}
        initialValues={initialValues}
        onFormChange={handleFormChange}
        onSubmit={handleTerminalPaymentCreation}
      />
    ),
    merchant.flags.is_link_payment_capable && (
      <LinkPaymentForm
        title={t('Link')}
        initialValues={initialValues}
        onFormChange={handleFormChange}
        onSubmit={handleLinkPaymentCreation}
      />
    ),
    hasRiskProfiles && (
      <PayOverTimeForm
        title={t('Finance')}
        initialValues={initialValues}
        onFormChange={handleFormChange}
        onSubmit={handleOfferCreation}
        successModal={modal}
      />
    ),
    merchant.flags.is_pay_now_capable && (
      <PayNowForm
        title={t('Card')}
        initialValues={initialValues}
        onFormChange={handleFormChange}
        onSubmit={handleOfferCreation}
        sector={sector}
        successModal={modal}
      />
    ),
    merchant.flags.is_bills_capable && country.isUK && (
      <BillForm
        title={t('Bill')}
        initialValues={initialValues}
        onFormChange={handleFormChange}
        onSubmit={handleBillCreation}
        sector={sector}
        successModal={modal}
      />
    ),
    merchant.flags.is_subscription_plan_capable &&
      !merchant.flags.is_plan_v2_capable && (
        <PlanForm
          title={t('Plan')}
          initialValues={initialValues}
          onFormChange={handleFormChange}
          onSubmit={Subscriptions.create}
          plans={plans?.items ?? []}
          sector={sector}
          successModal={modal}
        />
      ),
  ].filter(Boolean)

  const initialActiveTabTitle = titleMap[tabs[0].props.title]
  const tab = activeTab || initialActiveTabTitle

  return (
    <StripeTerminalProvider>
      <Layout
        loading={leadId && !lead}
        notification={
          <>
            <PricingChanges />
            <TermsUpdate />
            {isLeadView ? <LeadNotification lead={lead} mb={5} /> : null}
          </>
        }
      >
        <View>
          <SuccessModal innerRef={modal} />
          <TabbedPanel
            onChange={handleTabChange}
            alignSelf={['stretch', 'flex-start']}
            style={{ overflow: 'visible' }}
            data-testid="new-transaction-panel"
          >
            {tabs}
          </TabbedPanel>
        </View>
        <View>
          {tab === 'pay_over_time' && (
            <PayOverTimeOverview
              title={otherTitle || title}
              price={price || 0}
              sector={sector}
              petName={petName}
              numberOfPayments={paymentOption.numberOfMonths}
              interestRate={paymentOption.interestRate}
              rebateDays={paymentOption.rebateDays}
              paymentOption={paymentOption}
              possiblePaymentOptions={possiblePaymentOptions}
              merchantClinician={merchantClinician}
            />
          )}
          {tab === 'pay_now' && (
            <PayNowOverview
              title={otherTitle || title}
              price={price || 0}
              merchantName={tradingName}
              consumerFirstName={consumerFirstName}
            />
          )}
          {tab === 'bill' && (
            <BillOverview
              title={otherTitle || title}
              amount={amount || 0}
              merchantName={tradingName}
              consumerFirstName={consumerFirstName}
              scheduledAt={scheduledAt}
            />
          )}
          {tab === 'plan' && (
            <PlanOverview
              {...plan}
              merchantName={tradingName}
              sector={sector}
              petName={petName}
            />
          )}
          {tab === 'link_payment' && <LinkPaymentOverview price={price} />}
          {tab === 'terminal_payment' && (
            <TerminalPaymentOverview price={price} />
          )}
        </View>
      </Layout>
    </StripeTerminalProvider>
  )
}

NewTransaction.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(NewTransaction)
