import {
  Button,
  Caption,
  Flex,
  Icons,
  Subheading,
  Text,
  Title,
  View,
} from '@tabeo/scarf'
import usePromise from 'hooks/usePromise'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import activateImg from './assets/activate.png'

function Activate({ requestLeadsAccess, flags, theme, ...rest }) {
  const { t } = useTranslation()
  const isLeadManagementRequested = flags.is_lead_management_requested === true
  const [request, { pending, success, error }] = usePromise(requestLeadsAccess)

  const features = [
    t('Fully branded landing pages to market Invisalign® and capture leads'),
    t('Dedicated page to pre-qualify patients and let them upload photos'),
    t('A dashboard to process leads and convert them into consultations'),
    t(
      'Ability to create card and finance transactions and bookings for your leads'
    ),
  ]

  return (
    <Flex
      maxWidth="630px"
      mx="auto"
      alignItems="flex-start"
      bg="white"
      border={1}
      borderRadius={1}
      boxShadow={1}
      overflow="hidden"
      {...rest}
    >
      <View
        as="img"
        src={activateImg}
        maxWidth="200px"
        display={['none', 'block']}
      />
      <Flex
        flex="1"
        p={[6, '40px']}
        boxShadow="0 0 0 0 white, 0 0 0 0 white, 0 0 0 0 white, -6px 0px 6px 1px rgba(0, 0, 0, 0.06)"
        alignSelf="stretch"
        justifyContent="center"
        flexDirection="column"
      >
        <Flex justifyContent="flex-start" mb={6}>
          <Title mr={2}>{t('Lead Management')}</Title>
          <Subheading color="default.primary">{t('beta')}</Subheading>
        </Flex>
        <View as="ul">
          {features.map(f => (
            <Flex
              key={f}
              mb={4}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Icons.Tick light mr={4} />
              <Text>{f}</Text>
            </Flex>
          ))}
        </View>
        {isLeadManagementRequested || success ? (
          <Caption
            color="default.primary"
            p={2}
            textAlign="center"
            border={`1px solid ${theme.colors.default.primary}`}
            borderRadius={1}
            mt={2}
            mb={5}
            width="100%"
          >
            {t('We’ll get back to you soon')}
          </Caption>
        ) : (
          <Button
            width="100%"
            mt={2}
            mb={5}
            onClick={() => request()}
            loading={pending}
            disabled={!!error}
          >
            {error ? t('There was an error') : t('Request early access')}
          </Button>
        )}
        <Caption>
          {t('This addon service will be free during the beta phase')}
        </Caption>
      </Flex>
    </Flex>
  )
}

Activate.propTypes = {
  requestLeadsAccess: PropTypes.func.isRequired,
  flags: PropTypes.object,
  theme: PropTypes.object,
}

export default withTheme(Activate)
