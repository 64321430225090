import { TabScreens, Title } from '@tabeo/scarf'
import DatoNotifications from 'components/Notifications/DatoNotifications'
import PricingChanges from 'components/PricingChanges'
import TermsUpdate from 'components/TermsUpdate'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import { useMerchantUsers } from 'resources/MerchantUsers'
import Brand from './Brand'
import General from './General'
import Payments from './Payments'
import PayoutsAndBilling from './PayoutsAndBilling'
import People from './People'
import Treatments from './Treatments'

function Settings() {
  const { t } = useTranslation()
  const { data, resource } = useMerchant()
  const { data: merchantUsers, resource: merchantUsersResource } =
    useMerchantUsers()

  const isCliniciansTreatmentsEnabled =
    data?.flags.is_clinicians_treatments_enabled

  const isTerminalPaymentCapable = data?.flags.is_terminal_payment_capable

  return (
    <LayoutSideNavbar isLoading={!(data && merchantUsers)}>
      <Container bg="white" maxWidth="812px">
        <DatoNotifications />
        <PricingChanges />
        <TermsUpdate />
        <Title mb={1}>{t('Settings')}</Title>
        <TabScreens
          tabs={[
            { key: 'general', label: 'General', component: General },
            {
              key: 'people',
              label: 'People',
              component: People,
              labelProps: { 'ug-id': 'people-tab-label' },
            },
            isTerminalPaymentCapable && {
              key: 'payments',
              label: 'Payments',
              component: Payments,
            },
            data.isWhitelabelEnabled && {
              key: 'brand',
              label: 'Brand',
              component: Brand,
            },
            {
              key: 'payouts-and-billing',
              label: 'Payouts & billing',
              component: PayoutsAndBilling,
            },
            isCliniciansTreatmentsEnabled && {
              key: 'treatments',
              label: 'Treatments',
              component: Treatments,
              labelProps: { 'ug-id': 'treatments-tab-label' },
            },
          ].filter(Boolean)}
          context={{ resource, merchantUsersResource }}
        />
      </Container>
    </LayoutSideNavbar>
  )
}

export default Settings
