import { Box, Text } from '@tabeo/scarf'
import api from '@tabeo/sharpei/utils/api'
import BaseLayout from 'components/Layout'
import TermsDocument from 'components/TermsAndConditionDocument'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

class Terms extends Component {
  state = {
    content: null,
    version: 1,
    error: null,
  }

  async componentDidMount() {
    try {
      const response = await api.get(
        '/merchant/terms-and-conditions?subResource=content'
      )
      const {
        document: { content, version, countryCode },
      } = response
      this.setState({
        content,
        version,
        countryCode,
      })
    } catch (error) {
      this.setState({ error })
    }
  }

  render() {
    const { content, version, error, countryCode } = this.state
    const { t } = this.props
    return (
      <BaseLayout bg={['white', 'sky.2']}>
        <Box variant="content">
          {!error && content && (
            <TermsDocument
              version={version}
              content={content}
              countryCode={countryCode}
            />
          )}
          {!error && !content && <Text>{t('Loading...')}</Text>}
          {error && (
            <Text color="default.red">
              {t('There was an error while fetching this document.')}
            </Text>
          )}
        </Box>
      </BaseLayout>
    )
  }
}

Terms.propTypes = {
  t: PropTypes.func,
}

export default withTranslation()(Terms)
