import React from 'react'
import Svg, { SvgProps, Circle, G } from './Base'

function RoundPlus({
  fill,
  bg = 'sky.3',
  border = 'sky.0',
  ...props
}: SvgProps) {
  return (
    <Svg viewBox="0 0 27 27" {...props}>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <Circle
          stroke={border as any}
          fill={bg as any}
          cx={12.5}
          cy={12.5}
          r={13}
        />
        <G
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        >
          <path d="M12.844 7.324v10.352M17.656 12.5H7.344" />
        </G>
      </g>
    </Svg>
  )
}

export default RoundPlus
