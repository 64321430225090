'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var delay = function (ms) {
    if (ms === void 0) { ms = 1000; }
    return new Promise(function (resolve) {
        setTimeout(function () { return resolve(); }, ms);
    });
};

exports.default = delay;
