import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import moment from 'moment'
import normalize from './normalizers/bill'

const orderByGroup = {
  a: 'order=updatedAt:desc',
  b: 'order=scheduledAt:asc',
  c: 'order=updatedAt:desc',
}

class Bills extends Resource {
  getInitialData() {
    return {
      items: null,
      totalCount: 0,
    }
  }

  getInitialConfig() {
    return {
      refreshInterval: 15 * 1000,
    }
  }

  async fetch() {
    const { activeTabs = [], searchQuery, limit = 10, offset = 0 } = this.params
    const statuses = activeTabs.reduce((mem, tab) => {
      mem = [...mem, ...tab.statuses]
      return mem
    }, [])

    const activeGroup = activeTabs?.[0]?.group || 'a'
    const order = orderByGroup[activeGroup]
    const condition = `&condition=status:[${statuses.join(',')}]`
    const pagination = `&limit=${limit}&offset=${offset}`

    const endpoint = searchQuery
      ? `/merchant/bill-search?${order}&searchPhrase=${searchQuery}`
      : `/merchant/bills?${order}`

    const {
      data: { bills },
      totalCount,
    } = await api.get(`${endpoint}${condition}${pagination}`, {
      responseDataPath: 'data',
    })

    const normalizedBills = bills.map(o => normalize(o))

    this.data = {
      totalCount,
      items: normalizedBills,
    }

    return this.data
  }

  static create = async ({ scheduledAt, ...payload }) => {
    payload.scheduledAt = moment(scheduledAt).set({
      hour: '6',
      minute: '0',
      second: '0',
    })
    const {
      projections: {
        bill: { id },
      },
    } = await api.post('/merchant/bills', payload)

    const { bill } = await api.get(`/merchant/bills/${id}`)

    return bill
  }
}

export default Bills
export const useBills = createResourceHook(Bills)
