import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import ScarfProps from '../../types/ScarfProps'
import Flex from '../Flex'
import Text, { Heading } from '../Text'
import View from '../View'
import { ReactComponent as NotFoundSvg } from './assets/not-found.svg'
import placeholderImg from './assets/table-placeholder.svg'
import { Dispatch, State } from './context'

const Placeholder = styled(View)<ScarfProps<'img'>>``
Placeholder.defaultProps = {
  as: 'img',
  src: placeholderImg,
  width: '100%',
  borderTop: 1,
}

const Container = styled(View)`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

export type RenderProps = {
  items: any[]
  state: State
  searchQuery?: string
  resource: any
  dispatch: Dispatch
}

export type ListProps = {
  items: any[] | null
  state: State
  renderTable: (props: RenderProps) => JSX.Element
  renderEmpty?: (props: RenderProps) => JSX.Element
  emptyWrapperProps?: ScarfProps
  resource: any
  dispatch: Dispatch
}

const List = ({
  items,
  state,
  renderTable,
  renderEmpty,
  dispatch,
  resource,
  emptyWrapperProps,
}: ListProps) => {
  const { searchQuery } = state
  const renderProps = {
    items: items || [],
    // searchQuery is deprecated since we pass `state`
    searchQuery,
    resource,
    state,
    dispatch,
  }

  return items === null || items.length > 0 ? (
    <>
      <Container position="relative">{renderTable(renderProps)}</Container>
      {items === null && <Placeholder maxHeight="440px" />}
    </>
  ) : renderEmpty ? (
    renderEmpty(renderProps)
  ) : (
    <Flex
      minHeight="440px"
      height="1px" // IE 11 magic
      flexDirection="column"
      justifyContent="center"
      {...emptyWrapperProps}
    >
      <View textAlign="center">
        <View as={NotFoundSvg} color="primary.3" mb={1} />
        <Heading mb={1}>No results found</Heading>
        {!!searchQuery && (
          <Text fontSize="14px" color="ink.2">
            Try broadening your search
          </Text>
        )}
      </View>
    </Flex>
  )
}

List.propTypes = {
  items: PropTypes.array,
  state: PropTypes.object.isRequired,
  renderTable: PropTypes.func,
  renderEmpty: PropTypes.func,
  dispatch: PropTypes.func,
  resource: PropTypes.any,
  emptyWrapperProps: PropTypes.any,
}

export default List
