import { StateFunnel } from '@tabeo/scarf'
import usePromise from 'hooks/usePromise'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useBill } from 'resources/Bill'
import Cancel from '../modals/Cancel'
import Charge from '../modals/Charge'
import Update from '../modals/Update'
import Canceled from './Canceled'
import CardDeclined from './CardDeclined'
import Expired from './Expired'
import Opened from './Opened'
import PaymentOnTheWay from './PaymentOnTheWay'
import Scheduled from './Scheduled'
import Sent from './Sent'
import Settled from './Settled'

function getCurrentIndex(state) {
  if (state === 'sent') {
    return 0
  }
  if (state === 'opened') {
    return 1
  }
  if (state === 'scheduled') {
    return 2
  }

  return undefined
}

function ActionPanel({ data, ...rest }) {
  const { scheduledAt, consumerFirstName, amount, state } = data
  const { t } = useTranslation()
  const { resource } = useBill()

  const { cancel, update, charge } = resource

  const [triggerCancel, cancelState] = usePromise(cancel, [state])
  const [triggerUpdate, updateState] = usePromise(update, [state])
  const [triggerCharge, chargeState] = usePromise(charge, [state])

  const chargeModal = React.useRef()
  const cancelModal = React.useRef()
  const updateModal = React.useRef()

  // TODO: check
  const states = [t('sent'), t('opened'), t('scheduled'), t('paid')]

  useEffect(() => {
    function closeModalOnError(modal, error) {
      if (error) {
        if (modal.current.state.isOpen) {
          modal.current.close()
        }
      }
    }

    closeModalOnError(cancelModal, cancelState.error)
    closeModalOnError(chargeModal, chargeState.error)
    closeModalOnError(updateModal, updateState.error)
  }, [cancelState.error, chargeState.error, updateState.error])

  const renderCard = () => {
    const { state } = data

    const props = {
      data,
      ...rest,
    }

    const chargeAndCancel = {
      chargeModal,
      cancelModal,
      chargeState,
      cancelState,
    }

    const updateAndCancel = {
      data,
      cancelModal,
      updateModal,
      cancelState,
      updateState,
      ...rest,
    }

    const updateAndChargeAndCancel = {
      chargeModal,
      cancelModal,
      updateModal,
      chargeState,
      cancelState,
      updateState,
    }

    switch (state) {
      case 'sent':
        return <Sent {...props} {...updateAndCancel} />
      case 'opened':
        return <Opened {...props} {...updateAndCancel} />
      case 'scheduled':
        return <Scheduled {...props} {...updateAndChargeAndCancel} />
      case 'funds_on_the_way':
        return <PaymentOnTheWay {...props} />
      case 'settled':
        return <Settled {...props} />
      case 'card_declined':
        return <CardDeclined {...props} {...chargeAndCancel} />
      case 'canceled':
        return <Canceled {...props} />
      case 'expired':
        return <Expired {...props} />
      default:
        return null
    }
  }

  const currentIndex = getCurrentIndex(data.state)

  return (
    <>
      {currentIndex !== undefined && (
        <StateFunnel
          states={states}
          currentIndex={currentIndex}
          pastStepProps={{ bg: 'yellow.4', color: 'yellow.2' }}
          activeStepProps={{ bg: 'yellow.3', color: 'yellow.1' }}
          futureStepProps={{ bg: 'white', color: 'ink.2' }}
          mb={6}
        />
      )}
      <Charge
        innerRef={chargeModal}
        onConfirm={triggerCharge}
        state={chargeState}
        amount={amount}
        consumerFirstName={consumerFirstName}
      />
      <Cancel
        innerRef={cancelModal}
        onConfirm={triggerCancel}
        state={cancelState}
      />
      <Update
        innerRef={updateModal}
        onSubmit={triggerUpdate}
        state={updateState}
        consumerFirstName={consumerFirstName}
        scheduledAt={scheduledAt}
      />
      {renderCard()}
    </>
  )
}

ActionPanel.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ActionPanel
