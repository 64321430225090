import { Button, Icons, Modal, Text, Title, View } from '@tabeo/scarf'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'

function TreatmentStartDateInfo() {
  const { t } = useTranslation()
  const ref = useRef()

  const {
    data: { sector },
  } = useMerchant()

  const definitionsBySector = {
    dental_services: [
      {
        title: t('Clear aligners'),
        description: t('The date the aligners are handed over to the patient.'),
      },
      {
        title: t('Implants'),
        description: t('The date the implant is placed in the patient’s jaw.'),
      },
      {
        title: t('Bridges, Crowns, Dentures, Fixed Braces, Veneers'),
        description: t('The date the prosthesis is fitted.'),
      },
      {
        title: t('Root canal'),
        description: t(
          'The date the infected tissue is removed or canal(s) cleared.'
        ),
      },
    ],
    optical_care: [
      {
        title: t('Eye laser surgery, Cataract surgery'),
        description: t('The date the procedure is completed'),
      },
      {
        title: t('Hearing aids'),
        description: t('The date the Hearing aid is delivered to the patient.'),
      },
    ],
    skin_laser_facial_treatments: [
      {
        title: t('Botox or Lip Filler'),
        description: t('The date the botox or filler is injected.'),
      },
      {
        title: t('Hair removal'),
        description: t('The first session of the treatment course.'),
      },
    ],
  }

  const definitions = definitionsBySector[sector]

  if (!definitions) {
    return null
  }

  return (
    <>
      <Icons.Questionmark cursor="pointer" onClick={() => ref.current.open()} />
      <Modal ref={ref}>
        {({ isOpen, close }) => {
          return isOpen ? (
            <View
              maxWidth={['90%', '460px']}
              mx="auto"
              bg="white"
              borderRadius={1}
              boxShadow={1}
              p={[5, 10]}
            >
              <Title mb={3}>{t('Treatment start date')}</Title>
              <Text>
                {t(
                  'This is the date in which you expect the patient will start treatment, the definition varies by treatment:'
                )}
              </Text>

              <View p={4} borderRadius={4} my={4} bg="sky.2">
                {definitions.map(({ title, description }) => {
                  return (
                    <View key={title} _notLast={{ mb: 4 }}>
                      <Text fontSize={1} fontWeight="semibold" mb={1}>
                        {title}
                      </Text>
                      <Text fontSize={1}>{description}</Text>
                    </View>
                  )
                })}
              </View>

              <Text>
                {t(
                  'If you haven’t fixed a date with the patient yet you’ll be able to update this later.'
                )}
              </Text>

              <Button type="button" onClick={close} width="100%" mt={6}>
                {t('Close')}
              </Button>
            </View>
          ) : null
        }}
      </Modal>
    </>
  )
}

export default TreatmentStartDateInfo
