import { forwardRef, Ref, SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const SvgComponent = (
  { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M12.463 1.98a.4.4 0 00-.76 0l-.75 2.267a4 4 0 01-2.54 2.538l-2.267.752a.4.4 0 000 .76l2.267.75a4 4 0 012.54 2.54l.75 2.267a.4.4 0 00.76 0l.752-2.268a4 4 0 012.538-2.538l2.268-.752a.4.4 0 000-.759l-2.268-.752a4 4 0 01-2.539-2.538l-.751-2.268zm-6.44 8.593a.2.2 0 00-.38 0l-.215.65A4 4 0 012.89 13.76l-.65.216a.2.2 0 000 .38l.65.215a4 4 0 012.54 2.539l.214.65a.2.2 0 00.38 0l.216-.65a4 4 0 012.538-2.54l.65-.214a.2.2 0 000-.38l-.65-.216a4 4 0 01-2.538-2.538l-.216-.65z"
      clipRule="evenodd"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgComponent)
export default ForwardRef
