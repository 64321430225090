import React from 'react'
import styled from 'styled-components'
import Svg, { Path, SvgProps } from './Base'

const Spinner = ({
  fill = 'default.primary',
  strokeWidth = 9,
  ...rest
}: SvgProps) => (
  <Svg width="22px" height="22px" {...rest} viewBox="0 0 120 120">
    <g>
      <Path
        fill="none"
        fillRule="evenodd"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="1"
        strokeWidth={strokeWidth}
        d="M60 10c-27.614 0-50 22.386-50 50s22.386 50 50 50 50-22.386 50-50"
      />
    </g>
  </Svg>
)

export type AnimatedSpinnerProps = Omit<SvgProps, 'as'> & { spinning?: boolean }

const AnimatedSpinner = styled(Spinner)<AnimatedSpinnerProps>`
  animation-name: ${(p) => (p.spinning ? 'spin' : 'none')};
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.27, 0.54, 0.6, 0.41);

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export default AnimatedSpinner
