import React, { useState } from 'react'
import styled from 'styled-components'
import { InputProp } from '../../types/form'
import ScarfProps from '../../types/ScarfProps'
import View from '../View'

const Container = styled(View)`
  position: relative;
  width: 44px;
  height: 24px;
  border-radius: 12px;
  transition: background-color 120ms;
`

const Handle = styled(View)`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  box-shadow: 0px 0 5px 0px rgba(0, 0, 0, 0.15);
  background: white;
  position: absolute;
  transition: left 200ms;
  top: 50%;
  transform: translateY(-50%);
`

export type SwitchProps = ScarfProps & {
  input: Partial<InputProp>
  disabled?: boolean
  loading?: boolean
  defaultValue?: boolean
}

function Switch({
  input: { value: controlledValue, onChange } = {},
  defaultValue = false,
  disabled,
  loading,
  ...rest
}: SwitchProps) {
  const isControlled = controlledValue !== undefined
  const [selfValue, setSelfValue] = useState(defaultValue)
  const value = isControlled ? controlledValue : selfValue
  async function handleChange() {
    if (isControlled) {
      onChange?.(!value)
    } else {
      setSelfValue(!value)
      if (onChange) {
        try {
          await onChange(!value)
        } catch (e) {
          setSelfValue(value)
          throw e
        }
      }
    }
  }
  return (
    <Container
      onClick={() => !disabled && !loading && handleChange()}
      cursor={disabled ? 'not-allowed' : loading ? 'wait' : 'pointer'}
      bg={
        !value
          ? disabled
            ? 'sky.2'
            : 'sky.1'
          : disabled
          ? 'primary.4'
          : 'primary.3'
      }
      flexShrink="0"
      {...rest}
    >
      <Handle left={!value ? '2px' : 'calc(100% - 2px - 20px)'} />
    </Container>
  )
}

export default Switch
