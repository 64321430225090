import { PlusCircleIcon } from '@heroicons/react/20/solid'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { FFTextInput, Tag, RadioCards } from '@tabeo/scarf2'
import { format } from '@tabeo/sharpei/utils/currency'
import { required } from '@tabeo/sharpei/utils/validations'
import { Field } from 'components/nnts/form/Field'
import { ExtendedLoanOption, LoanOptionWithRiskProfile } from 'pages/NNTS/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import { twMerge } from 'tailwind-merge'

export interface FinanceRadioCardOptionProps {
  loanOption: ExtendedLoanOption
  onViewDetails: () => void
  monthlyPaymentAmount?: number
  popular?: boolean
  disabled?: boolean
}

export function getValueForLoanOption(loanOption: LoanOptionWithRiskProfile) {
  const { numberOfMonths, interestRate, profile } = loanOption

  return `finance-${numberOfMonths}-${interestRate}-${profile.name}`
}

export function FinanceRadioCardOption({
  popular,
  loanOption,
  onViewDetails,
  monthlyPaymentAmount,
  disabled,
}: FinanceRadioCardOptionProps) {
  const { t } = useTranslation()
  const { numberOfMonths, interestRate, customerTermOptions } = loanOption
  const value = getValueForLoanOption(loanOption)
  const [isReferenceOpen, setIsReferenceOpen] = useState(false)
  const { data } = useMerchant()

  if (!data) return null

  const {
    flags: {
      is_offer_internal_reference_capable: isOfferInternalReferenceCapable,
      is_offer_internal_reference_required: isOfferInternalReferenceRequired,
    } = {},
    isSDC,
  } = data

  return (
    <div className="mt-4">
      <RadioCards.Option key={value} value={value} disabled={disabled}>
        {({ checked }) => (
          <>
            {popular && (
              <Tag
                className="absolute -top-[10px] left-4 group-aria-disabled:bg-tabeo-sky-0 md:left-5"
                color="ink"
                size="sm"
              >
                {t('Popular')}
              </Tag>
            )}
            <div className="flex items-start gap-x-2">
              <RadioCards.OptionDot />

              <div className="flex flex-1 items-center justify-between">
                <div className="space-y-2">
                  <div className="font-medium">{numberOfMonths} months</div>
                  <AprChip value={interestRate} />
                </div>
                <div className="flex flex-col items-end gap-2">
                  {monthlyPaymentAmount ? (
                    <div className="flex items-baseline gap-1">
                      {format(monthlyPaymentAmount)}
                      <span className="caption text-tabeo-ink-1 group-aria-disabled:text-tabeo-sky-0">
                        {t('per month')}
                      </span>
                    </div>
                  ) : (
                    <div
                      className={`h-6 w-24 animate-pulse rounded-full ${
                        checked
                          ? 'bg-tabeo-primary-5'
                          : 'bg-tabeo-sky-1 group-aria-disabled:bg-tabeo-sky-2'
                      }`}
                    />
                  )}
                  <button
                    type="button"
                    className="anchor caption group-aria-disabled:cursor-not-allowed group-aria-disabled:text-tabeo-primary-5"
                    onClick={
                      !disabled
                        ? e => {
                            e.stopPropagation()
                            onViewDetails()
                          }
                        : undefined
                    }
                  >
                    {t('View details')}
                  </button>
                </div>
              </div>
            </div>

            {checked && (
              <div className="-m-4 mt-4 space-y-5 rounded-b border-t-2 border-tabeo-primary-3 bg-white p-5 md:-m-5 md:mt-5">
                <div className="caption intems-center flex gap-x-2 text-tabeo-ink-2">
                  <InformationCircleIcon className="h-5 w-5" />
                  {t(
                    'Patient will receive option for {{customerTermOptions}} months',
                    {
                      customerTermOptions: customerTermOptions
                        .map(o => o.numberOfMonths)
                        .join(', '),
                    }
                  )}
                </div>
                {!isReferenceOpen &&
                  isOfferInternalReferenceCapable &&
                  !isOfferInternalReferenceRequired && (
                    <button
                      type="button"
                      className="anchor caption mt-4 inline-flex items-center gap-1"
                      onClick={() => setIsReferenceOpen(true)}
                    >
                      <PlusCircleIcon className="h-5 w-5" />
                      {t('Add reference')}
                    </button>
                  )}
                {((isReferenceOpen && isOfferInternalReferenceCapable) ||
                  isOfferInternalReferenceRequired) && (
                  <div className="flex flex-col items-start gap-4 md:flex-row">
                    <Field
                      name="reference"
                      clearable
                      component={FFTextInput}
                      validate={
                        isOfferInternalReferenceRequired ? required : undefined
                      }
                      optional={!isOfferInternalReferenceRequired}
                      label={isSDC ? t('Case') : t('Reference')}
                    />
                    <div className="w-full" />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </RadioCards.Option>
    </div>
  )
}

interface AprProps extends React.HTMLAttributes<HTMLDivElement> {
  value: number
}

export function AprChip({ value, ...rest }: AprProps) {
  return (
    <div
      className={twMerge(
        'subheading inline-block rounded border bg-white px-2 py-1',
        value > 0
          ? 'border-tabeo-ink-1 text-tabeo-ink-1 group-aria-disabled:border-tabeo-sky-0 group-aria-disabled:text-tabeo-sky-0'
          : 'border-tabeo-blue-3 text-tabeo-blue-3 group-aria-disabled:border-tabeo-blue-5 group-aria-disabled:text-tabeo-blue-5'
      )}
      {...rest}
    >
      {(value * 100).toFixed(1)}% APR
    </div>
  )
}
