import React from 'react'
import ScarfProps from '../../types/ScarfProps'
import View from '../View'

export type ConditionalFieldsProps = ScarfProps

function ConditionalFields({ children, ...rest }: ConditionalFieldsProps) {
  return (
    <View display="flex" alignItems="stretch" mb={5} {...rest}>
      <View width="4px" mr={3} bg="primary.5" />
      <View mb="-20px" flex="1">
        {children}
      </View>
    </View>
  )
}
export default ConditionalFields
