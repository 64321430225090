import { Box, Prompt, Subtitle, View } from '@tabeo/scarf'
import Layout from 'components/Layout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import calculatingSvg from './assets/calculating.svg'

const Icon = styled(View)`
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

Icon.defaultProps = {
  as: 'img',
  mx: 'auto',
  src: calculatingSvg,
}

function Pending(props) {
  const { t } = useTranslation()
  return (
    <Layout maxWidth="460px" bg={['white', 'sky.2']}>
      <Box variant="content" textAlign="center" {...props}>
        <Icon width="100px" height="100px" />
        <Subtitle mb={3} color="sea.3">
          {t('We’re checking your business')}
        </Subtitle>
        <Prompt>{t('This may take a couple of seconds')}</Prompt>
      </Box>
    </Layout>
  )
}

export default Pending
