'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var parseJWT = function (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};
var validUntil = function (token) {
    var exp = parseJWT(token).exp;
    return new Date(exp * 1000);
};
var isValid = function (token) {
    var expireDate = validUntil(token);
    var now = new Date();
    if (expireDate > now) {
        return true;
    }
    return false;
};
var getJWT = function (name) {
    var jwt = window.localStorage.getItem(name) || null;
    if (jwt && isValid(jwt)) {
        return jwt;
    }
    return null;
};

exports.getJWT = getJWT;
exports.isValid = isValid;
exports.parseJWT = parseJWT;
exports.validUntil = validUntil;
