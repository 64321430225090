import React, { Component } from 'react'
import styled from 'styled-components'
import View from '../View'

const ANIMATION_DURATION = 800

const LoadingWrap = styled(View)`
  position: absolute;
  top: 0px;
  left: 0;
  overflow: hidden;
  height: 1px;
  width: 100%;
`

const LoadingLine = styled(View)<{ loading?: boolean }>`
  position: relative;
  height: 1px;
  width: 100%;
  left: 0;
  background: linear-gradient(
    to left,
    #0dab76,
    #0ae199 28%,
    #dfe3e8 53%,
    #dfe3e8
  );
  display: ${(p) => (p.loading ? 'block' : 'none')};
  animation-name: ${(p) => (p.loading ? 'slide' : 'none')};
  animation-duration: ${ANIMATION_DURATION}ms;
  animation-iteration-count: infinite;

  @keyframes slide {
    from {
      left: -780px;
    }
    to {
      left: 280px;
    }
  }
`

export type LoaderProps = {
  loading?: boolean
}

class Loader extends Component<LoaderProps, { isAnimating: boolean }> {
  state = {
    isAnimating: false,
  }

  mounted = true

  timerStart = 0

  componentDidUpdate() {
    const { loading } = this.props
    const { isAnimating } = this.state
    if (!isAnimating && loading) {
      this.timerStart = setTimeout(() => {
        this.mounted && this.setState({ isAnimating: true })
        setTimeout(() => {
          this.mounted && this.setState({ isAnimating: false })
        }, ANIMATION_DURATION - 50)
      }, 1000) as unknown as number
    }
    if (!loading && !isAnimating) {
      clearTimeout(this.timerStart)
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    const { isAnimating } = this.state
    return (
      <LoadingWrap>
        <LoadingLine loading={isAnimating} />
      </LoadingWrap>
    )
  }
}

export default Loader
