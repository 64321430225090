import { Merchant } from '@tabeo/ts-types'
import { ExtendedLoanOption, LoanOptionWithRiskProfile } from 'pages/NNTS/types'
import { useField } from 'react-final-form'

function getLoanOptions(merchant: Merchant) {
  const { flags, merchantRiskProfiles } = merchant

  const defaultRiskProfile = merchantRiskProfiles.sort(
    (a, b) => a.order - b.order
  )?.[0]

  const options = flags?.is_risk_profile_changing_capable
    ? merchantRiskProfiles.reduce((mem, profile) => {
        const options = profile.loanOptions.map(o => ({
          ...o,
          profile,
        }))
        return [...mem, ...options]
      }, [] as LoanOptionWithRiskProfile[])
    : defaultRiskProfile.loanOptions.map(o => ({
        ...o,
        profile: defaultRiskProfile,
      }))

  const orderedLoanOptions = options
    .map(o => ({
      ...o,
      profile: {
        ...o.profile,
        loanOptions: o.profile.loanOptions.sort(
          (a, b) => a.numberOfMonths - b.numberOfMonths
        ),
      },
    }))
    .sort((a, b) => a.numberOfMonths - b.numberOfMonths)

  return orderedLoanOptions
}

function getCustomerFacingTermOptions({
  maxTerm,
  loanOptions,
  price,
}: {
  maxTerm: number
  loanOptions: LoanOptionWithRiskProfile[]
  price: number
}) {
  const availableOptions = loanOptions.filter(o => {
    const isTermLess = o.numberOfMonths <= maxTerm
    const isPriceInValidationRange =
      price >=
        (o.minimumLoanAmount ||
          (o.numberOfMonths > 12
            ? DEFAULT_TIER_2_MINIMUM_LOAN_AMOUNT
            : DEFAULT_TIER_1_MINIMUM_LOAN_AMOUNT)) &&
      price <=
        (o.maximumLoanAmount ||
          (o.numberOfMonths > 12
            ? DEFAULT_TIER_2_MAXIMUM_LOAN_AMOUNT
            : DEFAULT_TIER_1_MAXIMUM_LOAN_AMOUNT))

    return isTermLess && isPriceInValidationRange
  })

  const lastThreeOptions = availableOptions.slice(-3)

  const interestFree12MonthsOption = availableOptions.find(
    o => o.numberOfMonths === 12 && o.interestRate === 0
  )

  if (
    maxTerm > 12 &&
    !lastThreeOptions.map(o => o.numberOfMonths).includes(12) &&
    interestFree12MonthsOption
  ) {
    return [interestFree12MonthsOption, ...lastThreeOptions.slice(1)]
  }

  return lastThreeOptions
}

const LOAN_OPTION_PRIORITY = [
  ...[12, 10, 6, 3].map(term => ({ term, isRegulated: false })),
  ...[12, 36, 24, 48, 60, 18].map(term => ({ term, isRegulated: true })),
]

export function isSameLoanOption(
  a: ExtendedLoanOption | null,
  b: ExtendedLoanOption | null
) {
  if (!a || !b) {
    return false
  }

  return (
    a.numberOfMonths === b.numberOfMonths &&
    a.interestRate === b.interestRate &&
    a.isRegulated === b.isRegulated &&
    a.profile.name === b.profile.name
  )
}

export const DEFAULT_TIER_1_MINIMUM_LOAN_AMOUNT = 250_00
export const DEFAULT_TIER_1_MAXIMUM_LOAN_AMOUNT = 30_000_00
export const DEFAULT_TIER_2_MINIMUM_LOAN_AMOUNT = 500_00
export const DEFAULT_TIER_2_MAXIMUM_LOAN_AMOUNT = 30_000_00

export const getLoanOptionsWithCustomerFacingTerms = (
  merchant: Merchant,
  price: number
) => {
  const loanOptions = getLoanOptions(merchant)

  const loanOptionsWithCustomerFacingTerms = loanOptions.map(o => ({
    ...o,
    customerTermOptions: getCustomerFacingTermOptions({
      maxTerm: o.numberOfMonths,
      loanOptions,
      price,
    }),
  }))

  return loanOptionsWithCustomerFacingTerms
}

export default function useFinanceOptions(merchant?: Merchant): [
  ExtendedLoanOption[],
  {
    priorityLoanOption: ExtendedLoanOption | null
    longestTermLoanOption: ExtendedLoanOption | null
    otherLoanOptions: ExtendedLoanOption[]
  }
] {
  const {
    input: { value: price },
  } = useField('price')

  if (!merchant) {
    return [
      [],
      {
        priorityLoanOption: null,
        longestTermLoanOption: null,
        otherLoanOptions: [],
      },
    ]
  }

  const loanOptionsWithCustomerFacingTerms =
    getLoanOptionsWithCustomerFacingTerms(merchant, price).map(o => {
      if (o.numberOfMonths > 12) {
        return {
          ...o,
          disabled:
            price <
              (o.minimumLoanAmount || DEFAULT_TIER_2_MINIMUM_LOAN_AMOUNT) ||
            price > (o.maximumLoanAmount || DEFAULT_TIER_2_MAXIMUM_LOAN_AMOUNT),
        }
      }

      return {
        ...o,
        disabled:
          price < (o.minimumLoanAmount || DEFAULT_TIER_1_MINIMUM_LOAN_AMOUNT) ||
          price > (o.maximumLoanAmount || DEFAULT_TIER_1_MAXIMUM_LOAN_AMOUNT),
      }
    })

  const loanOptionsBelowTheCutoff = loanOptionsWithCustomerFacingTerms.filter(
    o => o.numberOfMonths <= 12 && !o.disabled
  )

  const loanOptionsToDisplay =
    loanOptionsBelowTheCutoff.length > 0
      ? loanOptionsBelowTheCutoff
      : loanOptionsWithCustomerFacingTerms

  // Pick the priority option
  const priorityOption =
    LOAN_OPTION_PRIORITY.find(o =>
      loanOptionsToDisplay.find(l => l.numberOfMonths === o.term)
    ) || null

  const priorityLoanOption =
    loanOptionsToDisplay.find(o => o.numberOfMonths === priorityOption?.term) ||
    null

  const loanOptionsWithoutPriorityOption =
    loanOptionsWithCustomerFacingTerms.filter(
      o => !isSameLoanOption(o, priorityLoanOption)
    )

  // Pick the longest term option
  const longestTermLoanOption: ExtendedLoanOption | null =
    loanOptionsWithoutPriorityOption.sort(
      (a, b) => b.numberOfMonths - a.numberOfMonths
    )[0] || null

  // Filter out the priority and longest term options
  const otherLoanOptions = loanOptionsToDisplay
    .filter(o => {
      if (isSameLoanOption(o, priorityLoanOption)) {
        return false
      }

      if (isSameLoanOption(o, longestTermLoanOption)) {
        return false
      }

      return true
    })
    .sort((a, b) => a.numberOfMonths - b.numberOfMonths)

  return [
    loanOptionsWithCustomerFacingTerms,
    {
      priorityLoanOption,
      longestTermLoanOption,
      otherLoanOptions,
    },
  ]
}
