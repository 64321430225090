import { Panel, Prompt, Subheading, TableCard, Tag, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const { Row, Card } = TableCard

const ContactPanel = props => {
  const { t } = useTranslation()
  const { email, phone = t('Not yet available'), isUserVerified } = props
  return (
    <Panel
      header={
        <>
          <Panel.Title>{t('Contact')}</Panel.Title>
          <Tag small>{isUserVerified ? t('Verified') : t('not verified')}</Tag>
        </>
      }
      mb={6}
    >
      <View py={3} px={5} bg="white">
        <Row>
          <Card>
            <Subheading color="ink.2">{t('Email')}</Subheading>
            <Prompt data-no-track>{email}</Prompt>
          </Card>
          <Card>
            <Subheading color="ink.2">{t('Phone')}</Subheading>
            <Prompt data-no-track>{phone || t('Not available')}</Prompt>
          </Card>
        </Row>
      </View>
    </Panel>
  )
}

ContactPanel.propTypes = {
  email: PropTypes.string.isRequired,
  phone: PropTypes.string,
  isUserVerified: PropTypes.bool,
}

export default ContactPanel
