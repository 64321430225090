import { UserPlusIcon } from '@heroicons/react/24/outline'
import {
  FFPhoneNumberInput,
  FFRadioTabs,
  FFTextInput,
  Stepper as StepperItem,
} from '@tabeo/scarf2'
import {
  email,
  mobilePhonePCA,
  name,
  phone,
  required,
} from '@tabeo/sharpei/utils/validations'
import AutoSave from 'components/nnts/form/AutoSave'
import { Field } from 'components/nnts/form/Field'
import { scrollToErrorDecorator } from 'components/nnts/form/scrollToError'
import { ComponentProps } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import { z } from 'zod'
import { ActionButton } from '../components/action-button'
import Header from '../components/header'
import { useNewTransactionFlow } from '../components/provider'

export const patientSchema = z.object({
  gender: z.enum(['m', 'f']),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
})

export const patientWithContactSchema = patientSchema.extend({
  email: z.string().email(),
  mobileNumber: z.string().min(12),
})

export const getPatientSchema = ({
  isFinance,
  isBill,
}: {
  isFinance: boolean
  isBill: boolean
}) => {
  if (isFinance || isBill) {
    return patientWithContactSchema
  }
  return patientSchema
}

export function PatientMain() {
  const {
    setState,
    setStateForStep,
    state,
    modals: { cartSummaryModalRef },
    next,
    form,
    currentIndex,
  } = useNewTransactionFlow()
  const { t } = useTranslation()
  const { data: merchant } = useMerchant()

  function handleSubmit(values: any) {
    if (!merchant) {
      return {
        formError: 'Merchant not found',
      }
    }

    // Save latest form values to state
    const newState = {
      ...state,
      patient: {
        ...values,
      },
    }
    setState(newState)

    return next()
  }

  const isFinance = state.payment?.paymentMethod?.startsWith('finance')
  const isBill = state.payment?.paymentMethod === 'bill'

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={state.patient}
      decorators={[scrollToErrorDecorator]}
      destroyOnUnregister
    >
      {({ handleSubmit, form: f }) => {
        form.current = f
        return (
          <form onSubmit={handleSubmit} className="h-full">
            <Header
              cartSummaryModalRef={cartSummaryModalRef}
              currentIndex={currentIndex}
            />
            <h2 className="title text-tabeo-ink-0">{t('Patient')}</h2>
            <p className="caption mb-6 text-tabeo-ink-1">
              {t('Enter patient details in the fields below.')}
            </p>

            <div className="space-y-5">
              <div className="grid gap-4 md:grid-cols-2">
                <Field<string>
                  component={FFRadioTabs}
                  name="gender"
                  label={t('Title')}
                  validate={required}
                  items={[
                    { value: 'm', label: t('Mr.') },
                    { value: 'f', label: t('Ms.') },
                  ]}
                />
              </div>

              <div className="grid items-start gap-4 sm:grid-cols-2">
                <Field<string>
                  component={FFTextInput}
                  name="firstName"
                  label={t('First name')}
                  placeholder={t('E.g. John')}
                  validate={[required, name]}
                  format={v => (v ? v.capitalize() : '')}
                  clearable
                  description={t('As shown in passport/ID')}
                />

                <Field<string>
                  component={FFTextInput}
                  name="lastName"
                  label={t('Last name')}
                  placeholder={t('E.g. Smith')}
                  validate={[required, name]}
                  format={v => (v ? v.capitalize() : '')}
                  clearable
                  description={t('As shown in passport/ID')}
                />
              </div>

              <div className="grid items-start gap-4 sm:grid-cols-2">
                <Field
                  component={FFTextInput}
                  name="email"
                  label={t('Email address')}
                  placeholder={t('E.g. john@email.com')}
                  validate={[required, email]}
                  optional={!isFinance && !isBill}
                  onKeyDown={(e: any) => e.stopPropagation()}
                />
                <Field
                  component={FFPhoneNumberInput}
                  name="mobileNumber"
                  label={t('Mobile number')}
                  validate={[required, phone, mobilePhonePCA]}
                  optional={!isFinance && !isBill}
                  onKeyDown={(e: any) => e.stopPropagation()}
                />
              </div>
            </div>

            <AutoSave
              debounce={0}
              onSave={(values: any) => {
                setStateForStep('patient', values)
              }}
            />
            <button type="submit" className="hidden">
              {t('Submit')}
            </button>
          </form>
        )
      }}
    </Form>
  )
}

export function PatientActions() {
  const { t } = useTranslation()
  return <ActionButton>{t('Confirm patient')}</ActionButton>
}

export function shouldRenderPatientMobileActions() {
  return true
}

export function PatientStepper(props: ComponentProps<typeof StepperItem>) {
  const { state } = useNewTransactionFlow()
  const { t } = useTranslation()

  const { patient } = state

  const { firstName, lastName, email, mobileNumber } = state.patient || {}

  const body =
    firstName || lastName ? (
      <>
        {patient.firstName} {patient.lastName}
        {(email || mobileNumber) && (
          <div className="mt-2 space-y-1.5 bg-tabeo-sky-2 px-3 py-2 font-normal text-tabeo-ink-1 desktop:hidden">
            <div className="caption">{email}</div>
            <div className="caption">{mobileNumber}</div>
          </div>
        )}
      </>
    ) : undefined

  return (
    <StepperItem empty={!body} {...props}>
      <StepperItem.Header>
        <StepperItem.Icon icon={UserPlusIcon} />
        <StepperItem.Label>{t('Patient')}</StepperItem.Label>
      </StepperItem.Header>
      <StepperItem.Body>{body}</StepperItem.Body>
    </StepperItem>
  )
}
