import React from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Modal from '.'
import Button from '../Button'
import { Title } from '../Text'
import Box from '../Box'
import View from '../View'
import ScarfProps from '../../types/ScarfProps'

const Container = styled(Box)`
  overflow: auto;
`

export type SimpleModalProps = ScarfProps & {
  title: string
  closeButtonProps?: ScarfProps
  innerRef?: any
}

const SimpleModal = ({
  title,
  children,
  innerRef,
  closeButtonProps = {},
  ...rest
}: SimpleModalProps) => {
  const { t } = useTranslation()
  return (
    <Modal ref={innerRef}>
      {({ isOpen, close }) =>
        isOpen && (
          <Container
            maxWidth={['95%', '460px']}
            mx="auto"
            boxShadow={1}
            p={7}
            {...rest}
          >
            <Title mb={4}>{title}</Title>
            <View mb={4}>{children}</View>
            <Button onClick={close} {...closeButtonProps}>
              {t('Close')}
            </Button>
          </Container>
        )
      }
    </Modal>
  )
}

export default SimpleModal
