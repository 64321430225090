'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../tslib.es6-1ef680b2.js');

var debounce = function (fn, wait, options) {
    if (options === void 0) { options = {}; }
    var lastCallAt;
    var deferred;
    var timer;
    var pendingArgs = [];
    return function debounced() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var currentWait = getWait(wait);
        var currentTime = new Date().getTime();
        var isCold = !lastCallAt || currentTime - lastCallAt > currentWait;
        lastCallAt = currentTime;
        if (isCold && options.leading) {
            return options.accumulate
                ? Promise.resolve(fn.call(this, [args])).then(function (result) { return result[0]; })
                : Promise.resolve(fn.call.apply(fn, tslib_es6.__spreadArray([this], args, false)));
        }
        if (deferred) {
            clearTimeout(timer);
        }
        else {
            deferred = defer();
        }
        pendingArgs.push(args);
        timer = setTimeout(flush.bind(this), currentWait);
        if (options.accumulate) {
            var argsIndex_1 = pendingArgs.length - 1;
            return deferred.promise.then(function (results) { return results[argsIndex_1]; });
        }
        return deferred.promise;
    };
    function flush() {
        var thisDeferred = deferred;
        clearTimeout(timer);
        Promise.resolve(options.accumulate
            ? fn.call(this, pendingArgs)
            : fn.apply(this, pendingArgs[pendingArgs.length - 1])).then(thisDeferred && thisDeferred.resolve, thisDeferred && thisDeferred.reject);
        pendingArgs = [];
        deferred = null;
    }
};
function getWait(wait) {
    return typeof wait === 'function' ? wait() : wait;
}
function defer() {
    var deferred = {};
    deferred.promise = new Promise(function (resolve, reject) {
        deferred.resolve = resolve;
        deferred.reject = reject;
    });
    return deferred;
}

exports.default = debounce;
