import { Button, Prompt, Subtitle, View } from '@tabeo/scarf'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TickSvg from './assets/tick.svg'
import { useRefundFlow } from './RefundFlowContext'

function Success() {
  const { close } = useRefundFlow()

  const { t } = useTranslation()
  return (
    <View textAlign="center" data-ga-id="merchant-offer-refund-success">
      <img className="mx-auto my-10" src={TickSvg} />
      <Subtitle mb={3}>{t('Transaction refunded')}</Subtitle>
      <Prompt mb={6}>
        {t('We’ve sent your customer an email confirmation.')}
      </Prompt>
      <Button variant="secondary" width="100%" onClick={close}>
        {t('Close')}
      </Button>
    </View>
  )
}

export default Success
