import React from 'react'
import Svg, { SvgProps, G } from './Base'

function RoundedPlus({ fill, ...props }: SvgProps) {
  return (
    <Svg viewBox="0 0 27 27" {...props}>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <rect stroke="#DFE3E8" fill="#F9FAFB" width={25} height={25} rx={4} />
        <G
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        >
          <path d="M12.844 7.324v10.352M17.656 12.5H7.344" />
        </G>
      </g>
    </Svg>
  )
}

export default RoundedPlus
