import React from 'react'
import ScarfProps from '../types/ScarfProps'
import usePromise from '../hooks/usePromise'
import Flex from './Flex'
import { Spinner } from './Icons'
import { BaseProps, Caption, Clickable } from './Text'
import View from './View'

export type ItemProps = ScarfProps & {
  gridCols?: number
}

function Item({ gridCols = 3, ...rest }: ItemProps) {
  return (
    <View
      display={['block', 'grid']}
      gridTemplateColumns={`repeat(${gridCols}, minmax(0,1fr))`}
      gridGap={4}
      py={4}
      borderBottom={1}
      {...rest}
    />
  )
}

export type DTProps = BaseProps & {
  gridCols?: number
}

function DT({ ...rest }: DTProps) {
  return <Caption as="dt" {...rest} />
}

export type DDProps = ScarfProps & {
  colSpan?: number
}

export function DD({ colSpan = 2, ...rest }: DDProps) {
  return (
    <Flex
      as="dd"
      gridColumn={`span ${colSpan}/span ${colSpan}`}
      mt={[1, 0]}
      alignItems={['flex-start', 'center']}
      {...rest}
    />
  )
}

export type ValueProps = BaseProps

function Value({ ...rest }: ValueProps) {
  return <Caption color="ink.0" {...rest} />
}

export type ActionsProps = ScarfProps

function Actions({ ...rest }: ActionsProps) {
  return <Flex ml={4} {...rest} />
}

export type ActionProps = ScarfProps & {
  onClick?: (e: React.MouseEvent) => void
  disabled?: boolean
}

function Action({ onClick, disabled, ...rest }: ActionProps) {
  const [handleClick, status] = usePromise(onClick)

  return status.pending ? (
    <Spinner
      height="20px"
      width="20px"
      fill="primary.3"
      spinning
      mx={3}
      {...rest}
    />
  ) : (
    <Clickable
      onClick={!disabled ? handleClick : undefined}
      color={!disabled ? 'primary.3' : 'ink.3'}
      cursor={!disabled ? 'pointer' : 'not-allowed'}
      fontSize={1}
      {...rest}
    />
  )
}

export type SeparatorProps = ScarfProps

function Separator({ ...rest }: SeparatorProps) {
  return <View mx={3} borderLeft={1} height="20px" {...rest} />
}

export type ItemBuilderProps = ItemProps & {
  data: {
    label: string
    value?: React.ReactNode
    formattedValue?: React.ReactNode
    placeholder?: React.ReactNode
    actions?: ActionProps[]
  }
}

function ItemBuilder({ data: d, ...rest }: ItemBuilderProps) {
  if (!(d.value || d.placeholder || d.actions?.length)) {
    return null
  }

  return (
    <Item {...rest}>
      <DT>{d.label}</DT>
      <DD>
        <Value>{d.value ? d.formattedValue || d.value : d.placeholder}</Value>
        <Actions>
          {d.actions?.reduce(
            (acc: React.ReactNode[], curr) =>
              acc.length
                ? [...acc, <Separator />, <Action {...curr} />]
                : [<Action {...curr} />],
            []
          )}
        </Actions>
      </DD>
    </Item>
  )
}

export type DescriptionListProps = ScarfProps & {
  data: ItemBuilderProps['data'][]
}

function DescriptionList({ data, children, ...rest }: DescriptionListProps) {
  if (children) {
    return (
      <View as="dl" {...rest}>
        {children}
      </View>
    )
  }

  if (data) {
    return (
      <View as="dl" {...rest}>
        {data.map((d) => {
          if (React.isValidElement(d)) {
            return d
          }

          return <ItemBuilder key={d.label} data={d} />
        })}
      </View>
    )
  }

  throw new Error("One of props 'children' or 'data' is required")
}

DD.Value = Value
DD.Action = Action
DD.Actions = Actions
DD.Separator = Separator

DescriptionList.Item = Item
DescriptionList.DT = DT
DescriptionList.DD = DD

export { DescriptionList as DL }
export default DescriptionList
