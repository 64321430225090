import React, { useState } from 'react'
import ScarfProps from '../../../../types/ScarfProps'
import Flex from '../../../Flex'
import * as Icons from '../../../Icons'
import Text from '../../../Text'
import Tooltip2 from '../../../Tooltip2'

function copy(text: string) {
  const el = document.createElement('textarea')
  el.value = text
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)

  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export type IdProps = ScarfProps

function Id({ children, ...rest }: IdProps) {
  const [text, setText] = useState('Click to copy ticket URL')

  return (
    <Tooltip2 placement="right" title={text}>
      <Flex
        display="inline-flex"
        justifyContent="flex-start"
        cursor="pointer"
        onClick={() => {
          copy(window.location.href)
          const prevText = text

          setText('Copied!')
          setTimeout(() => {
            setText(prevText)
          }, 2000)
        }}
        {...rest}
      >
        <Icons.Id fill="currentColor" mr={1} />
        <Text fontSize="14px" color="currentColor">
          {children}
        </Text>
      </Flex>
    </Tooltip2>
  )
}

export default Id
