import { useDataTable } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import { useTranslation } from 'react-i18next'
import { useReconciliationStats } from 'resources/ReconciliationStats'
import { twMerge } from 'tailwind-merge'
import { mapReconciliationStateToParams } from './utils'

export default function Stats({ className }: { className?: string }) {
  const { t } = useTranslation()
  const { state, items } = useDataTable()
  const { activeFilters } = state
  const hasDateFilter = activeFilters?.date?.value?.value
  const { data } = useReconciliationStats(
    mapReconciliationStateToParams(state),
    {
      isEnabled: hasDateFilter,
    }
  )

  if (!items?.length || !hasDateFilter || !data) {
    return null
  }

  const stats = [
    { type: 'terminalPayments', label: t('Terminal') },
    { type: 'linkPayments', label: t('Link') },
    { type: 'allPayments', label: t('Total') },
  ] as const

  return (
    <div className={twMerge('px-4', className)}>
      <p className="font-medium">{t('Summary')}</p>
      <div className="mt-1 grid grid-cols-3 gap-2">
        {stats.map(({ type, label }) => (
          <div className="rounded border p-4" key={type}>
            <div className="subheading text-tabeo-ink-2">{label}</div>
            <div className="subtitle mt-1 ">
              {format(data[type].totalAmount)}
            </div>
            <div className="caption text-tabeo-ink-2">
              {data[type].numberOfPayments} {t('payments')}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
