import { Spinner } from '@tabeo/scarf'
import Container from 'components/Container'
import Nav from 'components/Nav'
import PropTypes from 'prop-types'
import React from 'react'

function LayoutTopNavbar({ isLoading, children, ...rest }) {
  return (
    <>
      <Nav />
      <Container
        py={[6, '40px']}
        px={[4, 0]}
        minHeight="calc(100vh - 60px)"
        bg="sky.3"
        {...rest}
      >
        {isLoading ? <Spinner minHeight="calc(100vh - 60px)" /> : children}
      </Container>
    </>
  )
}

LayoutTopNavbar.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.any,
}

export default LayoutTopNavbar
