import { Text } from '@tabeo/scarf'
import styled from 'styled-components'

const DocumentV2 = styled(Text)`
  font-size: 14px;
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  strong,
  b {
    font-weight: bold;
  }
  h1 {
    padding: 80px;
    text-align: center;
    font-weight: bold;
  }
  h2 {
    padding: 30px;
    text-align: center;
    font-weight: bold;
  }
  ol {
    counter-reset: section;
    list-style-type: none;
    width: 100%;
    padding: 0;
  }
  ol ol ol {
    padding-left: 50px;
    list-style-type: none;
    text-align: left;
  }
  li {
    position: relative;
    margin-top: 20px;
  }
  .document > ol > li > p:first-of-type {
    font-weight: bold;
  }
  li li {
    margin-top: 0;
  }
  ol > li:before {
    position: absolute;
    counter-increment: section;
    content: counters(section, '.') '. ';
    width: 50px;
    font-size: 14px;
    font-weight: bold;
  }
  li li:before {
    font-size: 14px;
    font-weight: normal;
  }
  li li li:before {
    content: counter(section, lower-alpha) ') ';
  }
  li li li li:before {
    content: counter(section, lower-roman) ') ';
  }
  ol.numbered {
    padding-left: 0;
  }
  ol.numbered li:before {
    content: counters(section, '.');
    width: 50px;
  }
  ol.numbered li p {
    margin-left: 50px;
  }

  li p {
    margin: 0 0 16px 50px;
  }
  li li p {
    margin: 0 0 16px 50px;
  }
  li li li p {
    margin: 0 0 16px 30px;
  }
  li div {
    margin: 0 0 16px 50px;
  }
  td h3 {
    margin: 0;
  }
  td p {
    display: block;
    margin: 0;
  }
  a {
    text-decoration: none;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
  }
  a:hover {
    text-decoration: underline;
  }
  blockquote p {
    margin-bottom: 10px;
  }
  .merhcant-guide-3 p {
    margin-bottom: 15px;
  }
  .merchant-responsibilities p {
    margin-bottom: 20px;
  }
  .merchant-responsibilities strong {
    display: block;
    margin-top: 30px;
  }
  .merchant-responsibilities ol li:before {
    content: '';
    width: 0;
  }
  .merchant-responsibilities ol li {
    list-style: decimal;
    margin-left: 25px;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .merchant-responsibilities ol li p {
    margin-left: 0;
    margin-bottom: 0;
  }

  .left,
  .left * {
    margin-left: 0;
  }
  table {
    font-size: 14px;
    border-collapse: collapse;
    border: 1px solid grey;
    width: 100%;
    margin-bottom: 20px;
  }

  table td {
    border: 1px solid grey;
    padding: 15px 10px;
  }
  table th {
    background: #f3f3f3;
    text-align: left;
    color: black;
    border: 1px solid grey;
  }
  th,
  td {
    padding: 8px;
  }
  .mt-30 {
    margin-top: 30px;
  }
  em {
    text-decoration: underline;
  }
  li > ul li:before {
    content: '';
  }
  ol ul {
    list-style: initial;
    padding-left: 50px;
    margin-left: 30px;
  }
  ul > li p {
    margin: 0 0 16px 12px;
  }
`

export default DocumentV2
