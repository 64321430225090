import { Caption, Panel, Subheading, TableCard, View } from '@tabeo/scarf'
import Filepreview from 'components/Filepreview'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const { Row, Card } = TableCard

const Uploads = ({ product, uploads = [], ...rest }) => {
  const { t } = useTranslation()
  const uploadRows = uploads.reduce((mem, item, index) => {
    const i = index + 1
    const extendedItem = {
      ...item,
      i,
    }
    if (i % 2 !== 0) {
      mem.push([extendedItem])
    } else {
      mem[mem.length - 1].push(extendedItem)
    }
    return mem
  }, [])

  const arePhotosUploaded = uploads.length

  const titles = {
    invisalign: t('Smile photos'),
    clear_correct: t('Smile photos'),
    implant: t('Smile photos'),
  }

  return (
    <Panel title={titles[product.type] || t('Photos')} {...rest}>
      <View py={3} px={5} bg="white">
        {arePhotosUploaded ? (
          uploadRows.map((images, index) => (
            <Row key={index}>
              {images.map(image => (
                <Card key={image.id}>
                  <Subheading color="ink.2" mb={1}>
                    {t('Image')} {image.i}
                  </Subheading>
                  <Filepreview
                    name={image.name}
                    url={image.objects.lead_image.url}
                    maxWidth="268px"
                  />
                </Card>
              ))}
            </Row>
          ))
        ) : (
          <Caption textAlign="center">
            {t("The patient hasn't uploaded any smile photos yet")}
          </Caption>
        )}
      </View>
    </Panel>
  )
}

Uploads.propTypes = {
  product: PropTypes.object.isRequired,
  uploads: PropTypes.array.isRequired,
}

export default Uploads
