import React from 'react'
import PropTypes from 'prop-types'

import { Spinner, Container } from '@tabeo/scarf'

import Nav from 'components/Nav'
import { children } from '@tabeo/sharpei/utils/propTypes'

const Layout = ({ isLoading, children }) => (
  <>
    <Nav />
    <Container
      pt={['4px', '30px']}
      pb="30px"
      px={[3, 0]}
      wrapStyle={{
        minHeight: 'calc(100vh - 60px)',
        height: 'auto',
      }}
      maxWidth="720px"
    >
      {isLoading ? <Spinner /> : children}
    </Container>
  </>
)

Layout.propTypes = {
  isLoading: PropTypes.bool,
  children,
}

export default Layout
