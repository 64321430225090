import getFingerprint from '@tabeo/sharpei/utils/getFingerprint'
import { getJWT } from '@tabeo/sharpei/utils/jwt'
import config from 'config'

declare global {
  // eslint-disable-next-line no-var, vars-on-top
  var refreshPromise: Promise<{
    token: string
    refresh: string
  }> | null
}

async function refreshAccessToken() {
  if (global.refreshPromise) {
    return global.refreshPromise
  }

  // eslint-disable-next-line no-async-promise-executor
  global.refreshPromise = new Promise(async (resolve, reject) => {
    try {
      const currentRefreshToken = getJWT('refreshToken')

      if (!currentRefreshToken) {
        throw new Error('token expired')
      }

      const { token, refresh } = await fetch(`${config.API_URL}/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refresh: currentRefreshToken,
          fingerprint: getFingerprint(),
        }),
      }).then(response => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }

        return response.json()
      })

      localStorage.setItem('token', token)
      localStorage.setItem('refreshToken', refresh)

      resolve({ token, refresh })
    } catch (error) {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')

      reject(error)
    } finally {
      global.refreshPromise = null
    }
  })

  return global.refreshPromise
}

export default refreshAccessToken
