import { Caption, Clickable, Flex, Heading, View } from '@tabeo/scarf'
import icon from 'components/ActionPanel/assets/settled-approved.svg'
import Card from 'components/Transaction/ActionPanel/Card'
import PropTypes from 'prop-types'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import CommonActions from './CommonActions'

function Qualified({ resource, ...rest }) {
  const { firstName: leadFirstName, phoneNumber: leadPhoneNumber } =
    resource.data
  const { t } = useTranslation()

  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('New qualified lead')}</Heading>
      </Flex>
      <Caption color="ink.1" mb={4}>
        <Trans>
          {{ leadFirstName }} is interested and has been pre-qualified. You can
          now call him on{' '}
          <Clickable as="a" href={`tel:${leadPhoneNumber}`}>
            {{ leadPhoneNumber }}
          </Clickable>{' '}
          to arrange the appointment or consultation.
        </Trans>
      </Caption>
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="flex-start"
        alignItems={['stretch', 'center']}
      >
        <CommonActions resource={resource} />
      </Flex>
    </Card>
  )
}

Qualified.propTypes = {
  resource: PropTypes.object.isRequired,
}

export default Qualified
