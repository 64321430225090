import { useTranslation } from 'react-i18next'
import { ReactComponent as XRed } from 'components/checkout-payment/assets/x-red.svg'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import CancelButton from '../CancelButton'

export default function NoDirectDebit() {
  const { t } = useTranslation()

  return (
    <ActionCard>
      <ActionCard.Header>
        <XRed className="h-6 w-6" />
        {t('No direct debit mandate')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t(
          'The customer’s bank has rejected or cancelled the direct debit mandate. Please contact the customer and ask them to set up a new direct debit mandate online.'
        )}
      </ActionCard.Body>
      <ActionCard.Actions>
        <CancelButton />
      </ActionCard.Actions>
    </ActionCard>
  )
}
