import ActionCard from 'components/ActionPanel/ActionCard'
import icon from 'components/ActionPanel/assets/x-grey.svg'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

function Disqualified({ resource }) {
  const { data } = resource
  const { firstName, stateMachineTransitions } = data
  const { t } = useTranslation()
  const event = stateMachineTransitions.find(e => e.to === 'disqualified')
  return (
    <>
      <ActionCard
        icon={icon}
        title={t('Lead disqualified')}
        body={t(
          `{{leadFirstName}} was disqualified {{elapsedTime}} because he/she was either unsuitable or not interested.`,
          {
            leadFirstName: firstName,
            elapsedTime: moment(event?.at).fromNow(),
          }
        )}
      />
    </>
  )
}

Disqualified.propTypes = {
  resource: PropTypes.object.isRequired,
}

export default Disqualified
