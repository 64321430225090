import { themeGet } from '@styled-system/theme-get'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { withTheme } from 'styled-components'
import BaseFlex from '../../Flex'

const Flex = styled(BaseFlex)`
  ${(p) =>
    p.outline &&
    `
    box-shadow: 0 0px 12px 2px rgba(80, 80, 80, 0.2);
    border-radius: 20px;
 `};
`

const Unselected = ({ fillColor = '#919EAB' }) => (
  <svg width="22" height="22" viewBox="0 0 22 22">
    <defs>
      <circle id="radio-item-default-a" cx="11" cy="11" r="11" />
      <filter
        id="radio-item-default-b"
        width="109.1%"
        height="109.1%"
        x="-4.5%"
        y="-4.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dx="2" dy="-2" in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#FFF" xlinkHref="#radio-item-default-a" />
      <use
        fill="#000"
        filter="url(#radio-item-default-b)"
        xlinkHref="#radio-item-default-a"
      />
      <circle cx="11" cy="11" r="10.5" stroke={fillColor} />
    </g>
  </svg>
)

Unselected.propTypes = {
  fillColor: PropTypes.string,
}

const Selected = ({ fillColor = '#2C9FA5' }) => (
  <svg width="22" height="22" viewBox="0 0 22 22">
    <defs>
      <circle id="radio-item-default-a" cx="11" cy="11" r="11" />
      <filter
        id="radio-item-default-b"
        width="109.1%"
        height="109.1%"
        x="-4.5%"
        y="-4.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dx="2" dy="-2" in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
      </filter>
      <circle id="radio-item-default-c" cx="11" cy="11" r="6" />
      <filter
        id="radio-item-default-d"
        width="108.3%"
        height="108.3%"
        x="-4.2%"
        y="-4.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dx="1" dy="-1" in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <use fill="#FFF" xlinkHref="#radio-item-default-a" />
        <use
          filter="url(#radio-item-default-b)"
          xlinkHref="#radio-item-default-a"
        />
        <circle cx="11" cy="11" r="10.5" stroke={fillColor} />
      </g>
      <g>
        <use fill={fillColor} xlinkHref="#radio-item-default-c" />
      </g>
    </g>
  </svg>
)

Selected.propTypes = {
  fillColor: PropTypes.string,
}

const Dot = ({ selected, disabled, focus, ...rest }) => {
  const primary = themeGet('colors.default.primary')(rest)
  const grey = themeGet('colors.sky.0')(rest)
  return (
    <Flex outline={focus && !selected} {...rest}>
      {selected ? (
        <Selected fillColor={disabled ? grey : primary} />
      ) : (
        <Unselected fillColor={disabled ? grey : undefined} />
      )}
    </Flex>
  )
}

Dot.propTypes = {
  selected: PropTypes.bool,
  focus: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default styled(withTheme(Dot))``
