import { Button, Icons, View } from '@tabeo/scarf'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container } from './LayoutSideNavbar'

export type SubpageProps = {
  backLink?: string
  renderBreadcrumb?: (props: { onBackClick: () => void }) => React.ReactNode
} & React.ComponentProps<typeof Container>

function Subpage({
  children,
  backLink,
  renderBreadcrumb,
  ...rest
}: SubpageProps) {
  const history = useHistory()
  const { t } = useTranslation()
  const buttonProps = backLink
    ? {
        as: Link,
        to: backLink,
      }
    : {
        onClick: history.goBack,
      }

  return (
    <Container
      py={null}
      pt={!renderBreadcrumb ? 1 : 4}
      pb={6}
      mx="auto"
      boxSizing="content-box"
      maxWidth="960px"
      width="calc(100% - 2 * 16px)"
      {...rest}
    >
      <View mb={1}>
        {renderBreadcrumb ? (
          renderBreadcrumb({ onBackClick: history.goBack })
        ) : (
          // @ts-ignore
          <Button
            leftIcon={p => <Icons.ArrowLeft {...p} ml={0} mr={0} />}
            variant="transparent"
            textAlign="left"
            {...buttonProps}
          >
            {t('Back')}
          </Button>
        )}
      </View>
      {children}
    </Container>
  )
}

export default Subpage
