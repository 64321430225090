import { Button, Spinner, Text, Title, View } from '@tabeo/scarf'
import ScarfProps from '@tabeo/scarf/dist/types/ScarfProps'
import { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import TerminalPaymentContext from './TerminalPayment/Context'

export type ConnectingProps = {
  titleProps?: ScarfProps
} & React.ComponentProps<typeof View>

function Connecting({ titleProps, ...rest }: ConnectingProps) {
  const { t } = useTranslation()
  const [showTroubleshooting, setShowTroubleshooting] = useState(false)
  const { modal } = useContext(TerminalPaymentContext)

  useEffect(() => {
    const troubleshootingTimeout = setTimeout(() => {
      setShowTroubleshooting(true)
    }, 30_000)

    return () => {
      clearTimeout(troubleshootingTimeout)
    }
  }, [])

  return (
    <View textAlign="center" {...rest}>
      <Spinner minHeight="160px" />
      <Title mt={6} {...titleProps}>
        {t('Connecting to your terminal')}
      </Title>
      {showTroubleshooting ? (
        <>
          <Text mt={2} fontSize={1} mb={6}>
            <Trans>
              Could not communicate with the terminal. Please make sure your
              terminal is online and on the same network as your device.
            </Trans>
          </Text>
          <Button
            variant="transparent"
            as="a"
            href="https://intercom.help/tabeo/en/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Click for more details')}
          </Button>
          {modal?.isOpen && (
            <div className="mt-4">
              <Button variant="secondary" onClick={() => modal.close()}>
                {t('Cancel')}
              </Button>
            </div>
          )}
        </>
      ) : modal?.isOpen ? (
        <div className="mt-4">
          <Button variant="transparent" onClick={() => modal.close()}>
            {t('Cancel')}
          </Button>
        </div>
      ) : null}
    </View>
  )
}

export default Connecting
