import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import { Patient } from '@tabeo/ts-types'

interface Params {
  limit?: number
  offset?: number
  searchQuery?: string
}

interface Data {
  items: null | Patient[]
  totalCount: number
}

class Patients extends Resource<Data, Params> {
  getInitialData() {
    return {
      items: null,
      totalCount: 0,
    }
  }

  getInitialConfig() {
    return {
      refreshInterval: 15 * 1000,
    }
  }

  async fetch(query: string) {
    const { searchQuery = query, limit = 10, offset = 0 } = this.params

    const pagination = `&limit=${limit}&offset=${offset}`

    const endpoint = searchQuery
      ? `/merchant/patient-search?order=createdAt:asc&searchPhrase=${encodeURIComponent(
          searchQuery
        )}`
      : `/merchant/patients?order=createdAt:asc`

    const {
      data: { patients },
      totalCount,
    } = await api.get(`${endpoint}${pagination}`, {
      responseDataPath: 'data',
    })

    this.data = {
      totalCount,
      items: patients,
    }

    return this.data
  }

  static create = async (
    payload: Partial<Patient>
  ): Promise<{ id: number }> => {
    const {
      projections: { patient },
    } = await api.post(`/merchant/patients`, payload)

    return patient
  }
}

export default Patients
export const usePatients = createResourceHook(Patients)
