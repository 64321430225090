import { Button, FFTextInput } from '@tabeo/scarf2'
import { email } from '@tabeo/sharpei/utils/validations'
import FormError from 'components/Form/FormError'
import { Field } from 'components/nnts/form/Field'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import Success from './Success'

export type EmailFormProps = {
  onSubmit: (values: any) => Promise<any>
  initialValue: { email?: string }
  buttonVariant: React.ComponentProps<typeof Button>['variant']
}

export default function EmailForm({
  onSubmit,
  initialValue,
  buttonVariant,
}: EmailFormProps) {
  const { t } = useTranslation()

  return (
    <Form onSubmit={onSubmit} initialValues={initialValue}>
      {({ handleSubmit, submitSucceeded, submitting }) =>
        submitSucceeded ? (
          <Success />
        ) : (
          <form onSubmit={handleSubmit}>
            <Field
              name="email"
              label={t('Email address')}
              component={FFTextInput}
              placeholder={t('E.g. john@email.co.uk')}
              validate={email}
            />
            <Button
              type="submit"
              className="mt-4 w-full"
              loading={submitting}
              variant={buttonVariant}
            >
              {t('Send')}
            </Button>
            <FormError />
          </form>
        )
      }
    </Form>
  )
}
