import { Box, Button, Modal, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Flow from './Flow'

function Refund({ transaction, ...rest }) {
  const { data } = transaction
  const modal = React.useRef()
  const { t } = useTranslation()
  const isLoanPayoutUnfunded =
    data?.paymentPlan?.lender?.payoutType === 'unfunded'

  const hasManualRefund = data.paymentPlan?.payments?.some(
    p => p.metadata.is_manual_ad_hoc_sheet_refund
  )

  const isAvailable =
    [
      'funds_on_the_way',
      'settled',
      'loan_started',
      'payout_scheduled',
      'fulfillment_required',
    ].includes(data.state) &&
    data.isPOT &&
    !isLoanPayoutUnfunded &&
    data.status !== 'first_payment_captured' &&
    data.revisedPrice !== 0 &&
    !(data.refunds?.length > 0)

  const isFrozen = data?.flags?.is_frozen || false

  return isAvailable ? (
    <View width={['100%', 'auto']} {...rest}>
      <Modal ref={modal}>
        {({ isOpen, close }) =>
          isOpen ? (
            <Box
              boxShadow={1}
              mx={['auto']}
              mb={10}
              maxWidth={['95%', '460px']}
              p={['20px', '40px']}
            >
              <Flow onClose={close} transaction={data} />
            </Box>
          ) : null
        }
      </Modal>
      <Button
        variant="secondary"
        sizeVariant="sm"
        onClick={() => modal.current.open()}
        disabled={
          (data?.isTabeoPlus &&
            ['payout_scheduled', 'fulfillment_required'].includes(
              data.state
            )) ||
          data?.isIFinance ||
          isFrozen ||
          hasManualRefund
        }
        tooltipProps={{
          title: isFrozen
            ? t(
                'Refund transaction is currently not available. Please try again later!'
              )
            : hasManualRefund
            ? t(
                'This transaction has been partially refunded manually and cannot be further refunded through the application.'
              )
            : '',
          maxWidth: '320px',
        }}
        width="100%"
      >
        {t('Refund transaction')}
      </Button>
    </View>
  ) : null
}

Refund.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default Refund
