import { Breadcrumb, Title, View } from '@tabeo/scarf'
import LayoutSideNavbar from 'components/layouts/LayoutSideNavbar'
import Subpage from 'components/layouts/Subpage'
import TwoColumns, { Column } from 'components/layouts/TwoColumns'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { usePayout } from 'resources/Payout'
import ActionPanel from './ActionPanel'
import BeneficiaryPanel from './BeneficiaryPanel'
import OverviewPanel from './OverviewPanel'
import TransactionsPanel from './TransactionsPanel'

function Payout() {
  const { data } = usePayout()
  const { t } = useTranslation()

  return (
    <LayoutSideNavbar isLoading={!data}>
      {data && (
        <Subpage
          renderBreadcrumb={props => (
            <Breadcrumb {...props} mb={4}>
              <Breadcrumb.Item as={Link} to="/payouts">
                {t('Payouts')}
              </Breadcrumb.Item>
              <Breadcrumb.Item as={Link} to={`/payouts/${data?.id}`}>
                {data?.displayID}
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
        >
          <View mb={6}>
            <Title>{t('Payout {{id}}', { id: data.displayID })}</Title>
          </View>
          <TwoColumns>
            <Column>
              <ActionPanel data={data} />
              <OverviewPanel data={data} />
              <TransactionsPanel data={data} data-testid="transactions-panel" />
            </Column>
            <Column>
              <BeneficiaryPanel data={data} />
            </Column>
          </TwoColumns>
        </Subpage>
      )}
    </LayoutSideNavbar>
  )
}

export default Payout
