import {
  GridTableCell as Cell,
  DataTable,
  GridTable,
  GridTableRow,
  themeGet,
} from '@tabeo/scarf'
import SubscriptionStateTag from 'components/SubscriptionStateTag'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  ACTIVE_STATUSES,
  INACTIVE_STATUSES,
  INCOMPLETE_STATUSES,
  SENT_STATUSES,
} from 'resources/normalizers/subscription'
import { useSubscriptions } from 'resources/Subscriptions'
import styled from 'styled-components'

const Row = styled(GridTableRow)`
  & > * {
    border-top: 1px solid ${themeGet('colors.sky.1')};
  }
  &:hover > * {
    background: ${themeGet('colors.sky.3', 'white')};
  }
`

function ListItem({ data, ...rest }) {
  const {
    id,
    consumerFirstName,
    consumerLastName,
    createdAt,
    state,
    agreementAcceptedAt,
  } = data
  const { t } = useTranslation()

  return (
    <Row as={Link} to={`/subscriptions/${id}`} {...rest}>
      <Cell>
        {consumerFirstName} {consumerLastName}
      </Cell>
      <Cell>
        <SubscriptionStateTag state={state} small />{' '}
      </Cell>
      <Cell textAlign="right" color="ink.2">
        {agreementAcceptedAt
          ? t(`Since {{date}}`, {
              date: moment(agreementAcceptedAt).format('DD MMM YYYY'),
            })
          : moment(createdAt).format('DD MMM YYYY')}
      </Cell>
    </Row>
  )
}

ListItem.propTypes = {
  data: PropTypes.object.isRequired,
}

function Subscriptions({ plan, ...rest }) {
  const { id } = plan
  const { t } = useTranslation()
  return (
    <DataTable
      tabs={[
        { label: t('All'), statuses: [], group: 'singleSelect' },
        { label: t('Sent'), statuses: SENT_STATUSES },
        { label: t('Pending'), statuses: INCOMPLETE_STATUSES },
        { label: t('Active'), statuses: ACTIVE_STATUSES },
        { label: t('Paused'), statuses: INACTIVE_STATUSES },
      ]}
      mapStateToParams={s => {
        return {
          ...s,
          filters: {
            planID: id,
          },
        }
      }}
      resourceHook={useSubscriptions}
      paginationLimit={20}
      {...rest}
    >
      {({ items }) => (
        <GridTable templateColumns={['repeat(3, 1fr)']}>
          {items.map(item => (
            <ListItem key={item.id} data={item} />
          ))}
        </GridTable>
      )}
    </DataTable>
  )
}

Subscriptions.propTypes = {
  plan: PropTypes.object.isRequired,
}

export default Subscriptions
