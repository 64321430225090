import React from 'react'
import ScarfProps from '../../../types/ScarfProps'
import { Container, Content, Id, Text, Tick } from './components'
import EntityContext from './EntityContext'

export type EntityProps = ScarfProps & {
  color?: string
  selected?: boolean
  hover?: boolean
  disabled?: boolean
  linked?: boolean
}

function Entity({
  children,
  color = 'default.primary',
  selected,
  hover,
  disabled,
  linked,
  ...rest
}: EntityProps) {
  return (
    <EntityContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ color, selected, hover, disabled, linked }}
    >
      <Container {...rest}>{children}</Container>
    </EntityContext.Provider>
  )
}

Entity.Id = Id
Entity.Content = Content
Entity.Text = Text
Entity.Tick = Tick

export default Entity
