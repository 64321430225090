import React, { useContext } from 'react'
import ScarfProps from '../../../../types/ScarfProps'
import Flex from '../../../Flex'
import EntityContext from '../EntityContext'

function Content({ children, ...rest }: ScarfProps) {
  const { hover, disabled } = useContext(EntityContext)

  return (
    <Flex
      justifyContent="flex-start"
      flexGrow={1}
      color={disabled ? 'sky.0' : hover ? 'white' : 'default.ink'}
      minWidth="0px"
      {...rest}
    >
      {children}
    </Flex>
  )
}

export default Content
