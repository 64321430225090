import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import {
  View,
  Caption,
  Panel,
  Table as BaseTable,
  TR as BaseTR,
  TD,
  TBody,
  Tag,
  themeGet,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'

import TransactionStateTag from 'components/TransactionStateTag'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const TR = styled(BaseTR)`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${themeGet('colors.sky.1')};
  }
  &:hover {
    background: ${themeGet('colors.sky.3', 'white')};
  }
`

const ListItem = ({ data }) => {
  const { t } = useTranslation()
  return (
    <TR bg="white" as={Link} to={`/transactions/${data.id}`}>
      <TD width={['auto', '200px']}>
        <Caption color="ink.1">
          {data.consumerFirstName} {data.consumerLastName}
        </Caption>
      </TD>
      <TD width={['auto', '80px']}>
        <Tag small>{data.isPOT ? t('Finance') : t('Card')}</Tag>
      </TD>
      <TD width="115px">
        <Caption color="ink.2">{format(data.price)}</Caption>
      </TD>
      <TD width={['auto', '125px']}>
        <TransactionStateTag state={data.state} small />
      </TD>
    </TR>
  )
}

ListItem.propTypes = {
  data: PropTypes.object.isRequired,
}

const Container = styled(View)`
  overflow: auto;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
`

const Table = styled(BaseTable)`
  table-layout: fixed;
`

const TransactionsPanel = ({ offers = [], ...rest }) => {
  const { t } = useTranslation()
  return (
    <Panel title={t('Transactions')} {...rest}>
      <View bg="white">
        {offers.length ? (
          <Container>
            <Table minWidth="auto">
              <TBody>
                {offers.map(offer => (
                  <ListItem key={offer.id} data={offer} />
                ))}
              </TBody>
            </Table>
          </Container>
        ) : (
          <Caption textAlign="center" py={3} px={5}>
            {t('There are no transactions associated with this lead')}
          </Caption>
        )}
      </View>
    </Panel>
  )
}

TransactionsPanel.propTypes = {
  offers: PropTypes.array.isRequired,
}

export default TransactionsPanel
