import { Button, usePromise } from '@tabeo/scarf'
import ActionCard from 'components/ActionPanel/ActionCard'
import review from 'components/ActionPanel/assets/review.svg'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import getBaseURL from 'utils/baseUrl'
import SendReminder from './SendReminder'

function New({ resource }) {
  const { data, setStatus } = resource
  const {
    createdAt,
    firstName,
    product: { name, type },
    accessToken,
  } = data
  const [triggerQualify, qulifyStatus] = usePromise(() =>
    setStatus('qualified')
  )
  const [triggerClose, closeStatus] = usePromise(() =>
    setStatus('disqualified')
  )
  const {
    data: { slug },
  } = useMerchant()
  const { t } = useTranslation()
  return (
    <>
      <ActionCard
        icon={review}
        title={t('New lead created')}
        body={t(
          `{{leadFirstName}} has enquired about getting an {{treatmentName}} treatment {{elapsedTime}}.`,
          {
            leadFirstName: firstName,
            treatmentName: name,
            elapsedTime: moment().to(createdAt),
          }
        )}
        actions={
          <>
            <Button
              sizeVariant="sm"
              onClick={triggerQualify}
              mr={[0, 4]}
              mb={[4, 0]}
              loading={qulifyStatus.pending}
            >
              {t('Qualify lead')}
            </Button>
            <Button
              variant="secondary"
              sizeVariant="sm"
              as="a"
              target="_blank"
              href={`${getBaseURL()}/${slug}/additional-info/${accessToken}`}
              mr={[0, 4]}
              mb={[4, 0]}
            >
              {t('Pre-assessment')}
            </Button>
            <SendReminder
              firstName={firstName}
              productType={type}
              onSubmit={resource.sendReminder}
              mr={[0, 4]}
              mb={[4, 0]}
            />
            <Button
              sizeVariant="sm"
              variant="secondary"
              color="ink.2"
              onClick={() => triggerClose()}
              loading={closeStatus.pending}
              mr={[0, 4]}
              mb={[4, 0]}
            >
              {t('Close')}
            </Button>
          </>
        }
      />
    </>
  )
}

New.propTypes = {
  resource: PropTypes.object.isRequired,
}

export default New
