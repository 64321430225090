import { Payout } from '@tabeo/ts-types'
import Succeeded from './Succeeded'

function ActionPanel({ data }: { data: Payout }) {
  if (new Date(data.arrivalDate) < new Date()) {
    return <Succeeded />
  }

  return null
}

export default ActionPanel
