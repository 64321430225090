import { themeGet } from '@styled-system/theme-get'
import React from 'react'
import styled from 'styled-components'
import { InputProp, MetaProp } from '../../types/form'
import ScarfProps from '../../types/ScarfProps'
import Flex from '../Flex'
import Alert from '../Icons/Alert'
import Text, { Caption, Prompt } from '../Text'
import View from '../View'

const FieldContainer = styled(View)`
  &:last-of-type + button {
    margin-top: 4px;
  }
`

const ErrorMessage = styled(Text)``

ErrorMessage.defaultProps = {
  lineHeight: 2,
  color: 'red.2',
}

const AlertIcon = styled(Alert).attrs((props) => ({
  color: themeGet('colors.default.red')(props),
  width: 22,
  height: 22,
}))`
  display: inline-block;
  vertical-align: bottom;
  margin-right: 4px;
`

export type InnerComponentProps<T> = {
  label?: React.ReactNode
  subLabel?: React.ReactNode
  description?: React.ReactNode
  component: React.ComponentType<T>
  meta?: Partial<MetaProp>
  input?: Partial<InputProp>
  containerProps?: ScarfProps
  errorProps?: ScarfProps
  actions?: (props: {
    meta?: Partial<MetaProp>
    disabled?: boolean
  }) => React.ReactNode
  disabled?: boolean
}

export type FieldProps<T> = T &
  InnerComponentProps<T> & {
    ref?: React.Ref<any>
  }

const Field: <T>(props: FieldProps<T>) => JSX.Element | null = React.forwardRef<
  any,
  InnerComponentProps<any>
>(
  (
    {
      label,
      subLabel,
      description,
      component: Component,
      meta = {},
      containerProps = {},
      errorProps = {},
      actions,
      ...rest
    },
    ref
  ) => (
    <FieldContainer mb={5} {...containerProps} data-testid="field">
      {label && (
        <Prompt mb={1} fontWeight="semibold">
          {label}
        </Prompt>
      )}
      {subLabel && <Caption mb={2}>{subLabel}</Caption>}
      <Component ref={ref} meta={meta} {...rest} />
      {((meta.error && meta.touched) ||
        (meta.submitError && !meta.dirtySinceLastSubmit)) && (
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          mt={1}
          {...errorProps}
        >
          <AlertIcon fill="default.red" />
          <ErrorMessage>{meta.error || meta.submitError}</ErrorMessage>
        </Flex>
      )}
      {actions && !rest?.input?.value && (
        <Flex justifyContent="flex-start" mt={2}>
          {actions({ meta, disabled: rest?.disabled })}
        </Flex>
      )}
      {description && <Caption mt={2}>{description}</Caption>}
    </FieldContainer>
  )
)

export default Field
