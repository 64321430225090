import React from 'react'
import styled from 'styled-components'
import ScarfProps from '../../types/ScarfProps'
import View from '../View'

export type OverlayProps = {
  isOpen: boolean
}

const Overlay = styled(View)<OverlayProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 500;

  display: flex;

  background: rgba(75, 75, 75, 0.3);

  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s;

  min-height: 100vh;
  min-height: -webkit-fill-available;

  overflow: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  ${({ isOpen }) =>
    isOpen &&
    `
    opacity: 1;
    pointer-events: all;
  `};
`

Overlay.defaultProps = {
  alignItems: ['flex-start', 'center'],
  pb: [0],
}

export type ContainerProps = {
  isOpen: boolean
}

const Container = styled(View).attrs<ContainerProps>(({ isOpen }) => ({
  mt: isOpen ? ['auto', 0] : '-99999px',
}))``

Container.defaultProps = {
  onClick: () => (evt: React.MouseEvent) => evt.stopPropagation(),
  maxHeight: ['unset', '100vh'],
  maxWidth: '100%',
  py: [5],
  mb: 3,
  flex: '1',
  position: 'relative',
  transition: 'margin 0.5s',
  width: '100%',
}

export type RenderProps = {
  isOpen: boolean
  closable?: boolean | (() => boolean)
  close: () => void
  open: (data?: any) => void
  data: any
}

export type ModalProps = {
  children?: (props: RenderProps) => React.ReactNode
  render?: (props: RenderProps) => React.ReactNode
  closable?: boolean | (() => boolean)
  isOpenByDefault?: boolean
  containerProps?: ScarfProps
  overlayProps?: ScarfProps
  onClose?: () => void
}

export default class Modal extends React.Component<
  ModalProps,
  { isOpen: boolean; data?: any }
> {
  mounted = false

  constructor(props: ModalProps) {
    super(props)
    this.state = {
      isOpen: props.isOpenByDefault || false,
    }
    if (props.isOpenByDefault) {
      this.setBodyPositions()
    }
  }

  componentDidMount() {
    this.mounted = true
    window.addEventListener('keyup', this.handleKeyUp)
  }

  componentWillUnmount() {
    this.mounted = false
    window.removeEventListener('keyup', this.handleKeyUp)
    this.cleanup()
  }

  // eslint-disable-next-line class-methods-use-this
  setBodyPositions = () => {
    // Uncommented to prevent content jump when modal opens
    // document.body.style['position'] = 'fixed'
    // document.body.style['width'] = '100%'
    document.body.style.overflow = 'hidden'
  }

  // eslint-disable-next-line class-methods-use-this
  cleanup = () => {
    // document.body.style['position'] = 'unset'
    // document.body.style['width'] = 'unset'
    document.body.style.overflow = 'unset'
  }

  handleKeyUp = ({ key }: KeyboardEvent) => {
    const { closable } = this.props
    const isClosableFunction = typeof closable === 'function'
    if (
      key === 'Escape' &&
      (closable === true || (isClosableFunction && closable()))
    ) {
      this.close()
    }
  }

  close = () => {
    const { onClose } = this.props
    onClose && onClose()

    this.mounted && this.setState({ isOpen: false })
    this.cleanup()
  }

  open = (data?: any) => {
    this.setBodyPositions()
    this.mounted && this.setState({ isOpen: true, data })
  }

  render() {
    const { children, closable, render, containerProps, overlayProps } =
      this.props
    const { isOpen, data } = this.state

    const renderProps = {
      isOpen,
      closable,
      close: this.close,
      open: this.open,
      data,
    }

    const isClosableFunction = typeof closable === 'function'
    return (
      <Overlay
        isOpen={isOpen}
        onMouseDown={() => {
          if (closable === true || (isClosableFunction && closable())) {
            this.close()
          }
        }}
        {...overlayProps}
      >
        <Container
          id="tmodal-container"
          isOpen={isOpen}
          onMouseDown={(e: React.MouseEvent) => {
            // @ts-ignore
            const isSelfClick = e.target.id === 'tmodal-container'
            if (
              (closable === true || (isClosableFunction && closable())) &&
              isSelfClick
            ) {
              this.close()
            } else {
              e.stopPropagation()
            }
          }}
          {...containerProps}
        >
          {render
            ? render(renderProps)
            : children
            ? children(renderProps)
            : null}
        </Container>
      </Overlay>
    )
  }
}

// @ts-ignore
Modal.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  closable: true,
}
