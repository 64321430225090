import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import { useRouteMatch } from 'react-router-dom'
import normalizeSub from './normalizers/subscription'

class Plan extends Resource {
  async fetch() {
    const subResources = formatSubresources([
      'planFeatures',
      'planPerks',
      'subscriptions',
      'subscriptions.stateMachineTransitions',
      'numberOfActiveSubscriptions',
    ])

    const { plan } = await api.get(
      `/merchant/plans/${this.params.id}?${subResources}`
    )
    const normalizedSubs = plan?.subscriptions.map?.(s => normalizeSub(s))
    this.data = {
      ...this.data,
      ...plan,
      subscriptions: normalizedSubs,
    }
    return this.data
  }

  updatePlan = async payload => {
    const { title, subtitle, description, imageURL, accessibility, price } =
      this.data

    const response = await api.put(`/merchant/plans/${this.params.id}`, {
      title,
      subtitle,
      description,
      imageURL,
      accessibility,
      price,
      ...payload,
    })

    await this.fetch()

    return response
  }

  deletePlan = async () => {
    const response = await api.delete(`/merchant/plans/${this.params.id}`, {})

    return response
  }
}

export default Plan
export const useResource = createResourceHook(Plan)
export function usePlan({ id } = {}) {
  const match = useRouteMatch({
    path: ['/plans/:planId'],
  })

  const {
    params: { planId },
  } = match || { params: {} }

  const planIdParam = id || planId

  return useResource(() => {
    if (!planIdParam) {
      throw new Error('Plan ID is null')
    }
    return {
      id: Number.isNaN(Number(planIdParam)) ? planIdParam : +planIdParam,
    }
  })
}
