import {
  Box,
  Button,
  Flex,
  Modal,
  Subheading,
  Subtitle,
  Text,
  View,
} from '@tabeo/scarf'
import plane from 'assets/airplane.svg'
import check from 'assets/check.svg'
import AutoCopyText from 'components/AutoCopyText'
import config from 'config'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Icon = styled('img')`
  margin: 12px auto 30px;
  display: block;
`

const { ENV } = config

const SuccessModal = ({ innerRef }) => {
  const { t } = useTranslation()
  return (
    <Modal ref={innerRef}>
      {({ isOpen, close, data }) => {
        const titles = {
          pay_over_time: data?.skipInitialPaymentOptionsEmail
            ? t('Finance transaction created')
            : t('Finance request sent'),
          pay_now: data?.skipInitialPaymentOptionsEmail
            ? t('Transaction created')
            : t('Payment request sent'),
          bill: t('Payment request sent'),
          plan: t('Subscription request sent'),
        }
        return isOpen ? (
          <Box
            boxShadow={1}
            mx={['auto']}
            mb={10}
            p={10}
            maxWidth={['95%', '420px']}
            textAlign="center"
          >
            <Icon src={data?.skipInitialPaymentOptionsEmail ? check : plane} />
            <Subtitle fontWeight="semibold" mb={3}>
              {titles[data.type] || titles.pay_over_time}
            </Subtitle>
            {['staging', 'development'].includes(ENV) &&
              ['pay_over_time', 'pay_now'].includes(data.type) && (
                <>
                  <Text mb={1}>
                    {data?.skipInitialPaymentOptionsEmail
                      ? t(`You can share the link below with the customer`)
                      : t(`The customer will receive an email shortly`)}
                  </Text>
                  {data && (
                    <View mt={4}>
                      <AutoCopyText value={data?.publicLink} />
                      <Flex mt={3}>
                        <View height="1px" bg="sky.1" flex="1" />
                        <Subheading mx="4" color="ink.2">
                          {t('OR')}
                        </Subheading>
                        <View height="1px" bg="sky.1" flex="1" />
                      </Flex>
                    </View>
                  )}
                </>
              )}
            {data.isPOT && !data.isDeferredPaymentPlan && (
              <Button
                as="a"
                href={`/transactions/${data.id}/print`}
                target="_blank"
                width="100%"
                variant="secondary"
                mt={4}
              >
                {t('Print a copy')}
              </Button>
            )}
            {['pay_over_time', 'pay_now'].includes(data.type) && (
              <Button
                as={Link}
                to={`/transactions/${data.id}`}
                variant="secondary"
                width="100%"
                mt={4}
              >
                {t('Go to transaction')}
              </Button>
            )}
            {['plan'].includes(data.type) && (
              <Button
                as={Link}
                to={`/subscriptions/${data.id}`}
                variant="secondary"
                width="100%"
                mt={4}
              >
                {t('View subscription')}
              </Button>
            )}
            {['bill'].includes(data.type) && (
              <Button
                as={Link}
                to={`/bills/${data.id}`}
                width="100%"
                variant="secondary"
                mt={4}
              >
                {t('View bill')}
              </Button>
            )}
            <Button onClick={close} width="100%" mt={4}>
              {t('Close')}
            </Button>
          </Box>
        ) : null
      }}
    </Modal>
  )
}

SuccessModal.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
}

export default SuccessModal
