import React from 'react'
import ScarfProps from '../../../types/ScarfProps'
import Flex from '../../Flex'
import {
  ActionButton,
  Actions,
  Description,
  Id,
  Main,
  Meta,
  MetaItem,
  Sidebar,
  State,
  Title,
} from './components'
import TicketContext from './TicketContext'

export type TicketDetailsProps = ScarfProps

function TicketDetails({ children, ...rest }: TicketDetailsProps) {
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <TicketContext.Provider value={{}}>
      <Flex alignItems="stretch" {...rest}>
        {children}
      </Flex>
    </TicketContext.Provider>
  )
}

TicketDetails.Id = Id
TicketDetails.Title = Title
TicketDetails.Description = Description
TicketDetails.Actions = Actions
TicketDetails.Main = Main
TicketDetails.ActionButton = ActionButton
TicketDetails.Meta = Meta
TicketDetails.MetaItem = MetaItem
TicketDetails.Sidebar = Sidebar
TicketDetails.State = State

export default TicketDetails
