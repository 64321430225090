import { Button, Caption, Flex, Heading, View } from '@tabeo/scarf'
import Card from 'components/Transaction/ActionPanel/Card'
import TreatmentModal from 'components/TreatmentModal'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import icon from './assets/payout_on_the_way.svg'
import InvoiceButton from './InvoiceButton'
import RefundButton from './Refund'

function PayoutScheduled({ transaction, ...rest }) {
  const { data, createOrUpdateTreatment } = transaction
  const modal = useRef()
  const { merchantPayoutAt, merchantProceedsTransferredAt } = data

  const {
    data: { bankDetails },
  } = useMerchant()
  const { t } = useTranslation()

  if (!merchantPayoutAt || !merchantProceedsTransferredAt) {
    return null
  }

  const transferMoment = moment(merchantProceedsTransferredAt)
  const arrivalMoment = moment(merchantPayoutAt)
  const accountNumber = bankDetails.accountNumber.slice(-3)

  return (
    <>
      <TreatmentModal
        ref={modal}
        onSubmit={createOrUpdateTreatment}
        offer={data}
      />
      <Card {...rest}>
        <Flex justifyContent="flex-start" mb={4}>
          <View as="img" src={icon} mr={4} />
          <Heading fontWeight="semibold">
            {t('Payout scheduled for {{date}}', {
              date: transferMoment.format('DD MMM YYYY'),
            })}
          </Heading>
        </Flex>
        <Caption color="ink.1" mb={4}>
          {t(
            'Your payout will be issued on {{transferDate}} and will arrive in your bank account ending in {{accountNumber}} by {{arrivalDate}}.',
            {
              accountNumber,
              transferDate: transferMoment.format('DD MMM YYYY'),
              arrivalDate: arrivalMoment.format('DD MMM YYYY'),
            }
          )}
        </Caption>
        <Flex
          flexDirection={['column', 'row']}
          justifyContent="flex-start"
          alignItems={['stretch', 'center']}
        >
          <InvoiceButton mr={[0, 4]} mb={[4, 0]} />
          <RefundButton mr={[0, 4]} mb={[4, 0]} transaction={transaction} />
          <Button
            variant="secondary"
            sizeVariant="sm"
            mr={[0, 4]}
            mb={[4, 0]}
            onClick={() => modal.current.open()}
          >
            {t('Update treatment details')}
          </Button>
        </Flex>
      </Card>
    </>
  )
}

PayoutScheduled.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default PayoutScheduled
