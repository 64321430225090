import { Portal } from '@headlessui/react'
import { Modal } from '@tabeo/scarf'
import { useEffect, useRef } from 'react'
import { useAuth } from 'resources/Auth'
import { useMerchant } from 'resources/Merchant'
import TermsUpdateModal from './Modal'

export default function TermsUpdate() {
  const modalId = 'merchantTermsAndConditionsUpdatesV13'

  const modal = useRef<Modal>(null)
  const { data } = useAuth()
  const { data: merchant } = useMerchant()

  const modals = data?.user?.metadata?.modals

  const { newTermsAndConditions } = merchant || { newTermsAndConditions: [] }
  const doc = newTermsAndConditions[0]

  useEffect(() => {
    const m = modals?.[modalId]

    if (!m) {
      return
    }

    if (
      doc &&
      new Date(m.displayAt) < new Date() &&
      !['expired'].includes(modals[modalId]?.status)
    ) {
      setTimeout(() => {
        modal.current?.open()
      }, 200)
    }
  }, [modals, modalId, doc])

  return (
    <Portal>
      <TermsUpdateModal modalId={modalId} ref={modal} />
    </Portal>
  )
}
