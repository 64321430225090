import React, { useState } from 'react'
import { Merge } from '../../types/helpers'
import ScarfProps from '../../types/ScarfProps'
import { Collapsible, Container, Header, Highlight, Row } from './components'
import ModuleContext, { ModuleContextType } from './ModuleContext'

export type ModuleProps = Merge<
  ScarfProps,
  {
    initialCollapsible?: Record<string, boolean>
    children: ((value: ModuleContextType) => React.ReactNode) | React.ReactNode
  }
>

function Module({
  color = 'sky.1',
  children,
  initialCollapsible = {},
  ...rest
}: ModuleProps) {
  const [collapsible, setCollapsible] = useState(initialCollapsible)

  const handleChange = (id = 'default', value = !collapsible[id]) => {
    setCollapsible((prev) => ({
      ...prev,
      [id]: value,
    }))
  }

  const handleCloseAll = () => {
    setCollapsible((state) =>
      Object.keys(state).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    )
  }

  return (
    <ModuleContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        collapsible,
        open: (id) => handleChange(id, true),
        close: (id) => handleChange(id, false),
        toggle: (id) => handleChange(id),
        closeAll: handleCloseAll,
        color,
      }}
    >
      <Container boxShadow={[null, 1]} {...rest}>
        {typeof children === 'function' ? (
          // @ts-ignore
          <ModuleContext.Consumer>{children}</ModuleContext.Consumer>
        ) : (
          children
        )}
      </Container>
    </ModuleContext.Provider>
  )
}

Module.Header = Header
Module.Row = Row
Module.Collapsible = Collapsible
Module.Highlight = Highlight
Module.Context = ModuleContext

export default Module
