import { ExtendedLoanOption } from 'pages/NNTS/types'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useField, useForm } from 'react-final-form'
import { useMerchant } from 'resources/Merchant'
import { useNewTransactionFlow } from '../provider'
import { useCardOptions } from './Cards'
import { getValueForLoanOption } from './FinanceRadioCard'
import useFinanceOptions from './useFinanceOptions'

function AutoSelectPaymentMethod() {
  const { data: merchant } = useMerchant()

  const cardOptions = useCardOptions(merchant)

  const financeOptions = useFinanceOptions(merchant)

  // [finance-*, terminal, link, bill]
  const orderedOptionValues = useMemo(() => {
    const { priorityLoanOption, otherLoanOptions, longestTermLoanOption } =
      financeOptions[1]

    const orderedLoanOptions = [
      priorityLoanOption,
      ...otherLoanOptions,
      longestTermLoanOption,
    ].filter(Boolean) as ExtendedLoanOption[]

    const optionValues = [
      ...orderedLoanOptions
        ?.filter(o => !o.disabled)
        .map(getValueForLoanOption),
      ...cardOptions.filter(o => !o.disabled).map(({ value }) => value),
    ].filter(Boolean)

    return optionValues
  }, [cardOptions, financeOptions])

  const { resetFieldState, focus } = useForm()

  // Force focus state on price field on initial render
  useEffect(() => {
    focus('price')
  }, [focus])

  const { input, meta } = useField('paymentMethod', {
    subscription: {
      value: true,
      initial: true,
      visited: true,
    },
  })
  const { value: paymentMethod, onChange } = input

  const hadInitialValue = useRef(!!meta.initial)
  const lastUserSelectedPaymentMethod = useRef(meta.initial)

  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const selectPaymentMethod = useCallback(
    (price: number) => {
      const setValue = (v: string | null | undefined) => {
        if (v === paymentMethod) {
          return
        }

        // Save last user selection
        if (meta.visited) {
          lastUserSelectedPaymentMethod.current = paymentMethod
        }

        // Delay the change to let field render
        // https://github.com/final-form/react-final-form/issues/626
        setTimeout(() => {
          onChange(v)
          // Reset state to track user / code triggered changes if we are still mounted
          if (isMounted.current) {
            resetFieldState('paymentMethod')
          }
        }, 0)
      }

      // Reset payment method if price is cleared
      if (!price) {
        setValue(undefined)
        return
      }

      if (
        // Initial option auto selection
        !paymentMethod ||
        // Rerun selection: bill => finance
        (!meta.visited && !hadInitialValue.current) ||
        // Dropped user selection, try to restore it
        (!meta.visited && lastUserSelectedPaymentMethod.current)
      ) {
        // Restore previous user selection if it's available
        if (
          orderedOptionValues.includes(lastUserSelectedPaymentMethod.current)
        ) {
          setValue(lastUserSelectedPaymentMethod.current)
          return
        }

        setValue(orderedOptionValues[0])
        return
      }

      // Reset payment method if item is no longer available
      if (!orderedOptionValues.includes(paymentMethod)) {
        setValue(orderedOptionValues[0])
      }
    },
    [
      meta.visited,
      onChange,
      paymentMethod,
      resetFieldState,
      orderedOptionValues,
    ]
  )

  const { state } = useNewTransactionFlow()
  const { price } = state.payment || {}

  const previousPrice = useRef()

  useEffect(() => {
    if (previousPrice.current !== price) {
      selectPaymentMethod(price)
    }

    previousPrice.current = price
  }, [price, selectPaymentMethod])

  return null
}

export default AutoSelectPaymentMethod
