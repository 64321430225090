import React from 'react'
import styled from 'styled-components'
import ScarfProps from '../types/ScarfProps'
import Flex from './Flex'
import { Prompt } from './Text'
import View from './View'

const Title = styled(Prompt)``
Title.defaultProps = {
  color: 'ink.0',
  fontWeight: 'semibold',
  mr: [1, 2],
}

export type PanelProps = ScarfProps & {
  title?: React.ReactNode
  header?: React.ReactNode
  Header?: React.ReactNode
}

const Panel = ({ title, Header, header, children, ...rest }: PanelProps) => {
  if (Header) {
    // eslint-disable-next-line no-console
    console.warn(
      'Using Header in Panel is depricated please use header instead.'
    )
  }
  const headerElement = Header || header
  return (
    <View
      boxShadow={1}
      borderRadius={1}
      border={1}
      overflow="hidden"
      bg="white"
      {...rest}
    >
      <Flex
        bg="white"
        minHeight={['40px', '56px']}
        // ie 11 bug: https://github.com/philipwalton/flexbugs/issues/231
        height="1px"
        pl={[4, 5]}
        pr={[3]}
        borderRadius="4px 4px 0 0"
        borderBottom={1}
      >
        {headerElement ||
          (typeof title === 'string' ? <Title>{title}</Title> : title)}
      </Flex>
      {children}
    </View>
  )
}

Panel.Title = Title

export default Panel
