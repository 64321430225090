import React, { useContext } from 'react'
import ScarfProps from '../../../../types/ScarfProps'
import Flex from '../../../Flex'
import * as Icons from '../../../Icons'
import Text from '../../../Text'
import EntityContext from '../EntityContext'

function Id({ children, ...rest }: ScarfProps) {
  const { color, hover, disabled } = useContext(EntityContext)

  return (
    <Flex
      display="inline-flex"
      justifyContent="flex-start"
      color={disabled ? 'sky.0' : hover ? 'white' : color}
      mr={3}
      {...rest}
    >
      <Icons.Id
        fill="currentColor"
        height={['25px', '22px']}
        width={['25px', '22px']}
        bg={disabled ? 'white' : hover ? color : 'white'}
        mb={['1px', 0]}
      />
      <Text
        fontSize={['16px', '14px']}
        color="currentColor"
        minWidth={['45px', '40px']}
      >
        {children}
      </Text>
    </Flex>
  )
}

export default Id
