import React from 'react'
import Svg, { G, SvgProps } from './Base'

function Clear({ fill, ...props }: SvgProps) {
  return (
    <Svg {...props}>
      <G
        stroke="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fill}
      >
        <path d="M0,11c0,-6.075 4.925,-11 11,-11c6.075,0 11,4.925 11,11c0,6.075 -4.925,11 -11,11c-6.075,0 -11,-4.925 -11,-11Zm11,-1.111l-2.98,-2.98c-0.307,-0.307 -0.805,-0.307 -1.111,0c-0.307,0.307 -0.307,0.804 0,1.111l2.98,2.98l-2.98,2.98c-0.307,0.307 -0.307,0.804 0,1.111c0.306,0.307 0.804,0.307 1.111,-0l2.98,-2.98l2.98,2.98c0.307,0.307 0.804,0.307 1.111,-0c0.307,-0.307 0.307,-0.804 -0,-1.111l-2.98,-2.98l2.98,-2.98c0.307,-0.307 0.307,-0.804 -0,-1.111c-0.307,-0.307 -0.804,-0.307 -1.111,0l-2.98,2.98Z" />
      </G>
    </Svg>
  )
}

export default Clear
