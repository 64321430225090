import {
  Button,
  ButtonGroup,
  Clickable,
  Modal,
  Text,
  Title,
  View,
} from '@tabeo/scarf'
import moment from 'moment'
import React, { ComponentPropsWithoutRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'resources/Auth'
import illustration from './assets/terms.png'

export type TermsUpdateModalProps = ComponentPropsWithoutRef<typeof Modal> & {
  modalId: string
}

const TermsUpdateModal = React.forwardRef<Modal, TermsUpdateModalProps>(
  ({ modalId, ...rest }, ref) => {
    const { resource } = useAuth()
    const { patchModalValue } = resource as any

    const history = useHistory()

    return (
      <Modal ref={ref} closable={false} {...rest}>
        {({ isOpen, close }) => {
          const handleRemindMe = () => {
            patchModalValue(modalId, {
              displayAt: moment().add(1, 'day').toISOString(),
            })
            close()
          }

          return isOpen ? (
            <View
              maxWidth={['90%', '620px']}
              mx="auto"
              bg="white"
              textAlign="center"
              borderRadius={1}
              boxShadow={1}
              pt={[10, 10]}
              pb={[5, 10]}
              px={[4, 10]}
            >
              <View
                as="img"
                src={illustration}
                width={['132px', '200px']}
                height={['120px', '180px']}
                mx="auto"
              />
              <Title mt={6}>Updates to our Merchant T&Cs</Title>
              <Text mt={6} textAlign="left" fontSize={1}>
                We&apos;re updating our Merchant Terms and Conditions effective
                from <b>4 July 2024</b>. We recently emailed you to explain
                what&apos;s new.
                <br />
                <br />
                Click below to review and accept. If you have not opted-in, we
                may assume from your continued use of our platform that you
                agree to the changes. If you have any questions, contact{' '}
                <Clickable
                  fontWeight="medium"
                  display="inline"
                  as="a"
                  href="mailto:growth@tabeo.co.uk"
                  target="_blank"
                >
                  growth@tabeo.co.uk
                </Clickable>
                .
              </Text>
              <ButtonGroup width="100%" mt={10}>
                <Button
                  variant="secondary"
                  width="100%"
                  onClick={handleRemindMe}
                >
                  Remind me tomorrow
                </Button>
                <Button
                  width="100%"
                  onClick={() => {
                    handleRemindMe()
                    history.push('/accept-terms')
                  }}
                >
                  View & Accept
                </Button>
              </ButtonGroup>
            </View>
          ) : null
        }}
      </Modal>
    )
  }
)

export default TermsUpdateModal
