import { Button, Caption, Flex, Heading, View } from '@tabeo/scarf'
import FieldError from 'components/Form/FieldError'
import Card from 'components/Transaction/ActionPanel/Card'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import icon from '../../Transaction/ActionPanel/assets/sent.svg'

function Sent({
  data,
  cancelState,
  updateState,
  cancelModal,
  updateModal,
  ...rest
}) {
  const { consumerFirstName, createdAt } = data
  const { t } = useTranslation()

  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Bill sent')}</Heading>
      </Flex>
      <Caption color="ink.1" mb={4}>
        {t(
          '{{consumerFirstName}} has received this bill {{elapsedTime}}, but hasn’t opened the link yet.',
          { consumerFirstName, elapsedTime: moment().to(createdAt) }
        )}
      </Caption>
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="flex-start"
        alignItems={['stretch', 'center']}
      >
        <Button
          variant="secondary"
          sizeVariant="sm"
          onClick={() => updateModal.current.open()}
          mr={[0, 4]}
          mb={[4, 0]}
        >
          {t('Change payment date')}
        </Button>
        <Button
          variant="secondary"
          sizeVariant="sm"
          color="ink.2"
          mr={[0, 4]}
          mb={[4, 0]}
          onClick={() => cancelModal.current.open()}
        >
          {t('Cancel bill')}
        </Button>
      </Flex>
      {(cancelState.error || updateState.error) && (
        <FieldError
          message={t('There was an error, please try again later.')}
          mt={4}
        />
      )}
    </Card>
  )
}

Sent.propTypes = {
  data: PropTypes.object.isRequired,
  cancelState: PropTypes.object.isRequired,
  updateState: PropTypes.object.isRequired,
  cancelModal: PropTypes.object.isRequired,
  updateModal: PropTypes.object.isRequired,
}

export default Sent
