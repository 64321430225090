import config from 'config'

const { CURRENCY } = config
const normalizer = ({ person, staffPerson, ...values }) => {
  return {
    ...values,
    title: values.title,
    otherTitle: values.otherTitle,
    patientName: values.petName
      ? `${values.petName} ${values.consumerLastName}`
      : `${values.consumerFirstName} ${values.consumerLastName}`,
    appointment: {
      person,
    },
    staffPerson: staffPerson
      ? {
          name: staffPerson,
        }
      : undefined,
    currency: CURRENCY,
  }
}
// TODO: check currency

export default normalizer
