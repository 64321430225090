import { Attributes, Panel, View } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const FinanceDetailsPanel = ({
  data: {
    paymentPlan: {
      principalAmount,
      apr,
      interestRate,
      interestAmount,
      totalAmountPayable,
      numberOfMonths,
      remainingSinglePaymentAmount,
      firstPaymentAmount,
    },
  },
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <Panel
      title={t('Selected payment plan')}
      data-testid="finance-panel"
      {...rest}
    >
      <View bg="white">
        <Attributes
          p={5}
          sections={[
            [
              {
                label: t('Amount of credit'),
                value: format(principalAmount),
              },
              {
                label: t('Term'),
                value: t(`{{numberOfMonths}} months`, { numberOfMonths }),
              },
              {
                label: t('APR (Interest p.a.)'),
                value: `${(apr * 100).toFixed(1)}% (${(
                  interestRate * 100
                ).toFixed(1)}%)`,
              },
              {
                label: t('First payment'),
                value: format(firstPaymentAmount),
              },
              {
                label: t('Monthly payments'),
                value: format(remainingSinglePaymentAmount),
              },
              {
                label: t('Total cost of credit'),
                value: format(interestAmount),
              },
              {
                label: t('Total to pay back'),
                value: format(totalAmountPayable),
              },
            ],
          ]}
        />
      </View>
    </Panel>
  )
}

FinanceDetailsPanel.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FinanceDetailsPanel
