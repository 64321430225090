import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { usePromise } from '@tabeo/scarf'
import { Button, Notification } from '@tabeo/scarf2'
import { useTranslation } from 'react-i18next'
import { useSubscriptionV2 } from 'resources/SubscriptionV2'

type DefaultProps = {
  onClose: () => void
}

export default function Default({ onClose }: DefaultProps) {
  const { t } = useTranslation()
  const { data, resource } = useSubscriptionV2()

  const [trigger, { pending }] = usePromise(async () => {
    if (resource?.cancel) {
      await resource.cancel()
    }
  })

  const benefits = [
    data?.subscription?.treatments?.map(({ treatment, usages }) => ({
      name: treatment?.name,
      quantity: treatment?.quantity,
      used: usages?.reduce((acc, curr) => acc + (curr?.quantity || 0), 0),
    })),
    data?.subscription?.perks?.map(({ perk, usages }) => ({
      name: perk?.name,
      quantity: perk?.quantity,
      used: usages?.reduce((acc, curr) => acc + (curr?.quantity || 0), 0),
    })),
  ]
    .flat()
    .filter(Boolean) as {
    name: string
    quantity?: number
    used: number
  }[]

  return (
    <div className="space-y-5">
      <h3 className="title">{t('Do you want to cancel this membership?')}</h3>
      <Notification variant="warning">
        <ExclamationTriangleIcon />
        {t(
          'Cancelling this plan will end the membership immediately, and no further payments will be taken. The customer will no longer be able to claim membership benefits from this point forward.'
        )}
      </Notification>
      <div>{t('Please review the customer’s usage before continuing:')}</div>
      <ul className="space-y-1">
        {benefits.map(b => (
          <li key={b.name} className="caption flex justify-between gap-4 px-2">
            <span className="text-tabeo-ink-2">{b.name}</span>
            <div className="text-tabeo-ink-0">
              {b.quantity ? `${b.used} / ${b.quantity}` : t('Unlimited')}
            </div>
          </li>
        ))}
      </ul>
      <div className="space-y-4">
        <Button
          variant="primary"
          className="w-full"
          loading={pending}
          onClick={trigger}
        >
          {t('Cancel membership')}
        </Button>
        <Button variant="secondary" className="w-full" onClick={onClose}>
          {t('Close')}
        </Button>
      </div>
    </div>
  )
}
