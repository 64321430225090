import { Button, FFTextInput } from '@tabeo/scarf2'
import formError from '@tabeo/sharpei/utils/formError'
import { emailPCA } from '@tabeo/sharpei/utils/validations'
import { Basket } from '@tabeo/ts-types'
import FormError from 'components/Form/FormError'
import { Field } from 'components/nnts/form/Field'
import { motion } from 'framer-motion'
import { useCallback } from 'react'
import { Form } from 'react-final-form'
import { useBasket } from 'resources/Basket'
import { usePatient } from 'resources/Patient'
import Patients from 'resources/Patients'
import { twMerge } from 'tailwind-merge'
import plane from './assets/plane.svg'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  basket: Basket
  onReceiptSend: (params: { email: string }) => Promise<void>
  buttonVariant?: React.ComponentProps<typeof Button>['variant']
}

export function SendReceiptPanel({
  basket,
  onReceiptSend,
  buttonVariant = 'secondary',
  className,
  ...rest
}: Props) {
  const { data: patient, resource: patientResource } = usePatient(() => {
    const id = basket.patient?.id

    if (!id) {
      throw new Error('Patient ID is missing')
    }

    return {
      id,
    }
  })

  const { resource: basketResource } = useBasket(() => {
    const id = basket?.id

    if (!id) {
      throw new Error('Basket ID is missing')
    }

    return {
      id,
    }
  })

  const handleSubmit = useCallback(
    async ({ email }: { email: string }) => {
      try {
        if (patient) {
          await Promise.all([
            patientResource?.update({
              ...patient,
              email,
            }),
            await onReceiptSend({ email }),
          ])
        } else {
          const patient = await Patients.create({
            email,
          })
          await basketResource?.addPatient(patient.id)
          await onReceiptSend({ email })
        }
      } catch (e) {
        return formError(e)
      }
    },
    [patient, patientResource, basketResource, onReceiptSend]
  )

  return (
    <div className={twMerge('rounded border p-4', className)} {...rest}>
      <Form onSubmit={handleSubmit} initialValues={{ email: patient?.email }}>
        {({ handleSubmit, submitting, submitSucceeded }) => (
          <div className="relative">
            <form
              className={`space-y-4 ${submitSucceeded && 'invisible'}`}
              onSubmit={handleSubmit}
            >
              <p className="subheading text-tabeo-ink-1">Send receipt to</p>
              <Field
                label="Email address"
                name="email"
                placeholder="E.g. john@email.com"
                component={FFTextInput}
                validate={emailPCA}
              />
              <Button
                type="submit"
                loading={submitting}
                className="w-full"
                variant={buttonVariant}
              >
                Send
              </Button>
              <FormError />
            </form>
            {submitSucceeded && (
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                <motion.div
                  initial={{
                    scale: 1.1,
                    x: -40,
                    y: 30,
                    opacity: 0.7,
                  }}
                  animate={{
                    scale: 1,
                    x: 0,
                    y: 0,
                    opacity: 1,
                  }}
                >
                  <img src={plane} />
                </motion.div>
                <p className="font-medium">Receipt has been sent</p>
              </div>
            )}
          </div>
        )}
      </Form>
    </div>
  )
}
