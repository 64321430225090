import { useMobile } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import { ReactNode } from 'react'
import { PaymentActions } from '../steps/payment'
import { useNewTransactionFlow } from './provider'

export function ActionButton({ children }: { children?: ReactNode }) {
  const {
    form,
    flowSteps,
    currentIndex,
    modals: { cartSummaryModalRef },
  } = useNewTransactionFlow()
  const isSummaryModalAlreadyOpen = cartSummaryModalRef.current?.state.isOpen
  const isMobile = useMobile()
  const { submitting, valid } = form.current?.getState() || {}
  const flowStep = flowSteps[currentIndex]

  // On mobile we show a confirm details button which opens the cart summary modal
  if (isMobile && !isSummaryModalAlreadyOpen) {
    return (
      <Button
        variant="primary"
        className="w-full"
        type="submit"
        onClick={() => {
          if (!valid) {
            form.current?.submit()
          } else {
            cartSummaryModalRef.current?.open({
              finished: true,
            })
          }
        }}
        loading={submitting}
      >
        {children}
      </Button>
    )
  }

  if (flowStep.isLast) {
    return <PaymentActions />
  }

  return (
    <Button
      variant="primary"
      className="w-full"
      type="submit"
      onClick={form.current?.submit}
    >
      {children}
    </Button>
  )
}
