import React, { useContext } from 'react'
import styled from 'styled-components'
import theme from '../theme'
import { Merge } from '../types/helpers'
import ScarfProps from '../types/ScarfProps'
import BaseButton, { WrapProps } from './Button'
import { Heading } from './Text'
import View from './View'

export type ColorEnum =
  | 'default'
  | 'green'
  | 'yellow'
  | keyof (typeof theme)['colors']

const CardContext = React.createContext<{
  color: ColorEnum
}>({ color: 'default' })

const customColor: Partial<Record<ColorEnum, { bg: string; border: string }>> =
  {
    default: {
      bg: 'white',
      border: 'sky.1',
    },
    green: {
      bg: 'green.6',
      border: 'green.3',
    },
    yellow: {
      bg: 'yellow.6',
      border: 'yellow.3',
    },
  }

const Title = styled(Heading)``

Title.defaultProps = {
  color: 'ink.0',
  mb: 1,
  fontWeight: 'semibold',
}

export type FooterProps = ScarfProps

function Footer(props: FooterProps) {
  const { color } = useContext(CardContext)
  const custom = customColor[color]
  const borderColor = custom ? custom.border : `${color}.4`
  return <View borderTopColor={borderColor} {...props} />
}
Footer.defaultProps = {
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  mt: '16px',
  mx: '-16px',
  mb: '-16px',
  borderRadius: '0 0 4px 4px',
}

export type ButtonProps = WrapProps

function Button(props: ButtonProps) {
  const { color } = useContext(CardContext)
  return (
    <BaseButton
      variant="transparent"
      width="100%"
      color={`${color === 'default' ? 'primary' : color}.3`}
      textDecoration="none !important"
      _hover={{
        bg: 'sky.3',
      }}
      _active={{
        bg: 'sky.2',
      }}
      {...props}
    />
  )
}

export type CardProps = Merge<
  ScarfProps,
  {
    title?: string
    color?: ColorEnum
    footer?: React.ReactNode
  }
>

const Card = ({
  title,
  color = 'default',
  children,
  footer,
  ...rest
}: CardProps) => {
  const custom = customColor[color]
  const borderColor = custom ? custom.border : `${color}.4`
  const bg = custom ? custom.bg : `${color}.6`

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CardContext.Provider value={{ color }}>
      <View
        boxShadow={1}
        borderRadius={1}
        borderWidth="1px"
        borderStyle="solid"
        borderColor={borderColor}
        bg={bg}
        p={4}
        {...rest}
      >
        {title && <Title fontWeight="normal">{title}</Title>}
        {children}
        {footer && <Footer>{footer}</Footer>}
      </View>
    </CardContext.Provider>
  )
}

Card.Title = Title
Card.Footer = Footer
Card.Button = Button

export default Card
