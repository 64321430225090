import React from 'react'
import ScarfProps from '../../types/ScarfProps'
import Flex from '../Flex'
import View from '../View'
import Builder from './Builder'
import Item from './Item'

export interface FeedItem {
  id: string | number
}

export type FeedProps<T extends FeedItem> = ScarfProps & {
  history: T[]
  renderIcon: (item: T) => React.ReactNode
  renderTitle: (item: T) => React.ReactNode
  renderState?: (item: T) => React.ReactNode
  renderDate: (item: T) => React.ReactNode
}

/**
 * Legacy Feed component
 * TODO: Cleanup if feeds are migrated
 */
function Feed<T extends FeedItem>({
  history,
  renderIcon,
  renderTitle,
  renderState,
  renderDate,
  ...rest
}: FeedProps<T>) {
  return (
    <View p={4} {...rest}>
      {history.map((h, index) => (
        <Flex
          alignItems="stretch"
          key={h.id}
          _notFirst={{
            mt: '6px',
          }}
          _last={{
            mb: -3,
          }}
        >
          <Flex flexDirection="column" mr={3}>
            {renderIcon(h)}
            {index < history.length - 1 && (
              <View
                width="2px"
                bg="sky.1"
                flex="1"
                borderRadius="2px"
                mt="6px"
              />
            )}
          </Flex>
          <View flex="1" mb={3}>
            {renderTitle(h)}
            {renderState && renderState(h)}
            {renderDate(h)}
          </View>
        </Flex>
      ))}
    </View>
  )
}

Feed.Item = Item
Feed.Builder = Builder

/**
 * Legacy components exports to keep compatibility
 * TODO: Remove if feeds are migrated
 */
Feed.TitleCaption = Feed.Item.Title
Feed.DateCaption = Feed.Item.Date
Feed.Code = Feed.Item.Code

export default Feed
