import React from 'react'
import styled from 'styled-components'
import { PolymorphicComponent } from '../types/helpers'
import styledSystem, { StyledSystemProps } from '../utils/styledSystem'
import CleanTag from '../utils/CleanTag'

type WrapperProps = React.ComponentPropsWithRef<typeof CleanTag> & {
  forwardedAs?: keyof JSX.IntrinsicElements
}

const Wrapper = React.forwardRef<HTMLElement, WrapperProps>(
  ({ forwardedAs, ...rest }, ref) => (
    <CleanTag ref={ref} as={forwardedAs} {...rest} />
  )
)

export type ViewProps = StyledSystemProps

const View: PolymorphicComponent<ViewProps, 'div'> = styled(CleanTag).attrs(
  ({ as, ...rest }) =>
    as
      ? {
          ...rest,
          as: Wrapper,
          forwardedAs: as,
        }
      : {}
)<ViewProps>(({ boxSizing = 'border-box', ...rest }) => {
  const props = { boxSizing, ...rest }

  return styledSystem(props)
})

export default View
