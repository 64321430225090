import { getAppUrlForCountry } from 'config'
import moment from 'moment'
import { feeCalculator } from 'utils/finance'
import {
  APPLYING_STATUSES,
  CANCELED_STATUSES,
  CONTACT_SUPPORT_STATUSES,
  FAILED_STATUSES,
  FULFILLMENT_REQUIRED_STATUSES,
  FUNDS_ON_WAY_STATUSES,
  LOAN_OFFER_MADE_STATUSES,
  LOAN_STARTED_STATUSES,
  MANUAL_REVIEW_REQUIRED_STATUSES,
  OPENED_STATUSES,
  PAYMENT_ERROR_STATUSES,
  PAYOUT_CANCELED_STATUSES,
  PAYOUT_SCHEDULED_STATUSES,
  SENT_STATUSES,
  SETTLED_STATUSES,
  TRANSFERRED_STATUSES,
} from './offerStatusGroups'

const GOODS_TYPES = [
  'specialist_retail',
  'home_and_repair',
  'professional_services',
  'leisure_and_entertainment',
  'travel_and_transportation',
  'other',
]

export const getPaymentPlan = offer => {
  const { paymentPlans = [] } = offer
  return (
    paymentPlans.find(plan => {
      return plan.status !== 'canceled'
    }) || []
  )
}

export const getPayments = paymentPlans => {
  const payments = paymentPlans.reduce((mem, plan) => {
    const payments = plan.status === 'activated' ? plan.payments : []
    return [...mem, ...(payments || [])]
  }, [])
  return payments
}

export const getCurrentState = ({
  status,
  paymentPlan,
  merchant: { loanConfirmationMode } = {},
  isTabeoPlus,
  isIFinance,
  paymentSourceID,
  isPOT,
}) => {
  if (
    ['loan_agreement_accepted', 'first_payment_capture_failed'].includes(
      status
    ) &&
    loanConfirmationMode === 'merchant_confirmation' &&
    !!paymentSourceID
  ) {
    return 'ready_to_confirm'
  }
  if (SENT_STATUSES.includes(status)) {
    return 'sent'
  }
  if (OPENED_STATUSES.includes(status)) {
    return 'opened'
  }
  if (APPLYING_STATUSES.includes(status)) {
    return 'applying'
  }
  if (
    MANUAL_REVIEW_REQUIRED_STATUSES.includes(status) ||
    (isIFinance && status === 'manual_review_passed')
  ) {
    return 'manual_review_required'
  }
  if (TRANSFERRED_STATUSES.includes(status)) {
    return 'transferred'
  }
  if (LOAN_OFFER_MADE_STATUSES.includes(status)) {
    return 'approved'
  }
  if (PAYMENT_ERROR_STATUSES.includes(status)) {
    return 'card_declined'
  }
  if (CONTACT_SUPPORT_STATUSES.includes(status)) {
    return 'contact_support'
  }
  if (FULFILLMENT_REQUIRED_STATUSES.includes(status)) {
    return 'fulfillment_required'
  }
  if (
    isPOT &&
    (LOAN_STARTED_STATUSES.includes(status) ||
      (FUNDS_ON_WAY_STATUSES.includes(status) &&
        paymentPlan?.lender?.payoutType === 'unfunded') ||
      (PAYOUT_SCHEDULED_STATUSES.includes(status) &&
        paymentPlan?.lender?.payoutType === 'unfunded'))
  ) {
    return 'loan_started'
  }
  if (
    FUNDS_ON_WAY_STATUSES.includes(status) ||
    (PAYOUT_SCHEDULED_STATUSES.includes(status) && isTabeoPlus)
  ) {
    return 'funds_on_the_way'
  }
  if (PAYOUT_SCHEDULED_STATUSES.includes(status)) {
    return 'payout_scheduled'
  }
  if (SETTLED_STATUSES.includes(status)) {
    return 'settled'
  }
  if (CANCELED_STATUSES.includes(status)) {
    return 'canceled'
  }
  if (FAILED_STATUSES.includes(status)) {
    return 'application_failed'
  }
  if (PAYOUT_CANCELED_STATUSES.includes(status)) {
    return 'payout_canceled'
  }

  return status
}

const normalizeTransaction = offer => {
  const paymentPlan = getPaymentPlan(offer)
  const payments = getPayments([paymentPlan])
  // Payment Reference
  const [{ statementDescriptor } = {}] = payments
  const paymentReference = statementDescriptor

  // Product Type
  const { sector } = offer.merchant || {}
  const productType = GOODS_TYPES.includes(sector) ? 'good' : 'service'

  const consumerHisHer = offer.consumerGender === 'm' ? 'his' : 'her'
  const consumerHeShe = offer.consumerGender === 'm' ? 'he' : 'she'

  const isPOT = offer.type === 'pay_over_time'
  const isPN = offer.type === 'pay_now'

  const confirmedEvent = (offer.stateMachineTransitions || []).find(i =>
    ['first_payment_captured', 'pay_now_payment_succeeded'].includes(i.to)
  )
  const confirmedDate = confirmedEvent && confirmedEvent.at

  const { is_cooling_off_period_waived: isCoolingOffPeriodWaived } =
    offer?.flags || {}

  const coolingOffPeriodEnds =
    !isCoolingOffPeriodWaived && confirmedDate
      ? moment(confirmedDate).add(14, 'day')
      : null

  const refunds = payments.filter(p => p.type === 'ref_mer')

  const sumOfMerchantPaidRefunds = refunds.reduce((mem, item) => {
    return mem + item.merchantRefundPaymentAmount
  }, 0)

  const sumMerchantNetProceedsTransfers =
    offer.merchantNetProceedsTransfers.reduce((mem, item) => {
      return mem + item.amount
    }, 0)

  if (
    !offer.feeAmount &&
    paymentPlan?.riskProfileName === 'nl_ifinance' &&
    paymentPlan?.numberOfMonths === 24
  ) {
    const { feeAmount } = feeCalculator('nl_ifinance', paymentPlan, offer.price)
    offer.feeAmount = feeAmount
  }

  const netProceeds = offer.price - offer.feeAmount
  const revisedNetProceeds =
    offer.revisedPrice -
    offer.revisedRequiredMerchantFeeAmount -
    offer.merchantLateRefundFeeAmountToDate

  const feePercent = offer.feeAmount / offer.price
  const revisedFeePercent =
    offer.revisedRequiredMerchantFeeAmount / offer.revisedPrice || 0

  const defaultRiskProfile = offer.metadata?.riskProfiles?.find(
    p => !!p.loanOptions.find(o => o.isAvailableForInitialPaymentPlan)
  )

  const defaultRiskProfiles = offer.metadata?.riskProfiles?.filter(
    p => !!p.loanOptions.find(o => o.isAvailableForInitialPaymentPlan)
  )

  const isTabeoCollect = [
    'profile_sdc_unfunded',
    'plusdent_unfunded',
    'diamondwhites_unfunded',
    'impress_unfunded',
    'vinci_unfunded',
    'uk_pl_unfunded',
    'smilewhites_unfunded',
    'nl_collect',
    'it_collect',
    'fr_collect',
    'fr_sdc_collect',
    'in_house',
  ].includes(paymentPlan?.riskProfileName || defaultRiskProfile?.name)
  const isTabeoPlus =
    (paymentPlan?.riskProfileName || defaultRiskProfile?.name) === 'tabeo_plus'
  const isTabeoCore = [
    'default',
    'it_core',
    'fr_core',
    'fr_sdc_core',
    'near_prime',
  ].includes(paymentPlan?.riskProfileName || defaultRiskProfile?.name)
  const isDeferredPaymentPlan =
    (paymentPlan?.riskProfileName || defaultRiskProfile?.name) === 'nl_collect'
  const isMerchantPartLender =
    isTabeoCollect || isTabeoPlus || isDeferredPaymentPlan
  const isIFinance =
    (paymentPlan?.riskProfileName || defaultRiskProfile?.name) === 'nl_ifinance'

  const isDirectDebit = offer.paymentGateway === 'gocardless'

  const isConsumerConfirmationFlow =
    offer?.merchant?.loanConfirmationMode === 'consumer_confirmation'
  const isMerchantConfirmationFlow =
    offer?.merchant?.loanConfirmationMode === 'merchant_confirmation'

  const isVoided = offer?.loan?.status === 'voided'

  const publicLink = `${getAppUrlForCountry('app', offer.countryCode)}${
    isPOT
      ? `/finance/${offer?.publicAccessToken}/how-it-works`
      : `/payment/${offer?.publicAccessToken}`
  }`

  const normalized = {
    ...offer,
    refunds,
    sumOfMerchantPaidRefunds,
    feePercent,
    revisedFeePercent,
    paymentPlan: {
      ...paymentPlan,
      payments,
      paymentReference,
    },
    productType,
    consumerHisHer,
    consumerHeShe,
    confirmedDate,
    isCoolingOffPeriodWaived,
    coolingOffPeriodEnds,
    isPOT,
    isPN,
    netProceeds,
    revisedNetProceeds,
    isTabeoCollect,
    isTabeoCore,
    isTabeoPlus,
    isDeferredPaymentPlan,
    isMerchantPartLender,
    isIFinance,
    isDirectDebit,
    isMerchantConfirmationFlow,
    isConsumerConfirmationFlow,
    sumMerchantNetProceedsTransfers,
    defaultRiskProfile,
    defaultRiskProfiles,
    isVoided,
    publicLink,
  }

  const state = getCurrentState(normalized)

  return {
    ...normalized,
    state,
  }
}

export default normalizeTransaction
