import ActionCard from 'components/ActionPanel/ActionCard'
import icon from 'components/ActionPanel/assets/sent.svg'
import moment from 'moment'
import PropType from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

function Incomplete({ data }) {
  const event = data.stateMachineTransitions?.[0].at
  const { t } = useTranslation()
  return (
    <ActionCard
      icon={icon}
      title={t('Started signing up')}
      body={t(
        `{{consumerFirstName}} has opened the invitation email and started signing up {{elapsedTime}}, but his subscription isn’t yet active. To complete the activation, {{consumerFirstName}} needs to provide a few details and sign the Terms & Conditions.`,
        {
          consumerFirstName: data.consumerFirstName,
          elapsedTime: moment().to(event),
        }
      )}
    />
  )
}

Incomplete.propTypes = {
  data: PropType.object.isRequired,
}

export default Incomplete
