import { format } from '@tabeo/sharpei/utils/currency'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import PaymentRefund from 'components/checkout-payment/PaymentRefund'
import reasons from 'components/checkout-payment/PaymentRefund/reasons'
import { useTranslation } from 'react-i18next'
import { ReactComponent as DoubleLeftArrowOrange } from '../assets/double-left-arrow-orange.svg'
import { usePaymentRefundContext } from '../PaymentRefund/Context'

function RefundRequested() {
  const { t } = useTranslation()

  const { payment } = usePaymentRefundContext()

  const refund = payment?.refunds?.at(0)

  if (!refund) {
    return null
  }

  const { id, amount, reasonType } = refund

  return (
    <ActionCard data-testid="rr-action-panel">
      <ActionCard.Header>
        <DoubleLeftArrowOrange className="h-6 w-6" />
        {t('Refund requested')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t(
          'A refund of {{amount}} was requested for this payment due to “{{reason}}”. The refund request is subject to approval.',
          {
            amount: format(amount),
            reason: reasons[reasonType] || reasonType,
          }
        )}
      </ActionCard.Body>
      <ActionCard.Actions>
        <PaymentRefund.ReviewButton id={id} />
        <PaymentRefund.CancelButton id={id} />
      </ActionCard.Actions>
    </ActionCard>
  )
}

export default RefundRequested
