import { useTranslation } from 'react-i18next'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as DoubleLeftArrowDarkGray } from '../assets/double-left-arrow-dark-gray.svg'

function Refunded() {
  const { t } = useTranslation()

  return (
    <ActionCard data-testid="fr-action-panel">
      <ActionCard.Header>
        <DoubleLeftArrowDarkGray className="h-6 w-6" />
        {t('Refunded')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t(
          'This payment was fully refunded. For more information, please review the Refunds panel.'
        )}
      </ActionCard.Body>
    </ActionCard>
  )
}

export default Refunded
