import React, { useContext, useState } from 'react'
import Avatar from '../../Avatar'
import AvatarList from '../../Avatar/AvatarList'
import Confirmation from '../../Confirmation'
import * as Icons from '../../Icons'
import BasicValue from '../../InteractiveForm/BasicValue'
import {
  ActionIconButton,
  Actions,
  Highlight,
  Id,
  Indicators,
  Line,
  Title,
} from './components'
import TicketContext from './TicketContext'

export interface OwnerItem {
  id: number
  name: string
  type?: string
}

export interface TicketItem {
  id: number
  isStarred?: boolean
  isResolved?: boolean
  isOverdue?: boolean
  title?: string
  description?: string
  owners?: OwnerItem[]
  overdueAt?: string
  overdueAtHumanized?: string
  uploads?: any[]
  typeLabel?: string
}

export type DefaultBuilderProps = {
  data: TicketItem
  onAction: (action: string) => void
}

function DefaultBuilder({ data, onAction }: DefaultBuilderProps) {
  const { isStarHidden, isDeleteHidden } = useContext(TicketContext)
  const [active, setActive] = useState<boolean>()

  const {
    id,
    isStarred,
    isResolved,
    isOverdue,
    title,
    description,
    owners,
    overdueAt,
    overdueAtHumanized,
    uploads,
    typeLabel,
  } = data

  const hasIndicators = description || owners?.length || overdueAt || typeLabel

  return (
    <>
      {isStarred && <Highlight />}
      <Line mb={hasIndicators ? 2 : '-4px'}>
        <Id>{id}</Id>
      </Line>
      {hasIndicators && <Title mb={[3, 1]}>{title}</Title>}
      <Line
        alignItems={!hasIndicators ? ['flex-start', 'flex-end'] : undefined}
      >
        {!hasIndicators && (
          <Title mt={2} mb={[5, 0]}>
            {title}
          </Title>
        )}
        {hasIndicators && (
          <Indicators mb={[2, 0]}>
            {!!description && (
              <Icons.Description
                fill="sky.0"
                mr={uploads?.length ? 1 : 3}
                mb={[3, 0]}
              />
            )}
            {!!uploads?.length && (
              <Icons.Clip fill="sky.0" mr={2} mb={[3, 0]} />
            )}
            {!!owners?.length && (
              <AvatarList mr={3} mb={[3, 0]}>
                {owners.map((assignee) => (
                  <Avatar
                    key={assignee.id}
                    name={assignee.name}
                    sizeVariant="fixed"
                    bg="inherit"
                  />
                ))}
              </AvatarList>
            )}
            {!!typeLabel && (
              <BasicValue
                sizeVariant="fixed"
                icon={({ fill, ...rest }) => (
                  <Icons.Tag fill={isResolved ? 'sky.0' : fill} {...rest} />
                )}
                mr={3}
                mb={[3, 0]}
              >
                {typeLabel}
              </BasicValue>
            )}
            {!!overdueAt && (
              <BasicValue
                sizeVariant="fixed"
                icon={({ fill, ...rest }) => (
                  <Icons.Calendar
                    fill={isResolved ? 'sky.0' : fill}
                    {...rest}
                  />
                )}
                mr={3}
                invalid={isOverdue}
                mb={[3, 0]}
              >
                {overdueAtHumanized}
              </BasicValue>
            )}
          </Indicators>
        )}

        <Actions
          {...(active && {
            opacity: 1,
            background: 'rgba(255, 255, 255, 0.9)',
            boxShadow: '0px 0px 8px 8px rgba(255, 255, 255, 0.9)',
          })}
        >
          {!isDeleteHidden && (
            <Confirmation
              content="Are you sure you want to delete this ticket?"
              confirmText="Delete"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
              }}
              onConfirm={() => {
                onAction('delete')
              }}
              onOpenChange={setActive}
            >
              <ActionIconButton
                mr={2}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                }}
              >
                <Icons.Trash
                  fill="ink.1"
                  height={['26px', '22px']}
                  width={['26px', '22px']}
                />
              </ActionIconButton>
            </Confirmation>
          )}
          {!isResolved && !isStarHidden && (
            <ActionIconButton
              mr={2}
              onClick={(e: React.MouseEvent) => {
                onAction('star')
                e.stopPropagation()
              }}
            >
              <Icons.Star
                active={isStarred}
                fill={isStarred ? 'currentColor' : 'ink.1'}
                height={['26px', '22px']}
                width={['26px', '22px']}
              />
            </ActionIconButton>
          )}
          <ActionIconButton
            onClick={(e: React.MouseEvent) => {
              onAction('resolve')
              e.stopPropagation()
            }}
          >
            {isResolved ? (
              <Icons.Revert
                fill="ink.1"
                height={['26px', '22px']}
                width={['26px', '22px']}
              />
            ) : (
              <Icons.MarkAsDone
                fill="ink.1"
                height={['26px', '22px']}
                width={['26px', '22px']}
              />
            )}
          </ActionIconButton>
        </Actions>
      </Line>
    </>
  )
}

export default DefaultBuilder
