import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const X = ({ color = '#FF6966', width = '22', height = '22', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 22 22" {...rest}>
    <g fill={color} mask="url(#b)">
      <path d="M11 16a5 5 0 1 1 0-10 5 5 0 0 1 0 10zm1.654-6.36l-2.441 2.342-.877-.796a.5.5 0 0 0-.672.74l1.222 1.11a.5.5 0 0 0 .682-.009l2.778-2.666a.5.5 0 0 0-.692-.722z" />
    </g>
  </svg>
)

X.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default styled(X)``
