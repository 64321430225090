import { Portal } from '@headlessui/react'
import {
  CheckBadgeIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  IdentificationIcon,
  MapPinIcon,
} from '@heroicons/react/20/solid'
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Modal } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import { format } from '@tabeo/sharpei/utils/currency'
import { AnimatePresence, motion } from 'framer-motion'
import { AmortizationCalculation, ExtendedLoanOption } from 'pages/NNTS/types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import { feeCalculator, getVisibleNearPrimeProfile } from 'utils/finance'
import formatFee from 'utils/formatFee'
import { AprChip } from './FinanceRadioCard'

type ModalData =
  | {
      loanOption: ExtendedLoanOption
      amortizationCalculation: AmortizationCalculation
    }
  | undefined

const FinanceDetailsModal = React.forwardRef<any, any>(
  ({ onSuccess, ...rest }, ref) => {
    const [isFeeCalcOpen, setIsFeeCalcOpen] = useState(false)
    const { t } = useTranslation()
    const { data } = useMerchant()

    const nearPrimeProfile = data?.merchantRiskProfiles.find(
      p => p.name === 'near_prime'
    )

    const nearPrimeProfileOptions =
      nearPrimeProfile?.loanOptions.map(o => ({
        ...o,
        profile: nearPrimeProfile,
      })) || []

    return (
      <Portal>
        <Modal ref={ref} closable={false} {...rest}>
          {({ isOpen, close, data }) => {
            const { loanOption, amortizationCalculation } =
              (data as ModalData) || {}

            if (!loanOption || !amortizationCalculation) {
              return null
            }

            const visibleNearPrimeOption = getVisibleNearPrimeProfile(
              loanOption,
              nearPrimeProfileOptions
            )

            return isOpen ? (
              <div className="fixed inset-0 overflow-y-scroll bg-white p-4 text-tabeo-ink-0 sm:inset-10  sm:rounded sm:p-10">
                <div className="flex items-center justify-between">
                  <h2 className="title">
                    {t('{{numberOfMonths}} months', {
                      numberOfMonths: loanOption?.numberOfMonths,
                    })}
                  </h2>
                  <Button
                    variant="secondary"
                    leadingIcon={p => <XMarkIcon {...p} />}
                    onClick={close}
                  >
                    Close
                  </Button>
                </div>
                <div className="mt-8 grid gap-4 sm:grid-cols-3">
                  {[
                    {
                      label: t('Consumer pays'),
                      value: `${format(
                        amortizationCalculation?.monthlyInvoicePaymentAmount
                      )} per month`,
                    },
                    {
                      label: t('Total cost of credit'),
                      value: `${format(
                        amortizationCalculation?.totalInterestPaymentAmount
                      )} (${((loanOption?.interestRate || 0) * 100).toFixed(
                        1
                      )}%)`,
                    },
                    {
                      label: t('Total to pay back'),
                      value: format(
                        amortizationCalculation?.totalPaymentAmount
                      ),
                    },
                  ].map(({ label, value }) => (
                    <div className="rounded bg-tabeo-sky-2 p-5" key={label}>
                      <div className="caption text-tabeo-ink-1">{label}</div>
                      <div className="heading mt-1">{value}</div>
                    </div>
                  ))}
                </div>
                <div className="heading mt-8">{t('Requirements')}</div>
                <div className="mt-4 grid gap-4 border-b pb-8 sm:grid-cols-3">
                  {[
                    {
                      icon: CheckBadgeIcon,
                      caption: t('Subject to creditworthiness & affordability'),
                    },
                    {
                      icon: MapPinIcon,
                      caption: t('Available to UK residents only'),
                    },
                    {
                      icon: IdentificationIcon,
                      caption: t('Applicants aged 18 - 79 only'),
                    },
                  ].map(({ icon: Icon, caption }) => (
                    <div className="flex items-center gap-2" key={caption}>
                      <div className="inline-block rounded-full bg-tabeo-sky-1 p-1">
                        <div className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-tabeo-ink-2 bg-white">
                          <Icon className="h-5 w-5 text-tabeo-ink-2" />
                        </div>
                      </div>
                      <div className="caption text-tabeo-ink-1">{caption}</div>
                    </div>
                  ))}
                </div>
                <button
                  type="button"
                  onClick={() => setIsFeeCalcOpen(v => !v)}
                  className="anchor caption mt-4 inline-flex items-center gap-1"
                >
                  {t('Subsidy fee calculator')}
                  {isFeeCalcOpen ? (
                    <ChevronUpIcon className="h-5 w-5" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" />
                  )}
                </button>
                <AnimatePresence initial={false}>
                  {isFeeCalcOpen && (
                    <motion.div
                      key="table"
                      initial={{
                        opacity: 0,
                        height: 0,
                      }}
                      animate={{
                        opacity: 1,
                        height: 'auto',
                      }}
                      exit={{
                        opacity: 0,
                        height: 0,
                      }}
                      className="overflow-hidden"
                    >
                      <div className="mt-3 grid-cols-4 overflow-hidden rounded border border-b-0 sm:grid">
                        {['Product', 'APR', 'Term', 'Subsidy fee'].map(
                          (item, index) => (
                            <div
                              className={`subheading hidden bg-tabeo-sky-2 px-5 py-3 text-tabeo-ink-1 sm:block ${
                                index === 3 && 'text-right'
                              }`}
                              key={item}
                            >
                              {item}
                            </div>
                          )
                        )}
                        {[
                          ...(loanOption?.customerTermOptions || []),
                          ...(visibleNearPrimeOption
                            ? [visibleNearPrimeOption]
                            : []),
                        ].map(o => {
                          const { fee, feeAmount } = feeCalculator(
                            o.profile?.name,
                            o,
                            amortizationCalculation?.principalAmount
                          )
                          let productName = o.isRegulated
                            ? 'Regulated'
                            : 'Unregulated'

                          if (o.profile?.name === 'near_prime') {
                            productName = 'Near Prime'
                          }
                          const feeString = `${format(feeAmount)} (${formatFee(
                            fee
                          )}%)`
                          return (
                            <div
                              className="sm:contents"
                              key={`${productName}-${feeString}-${o.numberOfMonths}`}
                            >
                              <div
                                className="caption hidden items-center border-b px-5 py-3 sm:inline-flex"
                                key="product"
                              >
                                {productName}
                              </div>
                              <div
                                className="caption hidden items-center border-b px-5 py-3 sm:inline-flex"
                                key="apr"
                              >
                                <AprChip value={o.interestRate} />
                              </div>
                              <div
                                className="caption hidden items-center border-b px-5 py-3 sm:inline-flex"
                                key="term"
                              >
                                {o.numberOfMonths} months
                              </div>
                              <div
                                className="caption hidden w-full place-content-end items-center border-b px-5 py-3 sm:inline-flex"
                                key="fee"
                              >
                                {feeString}
                              </div>
                              <div className="border-b px-3 py-4 sm:hidden">
                                <div className="subheading flex justify-between text-tabeo-ink-1">
                                  <span>{productName}</span>
                                  <span>Subsidy fee</span>
                                </div>
                                <div className="caption mt-1 flex items-center justify-between">
                                  <div className="inline-flex items-center gap-2">
                                    <AprChip value={o.interestRate} />
                                    <span>
                                      {t('{{numberOfMonths}} months', {
                                        numberOfMonths: o.numberOfMonths,
                                      })}
                                    </span>
                                  </div>
                                  <span>{feeString}</span>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      {nearPrimeProfile && (
                        <div className="caption mt-8 flex gap-1 text-tabeo-ink-2">
                          <InformationCircleIcon className="h-5 w-5" />
                          Near Prime finance offered automatically to the
                          customer if the initial application for finance was
                          not successful.
                        </div>
                      )}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ) : null
          }}
        </Modal>
      </Portal>
    )
  }
)

export default FinanceDetailsModal
