// @ts-nocheck

import { themeGet } from '@styled-system/theme-get'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import Flex from '../../Flex'
import Lock from '../../Icons/Lock'
import { Caption, Subheading } from '../../Text'
import View from '../../View'
import Input, { InputProps } from '../Input'
import Circle from './Circle'
import Invalid from './Invalid'
import Tick from './Tick'

const LockIcon = styled(Lock).attrs((props) => ({
  color: themeGet('colors.ink.2')(props),
  width: 22,
  height: 22,
}))``

const TickIcon = styled(Tick).attrs((props) => ({
  color: themeGet('colors.default.primary')(props),
}))``

const Toggle = styled(Subheading).attrs(() => ({
  color: 'default.primary',
}))`
  cursor: pointer;
  white-space: nowrap;
  ${(p) =>
    p.disabled &&
    css`
      color: ${themeGet('colors.ink.2')};
      cursor: not-allowed;
    `}
`

const Criteria = ({ touched, valid, label }) => {
  if (!touched && !valid) {
    return (
      <Flex justifyContent="flex-start">
        <Circle />
        <Caption ml={1} fontWeight="normal">
          {label}
        </Caption>
      </Flex>
    )
  }
  if (!valid) {
    return (
      <Flex justifyContent="flex-start">
        <Invalid />
        <Caption ml={1} color="red.2" fontWeight="normal">
          {label}
        </Caption>
      </Flex>
    )
  }
  return (
    <Flex justifyContent="flex-start">
      <TickIcon />
      <Caption ml={1} color="default.primary" fontWeight="normal">
        {label}
      </Caption>
    </Flex>
  )
}

Criteria.propTypes = {
  touched: PropTypes.bool,
  valid: PropTypes.bool,
  label: PropTypes.string.isRequired,
}

const Criterias = ({ value = '', touched }) => {
  const minLength8 = (value) => !(value.length < 8)
  const containsNumber = (value) => !!value.match(/\d+/g)
  const containsUpperCase = (value) => !!value.match(/(?=.*[A-Z])+/g)
  const containsLowerCase = (value) => !!value.match(/(?=.*[a-z])+/g)
  const { t } = useTranslation()

  return (
    <View mb={1}>
      <Criteria
        label={t('Is 8 characters long')}
        touched={touched}
        valid={minLength8(value)}
      />
      <Criteria
        label={t('Contains at least 1 number')}
        touched={touched}
        valid={containsNumber(value)}
      />
      <Criteria
        label={t('Contains at least 1 upper case letter')}
        touched={touched}
        valid={containsUpperCase(value)}
      />
      <Criteria
        label={t('Contains at least 1 lower case letter')}
        touched={touched}
        valid={containsLowerCase(value)}
      />
    </View>
  )
}

Criterias.propTypes = {
  touched: PropTypes.bool,
  value: PropTypes.string,
}

export type PasswordProps = InputProps & {
  criterias?: boolean
}

class Password extends Component<PasswordProps> {
  state = {
    inputType: 'password',
  }

  toggleInputType = () => {
    const { disabled } = this.props
    !disabled &&
      this.setState(({ inputType }) => ({
        inputType: inputType === 'password' ? 'text' : 'password',
      }))
  }

  render() {
    const { inputType } = this.state
    const {
      disabled,
      criterias,
      input = {},
      meta = {},
      t,
      tReady,
      ...rest
    } = this.props

    return (
      <View>
        {criterias && <Criterias value={input.value} touched={meta.touched} />}
        <Input
          type={inputType}
          left={<LockIcon fill="ink.2" />}
          disabled={disabled}
          input={input}
          meta={meta}
          right={
            <Toggle onClick={this.toggleInputType} disabled={disabled}>
              {inputType === 'password' ? t('SHOW') : t('HIDE')}
            </Toggle>
          }
          {...rest}
        />
      </View>
    )
  }
}

Password.propTypes = {
  disabled: PropTypes.bool,
  criterias: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.object,
  t: PropTypes.func,
  tReady: PropTypes.bool,
}

export default withTranslation()(Password)
