import React from 'react'
import PropTypes from 'prop-types'
import { FormSpy } from 'react-final-form'
import diff from 'object-diff'

class AutoSave extends React.Component {
  componentDidUpdate(prevProps) {
    const { values, debounce } = this.props

    const change = diff(prevProps.values || {}, values || {})
    const didValuesUpdate = !!Object.keys(change).length
    if (didValuesUpdate) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(this.save, debounce || 5000)
    }
  }

  save = async () => {
    if (this.promise) {
      await this.promise
    }
    const { values, save } = this.props
    this.promise = save(values)
    await this.promise
    delete this.promise
  }

  render() {
    return null
  }
}

AutoSave.propTypes = {
  debounce: PropTypes.number,
  save: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}

const AutoSaveHoc = props => (
  <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />
)

export default AutoSaveHoc
