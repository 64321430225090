import { Attributes, Button, Caption, Panel, View } from '@tabeo/scarf'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLead } from 'resources/Lead'
import Modal from './Modal'

const BookingPanel = ({
  data: { appointment, firstName, state, status } = {},
  ...rest
}) => {
  const {
    resource: { updateAppointment },
  } = useLead()

  const modal = React.useRef({})
  const { t } = useTranslation()

  const isBookingEditable = status === 'qualified' && state !== 'treatment'

  return (
    <>
      <Modal
        innerRef={modal}
        onSubmit={updateAppointment}
        firstName={firstName}
        appointment={appointment}
      />
      <Panel
        header={
          <>
            <Panel.Title>{t('Appointments')}</Panel.Title>
            {appointment && isBookingEditable && (
              <Button
                variant="secondary"
                sizeVariant="sm"
                onClick={() => modal.current.open()}
              >
                {t('Update')}
              </Button>
            )}
          </>
        }
        {...rest}
      >
        <View px={5} bg="white">
          {appointment ? (
            <Attributes
              py={3}
              sections={[
                [
                  {
                    label: t('Date'),
                    value: appointment.scheduledAt,
                    formattedValue: moment(appointment.scheduledAt).format(
                      'hh:mm a, DD MMM YYYY'
                    ),
                  },
                  {
                    label: t('Clinician'),
                    value: appointment.personName,
                  },
                ],
              ]}
            />
          ) : (
            <Caption textAlign="center" py={3} px={5}>
              {t('There are no appointments associated with this lead yet')}
            </Caption>
          )}
        </View>
      </Panel>
    </>
  )
}

BookingPanel.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BookingPanel
