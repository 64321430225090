import { FieldLayout, TextInput, toast } from '@tabeo/scarf2'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

export type CopyLinkProps = {
  url: string
}

export default function CopyLink({ url }: CopyLinkProps) {
  const { t } = useTranslation()

  const lastCopiedAt = useRef<number>(0)

  return (
    <FieldLayout label="Copy link" optionalLabel="">
      <TextInput
        value={url}
        readOnly
        onClick={async e => {
          if (+new Date() - lastCopiedAt.current < 5000) {
            return
          }

          const el = e.target as HTMLInputElement
          el.select()

          await navigator.clipboard.writeText(url)
          toast.success(t('Link copied'))

          lastCopiedAt.current = +new Date()
        }}
      />
    </FieldLayout>
  )
}
