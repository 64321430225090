// TODO: translate?

const values = {
  howManyImplants: {
    0: 'None',
    1: '1',
    2: '2',
    '>2': 'More than 2',
  },
  howManyMissingTeeth: {
    0: 'None',
    1: '1',
    2: '2',
    '>2': 'More than 2',
  },
  hadBracesBefore: {
    true: 'Yes',
    false: 'No',
  },
  painOrDiscomfort: {
    true: 'Yes',
    false: 'No',
  },
  whyDoYouWantStraighterTeeth: {
    wedding: 'Wedding',
    new_job: 'New job',
    confidence: 'Confidence',
    other: 'Other',
  },
  whatIsYourBiggestConcernWithYourSmile: {
    spacing: 'Spacing',
    crowding: 'Crowding',
    overbite: 'Overbite',
    underbite: 'Underbite',
    straighter_teeth: 'Straighter teeth',
  },
  spacing: {
    mild_or_no_space: 'Mild spacing',
    complex: 'Severe spacing',
  },
  crowding: {
    mild_or_no_crowding: 'Mild crowding',
    complex: 'Severe crowding',
  },
  doYouHaveAnyMissingTeeth: {
    yes_in_the_front: 'In the front',
    yes_in_the_back: 'In the back',
    yes_in_the_front_and_back: 'In the front and in the back',
    no: 'None',
  },
  interestedInTeethWhitening: {
    true: 'Yes',
    false: 'No',
  },
  whyDoYouWantImplant: {
    discomfort_when_eating: 'Discomfort when eating',
    smile_confidently: 'Smile confidently',
    avoid_bone_recession: 'Avoid bone recession',
    replace_denture: 'Replace denture',
    other: 'Other',
  },
  howManyTeethToReplace: {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    '>4': '5+',
  },
  whenDoYouWantToStart: {
    next_4_weeks: 'Next 4 weeks',
    in_4_to_8_weeks: 'In 4-8 weeks',
    later: 'Later',
  },
}

export default values
