import React from 'react'
import Svg, { G, SvgProps } from './Base'

function MarkAsDone({ fill, ...props }: SvgProps) {
  return (
    <Svg {...props}>
      <G
        transform="translate(4.5 4.5)"
        stroke={fill}
        fill="none"
        fillRule="evenodd"
      >
        <circle cx={6.5} cy={6.5} r={6} />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.063 6.806l1.969 1.725L8.937 4.47"
        />
      </G>
    </Svg>
  )
}

export default MarkAsDone
