import {
  Button,
  Modal,
  NativeSelect,
  Prompt,
  Subtitle,
  TextInput,
  Title,
  View,
} from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import {
  email,
  emailPCA,
  name,
  required,
} from '@tabeo/sharpei/utils/validations'
import plane from 'assets/airplane.svg'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Icon = styled(View)``

Icon.defaultProps = {
  src: plane,
  as: 'img',
  mx: 'auto',
  mt: 3,
  mb: '30px',
}

function InviteModal({ innerRef, onSubmit }) {
  const { t } = useTranslation()

  return (
    <Modal ref={innerRef}>
      {({ isOpen, close }) => {
        async function handleSubmit(values) {
          try {
            await onSubmit(values)
          } catch (e) {
            return formError(e)
          }
        }

        return isOpen ? (
          <View
            maxWidth={['90%', '460px']}
            mx="auto"
            bg="white"
            borderRadius={1}
            boxShadow={1}
            p={[5, 10]}
          >
            <Form onSubmit={handleSubmit}>
              {({
                handleSubmit,
                submitting,
                submitSucceeded,
                values: { firstName },
              }) =>
                !submitSucceeded ? (
                  <form onSubmit={handleSubmit}>
                    <Title mb={4}>{t('Edit contact details')}</Title>
                    <Field
                      component={TextInput}
                      name="firstName"
                      label={t('First name')}
                      validate={[required, name]}
                      placeholder={t('E.g. John')}
                      maxWidth="100%"
                    />

                    <Field
                      component={TextInput}
                      name="lastName"
                      label={t('Last name')}
                      validate={[required, name]}
                      placeholder={t('E.g. Smith')}
                      maxWidth="100%"
                    />

                    <Field
                      component={TextInput}
                      name="email"
                      type="email"
                      label={t('Email address')}
                      placeholder={t('e.g. user@example.co.uk')}
                      validate={[required, email, emailPCA]}
                      maxWidth="100%"
                    />

                    <Field
                      component={NativeSelect}
                      name="jobFunction"
                      label={t('Role')}
                      options={[
                        {
                          label: t('Dentist / Veterinarian'),
                          value: 'Dentist / Veterinarian',
                        },
                        {
                          label: t('Surgeon / Specialist'),
                          value: 'Surgeon / Specialist',
                        },
                        {
                          label: t('Nurse / Hygienist'),
                          value: 'Nurse / Hygienist',
                        },
                        {
                          label: t('Director / Owner'),
                          value: 'Director / Owner',
                        },
                        {
                          label: t('Admin / Finance'),
                          value: 'Admin / Finance',
                        },
                        {
                          label: t('Sales / Marketing'),
                          value: 'Sales / Marketing',
                        },
                      ]}
                      validate={[required]}
                      maxWidth="100%"
                    />

                    <Button loading={submitting} width="100%" mb={5}>
                      {t('Send invitation')}
                    </Button>

                    <Button
                      variant="secondary"
                      type="button"
                      width="100%"
                      onClick={close}
                    >
                      {t('Close')}
                    </Button>
                    <FormError />
                  </form>
                ) : (
                  <View textAlign="center">
                    <Icon />
                    <Subtitle color="primary.3" mb={4}>
                      {t('User invited successfully')}
                    </Subtitle>
                    <Prompt color="ink.1" mb={6}>
                      {t(
                        '{{invitedUserfirstName}} will receive an email shortly with a link to complete signup to Tabeo.',
                        { invitedUserfirstName: firstName }
                      )}
                    </Prompt>
                    <Button type="button" width="100%" onClick={close}>
                      {t('Close')}
                    </Button>
                  </View>
                )
              }
            </Form>
          </View>
        ) : null
      }}
    </Modal>
  )
}

InviteModal.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default InviteModal
