import { Modal } from '@tabeo/scarf'
import React from 'react'
import { NormalizedLinkPayment } from 'types/NormalizedLinkPayment'
import { NormalizedTerminalPayment } from 'types/NormalizedTerminalPayment'
import CancelButton from './Cancel/Button'
import { PaymentRefundContext } from './Context'
import RequestButton from './Request/Button'
import RequestModal from './Request/Modal'
import ReviewButton from './Review/Button'
import ReviewModal from './Review/Modal'

function PaymentRefund() {
  return null
}

type ProviderProps = {
  payment?: NormalizedTerminalPayment | NormalizedLinkPayment
  onPaymentRefetch?: () => any
  onRefundRequest?: (payload: any) => any
  onReceiptSend?: (payload: any) => any
  children?: React.ReactNode
}

function Provider({
  payment,
  onPaymentRefetch,
  onRefundRequest,
  onReceiptSend,
  children,
}: ProviderProps) {
  const requestModal = React.useRef<Modal>(null)
  const reviewModal = React.useRef<Modal>(null)

  return (
    <PaymentRefundContext.Provider
      value={{
        payment,
        onPaymentRefetch,
        onRefundRequest,
        onReceiptSend,
        requestModal,
        reviewModal,
      }}
    >
      <RequestModal ref={requestModal} onSubmit={onRefundRequest} />
      <ReviewModal ref={reviewModal} />
      {children}
    </PaymentRefundContext.Provider>
  )
}

PaymentRefund.Provider = Provider
PaymentRefund.RequestButton = RequestButton
PaymentRefund.ReviewButton = ReviewButton
PaymentRefund.CancelButton = CancelButton

export default PaymentRefund
