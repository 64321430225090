import { Field as BaseField } from '@tabeo/scarf'
import { FieldProps } from '@tabeo/scarf/dist/components/Form/Field'
import { Merge } from '@tabeo/scarf/dist/types/helpers'
import { Field as FinalField } from 'react-final-form'

const composeValidators =
  (...validators: any[]) =>
  (value: any, values: any) =>
    validators.reduce(
      (error, validator) => error || validator(value, values),
      undefined
    )

export type CustomFieldProps<T> = Merge<
  FieldProps<T>,
  {
    name: string
    validate?: any
    parse?: any
    format?: any
  }
>

const Field: <T>(props: CustomFieldProps<T>) => JSX.Element | null = ({
  validate,
  name,
  parse,
  format,
  ...rest
}: CustomFieldProps<any>) => {
  let validator = validate
  if (Array.isArray(validate)) {
    validator = composeValidators(...validate)
  }

  return (
    // @ts-ignore
    <FinalField
      validate={validator}
      name={name}
      parse={parse}
      format={format}
      {...rest}
    >
      {({ meta, input }) => {
        // @ts-ignore
        return <BaseField {...rest} meta={meta} input={input} />
      }}
    </FinalField>
  )
}

export default Field
