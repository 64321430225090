// @ts-nocheck

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { InputProp, MetaProp } from '../../types/form'
import { Merge } from '../../types/helpers'
import ScarfProps from '../../types/ScarfProps'
import Flex from '../Flex'
import DateInput from './Date'
import DatePicker from './DatePicker'
import Time from './Time'
import type { BaseProps } from './Input'

const dateComponents = {
  dateinput: DateInput,
  datepicker: DatePicker,
}

export type DateTimeProps = Merge<
  ScarfProps,
  {
    input?: Partial<InputProp>
    meta?: Partial<MetaProp>
    type?: 'dateinput' | 'datepicker'
    alignPopupToTop?: boolean
    variant?: BaseProps['variant']
  }
>

class DateTime extends Component<DateTimeProps> {
  state = {
    date: null,
    time: {
      hh: '',
      mm: '',
    },
  }

  constructor(props) {
    super(props)
    const {
      input: { value },
    } = props
    if (value) {
      const date = new Date(value)

      this.state = {
        time: {
          hh: date.getUTCHours(),
          mm: date.getUTCMinutes(),
        },
        date: date.toISOString(),
      }
    }
  }

  handleDateChange = (value) => {
    const { time } = this.state
    const date = new Date()
    date.setHours(0)
    date.setMinutes(0)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    this.setState({ date: value, time: time.mm ? time : { hh, mm } }, () =>
      this.handleOnChange()
    )
  }

  handleTimeChange = (value) => {
    this.setState({ time: value }, () => this.handleOnChange())
  }

  handleOnChange = () => {
    const { input } = this.props
    const {
      date: dateStr,
      time: { hh, mm },
    } = this.state
    if (dateStr) {
      const date = new Date(dateStr)
      const time = new Date()
      time.setUTCHours(hh, mm)
      const day = date.getDate()
      const month = date.getMonth()
      const year = date.getFullYear()
      const hour = time.getHours()
      const minute = time.getMinutes()
      const output = new Date(year, month, day, hour, minute).toISOString()
      input.onChange(output)
    }
  }

  render() {
    const { type, alignPopupToTop, meta, variant, ...rest } = this.props
    const { date, time } = this.state

    const DateComponent = dateComponents[type] || DatePicker

    return (
      <Flex display="inline-flex" {...rest}>
        <DateComponent
          input={{
            onChange: this.handleDateChange,
            value: date || undefined,
          }}
          meta={meta}
          mr={5}
          alignPopupToTop={alignPopupToTop}
          width="152px"
          variant={variant}
        />
        <Time
          key={date}
          input={{ onChange: this.handleTimeChange, value: time }}
          meta={meta}
          variant={variant}
        />
      </Flex>
    )
  }
}

DateTime.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  type: PropTypes.string,
  alignPopupToTop: PropTypes.bool,
}

DateTime.defaultProps = {
  input: {
    onChange: () => {},
  },
  type: 'datepicker',
}

export default DateTime
