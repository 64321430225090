import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import { Patient as P } from '@tabeo/ts-types'

interface Params {
  id: string | number
}

const subResources = formatSubresources(['address', 'address.addressDetails'])

class Patient extends Resource<P, Params> {
  async fetch() {
    const { patient } = await api.get(
      `/merchant/patients/${this.params.id}?${subResources}`
    )

    this.data = {
      ...patient,
    }

    return this.data
  }

  update = async (payload: Partial<P>) => {
    const response = await api.put(
      `/merchant/patients/${this.params.id}`,
      payload
    )
    await this.fetch()
    return response
  }
}

export default Patient
export const usePatient = createResourceHook(Patient)
