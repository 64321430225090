import {
  Button,
  Caption,
  DatePicker,
  Modal,
  Text,
  Title,
  View,
} from '@tabeo/scarf'
import { required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import calendar from './assets/treatment-date.svg'

export const Icon = styled('img')`
  margin: 12px auto 30px;
  display: block;
`

Icon.defaultProps = {
  src: calendar,
}

function Success({ close, consumerFirstName }) {
  const { t } = useTranslation()
  return (
    <>
      <Title mb={3}>{t('Payment date changed')}</Title>
      <Icon />
      <Text mb={6} color="ink.1">
        {t(
          'We’ve sent {{consumerFirstName}} a confirmation with the updated payment date.',
          { consumerFirstName }
        )}
      </Text>
      <Button variant="secondary" width="100%" onClick={close}>
        {t('Close')}
      </Button>
    </>
  )
}

Success.propTypes = {
  close: PropTypes.func.isRequired,
  consumerFirstName: PropTypes.string.isRequired,
}

function Update({ consumerFirstName, scheduledAt, innerRef, onSubmit }) {
  const { t } = useTranslation()
  return (
    <Modal ref={innerRef}>
      {({ isOpen, close }) =>
        isOpen ? (
          <View
            textAlign="center"
            maxWidth={['90%', '460px']}
            mx="auto"
            bg="white"
            borderRadius={1}
            boxShadow={1}
            p={[5, 10]}
          >
            <Form onSubmit={onSubmit} initialValues={{ scheduledAt }}>
              {({ handleSubmit, submitting, validating, submitSucceeded }) => {
                return !submitSucceeded ? (
                  <View bg="white" borderRadius="0 0 4px 4px" textAlign="left">
                    <form onSubmit={handleSubmit}>
                      <Title mb={3}>{t('Change payment date')}</Title>
                      <Text mb={3}>
                        {t(
                          'The bill is scheduled to be paid on {{date}}, when do you wish to change this to?',
                          { date: moment(scheduledAt).format('DD MMM YYYY') }
                        )}
                      </Text>

                      <View>
                        <Field
                          name="scheduledAt"
                          label={t('Payment date')}
                          component={DatePicker}
                          alignPopupToTop
                          validate={required}
                          datePickerProps={{
                            modifiers: {
                              disabled: {
                                before: moment().add(1, 'day').toDate(),
                              },
                            },
                          }}
                        />
                      </View>
                      <Caption mt={5} mb={3}>
                        {t(
                          'We’ll send {{consumerFirstName}} a confirmation with the new payment date',
                          { consumerFirstName }
                        )}
                      </Caption>

                      <Button loading={submitting} disabled={validating}>
                        {t('Confirm new date')}
                      </Button>
                      <FormError />
                    </form>
                  </View>
                ) : (
                  <Success
                    close={close}
                    consumerFirstName={consumerFirstName}
                  />
                )
              }}
            </Form>
          </View>
        ) : null
      }
    </Modal>
  )
}

Update.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  consumerFirstName: PropTypes.string.isRequired,
  scheduledAt: PropTypes.string,
}

export default Update
