import { Accordion, Attributes, Settings } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import { CardReader } from '@tabeo/ts-types'
import { useTranslation } from 'react-i18next'

const { Stack } = Settings

function ReaderItem({
  data,
  onEdit,
}: {
  data: CardReader
  onEdit?: (reader: CardReader) => void
}) {
  const {
    label,
    pairing_code: paringCode,
    model,
    serial_number: serialNumber,
  } = data

  const { t } = useTranslation()

  return (
    <Stack.Item p={0} _first={{}}>
      <Accordion title={label} bg="sky.2">
        <Attributes
          sections={[
            [
              { label: t('Registration code'), value: paringCode },
              { label: t('Serial number'), value: serialNumber },
              { label: t('Model'), value: model },
            ],
          ].filter(Boolean)}
        />

        <Button size="sm" className="mt-5" onClick={() => onEdit?.(data)}>
          Edit
        </Button>
      </Accordion>
    </Stack.Item>
  )
}

export default ReaderItem
