import { Button, ButtonGroup } from '@tabeo/scarf'
import ActionCard from 'components/ActionPanel/ActionCard'
import icon from 'components/ActionPanel/assets/approved.svg'
import moment from 'moment'
import PropType, { object } from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const COLLECTION_DAY = 15

function Active({ data, cancelModal }) {
  const currentDay = new Date().getDate()
  const nextPaymentDay = new Date()
  const { t } = useTranslation()
  nextPaymentDay.setDate(COLLECTION_DAY)
  if (currentDay >= COLLECTION_DAY) {
    nextPaymentDay.setMonth(nextPaymentDay.getMonth() + 1)
  }

  return (
    <ActionCard
      icon={icon}
      title={t('Subscription active')}
      body={t(
        `{{consumerFirstName}}’s subscription is up to date with payments and has some benefits to claim. The next payment will be collected on {{date}}.`,
        {
          consumerFirstName: data.consumerFirstName,
          date: moment(nextPaymentDay).format('DD MMM YYYY'),
        }
      )}
      actions={
        <ButtonGroup>
          <Button
            sizeVariant="sm"
            variant="secondary"
            color="ink.2"
            onClick={() => {
              cancelModal.current.open()
            }}
          >
            {t('Cancel subscription')}
          </Button>
        </ButtonGroup>
      }
    />
  )
}

Active.propTypes = {
  data: PropType.object.isRequired,
  cancelModal: object.isRequired,
}

export default Active
