import {
  ConnectOptions,
  DiscoverResult,
  DiscoveryConfig,
  ErrorResponse,
  ExposedError,
  ICancelResponse,
  ICollectConfig,
  IDisconnectResponse,
  IPaymentIntent,
  ISdkManagedPaymentIntent,
  ITipConfiguration,
  PaymentIntentClientSecret,
  Reader,
} from '@stripe/terminal-js'
import config from 'config'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import StripeTerminalContext from '../components/terminal/StripeTerminalContext'

const { ENV } = config

const SDK_NOT_LOADED_ERROR_MESSAGE = "Stripe Terminal SDK wasn't loaded"

function getURLSearchParam(name: string) {
  if (['development', 'staging', 'demo'].includes(ENV)) {
    return new URLSearchParams(window.location.search).get(name)
  }

  return null
}

function useStripeTerminal() {
  const { terminal, connectionStatus, paymentStatus, cache } = useContext(
    StripeTerminalContext
  )
  const mounted = useRef(true)

  const getSimulatedTestCardNumber = useCallback(() => {
    return getURLSearchParam('simulatedTestCardNumber')
  }, [])

  const discoverReaders = useCallback(
    async (config?: DiscoveryConfig) => {
      if (!terminal) {
        throw new Error(SDK_NOT_LOADED_ERROR_MESSAGE)
      }

      const result = await terminal.discoverReaders(config)

      if ((result as ErrorResponse).error) {
        throw (result as ErrorResponse).error
      }

      return (result as DiscoverResult).discoveredReaders
    },
    [terminal]
  )

  const connectReader = useCallback(
    async (reader: Reader, connectOptions?: ConnectOptions) => {
      if (!terminal) {
        throw new Error(SDK_NOT_LOADED_ERROR_MESSAGE)
      }

      const result = await terminal.connectReader(reader, connectOptions)

      if ((result as ErrorResponse).error) {
        throw (result as ErrorResponse).error
      }

      return (result as { reader: Reader }).reader
    },
    [terminal]
  )

  const collectPaymentMethod = useCallback(
    async (
      request: PaymentIntentClientSecret,
      options?: {
        tip_configuration?: ITipConfiguration
        config_override?: ICollectConfig
      }
    ) => {
      if (!terminal) {
        throw new Error(SDK_NOT_LOADED_ERROR_MESSAGE)
      }

      const simulatedTestCardNumber = getSimulatedTestCardNumber()

      if (simulatedTestCardNumber) {
        terminal?.setSimulatorConfiguration({
          testCardNumber: simulatedTestCardNumber,
        })
      }

      const result = await terminal.collectPaymentMethod(request, options)

      if ((result as ErrorResponse).error) {
        throw (result as ErrorResponse).error
      }

      const validResult = result as {
        paymentIntent: ISdkManagedPaymentIntent
      }

      if (simulatedTestCardNumber) {
        // Mock payment method as it's not returned in simulator mode
        validResult.paymentIntent.payment_method = {
          card_present: {
            brand: getURLSearchParam('simulatedTestCardBrand') || 'visa',
            country: getURLSearchParam('simulatedTestCardCountry') || 'GB',
            funding: getURLSearchParam('simulatedTestCardFunding') || 'credit',
            cardholder_name: 'Peter Gomboski',
            exp_month: 3,
            exp_year: 2029,
            last4: '9969',
            networks: {
              available: ['visa'],
            },
            preferred_locales: ['en'],
            read_method: 'contactless_emv',
          },
          created: 1720526121,
          id: 'pm_randomID',
          metadata: {},
          type: 'card_present',
        } as any
      }

      // Removed due to QA feedback
      // Handle physical testcard overrides
      // @ts-ignore
      // const card = validResult.paymentIntent.payment_method?.card_present
      // if (card && card.cardholder_name === 'CARDHOLDER/VISA') {
      //   // @ts-ignore
      //   validResult.paymentIntent.payment_method.card_present = {
      //     ...card,
      //     brand: getURLSearchParam('simulatedTestCardBrand') || card.brand,
      //     funding:
      //       getURLSearchParam('simulatedTestCardFunding') || card.funding,
      //     // Override default test card country
      //     country: getURLSearchParam('simulatedTestCardCountry') || 'GB',
      //   }
      // }

      return validResult.paymentIntent
    },
    [terminal, getSimulatedTestCardNumber]
  )

  const cancelCollectPaymentMethod = useCallback(async () => {
    if (!terminal) {
      throw new Error(SDK_NOT_LOADED_ERROR_MESSAGE)
    }

    const result = await terminal.cancelCollectPaymentMethod()

    if ((result as ErrorResponse).error) {
      throw (result as ErrorResponse).error
    }

    return result as ICancelResponse
  }, [terminal])

  const processPayment = useCallback(
    async (request: ISdkManagedPaymentIntent) => {
      if (!terminal) {
        throw new Error(SDK_NOT_LOADED_ERROR_MESSAGE)
      }

      const result = await terminal.processPayment(request)

      if ((result as ErrorResponse).error) {
        throw (result as ErrorResponse).error
      }

      return (
        result as {
          paymentIntent: IPaymentIntent
        }
      ).paymentIntent
    },
    [terminal]
  )

  const disconnectReader = useCallback(async () => {
    if (!terminal) {
      throw new Error(SDK_NOT_LOADED_ERROR_MESSAGE)
    }

    const result = await terminal.disconnectReader()

    return result
  }, [terminal])

  const getConnectedReader = useCallback(async () => {
    if (!terminal) {
      throw new Error(SDK_NOT_LOADED_ERROR_MESSAGE)
    }

    const result = await terminal.getConnectedReader()

    return result
  }, [terminal])

  const clearReaderDisplay = useCallback(async () => {
    if (!terminal) {
      throw new Error(SDK_NOT_LOADED_ERROR_MESSAGE)
    }

    const result = await terminal.clearReaderDisplay()

    if ((result as ErrorResponse).error) {
      throw (result as ErrorResponse).error
    }

    return result
  }, [terminal])

  const buildConnection = useCallback(
    async (config: { force?: boolean; simulated?: boolean }) => {
      const simulatedTestCardNumber = getSimulatedTestCardNumber()

      const { force = false, simulated = !!simulatedTestCardNumber } = config
      clearTimeout(cache.current.disconnectTimeout)

      if (cache.current.connectionPromise) {
        return cache.current.connectionPromise
      }

      // eslint-disable-next-line no-async-promise-executor
      cache.current.connectionPromise = new Promise(async (resolve, reject) => {
        try {
          const reader = await getConnectedReader()

          if (reader) {
            resolve(reader)
            return
          }

          const readers = await discoverReaders({ simulated })

          const r = await connectReader(readers[0], {
            fail_if_in_use: !force,
          })

          resolve(r)
        } catch (e) {
          reject(e)
        } finally {
          cache.current.connectionPromise = undefined
        }
      })

      return cache.current.connectionPromise
    },
    [
      cache,
      connectReader,
      discoverReaders,
      getConnectedReader,
      getSimulatedTestCardNumber,
    ]
  )

  const connect = useCallback(
    async (config?: {
      force?: boolean
      retry?: boolean
      simulated?: boolean
    }) => {
      const { retry, force, simulated } = config || {}

      if (retry) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise<Reader>(async (resolve, reject) => {
          let r: Reader | undefined
          while (!r) {
            if (!mounted.current) {
              reject(new Error('Unmounted'))
            }

            try {
              // eslint-disable-next-line no-await-in-loop
              r = await buildConnection({ force, simulated })
              resolve(r)
            } catch (e) {
              if ((e as ExposedError).code === 'reader_error') {
                if (
                  (e as ExposedError).message === 'Reader is currently in use.'
                ) {
                  reject(e)
                  return
                }
              }
              // eslint-disable-next-line no-await-in-loop
              await new Promise(r => {
                setTimeout(r, 5000)
              })
            }
          }
        })
      }

      return buildConnection({ force, simulated })
    },
    [buildConnection]
  )

  const disconnect = useCallback(
    async (delay?: number) => {
      if (delay) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise<IDisconnectResponse>(async (resolve, reject) => {
          clearTimeout(cache.current.disconnectTimeout)
          cache.current.disconnectTimeout = window.setTimeout(async () => {
            try {
              const r = await disconnectReader()
              resolve(r)
            } catch (e) {
              reject(e)
            }
          }, delay)
        })
      }
      return disconnectReader()
    },
    [cache, disconnectReader]
  )

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  const isSimulated = useMemo(
    () => !!getSimulatedTestCardNumber(),
    [getSimulatedTestCardNumber]
  )

  return {
    discoverReaders,
    connectReader,
    collectPaymentMethod,
    processPayment,
    disconnectReader,
    getConnectedReader,
    clearReaderDisplay,
    cancelCollectPaymentMethod,
    sdk: terminal,
    connectionStatus,
    paymentStatus,
    connect,
    disconnect,
    isSimulated,
  }
}

export default useStripeTerminal
