import { useMobile } from '@tabeo/scarf'
import { useScript } from 'components/Script'
import config from 'config'
import { useCallback, useEffect, useState } from 'react'
import { useAuth } from 'resources/Auth'

const { TRENGO_KEY } = config

function useTrengoWidget() {
  const {
    data: { isCookiePolicyAccepted },
  } = useAuth()
  const show = useCallback(() => {
    const widget = document.getElementById('trengo-web-widget')
    if (widget) {
      widget.style.display = 'block'
    }
  }, [])

  const hide = useCallback(() => {
    const widget = document.getElementById('trengo-web-widget')
    if (widget) {
      widget.style.display = 'none'
    }
  }, [])

  // Initialize trengo
  useScript('https://static.widget.trengo.eu/embed.js')
  const [, rerender] = useState()
  const isMobile = useMobile()
  useEffect(() => {
    function updateWidgetPosition() {
      const widgetContainer = document.querySelector(
        '.TrengoWidgetLauncher__iframe'
      )
      if (widgetContainer) {
        const offsetY = isCookiePolicyAccepted
          ? '0px'
          : isMobile
          ? '120px'
          : '60px'
        widgetContainer.style['margin-bottom'] = offsetY
        widgetContainer.style['z-index'] = 490
        window.Trengo.extraOffsetY = offsetY
      }
    }
    window.Trengo = window.Trengo || {}
    window.Trengo.key = TRENGO_KEY
    window.Trengo.render = false

    const widget = document.getElementById('trengo-web-widget')
    if (widget) {
      // Show already rendered vidget
      updateWidgetPosition()
      rerender({})
    } else {
      // Render widget first time
      window.Trengo.on_ready = () => {
        window.Trengo.inited = true
        const widget = document.getElementById('trengo-web-widget')
        widget.style.display = 'none'
        window.Trengo.Api.Widget.render()
        setTimeout(() => {
          updateWidgetPosition()
          rerender({})
        }, 10)
      }
    }
  }, [isMobile, isCookiePolicyAccepted])

  function handleOpen(...args) {
    if (window?.Trengo?.inited) {
      show()
      window.Trengo.Api.Widget.open(...args)
    }
  }
  return {
    isReady: window?.Trengo?.inited,
    show,
    hide,
    open: handleOpen,
  }
}

export default useTrengoWidget
