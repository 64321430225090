import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import Overview from './Overview'

export type LinkPaymentOverviewProps = {
  price: number
}

const LinkPaymentOverview = (props: LinkPaymentOverviewProps) => {
  const { data: merchant } = useMerchant()

  const { t } = useTranslation()

  const { price } = props

  return (
    <Overview
      hidePreview={!price}
      placeholder={t(
        'Start typing in the Transaction section on the left to see the payment link summary'
      )}
      price={price}
      isAmexCapable={!!merchant?.activeFeeGroup?.isAmexCapable}
    />
  )
}

export default LinkPaymentOverview
