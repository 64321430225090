import React, { useEffect } from 'react'
import ScarfProps from '../types/ScarfProps'
import useSearchQueryState from '../hooks/useSearchQueryState'
import Flex from './Flex'
import { Caption } from './Text'
import View from './View'

export type TabScreensProps = {
  tabs: {
    key: string
    label: string
    component:
      | React.FunctionComponent<Record<string, unknown>>
      | React.ComponentClass
    labelProps?: ScarfProps
  }[]
  id?: string
  defaultTab?: string
  context?: Record<string, unknown>
}

function TabScreens({ tabs, id, defaultTab, context }: TabScreensProps) {
  const [tab, setTab] = useSearchQueryState(
    `${id ? `${id}-` : ''}tab`,
    defaultTab || tabs[0].key
  )

  const current = tabs.find((t) => t.key === tab)

  useEffect(() => {
    if (!current) {
      setTab(defaultTab || tabs[0].key)
    }
  }, [current, defaultTab, setTab, tabs])

  return (
    <>
      <View
        borderBottom={1}
        mb={6}
        position="sticky"
        top="0"
        pt={3}
        bg="white"
        zIndex="50"
      >
        <Flex as="nav" justifyContent="flex-start" mb="-1px" overflowX="auto">
          {tabs.map((t) => (
            <Caption
              as="label"
              key={t.label}
              borderStyle="solid"
              borderBottomWidth="2px"
              borderColor={t.key === tab ? 'primary.3' : 'transparent'}
              color={t.key === tab ? 'primary.3' : 'ink.2'}
              fontWeight="semibold"
              pb={2}
              mr={4}
              cursor="pointer"
              tabIndex={t.key === tab ? 0 : undefined}
              onClick={() => setTab(t.key)}
              onKeyDown={(e: React.KeyboardEvent) =>
                e.keyCode === 13 && setTab(t.key)
              }
              _focus={{
                outline: 'none',
                borderColor: 'primary.2',
                color: 'primary.2',
              }}
              _active={{
                outline: 'none',
              }}
              whiteSpace="nowrap"
              {...(t.labelProps || {})}
            >
              {t.label}
            </Caption>
          ))}
        </Flex>
      </View>
      {current
        ? React.createElement(current?.component, {
            ...context,
          })
        : null}
    </>
  )
}

export default TabScreens
