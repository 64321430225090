import {
  Button,
  Clickable,
  ExpansionPanel,
  Flex,
  Modal,
  Subtitle,
  Text,
  View,
} from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import icon from './assets/red_x.svg'

function Header({ children }) {
  return (
    <Flex>
      <View as="img" src={icon} mr={2} />
      <Text fontWeight="semibold">{children}</Text>
    </Flex>
  )
}

Header.propTypes = {
  children: PropTypes.node,
}

const FormattedContent = styled(Text)`
  font-size: 14px;
  margin-left: 22px;

  ul {
    list-style: disc;
    margin: 12px 0 12px 15px;

    li {
      margin: 8px 0;

      :last-child {
        margin-bottom: 0;
      }

      :first-child {
        margin-top: 0;
      }
    }

    :last-child {
      margin-bottom: 0;
    }

    :first-child {
      margin-top: 0;
    }
  }

  p {
    margin: 8px 0;

    :last-child {
      margin-bottom: 0;
    }

    :first-child {
      margin-top: 0;
    }
  }

  i {
    font-style: italic;
  }
`

function DeclinedReasonsModal({ merchant, reasons = [], innerRef, ...rest }) {
  const {
    flags: { is_regulated_loan_capable: isRegulatedLoanCapable },
  } = merchant || {}
  const { t } = useTranslation()

  return (
    <Modal ref={innerRef}>
      {({ isOpen, close }) => {
        return isOpen ? (
          <View
            maxWidth={['90%', '460px']}
            mx="auto"
            bg="white"
            borderRadius={1}
            boxShadow={1}
            p={[5, 10]}
            {...rest}
          >
            <Subtitle pb={1} color="default.primary" textAlign="center">
              {t('Why your customer didn’t qualify')}
            </Subtitle>
            {reasons.includes('credit_profile') && (
              <ExpansionPanel
                header={<Header>{t('Credit profile unsuitable')}</Header>}
                my={4}
              >
                <FormattedContent>
                  {/* TODO: check */}
                  <Trans>
                    <p>
                      The customer’s credit profile doesn’t suit the minimum
                      requirements set by our lenders, this could be because:
                    </p>
                    <ul>
                      <li>The customer’s credit score isn’t high enough</li>
                      <li>
                        There’s a record of an <i>adverse credit event</i> such
                        as a missed credit card payment or a bankruptcy
                      </li>
                      <li>
                        The credit agency holds incorrect or outdated
                        information about your customer
                      </li>
                    </ul>
                    <p>
                      To understand what might be negatively impacting their
                      credit profile, we encourage customers to register with a
                      credit monitoring service such as{' '}
                      <Clickable
                        as="a"
                        href="https://creditkarma.co.uk/"
                        target="_blank"
                        fontWeight="regular"
                      >
                        creditkarma.co.uk
                      </Clickable>
                      .
                    </p>
                  </Trans>
                </FormattedContent>
              </ExpansionPanel>
            )}
            {reasons.includes('affordability') && (
              <ExpansionPanel
                header={<Header>{t('Insufficient affordability')}</Header>}
                my={4}
              >
                <FormattedContent>
                  <p>
                    {t(
                      'After comparing your customer’s monthly incomings with the outgoings, we’ve determined that the customer wouldn’t be able to afford the monthly payments with any of the options you’ve sent them.'
                    )}
                  </p>
                  {!isRegulatedLoanCapable && (
                    <p>
                      {t(
                        'If you find that your customers often don’t qualify for this reason, you might want to consider offering longer-term payment options.'
                      )}
                    </p>
                  )}
                </FormattedContent>
              </ExpansionPanel>
            )}
            {reasons.includes('knockouts') && (
              <ExpansionPanel
                header={<Header>{t('Minimum requirement')}</Header>}
                my={4}
              >
                <FormattedContent>
                  <p>
                    {t(
                      'Your customer does not meet our minimum requirement criteria for age, monthly income or employment status.'
                    )}
                  </p>
                </FormattedContent>
              </ExpansionPanel>
            )}
            {reasons.includes('information_missing') && (
              <ExpansionPanel
                header={<Header>{t('Information missing')}</Header>}
                my={4}
              >
                <FormattedContent>
                  <p>
                    {t(
                      'Some of the information in the application or in the documents provided is missing or inconsistent.'
                    )}
                  </p>
                </FormattedContent>
              </ExpansionPanel>
            )}
            <View mt={6}>
              <Button type="button" onClick={close} width="100%">
                {t('Close')}
              </Button>
            </View>
          </View>
        ) : null
      }}
    </Modal>
  )
}

DeclinedReasonsModal.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  merchant: PropTypes.object,
  reasons: PropTypes.array,
}

export default DeclinedReasonsModal
