import { View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import Business from './Business'
import Contact from './Contact'
import Documents from './Documents'

function General({ resource }) {
  return (
    <View>
      <Business resource={resource} />
      <Contact resource={resource} />
      <Documents resource={resource} />
    </View>
  )
}

General.propTypes = {
  resource: PropTypes.object.isRequired,
}

export default General
