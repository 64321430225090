import { Feed, View } from '@tabeo/scarf'
import moment from 'moment'
import { TFunction, useTranslation } from 'react-i18next'

import cardBlue from 'components/checkout-payment/assets/card-blue.svg'
import planeYellow from 'components/checkout-payment/assets/plane-yellow.svg'
import rightArrowGreen from 'components/checkout-payment/assets/right-arrow-green.svg'
import tickGreen from 'components/checkout-payment/assets/tick-green.svg'
import xGray from 'components/checkout-payment/assets/x-gray.svg'
import xRed from 'components/checkout-payment/assets/x-red.svg'

const states = {
  CREATED: 'created',
  AUTHORIZED: 'authorized',
  CAPTURED: 'captured',
  SETTLED: 'settled',
  CANCELED: 'canceled',
  CANCEL_FAILED: 'cancel_failed',
}

function getStateVariables({ data, t }: { data: any; t: TFunction }) {
  switch (data.to) {
    case states.CREATED:
      return {
        titleContent: t('Created'),
        Icon: (p: any) => <View as="img" src={planeYellow} {...p} />,
      }
    case states.CANCELED:
      return {
        titleContent: t('Cancelled'),
        Icon: (p: any) => <View as="img" src={xGray} {...p} />,
      }
    case states.CANCEL_FAILED:
      return {
        titleContent: t('Cancellation attempt failed'),
        Icon: (p: any) => <View as="img" src={xRed} {...p} />,
      }
    case states.AUTHORIZED:
      return {
        titleContent: t('Authorised'),
        Icon: (p: any) => <View as="img" src={cardBlue} {...p} />,
      }
    case states.CAPTURED:
      return {
        titleContent: t('Paid'),
        Icon: (p: any) => <View as="img" src={rightArrowGreen} {...p} />,
      }
    case states.SETTLED:
      return {
        titleContent: t('Settled'),
        Icon: (p: any) => <View as="img" src={tickGreen} {...p} />,
      }
    default:
      return null
  }
}

function State({ data, ...rest }: any) {
  const { t } = useTranslation()

  const variables = getStateVariables({ data, t })

  if (!variables) {
    return null
  }

  const { titleContent, Icon } = variables

  return (
    <Feed.Item icon={(p: any) => <Icon width="20px" {...p} />} {...rest}>
      <Feed.Item.Title mb={1}>{titleContent}</Feed.Item.Title>
      <Feed.Item.Date>
        {moment(data.at).format('DD MMM YYYY, hh:mm a')}
      </Feed.Item.Date>
    </Feed.Item>
  )
}

export default State
