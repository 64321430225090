import React from 'react'
import {
  ConnectionStatus,
  PaymentStatus,
  Reader,
  Terminal,
} from '@stripe/terminal-js'

export interface Cache {
  disconnectTimeout?: number
  connectionPromise?: Promise<Reader>
}

const StripeTerminalContext = React.createContext<{
  terminal?: Terminal
  connectionStatus?: ConnectionStatus
  paymentStatus?: PaymentStatus
  cache: React.MutableRefObject<Cache>
}>({ cache: { current: {} } })

export default StripeTerminalContext
