import { View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'

const CustomOption = React.forwardRef(
  (
    {
      isFocused,
      option,
      onSelect,
      onFocus,
      children,
      isDisabled,
      opacity,
      className,
    },
    ref
  ) => {
    const handleMouseDown = event => {
      event.preventDefault()
      event.stopPropagation()

      if (isDisabled) {
        return
      }

      onSelect(option, event)
    }
    const handleMouseEnter = event => {
      onFocus(option, event)
    }
    const handleMouseMove = event => {
      if (isFocused) return
      onFocus(option, event)
    }

    return (
      <View
        ref={ref}
        onMouseDown={handleMouseDown}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        opacity={opacity}
        className={className}
      >
        {children}
      </View>
    )
  }
)

CustomOption.propTypes = {
  option: PropTypes.object,
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  isFocused: PropTypes.bool,
  onFocus: PropTypes.func,
  children: PropTypes.any,
  isDisabled: PropTypes.bool,
  opacity: PropTypes.string,
}

export default CustomOption
