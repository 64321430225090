import { Feed, Panel } from '@tabeo/scarf'
import Refund from 'components/checkout-payment/ActivityPanel/Refund'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NormalizedLinkPayment } from 'types/NormalizedLinkPayment'
import { NormalizedTerminalPayment } from 'types/NormalizedTerminalPayment'
import State from './State'

function mapEventsToStateTransitions(
  data: NormalizedTerminalPayment | NormalizedLinkPayment
) {
  const { events } = data

  const createdEvent = events?.find(
    ({ eventName }) => eventName === 'CreateTerminalPaymentSuccess'
  )

  const capturedEvent = events?.find(
    ({ eventName }) => eventName === 'ChargeSucceeded'
  )

  const canceledEvent = events?.find(
    ({ eventName }) => eventName === 'CancelTerminalPaymentSuccess'
  )

  const cancelFailedEvent = events?.find(
    ({ eventName }) => eventName === 'CancelTerminalPaymentFail'
  )

  const settledEvent = events?.find(
    ({ eventName }) => eventName === 'MerchantTerminalPaymentPaymentReceived'
  )

  return [
    createdEvent && {
      id: `event-${createdEvent.id}`,
      type: 'state',
      date: createdEvent.createdAt,
      data: {
        to: 'created',
        at: createdEvent.createdAt,
      },
    },
    capturedEvent && {
      id: `event-${capturedEvent.id}`,
      type: 'state',
      date: capturedEvent.createdAt,
      data: {
        to: 'captured',
        at: capturedEvent.createdAt,
      },
    },
    canceledEvent && {
      id: `event-${canceledEvent.id}`,
      type: 'state',
      date: canceledEvent.createdAt,
      data: {
        to: 'canceled',
        at: canceledEvent.createdAt,
      },
    },
    cancelFailedEvent && {
      id: `event-${cancelFailedEvent.id}`,
      type: 'state',
      date: cancelFailedEvent.createdAt,
      data: {
        to: 'cancel_failed',
        at: cancelFailedEvent.createdAt,
      },
    },
    settledEvent && {
      id: `event-${settledEvent.id}`,
      type: 'state',
      date: settledEvent.createdAt,
      data: {
        to: 'settled',
        at: settledEvent.createdAt,
      },
    },
  ].filter(Boolean) as {
    id: string
    type: string
    date: string
    data: any
  }[]
}

export function getStateTransitionsForRefunds(
  data: NormalizedTerminalPayment | NormalizedLinkPayment
) {
  const { refunds } = data

  return [
    ...(refunds?.map((r: any) => ({
      id: `${r.id}-new`,
      type: 'refund',
      date: r.createdAt,
      data: {
        ...r,
        to: 'new',
        at: r.createdAt,
      },
    })) || []),
    ...(refunds
      ?.filter((r: any) => r.status === 'succeeded')
      .map((r: any) => ({
        id: `${r.id}-succeeded`,
        type: 'refund',
        date: r.updatedAt,
        data: {
          ...r,
          isPartial: r.amount !== data.amount,
          to: 'succeeded',
          at: r.updatedAt,
        },
      })) || []),
    ...(refunds
      ?.filter((r: any) => r.status === 'canceled')
      .map((r: any) => ({
        id: `${r.id}-canceled`,
        type: 'refund',
        date: r.updatedAt,
        data: {
          ...r,
          to: 'canceled',
          at: r.updatedAt,
        },
      })) || []),
    ...(refunds
      ?.filter((r: any) => r.status === 'declined')
      .map((r: any) => ({
        id: `${r.id}-declined`,
        type: 'refund',
        date: r.updatedAt,
        data: {
          ...r,
          to: 'declined',
          at: r.updatedAt,
        },
      })) || []),
    ...(refunds
      ?.filter((r: any) => r.status === 'failed')
      .map((r: any) => ({
        id: `${r.id}-failed`,
        type: 'refund',
        date: r.updatedAt,
        data: {
          ...r,
          to: 'failed',
          at: r.updatedAt,
        },
      })) || []),
  ]
}

export type ActivityPanelProps = {
  data: NormalizedTerminalPayment
} & React.ComponentProps<typeof Panel>

function ActivityPanel({ data }: ActivityPanelProps) {
  const { t } = useTranslation()

  const sortedItems = [
    ...mapEventsToStateTransitions(data),
    ...getStateTransitionsForRefunds(data),
  ].sort((a, b) => (a.date < b.date ? 1 : -1))

  return (
    <Panel title={t('Activity')} data-testid="activity-panel">
      <Feed.Builder
        itemBuilders={{
          state: State,
          refund: Refund,
        }}
        items={sortedItems}
      />
    </Panel>
  )
}

export default ActivityPanel
