import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import normalize from './normalizers/lead'

const subresources = `subResource=${['product', 'appointment'].join(
  '&subResource='
)}`

class Leads extends Resource {
  getInitialData() {
    return {
      items: null,
      totalCount: 0,
    }
  }

  getInitialConfig() {
    return {
      refreshInterval: 60 * 1000,
    }
  }

  async fetch() {
    const { activeTabs = [], searchQuery, limit = 10, offset = 0 } = this.params
    const statuses = activeTabs.reduce((mem, tab) => {
      mem = [...mem, ...tab.statuses]
      return mem
    }, [])
    const condition = `&condition=status:[${statuses.join(',')}]`
    const pagination = `&limit=${limit}&offset=${offset}`
    const endpoint = searchQuery
      ? `/merchant/lead-search?${subresources}&order=updatedAt:desc&searchPhrase=${searchQuery}`
      : `/merchant/leads?${subresources}&order=updatedAt:desc`

    const {
      data: { leads },
      totalCount,
    } = await api.get(`${endpoint}${condition}${pagination}`, {
      responseDataPath: 'data',
    })

    const data = {
      totalCount,
      items: leads.map(o => normalize(o)),
    }

    this.data = data

    return data
  }
}

export default Leads
export const useLeads = createResourceHook(Leads)
