import { PlusIcon } from '@heroicons/react/24/solid'
import { Title } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import Notifications from 'components/Notifications'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Plans from './Plans'
import Subscriptions from './Subscriptions'

function PlansV2() {
  const { t } = useTranslation()

  return (
    <LayoutSideNavbar>
      <Container>
        <Notifications mb={6} />
        <div className="mb-4 flex justify-between">
          <Title>{t('Plans')}</Title>
          <Button
            variant="secondary"
            as={Link}
            to="/new-subscription"
            leadingIcon={p => <PlusIcon {...p} />}
          >
            {t('Invite member')}
          </Button>
        </div>
        <div className="space-y-6">
          <Subscriptions />
          <Plans />
        </div>
      </Container>
    </LayoutSideNavbar>
  )
}

export default PlansV2
