import React, { Children, cloneElement, isValidElement } from 'react'
import ScarfProps from '../types/ScarfProps'
import Flex from './Flex'
import View from './View'

export type ButtonGroupProps = ScarfProps & {
  spacing?: number | string
  shouldWrapChildren?: boolean
}

const ButtonGroup = ({
  children,
  spacing = 4,
  shouldWrapChildren,
  ...rest
}: ButtonGroupProps) => {
  const validChildrenArray = Children.toArray(children).filter(isValidElement)

  return (
    <Flex
      justifyContent={['stretch', 'flex-start']}
      alignItems={['stretch', 'center']}
      flexDirection={['column', 'row']}
      flex={[1, null]}
      {...rest}
    >
      {validChildrenArray.map((child, index) => {
        const isLastChild = validChildrenArray.length === index + 1
        const isFirstChlid = index === 0
        const spacingProps = {
          mr: isLastChild ? null : [0, spacing],
          mb: isLastChild ? null : [spacing, 0],
          borderRightWidth:
            spacing === 0 && !isLastChild
              ? ['1px !important', '0px !important']
              : null,
          borderBottomWidth:
            spacing === 0 && !isLastChild
              ? ['0px !important', '1px !important']
              : null,
          borderRadius:
            spacing !== 0
              ? 1
              : isLastChild
              ? ['0 0 4px 4px', '0 4px 4px 0']
              : isFirstChlid
              ? ['4px 4px 0 0', '4px 0 0 4px']
              : 0,
        }

        if (shouldWrapChildren) {
          return (
            <View
              display="inline-block"
              {...spacingProps}
              key={`buttongroup-view-wrapper-${index}`}
            >
              {child}
            </View>
          )
        }
        return cloneElement(child, spacingProps)
      })}
    </Flex>
  )
}

export default ButtonGroup
