import { LinkPayment } from '@tabeo/ts-types'
import { NormalizedLinkPayment } from 'types/NormalizedLinkPayment'
import checkoutPaymentNormalizer from './checkoutPayment'

const normalize = (linkPayment: LinkPayment): NormalizedLinkPayment => {
  const checkoutPayment = checkoutPaymentNormalizer(linkPayment)
  return {
    ...linkPayment,
    ...checkoutPayment,
    _type: 'link_payment',
  }
}

export default normalize
