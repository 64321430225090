import styled from 'styled-components'
import { Caption } from '../../Text'

const Date = styled(Caption)``

Date.defaultProps = {
  color: 'ink.2',
  fontSize: 0,
}

export default Date
