import NewTransaction from 'pages/NewTransaction'
import NewTransactionV2 from 'pages/NNTS'
import { useMerchant } from 'resources/Merchant'

function NewTransactionRouter(props: any) {
  const { data: merchant } = useMerchant()

  if (merchant?.flags?.is_new_transaction_screen_v2_capable) {
    return <NewTransactionV2 {...props} />
  }

  return <NewTransaction {...props} />
}

export default NewTransactionRouter
