import { ScarfProvider } from '@tabeo/scarf'
import { ScarfContextProvider, Toaster } from '@tabeo/scarf2'
import EnvWarning from 'components/EnvWarning'
import ErrorBoundary from 'components/ErrorBoundary'
import LoadingScreen from 'components/LoadingScreen'
import Routes from 'components/Router'
import Script from 'components/Script'
import { browserHistory } from 'components/sentry'
import TailwindColorConfig from 'components/TailwindColorConfig'
import config from 'config'
import i18n from 'i18n'
import { I18nextProvider } from 'react-i18next'
import { Router } from 'react-router-dom'
import { StripeProvider } from 'react-stripe-elements'
import { createGlobalStyle } from 'styled-components'
import { ThemeDataConsumer, ThemeDataProvider } from './ThemeData'

const CSSReset = createGlobalStyle`
body {
  line-height: 1.5;
}
`

const App = () => {
  // useUserGuiding()

  return (
    <I18nextProvider i18n={i18n}>
      <Router history={browserHistory}>
        <CSSReset />
        <ThemeDataProvider>
          <ThemeDataConsumer>
            {({ theme }) => (
              <ScarfProvider
                primaryColor="sea"
                {...theme}
                theme={{
                  breakpoints: ['960px', '1400px'],
                }}
                locale={config.LOCALE}
                countryCode={config.COUNTRY}
                pcaKey={config.PCA_API_KEY}
              >
                <ScarfContextProvider
                  countryCode={config.COUNTRY}
                  pcaKey={config.PCA_API_KEY}
                >
                  <TailwindColorConfig />
                  <ErrorBoundary>
                    <Script
                      src="https://js.stripe.com/v3/"
                      fallback={<LoadingScreen />}
                    >
                      <StripeProvider apiKey={config.STRIPE_KEY}>
                        <>
                          <EnvWarning />
                          <Routes />
                          {/* <CookiesPolicyBanner /> */}
                        </>
                      </StripeProvider>
                    </Script>
                  </ErrorBoundary>
                </ScarfContextProvider>
              </ScarfProvider>
            )}
          </ThemeDataConsumer>
        </ThemeDataProvider>
      </Router>
      <Toaster />
    </I18nextProvider>
  )
}

App.propTypes = {}

export default App
