import {
  Banner,
  Box,
  Button,
  Caption,
  Flex,
  Heading,
  Modal,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import FieldError from 'components/Form/FieldError'
import Pending from 'components/Payment/Pending'
import Card from 'components/Transaction/ActionPanel/Card'
import usePromise from 'hooks/usePromise'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import icon from './assets/ready_to_confirm.svg'

function ReadyToConfirm({ transaction, ...rest }) {
  const { data, confirm, cancel } = transaction
  const { consumerFirstName, stateMachineTransitions } = data
  const [triggerConfirm, confirmState] = usePromise(confirm)
  const [triggerCancel, cancelState] = usePromise(cancel)
  const event = stateMachineTransitions.find(e =>
    ['loan_agreement_accepted'].includes(e.to)
  )
  const didFirstPaymentFailed = data.status === 'first_payment_capture_failed'
  const modal = React.useRef()
  const { t } = useTranslation()

  return (
    <>
      {confirmState.pending && (
        <Modal isOpenByDefault closable={false} ref={modal}>
          {() => (
            <Box maxWidth="460px" mx="auto">
              <Pending
                headline={t(`Charging {{consumerFirstName}}'s first payment`, {
                  consumerFirstName,
                })}
              />
            </Box>
          )}
        </Modal>
      )}
      {didFirstPaymentFailed && (
        <Banner title={t('Card declined')} color="red">
          <Caption>
            {t(
              "The card payment was declined. Please ask your customer to contact their bank. The customer should also make sure to have {{amount}} available. The loan won't be confirmed until the first payment goes through.",
              {
                amount: format(data.paymentPlan.firstPaymentAmount),
              }
            )}
          </Caption>
        </Banner>
      )}
      <Card {...rest}>
        <Flex justifyContent="flex-start" mb={4}>
          <View as="img" src={icon} mr={4} />
          <Heading fontWeight="semibold">{t('Ready to confirm')}</Heading>
        </Flex>
        <Caption color="ink.1" mb={4}>
          {t(
            'The customer has signed the loan agreement and has added their debit card. You can now confirm the transaction by charging the first payment, this will start the loan. Your payout will be issued shortly after.',
            {
              elapsedTime: moment().to(event.at),
            }
          )}
        </Caption>
        <Flex
          flexDirection={['column', 'row']}
          justifyContent="flex-start"
          alignItems={['stretch', 'center']}
        >
          <Button
            variant="primary"
            sizeVariant="sm"
            mr={[0, 4]}
            mb={[4, 0]}
            onClick={triggerConfirm}
            loading={confirmState.pending}
            disabled={confirmState.success}
          >
            {t('Confirm and charge first payment')}
          </Button>
          <Button
            variant="secondary"
            sizeVariant="sm"
            onClick={triggerCancel}
            loading={cancelState.pending}
          >
            {t('Cancel transaction')}
          </Button>
        </Flex>
        {(confirmState.error || cancelState.error) && (
          <FieldError
            message={t('There was an error, please try again later.')}
            mt={4}
          />
        )}
      </Card>
    </>
  )
}

ReadyToConfirm.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default ReadyToConfirm
