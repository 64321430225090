import React from 'react'
import scarfProps from './scarfProps'

export const omit = (obj: any, keys: string[]) => {
  const next: any = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-continue
    if (keys.indexOf(key) > -1) continue
    next[key] = obj[key]
  }
  return next
}

export type CleanTagProps = {
  as?: React.ElementType
  omitProps?: string[]
  [x: string]: unknown
}

const CleanTag = React.forwardRef<HTMLElement, CleanTagProps>(
  ({ as: Component, omitProps = [], ...props }, ref) =>
    React.createElement(Component || 'div', {
      ref,
      ...omit(props, [...scarfProps, ...omitProps]),
    })
)

CleanTag.displayName = 'CleanTag'

export default CleanTag
