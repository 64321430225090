import { Feed, Panel, View } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import startedIcon from 'components/ActionPanel/assets/applying.svg'
import subscribedIcon from 'components/ActionPanel/assets/approved.svg'
import cardBlueIcon from 'components/ActionPanel/assets/card_blue.svg'
import cardDeclinedIcon from 'components/ActionPanel/assets/card_declined.svg'
import sentIcon from 'components/ActionPanel/assets/sent.svg'
import userIcon from 'components/ActionPanel/assets/user_green.svg'
import canceledIcon from 'components/ActionPanel/assets/x-grey.svg'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const { DateCaption, TitleCaption: Caption } = Feed

function Title({ item, subscription }) {
  const { t } = useTranslation()
  if (item.type === 'sent') {
    const merchnatUserName = `${subscription.merchantUser.firstName} ${subscription.merchantUser.lastName[0]}.`
    return (
      <Caption>
        {t(
          '{{merchnatUserName}} invited {{consumerFirstName}} to join {{plan}}',
          {
            merchnatUserName,
            consumerFirstName: subscription.consumerFirstName,
            plan: subscription.plan.title,
          }
        )}
      </Caption>
    )
  }
  if (item.type === 'started') {
    return (
      <Caption>
        {t('{{consumerFirstName}} {{consumerLastName}} started signing up', {
          consumerFirstName: subscription.consumerFirstName,
          consumerLastName: subscription.consumerLastName,
        })}
      </Caption>
    )
  }
  if (item.type === 'subscribed') {
    return <Caption>{t('Subscription started')}</Caption>
  }
  if (item.type === 'payment') {
    return (
      <Caption>
        <b>{format(item.data.amount)}</b> {t('payment')}{' '}
        {item.data.status === 'failed' ? t('failed') : t('succeeded')}{' '}
      </Caption>
    )
  }
  if (item.type === 'claim') {
    return (
      <Caption>
        {item.data.person
          ? t(
              '{{consumerFirstName}} {{consumerLastName}} claimed the benefit {{benefitName}} with {{doctorName}}',
              {
                consumerFirstName: subscription.consumerFirstName,
                consumerLastName: subscription.consumerLastName,
                benefitName: item.data.claimTitle,
                doctorName: item.data.person,
              }
            )
          : t(
              '{{consumerFirstName}} {{consumerLastName}} claimed the benefit {{benefitName}}',
              {
                consumerFirstName: subscription.consumerFirstName,
                consumerLastName: subscription.consumerLastName,
                benefitName: item.data.claimTitle,
              }
            )}
      </Caption>
    )
  }
  if (item.type === 'canceled') {
    return <Caption>{t('Subscription cancelled')}</Caption>
  }
  return null
}

Title.propTypes = {
  item: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
}

function Icon({ item, ...rest }) {
  let icon
  if (item.type === 'sent') {
    icon = sentIcon
  }
  if (item.type === 'started') {
    icon = startedIcon
  }
  if (item.type === 'subscribed') {
    icon = subscribedIcon
  }
  if (item.type === 'claim') {
    icon = userIcon
  }
  if (item.type === 'payment') {
    icon = cardBlueIcon
  }
  if (item.type === 'payment' && item.data.status === 'failed') {
    icon = cardDeclinedIcon
  }
  if (item.type === 'canceled') {
    icon = canceledIcon
  }
  if (icon) {
    return <View as="img" src={icon} {...rest} />
  }
  return null
}

Icon.propTypes = {
  item: PropTypes.object.isRequired,
}

function ActivityFeed({ data, ...rest }) {
  const {
    plan: { planPerks, planFeatures },
    planPerkClaims,
    planFeatureClaims,
    stateMachineTransitions,
    createdAt,
    payments,
  } = data
  const { t } = useTranslation()

  const paymentEvents = payments.map(p => ({
    id: `payment-${p.id}`,
    type: 'payment',
    date: p.scheduledAt,
    data: p,
  }))

  const mergedFeatureClaims = planFeatureClaims.map(b => ({
    id: `feature-${b.id}`,
    type: 'claim',
    date: b.claimedAt,
    data: {
      ...b,
      claimTitle: planFeatures.find(i => i.id === b.planFeatureID)?.title,
    },
  }))

  const mergedPerkClaims = planPerkClaims.map(b => ({
    id: `perk-${b.id}`,
    type: 'claim',
    date: b.claimedAt,
    data: {
      ...b,
      claimTitle: planPerks.find(i => i.id === b.planPerkID)?.title,
    },
  }))

  const claims = [...mergedFeatureClaims, ...mergedPerkClaims]

  const subscriptionStartedEvent = stateMachineTransitions.find(
    s => s.to === 'subscription_agreement_accepted'
  )
  const subscriptionStarted = subscriptionStartedEvent && {
    id: subscriptionStartedEvent.uuid,
    type: 'subscribed',
    date: subscriptionStartedEvent.at,
    data: {
      ...subscriptionStartedEvent,
    },
  }

  const signupStartedEvent = stateMachineTransitions.find(
    s => s.to === 'personal_details_created'
  )
  const signupStarted = signupStartedEvent && {
    id: signupStartedEvent.uuid,
    type: 'started',
    date: signupStartedEvent.at,
    data: {
      ...signupStartedEvent,
    },
  }

  const canceledEvent = stateMachineTransitions.find(s => s.to === 'canceled')
  const canceled = canceledEvent && {
    id: canceledEvent.uuid,
    type: 'canceled',
    date: canceledEvent.at,
    data: {
      ...canceledEvent,
    },
  }

  const sent = {
    id: 'sent',
    type: 'sent',
    date: createdAt,
    data: {},
  }

  const history = [
    signupStarted,
    sent,
    subscriptionStarted,
    ...claims,
    ...paymentEvents,
    canceled,
  ]
    .filter(Boolean)
    .sort((a, b) => (a.date < b.date ? 1 : -1))

  return (
    <Panel title={t('Activity')} data-testid="activity-feed-panel" {...rest}>
      <Feed
        history={history}
        renderIcon={h => <Icon item={h} width="24px" height="24px" />}
        renderTitle={h => <Title item={h} subscription={data} />}
        renderDate={h => (
          <DateCaption>
            {moment(h.date).format('DD MMM YYYY, hh:mm a')}
          </DateCaption>
        )}
      />
    </Panel>
  )
}

ActivityFeed.propTypes = {
  data: PropTypes.object,
}

export default ActivityFeed
