import { Button, ButtonGroup } from '@tabeo/scarf'
import ActionCard from 'components/ActionPanel/ActionCard'
import icon from 'components/ActionPanel/assets/unsuccessful.svg'
import PropType from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

function Inactive({ data, cancelModal }) {
  const { t } = useTranslation()
  return (
    <ActionCard
      icon={icon}
      title={t('Subscription paused')}
      body={t(
        `{{consumerFirstName}}'s subscription has paused because one or more of his subscription payments have failed. Until the payment is settled {{consumerFirstName}} should not have access to the plan benefits.`,
        {
          consumerFirstName: data.consumerFirstName,
        }
      )}
      actions={
        <ButtonGroup>
          <Button
            sizeVariant="sm"
            variant="secondary"
            color="ink.2"
            onClick={() => {
              cancelModal.current.open()
            }}
          >
            {t('Cancel subscription')}
          </Button>
        </ButtonGroup>
      }
    />
  )
}

Inactive.propTypes = {
  data: PropType.object.isRequired,
  cancelModal: PropType.object.isRequired,
}

export default Inactive
