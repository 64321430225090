import { RouteProps } from 'react-router-dom'

export default function pageVersion(
  v1: RouteProps['component'],
  v2: RouteProps['component'],
  shouldUseV2?: boolean
) {
  if (shouldUseV2) {
    return v2
  }

  return v1
}
