import { Modal } from '@tabeo/scarf'
import React from 'react'
import Review from '.'

const ReviewModal = React.forwardRef((props: any, ref: any) => {
  return (
    <Modal ref={ref}>
      {({ isOpen, close, data }) => {
        return isOpen ? <Review id={data.id} modal={{ close }} /> : null
      }}
    </Modal>
  )
})

export default ReviewModal
