import { Tag } from '@tabeo/scarf'
import { TerminalPaymentStatus } from '@tabeo/ts-types'
import { t } from 'i18n'
import { ComponentProps } from 'react'

type TagProps = ComponentProps<typeof Tag>

const statuses: {
  [key in TerminalPaymentStatus]: {
    color: TagProps['color']
    label: string
  }
} = {
  undefined: { color: 'grey', label: t('Failed') },
  c: { color: 'yellow', label: t('Created') },
  pi_err: { color: 'grey', label: t('Failed') },
  auth: { color: 'blue', label: t('Authorised') },
  pi_cancelled: { color: 'grey', label: t('Cancelled') },
  pi_cancelled_error: { color: 'red', label: 'Cancellation failed' },
  p: { color: 'green', label: t('Paid') },
  refund_req: { color: 'orange', label: t('Refund requested') },
  fr: { color: 'darkGrey', label: t('Refunded') },
  pr: { color: 'darkGrey', label: t('Partially refunded') },
  s: { color: 'green', label: t('Settled') },
}

interface Props extends TagProps {
  status: TerminalPaymentStatus
}

const TerminalPaymentStatusTag = ({ status, ...rest }: Props) => {
  const currentState = statuses[status] || {}
  const color = currentState.color || 'grey'
  const label = currentState.label || status.replaceAll('_', ' ')
  return (
    <Tag color={color} {...rest}>
      {label}
    </Tag>
  )
}

export default TerminalPaymentStatusTag
