import { Button, Caption, Flex, Title, View } from '@tabeo/scarf'
import Summary from 'components/terminal/Summary'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTerminalPayment } from 'resources/TerminalPayment'
import failedImg from '../assets/failed.png'
import internationalCardImg from '../assets/international-card.png'
import noAmexImg from '../assets/no-amex.png'
import TerminalPaymentContext from '../Context'

function PaymentFailed() {
  const { t } = useTranslation()

  const { state, dispatch, modal } = useContext(TerminalPaymentContext)
  const { data: tp } = useTerminalPayment({
    id: state.terminalPaymentId,
  })

  const { illustrationImg, title, description, primaryButtonLabel } =
    useMemo(() => {
      if (
        state?.paymentIntent?.last_payment_error?.type === 'card_error' &&
        state?.paymentIntent?.last_payment_error?.decline_code === 'brand'
      ) {
        return {
          illustrationImg: noAmexImg,
          title: t('Amex not supported'),
          description: t('The patient should use a different card'),
          primaryButtonLabel: t('Resend to terminal'),
        }
      }

      if (
        state?.paymentIntent?.last_payment_error?.type === 'card_error' &&
        state?.paymentIntent?.last_payment_error?.decline_code === 'country'
      ) {
        return {
          illustrationImg: internationalCardImg,
          title: t('International cards not supported'),
          description: t('The patient should use a card issued in the UK'),
          primaryButtonLabel: t('Resend to terminal'),
        }
      }

      if (
        [
          'call_issuer',
          'card_not_supported',
          'card_velocity_exceeded',
          'currency_not_supported',
          'do_not_honor',
          'do_not_try_again',
          'expired_card',
          'fraudulent',
          'insufficient_funds',
          'invalid_account',
          'invalid_amount',
          'lost_card',
          'merchant_blacklist',
          'new_account_information_available',
          'no_action_taken',
          'not_permitted',
          'pickup_card',
          'pin_try_exceeded',
          'restricted_card',
          'revocation_of_all_authorizations',
          'security_violation',
          'service_not_allowed',
          'stolen_card',
          'stop_payment_order',
          'testmode_decline',
          'transaction_not_allowed',
          'withdrawal_count_limit_exceeded',
        ].includes(
          state?.paymentIntent?.last_payment_error?.decline_code ||
            state?.paymentIntent?.last_payment_error?.code ||
            ''
        )
      ) {
        return {
          illustrationImg: failedImg,
          title: t('Card declined'),
          description: t(
            'The patient should contact the card issuer for more information or use a different card'
          ),
          primaryButtonLabel: t('Resend to terminal'),
        }
      }

      /**
       * Generic decline, it should cover all other cases e.g.:
       * 'approve_with_id','duplicate_transaction','generic_decline',
       * 'issuer_not_available','processing_error','reenter_transaction',
       * 'try_again_later'
       */
      return {
        illustrationImg: failedImg,
        title: t('Card declined'),
        description: t(
          'Try again! If this continues, the patient should use a different card'
        ),
        primaryButtonLabel: t('Resend to terminal'),
      }
    }, [state, t])

  return (
    <View textAlign="center">
      <View as="img" src={illustrationImg} width="176px" mx="auto" />
      <Title mt={6}>{title}</Title>
      <Caption mt={1}>{description}</Caption>
      <Summary data={tp} mt={6} />
      <Flex mt={6} flexDirection="column">
        <Button
          onClick={() => dispatch({ type: 'reload-reader' })}
          width="100%"
        >
          {primaryButtonLabel}
        </Button>
        <Button
          variant="secondary"
          mt={4}
          onClick={() => modal.close()}
          width="100%"
        >
          {t('Close')}
        </Button>
      </Flex>
    </View>
  )
}

export default PaymentFailed
