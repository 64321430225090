import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import { useRouteMatch } from 'react-router-dom'
import normalize from './normalizers/lead'

class Lead extends Resource {
  async fetch() {
    const subResources = formatSubresources([
      'product',
      'leads',
      'uploads',
      'stateMachineTransitions',
      'appointments',
      'productAnswers',
      'appointment',
      'offers',
    ])

    const { lead } = await api.get(
      `/merchant/leads/${this.params.id}?${subResources}`
    )

    const normalizedLead = normalize(lead)
    this.data = {
      ...this.data,
      ...normalizedLead,
    }
    return normalizedLead
  }

  sendReminder = () => {
    return api.post(
      `/merchant/leads/${this.params.id}/pre-qualification-request`,
      {}
    )
  }

  updateMetadata = async (type, payload) => {
    const response = await api.put(
      `/merchant/leads/${this.params.id}/metadata`,
      {
        type,
        [type]: payload,
      }
    )

    await this.fetch()
    return response
  }

  createAppointment = async payload => {
    const response = await api.post(
      `/merchant/leads/${this.params.id}/appointment`,
      payload
    )
    await this.fetch()
    return response
  }

  updateAppointment = async payload => {
    const response = await api.put(
      `/merchant/leads/${this.params.id}/appointment`,
      payload
    )
    await this.fetch()
    return response
  }

  setStatus = async status => {
    const response = await api.put(`/merchant/leads/${this.params.id}/status`, {
      status,
    })
    await this.fetch()
    return response
  }

  remove = async () => {
    const response = await api.delete(`/merchant/leads/${this.params.id}`, {
      data: {},
    })
    return response
  }

  closeLead = async status => {
    const response = await api.put(`/merchant/leads/${this.params.id}/close`, {
      status,
    })
    await this.fetch()
    return response
  }
}

export default Lead
export const useResource = createResourceHook(Lead)
export function useLead({ id } = {}) {
  const match = useRouteMatch({
    path: ['/leads/:leadId', '/lead-transaction/:leadId'],
  })

  const {
    params: { leadId },
  } = match || { params: {} }

  const leadIdParam = id || leadId

  return useResource(() => {
    if (!leadIdParam) {
      throw new Error('Lead ID is null')
    }
    return {
      id: Number.isNaN(Number(leadIdParam)) ? leadIdParam : +leadIdParam,
    }
  })
}
