import {
  ClinicianActions,
  ClinicianMain,
  ClinicianStepper,
  clinicianSchema,
  shouldRenderClinicianMobileActions,
} from './steps/clinician'
import {
  PatientActions,
  PatientMain,
  PatientStepper,
  patientSchema,
  patientWithContactSchema,
  shouldRenderPatientMobileActions,
} from './steps/patient'
import {
  PaymentActions,
  PaymentMain,
  PaymentStepper,
  billPaymentSchema,
  financeWithReferencePaymentSchema,
  paymentSchema,
  shouldRenderPaymentMobileActions,
} from './steps/payment'
import {
  TreatmentActions,
  TreatmentMain,
  TreatmentStepper,
  shouldRenderTreatmentMobileActions,
  treatmentSchema,
} from './steps/treatment'
import {
  TreatmentActions as TreatmentActionsCT,
  TreatmentMain as TreatmentMainCT,
  TreatmentStepper as TreatmentStepperCT,
  shouldRenderTreatmentMobileActions as shouldRenderTreatmentMobileActionsCT,
  treatmentCTSchema,
} from './steps/treatment-ct'
import { Modules } from './types'

export const modules = {
  payment: {
    main: PaymentMain,
    actions: PaymentActions,
    shouldRenderMobileActions: shouldRenderPaymentMobileActions,
    stepper: PaymentStepper,
    stateKey: 'payment',
    getSchema: ({ paymentMethod }) => {
      if (paymentMethod?.startsWith('finance')) {
        return financeWithReferencePaymentSchema
      }
      if (paymentMethod === 'bill') {
        return billPaymentSchema
      }
      return paymentSchema
    },
  },
  clinician: {
    main: ClinicianMain,
    actions: ClinicianActions,
    shouldRenderMobileActions: shouldRenderClinicianMobileActions,
    stepper: ClinicianStepper,
    stateKey: 'clinician',
    getSchema: () => clinicianSchema,
  },
  patient: {
    main: PatientMain,
    actions: PatientActions,
    shouldRenderMobileActions: shouldRenderPatientMobileActions,
    stepper: PatientStepper,
    stateKey: 'patient',
    getSchema: ({ paymentMethod }) => {
      const isFinance = paymentMethod?.startsWith('finance')
      const isBill = paymentMethod === 'bill'

      if (isFinance || isBill) {
        return patientWithContactSchema
      }
      return patientSchema
    },
  },
  treatment: {
    main: TreatmentMain,
    actions: TreatmentActions,
    shouldRenderMobileActions: shouldRenderTreatmentMobileActions,
    stepper: TreatmentStepper,
    stateKey: 'treatment',
    getSchema: () => treatmentSchema,
  },
  treatmentCT: {
    main: TreatmentMainCT,
    actions: TreatmentActionsCT,
    shouldRenderMobileActions: shouldRenderTreatmentMobileActionsCT,
    stepper: TreatmentStepperCT,
    stateKey: 'treatment',
    getSchema: () => treatmentCTSchema,
  },
} satisfies Modules
