import React from 'react'
import Svg, { G, SvgProps } from './Base'

function Clock({ fill, ...props }: SvgProps) {
  return (
    <Svg {...props}>
      <G
        fill="none"
        fillRule="evenodd"
        stroke={fill}
        transform="translate(4.5 5)"
      >
        <circle cx={6.5} cy={6.5} r={6} />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.5 3.449v3.265l2.66 2.482"
        />
      </G>
    </Svg>
  )
}

export default Clock
