import { useTranslation } from 'react-i18next'
import { ReactComponent as TickGreen } from 'components/checkout-payment/assets/tick-green.svg'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import CancelButton from '../CancelButton'

export default function BulkTransfer() {
  const { t } = useTranslation()

  return (
    <ActionCard>
      <ActionCard.Header>
        <TickGreen className="h-6 w-6" />
        {t('Bulk transfer mandate pending')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t(
          'The patient’s Direct Debit mandate is not yet active. This could be due to missing bank details, a failed Direct Debit setup, or a cancellation. The patient will remain in “Bulk Transfer” until the mandate is active.'
        )}
        <br />
        <br />
        {t(
          'If the patient hasn’t moved to active, there may be an issue with their Direct Debit setup. Please reach out to us if you need assistance in resolving this.'
        )}
      </ActionCard.Body>
      <ActionCard.Actions>
        <CancelButton />
      </ActionCard.Actions>
    </ActionCard>
  )
}
