import { Text, View } from '@tabeo/scarf'
import { useTranslation } from 'react-i18next'
import planeSvg from './assets/plane.svg'

const Success = () => {
  const { t } = useTranslation()

  return (
    <View width="100%" textAlign="center">
      <View as="img" src={planeSvg} mx="auto" />
      <Text fontWeight="semibold">{t('Receipt has been sent')}</Text>
    </View>
  )
}

export default Success
