import { Caption, Flex, Panel, Prompt, Text, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import mastercard from '../Payment/Options/assets/mastercard.svg'
import visa from '../Payment/Options/assets/visa.svg'
import bank from './assets/bank.svg'

const images = {
  visa,
  mastercard,
}

export const PaymentSource = ({ data, ...rest }) => {
  const { brand, lastFourDigits, expiryMonth, expiryYear } = data || {}
  const { t } = useTranslation()
  return (
    <Panel title={t('Payment method')} data-testid="payment-panel-cc" {...rest}>
      <View bg="white" p={5}>
        {data?.brand ? (
          <Flex flex="1">
            <Flex justifyContent="flex-start">
              {brand && images[brand.toLowerCase()] && (
                <View
                  as="img"
                  src={images[brand.toLowerCase()]}
                  alt={brand}
                  ml={1}
                  mr={3}
                />
              )}
              <Prompt flex="1" data-no-track>
                {brand.capitalize()} *{lastFourDigits}
              </Prompt>
            </Flex>
            <Text color="ink.2" data-no-track>
              {expiryMonth < 10 ? '0' : ''}
              {expiryMonth}/{`${expiryYear}`.slice(-2)}
            </Text>
          </Flex>
        ) : (
          <Caption>{t('No assigned payment source')}</Caption>
        )}
      </View>
    </Panel>
  )
}

PaymentSource.propTypes = {
  data: PropTypes.object,
}

export const BankAccount = ({ data, ...rest }) => {
  const { accountNumberEnding, bankName } = data || {}
  const { t } = useTranslation()

  return (
    <Panel title={t('Payment method')} data-testid="payment-panel-dd" {...rest}>
      <View bg="white" p={5}>
        {data ? (
          <Flex justifyContent="space-between">
            <Flex>
              <View as="img" src={bank} ml={1} mr={3} />
              <Prompt color="ink.0" data-no-track>
                {bankName}
              </Prompt>
            </Flex>
            <Flex>
              <Caption fontSize="8px" color="ink.2">
                ●●●●
              </Caption>
              <Text color="ink.2" ml="2px">
                {accountNumberEnding}
              </Text>
            </Flex>
          </Flex>
        ) : (
          <Caption>
            {t('No bank account is linked to this transaction')}
          </Caption>
        )}
      </View>
    </Panel>
  )
}

BankAccount.propTypes = {
  data: PropTypes.object,
}

function PaymentMethodPanel({ data, ...rest }) {
  const { isDirectDebit, bankAccount, paymentSource } = data

  if (isDirectDebit) {
    return <BankAccount data={bankAccount} {...rest} />
  }
  return <PaymentSource data={paymentSource} {...rest} />
}

PaymentMethodPanel.propTypes = {
  data: PropTypes.object,
}

export default PaymentMethodPanel
