import styled from 'styled-components'
import { Text } from '@tabeo/scarf'

const DocumentV1 = styled(Text)`
  box-sizing: border-box;
  text-align: left;
  width: 90%;
  max-width: 940px;
  font-size: 12px;
  strong {
    font-weight: 600;
  }
  p {
    margin: 0 0 10px;
  }
  a {
    text-decoration: none;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
  }
  ol,
  ul {
    margin: 0 0 20px 20px;
    padding: 0;
    li {
      margin: 0 0 10px;
    }
  }
  > ol > li > p:first-of-type {
    font-size: 14px;
  }
  > p:first-of-type {
    em {
      font-style: normal;
    }
    text-align: center;
    font-size: 16px;
  }
`

export default DocumentV1
