import axios from 'axios'
import config from 'config'

export default async function dato(query, { variables } = {}) {
  const r = await axios.post(
    config.DATO_URL,
    {
      query,
      variables,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${config.DATO_TOKEN}`,
      },
    }
  )

  const response = r.data

  if (response.errors) {
    throw response.errors
  }

  return response.data
}
