import styled from 'styled-components'
import { style, ResponsiveValue, Theme } from 'styled-system'
import ScarfProps from '../../types/ScarfProps'
import View from '../View'

export const fill = style({
  prop: 'fill',
  cssProperty: 'fill',
  key: 'colors',
})

export const stroke = style({
  prop: 'stroke',
  cssProperty: 'stroke',
  key: 'colors',
})

export type SvgProps = ScarfProps<'svg'> & {
  fill?: ResponsiveValue<string, Theme> | undefined
}

const Svg = styled(View as any)<SvgProps>`
  ${fill};
`

Svg.defaultProps = {
  as: 'svg',
  fill: 'default.primary',
  width: '22px',
  height: '22px',
  viewBox: '0 0 22 22',
  flex: '0 0 auto',
  display: 'inherit',
}

export type PathProps = ScarfProps<'path'> & {
  stroke?: ResponsiveValue<string, Theme> | undefined
  fill?: ResponsiveValue<string, Theme> | undefined
}

export const Path = styled('path')<PathProps>`
  ${stroke};
  ${fill}
`

Path.defaultProps = {
  stroke: 'default.primary',
  fill: 'default.primary',
}

export type GProps = ScarfProps<'g'> & {
  stroke?: ResponsiveValue<string, Theme> | undefined
  fill?: ResponsiveValue<string, Theme> | undefined
}

export const G = styled('g')<GProps>`
  ${stroke}
  ${fill}
`

G.defaultProps = {
  stroke: 'default.primary',
  fill: 'default.primary',
}

export type CircleProps = ScarfProps<'circle'> & {
  stroke?: ResponsiveValue<string, Theme> | undefined
  fill?: ResponsiveValue<string, Theme> | undefined
}

export const Circle = styled('circle')<CircleProps>`
  ${fill}
  ${stroke}
`

Circle.defaultProps = {
  fill: 'default.primary',
  stroke: 'default.primary',
}

export type RectProps = ScarfProps<'rect'> & {
  fill?: ResponsiveValue<string, Theme> | undefined
}

export const Rect = styled('rect')<RectProps>`
  ${fill}
`

Rect.defaultProps = {
  fill: 'default.primary',
}

export default Svg
