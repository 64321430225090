import { View, Caption } from '@tabeo/scarf'
import React from 'react'

import visaSvg from 'components/Payment/Options/assets/visa.svg'
import mastercardSvg from 'components/Payment/Options/assets/mastercard.svg'
import amexSvg from 'components/Payment/Options/assets/amex.svg'
import { Brand } from '@tabeo/ts-types'

function Tally({ children, ...rest }: React.ComponentProps<typeof View>) {
  return (
    <View className="space-y-3 rounded px-5 py-4" border={1} {...rest}>
      {children}
    </View>
  )
}

function Item({ children }: { children: React.ReactNode }) {
  return <div className="flex justify-between">{children}</div>
}

function Divider() {
  return <div className="border-t border-solid border-tabeo-sky-1" />
}

function Label({ children, ...rest }: React.ComponentProps<typeof Caption>) {
  return <Caption {...rest}>{children}</Caption>
}

function Value({ children, ...rest }: React.ComponentProps<typeof Caption>) {
  return (
    <Caption color="ink.0" {...rest}>
      {children}
    </Caption>
  )
}

type BrandWithAmex = Brand | 'amex'

const brandImages: Record<BrandWithAmex, any> = {
  visa: visaSvg,
  mastercard: mastercardSvg,
  amex: amexSvg,
}

function CardValue({
  brand,
  lastFourDigits,
  ...rest
}: {
  brand: BrandWithAmex
  lastFourDigits: string
} & React.ComponentProps<typeof Value>) {
  const brandSvg = brand ? brandImages[brand] || null : null

  return (
    <div className="flex items-center">
      {brandSvg && (
        <img src={brandSvg} alt={brand} className="-my-3 mr-1 w-12" />
      )}
      <Value {...rest}>
        {brand?.capitalize()} •••• {lastFourDigits}
      </Value>
    </div>
  )
}

function DiffValue({
  diff,
  children,
  ...rest
}: {
  diff: React.ReactNode
} & React.ComponentProps<typeof Value>) {
  return (
    <div className="flex items-baseline">
      {diff && (
        <Value color="ink.2" className="line-through" mr={1}>
          {diff}
        </Value>
      )}
      <Value {...rest}>{children}</Value>
    </div>
  )
}

Tally.Item = Item
Tally.Divider = Divider
Tally.Label = Label
Tally.Value = Value
Tally.CardValue = CardValue
Tally.DiffValue = DiffValue

export default Tally
