import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import Flex from '../../Flex'
import { Prompt } from '../../Text'

import Dot from './Dot'

const Container = styled(Flex).attrs(({ horizontal, index, ml, mt }) => ({
  ml: ml ?? (horizontal && index > 0) ? 5 : undefined,
  mt: mt ?? (!horizontal && index > 0) ? 3 : undefined,
}))`
  outline: none;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
`

const Item = ({
  item: { label, props = {}, index },
  selected,
  disabled,
  horizontal,
  focus,
  ...rest
}) => (
  <Container
    {...rest}
    justifyContent="flex-start"
    disabled={disabled}
    horizontal={horizontal}
    index={index}
    {...props}
  >
    <Dot selected={selected} disabled={disabled} focus={focus} mr={2} />
    <Prompt color={disabled ? 'ink.2' : undefined}>{label}</Prompt>
  </Container>
)

Item.propTypes = {
  item: PropTypes.shape({
    props: PropTypes.object,
    index: PropTypes.any,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
      .isRequired,
  }).isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  focus: PropTypes.bool,
  horizontal: PropTypes.bool,
}

export default Item
