import ActionCard from 'components/ActionPanel/ActionCard'
import icon from 'components/ActionPanel/assets/x-grey.svg'
import moment from 'moment'
import PropType from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

function Canceled({ data }) {
  const { consumerFirstName, updatedAt } = data
  const { t } = useTranslation()
  return (
    <ActionCard
      icon={icon}
      title={t('Subscription cancelled')}
      body={t(
        `{{consumerFirstName}}’s subscription was cancelled {{elapsedTime}}.`,
        {
          consumerFirstName,
          elapsedTime: moment(updatedAt).to(),
        }
      )}
    />
  )
}

Canceled.propTypes = {
  data: PropType.object.isRequired,
}

export default Canceled
