import { themeGet } from '@styled-system/theme-get'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import ScarfProps from '../../types/ScarfProps'
import { children } from '../../utils/propTypes'
import { GridTableCell as Cell, GridTableRow as BaseRow } from '../GridTable'
import Selector from '../Selector'
import { useDataTable } from './context'

export type RowProps = {
  selected?: boolean
}

const Row = styled(BaseRow)<RowProps>`
  & > * {
    border-top: 1px solid ${themeGet('colors.sky.1')};
    background: ${(p) => (p.selected ? themeGet('colors.sky.3') : 'white')};
  }
  &:hover > * {
    background: ${themeGet('colors.sky.3')};
  }
`

export type SelectableGridTableRowProps = ScarfProps & {
  id?: string | number
  selectorCellProps?: ScarfProps
}

export const SelectableGridTableRow = ({
  children,
  id,
  selectorCellProps,
  ...rest
}: SelectableGridTableRowProps) => {
  const { state, dispatch } = useDataTable()
  const isSelected = state.selected?.includes(id)

  function toggleSelected(e: React.MouseEvent) {
    e.stopPropagation()
    const selected = isSelected
      ? state.selected?.filter((s) => s !== id)
      : [...(state.selected || []), id]
    dispatch({
      type: 'selectedChange',
      payload: selected,
    })
  }

  return (
    <Row selected={isSelected} {...rest}>
      {/* eslint-disable-next-line react/jsx-no-bind */}
      <Cell onClick={toggleSelected} {...selectorCellProps}>
        <Selector
          state={isSelected ? 'active' : 'idle'}
          width="16px"
          height="100%"
        />
      </Cell>
      {children}
    </Row>
  )
}

SelectableGridTableRow.propTypes = {
  children,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selectorCellProps: PropTypes.object,
}

const GridTableRow = styled(BaseRow)`
  & > * {
    border-top: ${(p) => (p.borderTop !== 0 ? themeGet('borders.1') : 'unset')};
    background: ${(p) => (p.bg ? themeGet(`colors.${String(p.bg)}`) : 'white')};
  }
  &:hover > * {
    background: ${themeGet('colors.sky.3')};
  }
`

export default GridTableRow
