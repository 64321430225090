import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'

interface Params {
  conditions?: Record<string, any>
  searchQuery?: string
}

interface Data {
  linkPayments: {
    numberOfPayments: number
    totalAmount: number
  }
  terminalPayments: {
    numberOfPayments: number
    totalAmount: number
  }
  allPayments: {
    numberOfPayments: number
    totalAmount: number
  }
}

const initalData = {
  linkPayments: {
    numberOfPayments: 0,
    totalAmount: 0,
  },
  terminalPayments: {
    numberOfPayments: 0,
    totalAmount: 0,
  },
  allPayments: {
    numberOfPayments: 0,
    totalAmount: 0,
  },
}

class ReconciliationStats extends Resource<Data, Params> {
  getInitialData() {
    return initalData
  }

  async fetch() {
    const { searchQuery, conditions = {} } = this.params

    const searchParams = new URLSearchParams()
    if (conditions) {
      searchParams.append('jsonConditions', JSON.stringify(conditions))
    }

    if (searchQuery) {
      searchParams.append('searchPhrase', searchQuery)
    }

    const response = await api.get(
      `/merchant/reconciliation/statistics?${searchParams.toString()}`
    )
    this.data = response
  }
}

export default ReconciliationStats
export const useReconciliationStats = createResourceHook(ReconciliationStats)
