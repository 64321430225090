import React, { useRef, useState } from 'react'
import { useMobile } from '../../hooks/useMatchMedia'
import { Merge } from '../../types/helpers'
import ScarfProps from '../../types/ScarfProps'
import Flex from '../Flex'
import * as Icons from '../Icons'
import Popover from '../Popover'
import Text from '../Text'
import View from '../View'
import BasicValue from './BasicValue'
import mapFormToProps from './mapFormToProps'
import Placeholder, { PlaceholderProps } from './Placeholder'
import Select, { Item, SelectProps } from './Select/index'

export type TypeSelectorProps = Merge<
  ScarfProps,
  {
    loadOptions: SelectProps['loadOptions']
    onChange: SelectProps['onChange']
    value?: Item
    invalid?: PlaceholderProps['invalid']
  }
>

function TypeSelector({
  loadOptions,
  onChange,
  value,
  invalid,
  onFocus,
  onBlur,
  ...rest
}: TypeSelectorProps) {
  const [open, setOpen] = useState(false)
  const ref = useRef()
  const mobile = useMobile()

  return (
    <View display="inline-block">
      <Popover
        open={open}
        onClickOut={() => setOpen(false)}
        placement="bottom-start"
        referenceElement={ref}
        offset={[0, 4]}
      >
        {({ ref, style, placement, update }) => (
          <Select
            ref={ref}
            onBlur={onBlur}
            onFocus={onFocus}
            style={mobile ? { zIndex: 1000 } : style}
            data-placement={placement}
            onHitsUpdate={update}
            placeholder="Search by type"
            loadOptions={loadOptions}
            filtering
            selectedFirst
            value={value}
            maxHeight="360px"
            onChange={(e) => {
              setOpen(false)
              onChange(e)
            }}
            onClose={() => setOpen(false)}
            renderItem={(item, { hover, selected, ...rest }) => (
              <Flex
                justifyContent="flex-start"
                px={[5, 3]}
                py={[5, 3]}
                bg={hover ? 'default.primary' : undefined}
                color={hover ? 'white' : undefined}
                {...rest}
              >
                <Text
                  color={hover ? 'white' : 'default.ink'}
                  fontSize={[null, '14px']}
                  minHeight="22px"
                  data-testid="type-item"
                >
                  {item.label}
                </Text>
                <Flex flex="1" />
                {selected && (
                  <Icons.Tick light fill={hover ? 'white' : undefined} />
                )}
              </Flex>
            )}
            data-testid="type-selector"
          />
        )}
      </Popover>
      <View
        ref={ref}
        onClick={() => {
          setOpen(!open)
        }}
        cursor="pointer"
        {...rest}
      >
        {!value?.label && (
          <Placeholder
            icon={(props) => <Icons.Tag {...props} />}
            invalid={invalid}
            _hover={{
              bg: 'sky.3',
            }}
          >
            Set type
          </Placeholder>
        )}
        {!!value?.label && (
          <Flex alignItems="center">
            <BasicValue
              icon={(props) => <Icons.Tag {...props} />}
              invalid={invalid}
              _hover={{
                bg: 'sky.3',
              }}
            >
              {value.label}
            </BasicValue>
          </Flex>
        )}
      </View>
    </View>
  )
}

export default mapFormToProps(TypeSelector)
