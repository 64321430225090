import { Box, Subtitle } from '@tabeo/scarf'
import Footer from 'components/Footer'
import Layout from 'components/Layout'
import React from 'react'
import { useTranslation } from 'react-i18next'

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <>
      <Layout maxWidth="460px" bg="sky.2">
        <Box variant="content" textAlign="center">
          <Subtitle>{t('Page Not Found')}</Subtitle>
        </Box>
      </Layout>
      <Footer />
    </>
  )
}

export default NotFound
