import { Caption, Panel, View } from '@tabeo/scarf'
import { Brand } from '@tabeo/ts-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'
import amexSvg from './assets/amex-border.svg'
import mastercardSvg from './assets/mastercard-border.svg'
import visaSvg from './assets/visa-border.svg'

const brandImages: Record<Brand | 'amex', any> = {
  visa: visaSvg,
  mastercard: mastercardSvg,
  amex: amexSvg,
}

export type PaymentMethodPanelProps = {
  brand: Brand | 'amex'
  lastFourDigits: string
  expiryMonth: number
  expiryYear: number
  funding: string
} & React.ComponentProps<typeof Panel>

function PaymentMethodPanel({
  brand,
  lastFourDigits,
  expiryMonth,
  expiryYear,
  funding,
  ...rest
}: PaymentMethodPanelProps) {
  const { t } = useTranslation()

  const brandSvg = brandImages[brand] || null

  const isCardExpired =
    moment(new Date()).startOf('month').toDate() >
    moment(new Date(expiryYear, expiryMonth - 1))
      .startOf('month')
      .toDate()

  return (
    <Panel
      data-testid="payment-method-panel"
      title={t('Payment method')}
      {...rest}
    >
      <div className="p-5">
        <div className="flex items-start">
          {brandSvg && <img src={brandSvg} alt={brand} className="mr-3 w-12" />}
          <View>
            <Caption color="ink.0" data-no-track>
              {brand.capitalize()} {funding.capitalize()} •••• {lastFourDigits}
            </Caption>

            <Caption data-no-track>
              {String(expiryMonth).padStart(2, '0')}/{`${expiryYear}`.slice(-2)}{' '}
              {isCardExpired && t('(expired)')}
            </Caption>
          </View>
        </div>
      </div>
    </Panel>
  )
}

export default PaymentMethodPanel
