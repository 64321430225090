import React from 'react'
import Svg, { Path, SvgProps } from './Base'

function Chat({ fill, ...rest }: SvgProps) {
  return (
    <Svg viewBox="0 0 25 24" {...rest}>
      <g transform="translate(.5)" fill="none" fillRule="evenodd">
        <Path
          fill="none"
          stroke={fill}
          d="M6.24 16.404c.123.112.161.29.095.442-.1.238-.183.48-.305.709-.295.549-.738.96-1.184 1.372-.205.195-.422.39-.613.598-.19.208-.142.3.122.368.686.175 1.371.112 2.05-.02 1.28-.252 2.497-.743 3.68-1.303.134-.07.288-.094.437-.07a8.712 8.712 0 003.442-.095c2.126-.511 3.85-1.646 5.037-3.57a6.503 6.503 0 00.9-4.593c-.33-1.984-1.374-3.513-2.942-4.641A8.231 8.231 0 0012.096 4c-.416.028-.836.03-1.248.088-1.788.246-3.38.96-4.695 2.249-1.234 1.213-1.992 2.682-2.13 4.46a6.653 6.653 0 001.292 4.53c.29.375.598.734.926 1.077h0z"
        />
        <Path
          stroke="none"
          fill={fill}
          d="M9.3 10.6a1 1 0 110 2 1 1 0 010-2zm3 0a1 1 0 110 2 1 1 0 010-2zm3 0a1 1 0 110 2 1 1 0 010-2z"
        />
      </g>
    </Svg>
  )
}

export default Chat
