import { DataTable, GridTable, Panel } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { usePlansV2 } from 'resources/PlansV2'
import ListItem from './ListItem'

function Plans() {
  const { t } = useTranslation()

  return (
    <Panel
      header={
        <>
          <Panel.Title>{t('Plans')}</Panel.Title>
          <Button as={Link} to="/new-plan" size="sm">
            {t('Create plan')}
          </Button>
        </>
      }
    >
      <DataTable
        // @ts-ignore
        resourceHook={usePlansV2}
        paginationLimit={10}
        borderRadius={0}
        border="none"
        data-testid="plans-table"
      >
        {({ items }) => (
          <GridTable
            templateColumns={[
              'minmax(100px,auto) minmax(140px, 1fr) minmax(140px, 1fr) minmax(140px, auto) minmax(140px, auto)',
            ]}
            minWidth="650px"
          >
            <DataTable.Head
              columns={[
                {
                  label: 'Plan ID',
                },
                {
                  label: 'Plan name',
                },
                {
                  label: 'Internal reference',
                },
                {
                  label: 'No of members',
                },
                {
                  label: 'Price',
                  cellProps: { justifyContent: 'flex-end' },
                },
              ]}
            />
            {items.map(item => (
              <ListItem key={item.id} data={item} />
            ))}
          </GridTable>
        )}
      </DataTable>
    </Panel>
  )
}

export default Plans
