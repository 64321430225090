import React, { useState, useEffect } from 'react'
import ScarfProps from '../types/ScarfProps'
import Tick from './Icons/Tick'
import Funnelbar from './Funnelbar'

import theme from '../theme'

function matchMobileQuery() {
  const mediaQueryList = window.matchMedia(
    `(max-width: ${theme.breakpoints[0] || '960px'})`
  )
  return mediaQueryList.matches
}

export type StateFunnelProps = ScarfProps & {
  states: string[]
  currentIndex?: number
  pastStepProps?: ScarfProps
  activeStepProps?: ScarfProps
  futureStepProps?: ScarfProps
}

function StateFunnel({
  states,
  currentIndex = 0,
  pastStepProps = { bg: 'primary.4', color: 'white' },
  activeStepProps = { bg: 'primary.3', color: 'white' },
  futureStepProps = { bg: 'sky.2', color: 'ink.2' },
  ...rest
}: StateFunnelProps) {
  const [mobile, setMobile] = useState(() => matchMobileQuery())

  const checkMobile = () => {
    const match = matchMobileQuery()
    setMobile(match)
  }

  useEffect(() => {
    window.addEventListener('resize', checkMobile)
    return () => {
      window.removeEventListener('resize', checkMobile)
    }
  }, [])

  const steps = states.map((state, index) => ({
    label: mobile ? index + 1 : state,
    props:
      index < currentIndex
        ? pastStepProps
        : index === currentIndex
        ? activeStepProps
        : futureStepProps,
    children:
      index < currentIndex ? (
        <Tick light fill={pastStepProps.color as any} />
      ) : null,
  }))

  return <Funnelbar steps={steps} {...rest} />
}

export default StateFunnel
