import { Accordion, Button, Panel } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function Documents({ data, ...rest }) {
  const { t } = useTranslation()
  if (!data.agreementAcceptedAt) {
    return null
  }
  return (
    <Panel title={t('Documents')} data-testid="document-panel" {...rest}>
      <Accordion title={t('Terms & Conditions')} subtitle={data.plan.title}>
        <Button
          sizeVariant="sm"
          variant="secondary"
          as={Link}
          to={`/subscriptions/${data.id}/terms`}
        >
          {t('View document')}
        </Button>
      </Accordion>
    </Panel>
  )
}

Documents.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Documents
