import { children } from '@tabeo/sharpei/utils/propTypes'
import React from 'react'
import { useAuth } from '../../resources/Auth'
import { useMerchant } from '../../resources/Merchant'

export const defaultThemeData = {
  theme: {},
  isRouteThemeable: false,
}

const Context = React.createContext(defaultThemeData)

export function ThemeDataProvider({ children }) {
  const {
    data: { token },
  } = useAuth()
  const { data: merchant } = useMerchant(() => {
    if (!token) {
      throw Error('waiting for auth')
    }
  })

  const theme = merchant?.isWhitelabelEnabled
    ? merchant?.metadata?.merchantTheme ?? {}
    : {}

  return (
    <Context.Provider
      value={{
        theme,
        isMerchantLoaded: !!merchant,
      }}
    >
      {children}
    </Context.Provider>
  )
}

ThemeDataProvider.propTypes = {
  children,
}

export const ThemeDataConsumer = Context.Consumer
