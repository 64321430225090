import { Stepper } from '@tabeo/scarf2'
import { InvoiceIcon } from 'components/nnts/icons/24'
import { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

export function InvoiceStepper({
  children,
  ...rest
}: ComponentProps<typeof Stepper>) {
  const { t } = useTranslation()
  return (
    <Stepper {...rest}>
      <Stepper.Header>
        <Stepper.Icon icon={InvoiceIcon} />
        <Stepper.Label>{t('Invoice')}</Stepper.Label>
      </Stepper.Header>
      <Stepper.Body>{children}</Stepper.Body>
    </Stepper>
  )
}
