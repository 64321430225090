import { useTranslation } from 'react-i18next'
import PaymentRefund from 'components/checkout-payment/PaymentRefund'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as RightArrowGreen } from '../assets/right-arrow-green.svg'

function Paid() {
  const { t } = useTranslation()

  return (
    <ActionCard data-testid="paid-action-panel">
      <ActionCard.Header>
        <RightArrowGreen className="h-6 w-6" />
        {t('Paid')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t(
          'This payment was successful. The payout should arrive within 1-3 business days.'
        )}
      </ActionCard.Body>
      <ActionCard.Actions>
        <PaymentRefund.RequestButton />
      </ActionCard.Actions>
    </ActionCard>
  )
}

export default Paid
