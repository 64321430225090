import { View } from '@tabeo/scarf'
import React from 'react'
import { useDatoApp } from 'resources/DatoApp'
import Notification from './Notification'

export type DatoNotificationsProps = React.ComponentProps<typeof View>

function DatoNotifications({ ...rest }: DatoNotificationsProps) {
  const { data } = useDatoApp()

  const { notificationsV2 } = data || {}

  return (
    <View className="space-y-4 empty:hidden" mb={4} {...rest}>
      {notificationsV2?.map((n: any) => (
        <Notification key={n.id} data={n} />
      ))}
    </View>
  )
}

export default DatoNotifications
