import { GridTableCell as Cell, GridTableRow, themeGet } from '@tabeo/scarf'
import ScarfProps from '@tabeo/scarf/dist/types/ScarfProps'
import { format } from '@tabeo/sharpei/utils/currency'
import { Patient } from '@tabeo/ts-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { ReconciliationPayment } from 'resources/ReconciliationPayments'
import styled from 'styled-components'
import formatClinicianName from 'utils/formatClinicianName'

const Row = styled(GridTableRow)<ScarfProps>`
  & > * {
    border-top: 1px solid ${themeGet('colors.sky.1')};
  }
  &:hover > * {
    background: ${themeGet('colors.sky.3', 'white')};
  }
`

export type ListItemProps = {
  data: ReconciliationPayment
  isCliniciansTreatmentsEnabled?: boolean
}

function formatPatient(patient: Patient | undefined) {
  if (patient?.firstName || patient?.lastName) {
    return `${patient.firstName || ''} ${patient.lastName || ''}`.trim()
  }

  if (patient?.email) {
    return patient.email
  }

  if (patient?.phoneNumber) {
    return patient.phoneNumber
  }

  return ''
}

const ListItem = ({ data, isCliniciansTreatmentsEnabled }: ListItemProps) => {
  return (
    <Row
      bg="white"
      as={Link}
      to={
        data.type === 'link'
          ? `/link-payments/${data.id}`
          : `/terminal-payments/${data.id}`
      }
    >
      <Cell color="ink.2">{data.displayID}</Cell>
      <Cell textAlign="right">{format(data.amount)}</Cell>
      <Cell data-testid="transaction-consumer" color="ink.2">
        {formatPatient(data.basket?.patient)}
      </Cell>
      <Cell data-testid="transaction-treatment-name" color="ink.2">
        {data.treatmentName}
      </Cell>
      {isCliniciansTreatmentsEnabled && (
        <Cell data-testid="transaction-clinician-name" color="ink.2">
          {data.basket?.merchantClinicianID &&
            formatClinicianName(data.basket?.merchantClinician)}
        </Cell>
      )}
      <Cell color="ink.2">{data.type === 'link' ? 'Link' : 'Terminal'}</Cell>
      <Cell textAlign="right" color="ink.2">
        {moment(data.chargedAt).format('DD MMM YYYY')}
      </Cell>
    </Row>
  )
}

export default ListItem
