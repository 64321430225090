import { GridTable } from '@tabeo/scarf'
import { children } from '@tabeo/sharpei/utils/propTypes'
import React from 'react'

export function Column({ children, ...rest }) {
  return (
    <div>
      <GridTable templateColumns="1fr" gap="16px" {...rest}>
        {children}
      </GridTable>
    </div>
  )
}

Column.propTypes = {
  children,
}

function TwoColumns({ children, ...rest }) {
  return (
    <GridTable templateColumns={['1fr', '1fr 324px']} gap="16px" {...rest}>
      {children}
    </GridTable>
  )
}

TwoColumns.propTypes = {
  children,
}

export default TwoColumns
