import { Caption, Flex, Heading, View } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import Card from 'components/Transaction/ActionPanel/Card'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import icon from './assets/settled.svg'

function Settled({ data, ...rest }) {
  const { netProceeds } = data
  const { data: merchant } = useMerchant()
  const { t } = useTranslation()

  const bankAccountNumber = merchant
    ? merchant.bankDetails.accountNumber.slice(-3)
    : ''

  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Bill settled')}</Heading>
      </Flex>
      <Caption color="ink.1">
        {bankAccountNumber
          ? t(
              'Your payout ({{amount}}) should have arrived in your bank account ending in {{bankAccountNumber}}.',
              {
                amount: format(netProceeds),
                bankAccountNumber,
              }
            )
          : t(
              'Your payout ({{amount}}) should have arrived in your bank account.',
              {
                amount: format(netProceeds),
              }
            )}
      </Caption>
    </Card>
  )
}

Settled.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Settled
