import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import ScarfProps from '../../types/ScarfProps'
import variants from '../../utils/variants'
import Flex from '../Flex'
import * as Icons from '../Icons'
import { Caption } from '../Text'
import View from '../View'
import mapFormToProps from './mapFormToProps'

export type ContainerProps = {
  sizeVariant?: 'fixed' | 'md'
}

const Container = styled(View)<ContainerProps>`
  ${variants({
    sizeVariant: {
      md: {
        height: ['40px', '25px'],
      },
      fixed: {
        height: ['25px', '25px'],
      },
    },
  })}
`

Container.defaultProps = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'sky.0',
  borderRadius: 1,
  sizeVariant: 'md',
}

const StyledCaption = styled(Caption)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export type BasicValueProps = ScarfProps &
  ContainerProps & {
    invalid?: boolean
    loading?: boolean
    onDelete?: (e: React.MouseEvent) => void
    icon?: (props: {
      fill: ScarfProps<'svg'>['fill']
      height: ScarfProps<'svg'>['height']
      width: ScarfProps<'svg'>['width']
    }) => React.ReactNode
  }

function BasicValue({
  children,
  icon,
  color = 'ink.2',
  invalid,
  loading,
  onDelete,
  sizeVariant,
  _hover: hover,
  ...rest
}: BasicValueProps) {
  return (
    <Container
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      sizeVariant={sizeVariant}
      borderColor={invalid ? 'default.red' : undefined}
      overflow="hidden"
      {...rest}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        minWidth={0}
        _hover={hover}
      >
        {loading ? (
          <Icons.Spinner
            spinning
            mx={sizeVariant === 'fixed' ? [1] : ['6px', 1]}
            height={sizeVariant === 'fixed' ? ['14px'] : ['16px', '14px']}
            width={sizeVariant === 'fixed' ? ['14px'] : ['16px', '14px']}
          />
        ) : (
          icon?.({
            fill: invalid ? 'default.red' : 'default.primary',
            height: sizeVariant === 'fixed' ? ['22px'] : ['30px', '22px'],
            width: sizeVariant === 'fixed' ? ['22px'] : ['30px', '22px'],
          })
        )}
        <StyledCaption
          color={invalid ? 'default.red' : color}
          fontSize={sizeVariant === 'fixed' ? ['14px'] : ['16px', '14px']}
          pl={icon || loading ? '2px' : 2}
          pr={2}
          minWidth={0}
        >
          {children}
        </StyledCaption>
      </Flex>
      {!loading && onDelete && (
        <Flex
          borderLeft={1}
          height="100%"
          px={sizeVariant === 'fixed' ? ['5px'] : ['10px', '5px']}
          borderColor={invalid ? 'default.red' : undefined}
          cursor="pointer"
          onClick={onDelete}
          bg="white"
          _hover={{
            bg: 'sky.3',
          }}
        >
          <Icons.X
            height={sizeVariant === 'fixed' ? ['12px'] : ['13px', '12px']}
            width={sizeVariant === 'fixed' ? ['12px'] : ['13px', '12px']}
            fill="ink.2"
          />
        </Flex>
      )}
    </Container>
  )
}

BasicValue.propTypes = {
  children: PropTypes.string.isRequired,
  icon: PropTypes.func,
  color: PropTypes.string,
  invalid: PropTypes.bool,
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
  sizeVariant: PropTypes.string,
  _hover: PropTypes.any,
}

BasicValue.defaultProps = {
  sizeVariant: 'md',
}

export default mapFormToProps(BasicValue)
