import PropTypes from 'prop-types'
import React from 'react'
import { GridTableCell, GridTableRow } from '../GridTable'
import { useDataTable } from './context'
import HeadingListItem, {
  Column,
  CustomColumn,
  HeadListItemProps,
} from './HeadListItem'

export type HeadProps = {
  columns: HeadListItemProps['column'][]
}

function Head({ columns, ...rest }: HeadProps) {
  const { state } = useDataTable()

  return (
    <GridTableRow
      display={[state.selected?.length ? 'none' : 'contents', 'contents']}
      {...rest}
    >
      {columns.map((c, i) => (
        <GridTableCell
          display="flex"
          key={i}
          height="52px"
          {...(c as Column | CustomColumn).cellProps}
        >
          <HeadingListItem column={c} />
        </GridTableCell>
      ))}
    </GridTableRow>
  )
}

Head.propTypes = {
  columns: PropTypes.array,
}

export default Head
