import React, { useContext } from 'react'
import ScarfProps from '../../../../types/ScarfProps'
import Flex from '../../../Flex'
import EntityContext from '../EntityContext'

function Container({ children, ...rest }: ScarfProps) {
  const { hover, disabled, color, linked } = useContext(EntityContext)

  return (
    <Flex
      justifyContent="flex-start"
      flexGrow="1"
      bg={disabled ? 'sky.2' : hover ? color : undefined}
      px={linked ? [2] : [3, 3]}
      py={!linked && [3, 2]}
      minWidth="0px"
      {...rest}
      cursor={disabled ? 'default' : rest.cursor}
      onClick={disabled ? null : rest.onClick}
    >
      {children}
    </Flex>
  )
}

export default Container
