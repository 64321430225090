import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import normalize from './normalizers/subscription'

const subResources = `&${formatSubresources([
  'plan',
  'stateMachineTransitions',
  'metadata',
])}`

class Subscriptions extends Resource {
  getInitialData() {
    return {
      items: null,
      totalCount: 0,
    }
  }

  getInitialConfig() {
    return {
      refreshInterval: 15 * 1000,
    }
  }

  async fetch() {
    const {
      activeTabs,
      searchQuery,
      limit = 10,
      offset = 0,
      filters = {},
    } = this.params
    const statuses = activeTabs.reduce((mem, tab) => {
      return [...mem, ...tab.statuses]
    }, [])

    const conditions = [{ status: statuses, ...filters }]
    const jsonConditions = `&jsonConditions=${encodeURIComponent(
      JSON.stringify(conditions)
    )}`

    const pagination = `&limit=${limit}&offset=${offset}`
    const endpoint = searchQuery
      ? `/merchant/subscription-search?order=id:desc&searchPhrase=${searchQuery}`
      : `/merchant/subscriptions?order=id:desc`

    const {
      data: { subscriptions },
      totalCount,
    } = await api.get(
      `${endpoint}${jsonConditions}${pagination}${subResources}`,
      {
        responseDataPath: 'data',
      }
    )

    const data = {
      totalCount,
      items: subscriptions.map(s => normalize(s)),
    }

    this.data = data

    return data
  }

  static create = async (planId, payload) => {
    const {
      projections: {
        subscription: { id },
      },
    } = await api.post(`/merchant/plans/${planId}/subscriptions`, payload)

    const { subscription } = await api.get(`/merchant/subscriptions/${id}`)

    return subscription
  }
}

export default Subscriptions
export const useSubscriptions = createResourceHook(Subscriptions)
