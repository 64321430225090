import { useTranslation } from 'react-i18next'
import { ReactComponent as XGray } from 'components/checkout-payment/assets/x-gray.svg'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import moment from 'moment'
import { useSubscriptionV2 } from 'resources/SubscriptionV2'

export default function Canceled() {
  const { t } = useTranslation()
  const { data } = useSubscriptionV2()

  const nextPaymentDate = data?.subscription?.nextPaymentDate

  return (
    <ActionCard>
      <ActionCard.Header>
        <XGray className="h-6 w-6" />
        {t('Membership cancelled')}
      </ActionCard.Header>
      <ActionCard.Body>
        {nextPaymentDate
          ? t(
              'The membership was cancelled by a member of your team. Considering our 30-day notice period, Tabeo will collect the next, final monthly payment on {{date}}.',
              {
                date: moment(nextPaymentDate).format('DD MMM YYYY'),
              }
            )
          : t('The membership was cancelled by a member of your team.')}
      </ActionCard.Body>
    </ActionCard>
  )
}
