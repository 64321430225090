import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import config from 'config'
import useLocalStorage from 'hooks/useLocalStorage'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from 'resources/Auth'
import { useCardReaders } from 'resources/CardReaders'
import { useMerchant } from 'resources/Merchant'
import { twMerge } from 'tailwind-merge'
import { Where, check, path } from 'utils/conditions'
import { markdownToReact } from 'utils/markdown'
import SmartButton from './SmartButton'
import SmartLink from './SmartLink'
import SparkleIcon from './icons/20/SparkleIcon'

export type NotificationProps = {
  data: {
    id: string
    variant: 'info' | 'success' | 'warning' | 'error' | 'default'
    content: string
    buttonText?: string
    buttonLink?: string
    dismissible?: boolean
    customIcon?: string
    conditions?: Where
  }
  children?: React.ReactNode
}

function Notification({
  data: {
    id,
    variant = 'default',
    content,
    buttonText,
    buttonLink,
    dismissible,
    customIcon,
    conditions,
  },
  children,
}: NotificationProps) {
  const {
    data: { token, user },
  } = useAuth()
  const { data: merchant } = useMerchant(() => {
    if (!token) {
      throw new Error('Unauthenticated')
    }

    return undefined
  })
  const { data: cardReaders } = useCardReaders(() => {
    if (!token) {
      throw new Error('Unauthenticated')
    }

    return {}
  })

  const [value, setValue] = useLocalStorage('tabeoNotifications', {})
  const closed = value[id] === 'closed'

  const handleClose = () => {
    setValue({ ...value, [id]: 'closed' })
  }

  const location = useLocation()

  const valid = useMemo(() => {
    return check(conditions, {
      merchant: {
        ...merchant,
        cardReaders: cardReaders?.items || [],
      },
      user,
      path: path(location.pathname),
      // a temporary solution to prevent draft notifications
      // from being pushed to production by mistake
      env: config.ENV,
    })
  }, [conditions, location.pathname, merchant, user, cardReaders])

  if (closed || !valid) {
    return null
  }

  const className = {
    variant: {
      default: 'border-tabeo-ink-3 bg-tabeo-sky-2',
      info: 'border-tabeo-blue-3 bg-tabeo-blue-6',
      warning: 'border-tabeo-yellow-4 bg-tabeo-yellow-6',
      error: 'border-tabeo-red-3 bg-tabeo-red-6',
      success: 'border-tabeo-green-4 bg-tabeo-green-6',
    },
    icon: {
      variant: {
        default: 'text-tabeo-ink-3',
        info: 'text-tabeo-blue-3',
        warning: 'text-tabeo-yellow-2',
        error: 'text-tabeo-red-3',
        success: 'text-tabeo-green-2',
      },
    },
  }

  const defaultIcons = {
    variant: {
      default: InformationCircleIcon,
      info: InformationCircleIcon,
      warning: ExclamationTriangleIcon,
      error: XCircleIcon,
      success: CheckCircleIcon,
    },
  }

  const customIcons: Record<string, React.ComponentType<any>> = {
    sparkle: SparkleIcon,
  }

  const Icon = customIcon
    ? customIcons[customIcon] || defaultIcons.variant[variant]
    : defaultIcons.variant[variant]

  return (
    <div
      className={twMerge(
        'relative flex flex-col gap-2 rounded border px-10 py-3 desktop:min-h-[52px] desktop:flex-row desktop:items-center desktop:gap-4 desktop:py-2',
        !dismissible && 'pr-3',
        className.variant[variant]
      )}
    >
      <div className="flex-1">
        <Icon
          className={twMerge(
            'absolute left-3 top-3 h-5 w-5 desktop:top-auto',
            className.icon.variant[variant]
          )}
        />
        <div className="caption text-tabeo-ink-0 [&_a]:underline [&_em]:italic [&_strong]:font-medium">
          {children ||
            markdownToReact(content, {
              components: { a: SmartLink },
            })}
        </div>
      </div>
      {buttonText && buttonLink && (
        <SmartButton
          href={buttonLink}
          className="w-fit text-tabeo-ink-1 focus:border-tabeo-ink-1"
          size="sm"
          variant="secondary"
        >
          {buttonText}
        </SmartButton>
      )}
      {!!dismissible && (
        <XMarkIcon
          onClick={handleClose}
          className="absolute right-3 top-3 h-5 w-5 cursor-pointer text-tabeo-ink-1 hover:text-tabeo-ink-0 desktop:top-auto"
        />
      )}
    </div>
  )
}

export default Notification
