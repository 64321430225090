import React from 'react'
import PropTypes from 'prop-types'

import { Text, SimpleModal } from '@tabeo/scarf'
import { Trans, useTranslation } from 'react-i18next'

const PlanAccessibilityModal = ({ innerRef }) => {
  const { t } = useTranslation()
  return (
    <SimpleModal innerRef={innerRef} title={t('Sharing settings')}>
      <Text mb={6}>
        <Trans>
          This panel allows you to manage how clients sign up to your plans. If
          you would like to create a customised plan and offer it only to
          certain clients you can “hide” it from the public with{' '}
          <Text fontWeight="semibold" as="span">
            private mode
          </Text>
          .
        </Trans>
      </Text>
      <Text fontWeight="semibold">{t('Private mode:')}</Text>
      <Text>
        <Trans>
          If this switch is{' '}
          <Text fontWeight="semibold" as="span">
            off
          </Text>
          , the plan is public and available for anyone to sign up.
        </Trans>
      </Text>
      <Text>
        <Trans>
          If the switch is{' '}
          <Text fontWeight="semibold" as="span">
            on
          </Text>
          , you can only invite clients individually using the{' '}
          <i>New Transaction</i> page.
        </Trans>
      </Text>
    </SimpleModal>
  )
}

PlanAccessibilityModal.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
}

export default PlanAccessibilityModal
