import React from 'react'

export type EntityContextType = {
  color?: string
  selected?: boolean
  hover?: boolean
  disabled?: boolean
  linked?: boolean
}

const EntityContext = React.createContext<EntityContextType>({})

export default EntityContext
