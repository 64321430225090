import { DL, Flex, Settings, Switch, View } from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import LogoUploadModal from './LogoUploadModal'
import PrimaryColorUpdateModal from './PrimaryColorUpdateModal'

const { Section } = Settings

function Brand({ resource }) {
  const primaryColorUpdateModal = React.useRef()
  const logoUploadModal = React.useRef()

  const {
    data: merchant,
    updateMerchantMetadata,
    uploadMerchantFile,
    setMerchantFlag,
  } = resource
  const { t } = useTranslation()

  const theme = get(merchant, 'metadata.merchantTheme', {})

  const handleThemeUpdate = async payload => {
    const logoUploads =
      merchant?.uploads?.filter(l => l.type === 'merchant_logo_image') || []
    try {
      await updateMerchantMetadata({
        type: 'merchant_theme',
        merchantTheme: {
          ...theme,
          uploadID: logoUploads?.[logoUploads - 1]?.id,
          ...payload,
        },
      })
    } catch (e) {
      return formError(e)
    }
  }

  return (
    <View>
      <Section _notLast={{ borderBottom: 0, pb: 4 }}>
        <Section.Header mb="-12px">
          <View>
            <Section.Title>{t('Your brand')}</Section.Title>
            <Section.Description>
              {t(
                'Add your logo and primary colour to customise your team’s dashboard.'
              )}
            </Section.Description>
          </View>
        </Section.Header>
        <DL>
          <DL.Item>
            <LogoUploadModal
              innerRef={logoUploadModal}
              updateTheme={handleThemeUpdate}
              uploadMerchantFile={uploadMerchantFile}
            />
            <DL.DT alignSelf="center">{t('Logo')}</DL.DT>
            <DL.DD>
              <Flex>
                {theme.logo && (
                  <View
                    as="img"
                    maxWidth="106px"
                    maxHeight={['50px', '32px']}
                    objectFit="cover"
                    src={theme.logo}
                    mb={[4, 0]}
                  />
                )}
              </Flex>
              <DL.DD.Actions>
                <DL.DD.Action onClick={() => logoUploadModal.current.open()}>
                  {theme.logo ? t('Update') : t('Add')}
                </DL.DD.Action>
              </DL.DD.Actions>
            </DL.DD>
          </DL.Item>
          <DL.Item>
            <PrimaryColorUpdateModal
              innerRef={primaryColorUpdateModal}
              updateTheme={handleThemeUpdate}
              theme={theme}
            />
            <DL.DT alignSelf="center">{t('Primary colour')}</DL.DT>
            <DL.DD>
              <Flex>
                {theme.primaryColor && (
                  <>
                    <View
                      width="16px"
                      height="16px"
                      borderRadius={1}
                      bg={theme.primaryColor}
                      mr={1}
                    />
                    <DL.DD.Value>
                      {theme.primaryColor.toUpperCase()}
                    </DL.DD.Value>
                  </>
                )}
              </Flex>
              <DL.DD.Actions>
                <DL.DD.Action
                  onClick={() => primaryColorUpdateModal.current.open()}
                  disabled={!theme.logo}
                >
                  {t('Update')}
                </DL.DD.Action>
              </DL.DD.Actions>
            </DL.DD>
          </DL.Item>
        </DL>
      </Section>
      <Section _notLast={{ borderBottom: 0, pb: 4 }}>
        <Section.Header mb="-12px">
          <View>
            <Section.Title>{t('Where your brand is applied')}</Section.Title>
            <Section.Description>
              {t(
                'Customise the look and feel of your patient experience for each product.'
              )}
            </Section.Description>
          </View>
        </Section.Header>
        <DL>
          <DL.Item>
            <LogoUploadModal
              innerRef={logoUploadModal}
              updateTheme={handleThemeUpdate}
              uploadMerchantFile={uploadMerchantFile}
            />
            <DL.DT alignSelf="center">{t('Finance & Card payments')}</DL.DT>
            <DL.DD>
              <DL.DD.Value />
              <DL.DD.Actions>
                <Switch
                  defaultValue={
                    merchant.flags.is_white_labeling_capable_finance
                  }
                  input={{
                    onChange: v =>
                      setMerchantFlag({
                        name: 'is_white_labeling_capable_finance',
                        value: v,
                      }),
                  }}
                />
              </DL.DD.Actions>
            </DL.DD>
          </DL.Item>
          <DL.Item>
            <DL.DT alignSelf="center">{t('Care Plans')}</DL.DT>
            <DL.DD>
              <DL.DD.Value />
              <DL.DD.Actions>
                <Switch
                  defaultValue={merchant.flags.is_white_labeling_capable_plans}
                  input={{
                    onChange: v =>
                      setMerchantFlag({
                        name: 'is_white_labeling_capable_plans',
                        value: v,
                      }),
                  }}
                />
              </DL.DD.Actions>
            </DL.DD>
          </DL.Item>
        </DL>
      </Section>
    </View>
  )
}

Brand.propTypes = {
  resource: PropTypes.object.isRequired,
}

export default Brand
