import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import Overview from './Overview'

export type TerminalPaymentOverviewProps = {
  price: number
}

function TerminalPaymentOverview(props: TerminalPaymentOverviewProps) {
  const { data: merchant } = useMerchant()

  const { t } = useTranslation()

  const { price } = props

  return (
    <Overview
      hidePreview={!price}
      placeholder={t(
        'Start typing in the Transaction section on the left to see the terminal payment summary'
      )}
      price={price}
      isAmexCapable={!!merchant?.activeFeeGroup?.isAmexCapable}
    />
  )
}

export default TerminalPaymentOverview
