import { Button, Caption, Flex, Heading, View } from '@tabeo/scarf'
import FieldError from 'components/Form/FieldError'
import Card from 'components/Transaction/ActionPanel/Card'
import usePromise from 'hooks/usePromise'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import icon from './assets/review.svg'

const types = {
  consumer_proof_of_income: 'proof of income',
  consumer_two_bank_statements:
    'a bank statement covering the last 2 months of transactions',
  consumer_proof_of_id: 'proof of identity',
}

const listFormat = array => {
  if (array.length === 1) {
    return array[0]
  }

  const last = array[array.length - 1]
  const rest = array.slice(0, array.length - 1)

  return `${rest.join(', ')} and ${last}`
}

function Review({ transaction, ...rest }) {
  const { data, sendReminder, cancel } = transaction
  const { consumerFirstName, consumerHeShe, isIFinance } = data
  const [triggerReminder, reminderState] = usePromise(sendReminder)
  const [triggerCancel, cancelState] = usePromise(cancel)
  const { t } = useTranslation()

  const { manualReviewRequirements } = data

  const heSheText =
    consumerHeShe === 'he'
      ? t(
          'We need to verify a few details manually, {{consumerFirstName}} has been asked to upload documents as {{documents}}. If he has uploaded these already a member of our team will review them shortly.',
          {
            consumerFirstName,
            documents: listFormat(
              manualReviewRequirements.map(m => types[m.type])
            ),
          }
        )
      : t(
          'We need to verify a few details manually, {{consumerFirstName}} has been asked to upload documents as {{documents}}. If she has uploaded these already a member of our team will review them shortly.',
          {
            consumerFirstName,
            documents: listFormat(
              manualReviewRequirements.map(m => types[m.type])
            ),
          }
        )

  const ifinanceText =
    consumerHeShe === 'he'
      ? t(
          'We need to verify a few details manually, {{consumerFirstName}} has been asked to provide documentation to prove his income.',
          {
            consumerFirstName,
          }
        )
      : t(
          'We need to verify a few details manually, {{consumerFirstName}} has been asked to provide documentation to prove her income.',
          {
            consumerFirstName,
          }
        )

  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Pending manual review')}</Heading>
      </Flex>
      <Caption color="ink.1" mb={4}>
        {isIFinance ? (
          ifinanceText
        ) : (
          <>
            {!manualReviewRequirements.length && (
              <>
                {t(
                  'We need to verify a few details manually, a member of our team will review {{consumerFirstName}}’s case shortly. This won’t take more than 24 hours, although most cases are resolved within 1 hour.',
                  { consumerFirstName }
                )}
              </>
            )}
            {!!manualReviewRequirements.length && <>{heSheText}</>}
          </>
        )}
      </Caption>
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="flex-start"
        alignItems={['stretch', 'center']}
      >
        <Button
          variant="secondary"
          sizeVariant="sm"
          mr={[0, 4]}
          mb={[4, 0]}
          onClick={triggerReminder}
          loading={reminderState.pending}
          disabled={reminderState.success}
        >
          {reminderState.success ? t('Reminder sent') : t('Send reminder')}
        </Button>
        <Button
          variant="secondary"
          sizeVariant="sm"
          onClick={triggerCancel}
          loading={cancelState.pending}
        >
          {t('Cancel transaction')}
        </Button>
      </Flex>
      {(reminderState.error || cancelState.error) && (
        <FieldError
          message={t('There was an error, please try again later.')}
          mt={4}
        />
      )}
    </Card>
  )
}

Review.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default Review
