import React, { Component } from 'react'

export type TabsProps = {
  children: ({
    activeIndex,
    changeActiveIndex,
  }: {
    activeIndex: number
    changeActiveIndex: (index: number) => void
  }) => React.ReactNode
  initialIndex?: number
}

class Tabs extends Component<TabsProps, { activeIndex: number }> {
  constructor(props: TabsProps) {
    super(props)
    const { initialIndex } = props
    this.state = {
      activeIndex: initialIndex || 0,
    }
  }

  changeActiveIndex = (index: number) =>
    new Promise<void>((resolve) => {
      this.setState(
        {
          activeIndex: index,
        },
        () => {
          resolve()
        }
      )
    })

  render() {
    const { children } = this.props
    const { activeIndex } = this.state

    return children({
      activeIndex,
      changeActiveIndex: this.changeActiveIndex,
    })
  }
}

export default Tabs
