import { RadioGroup } from '@headlessui/react'
import { HeartIcon } from '@heroicons/react/24/outline'
import { ArrowRightIcon, PlusIcon } from '@heroicons/react/24/solid'
import { useMobile } from '@tabeo/scarf'
import {
  FFRadioGroup,
  FFTextInput,
  RadioCards,
  Stepper as StepperItem,
  Tile,
} from '@tabeo/scarf2'
import { lengthRange, required } from '@tabeo/sharpei/utils/validations'
import AutoSave from 'components/nnts/form/AutoSave'
import { Field } from 'components/nnts/form/Field'
import { scrollToErrorDecorator } from 'components/nnts/form/scrollToError'
import { ComponentProps, useCallback } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import trigramSimilarity from 'trigram-similarity'
import { z } from 'zod'
import { ActionButton } from '../components/action-button'
import Header from '../components/header'
import { useNewTransactionFlow } from '../components/provider'
import CategorySection from '../components/treatment/CategorySection'
import MagnifyingGlass from '../components/treatment/MagnifyingGlass'
import useTreatments from '../components/treatment/useTreatments'
import { NNTSState } from '../types'

export const treatmentSchema = z.object({
  treatmentName: z.string().min(1),
  otherTreatmentName: z.string().optional(),
})

export function TreatmentMain() {
  const {
    next,
    setStateForStep,
    state,
    modals: { cartSummaryModalRef },
    form,
    currentIndex,
    flowSteps,
  } = useNewTransactionFlow()

  const { isLast } = flowSteps[currentIndex]

  const { t } = useTranslation()
  const isMobile = useMobile()

  const selectionRequired = useCallback(
    (value: any) => (!value ? t('Please select a treatment') : undefined),
    [t]
  )

  const treatmentsByCategories = useTreatments()

  function handleSubmit(values: any) {
    setStateForStep('treatment', values)
    return next()
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={state.treatment}
      decorators={[scrollToErrorDecorator]}
      destroyOnUnregister
    >
      {({ handleSubmit, values, form: f }) => {
        form.current = f
        const isSearchActive = values?.query && values?.query?.length > 2
        const filteredTreatmentsByCategories = treatmentsByCategories.map(
          category => {
            if (!category) {
              return category
            }
            const { treatments, name } = category

            if (name === t('Other')) {
              return category
            }

            const filteredCategoryItems = isSearchActive
              ? treatments
                  .map(mt => {
                    const searchScore = trigramSimilarity(
                      String(mt),
                      values?.query
                    )
                    return {
                      name: mt,
                      searchScore,
                    }
                  })
                  .filter(mt => mt.searchScore > 0.15)
                  .sort((a, b) => b.searchScore - a.searchScore)
                  .map(mt => mt.name)
              : treatments
            return {
              ...category,
              treatments: filteredCategoryItems,
            }
          }
        )

        const hasCategoriesWithTreatments = filteredTreatmentsByCategories.some(
          category =>
            category &&
            category.treatments.length > 0 &&
            category?.name !== t('Other')
        )
        return (
          <form onSubmit={handleSubmit} className="flex h-full flex-col">
            <Header
              cartSummaryModalRef={cartSummaryModalRef}
              currentIndex={currentIndex}
            />
            <h2 className="title text-tabeo-ink-0">{t('Treatment')}</h2>
            <p className="caption mb-6 text-tabeo-ink-1">
              {t('Select a treatment from the options below.')}
            </p>
            <div className="mb-6 border-b pb-6">
              <Field
                component={FFTextInput}
                name="query"
                label={t('Search treatments')}
                placeholder="Start typing to search..."
                clearable
                wrapClassName="sm:max-w-[386px]"
                autoFocus={!isMobile && !values?.merchantTreatmentId}
              />
            </div>
            <Field
              name="treatmentName"
              validate={[selectionRequired]}
              component={FFRadioGroup}
            >
              <div className="space-y-10">
                {!hasCategoriesWithTreatments && (
                  <div className="text-center">
                    <MagnifyingGlass className="mx-auto mb-3 text-tabeo-primary-3" />
                    <p>{t('No results found')}</p>
                    <p className="caption mt-1 text-tabeo-ink-3">
                      {t(
                        'Modify the search or select “Other” below to manually enter the treatment.'
                      )}
                    </p>
                  </div>
                )}
                {filteredTreatmentsByCategories.map(category => {
                  if (!category) {
                    return null
                  }
                  const { name, treatments } = category
                  return category.treatments.length > 0 ? (
                    <CategorySection
                      key={name}
                      className={
                        category.name === t('Other')
                          ? '-mt-4 border-t pt-6'
                          : ''
                      }
                    >
                      {filteredTreatmentsByCategories.length > 1 &&
                        category.name !== t('Other') && (
                          <CategorySection.Label>{name}</CategorySection.Label>
                        )}
                      <CategorySection.Items
                        items={treatments}
                        compareItems={(a, b) => {
                          if (isSearchActive) {
                            return 0
                          }

                          return a > b ? 1 : -1
                        }}
                        renderItem={label => (
                          <RadioGroup.Option
                            key={label}
                            value={
                              label === t('Other') ? `other-${name}` : label
                            }
                            onClick={() => {
                              if (label !== t('Other') && !isLast) {
                                setTimeout(() => f.submit(), 160)
                              }
                            }}
                          >
                            {({ checked }) => (
                              <Tile
                                key={label}
                                checked={checked}
                                className={
                                  isLast && label !== t('Other')
                                    ? 'justify-start gap-2'
                                    : undefined
                                }
                              >
                                {isLast && label !== t('Other') && (
                                  <RadioCards.OptionDot />
                                )}
                                <Tile.Label>{label}</Tile.Label>
                                {label === t('Other') ? (
                                  <Tile.Icon icon={PlusIcon} />
                                ) : !isLast ? (
                                  <Tile.Icon icon={ArrowRightIcon} />
                                ) : null}
                              </Tile>
                            )}
                          </RadioGroup.Option>
                        )}
                        defaultIsOpen={
                          !!treatments.find(
                            label => label === values.treatmentName
                          )
                        }
                      />
                    </CategorySection>
                  ) : null
                })}
              </div>
            </Field>

            {values?.treatmentName?.startsWith('other-') && (
              <div className="mt-5">
                <div>
                  <h3 className="subheading mb-3 text-tabeo-ink-1">
                    {t('Other treatment')}
                  </h3>
                  <Field
                    name="otherTreatmentName"
                    label={t('Specify name')}
                    placeholder={t('Type name here...')}
                    component={FFTextInput}
                    validate={[required, lengthRange(4, 500)]}
                    autoFocus
                    clearable
                    containerClassName="max-w-[386px]"
                    defaultValue={values?.query}
                  />
                </div>
              </div>
            )}

            <AutoSave
              debounce={0}
              onSave={(values: any) => {
                setStateForStep('treatment', values)
              }}
            />
            <button type="submit" className="hidden">
              {t('Submit')}
            </button>
          </form>
        )
      }}
    </Form>
  )
}

export function TreatmentActions() {
  const { state } = useNewTransactionFlow()
  const { t } = useTranslation()
  const submitLabel = state.treatment?.otherTreatmentName
    ? t('Confirm treatment')
    : t('Continue')
  return <ActionButton>{submitLabel}</ActionButton>
}

export function shouldRenderTreatmentMobileActions(state: NNTSState) {
  return !!state?.treatment?.otherTreatmentName
}

export function TreatmentStepper(props: ComponentProps<typeof StepperItem>) {
  const { state } = useNewTransactionFlow()
  const { t } = useTranslation()
  const treatmentName = state?.treatment?.treatmentName
  const otherTreatmentName = state?.treatment?.otherTreatmentName

  const body = treatmentName?.startsWith('other-')
    ? otherTreatmentName || t('Other')
    : treatmentName

  return (
    <StepperItem empty={!body} {...props}>
      <StepperItem.Header>
        <StepperItem.Icon icon={HeartIcon} />
        <StepperItem.Label>{t('Treatment')}</StepperItem.Label>
      </StepperItem.Header>
      <StepperItem.Body className="line-clamp-4 whitespace-normal">
        {body}
      </StepperItem.Body>
    </StepperItem>
  )
}
