import { DataTable, GridTable } from '@tabeo/scarf'
import { State } from '@tabeo/scarf/dist/components/DataTable/context'
import { useTranslation } from 'react-i18next'
import { useSubscriptionsV2 } from 'resources/SubscriptionsV2'
import ListItem from './ListItem'

function Subscriptions({ planId }: { planId?: string }) {
  const { t } = useTranslation()

  const tabs = [
    { label: t('All'), statuses: [], group: 'singleSelect' },
    { label: t('Sent'), statuses: ['sent', 'sign_up'] },
    { label: t('Active'), statuses: ['active', 'pending', 'bulk_transfer'] },
    { label: t('Late'), statuses: ['overdue', 'no_direct_debit', 'paused'] },
    { label: t('Ended'), statuses: ['ended', 'canceled'] },
  ]

  const mapStateToParams = (state: State) => {
    const status = state?.activeTabs?.flatMap(tab => tab.statuses)

    return {
      ...state,
      conditions: {
        planId,
        status,
      },
    }
  }

  return (
    <DataTable
      tabs={tabs}
      // @ts-ignore
      resourceHook={useSubscriptionsV2}
      mapStateToParams={mapStateToParams}
      paginationLimit={10}
      searchPlaceholder={t('Search by first name, last name or membership ID')}
      data-testid="memberships-table"
    >
      {({ items }) => (
        <GridTable
          templateColumns={
            planId
              ? ['minmax(140px, 1fr) auto']
              : [
                  'minmax(65px,auto) minmax(140px, 1fr) minmax(140px, 1fr) auto auto',
                ]
          }
          minWidth={planId ? undefined : '650px'}
        >
          {items.map(item => (
            <ListItem
              key={item.id}
              data={item}
              fields={
                planId
                  ? {
                      displayId: false,
                      patient: true,
                      plan: false,
                      price: false,
                      status: true,
                    }
                  : {
                      displayId: true,
                      patient: true,
                      plan: true,
                      price: true,
                      status: true,
                    }
              }
            />
          ))}
        </GridTable>
      )}
    </DataTable>
  )
}

export default Subscriptions
