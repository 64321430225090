import {
  Caption,
  Flex,
  Heading,
  Panel,
  Subheading,
  Text,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import PaymentOptions from 'components/Payment/Options'
import { useTranslation } from 'react-i18next'

export type OverviewProps = {
  price: number
  isAmexCapable: boolean
  placeholder: string
  hidePreview: boolean
  header?: string
}

const Overview = ({
  price,
  isAmexCapable,
  placeholder,
  hidePreview,
  header,
}: OverviewProps) => {
  const { t } = useTranslation()

  return (
    <Panel
      header={
        <Heading color="ink.2" textAlign="center" flex="1">
          {header || t('Summary')}
        </Heading>
      }
      mb={6}
    >
      {hidePreview ? (
        <View bg="white" p={7}>
          <Text textAlign="center" color="ink.2">
            {placeholder}
          </Text>
        </View>
      ) : (
        <>
          <View bg="white">
            {!!price && (
              <Flex borderBottom={1} px={5} py={4}>
                <Subheading color="ink.2">{t('Price')}</Subheading>
                <Text>{format(price)}</Text>
              </Flex>
            )}
          </View>
          <View bg="white" textAlign="center" px={10} py={7}>
            <Caption mb={2}>
              {t('We accept the following payment methods:')}
            </Caption>
            <PaymentOptions amex={isAmexCapable} />
          </View>
        </>
      )}
    </Panel>
  )
}

export default Overview
