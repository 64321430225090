import { FFTextInput } from '@tabeo/scarf2'
import React from 'react'
import { PlusIcon, MinusIcon } from '@heroicons/react/20/solid'

function NumberInput(props: React.ComponentProps<typeof FFTextInput>) {
  /* eslint-disable react/prop-types */
  const {
    min,
    max,
    input: { value, onChange },
  } = props
  /* eslint-enable react/prop-types */

  const buttonClassName =
    'text-tabeo-primary-3 disabled:cursor-not-allowed disabled:text-tabeo-ink-2'

  return (
    <div className="flex items-center gap-1">
      <button
        type="button"
        disabled={min !== undefined ? value <= min : undefined}
        onClick={() => onChange(Number(value) - 1)}
        className={buttonClassName}
      >
        <MinusIcon className="h-4 w-4" />
      </button>
      <FFTextInput {...props} className="w-11 text-center" readOnly />
      <button
        type="button"
        disabled={max !== undefined ? value >= max : undefined}
        onClick={() => onChange(Number(value) + 1)}
        className={buttonClassName}
      >
        <PlusIcon className="h-4 w-4" />
      </button>
    </div>
  )
}

export default NumberInput
