import { Flex, Icons, Prompt } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

function FinancePanel({ firstName, productAnswers, ...rest }) {
  const { t } = useTranslation()
  if (!firstName || !productAnswers || !productAnswers.interested_in_finance) {
    return null
  }
  return (
    <Flex
      bg="white"
      boxShadow={1}
      borderRadius={1}
      border="1px solid #6dccac"
      px={5}
      py={4}
      {...rest}
    >
      <Prompt>
        {t('{{leadFirstName}} is interested in paying with finance', {
          leadFirstName: firstName,
        })}
      </Prompt>
      <Icons.Tick fill="sea.3" />
    </Flex>
  )
}

FinancePanel.propTypes = {
  firstName: PropTypes.string,
  productAnswers: PropTypes.object,
}

export default FinancePanel
