import {
  Accordion,
  Attributes,
  Icons,
  Panel,
  Tooltip2,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import { t } from 'i18n'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Trans } from 'react-i18next'

function LabelWithTooltip({ tooltip, children }) {
  return (
    <div className="flex items-center gap-1">
      <div className="whitespace-nowrap">{children}</div>
      <Tooltip2 title={tooltip} maxWidth="280px">
        <View>
          <Icons.Questionmark />
        </View>
      </Tooltip2>
    </div>
  )
}

LabelWithTooltip.propTypes = {
  tooltip: PropTypes.string,
  children: PropTypes.node,
}

const LOWER_COST = 'lower_cost'
const DUPLICATED = 'duplicate_or_error'
const DISCOUNT = 'discount'
const CHANGED_MIND = 'mind_change'
const UNHAPPY = 'dissatisfaction'
const CLINICALLY_UNSUITABLE = 'clinically_unsuitable'

const refundReasons = [
  { label: t('Patient changed mind'), value: CHANGED_MIND },
  {
    label: t('Patient unhappy with treatment'),
    value: UNHAPPY,
  },
  {
    label: t('Clinically unsuitable'),
    value: CLINICALLY_UNSUITABLE,
  },
  { label: t('Discount'), value: DISCOUNT },
  { label: t('Duplicate or error'), value: DUPLICATED },
  {
    label: t('Cost of treatment lower than expected'),
    value: LOWER_COST,
  },
]

const sectionFromRefund = (
  {
    executedAt,
    refundedFeeAmount,
    lateRefundFeeAmount,
    refundReason,
    retentionReason,
    amount,
    merchantRefundPaymentAmount,
  },
  { feeAmount, feePercent, paymentPlan: { principalAmount, apr } }
) => [
  [
    {
      label: (
        <LabelWithTooltip
          tooltip={
            <Trans>
              If the customer has paid more than the final treatment cost, the
              refund will be processed to their bank account. If they have paid
              less than the final treatment cost, their loan will be adjusted
              accordingly.
            </Trans>
          }
        >
          {t('Refund amount to customer')}
        </LabelWithTooltip>
      ),
      formattedValue: format(amount),
      value: amount,
    },
    {
      label: (
        <LabelWithTooltip
          tooltip={t(
            `You previously paid a {{amount}}% ({{fee}}) fee based on the original treatment cost of {{cost}}. This amount represents the fee you are to be refunded now that the treatment cost is lower.`,
            {
              amount: (feePercent * 100).toFixed(1),
              fee: format(feeAmount),
              cost: format(principalAmount),
            }
          )}
        >
          {t('Fee refunded to merchant')}
        </LabelWithTooltip>
      ),
      formattedValue: format(refundedFeeAmount),
      value: refundedFeeAmount,
    },
    {
      label: (
        <LabelWithTooltip
          tooltip={t(
            'For refunds that are triggered after more than 14 days from the transaction confirmation date, we apply a fee of {{feeAmount}}% (for {{interestBearingFree}} loans). This is based on the amount to refund.',
            {
              feeAmount:
                apr !== 0 ? Math.min(feePercent * 100, 3).toFixed(1) : 3,
              interestBearingFree:
                apr !== 0 ? t('interest-bearing') : t('interest-free'),
            }
          )}
        >
          {t('Late refund fee')}
        </LabelWithTooltip>
      ),
      formattedValue: format(lateRefundFeeAmount),
      value: lateRefundFeeAmount,
    },
  ],
  [
    {
      label: t('Refund payment'),
      formattedValue: format(merchantRefundPaymentAmount),
      value: merchantRefundPaymentAmount,
    },
  ],
  [
    {
      label: t('Executed on'),
      value: executedAt,
      formattedValue: moment(executedAt).format('DD MMM YYYY, hh:mm a'),
    },
    {
      label: t('Reason for refund'),
      value: refundReason,
      formattedValue:
        refundReasons.find(reason => reason.value === refundReason)?.label ||
        '',
    },
    { label: t('Reason why not full refund'), value: retentionReason },
  ],
]

const getRefundTitle = ({ refundReason, amount, retentionReason }) => {
  if (refundReason === DISCOUNT) {
    return t('Discount of {{amount}}', { amount: format(amount) })
  }
  if (!retentionReason) {
    return t(`Full refund of {{amount}}`, { amount: format(amount) })
  }
  return t(`Partial refund of {{amount}}`, { amount: format(amount) })
}

const RefundsPanel = ({ data, ...rest }) => {
  return data?.refunds?.length > 0 ? (
    <Panel
      header={
        <>
          <Panel.Title>{t('Refunds')}</Panel.Title>
        </>
      }
      {...rest}
    >
      {data?.refunds?.map(refund => (
        <Accordion
          key={refund.id}
          title={getRefundTitle(refund)}
          subtitle={moment(refund.executedAt).format('DD MMM YYYY, hh:mm a')}
        >
          <Attributes
            sections={sectionFromRefund(refund, data)}
            data-testid="refund-details"
          />
        </Accordion>
      ))}
    </Panel>
  ) : null
}

RefundsPanel.propTypes = {
  data: PropTypes.shape({
    refunds: PropTypes.array,
  }),
}

export default RefundsPanel
