import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'

const subResources = `&${formatSubresources([
  'stateMachineTransitions',
  'specializations',
  'boards.board',
])}`

class Clinicians extends Resource {
  getInitialData() {
    return {
      items: null,
      totalCount: 0,
    }
  }

  getInitialConfig() {
    return {
      refreshInterval: 60 * 1000,
    }
  }

  async fetch(query) {
    const {
      conditions = [],
      searchQuery = query,
      limit = 10,
      offset = 0,
      orderBy: { field, direction } = { field: 'id', direction: 'asc' },
    } = this.params

    const jsonConditions = `&jsonConditions=${encodeURIComponent(
      JSON.stringify(conditions)
    )}`
    const pagination = `&limit=${limit}&offset=${offset}`
    const endpoint = `/merchant/clinician-search?order=${field}:${direction}&searchPhrase=${encodeURIComponent(
      searchQuery
    )}`

    const {
      data: { clinicians },
      totalCount,
    } = await api.get(
      `${endpoint}${jsonConditions}${pagination}${subResources}`,
      {
        responseDataPath: 'data',
      }
    )

    const data = {
      totalCount,
      items: clinicians,
    }
    this.data = data

    return data
  }
}

export default Clinicians
export const useClinicians = createResourceHook(Clinicians)
