import {
  ChatBubbleBottomCenterTextIcon,
  EnvelopeIcon,
  LinkIcon,
} from '@heroicons/react/24/outline'
import * as Tabs from '@radix-ui/react-tabs'
import formError from '@tabeo/sharpei/utils/formError'
import { getAppUrlForCountry } from 'config'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useBasket } from 'resources/Basket'
import { useLinkPayment } from 'resources/LinkPayment'
import { usePatient } from 'resources/Patient'
import Patients from 'resources/Patients'
import Content from './Content'
import CopyLink from './CopyLink'
import EmailForm from './EmailForm'
import SmsForm from './SmsForm'
import Tab from './Tab'

export type ShareProps = {
  linkPaymentId: number | string
  isReminder?: boolean
}

export default function Share({
  linkPaymentId,
  isReminder = false,
}: ShareProps) {
  const { t } = useTranslation()

  const { data: linkPayment, resource } = useLinkPayment({ id: linkPaymentId })
  const { data: basket, resource: basketResource } = useBasket(() => {
    const id = linkPayment?.basket.id

    if (!id) {
      throw new Error('Basket ID is missing')
    }

    return {
      id,
    }
  })

  const { data: patient, resource: patientResource } = usePatient(() => {
    const id = linkPayment?.basket.patient?.id

    if (!id) {
      throw new Error('Patient ID is missing')
    }

    return {
      id,
    }
  })

  const handleSubmit = useCallback(
    async ({
      email,
      phoneNumber,
    }: {
      email?: string
      phoneNumber?: string
    }) => {
      try {
        if (patient) {
          await Promise.all([
            patientResource?.update({
              ...patient,
              email: email || patient.email,
              phoneNumber: phoneNumber || patient.phoneNumber,
            }),
            resource?.sendLink({ email, phoneNumber, isReminder }),
          ])
        } else {
          const patient = await Patients.create({
            email,
            phoneNumber,
          })
          await basketResource?.addPatient(patient.id)
          await resource?.sendLink({ email, phoneNumber, isReminder })
        }
      } catch (e) {
        return formError(e)
      }
    },
    [patient, patientResource, resource, isReminder, basketResource]
  )

  return (
    <div>
      <Tabs.Root
        key={basket?.id}
        defaultValue={
          basket?.patient?.email
            ? 'email'
            : basket?.patient?.phoneNumber
            ? 'sms'
            : isReminder
            ? 'email'
            : undefined
        }
      >
        <Tabs.List className="flex gap-4">
          <Tab value="email">
            <EnvelopeIcon className="h-5 w-5 text-tabeo-ink-1" />
            {t('Email')}
          </Tab>
          <Tab value="sms">
            <ChatBubbleBottomCenterTextIcon className="h-5 w-5 text-tabeo-ink-1" />
            {t('Message')}
          </Tab>
          <Tab value="link">
            <LinkIcon className="h-5 w-5 text-tabeo-ink-1" />
            {t('Link')}
          </Tab>
        </Tabs.List>
        <Content value="email">
          <EmailForm
            onSubmit={handleSubmit}
            initialValue={{ email: patient?.email }}
            buttonVariant={isReminder ? 'primary' : 'secondary'}
          />
        </Content>
        <Content value="sms">
          <SmsForm
            onSubmit={handleSubmit}
            initialValue={{ phoneNumber: patient?.phoneNumber }}
            buttonVariant={isReminder ? 'primary' : 'secondary'}
          />
        </Content>
        <Content value="link" className="min-h-[108px]">
          <CopyLink
            url={`${getAppUrlForCountry('pay', 'gb')}/PL/${
              linkPayment?.accessToken
            }`}
          />
        </Content>
      </Tabs.Root>
    </div>
  )
}
