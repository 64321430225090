import { themeGet } from '@styled-system/theme-get'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import styled from 'styled-components'
import { useMobile } from '../../../hooks/useMatchMedia'
import { Merge } from '../../../types/helpers'
import ScarfProps from '../../../types/ScarfProps'
import Flex from '../../Flex'
import Popover from '../../Popover'
import { Caption } from '../../Text'
import Select, { Item, SelectProps } from '../Select'

const StyledFlex = styled(Flex)`
  :hover {
    background: ${themeGet('colors.sky.3')};
  }
`

StyledFlex.defaultProps = {
  height: '100%',
  flex: '1',
}

export interface EntityType {
  type: string
  label: string
  loader: (query: string) => Promise<any[]>
  placeholder?: string
  renderItem: (item: Item, props: any) => React.ReactElement
  disabledValues?: SelectProps['disabledValues']
  link?: (value?: {
    type?: string
    id?: string | number | null
    data?: any
  }) => void
}

export type EntitySelectProps = Merge<
  ScarfProps,
  {
    open?: boolean
    toggle: () => void
    value?: Item
    onChange: SelectProps['onChange']
    type?: EntityType
    disabledValues?: SelectProps['disabledValues']
  }
>

function EntitySelect({
  open,
  toggle,
  value,
  onChange,
  type,
  disabledValues,
  ...rest
}: EntitySelectProps) {
  const mobile = useMobile()
  const ref = useRef<Element>()

  const width = ref.current?.clientWidth

  return (
    <>
      <Popover
        open={open}
        onClickOut={() => toggle()}
        placement="bottom-start"
        referenceElement={ref}
        offset={[0, 4]}
      >
        {({ ref, style, placement, update }) => (
          <Select
            ref={ref}
            style={mobile ? { zIndex: 1000 } : style}
            data-placement={placement}
            placeholder="Search by id or name"
            value={value}
            width={['100%', `${width}px`]}
            onHitsUpdate={update}
            onChange={(e) => {
              onChange(e)
              toggle()
            }}
            onClose={() => toggle()}
            renderItem={type?.renderItem}
            loadOptions={type?.loader}
            disabledValues={disabledValues}
            filtering
            selectedFirst
            data-testid="entity-select"
          />
        )}
      </Popover>
      <StyledFlex
        ref={ref}
        justifyContent={!value ? 'center' : 'flex-start'}
        onClick={() => {
          toggle()
        }}
        minWidth="0px"
        cursor="pointer"
        {...rest}
      >
        {value ? (
          type?.renderItem(value, { linked: true })
        ) : (
          <Caption fontSize={['16px', '14px']}>
            {type?.placeholder || 'ID or name'}
          </Caption>
        )}
      </StyledFlex>
    </>
  )
}

EntitySelect.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onChange: PropTypes.func,
  type: PropTypes.shape({
    renderItem: PropTypes.func,
    loader: PropTypes.func,
    placeholder: PropTypes.string,
  }),
  disabledValues: PropTypes.array,
}

export default EntitySelect
