import { View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import BankDetails from './BankDetails'

function PayoutsAndBilling({ resource }) {
  return (
    <View>
      <BankDetails resource={resource} />
    </View>
  )
}

PayoutsAndBilling.propTypes = {
  resource: PropTypes.object.isRequired,
}

export default PayoutsAndBilling
