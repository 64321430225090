import {
  AddButton,
  Box,
  Button,
  DateTime,
  Icons,
  Modal,
  Subtitle,
  Text,
  TextInput,
  Title,
  View,
} from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import { required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Container = styled(Box)`
  overflow: visible;
`

Container.defaultProps = {
  maxWidth: ['95%', '460px'],
  mx: 'auto',
  boxShadow: 2,
  p: [5, '40px'],
}

class BookingModal extends Component {
  state = {
    showField: false,
  }

  handleSubmit = async payload => {
    const { onSubmit } = this.props
    try {
      await onSubmit(payload)
    } catch (e) {
      return formError(e)
    }
  }

  showExtraField = () => {
    this.setState({
      showField: true,
    })
  }

  render() {
    const { innerRef, firstName, appointment, t } = this.props

    const { showField } = this.state
    return (
      <Modal
        ref={innerRef}
        containerProps={{
          style: { overflowY: 'visible' },
          maxHeight: 'initial',
        }}
      >
        {({ isOpen, close }) =>
          isOpen && (
            <Container>
              <Form onSubmit={this.handleSubmit} initialValues={appointment}>
                {({ handleSubmit, submitting, submitSucceeded }) => {
                  return submitSucceeded ? (
                    <View textAlign="center">
                      <Icons.Tick
                        light
                        width="60px"
                        height="60px"
                        mx="auto"
                        mb={4}
                      />
                      <Subtitle mb={3} color="default.primary">
                        {appointment?.id
                          ? t('Booking updated')
                          : t('Booking created')}
                      </Subtitle>
                      <Text mb={6}>
                        {t(
                          '{{leadFirstName}} will receive a confirmation by email.',
                          { leadFirstName: firstName }
                        )}
                      </Text>
                      <Button variant="secondary" onClick={close}>
                        {t('Close')}
                      </Button>
                    </View>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <Title mb={3}>
                        {appointment?.id
                          ? t('Update booking')
                          : t('Book consultation')}
                      </Title>
                      <Text mb={4}>
                        {t(
                          'The customer will receive a reminder 3 days before and on the day of the booking.'
                        )}
                      </Text>
                      <Field
                        name="scheduledAt"
                        label={t('Date')}
                        component={DateTime}
                        validate={required}
                        containerProps={{
                          width: ['100%', '155px'],
                        }}
                        alignPopupToTop
                      />
                      {showField || appointment?.personName ? (
                        <Field
                          name="personName"
                          label={t('Clinician (optional)')}
                          placeholder={t('E.g. Dr. Jones')}
                          component={TextInput}
                        />
                      ) : (
                        <AddButton onClick={this.showExtraField} mb={6}>
                          {t('Add clinician')}
                        </AddButton>
                      )}
                      <View>
                        <Button type="submit" loading={submitting} width="100%">
                          {appointment?.id
                            ? t('Update booking')
                            : t('Book consultation')}
                        </Button>
                        <FormError />
                      </View>
                    </form>
                  )
                }}
              </Form>
            </Container>
          )
        }
      </Modal>
    )
  }
}

BookingModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  appointment: PropTypes.object,
  t: PropTypes.func,
}

export default props => {
  const { t } = useTranslation()
  return <BookingModal t={t} {...props} />
}
