import React, { Component } from 'react'
import styled from 'styled-components'
import { Merge } from '../../types/helpers'
import ScarfProps from '../../types/ScarfProps'
import TextInput from '../Form/Input'
import View from '../View'
import icon from './assets/looking-glass.svg'

const Icon = styled(View)<ScarfProps<'img'>>``
Icon.defaultProps = {
  as: 'img',
  src: icon,
}

const SearchInput = styled(TextInput)`
  flex: 1;
  border: none;
  border-radius: 4px 0 0 4px;
  padding-top: 6px;
  padding-bottom: 6px;
  &:after {
    display: none;
  }
  input::placeholder {
    font-size: 14px;
    font-weight: 400;
  }
`

SearchInput.defaultProps = {
  bg: 'transparent',
}

export type SearchProps = Merge<
  ScarfProps,
  {
    placeholder?: string
    onChange?: (value: string) => void
    value?: string
  }
>

class Search extends Component<SearchProps> {
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props
    const { value } = e.target
    onChange?.(value)
  }

  render() {
    const { placeholder, onChange, value, ...rest } = this.props
    return (
      <View flex="1" {...rest}>
        <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
          <SearchInput
            input={{ onChange: this.handleChange, value }}
            autoComplete="false"
            maxWidth="100%"
            left={
              <View maxHeight="24px">
                <Icon />
              </View>
            }
            placeholder={placeholder}
            variant="outline"
          />
        </form>
      </View>
    )
  }
}

// @ts-ignore
Search.defaultProps = {
  onChange: () => {},
}

export default Search
