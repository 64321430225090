import React from 'react'
import { twMerge } from 'tailwind-merge'

export function Section({ children }: { children: React.ReactNode }) {
  return <div className="border-t first:border-t-0">{children}</div>
}

export function SectionHeader({ children }: { children: React.ReactNode }) {
  return (
    <div className="border-t bg-tabeo-sky-3 px-5 py-3 first:border-t-0">
      {children}
    </div>
  )
}

export function SectionContent({
  // eslint-disable-next-line react/prop-types
  className,
  ...rest
}: React.ComponentPropsWithoutRef<'div'>) {
  return <div className={twMerge('space-y-5 p-5', className)} {...rest} />
}

export function SectionTitle({ children }: { children: React.ReactNode }) {
  return <h3 className="heading font-normal text-tabeo-ink-1">{children}</h3>
}
