import { DL, Settings, View } from '@tabeo/scarf'
import handleFileDownload from '@tabeo/sharpei/utils/handleFileDownload'
import config from 'config'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useAuth } from 'resources/Auth'
import getDocumentUrl from 'utils/getDocumentUrl'

const { Section } = Settings

function Documents({ resource }) {
  const { data: merchant } = resource

  const { t } = useTranslation()
  const {
    data: { token },
  } = useAuth()

  const termsAndCondition = merchant.documents
    .filter(
      d =>
        d.type === 'merchant_terms_and_conditions' &&
        ['accepted', 'deemed_accepted'].includes(d.status)
    )
    .sort((a, b) => Number(b.version) - Number(a.version))[0]

  return (
    <Section>
      <Section.Header mb="-12px">
        <View>
          <Section.Title>{t('Documents')}</Section.Title>
        </View>
      </Section.Header>
      <DL>
        <DL.Item>
          <DL.DT>{t('Merchant Terms & Conditions')}</DL.DT>
          <DL.DD>
            <DL.DD.Value />
            <DL.DD.Actions>
              <DL.DD.Action as={Link} to="/terms">
                {t('View')}
              </DL.DD.Action>
              {termsAndCondition.isDownloadable && (
                <>
                  <DL.DD.Separator />
                  <DL.DD.Action
                    onClick={() =>
                      handleFileDownload(getDocumentUrl(termsAndCondition), {
                        token,
                      })
                    }
                    rel="noopener noreferrer"
                  >
                    {t('Download')}
                  </DL.DD.Action>
                </>
              )}
            </DL.DD.Actions>
          </DL.DD>
        </DL.Item>
        <DL.Item>
          <DL.DT>{t('User Terms & Conditions')}</DL.DT>
          <DL.DD>
            <DL.DD.Value />
            <DL.DD.Actions>
              <DL.DD.Action
                as="a"
                href={`${config.LANDING_PAGE_URL}/terms-and-conditions`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('View')}
              </DL.DD.Action>
            </DL.DD.Actions>
          </DL.DD>
        </DL.Item>
        <DL.Item>
          <DL.DT>{t('Privacy Policy')}</DL.DT>
          <DL.DD>
            <DL.DD.Value />
            <DL.DD.Actions>
              <DL.DD.Action
                as="a"
                href={`${config.LANDING_PAGE_URL}/privacy-policy`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('View')}
              </DL.DD.Action>
            </DL.DD.Actions>
          </DL.DD>
        </DL.Item>
      </DL>
    </Section>
  )
}

Documents.propTypes = {
  resource: PropTypes.any,
}

export default Documents
