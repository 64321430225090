/* eslint-disable no-unsafe-optional-chaining */
import { themeGet } from '@styled-system/theme-get'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ScarfProps from '../../types/ScarfProps'
import View from '../View'
import Avatar from './index'

const AvatarList = styled(View)`
  ${Avatar}:not(:first-of-type) {
    margin-left: -${themeGet('space.1')}px;
  }

  ${Avatar}:hover {
    z-index: ${(p) => React.Children.toArray(p.children)?.length};
  }

  ${({ children }) =>
    React.Children.map(
      children,
      (_, i) => `
        ${Avatar}:not(:hover):nth-of-type(${i + 1}) {
          z-index: ${React.Children.toArray(children)?.length - (i + 1)};
        }
      `
    )}
`

AvatarList.defaultProps = {
  display: 'flex',
  bg: 'inherit',
}

function getAvatarsToDisplay(
  hover: boolean,
  children: React.ReactNode,
  maxAvatars: number
) {
  const avatars = React.Children.toArray(children)

  if (!hover && avatars?.length > maxAvatars) {
    const plusText = `+${avatars?.length - maxAvatars}`
    // @ts-ignore
    const plusProps = avatars[0].props

    return [
      ...avatars.slice(0, maxAvatars),
      <Avatar {...plusProps} key={plusText}>
        {plusText}
      </Avatar>,
    ]
  }

  return children
}

type CollapsibleAvatarListProps = ScarfProps & {
  maxAvatars?: number
}

function CollapsibleAvatarList({
  maxAvatars = 4,
  children,
  ...rest
}: CollapsibleAvatarListProps) {
  const [hover, setHover] = useState(false)
  const [avatars, setAvatars] = useState(() =>
    getAvatarsToDisplay(hover, children, maxAvatars)
  )

  useEffect(() => {
    setAvatars(getAvatarsToDisplay(hover, children, maxAvatars))
  }, [children, hover, maxAvatars])

  return (
    <AvatarList
      {...rest}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {avatars}
    </AvatarList>
  )
}

export default CollapsibleAvatarList
