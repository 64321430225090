import { Spinner, Title } from '@tabeo/scarf'
import React from 'react'
import { useTranslation } from 'react-i18next'

function Processing() {
  const { t } = useTranslation()

  return (
    <div className="text-center">
      <Spinner minHeight="160px" />
      <Title mt={6}>{t('Processing refund')}</Title>
    </div>
  )
}

export default Processing
