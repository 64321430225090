import {
  Button,
  Clickable,
  Flex,
  Icons,
  Modal,
  Prompt,
  Radio,
  Subtitle,
  Text,
  TextInput,
  themeGet,
  View,
} from '@tabeo/scarf'
import Dot from 'components/Dot'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import Toggle from 'components/Toggle'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import getColors from './getColorsFromImg'

const Item = styled(View)`
  width: 28px;
  height: 28px;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(:last-of-type) {
    margin-right: 24px;
  }
`

Item.defaultProps = {
  borderRadius: 1,
  border: 1,
}

const Left = () => (
  <Flex
    bg="sky.2"
    width="32px"
    height="30px"
    justifyContent="center"
    borderRight={1}
  >
    <Text color="ink.2">#</Text>
  </Flex>
)

const CustomTextInput = styled(TextInput)`
  border: 1px solid ${themeGet('colors.sky.1')};
  padding-left: 0;
  overflow: hidden;
  &:after {
    display: none !important;
  }
`

CustomTextInput.defaultProps = {
  left: <Left />,
  bg: 'white',
  maxWidth: '130px',
  height: '32px',
}

const initialState = {
  colors: [],
}

class PrimaryColorUpdateModal extends Component {
  state = initialState

  componentDidMount() {
    this.setColorsFromLogo()
  }

  componentDidUpdate() {
    const {
      theme: { primaryColor },
    } = this.props
    const { colors } = this.state
    if (!colors.includes(primaryColor)) {
      this.setColorsFromLogo()
    }
  }

  setColorsFromLogo = () => {
    const {
      theme: { logo, primaryColor },
    } = this.props

    if (!logo) {
      return
    }

    const img = new Image()
    img.crossOrigin = 'Anonymous'
    img.onload = () => {
      const colors = getColors(img)
      if (!colors.includes(primaryColor)) {
        colors.splice(0, 1)
        colors.unshift(primaryColor)
      }
      this.setState({
        colors,
      })
    }
    img.src = logo
  }

  render() {
    const { innerRef, theme, updateTheme, t } = this.props
    const { colors } = this.state
    const { logo } = theme
    return (
      <Modal ref={innerRef}>
        {({ isOpen, close }) =>
          isOpen ? (
            <View
              bg="white"
              mx="auto"
              maxWidth="460px"
              width="100%"
              p={[5, 10]}
              borderRadius={1}
              height="100%"
            >
              <Form
                initialValues={theme}
                onSubmit={values => updateTheme(values).then(() => close())}
              >
                {({ handleSubmit, submitting, values: { primaryColor } }) => (
                  <form onSubmit={handleSubmit}>
                    <Subtitle textAlign="center" mb={4}>
                      {t('Select a primary colour')}
                    </Subtitle>
                    <Text textAlign="center" mb={5}>
                      {t(
                        'The primary colour is used for buttons and other interactive elements in your customer experience.'
                      )}
                    </Text>
                    <Toggle>
                      {({ value, toggle }) => (
                        <>
                          <View
                            boxShadow="0 4px 3px -4px rgba(0, 0, 0, 0.2)"
                            bg="sky.2"
                            border={1}
                            borderRadius={1}
                            mb={5}
                            pb={4}
                          >
                            {!value ? (
                              <>
                                <View
                                  bg="white"
                                  p={3}
                                  mb={4}
                                  textAlign="center"
                                  height="108px"
                                  borderBottom={1}
                                  borderRadius="4px 4px 0 0"
                                >
                                  <img
                                    src={logo}
                                    className="mx-auto max-h-20 max-w-full"
                                  />
                                </View>
                                <Field
                                  name="primaryColor"
                                  component={Radio}
                                  items={colors.map(c => ({ value: c }))}
                                  justifyContent="flex-start"
                                  flexWrap="wrap"
                                  flexDirection="row"
                                  containerProps={{ mb: 0, ml: 5 }}
                                  renderItem={({
                                    item: { value },
                                    disabled,
                                    horizontal,
                                    selected,
                                    ...inputProps
                                  }) => (
                                    <Item bg={value} {...inputProps}>
                                      {selected ? (
                                        <Icons.Tick light fill="white" />
                                      ) : null}
                                    </Item>
                                  )}
                                />
                              </>
                            ) : (
                              <>
                                <Flex
                                  bg={primaryColor}
                                  width="100%"
                                  height="108px"
                                  justifyContent="center"
                                  mb={3}
                                  borderRadius="4px 4px 0 0"
                                >
                                  <Subtitle color="white">
                                    {primaryColor}
                                  </Subtitle>
                                </Flex>
                                <Field
                                  name="primaryColor"
                                  component={CustomTextInput}
                                  parse={v => `#${v}`}
                                  format={v => v.substr(1)}
                                  containerProps={{ mb: 0, ml: 5 }}
                                />
                              </>
                            )}
                          </View>
                          <View textAlign="center" mb={6}>
                            {!value ? (
                              <Clickable onClick={toggle}>
                                {t('Or enter the color manually')}
                              </Clickable>
                            ) : (
                              <Clickable onClick={toggle}>
                                {t('Or pick a colour from your logo')}
                              </Clickable>
                            )}
                          </View>
                        </>
                      )}
                    </Toggle>

                    <View
                      mx="auto"
                      display="flex"
                      flexDirection="column"
                      maxWidth="350px"
                    >
                      <View mx="auto">
                        <Trans>
                          <Prompt mb={3}>
                            Before confirming make sure your primary colour:
                          </Prompt>
                          <Flex justifyContent="flex-start" mb={4}>
                            <Dot />
                            <Prompt>
                              Is strong enough to attract attention and to be
                              used as a button
                            </Prompt>
                          </Flex>
                          <Flex justifyContent="flex-start" mb={4}>
                            <Dot />
                            <Prompt>Fits with your brand</Prompt>
                          </Flex>
                        </Trans>
                      </View>
                      <Flex justifyContent="space-around">
                        <Button
                          onClick={close}
                          type="button"
                          variant="secondary"
                        >
                          {t('Cancel')}
                        </Button>
                        <Button loading={submitting}>
                          {t('Confirm colour')}
                        </Button>
                      </Flex>
                      <FormError />
                    </View>
                  </form>
                )}
              </Form>
            </View>
          ) : null
        }
      </Modal>
    )
  }
}

PrimaryColorUpdateModal.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  updateTheme: PropTypes.func.isRequired,
  theme: PropTypes.object,
  t: PropTypes.func,
}

export default function (props) {
  const { t } = useTranslation()
  return <PrimaryColorUpdateModal t={t} {...props} />
}
