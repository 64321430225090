import { Subtitle } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import Tally from 'components/Tally'
import { useTranslation } from 'react-i18next'
import { NormalizedTerminalPayment } from 'types/NormalizedTerminalPayment'

type ReceiptProps = { data?: NormalizedTerminalPayment } & React.ComponentProps<
  typeof Tally
>

function Receipt({ data, ...rest }: ReceiptProps) {
  const { t } = useTranslation()

  const charge = data?.charges?.at(-1)

  return (
    <Tally {...rest}>
      <Tally.Item>
        <Subtitle fontWeight="semibold">{t('Total')}</Subtitle>
        <Subtitle fontWeight="semibold">{format(data?.amount)}</Subtitle>
      </Tally.Item>
      {charge && (
        <Tally.Item>
          <Tally.Label>{t('Payment method')}</Tally.Label>
          <Tally.CardValue brand={charge.brand} lastFourDigits={charge.last4} />
        </Tally.Item>
      )}
    </Tally>
  )
}

export default Receipt
