import React from 'react'
import PropTypes from 'prop-types'

import { Heading, Caption, Flex, View, Button } from '@tabeo/scarf'
import { useTranslation } from 'react-i18next'

import Card from 'components/Transaction/ActionPanel/Card'
import { useMerchant } from 'resources/Merchant'
import icon from './assets/unsuccessful.svg'
import DeclinedReasonsModal from './DeclinedReasonsModal'

function Unsuccessful({ transaction, ...rest }) {
  const { data } = transaction
  const { consumerFirstName, consumerHeShe, updatedAt, declinedReasons } = data
  const { data: merchant } = useMerchant()

  const {
    flags: { is_declined_reasons_enabled: isDeclinedReasonsEnabled },
  } = merchant || {}

  const modal = React.useRef()
  const { t } = useTranslation()

  const heSheText =
    consumerHeShe === 'he'
      ? t(
          'Unfortunately, {{consumerFirstName}} did not qualify for Finance. He can now refer the application to a family member or friend, or he can pay the whole amount upfront.',
          {
            consumerFirstName,
          }
        )
      : t(
          'Unfortunately, {{consumerFirstName}} did not qualify for Finance. She can now refer the application to a family member or friend, or she can pay the whole amount upfront.',
          {
            consumerFirstName,
          }
        )

  return (
    <>
      <DeclinedReasonsModal
        innerRef={modal}
        merchant={merchant}
        reasons={declinedReasons}
        key={updatedAt}
      />
      <Card {...rest}>
        <Flex justifyContent="flex-start" mb={4}>
          <View as="img" src={icon} mr={4} />
          <Heading fontWeight="semibold">
            {t('Application unsuccessful')}
          </Heading>
        </Flex>
        <Caption color="ink.1">{heSheText}</Caption>
        {isDeclinedReasonsEnabled && !!declinedReasons.length && (
          <Flex
            flexDirection={['column', 'row']}
            justifyContent="flex-start"
            alignItems={['stretch', 'center']}
            mt={4}
          >
            <Button
              variant="secondary"
              sizeVariant="sm"
              onClick={() => modal.current.open()}
              mr={[0, 4]}
              mb={[4, 0]}
            >
              {t('View reason')}
            </Button>
          </Flex>
        )}
      </Card>
    </>
  )
}

Unsuccessful.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default Unsuccessful
