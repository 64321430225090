import { Caption, Heading, Subheading, Title, View, Flex } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import PropTypes from 'prop-types'

function Option({ apr, cost, fee, ...rest }) {
  return (
    <View border={1} borderRadius={1} overflow="hidden" {...rest}>
      <View bg="blue.6" py="10px">
        <Heading color="blue.1" className="uppercase">
          {(apr * 100).toFixed(1)}% APR
        </Heading>
      </View>
      <Flex alignItems="baseline" justifyContent="center" pt="10px">
        <Title color="blue.1">{format(cost, undefined, 0)}</Title>
        <Subheading color="blue.1" ml={1}>
          / month
        </Subheading>
      </Flex>
      <View pt={1} pb="10px">
        <Caption>{format(fee, undefined, 0)} fee</Caption>
      </View>
    </View>
  )
}

Option.propTypes = {
  cost: PropTypes.number.isRequired,
  fee: PropTypes.number.isRequired,
  apr: PropTypes.number.isRequired,
}

export default Option
