import { themeGet } from '@styled-system/theme-get'
import styled from 'styled-components'
import View from '../../View'

const Container = styled(View)`
  :focus {
    outline: none;
  }

  @media (max-width: ${themeGet('breakpoints.0')}) {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
`

Container.defaultProps = {
  display: ['flex', 'inline-flex'],
  flexDirection: 'column',
  bg: 'white',
  boxShadow: 1,
  borderRadius: 1,
  border: [0, 1],
  height: ['100vh', 'unset'],
}

export default Container
