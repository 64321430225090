import { forwardRef, Ref, SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const SvgComponent = (
  { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.75 5.99986C6.94563 5.99986 9.8495 4.75073 12 2.71411C14.1505 4.75073 17.0544 5.99986 20.25 5.99986C20.3008 5.99986 20.3515 5.99955 20.4021 5.99892C20.7901 7.17903 21 8.43995 21 9.74991C21 15.3414 17.1757 20.0397 12 21.3719C6.82432 20.0397 3 15.3414 3 9.74991C3 8.43995 3.2099 7.17903 3.59789 5.99892C3.64852 5.99955 3.69922 5.99986 3.75 5.99986Z"
    />
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9V15M15 12H9" />
  </svg>
)

const ForwardRef = forwardRef(SvgComponent)
export default ForwardRef
