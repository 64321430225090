import React from 'react'
import Svg, { Path, SvgProps } from './Base'

function Edit({ fill, ...props }: SvgProps) {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <Path
        d="M12.13 4.778l2.857 3.095-8.21 8.547L2.5 17.5l1.085-4.343 8.546-8.379zm3.414-1.994l1.697 1.76a.921.921 0 01-.026 1.307L15.93 7.079l-2.983-3.093 1.285-1.229a.93.93 0 011.312.027z"
        fill={fill}
        stroke="none"
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default Edit
