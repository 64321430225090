import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Transaction from './Transaction'
import Invoice from './Invoice'
import Print from './Print'

function TransactionRouter() {
  return (
    <Switch>
      <Route path="/transactions/:transactionId/invoice" component={Invoice} />
      <Route path="/transactions/:transactionId/print" component={Print} />
      <Route path="/transactions/:transactionId" component={Transaction} />
    </Switch>
  )
}

export default TransactionRouter
