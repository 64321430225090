import {
  Button,
  Icons,
  Modal,
  NativeSelect,
  Prompt,
  Subtitle,
  Textarea,
  TextInput,
  Title,
  View,
} from '@tabeo/scarf'
import api from '@tabeo/sharpei/utils/api'
import formError from '@tabeo/sharpei/utils/formError'
import {
  email,
  emailPCA,
  maxLength,
  required,
} from '@tabeo/sharpei/utils/validations'
import plane from 'assets/airplane.svg'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'resources/Auth'
import styled from 'styled-components'

const maxLength200 = maxLength(200)

const Icon = styled(View)``

Icon.defaultProps = {
  src: plane,
  as: 'img',
  mx: 'auto',
  mt: 3,
  mb: '30px',
}

const submitMessage = values => api.post('/messages/user-support', values)

const Complaint = React.forwardRef(({ isOpenByDefault, ...rest }, ref) => {
  const {
    data: { user },
  } = useAuth()
  const { t } = useTranslation()
  return (
    <Modal ref={ref} isOpenByDefault={isOpenByDefault}>
      {({ isOpen, close }) => {
        async function handleSubmit({ name, ...values }) {
          const subject = t(`Complaint by {{ name }} - {{title}}`, {
            name,
            title:
              values.subject === t('Other')
                ? values.otherSubject
                : values.subject,
          })
          try {
            const payload = {
              ...values,
              subject,
              type: 'complaint',
            }
            await submitMessage(payload)
          } catch (e) {
            return formError(e)
          }
        }

        return isOpen ? (
          <View
            position="relative"
            maxWidth={['90%', '460px']}
            mx="auto"
            bg="white"
            borderRadius={1}
            boxShadow={1}
            p={[5, 10]}
            {...rest}
          >
            <View
              onClick={close}
              position="absolute"
              top="0px"
              right="0px"
              cursor="pointer"
              p={4}
            >
              <Icons.X fill="ink.2" />
            </View>

            <Form
              onSubmit={handleSubmit}
              initialValues={
                user
                  ? {
                      email: user.email,
                      name: `${user.firstName} ${user.lastName}`,
                    }
                  : undefined
              }
            >
              {({
                handleSubmit,
                submitting,
                submitSucceeded,
                values: { subject },
              }) =>
                !submitSucceeded ? (
                  <form onSubmit={handleSubmit}>
                    <Title mb={4}>{t('Make a complaint')}</Title>

                    <Field
                      component={NativeSelect}
                      name="subject"
                      label={t('Subject')}
                      options={[
                        { value: 'Refund', label: t('Refund') },
                        { value: 'Tabeo', label: t('Tabeo') },
                        {
                          value: 'Another business',
                          label: t('Another business'),
                        },
                        { value: 'Other', label: t('Other') },
                      ]}
                      validate={[required]}
                      maxWidth="100%"
                    />

                    {subject === t('Other') && (
                      <Field
                        name="otherSubject"
                        component={TextInput}
                        label={t('Please specify')}
                        placeholder={t('Subject')}
                        validate={[required]}
                        maxWidth="100%"
                      />
                    )}

                    <Field
                      name="name"
                      component={TextInput}
                      label={t('Name')}
                      placeholder={t('e.g. John Smith')}
                      validate={[required]}
                      maxWidth="100%"
                    />

                    <Field
                      name="email"
                      component={TextInput}
                      label={t('Email')}
                      type="email"
                      placeholder={t('e.g. test@example.com')}
                      validate={[required, email, emailPCA]}
                      maxWidth="100%"
                    />

                    <Field
                      name="body"
                      component={Textarea}
                      label={t('Tell us more')}
                      placeholder={t('Add comments here…')}
                      validate={[required, maxLength200]}
                      width="100%"
                    />

                    <Button loading={submitting} width="100%" mb={5}>
                      {t('Submit')}
                    </Button>

                    <Button
                      variant="secondary"
                      type="button"
                      width="100%"
                      onClick={close}
                    >
                      {t('Close')}
                    </Button>
                    <FormError />
                  </form>
                ) : (
                  <View textAlign="center">
                    <Icon />
                    <Subtitle color="primary.3" mb={4}>
                      {t('Message sent successfully')}
                    </Subtitle>
                    <Prompt color="ink.1" mb={6}>
                      {t("We'll get back to you sortly.")}
                    </Prompt>
                    <Button type="button" width="100%" onClick={close}>
                      {t('Close')}
                    </Button>
                  </View>
                )
              }
            </Form>
          </View>
        ) : null
      }}
    </Modal>
  )
})

Complaint.propTypes = {
  isOpenByDefault: PropTypes.bool,
}

export default Complaint
