import React from 'react'
import styled from 'styled-components'

import Svg, { SvgProps } from './Base'

const ArrowRight = (props: SvgProps) => (
  <Svg {...props}>
    <g>
      <path
        d="M9.38317757,14.7038462 L4.29906542,9.47307692 C3.94766355,9.11153846 3.94766355,8.52692308 4.29906542,8.16923077 L5.14392523,7.3 C5.4953271,6.93846154 6.0635514,6.93846154 6.41121495,7.3 L10.0149533,11.0076923 L13.6186916,7.3 C13.9700935,6.93846154 14.5383178,6.93846154 14.8859813,7.3 L15.7308411,8.16923077 C16.082243,8.53076923 16.082243,9.11538462 15.7308411,9.47307692 L10.646729,14.7038462 C10.3028037,15.0653846 9.73457944,15.0653846 9.38317757,14.7038462 Z"
        id="path-1"
        transform="translate(10.014953, 11.001923) rotate(-90.000000) translate(-10.014953, -11.001923)"
      />
    </g>
  </Svg>
)

export default styled(ArrowRight)``
