'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../tslib.es6-1ef680b2.js');
require('i18next');
var index = require('../i18n/index.js');

function handleFileDownload(url, options) {
    if (options === void 0) { options = {}; }
    return tslib_es6.__awaiter(this, void 0, void 0, function () {
        // Helper to get blob from fetch promise
        function getObjectURL() {
            return tslib_es6.__awaiter(this, void 0, void 0, function () {
                var res, blobby, objectUrl;
                return tslib_es6.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, downloadPromise];
                        case 1:
                            res = _a.sent();
                            return [4 /*yield*/, res.blob()];
                        case 2:
                            blobby = _a.sent();
                            objectUrl = window.URL.createObjectURL(blobby);
                            return [2 /*return*/, objectUrl];
                    }
                });
            });
        }
        var _a, token, open, save, filename, headers, downloadPromise, objectUrl, a, newTab, objectUrl;
        return tslib_es6.__generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = tslib_es6.__assign({ token: null, open: true, save: false, filename: "download-".concat(Date.now()) }, options), token = _a.token, open = _a.open, save = _a.save, filename = _a.filename;
                    headers = new Headers();
                    if (token) {
                        headers.append('Authorization', "Bearer ".concat(token));
                    }
                    downloadPromise = fetch(url, { headers: headers });
                    // Download the file and return blob object url so custom behaviour can be added
                    if (!open && !save) {
                        return [2 /*return*/, getObjectURL()];
                    }
                    if (!save) return [3 /*break*/, 2];
                    return [4 /*yield*/, getObjectURL()];
                case 1:
                    objectUrl = _b.sent();
                    a = document.createElement('a');
                    a.href = objectUrl;
                    a.setAttribute('target', '_blank');
                    a.setAttribute('download', filename);
                    a.click();
                    URL.revokeObjectURL(objectUrl);
                    return [2 /*return*/, objectUrl];
                case 2:
                    if (!open) return [3 /*break*/, 4];
                    newTab = window.open('', '_blank');
                    if (!newTab) return [3 /*break*/, 4];
                    newTab.document.write(index.t('Downloading...'));
                    return [4 /*yield*/, getObjectURL()];
                case 3:
                    objectUrl = _b.sent();
                    newTab.location.href = objectUrl;
                    URL.revokeObjectURL(objectUrl);
                    return [2 /*return*/, objectUrl];
                case 4: return [2 /*return*/];
            }
        });
    });
}

exports.default = handleFileDownload;
