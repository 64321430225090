// @ts-nocheck

import { themeGet } from '@styled-system/theme-get'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelect, {
  Async,
  Options,
  ReactAsyncSelectProps,
} from 'react-select'
import styled, { css } from 'styled-components'
import { InputProp, MetaProp } from '../../types/form'
import { Merge } from '../../types/helpers'
import ScarfProps from '../../types/ScarfProps'
import variants from '../../utils/variants'
import DoubleArrow from '../Icons/DoubleArrow'
import View from '../View'

const Select = styled(View)`
  ${variants({
    variant: {
      outline: {
        transition: 'border-color 200ms linear',
        '&:after': {
          display: 'none',
        },
      },
      'outline-v2': {
        transition: 'border-color 200ms linear',
        '&:after': {
          display: 'none',
        },
      },
    },
  })}
  font-family: ${themeGet('font', 'Arial')};
  letter-spacing: 0.4px;
  line-height: 1.5 !important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .Select-control:hover {
    box-shadow: none;
    background-color: ${themeGet('colors.sky.2')};
  }

  .Select-control {
    border: 1px solid ${themeGet('colors.sky.0')};
    background-color: ${themeGet('colors.sky.2')};
    ${(p) =>
      p.variant === 'outline-v2' &&
      css`
        background-color: ${themeGet('colors.sky.3')};
      `}
    ${(p) =>
      p.invalid &&
      css`
        border: 1px solid ${themeGet('colors.default.red')} !important;
      `}
    border-radius: 4px;
    cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
    height: 38px;
    ${(p) =>
      p.variant === 'outline-v2' &&
      css`
        height: 42px;
      `}
    box-shadow: none;
  }

  &.is-open,
  &.is-focused {
    .Select-control {
      background-color: ${themeGet('colors.sky.2')} !important;
      border: 1px solid ${themeGet('colors.sky.0')} !important;
      .Select-input {
        background-color: ${themeGet('colors.sky.2')} !important;
        border-radius: 4px;
      }
    }
  }

  &.is-focused {
    .Select-control {
      box-shadow: none !important;
    }
  }

  &.is-open.is-focused {
    .Select-control {
      box-shadow: -1px 1px 2px 0px rgba(0, 0, 0, 0.2) !important;
      overflow: auto;
    }
  }

  &.is-disabled {
    .Select-control {
      background-color: ${themeGet('colors.sky.3')} !important;
    }
    .Select-arrow-zone {
      opacity: 1;
    }
  }

  .Select-placeholder,
  .Select-value {
    padding: 7px 11px;
    line-height: 1.5 !important;
  }

  .Select-placeholder {
    font-weight: 400;
  }

  .Select-menu-outer {
    box-shadow: -1px 1px 2px 0px rgba(0, 0, 0, 0.2);
    max-height: ${({ selectMenuStyle }) =>
      selectMenuStyle?.maxHeight
        ? `calc(${selectMenuStyle.maxHeight} + 9px)`
        : '229px'};
    .Select-menu {
      max-height: ${({ selectMenuStyle }) =>
        selectMenuStyle?.maxHeight ? selectMenuStyle.maxHeight : '220px'};
    }
  }

  .Select-option {
    font-weight: 400;
    &.is-selected {
      background-color: ${themeGet('colors.primary.5')};
    }
    &.is-selected.is-focused,
    &.is-focused {
      background-color: ${themeGet('colors.primary.3')};
      color: white;
    }
  }

  .Select-placeholder {
    color: ${themeGet('colors.ink.2')};
  }

  .Select-loading {
    border: 2px solid transparent;
    border-right-color: ${themeGet('colors.ink.2')};
    border-top-color: ${themeGet('colors.ink.2')};
  }

  input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  ${(p) =>
    p.isAsync &&
    css`
      .Select-loading-zone {
        padding-right: 12px !important;
      }
      .Select-input > input {
        padding: 8px 0;
        line-height: 1.5 !important;
        font-weight: 400;
      }
      .Select-placeholder,
      .Select-value {
        padding: 6px 11px;
      }

      ${(p) =>
        p.variant === 'outline-v2' &&
        css`
          .Select-placeholder,
          .Select-value {
            padding: 8px 11px;
          }
        `}

      ${(p) =>
        p.variant === 'normal' &&
        css`
          &:not(.is-open) {
            .Select-control {
              border-color: transparent !important;
            }
          }
        `}
      &:not(.is-open):after {
        content: ' ';
        width: 100%;
        height: 3px;
        position: absolute;
        left: 0;
        bottom: 0;
        border-radius: 0 0 4px 4px;
        background: ${themeGet('colors.ink.2')};
        transition: background-color 200ms linear;
        ${(p) =>
          p.variant === 'normal' &&
          css`
            ${(p) =>
              p.meta.focus &&
              css`
                background-color: ${themeGet('colors.ink.2')};
              `}
            ${(p) =>
              p.meta.touched &&
              !p.meta.error &&
              css`
                background-color: ${themeGet('colors.default.primary')};
              `}
          ${(p) =>
              p.meta.error &&
              p.meta.touched &&
              css`
                background-color: ${themeGet('colors.default.red')};
              `}
            ${(p) =>
              p.disabled &&
              css`
                background-color: ${themeGet('colors.sky.0')};
              `}
          `}
      }

      ${(p) =>
        p.variant === 'outline' &&
        css`
          .Select-control {
            border-color: ${themeGet('colors.sky.1')};
            ${(p) =>
              p.meta.focus &&
              css`
                border-color: ${themeGet('colors.sky.0')};
              `}
            ${(p) =>
              p.meta.touched &&
              !p.meta.error &&
              css`
                border-color: ${themeGet('colors.default.primary')};
              `}
        ${(p) =>
              p.meta.error &&
              p.meta.touched &&
              css`
                border-color: ${themeGet('colors.default.red')};
              `}
        ${(p) =>
              p.disabled &&
              css`
                border-color: ${themeGet('colors.sky.0')};
              `}
          }
        `}

      ${(p) =>
        p.variant === 'outline-v2' &&
        css`
          .Select-control {
            ${(p) =>
              p.meta.focus &&
              css`
                border-color: ${themeGet('colors.blue.3')};
                box-shadow: 0 0 0 1px ${themeGet('colors.blue.3')},
                  0 0 0 1px ${themeGet('colors.blue.3')};
              `}
            ${(p) =>
              p.meta.touched &&
              !p.meta.error &&
              !p.meta.focus &&
              css`
                border-color: ${themeGet('colors.ink.1')};
              `}
          ${(p) =>
              p.meta.error &&
              p.meta.touched &&
              !p.meta.focus &&
              css`
                border-color: ${themeGet('colors.default.red')};
              `}
          ${(p) =>
              p.disabled &&
              css`
                border-color: ${themeGet('colors.sky.0')};
              `}
          }
        `}
    `}
`

Select.defaultProps = {
  as: ReactSelect,
  clearable: false,
  searchable: false,
  required: false,
  simpleValue: true,
  joinValues: true,
  maxWidth: ['100%', '350px'],
}

const AsyncSelect = ({ ...props }) => <Select as={Async} {...props} />

const arrow = (invalid, disabled) => {
  const color = disabled ? 'sky.0' : invalid ? 'default.red' : 'default.primary'
  return <DoubleArrow fill={color} />
}

export type WrapProps = Merge<
  ScarfProps,
  Merge<
    ReactAsyncSelectProps,
    {
      input?: Partial<InputProp>
      meta?: Partial<MetaProp>
      async?: boolean
      onError?: (error: any) => void
      onOptionsLoad?: () => void
      onOptionsLoaded?: (options: Options) => void
      loadOptions?: ReactAsyncSelectProps['loadOptions']
      variant?: 'normal' | 'outline' | 'outline-v2'
      selectMenuStyle?: {
        maxHeight?: string
      }
    }
  >
>

class Wrap extends Component<WrapProps> {
  state = {
    query: '',
    options: [],
  }

  mounted = false

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleOptionsLoad = async (nextQuery) => {
    const {
      loadOptions,
      onError = () => {},
      autoload,
      onOptionsLoaded,
      onOptionsLoad,
    } = this.props
    const { query, options } = this.state
    const shouldLoad =
      ((nextQuery || autoload) && nextQuery !== query) ||
      (autoload && nextQuery === query && !options.length)

    this.mounted &&
      this.setState({
        query: nextQuery,
      })
    if (shouldLoad) {
      try {
        onOptionsLoad?.()
        const { options: newOptions } = await loadOptions(nextQuery)
        this.mounted &&
          this.setState({
            options: newOptions,
          })
        onOptionsLoaded?.(newOptions)
        return { options: newOptions }
      } catch (e) {
        this.mounted &&
          this.setState({
            options: [],
          })
        onError(e)
      }
    } else {
      return { options }
    }
  }

  render() {
    const {
      input,
      meta,
      disabled,
      loadOptions,
      async,
      flex,
      placeholder,
      autoload,
      variant = 'outline-v2',
      ...rest
    } = this.props
    const invalid = meta.error && meta.touched

    const isAsync = loadOptions || async

    const asyncDefaultProps = isAsync
      ? {
          onSelectResetsInput: false,
          onBlurResetsInput: false,
          searchable: true,
          autoload: false,
          cache: false,
          filterOptions: (options) => options,
        }
      : {}

    const SelectVersion = loadOptions ? AsyncSelect : Select

    return (
      <View
        flex={flex}
        data-no-track={rest['data-no-track'] ? true : undefined}
      >
        <SelectVersion
          meta={meta}
          invalid={invalid}
          arrowRenderer={() => (isAsync ? null : arrow(invalid, disabled))}
          disabled={disabled}
          loadOptions={loadOptions ? this.handleOptionsLoad : undefined}
          isAsync={isAsync}
          placeholder={placeholder}
          variant={variant}
          {...input}
          {...asyncDefaultProps}
          autoload={autoload}
          {...rest}
        />
      </View>
    )
  }
}

Wrap.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  async: PropTypes.bool,
  loadOptions: PropTypes.func,
  flex: PropTypes.string,
  placeholder: PropTypes.string,
  onError: PropTypes.func,
  onOptionsLoad: PropTypes.func,
  onOptionsLoaded: PropTypes.func,
  autoload: PropTypes.bool,
}

export type I18nWrapProps = WrapProps

const I18nWrap = ({ placeholder, ...rest }: I18nWrapProps) => {
  const { t } = useTranslation()

  return (
    <Wrap placeholder={placeholder || t('Select from the list...')} {...rest} />
  )
}

I18nWrap.propTypes = {
  placeholder: PropTypes.string,
}

export default I18nWrap
