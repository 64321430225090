import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'

class MerchantUsers extends Resource {
  getInitialConfig() {
    return {
      refreshInterval: 15 * 1000,
    }
  }

  async fetch() {
    const [{ merchantUsers: users }, { merchantUserInvitations: invites }] =
      await Promise.all([
        api.get(`/merchant/merchant-users?limit=500&subResource=user`),
        api.get(`/merchant/merchant-user-invitations?limit=500`),
      ])

    const items = [
      ...users.map(u => ({
        type: 'active',
        ...u,
        email: u.user.email,
        status: u.user.status,
        lastLogin: u.user.lastLogin,
      })),
      ...invites
        .filter(i => i.status !== 'accepted')
        .map(i => ({
          type: 'invited',
          ...i,
        })),
    ]

    this.data = items
  }

  static register = ({ token, ...payload }) =>
    api.post(`/merchant-user-invitations/${token}/merchant-user`, payload)

  invite = async payload => {
    await api.post('/merchant/merchant-user-invitations', payload)
    await this.fetch()
  }

  remove = async id => {
    const user = this.data.find(u => u.id === id)
    const endpoint =
      user.type === 'invited'
        ? `/merchant/merchant-user-invitations/${id}`
        : `/merchant/merchant-users/${id}`
    await api.delete(endpoint, { data: {} })
    await this.fetch()
  }
}

export default MerchantUsers
export const useMerchantUsers = createResourceHook(MerchantUsers)
