import React from 'react'
import styled from 'styled-components'

import Svg, { SvgProps } from './Base'

const Lock = (props: SvgProps) => (
  <Svg {...props}>
    <g>
      <path d="M16.5 10.5h-.75V7.75A4.756 4.756 0 0 0 11 3a4.756 4.756 0 0 0-4.75 4.75v2.75H5.5A1.5 1.5 0 0 0 4 12v5.5A1.5 1.5 0 0 0 5.5 19h11a1.5 1.5 0 0 0 1.5-1.5V12a1.5 1.5 0 0 0-1.5-1.5zm-3.25 0h-4.5V7.75C8.75 6.51 9.76 5.5 11 5.5s2.25 1.01 2.25 2.25v2.75z" />
    </g>
  </Svg>
)

export default styled(Lock)``
