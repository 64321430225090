import React from 'react'
import styled from 'styled-components'
import ScarfProps from '../types/ScarfProps'
import { Merge, PolymorphicComponent } from '../types/helpers'
import variants from '../utils/variants'
import Flex from './Flex'
import * as Icons from './Icons'
import Text, { BaseProps } from './Text'
import Tooltip2, { Tooltip2Props } from './Tooltip2'
import View from './View'

const LoadingIcon = styled(Icons.Spinner)`
  width: 22px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

const primaryVariant = {
  bg: 'default.primary',
  color: 'white',
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.1)',
  borderColor: 'default.primary',
  '&:hover:not(:disabled)': {
    filter: 'brightness(102%)',
  },
  '&:active:not(:disabled)': {
    filter: 'brightness(95%)',
  },
  '&:focus:not(:disabled)': {
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
  },
}

type ButtonProps = {
  sizeVariant?: 'xs' | 'sm' | 'md'
  variant?: 'primary' | 'secondary' | 'warning' | 'transparent'
  ref?: any
}

const Button = styled(Text)<ButtonProps>`
  ${variants(
    {
      variant: {
        primary: primaryVariant,
        secondary: {
          color: 'default.primary',
          backgroundImage: 'linear-gradient(to bottom, #f9fafb, #f4f6f8)',
          boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.1)',
          borderColor: '#c4cdd5',
          '&:hover:not(:disabled)': {
            backgroundImage: 'linear-gradient(to bottom, #ffffff, #f9fafb)',
          },
          '&:active:not(:disabled)': {
            backgroundImage: 'linear-gradient(to top, #f9fafb, #f4f6f8)',
          },
          '&:focus:not(:disabled)': {
            boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
          },
        },
        warning: {
          ...primaryVariant,
          bg: 'default.red',
          borderColor: 'default.red',
        },
        transparent: {
          color: 'default.primary',
          bg: 'transparent',
          borderColor: 'transparent',
          '&:not(:disabled)': {
            textDecoration: 'underline',
          },
        },
      },
      sizeVariant: {
        xs: {
          fontSize: ['18px', '14px'],
          pl: [4],
          pr: [4],
          height: ['48px', '32px'],
        },
        sm: {
          // minWidth: 'auto',
          fontSize: '14px',
          pl: [4],
          pr: [4],
          height: '36px',
        },
        md: {
          minWidth: '150px',
          pl: [6],
          pr: [6],
          width: ['100%', 'unset'],
          fontSize: ['18px', '16px'],
          height: ['48px', '40px'],
        },
      },
    },
    Text.defaultProps
  )}
  outline: none;
  white-space: nowrap;
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`

Button.defaultProps = {
  // Default variants
  sizeVariant: 'md',
  variant: 'primary',
  // Shared default properties
  as: 'button',
  cursor: 'pointer',
  display: 'inline-block',
  borderRadius: 1,
  borderWidth: '1px',
  borderStyle: 'solid',
  textAlign: 'center',
}

function getSpacingPropsForButton({
  left,
  right,
  leftIcon,
  rightIcon,
  px,
  pl,
  pr,
}: WrapProps) {
  const spacingProps: Record<string, unknown> = {}
  if (left || leftIcon) {
    spacingProps.pl = 0
  }
  if (right || rightIcon) {
    spacingProps.pr = 0
  }
  if (px) {
    spacingProps.pl = pl || px
    spacingProps.pr = pr || px
  }
  return spacingProps
}

function getIconProps(place: 'left' | 'right', { sizeVariant }: WrapProps) {
  const iconProps: Record<string, unknown> = {
    fill: 'currentColor',
  }
  const isRight = place === 'right'
  const marginLarge = isRight ? 'mr' : 'ml'
  const marginSmall = isRight ? 'ml' : 'mr'

  switch (sizeVariant) {
    case 'sm':
      iconProps[marginLarge] = '6px'
      iconProps[marginSmall] = 1
      iconProps.width = '20px'
      iconProps.height = '20px'
      break
    case 'xs':
      iconProps[marginLarge] = 2
      iconProps[marginSmall] = 1
      iconProps.width = ['28px', '18px']
      iconProps.height = ['28px', '18px']
      break
    default:
      iconProps[marginLarge] = 4
      iconProps[marginSmall] = 2
      iconProps.width = '22px'
      iconProps.height = '22px'
      break
  }
  return iconProps
}

export type WrapProps = Merge<
  BaseProps,
  ButtonProps & {
    loading?: boolean
    disabled?: boolean
    left?: React.ReactNode | any
    right?: React.ReactNode | any
    leftIcon?: (props: any) => React.ReactNode
    rightIcon?: (props: any) => React.ReactNode
    contentWrapperProps?: ScarfProps
    childrenWrapperProps?: ScarfProps
    hint?: string
    tooltipProps?: Partial<Tooltip2Props>
  }
>

const Wrap: PolymorphicComponent<WrapProps, 'button'> = React.forwardRef<
  any,
  WrapProps
>((props, ref) => {
  const {
    /* eslint-disable react/prop-types */
    children,
    loading,
    disabled,
    left = null,
    right = null,
    leftIcon,
    rightIcon,
    textAlign,
    contentWrapperProps,
    childrenWrapperProps,
    hint,
    tooltipProps,
    /* eslint-enable react/prop-types */
    ...rest
  } = props
  const buttonSpacingProps = getSpacingPropsForButton(props)

  const Component = (
    <Button
      ref={ref}
      disabled={disabled || loading}
      position="relative"
      {...buttonSpacingProps}
      {...rest}
    >
      {loading && <LoadingIcon fill="currentColor" spinning size="22px" />}
      <Flex justifyContent="center" height="100%">
        <Flex
          visibility={loading ? 'hidden' : 'visible'}
          flex="1"
          lineHeight="normal"
          {...contentWrapperProps}
        >
          {left}
          {leftIcon && leftIcon(getIconProps('left', props))}
          <View flex="1" textAlign={textAlign} {...childrenWrapperProps}>
            {children}
          </View>
          {rightIcon && rightIcon(getIconProps('right', props))}
          {right}
        </Flex>
      </Flex>
    </Button>
  )

  if (hint || tooltipProps) {
    const props = tooltipProps || {
      title: hint,
    }
    return (
      <Tooltip2 placement="auto" {...props}>
        {Component}
      </Tooltip2>
    )
  }

  return Component
})

export default Wrap
