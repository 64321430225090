import React from 'react'

import Svg, { Path, SvgProps } from './Base'

function Star({ fill, active, ...props }: SvgProps & { active?: boolean }) {
  return (
    <Svg {...props}>
      {!active ? (
        <Path
          d="M10.785 5.418L8.714 9.145 4.56 9.85l3.129 3.362-.613 4.168 4.164-1.937 3.774 1.871-.555-4.558 2.946-3.012-4.485-.877-2.135-3.449z"
          stroke={fill}
          fill="none"
          fillRule="evenodd"
        />
      ) : (
        <Path
          d="M10.772 16l-3.564 1.874a.5.5 0 01-.725-.528l.68-3.968a.5.5 0 00-.143-.443l-2.884-2.81a.5.5 0 01.277-.853l3.985-.58a.5.5 0 00.377-.273l1.782-3.61a.5.5 0 01.896 0l1.782 3.61a.5.5 0 00.377.274l3.985.579a.5.5 0 01.277.853l-2.884 2.81a.5.5 0 00-.143.443l.68 3.968a.5.5 0 01-.725.528L11.238 16a.5.5 0 00-.466 0z"
          stroke="none"
          fill={fill}
          fillRule="evenodd"
        />
      )}
    </Svg>
  )
}

export default Star
