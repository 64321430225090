import { Modal } from '@tabeo/scarf'
import Layout from 'pages/NewTransaction/Layout'
import React from 'react'
import { usePlansV2 } from 'resources/PlansV2'
import Form, { FormValues } from './Form'
import PlanOverview from './PlanOverview'
import SuccessModal from './SuccessModal'

export default function NewSubscriptionV2() {
  const { data: plans } = usePlansV2()
  const modal = React.useRef<Modal>(null)
  const [formValues, setFormValues] = React.useState<Partial<FormValues>>()

  const planId = formValues?.planId

  return (
    <Layout>
      <div>
        <SuccessModal ref={modal} />
        <Form
          plans={plans?.items ?? []}
          onFormChange={setFormValues}
          modalRef={modal}
        />
      </div>
      <div>
        <PlanOverview planId={planId} />
      </div>
    </Layout>
  )
}
