import Overview, { planToData } from 'pages/NewPlanV2/Overview'
import { usePlanV2 } from 'resources/PlanV2'

type PlanOverviewProps = {
  planId?: string
}

export default function PlanOverview({ planId }: PlanOverviewProps) {
  const { data: plan } = usePlanV2(() => {
    return { id: planId }
  })

  return <Overview data={planToData(plan)} />
}
