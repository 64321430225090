import config from 'config'

export default function getBaseURL() {
  const { ENV: env } = config
  switch (env) {
    case 'production':
      return 'https://lead.tabeo.co.uk'
    case 'demo':
      return 'https://demo.lead.tabeo.co.uk'
    case 'staging':
      return 'https://staging.lead.tabeo.co.uk'
    case 'development':
      return 'https://development.lead.tabeo.co.uk'
    default:
      return 'https://development.lead.tabeo.co.uk'
  }
}

export const BASE_URL = getBaseURL()
