import { createContext, useContext } from 'react'

export type State = {
  activeTabIndexes?: number[]
  activeTabs?: any[]
  activeFilters?: {
    [key: string]: {
      value: any
      mode: string | undefined
      [key: string]: any
    }
  }
  searchQuery?: string
  offset?: number
  orderBy?: { field?: string; direction?: 'asc' | 'desc' }
  selected?: any[]
  metadata?: any
  [key: string]: any
}

export type Action = { type: string; payload?: any }

export type Dispatch = (action?: Action) => void

export type DataTableContextType = {
  items: any[] | null
  state: State
  resource: any
  dispatch: Dispatch
}

const dataTableCtx = createContext<DataTableContextType>({
  items: [],
  state: {},
  resource: {},
  dispatch: () => {},
})

export default dataTableCtx

export const useDataTable = () => useContext(dataTableCtx)
