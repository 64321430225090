import Resource, { createResourceHook } from '@tabeo/resync'
import fetchDato from 'utils/dato'
import gql from 'utils/gql'

const MerchantAppQuery = gql`
  query MerchantAppQuery {
    app: merchantApp {
      notificationsV2 {
        id
        variant
        content
        buttonText
        buttonLink
        dismissible
        customIcon
        conditions
      }
    }
  }
`

class DatoApp extends Resource {
  getInitialConfig() {
    return {
      refreshInterval: 0,
    }
  }

  async fetch() {
    const diff = 5 * 60 * 1000 // 5 min

    if ((this.fetchedAt || 0) + diff < +new Date()) {
      const data = await fetchDato(MerchantAppQuery)
      this.data = data.app
      this.fetchedAt = +new Date()
    }

    return this.data
  }
}

export default DatoApp
export const useDatoApp = createResourceHook(DatoApp)
