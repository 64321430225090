import React from 'react'
import Svg, { Path, SvgProps } from './Base'

function Clip({ fill, ...props }: SvgProps) {
  return (
    <Svg width={22} height={23} viewBox="0 0 22 23" {...props}>
      <Path
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16 11.188l-4.728 4.459c-1.206 1.137-3.161 1.137-4.367 0a2.797 2.797 0 010-4.12l4.727-4.458a2.147 2.147 0 012.912 0 1.865 1.865 0 010 2.746l-4.733 4.459a1.074 1.074 0 01-1.456 0 .932.932 0 010-1.373l4.368-4.115"
      />
    </Svg>
  )
}

export default Clip
