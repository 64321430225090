import { Cog8ToothIcon } from '@heroicons/react/20/solid'
import { HeartIcon } from '@heroicons/react/24/outline'
import { ArrowRightIcon, PlusIcon } from '@heroicons/react/24/solid'
import { useMobile } from '@tabeo/scarf'
import {
  FFRadioGroup,
  FFTextInput,
  RadioCards,
  RadioGroup,
  Stepper as StepperItem,
  Tile,
} from '@tabeo/scarf2'
import { lengthRange, required } from '@tabeo/sharpei/utils/validations'
import AutoSave from 'components/nnts/form/AutoSave'
import { Field } from 'components/nnts/form/Field'
import { scrollToErrorDecorator } from 'components/nnts/form/scrollToError'
import { getCategoriesBySector } from 'pages/Settings/Treatments/constants'
import { ComponentProps, useCallback } from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useMerchant } from 'resources/Merchant'
import trigramSimilarity from 'trigram-similarity'
import { z } from 'zod'
import { ActionButton } from '../components/action-button'
import Header from '../components/header'
import { useNewTransactionFlow } from '../components/provider'
import CategorySection from '../components/treatment/CategorySection'
import MagnifyingGlass from '../components/treatment/MagnifyingGlass'
import useMerchantTreatments, {
  compare,
  composeMerchantTreatmentId,
  isOther,
} from '../components/treatment/useMerchantTreatments'
import { NNTSState } from '../types'
import { getMerchantTreatmentById } from '../utils'

export const treatmentCTSchema = z.object({
  merchantTreatmentId: z.string().min(1),
  otherTreatmentName: z.string().optional(),
  otherTreatmentCategory: z.string().optional(),
})

export function TreatmentMain() {
  const {
    next,
    setStateForStep,
    state,
    modals: { cartSummaryModalRef },
    form,
    currentIndex,
    flowSteps,
  } = useNewTransactionFlow()

  const { isLast } = flowSteps[currentIndex]

  const isMobile = useMobile()
  const { t } = useTranslation()

  const selectionRequired = useCallback(
    (value: any) => (!value ? t('Please select a treatment') : undefined),
    [t]
  )

  const { data: merchant } = useMerchant()

  const merchantTreatmentsByCategories = useMerchantTreatments()

  const treatmentCategories = [
    ...getCategoriesBySector(merchant?.sector).sort((a, b) =>
      a.localeCompare(b)
    ),
    t('Other'),
  ]

  function handleSubmit(values: any) {
    setStateForStep('treatment', values)
    return next()
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={state.treatment}
      decorators={[scrollToErrorDecorator]}
      destroyOnUnregister
    >
      {({ handleSubmit, values, form: f }) => {
        form.current = f

        const isSearchActive = values?.query && values?.query?.length > 2
        const filteredMerchantTreatmentsByCategories =
          merchantTreatmentsByCategories.map(category => {
            const { merchantTreatments, name } = category

            if (name === t('Other')) {
              return category
            }

            const filteredCategoryItems = isSearchActive
              ? merchantTreatments
                  .map(mt => {
                    const searchScore = trigramSimilarity(
                      String(mt.treatment?.name),
                      values?.query
                    )
                    return {
                      ...mt,
                      searchScore,
                    }
                  })
                  .filter(mt => mt.searchScore > 0.15)
              : merchantTreatments
            return {
              ...category,
              merchantTreatments: filteredCategoryItems,
            }
          })

        const hasCategoriesWithTreatments =
          filteredMerchantTreatmentsByCategories.some(
            category =>
              category.merchantTreatments.length > 0 &&
              category.name !== t('Other')
          )

        return (
          <form onSubmit={handleSubmit} className="flex h-full flex-col">
            <Header
              cartSummaryModalRef={cartSummaryModalRef}
              currentIndex={currentIndex}
            />
            <h2 className="title text-tabeo-ink-0">{t('Treatment')}</h2>
            <p className="caption mb-6 text-tabeo-ink-1">
              {t('Select a treatment from the options below.')}
            </p>
            <div className="mb-6 border-b pb-6">
              <Field
                component={FFTextInput}
                name="query"
                label={t('Search treatments')}
                placeholder="Start typing to search..."
                clearable
                wrapClassName="sm:max-w-[386px]"
                autoFocus={!isMobile && !values?.merchantTreatmentId}
              />
            </div>
            <Field
              name="merchantTreatmentId"
              validate={[selectionRequired]}
              component={FFRadioGroup}
            >
              <div className="space-y-10">
                {!hasCategoriesWithTreatments && (
                  <div className="text-center">
                    <MagnifyingGlass className="mx-auto mb-3 text-tabeo-primary-3" />
                    <p>{t('No results found')}</p>
                    <p className="caption mt-1 text-tabeo-ink-3">
                      {t(
                        'Modify the search or select “Other” below to manually enter the treatment.'
                      )}
                    </p>
                  </div>
                )}
                {filteredMerchantTreatmentsByCategories.map(category => {
                  const { name, merchantTreatments } = category
                  return category.merchantTreatments.length > 0 ? (
                    <CategorySection
                      key={name}
                      className={
                        category.name === t('Other')
                          ? '-mt-4 border-t pt-6'
                          : ''
                      }
                    >
                      {filteredMerchantTreatmentsByCategories.length > 1 &&
                        category.name !== t('Other') && (
                          <CategorySection.Label>{name}</CategorySection.Label>
                        )}
                      <CategorySection.Items
                        items={merchantTreatments}
                        compareItems={compare(
                          isSearchActive ? 'searchScore' : 'alphanumeric'
                        )}
                        renderItem={mt => (
                          <RadioGroup.Option
                            key={mt.id}
                            value={composeMerchantTreatmentId(mt, name)}
                            onClick={() => {
                              if (!isOther(mt) && !isLast) {
                                setTimeout(() => f.submit(), 160)
                              }
                            }}
                          >
                            {({ checked }) => (
                              <Tile
                                key={mt.id}
                                checked={checked}
                                className={
                                  isLast && !isOther(mt)
                                    ? 'justify-start gap-2'
                                    : undefined
                                }
                              >
                                {isLast && !isOther(mt) && (
                                  <RadioCards.OptionDot />
                                )}
                                <Tile.Label>{mt.treatment?.name}</Tile.Label>
                                {isOther(mt) ? (
                                  <Tile.Icon icon={PlusIcon} />
                                ) : !isLast ? (
                                  <Tile.Icon icon={ArrowRightIcon} />
                                ) : null}
                              </Tile>
                            )}
                          </RadioGroup.Option>
                        )}
                        defaultIsOpen={
                          !!merchantTreatments.find(
                            mt =>
                              composeMerchantTreatmentId(mt, name) ===
                              values?.merchantTreatmentId
                          )
                        }
                      />
                    </CategorySection>
                  ) : null
                })}
              </div>
            </Field>

            {values?.merchantTreatmentId?.includes('-') && (
              <div className="mt-5 pb-8">
                <h3 className="subheading col-span-2 mb-3 text-tabeo-ink-1">
                  {t('Other treatment')}
                </h3>
                <div className="space-y-5">
                  <div className="grid gap-4 md:grid-cols-2">
                    <Field
                      name="otherTreatmentName"
                      label={t('Specify name')}
                      placeholder={t('Type name here...')}
                      component={FFTextInput}
                      validate={[required, lengthRange(4, 500)]}
                      autoFocus
                      clearable
                      defaultValue={values?.query}
                    />
                  </div>
                  <div>
                    <Field
                      name="otherTreatmentCategory"
                      label={t('Specify category')}
                      validate={[required]}
                      component={FFRadioGroup}
                    >
                      <div className="grid grid-cols-1 gap-x-4 gap-y-3 md:grid-cols-2 md:gap-y-5 xl:grid-cols-3">
                        {treatmentCategories.map(category => (
                          <RadioGroup.Option key={category} value={category}>
                            {({ checked }) => (
                              <Tile
                                key={category}
                                checked={checked}
                                className="justify-start gap-2"
                              >
                                <RadioCards.OptionDot />
                                <Tile.Label>{category}</Tile.Label>
                              </Tile>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </Field>
                  </div>
                </div>
              </div>
            )}

            <div className="caption mt-auto flex gap-2 pb-6 pt-4 text-tabeo-ink-1">
              <Cog8ToothIcon className="h-5 w-5 text-tabeo-ink-3" />
              <div>
                <Trans>
                  Manage your default treatments in the{' '}
                  <Link to="/settings?tab=treatments" className="anchor">
                    settings
                  </Link>
                </Trans>
              </div>
            </div>

            <AutoSave
              debounce={0}
              onSave={(values: any) => {
                setStateForStep('treatment', values)
              }}
            />
            <button type="submit" className="hidden">
              {t('Submit')}
            </button>
          </form>
        )
      }}
    </Form>
  )
}

export function TreatmentActions() {
  const { state } = useNewTransactionFlow()
  const { t } = useTranslation()
  const { data: merchant } = useMerchant()

  if (!merchant) return null

  const mt = getMerchantTreatmentById(
    state?.treatment?.merchantTreatmentId,
    merchant
  )

  const submitLabel = isOther(mt) ? t('Confirm treatment') : t('Continue')

  return <ActionButton>{submitLabel}</ActionButton>
}

export function shouldRenderTreatmentMobileActions(state: NNTSState) {
  return !!state?.treatment?.otherTreatmentName
}

export function TreatmentStepper(props: ComponentProps<typeof StepperItem>) {
  const { state } = useNewTransactionFlow()
  const { t } = useTranslation()
  const { data: merchant } = useMerchant()

  if (!merchant) return null

  const { treatment } = state

  const mt = getMerchantTreatmentById(
    state?.treatment?.merchantTreatmentId,
    merchant
  )

  const otherTreatmentName = state?.treatment?.otherTreatmentName
  const treatmentName = treatment?.treatmentName

  const body = isOther(mt)
    ? otherTreatmentName || mt?.treatment?.name
    : mt?.treatment?.name || treatmentName || ''

  return (
    <StepperItem empty={!body} {...props}>
      <StepperItem.Header>
        <StepperItem.Icon icon={HeartIcon} />
        <StepperItem.Label>{t('Treatment')}</StepperItem.Label>
      </StepperItem.Header>
      <StepperItem.Body className="line-clamp-4 whitespace-normal">
        {body}
      </StepperItem.Body>
    </StepperItem>
  )
}
