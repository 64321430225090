import React from 'react'
import Svg, { SvgProps, G } from './Base'

function Link({ fill, ...props }: SvgProps) {
  return (
    <Svg {...props}>
      <G
        stroke={fill}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9.917 11.824a3.135 3.135 0 002.315 1.334c.919.07 1.82-.285 2.472-.973l1.905-2.01c1.203-1.313 1.186-3.402-.039-4.694a3.058 3.058 0 00-4.45-.04l-1.092 1.145" />
        <path d="M12.083 10.176a3.135 3.135 0 00-2.315-1.334 3.083 3.083 0 00-2.472.973l-1.905 2.01c-1.203 1.313-1.186 3.402.039 4.694a3.058 3.058 0 004.45.04l1.086-1.145" />
      </G>
    </Svg>
  )
}

export default Link
