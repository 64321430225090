import { Spinner } from '@tabeo/scarf'
import handleFileDownload from '@tabeo/sharpei/utils/handleFileDownload'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from 'resources/Auth'

function ViewAsset() {
  const {
    data: { token },
  } = useAuth()
  const query = new URLSearchParams(useLocation().search)
  const url = query.get('url')

  useEffect(() => {
    async function handleDownload() {
      const objectUrl = await handleFileDownload(url, { token, open: false })
      window.location.href = objectUrl
      URL.revokeObjectURL(objectUrl)
    }
    if (token && url) {
      handleDownload()
    }
  }, [token, url])

  return <Spinner />
}

ViewAsset.propTypes = {}

export default ViewAsset
