import { Box, Prompt, Subtitle, View } from '@tabeo/scarf'
import Layout from 'components/Layout'
import declinedSvg from 'components/Signup/assets/declined.svg'
import useAuthGuard from 'hooks/useAuthGuard'
import useFlow from 'hooks/useFlow'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'resources/Auth'
import { useMerchant } from 'resources/Merchant'
import styled from 'styled-components'

const Icon = styled(View)``

Icon.defaultProps = {
  as: 'img',
  mx: 'auto',
  src: declinedSvg,
}

function RiskChecksFailed() {
  const { t } = useTranslation()
  useAuthGuard()

  const { data: merchant } = useMerchant()
  const {
    data: { user },
  } = useAuth()

  const { loading } = useFlow({ merchant, user })

  return (
    <Layout maxWidth="460px" bg={['white', 'sky.2']} isLoading={loading}>
      <Box variant="content" textAlign="center">
        <Icon />
        <Subtitle mb={3} color="sea.3">
          {t('Something went wrong')}
        </Subtitle>
        <Prompt px="2px">
          {t(
            'We’re having trouble verifying some of your details. A member of our team will get in touch with you shortly, we appreciate your pateince.'
          )}
        </Prompt>
      </Box>
    </Layout>
  )
}

export default RiskChecksFailed
