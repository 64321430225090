import React from 'react'
import styled from 'styled-components'
import Svg, { SvgProps } from './Base'

export type TickProps = SvgProps & { light?: boolean }

const Tick = ({ light, ...rest }: TickProps) => (
  <Svg {...rest}>
    <g>
      {light ? (
        <path d="M9.286 15.227l-4.101-4.1a.63.63 0 0 1 0-.893l.892-.892a.63.63 0 0 1 .892 0l2.763 2.762 5.917-5.917a.63.63 0 0 1 .892 0l.892.892a.63.63 0 0 1 0 .893l-7.255 7.255a.63.63 0 0 1-.892 0z" />
      ) : (
        <path d="M11 19a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm2.646-10.177l-3.905 3.749-1.403-1.275a.8.8 0 0 0-1.076 1.184l1.955 1.778a.8.8 0 0 0 1.093-.015l4.444-4.267a.8.8 0 0 0-1.108-1.154z" />
      )}
    </g>
  </Svg>
)

export default styled(Tick)``
