import { MerchantTreatment } from '@tabeo/ts-types'
import { OTHER_VALUES } from 'components/MerchantTreatmentSelect'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import { NormalizedMerchant } from 'types/NormalizedMerchant'

export const isOther = (mt?: MerchantTreatment) =>
  mt ? OTHER_VALUES.includes(mt.treatment?.name || '') : false

type MerchantTreatmentWithSearchScore = MerchantTreatment & {
  searchScore?: number
}

export const compare =
  (mode: 'usage' | 'alphanumeric' | 'searchScore') =>
  (
    a: MerchantTreatmentWithSearchScore,
    b: MerchantTreatmentWithSearchScore
  ) => {
    const aName = a.treatment?.name || ''
    const bName = b.treatment?.name || ''

    if (OTHER_VALUES.includes(aName)) {
      return 1
    }

    if (OTHER_VALUES.includes(bName)) {
      return -1
    }

    if (mode === 'usage') {
      if (a.usage === b.usage) {
        return aName.localeCompare(bName)
      }

      return b.usage - a.usage
    }

    if (mode === 'alphanumeric') {
      return aName.localeCompare(bName)
    }

    if (mode === 'searchScore') {
      if (a.searchScore === b.searchScore) {
        return aName.localeCompare(bName)
      }

      return (b.searchScore || 0) - (a.searchScore || 0)
    }

    return 0
  }

export const parseMerchantTreatmentId = (id?: string) => {
  return Number(id?.split('-')[0])
}

export const composeMerchantTreatmentId = (
  mt: MerchantTreatment,
  categoryName: string
) => {
  return isOther(mt) ? `${mt.id}-${categoryName}` : `${mt.id}`
}

export default function useMerchantTreatments() {
  const { data: merchant } = useMerchant()
  const { t } = useTranslation()

  if (!merchant) {
    return []
  }

  const getMerchantTreatmentsByCategories = (merchant: NormalizedMerchant) => {
    const { merchantTreatments, sector } = merchant

    if (sector === 'dental_services') {
      const isNHS = (mt: MerchantTreatment) =>
        mt.treatment?.category.toLowerCase() === 'nhs'

      return [
        {
          name: t('NHS'),
          merchantTreatments: merchantTreatments.filter(isNHS),
        },
        {
          name: t('General'),
          merchantTreatments: merchantTreatments.filter(
            mt =>
              !isNHS(mt) &&
              !isOther(mt) &&
              ['General'].includes(mt.treatment?.category || '')
          ),
        },
        {
          name: t('Specialist'),
          merchantTreatments: merchantTreatments.filter(
            mt =>
              !isNHS(mt) &&
              !isOther(mt) &&
              !['General'].includes(mt.treatment?.category || '')
          ),
        },
        {
          name: t('Other'),
          merchantTreatments: merchantTreatments.filter(isOther),
        },
      ]
    }

    if (sector === 'optical_care') {
      return [
        {
          name: t('General'),
          merchantTreatments: merchantTreatments.filter(
            mt =>
              ['General'].includes(mt.treatment?.category || '') && !isOther(mt)
          ),
        },
        {
          name: t('Specialist'),
          merchantTreatments: merchantTreatments.filter(
            mt =>
              !['General'].includes(mt.treatment?.category || '') &&
              !isOther(mt)
          ),
        },
      ]
    }

    if (sector === 'veterinary_services') {
      return [
        {
          name: t('General'),
          merchantTreatments: merchantTreatments.filter(
            mt =>
              ['General'].includes(mt.treatment?.category || '') && !isOther(mt)
          ),
        },
        {
          name: t('Shop'),
          merchantTreatments: merchantTreatments.filter(
            mt =>
              ['Goods'].includes(mt.treatment?.category || '') && !isOther(mt)
          ),
        },
        {
          name: t('Specialist'),
          merchantTreatments: merchantTreatments.filter(
            mt =>
              !isOther(mt) &&
              !['General', 'Goods'].includes(mt.treatment?.category || '')
          ),
        },
      ]
    }

    if (sector === 'hair_transplant') {
      return [
        {
          name: t('General'),
          merchantTreatments,
        },
      ]
    }

    if (sector === 'hearing_aids') {
      return [
        {
          name: t('General'),
          merchantTreatments: merchantTreatments.filter(
            mt =>
              !['Device'].includes(mt.treatment?.category || '') && !isOther(mt)
          ),
        },
        {
          name: t('Device'),
          merchantTreatments: merchantTreatments.filter(
            mt =>
              ['Device'].includes(mt.treatment?.category || '') && !isOther(mt)
          ),
        },
      ]
    }

    return [
      {
        name: t('General'),
        merchantTreatments,
      },
    ]
  }

  const merchantTreatmentsByCategories =
    getMerchantTreatmentsByCategories(merchant)

  return merchantTreatmentsByCategories
    .filter(({ merchantTreatments }) => merchantTreatments.length !== 0)
    .map(({ name, merchantTreatments }) => ({
      name,
      merchantTreatments: merchantTreatments.sort(compare('usage')),
    }))
}
