import { ExposedError } from '@stripe/terminal-js'
import { Caption, Spinner, Title, View } from '@tabeo/scarf'
import Summary from 'components/terminal/Summary'
import useStripeTerminal from 'hooks/useStripeTerminal'
import { useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useTerminalPayment } from 'resources/TerminalPayment'
import TerminalPaymentContext from '../Context'

function ProcessPayment() {
  const { t } = useTranslation()
  const { processPayment, getConnectedReader } = useStripeTerminal()
  const { dispatch, state } = useContext(TerminalPaymentContext)
  const { data: tp, resource } = useTerminalPayment({
    id: state.terminalPaymentId,
  })

  const didInit = useRef(false)

  useEffect(() => {
    const fn = async () => {
      try {
        // Store reader serial number in terminal payment
        // Avoid interrupting payment flow if this fails
        try {
          const reader = await getConnectedReader()
          await resource?.assignReaderToTerminalPayment(reader.serial_number)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await processPayment(state.paymentIntent!)
        dispatch({ type: 'payment-captured' })
      } catch (e) {
        const { code, payment_intent: paymentIntent } = e as ExposedError

        if (
          ['card_declined', 'expired_card', 'processing_error'].includes(
            code || ''
          )
        ) {
          dispatch({ type: 'payment-declined', payload: paymentIntent })
          return
        }

        dispatch({ type: 'error' })
      }
    }

    if (!didInit.current) {
      didInit.current = true
      fn()
    }
  }, [
    dispatch,
    processPayment,
    state.paymentIntent,
    getConnectedReader,
    resource,
  ])

  return (
    <View textAlign="center">
      <Spinner minHeight="160px" />
      <Title mt={6}>{t('Processing payment')}</Title>
      <Caption mt={1}>{t('Please do not refresh this page')}</Caption>
      <Summary data={tp} mt={6} />
    </View>
  )
}

export default ProcessPayment
