import propTypes from '@styled-system/prop-types'

export const allPropTypes = Object.keys(propTypes).reduce(
  (a, key) => Object.assign(a, propTypes[key]),
  {}
)

export const styledSystemProps = Object.keys(allPropTypes)

export const customProps = [
  'theme',
  'blacklist',
  'isPreviousActive',
  'isFocused',
  'isOpen',
  'spinning',
  'active',
  'alignPopupToTop',
  'outline',
  'horizontal',
  'index',
  'gap',
  'light',
  'strokeWidth',
  'fill',
  'loading',
  'cursor',
  'transform',
  'transition',
  'boxSizing',
  'focus',
  'sizeVariant',
  'small',
  'templateColumns',
  '_after',
  '_focus',
  '_selected',
  '_focusWithin',
  '_hover',
  '_invalid',
  '_active',
  '_disabled',
  '_grabbed',
  '_pressed',
  '_expanded',
  '_visited',
  '_before',
  '_readOnly',
  '_first',
  '_notFirst',
  '_notLast',
  '_last',
  '_placeholder',
  '_checked',
  '_groupHover',
  '_mixed',
  '_odd',
  '_even',
  'whiteSpace',
  'textOverflow',
]

const scarfProps = [...styledSystemProps, ...customProps]

export default scarfProps
