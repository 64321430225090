import { Button, Text, Title, usePromise, View } from '@tabeo/scarf'
import ConfirmModal from 'components/ConfirmModal'
import LayoutSideNavbar from 'components/layouts/LayoutSideNavbar'
import Subpage from 'components/layouts/Subpage'
import TwoColumns, { Column } from 'components/layouts/TwoColumns'
import Notifications from 'components/Notifications'
import { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useMerchant } from '../../resources/Merchant'
import { usePlan } from '../../resources/Plan'
import Details from './Details'
import Header from './Header'
import SharePanel from './SharePanel'
import Subscriptions from './Subscriptions'

function Plan() {
  const {
    data: plan,
    resource: { updatePlan, deletePlan },
  } = usePlan()
  const { data: merchant } = useMerchant()
  const { replace } = useHistory()
  const [confirm, confirmStatus] = usePromise(async () => {
    await deletePlan()
    replace('/plans')
  })
  const confirmModal = useRef()

  return (
    <LayoutSideNavbar isLoading={!plan}>
      <ConfirmModal
        innerRef={confirmModal}
        isConfirmPending={confirmStatus.pending}
        confirmLabel="Delete"
        textAlign="center"
      >
        <Title>Delete plan</Title>
        <Text mt={1} mb={4}>
          Are you sure you want to delete this plan? This action is not
          reversible.
        </Text>
      </ConfirmModal>
      {plan && (
        <Subpage>
          <Notifications />
          <Header plan={plan} mb={4} />
          <TwoColumns>
            <Column>
              <Details data={plan} />
              <Subscriptions plan={plan} />
              {plan?.subscriptions?.filter(s => s.state === 'active').length ===
                0 && (
                <View>
                  <Button
                    variant="secondary"
                    color="red.3"
                    onClick={() =>
                      confirmModal.current.open({
                        onConfirm: confirm,
                      })
                    }
                  >
                    Delete plan
                  </Button>
                </View>
              )}
            </Column>
            <Column>
              {merchant.flags?.is_subscription_plan_capable && (
                <SharePanel
                  onSave={updatePlan}
                  plan={plan}
                  merchant={merchant}
                />
              )}
            </Column>
          </TwoColumns>
        </Subpage>
      )}
    </LayoutSideNavbar>
  )
}

export default Plan
