import React from 'react'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import { twMerge } from 'tailwind-merge'

type AddButtonProps = React.ComponentPropsWithoutRef<'button'>

// eslint-disable-next-line react/prop-types
function AddButton({ children, className, ...rest }: AddButtonProps) {
  return (
    <button
      type="button"
      className={twMerge('flex gap-1', className)}
      {...rest}
    >
      <PlusCircleIcon className="h-5 w-5 text-tabeo-primary-3" />
      <span className="caption text-tabeo-primary-3 underline">{children}</span>
    </button>
  )
}

export default AddButton
