import React from 'react'
import styled from 'styled-components'
import theme from '../theme'
import { Merge } from '../types/helpers'
import ScarfProps from '../types/ScarfProps'
import Box from './Box'
import { Heading } from './Text'
import View from './View'

const customColor: Record<string, { bg: string; color: string }> = {
  green: {
    bg: 'green.3',
    color: 'green.2',
  },
  yellow: {
    bg: 'yellow.3',
    color: 'yellow.2',
  },
}

const Line = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  border-radius: 4px 4px 0 0;
`

Line.defaultProps = {
  width: '100%',
  bg: 'sky.0',
}

const Container = styled(Box)`
  position: relative;
  border-top: none;
`

Container.defaultProps = {
  p: [4, 5],
  bg: 'white',
  boxShadow: 1,
  width: 'auto',
}

const Title = styled(Heading)``

Title.defaultProps = {
  color: 'ink.2',
  mb: 1,
}

export type BannerProps = Merge<
  ScarfProps,
  {
    title?: string
    color?: Exclude<keyof (typeof theme)['colors'], 'default' | 'ink' | 'sky'>
  }
>

const Banner = ({ title, color, children, ...rest }: BannerProps) => {
  const custom = color ? customColor[color] : undefined
  const line = custom ? custom.bg : `${color}.3`
  const text = custom ? custom.color : `${color}.3`
  return (
    <Container {...rest}>
      <Line bg={color ? line : undefined} />
      {title && <Title color={color ? text : undefined}>{title}</Title>}
      {children}
    </Container>
  )
}

export default Banner
