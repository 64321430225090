import React from 'react'
import PropTypes from 'prop-types'

import { Tag } from '@tabeo/scarf'
import { t } from 'i18n'

const states = {
  sent: {
    color: 'yellow',
    label: t('Sent'),
  },
  opened: {
    color: 'yellow',
    label: t('Opened'),
  },
  scheduled: {
    color: 'green',
    label: t('Payment scheduled'),
  },
  card_declined: {
    color: 'red',
    label: t('Card Declined'),
  },
  funds_on_the_way: {
    color: 'grey',
    label: t('Payout on the way'),
  },
  settled: {
    color: 'grey',
    label: t('Settled'),
  },
  canceled: {
    color: 'grey',
    label: t('Cancelled'),
  },
  expired: {
    color: 'grey',
    label: t('Expired'),
  },
}

const SubscriptionStateTag = ({ state, ...rest }) => {
  const currentState = states[state] || {}
  const color = currentState.color || 'grey'
  const label = currentState.label || state
  return (
    <Tag color={color} {...rest}>
      {label}
    </Tag>
  )
}

SubscriptionStateTag.propTypes = {
  state: PropTypes.string.isRequired,
}

export default SubscriptionStateTag
