import PropTypes from 'prop-types'
import React from 'react'
import { Merge } from '../../types/helpers'
import ScarfProps from '../../types/ScarfProps'
import Flex from '../Flex'
import * as Icons from '../Icons'
import { Item } from '../InteractiveForm/Select'
import { Caption, Subheading } from '../Text'
import View from '../View'
import { State } from './context'
import { FilterSelectProps } from './FilterSelect'

export type ActiveFiltersProps = Merge<
  ScarfProps,
  {
    filters: Omit<
      FilterSelectProps & {
        initialMode?: FilterSelectProps['mode']
        initialValue?: FilterSelectProps['value']
      },
      'value' | 'mode' | 'onChange'
    >[]
    onChange: (change: { name: string; value: any; mode?: string }) => void
    values: Required<State['activeFilters']>
  }
>

function ActiveFilters({
  values = {},
  onChange,
  filters,
  ...rest
}: ActiveFiltersProps) {
  function handleDelete(name: string, value: Item['value']) {
    const { value: previousValue } = values[name]

    const isArray = Array.isArray(previousValue)
    const change = {
      name,
      value: isArray
        ? previousValue.filter((v) => v.value !== value)
        : undefined,
    }
    onChange(change)
  }
  const filterNames = Object.keys(values)
  const filterLabels: Record<string, string> = filters.reduce(
    (mem, item) => ({
      ...mem,
      [item.name]: item.label,
    }),
    {}
  )

  const hasSelectedFilter = filterNames.find((n) => {
    const { value } = values[n]

    const fields = Array.isArray(value)
      ? value
      : value !== undefined
      ? [value]
      : []
    const validFields = fields.filter((f) => f !== null)
    return validFields.length
  })

  if (!hasSelectedFilter) {
    return null
  }

  return filterNames.length ? (
    <Flex
      justifyContent="flex-start"
      flexWrap="wrap"
      mt={4}
      mb="-12px"
      display={['none', 'flex']}
      {...rest}
    >
      {filterNames.map((name) => {
        const { value } = values[name]

        const fields = Array.isArray(value)
          ? value
          : value !== undefined
          ? [value]
          : []
        const validFields = fields.filter((f) => f !== null)
        const { renderActiveLabel } = filters.find((f) => f.name === name) || {}
        return (
          !!validFields.length && (
            <View key={name} mr={2}>
              <Subheading color="ink.2" fontSize="10px" mb={1} minHeight="10px">
                {renderActiveLabel
                  ? renderActiveLabel({
                      ...values[name],
                    })
                  : filterLabels[name]}
              </Subheading>
              {validFields.map((field) => (
                <Flex
                  key={field.value}
                  bg="sky.3"
                  border={1}
                  display="inline-flex"
                  borderRadius={1}
                  mr={3}
                  mb={3}
                  alignItems="unset"
                  overflow="hidden"
                >
                  <Flex>
                    <Caption
                      flex="1"
                      borderRight={1}
                      color="ink.2"
                      px={2}
                      py={1}
                    >
                      {field.label}
                    </Caption>
                  </Flex>
                  <Flex
                    onClick={() => handleDelete(name, field.value)}
                    cursor="pointer"
                    _hover={{
                      bg: 'sky.2',
                    }}
                  >
                    <Icons.Close fill="ink.2" />
                  </Flex>
                </Flex>
              ))}
            </View>
          )
        )
      })}
    </Flex>
  ) : null
}

ActiveFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object,
  filters: PropTypes.array,
}

export default ActiveFilters
