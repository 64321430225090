import PropTypes from 'prop-types'
import React from 'react'
import { Merge } from '../../types/helpers'
import ScarfProps from '../../types/ScarfProps'
import Flex from '../Flex'
import { Subheading } from '../Text'

export type TabsProps = Merge<
  ScarfProps,
  {
    labels?: string[]
    onClick?: (index: number) => void
    activeIndexes?: number[]
  }
>

function Tabs({
  labels = [],
  onClick = (e) => e,
  activeIndexes = [],
  ...rest
}: TabsProps) {
  return (
    <Flex px={2} justifyContent="flex-start" flexWrap="wrap" {...rest}>
      {labels.map((label, index) => (
        <Subheading
          key={index}
          mr={2}
          p={2}
          color={activeIndexes.includes(index) ? 'default.primary' : 'ink.2'}
          onClick={() => onClick(index)}
          cursor="pointer"
          data-testid="tab"
        >
          {label}
        </Subheading>
      ))}
    </Flex>
  )
}

Tabs.propTypes = {
  labels: PropTypes.array.isRequired,
  activeIndexes: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Tabs
