import {
  Caption,
  Flex,
  Icons,
  Panel,
  Prompt,
  Subheading,
  Switch,
  View,
} from '@tabeo/scarf'
import { required } from '@tabeo/sharpei/utils/validations'
import AnimateHeight from 'components/AnimateHeight'
import AutoCopyText from 'components/AutoCopyText'
import AutoSave from 'components/Form/AutoSave'
import PlanAccessibilityModal from 'components/PlanAccessibilityModal'
import config from 'config'
import PropTypes from 'prop-types'
import React from 'react'
import { Field as FormField, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

let shareModal

const SharePanel = ({ onSave, plan, merchant, ...rest }) => {
  const planPublicUrl = `${config.CONSUMER_APP_URL}/merchants/${merchant.slug}/plans/${plan.slug}`
  const { t } = useTranslation()
  return (
    <Form
      onSubmit={onSave}
      initialValues={{ accessibility: plan.accessibility }}
    >
      {({ values: { accessibility } }) => {
        return (
          <Panel
            header={
              <>
                <Panel.Title>{t('Sharing')}</Panel.Title>
                <Icons.Questionmark
                  cursor="pointer"
                  onClick={() => shareModal.open()}
                />
              </>
            }
            {...rest}
            data-testid="share-panel"
          >
            <Flex bg="white" px={5} py={2}>
              <Prompt>{t('Private mode')}</Prompt>
              <FormField
                name="accessibility"
                component={Switch}
                validate={required}
                parse={v => (v === false ? 'public' : 'private')}
                format={v => v !== 'public'}
                data-testid={
                  accessibility === 'public'
                    ? 'public-accessibility'
                    : 'private-accessibility'
                }
              />
            </Flex>
            <AnimateHeight
              duration={200}
              height={accessibility === 'public' ? 'auto' : 0}
            >
              <View bg="white" borderTop={1} px={5} py={3}>
                <Subheading color="ink.2" mb={1}>
                  {t('Shareable link')}
                </Subheading>
                <AutoCopyText
                  value={planPublicUrl}
                  mb={2}
                  data-testid="shareable-link"
                />
                <Caption>
                  {t('Anyone with the link will be able to sign up.')}
                </Caption>
              </View>
            </AnimateHeight>
            <AutoSave debounce={1000} save={onSave} />
            <PlanAccessibilityModal
              innerRef={modal => {
                shareModal = modal
              }}
            />
          </Panel>
        )
      }}
    </Form>
  )
}

SharePanel.propTypes = {
  onSave: PropTypes.func.isRequired,
  plan: PropTypes.object,
  merchant: PropTypes.object,
}

export default SharePanel
