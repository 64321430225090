import React from 'react'
import { Redirect } from 'react-router-dom'

import Layout from 'components/Layout'
import ScrollToTopOnMount from 'components/helpers/ScrollToTopOnMount'

import { Box, View, Subtitle, Text } from '@tabeo/scarf'
import { useTranslation, Trans } from 'react-i18next'
import rejected from './rejected.svg'
import { useAuth } from '../../resources/Auth'

const Failed = () => {
  const {
    data: { token },
  } = useAuth()
  const { t } = useTranslation()
  if (!token) {
    return <Redirect to="/" />
  }

  return (
    <Layout maxWidth="460px" bg={['white', 'sky.2']}>
      <ScrollToTopOnMount />
      <Box variant="content" textAlign="center">
        <View as="img" display="block" mx="auto" mb={5} src={rejected} />
        <Subtitle mb={3}>{t('Company Not Supported')}</Subtitle>
        <Text>
          <Trans>
            Thank you for your interest, unfortunately we are unable to support
            your business at this point.
            <br />
            We appreciate your understanding.
          </Trans>
        </Text>
      </Box>
    </Layout>
  )
}

export default Failed
