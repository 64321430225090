import React from 'react'
import { themeGet } from '@styled-system/theme-get'

import styled from 'styled-components'
import ScarfProps from '../types/ScarfProps'
import View from './View'

export const Row = styled(View)`
  display: flex;
  flex-direction: column;
  @media (min-width: ${themeGet('breakpoints.0')}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const CardWrap = styled(View)`
  flex: 1 0;

  &:after {
    margin-left: 20px;
    margin-right: 20px;
  }

  // Mobile
  border-bottom: 1px solid ${themeGet('colors.sky.1')};
  ${Row}:last-of-type &:last-of-type {
    border-bottom: none;
  }

  @media (min-width: ${themeGet('breakpoints.0')}) {
    border-bottom: none;
    &:not(:last-of-type) {
      border-right: 1px solid ${themeGet('colors.sky.1')};
    }
  }

  @media (min-width: ${themeGet('breakpoints.0')}) {
    /* prettier-ignore */
    ${Row}:not(:last-of-type) &:after {
      content: '';
      height: 1px;
      background: ${themeGet('colors.sky.1')};
      display: block;
    }
  }
  /* prettier-ignore */
  ${Row}:not(:last-of-type) &:first-of-type:after {
    margin-left: 0;
  }
  /* prettier-ignore */
  ${Row}:not(:last-of-type) &:last-of-type:after {
    margin-right: 0;
  }
`

const CardContent = styled(View)`
  padding: 12px 0;
  height: 100%;
  @media (max-width: ${themeGet('breakpoints.0')}) {
    ${Row}:first-of-type ${CardWrap}:first-of-type & {
      padding-top: 0;
    }

    ${Row}:last-of-type ${CardWrap}:last-of-type & {
      padding-bottom: 0;
    }
  }

  @media (min-width: ${themeGet('breakpoints.0')}) {
    padding: 12px 20px;
    ${Row}:first-of-type & {
      padding-top: 0;
    }

    ${Row}:last-of-type & {
      padding-bottom: 0;
    }

    ${CardWrap}:first-of-type & {
      padding-left: 0;
    }

    ${CardWrap}:last-of-type & {
      padding-right: 0;
    }
  }
`

export type CardProps = ScarfProps

export const Card = ({ children, ...rest }: CardProps) => (
  <CardWrap {...rest}>
    <CardContent>{children}</CardContent>
  </CardWrap>
)
