import React from 'react'
import { useTranslation } from 'react-i18next'
import debounce from '../../utils/debounce'
import Select, { I18nWrapProps } from './Select'

const API_BASE_URLS = {
  development: 'development.api.tabeo.co.uk/v1',
  staging: 'staging.api.tabeo.co.uk/v1',
  demo: 'demo.api.tabeo.co.uk/v1',
  production: 'api.tabeo.co.uk/v1',
}

export type EnvType = 'development' | 'staging' | 'demo' | 'production'

const getCompanies = async (name: string, env: EnvType = 'production') => {
  if (!name || name.length < 3) {
    return []
  }
  const res = await fetch(`https://${API_BASE_URLS[env]}/companies`, {
    body: JSON.stringify({
      name,
    }),
    method: 'post',
  })
  if (!res.ok) {
    // eslint-disable-next-line no-throw-literal
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw {
      name: 'NetworkError',
      message: `Request failed with status code: ${res.status}`,
      status: res.status,
      query: name,
    }
  }
  const { data } = await res.json()
  if (!data || !data.length) {
    // eslint-disable-next-line no-throw-literal
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw {
      name: 'SearchError',
      message: `No company found for: ${name}`,
      status: 404,
      query: name,
    }
  }
  const options = data.map((i: any) => ({
    ...i,
    label: i.title,
    value: i.companyNumber,
  }))
  return { options }
}

export type CompanySelectProps = I18nWrapProps & {
  env?: EnvType
}

const CompanySelect = ({ env, ...props }: CompanySelectProps) => {
  const debounced = debounce(getCompanies, 200)
  const { t } = useTranslation()
  return (
    <Select
      {...props}
      loadOptions={(name: string) => debounced(name, env)}
      placeholder={t('Enter company name')}
    />
  )
}

export default CompanySelect
