import { useTranslation } from 'react-i18next'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import PaymentRefund from 'components/checkout-payment/PaymentRefund'
import { ReactComponent as TickGreen } from 'components/checkout-payment/assets/tick-green.svg'

function Settled() {
  const { t } = useTranslation()

  return (
    <ActionCard data-testid="settled-action-panel">
      <ActionCard.Header>
        <TickGreen className="h-6 w-6" />
        {t('Settled')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t(
          'This payment was successful. The payout should have reached your bank account.'
        )}
      </ActionCard.Body>
      <ActionCard.Actions>
        <PaymentRefund.RequestButton />
      </ActionCard.Actions>
    </ActionCard>
  )
}

export default Settled
