import { PlusIcon } from '@heroicons/react/20/solid'
import { Modal, Settings } from '@tabeo/scarf'
import { Button, Tooltip } from '@tabeo/scarf2'

import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useCardReaders } from 'resources/CardReaders'
import CreateReaderModal from './AddReaderModal'
import ReaderItem from './ReaderItem'
import UpdateReaderModal from './UpdateReaderModal'

const { Section, Stack } = Settings

export default function Readers() {
  const { t } = useTranslation()
  const { data } = useCardReaders()

  const hasAtLeastOneReader = Number(data?.items?.length) > 0

  const history = useHistory()
  const location = useLocation()
  const createReaderModalRef = useRef<Modal>()
  const updateReaderModalRef = useRef<Modal>()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get('modal') === 'new-reader') {
      createReaderModalRef.current?.open()

      // Remove the query param from the URL
      searchParams.delete('modal')
      history.replace(`${location.pathname}?${searchParams}`)
    }
  }, [location.search, location.pathname, history])

  return (
    <Section>
      <CreateReaderModal ref={createReaderModalRef} />
      <UpdateReaderModal ref={updateReaderModalRef} />
      <Section.Header flexWrap={['wrap', 'nowrap']}>
        <div>
          <Section.Title>{t('Readers')}</Section.Title>
          <Section.Description>
            {t('Manage the readers associated with your account.')}
          </Section.Description>
        </div>
        {hasAtLeastOneReader ? (
          <Tooltip title="Support for more than one reader is on the way.">
            <Button size="sm" leadingIcon={p => <PlusIcon {...p} />} disabled>
              {t('Add reader')}
            </Button>
          </Tooltip>
        ) : (
          <Button
            size="sm"
            leadingIcon={p => <PlusIcon {...p} />}
            onClick={() => createReaderModalRef.current?.open()}
          >
            {t('Add reader')}
          </Button>
        )}
      </Section.Header>
      {data?.items?.map(r => (
        <Stack key={r.id}>
          <ReaderItem
            key={r.id}
            data={r}
            onEdit={d => updateReaderModalRef.current?.open(d)}
          />
        </Stack>
      ))}
    </Section>
  )
}
