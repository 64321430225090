// @ts-nocheck

import { themeGet } from '@styled-system/theme-get'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextMask, {
  MaskedInputProps as BaseMaskedInputProps,
} from 'react-text-mask'
import styled, { css } from 'styled-components'
import { InputProp, MetaProp } from '../../types/form'
import { Merge } from '../../types/helpers'
import ScarfProps from '../../types/ScarfProps'
import { children } from '../../utils/propTypes'
import variants from '../../utils/variants'
import { Clear } from '../Icons'
import Text, { BaseProps as TextBaseProps } from '../Text'
import View from '../View'

export type BaseProps = Merge<
  ScarfProps<'input'>,
  {
    variant?: 'normal' | 'outline' | 'outline-v2'
    disabled?: boolean
    meta?: Partial<MetaProp>
  }
>

export const Base = styled(View)<BaseProps>`
  ${variants({
    variant: {
      normal: {
        py: '7px',
      },
      outline: {
        py: '6px',
        border: 1,
        borderColor: 'sky.1',
        transition: 'border-color 200ms linear',
        '&:after': {
          display: 'none',
        },
      },
      'outline-v2': {
        py: '8px',
        border: 1,
        borderColor: 'sky.1',
        transition: 'border-color 200ms linear',
        '&:after': {
          display: 'none',
        },
      },
    },
  })}

  cursor: text;
  position: relative;
  outline: none;

  input {
    height: 24px;
  }

  ${(p) =>
    p.disabled &&
    css`
      background-color: ${themeGet('colors.sky.3')};
      cursor: not-allowed;
    `}

  ${(p) =>
    p.variant === 'normal' &&
    css`
      &:after {
        content: ' ';
        width: 100%;
        height: 3px;
        position: absolute;
        left: 0;
        bottom: 0;
        border-radius: 0 0 4px 4px;
        background: ${themeGet('colors.ink.2')};
        transition: background-color 200ms linear;
        ${(p) =>
          p.meta.focus &&
          css`
            background-color: ${themeGet('colors.ink.0')};
          `}
        ${(p) =>
          p.meta.touched &&
          !p.meta.error &&
          css`
            background-color: ${themeGet('colors.default.primary')};
          `}
    ${(p) =>
          p.meta.error &&
          p.meta.touched &&
          css`
            background-color: ${themeGet('colors.default.red')};
          `}
    ${(p) =>
          p.disabled &&
          css`
            background-color: ${themeGet('colors.sky.0')};
          `}
      }
    `}

  ${(p) =>
    p.variant === 'outline' &&
    css`
      ${(p) =>
        p.meta.focus &&
        css`
          border-color: ${themeGet('colors.sky.0')};
        `}
      ${(p) =>
        p.meta.touched &&
        !p.meta.error &&
        css`
          border-color: ${themeGet('colors.default.primary')};
        `}
    ${(p) =>
        p.meta.error &&
        p.meta.touched &&
        css`
          border-color: ${themeGet('colors.default.red')};
        `}
    ${(p) =>
        p.disabled &&
        css`
          border-color: ${themeGet('colors.sky.0')};
        `}
    `}

  ${(p) =>
    p.variant === 'outline-v2' &&
    css`
      border-color: ${themeGet('colors.sky.0')};
      background-color: ${themeGet('colors.sky.3')};
      &:hover {
        background-color: ${themeGet('colors.sky.2')};
      }
      ${(p) =>
        p.meta.focus &&
        css`
          border-color: ${themeGet('colors.blue.3')};
          box-shadow: 0 0 0 1px ${themeGet('colors.blue.3')},
            0 0 0 1px ${themeGet('colors.blue.3')};
        `}
      ${(p) =>
        p.meta.touched &&
        !p.meta.error &&
        !p.meta.focus &&
        css`
          border-color: ${themeGet('colors.ink.1')};
        `}
    ${(p) =>
        p.meta.error &&
        p.meta.touched &&
        !p.meta.focus &&
        css`
          border-color: ${themeGet('colors.default.red')};
        `}
    ${(p) =>
        p.disabled &&
        css`
          border-color: ${themeGet('colors.sky.1')};
        `}
    `}
`

Base.defaultProps = {
  maxWidth: ['100%', '350px'],
  minWidth: '130px',
  bg: 'sky.2',
  px: 3,
  borderRadius: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  variant: 'outline-v2',
}

export type HtmlInputProps = Merge<TextBaseProps, ScarfProps<'input'>>

export const HtmlInput = styled(Text)<HtmlInputProps>`
  background: none;
  border: none;
  min-width: 1px;
  width: 100%;
  outline: none;
  padding: 0;
  &::placeholder {
    color: ${themeGet('colors.ink.2')};
  }
  &:disabled {
    cursor: not-allowed;
    color: ${themeGet('colors.ink.2')};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  &:-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

HtmlInput.defaultProps = {
  as: 'input',
  fontWeight: 'regular',
  type: 'text',
  color: 'default.ink',
}

export type MaskedInputProps = Merge<HtmlInputProps, BaseMaskedInputProps>

export const MaskedInput = styled(HtmlInput)<MaskedInputProps>``
MaskedInput.defaultProps = {
  guide: false,
  as: TextMask,
}

const Left = styled.div`
  margin-right: 12px;
`

const Right = styled.div`
  margin-left: 12px;
`

export type InputProps = Merge<
  BaseProps,
  {
    left?: React.ReactNode | any
    right?: React.ReactNode | any
    mask?: MaskedInputProps['mask']
    input?: Partial<InputProp>
    pipe?: MaskedInputProps['pipe']
    clearable?: boolean
    onClear?: () => void
  }
>

class Input extends Component<InputProps> {
  state = {
    focus: false,
  }

  handleFocus = () => {
    this.setState({
      focus: true,
    })
  }

  handleBlur = () => {
    const {
      input: { onBlur },
    } = this.props
    this.setState({
      focus: false,
    })
    onBlur && onBlur()
  }

  handleClear = () => {
    const { input } = this.props
    input?.value && input?.onChange && input?.onChange('')
  }

  render() {
    const {
      left: LeftContent,
      right: RightContent,
      placeholder,
      type,
      inputMode,
      pattern,
      input,
      meta,
      disabled,
      mask,
      clearable,
      onClear,
      pipe,
      ...rest
    } = this.props
    const { focus } = this.state

    const Component = mask ? MaskedInput : HtmlInput

    return (
      <Base
        onClick={() => {
          this.input && this.input.focus()
        }}
        disabled={disabled}
        meta={{
          focus,
          ...meta,
        }}
        {...rest}
      >
        {LeftContent && <Left>{LeftContent}</Left>}
        <Component
          ref={(inputRef) => {
            this.input = mask ? inputRef && inputRef.inputElement : inputRef
          }}
          placeholder={placeholder}
          type={type}
          inputMode={inputMode}
          pattern={pattern}
          disabled={disabled}
          mask={mask}
          pipe={pipe}
          {...input}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
        />

        {clearable && input?.value && (
          <Clear
            width={20}
            height={20}
            fill="ink.3"
            onClick={disabled ? null : onClear || this.handleClear}
            cursor={disabled ? 'not-allowed' : 'pointer'}
          />
        )}

        {RightContent && <Right>{RightContent}</Right>}
      </Base>
    )
  }
}

Input.propTypes = {
  left: children,
  right: children,
  input: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  inputMode: PropTypes.string,
  pattern: PropTypes.string,
  meta: PropTypes.object,
  mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  pipe: PropTypes.func,
}

Input.defaultProps = {
  input: {},
  meta: {},
}

export default Input
