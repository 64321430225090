import {
  Attributes,
  Box,
  Caption,
  Clickable,
  Flex,
  Prompt,
  Tag,
  View,
} from '@tabeo/scarf'
import Avatar from 'components/Avatar'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const PatientPanel = ({ data, ...rest }) => {
  const {
    consumerFirstName,
    consumerLastName,
    consumerEmail,
    consumerUserStatus,
    consumerPhoneNumber,
    consumer,
    legacyOffer,
  } = data

  const { t } = useTranslation()

  return (
    <Box p={0} boxShadow={1} data-testid="patient-panel" {...rest}>
      <View p={5}>
        <Flex>
          <Avatar name={`${consumerFirstName} ${consumerLastName}`} mr={3} />
          <View flex="1">
            <Prompt data-testid="patient-name" fontWeight="semibold">
              {consumerFirstName} {consumerLastName}
            </Prompt>
            <Caption color="ink.0">{t('Customer')}</Caption>
          </View>
        </Flex>
      </View>

      <Attributes
        p={5}
        sections={[
          [
            {
              label: (
                <Flex justifyContent="flex-start">
                  {t('Email address')}{' '}
                  {consumerUserStatus === 'activated' && (
                    <Tag small color="green" ml={2}>
                      {t('Verified')}
                    </Tag>
                  )}
                </Flex>
              ),
              value: consumerEmail,
            },
            {
              label: t('Phone number'),
              value: consumerPhoneNumber || t('Not yet available'),
            },
            {
              label: t('Home address'),
              value: consumer?.addressDetails?.Label,
            },
          ],
        ]}
        borderTop={1}
      />
      {legacyOffer && (
        <>
          <View px={5} py={4} borderTop={1}>
            <Caption color="ink.0">
              {t(
                'This application was transferred from {{consumerFirstName}} {{consumerLastName}}’s previous application ',
                {
                  consumerFirstName: legacyOffer.consumerFirstName,
                  consumerLastName: legacyOffer.consumerLastName,
                }
              )}
              <Clickable
                as={Link}
                to={`/transactions/${legacyOffer.id}`}
                fontWeight="medium"
              >
                #{legacyOffer.id}
              </Clickable>
              .
            </Caption>
          </View>
          <View p={5} borderTop={1}>
            <Flex>
              <Avatar
                name={`${consumerFirstName} ${consumerLastName}`}
                mr={3}
              />
              <View flex="1">
                <Prompt fontWeight="semibold">
                  {legacyOffer.consumerFirstName} {legacyOffer.consumerLastName}
                </Prompt>
                <Caption color="ink.0">customer</Caption>
              </View>
            </Flex>
          </View>
          <Attributes
            p={5}
            sections={[
              [
                {
                  label: (
                    <Flex justifyContent="flex-start">
                      {t('Email address')}{' '}
                      {legacyOffer.consumerUserStatus === 'activated' && (
                        <Tag small color="green" ml={2}>
                          {t('Verified')}
                        </Tag>
                      )}
                    </Flex>
                  ),
                  value: consumerEmail,
                },
                {
                  label: t('Phone number'),
                  value:
                    legacyOffer.consumerPhoneNumber || t('Not yet available'),
                },
              ],
            ]}
            borderTop={1}
          />
        </>
      )}
    </Box>
  )
}

PatientPanel.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PatientPanel
