import React from 'react'
import PropTypes from 'prop-types'
import { Icons, Funnelbar } from '@tabeo/scarf'

const StateFunnel = ({ states, currentIndex = 0, ...rest }) => {
  const pastStepProps = { bg: 'yellow.4', color: 'yellow.2' }
  const activeStepProps = { bg: 'yellow.3', color: 'yellow.1' }
  const futureStepProps = { bg: 'white', color: 'ink.2' }

  const steps = states.map((state, index) => ({
    label: state,
    props:
      index < currentIndex
        ? pastStepProps
        : index === currentIndex
        ? activeStepProps
        : futureStepProps,
    children:
      index < currentIndex ? <Icons.Tick light fill="yellow.2" /> : null,
  }))

  return <Funnelbar steps={steps} {...rest} />
}

StateFunnel.propTypes = {
  states: PropTypes.array.isRequired,
  currentIndex: PropTypes.number,
}

export default StateFunnel
