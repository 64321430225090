import { Button, Caption, Flex, Icons, Text, Title } from '@tabeo/scarf'
import Layout from 'components/Layout'
import Form from 'components/PasswordResetForm'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'resources/Auth'

function PasswordChange() {
  const { t } = useTranslation()
  const { resource } = useAuth()
  const [success, setSuccess] = useState(false)
  const history = useHistory()

  async function handlePasswordReset(payload) {
    await resource.changePassword({
      oldPassword: payload.currentPassword,
      newPassword: payload.password,
    })
    setSuccess(true)
  }
  return (
    <Layout maxWidth="640px">
      {!success ? (
        <>
          <Title mb={5}>{t('Change password')}</Title>
          <Text mb={5}>
            {t(
              'Please set your new password and make sure to store it somewhere safe'
            )}
          </Text>
          <Form onSubmit={handlePasswordReset} promptCurrentPassword />
          <Flex justifyContent="flex-start" mt={5}>
            <Icons.Lock fill="ink.2" mr={2} />
            <Caption color="ink.2">
              {t('This website is protected by 128-bit SSL security')}
            </Caption>
          </Flex>
        </>
      ) : (
        <>
          <Title mb={5}>{t('Password changed successfuly')}</Title>
          <Button onClick={() => history.goBack()}>{t('Back')}</Button>
        </>
      )}
    </Layout>
  )
}

export default PasswordChange
