import RadioGroupItem from './RadioGroupItem'
import Section from './Section'
import Stack from './Stack'

const Settings = () => null

Settings.Stack = Stack
Settings.Section = Section
Settings.RadioGroupItem = RadioGroupItem

export default Settings
