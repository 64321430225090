import React from 'react'
import ScarfProps from '../../../types/ScarfProps'
import View from '../../View'

export type ContainerProps = ScarfProps

function Container({ ...rest }: ContainerProps) {
  return (
    <View
      position="relative"
      borderRadius={[0, 1]}
      borderLeft={[0, 1]}
      borderRight={[0, 1]}
      borderTop={1}
      borderBottom={1}
      bg="white"
      {...rest}
    />
  )
}

export default Container
