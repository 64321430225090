import {
  GridTableCell as Cell,
  GridTableRow,
  Tag,
  themeGet,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Row = styled(GridTableRow)`
  &:not(:last-of-type) > * {
    border-bottom: 1px solid ${themeGet('colors.sky.1')};
  }
  &:hover > * {
    background: ${themeGet('colors.sky.3', 'white')};
  }
`

const ListItem = ({ data }) => {
  const { t } = useTranslation()
  return (
    <Row bg="white" as={Link} to={`/plans/${data.id}`}>
      <Cell color="ink.2">{data.id}</Cell>
      <Cell>{data.title}</Cell>
      <Cell color="ink.2">
        {t('{{numberOfActiveSubscriptions}} members', {
          numberOfActiveSubscriptions: data.numberOfActiveSubscriptions,
        })}
      </Cell>
      <Cell textAlign="right" color="ink.2">
        {t('{{amount}} / month', { amount: format(data.price) })}
      </Cell>
      <Cell>
        <Tag small>{data.accessibility}</Tag>
      </Cell>
    </Row>
  )
}

ListItem.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ListItem
