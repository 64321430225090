import React from 'react'
import PropTypes from 'prop-types'

import { Modal, Button, Title, Box, Text } from '@tabeo/scarf'
import { useTranslation } from 'react-i18next'

const ManualReviewPending = ({ innerRef, ...rest }) => {
  const { t } = useTranslation()
  return (
    <Modal ref={innerRef}>
      {({ close }) => {
        return (
          <Box
            maxWidth={['100%', '460px']}
            mx="auto"
            boxShadow={1}
            p={7}
            textAlign="center"
            {...rest}
          >
            <Title mb={4}>{t('Pending manual review')}</Title>
            <Text mb={6}>
              {t(
                'We still need to manually run some checks on your business, in the meantime, some functionalities may be restricted.'
              )}{' '}
            </Text>
            <Button variant="secondary" onClick={close}>
              {t('Close')}
            </Button>
          </Box>
        )
      }}
    </Modal>
  )
}

ManualReviewPending.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
}

export default ManualReviewPending
