import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Merge } from '../../../types/helpers'
import ScarfProps from '../../../types/ScarfProps'
import Flex from '../../Flex'
import * as Icons from '../../Icons'
import { Caption } from '../../Text'
import View from '../../View'
import mapFormToProps from '../mapFormToProps'
import DashedContainer from './DashedContainer'
import SingleEntitySelector, {
  EntityItem,
  SingleEntitySelectorProps,
} from './SingleEntitySelector'

const Container = styled(View)`
  :focus {
    outline: none;
  }
`

export type EntitySelectorProps = Merge<
  ScarfProps,
  {
    types: SingleEntitySelectorProps['types']
    value?: EntityItem[]
    onChange: (value: EntityItem[]) => void
  }
>

function EntitySelector({
  types,
  onChange,
  value = [],
  onFocus,
  onBlur,
  ...rest
}: EntitySelectorProps) {
  const handleChange = (entity: EntityItem | undefined, index: number) => {
    if (!entity || entity.data) {
      onFocus && onFocus()
    }
    if (entity) {
      onChange([...value.slice(0, index), entity, ...value.slice(index + 1)])
    } else {
      onChange([...value.slice(0, index), ...value.slice(index + 1)])
    }

    if (!entity || entity.data) {
      onBlur && onBlur()
    }
  }

  const hasAddItem = value[value.length - 1]?.id || value.length === 0

  return (
    <Container {...rest}>
      {value.map?.((entity, i) => (
        <SingleEntitySelector
          key={`${entity.type ? entity.type : 'new'}${entity.id || ''}`}
          types={types}
          onChange={(e) => handleChange(e, i)}
          value={entity}
          disabledValues={
            value
              .filter((v) => v.type === entity.type && v.id !== entity.id)
              .map((v) => v.id) as (string | number)[]
          }
          // eslint-disable-next-line no-unsafe-optional-chaining
          mb={i !== value?.length - 1 || hasAddItem ? 2 : undefined}
        />
      ))}
      {hasAddItem && (
        <DashedContainer width="100%" height="40px">
          <Flex
            height="100%"
            justifyContent="center"
            onClick={() => {
              onFocus && onFocus()
              onChange([...value, {}])
              onBlur && onBlur()
            }}
            cursor="pointer"
            _hover={{
              bg: 'sky.3',
            }}
          >
            <Icons.Plus light mb="2px" height="18px" fill="sky.0" />
            <Caption color="sky.0" fontSize={['16px', '14px']}>
              Add link
            </Caption>
          </Flex>
        </DashedContainer>
      )}
    </Container>
  )
}

EntitySelector.propTypes = {
  types: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      label: PropTypes.string,
      renderItem: PropTypes.func,
      loader: PropTypes.func,
      placeholder: PropTypes.string,
      disabledValues: PropTypes.array,
    })
  ),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

export default mapFormToProps(EntitySelector)
