import { Button, usePromise } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import { useTranslation } from 'react-i18next'
import { useTerminalPayment } from 'resources/TerminalPayment'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as CardBlue } from 'components/checkout-payment/assets/card-blue.svg'

function Authorised() {
  const { t } = useTranslation()

  const { data, resource } = useTerminalPayment()

  const [triggerCapture, captureState] = usePromise(
    resource?.capturePayment || (() => {})
  )
  const [triggerCancel, cancelState] = usePromise(
    resource?.cancel || (() => {})
  )

  return (
    <ActionCard>
      <ActionCard.Header>
        <CardBlue className="h-6 w-6" />
        {t('Authorised')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t(
          'The card was read successfully and the payment is now authorised, but has not been captured yet.'
        )}
      </ActionCard.Body>
      <ActionCard.Actions>
        <Button
          sizeVariant="sm"
          onClick={triggerCapture}
          loading={captureState.pending}
        >
          {t('Charge payment ({{amount}})', { amount: format(data?.amount) })}
        </Button>
        <Button
          sizeVariant="sm"
          variant="secondary"
          onClick={triggerCancel}
          loading={cancelState.pending}
        >
          {t('Cancel')}
        </Button>
      </ActionCard.Actions>
      {(captureState.error || cancelState.error) && (
        <ActionCard.Error>
          {t('There was an error, please try again later.')}
        </ActionCard.Error>
      )}
    </ActionCard>
  )
}

export default Authorised
