import { useCallback, useMemo } from 'react'
import useSearchQueryState from './useSearchQueryState'

function useJSONQueryStringReducer(key, reducer, initialValue) {
  const [jsonString, setJsonString] = useSearchQueryState(
    key,
    JSON.stringify(initialValue)
  )
  const state = useMemo(() => {
    try {
      return JSON.parse(jsonString)
    } catch (e) {
      return {}
    }
  }, [jsonString])

  const dispatch = useCallback(
    (action) => {
      const newState = reducer(state, action)
      setJsonString(JSON.stringify(newState))
    },
    [reducer, state, setJsonString]
  )

  return [state, dispatch]
}

export default useJSONQueryStringReducer
