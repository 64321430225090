import { Button, DataTable, Flex, GridTable, Icons, Title } from '@tabeo/scarf'
import Analytics from 'components/Analytics'
import { clinicianSort } from 'components/MerchantClinicianSelect'
import { treatmentSort } from 'components/MerchantTreatmentSelect'
import PricingChanges from 'components/PricingChanges'
import TermsUpdate from 'components/TermsUpdate'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useMerchant } from 'resources/Merchant'
import { useOffers } from 'resources/Offers'
import {
  LOST_STATUSES,
  OPEN_STATUSES,
  WON_STATUSES,
} from 'resources/normalizers/offerStatusGroups'
import formatClinicianName from 'utils/formatClinicianName'
import Layout from './Layout'
import ListItem from './ListItem'

function mapStateToParams(state) {
  const { activeFilters, activeTabs } = state

  const clinicianIds = activeFilters?.clinician?.value?.map(t => t.value)
  const treatmentIds = activeFilters?.treatment?.value?.map(t => t.value)

  const status = activeTabs.reduce((acc, curr) => {
    return [...acc, ...curr.statuses]
  }, [])

  return {
    ...state,
    conditions: {
      treatments: treatmentIds?.length ? [{ id: treatmentIds }] : undefined,
      clinicians: clinicianIds?.length ? [{ id: clinicianIds }] : undefined,
      status,
    },
  }
}

const Transactions = () => {
  const { t } = useTranslation()
  const { data: merchant } = useMerchant()

  const analyticsTabs = [
    {
      label: t('All'),
      endpoint: '/merchant/analytics/all',
      responseDataPath: 'allProducts',
    },
    {
      label: t('Finance'),
      endpoint: '/merchant/analytics/pay-over-time',
      responseDataPath: 'payOverTimeAnalytics',
    },
    {
      label: t('Card'),
      endpoint: '/merchant/analytics/pay-now',
      responseDataPath: 'payNowAnalytics',
    },
  ]

  const tabs = [
    { label: t('All'), statuses: [], group: 'singleSelect' },
    {
      label: t('Follow up'),
      statuses: OPEN_STATUSES,
    },
    merchant.isMerchantConfirmationFlow
      ? {
          label: t('Ready to confirm'),
          statuses: ['loan_agreement_accepted', 'first_payment_capture_failed'],
        }
      : false,
    merchant.flags.is_fulfillment_required
      ? {
          label: t('Confirm treatment'),
          statuses: ['fulfillment_required'],
        }
      : false,
    { label: t('Won'), statuses: WON_STATUSES },
    { label: t('Lost'), statuses: LOST_STATUSES },
  ].filter(Boolean)

  const filters = [
    {
      name: 'treatment',
      label: t('Filter treatment'),
      loadOptions: query =>
        merchant?.merchantTreatments
          .sort(treatmentSort)
          .map(({ treatment }) => ({
            value: treatment.id,
            label: treatment.name,
          }))
          .filter(({ label }) =>
            label.toLowerCase().includes(query.toLowerCase())
          ),
      initialValue: [],
      filtering: true,
    },
    {
      name: 'clinician',
      label: t('Filter clinician'),
      loadOptions: query =>
        merchant?.merchantClinicians
          .sort(clinicianSort)
          .map(mc => ({
            value: mc.clinician.id,
            label: formatClinicianName(mc),
          }))
          .filter(({ label }) =>
            label.toLowerCase().includes(query.toLowerCase())
          ),
      initialValue: [],
      filtering: true,
    },
  ]

  const isCliniciansTreatmentsEnabled =
    merchant?.flags?.is_clinicians_treatments_enabled

  return (
    <Layout>
      <PricingChanges />
      <TermsUpdate />
      <Flex mb={4}>
        <Title>{t('Transactions')}</Title>
        <Button
          data-testid="new-transaction"
          variant="secondary"
          as={Link}
          to="/new-transaction"
          leftIcon={p => <Icons.Plus light {...p} />}
        >
          {t('New transaction')}
        </Button>
      </Flex>
      <Analytics tabs={analyticsTabs} initialIndex={0} mb={6} />
      <DataTable
        data-testid="transactions-table"
        mapStateToParams={mapStateToParams}
        filters={isCliniciansTreatmentsEnabled ? filters : undefined}
        resourceHook={useOffers}
        tabs={tabs}
        paginationLimit={20}
        searchPlaceholder={t('Search by name or id')}
      >
        {({ items }) => (
          <GridTable
            templateColumns={[
              'minmax(65px,auto) minmax(140px, 1fr) minmax(140px, 1fr) auto auto',
            ]}
            minWidth="650px"
          >
            {items.map(item => (
              <ListItem key={item.id} data={item} />
            ))}
          </GridTable>
        )}
      </DataTable>
    </Layout>
  )
}

export default Transactions
