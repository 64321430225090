import { DefaultTheme } from 'styled-components'
import colors from './colors'

const breakpoints = ['960px']
const maxWidths = breakpoints

const space = [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40]

const fontSizes = [12, 14, 16, 18, 22, 26, 28]

const lineHeights = [1, 1.125, 1.25, 1.5]

const fontWeights = {
  light: 300,
  regular: 400,
  normal: 500,
  semibold: 500,
}

const letterSpacings = {
  normal: 'normal',
  medium: '0.5px',
}

const radii = [0, '4px']

const borders = [0, `1px solid ${colors.sky[1]}`]

const shadows = [
  `0 6px 5px -4px rgba(0, 0, 0, 0.2)`,
  `0 4px 8px -4px rgba(0, 0, 0, 0.2)`,
  `0 4px 3px -4px rgba(0, 0, 0, 0.2)`,
]

const font = `Roboto, -apple-system, BlinkMacSystemFont, sans-serif`
const monospace = '"SF Mono", "Roboto Mono", Menlo, monospace'

declare module 'styled-components' {
  export interface DefaultTheme {
    font: typeof font
    monospace: typeof monospace
    breakpoints: string[]
    colors: typeof colors
    space: typeof space
    fontSizes: typeof fontSizes
    lineHeights: typeof lineHeights
    fontWeights: typeof fontWeights
    letterSpacings: typeof letterSpacings
    radii: typeof radii
    borders: typeof borders
    shadows: typeof shadows
    maxWidths: typeof maxWidths
  }
}

const theme: DefaultTheme = {
  font,
  monospace,
  breakpoints,
  colors,
  space,
  fontSizes,
  lineHeights,
  fontWeights,
  letterSpacings,
  radii,
  borders,
  shadows,
  maxWidths,
}

export default theme
