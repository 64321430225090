import { Subtitle } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import Tally from 'components/Tally'
import { useTranslation } from 'react-i18next'
import { NormalizedTerminalPayment } from 'types/NormalizedTerminalPayment'

type SummaryProps = { data?: NormalizedTerminalPayment } & React.ComponentProps<
  typeof Tally
>

function Summary({ data, ...rest }: SummaryProps) {
  const { t } = useTranslation()

  return (
    <>
      <Tally {...rest}>
        <Tally.Item>
          <Subtitle fontWeight="semibold">{t('Total')}</Subtitle>
          <Subtitle fontWeight="semibold">{format(data?.amount)}</Subtitle>
        </Tally.Item>
      </Tally>
    </>
  )
}

export default Summary
