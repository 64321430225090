'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var i18n = _interopDefault(require('i18next'));

var deDE = {
	"Email address is not valid": "Ungültige E-Mail-Adresse",
	"Field is required": "Pflichtfeld",
	"Field must be a number": "Feld muss eine Zahl enthalten",
	"Invalid date": "Ungültiges Datum",
	"Invalid email address format": "Ungültiges Format E-Mail-Adresse",
	"Invalid input": "Eingabe ungültig",
	"Invalid name format": "Ungültiges Format Name",
	"Invalid password": "Passwort ungültig",
	"Invalid phone number format": "Ungültiges Format Telefonnummer",
	"Length must be between {{min}} and {{max}} characters": "Länge muss zwischen {{min}} und {{max}} Zeichen liegen",
	"Must be {{max}} characters or less": "Darf aus höchstens {{max}} Zeichen bestehen",
	"Must be {{min}} characters or more": "Muss aus mindestens {{min}} Zeichen bestehen",
	"Must be {{target}} characters": "Muss aus {{target}} Zeichen bestehen",
	"Must be a mobile number": "Muss eine Mobiltelefonnummer sein",
	"Must be at least or equat to {{min}}": "Muss größer oder gleich {{min}} sein",
	"Must be between {{min}} and {{max}}": "Muss zwischen {{min}} und {{max}} liegen",
	"Must be less or equal to {{max}}": "Muss kleiner oder gleich {{max}} sein",
	"Phone number is not valid": "Telefonnummer ist ungültig",
	"There was a problem with your payment. Please do not re-attempt the payment as this may cause you to be charged twice. Please send a message to our support team at https://app.tabeo.co.uk/support.": "Es ist ein Problem bei Ihrer Zahlung aufgetreten. Bitte versuchen Sie nicht, die Zahlung erneut in die Wege zu leiten. Der Betrag könnte sonst zwei Mal abgebucht werden. Bitte schicken Sie eine Nachricht an unser Support-Team unter https://app.tabeo.de/support.",
	"There was an error. Please try again later.": "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
	"You must be over {{min}} to continue": "Sie müssen mindestens {{min}} Jahre alt sein, um fortfahren zu können",
	"You must be under {{max}} {{unit}} old to continue": "Sie müssen unter {{max}} {{unit}} alt sein, um fortfahren zu können",
	"Your card was declined, please try again or use a different card.": "Ihre Karte wurde abgelehnt, bitte versuchen Sie es erneut oder verwenden Sie eine andere Karte.",
	"Downloading...": "Wird heruntergeladen..."
};

var enGb = {
	"Downloading...": "Downloading...",
	"Email address is not valid": "Email address is not valid",
	"Field is required": "Field is required",
	"Field must be a number": "Field must be a number",
	"Invalid date": "Invalid date",
	"Invalid email address format": "Invalid email address format",
	"Invalid input": "Invalid input",
	"Invalid name format": "Invalid name format",
	"Invalid password": "Invalid password",
	"Invalid phone number format": "Invalid phone number format",
	"Length must be between {{min}} and {{max}} characters": "Length must be between {{min}} and {{max}} characters",
	"Must be {{max}} characters or less": "Must be {{max}} characters or less",
	"Must be {{min}} characters or more": "Must be {{min}} characters or more",
	"Must be {{target}} characters": "Must be {{target}} characters",
	"Must be a mobile number": "Must be a mobile number",
	"Must be at least or equat to {{min}}": "Must be at least or equal to {{min}}",
	"Must be between {{min}} and {{max}}": "Must be between {{min}} and {{max}}",
	"Must be less or equal to {{max}}": "Must be less or equal to {{max}}",
	"Phone number is not valid": "Phone number is not valid",
	"There was a problem with your payment. Please do not re-attempt the payment as this may cause you to be charged twice. Please send a message to our support team at https://app.tabeo.co.uk/support.": "There was a problem with your payment. Please do not re-attempt the payment as this may cause you to be charged twice. Please send a message to our support team at https://app.tabeo.co.uk/support.",
	"There was an error. Please try again later.": "There was an error. Please try again later.",
	"You must be over {{min}} to continue": "You must be over {{min}} to continue",
	"You must be under {{max}} {{unit}} old to continue": "You must be under {{max}} {{unit}} old to continue",
	"Your card was declined, please try again or use a different card.": "Your card was declined, please try again or use a different card."
};

var esES = {
	"Email address is not valid": "La dirección de correo electrónico no es válida",
	"Field is required": "El campo es obligatorio",
	"Field must be a number": "El campo debe ser un número",
	"Invalid date": "Fecha no válida",
	"Invalid email address format": "Formato de dirección de correo electrónico no válido",
	"Invalid input": "Dato no válido",
	"Invalid name format": "Formato de nombre no válido",
	"Invalid password": "Contraseña no válida",
	"Invalid phone number format": "Formato de número de teléfono no válido",
	"Length must be between {{min}} and {{max}} characters": "Debe tener entre {{min}} y {{max}} caracteres",
	"Must be {{max}} characters or less": "Debe tener {{max}} caracteres o menos",
	"Must be {{min}} characters or more": "Debe tener {{min}} caracteres o más",
	"Must be {{target}} characters": "Debe tener {{target}} caracteres",
	"Must be a mobile number": "Debe ser un número de móvil",
	"Must be at least or equat to {{min}}": "Debe ser mayor o igual que {{min}}",
	"Must be between {{min}} and {{max}}": "Debe estar entre {{min}} y {{max}}",
	"Must be less or equal to {{max}}": "Debe ser menor o igual que {{max}}",
	"Phone number is not valid": "El número de teléfono no es válido",
	"There was a problem with your payment. Please do not re-attempt the payment as this may cause you to be charged twice. Please send a message to our support team at https://app.tabeo.co.uk/support.": "Ha habido un problema con su pago. No vuelva a intentar el pago porque podría cobrársele dos veces. Envíe un mensaje a nuestro equipo de soporte en https://app.tabeo.es/support.",
	"There was an error. Please try again later.": "Ha habido un error. Vuelva a intentarlo más tarde.",
	"You must be over {{min}} to continue": "Debe tener al menos {{min}} años para continuar",
	"You must be under {{max}} {{unit}} old to continue": "Debe tener menos de {{max}} {{unit}} para continuar",
	"Your card was declined, please try again or use a different card.": "Se ha rechazado su tarjeta; vuelva a intentarlo o utilice otra tarjeta.",
	"Downloading...": "Descargando..."
};

var frFR = {
	"Downloading...": "Téléchargement...",
	"Email address is not valid": "l'adresse email n'est pas valide",
	"Field is required": "Le champ est obligatoire",
	"Field must be a number": "Le champ doit contenir un nombre",
	"Invalid date": "Date invalide",
	"Invalid email address format": "Format d'adresse e-mail invalide",
	"Invalid input": "Entrée invalide",
	"Invalid name format": "Format de nom invalide",
	"Invalid password": "Mot de passe invalide",
	"Invalid phone number format": "Format de numéro de téléphone invalide",
	"Length must be between {{min}} and {{max}} characters": "La longueur doit être comprise entre {{min}} et {{max}} caractères",
	"Must be {{max}} characters or less": "Doit comporter {{max}} caractères ou moins",
	"Must be {{min}} characters or more": "Doit comporter {{min}} caractères ou plus",
	"Must be {{target}} characters": "Doit comporter {{target}} caractères",
	"Must be a mobile number": "Doit être un numéro de téléphone mobile",
	"Must be at least or equat to {{min}}": "Doit être supérieur ou égal à {{min}}",
	"Must be between {{min}} and {{max}}": "Doit être compris entre {{min}} et {{max}}",
	"Must be less or equal to {{max}}": "Doit être inférieur ou égal à {{max}}",
	"Phone number is not valid": "Le numéro de téléphone est invalide",
	"There was a problem with your payment. Please do not re-attempt the payment as this may cause you to be charged twice. Please send a message to our support team at https://app.tabeo.co.uk/support.": "Si è verificato un problema con il pagamento. Non tentare nuovamente il pagamento in quanto ciò potrebbe comportare un addebito doppio. Invia un messaggio al nostro team di supporto all'indirizzo indicato in https://app.tabeo.it/support.",
	"There was an error. Please try again later.": "Si è verificato un errore. Riprova più tardi.",
	"You must be over {{min}} to continue": "Vous devez avoir plus de {{min}} pour continuer",
	"You must be under {{max}} {{unit}} old to continue": "Vous devez avoir moins de {{max}} {{unit}} ans pour continuer",
	"Your card was declined, please try again or use a different card.": "La tua carta è stata rifiutata: riprova o utilizza un'altra carta."
};

var itIT = {
	"Downloading...": "Download in corso...",
	"Email address is not valid": "Questo indirizzo email non è valido",
	"Field is required": "Questo campo è obbligatorio",
	"Field must be a number": "Questo deve essere un numero",
	"Invalid date": "Questa data non è valida",
	"Invalid email address format": "Il formato di questo indirizzo e-mail non è valido",
	"Invalid input": "Risposta non valida",
	"Invalid name format": "Formato del nome non valido",
	"Invalid password": "La tua password non è valida",
	"Invalid phone number format": "Il formato di questo numero telefonico non è valido",
	"Length must be between {{min}} and {{max}} characters": "La lunghezza deve essere compresa fra i {{min}} e i {{max}} caratteri",
	"Must be {{max}} characters or less": "Questo non deve contenere più di {{max}} caratteri",
	"Must be {{min}} characters or more": "Questo deve contenere almeno {{min}} caratteri",
	"Must be {{target}} characters": "Questo deve contenere esattamente {{target}} caratteri",
	"Must be a mobile number": "Questo deve essere un numero di cellulare",
	"Must be at least or equat to {{min}}": "Questo deve essere maggiore o uguale a {{min}}",
	"Must be between {{min}} and {{max}}": "Questo deve essere compreso fra {{min}} e {{max}}",
	"Must be less or equal to {{max}}": "Questo deve essere minore o uguale a {{max}}",
	"Phone number is not valid": "Questo numero di telefono non è valido",
	"There was a problem with your payment. Please do not re-attempt the payment as this may cause you to be charged twice. Please send a message to our support team at https://app.tabeo.co.uk/support.": "Si è verificato un problema con il tuo pagamento. Ti preghiamo di contattare l'assistenza clienti presso https://app.tabeo.it/support. Ti sconsigliamo di ritentare il pagamento in quanto ciò potrebbe causare un doppio addebito.",
	"There was an error. Please try again later.": "Si è verificato un problema, ti preghiamo di riprovare più tardi.",
	"You must be over {{min}} to continue": "Il servizio non è disponiile per i minori di {{min}}",
	"You must be under {{max}} {{unit}} old to continue": "Ci scusiamo, ma il servizio non è disponibile per maggiori di {{max}} {{unit}}",
	"Your card was declined, please try again or use a different card.": "La tua carta è stata rifiutata, ti preghiamo di riprovare con un'altra carta."
};

var nlNL = {
	"Downloading...": "Downloaden...",
	"Email address is not valid": "e-mailadres is niet geldig",
	"Field is required": "Veld is verplicht",
	"Field must be a number": "Veld moet een cijfer zijn",
	"Invalid date": "Datum ongeldig",
	"Invalid email address format": "E-mailadres ongeldig",
	"Invalid input": "Invoer ongeldig",
	"Invalid name format": "Naam ongeldig",
	"Invalid password": "Wachtwoord ongeldig",
	"Invalid phone number format": "Telefoonnummer ongeldig",
	"Length must be between {{min}} and {{max}} characters": "Lengte moet tussen {{min}} en {{max}} tekens zijn",
	"Must be {{max}} characters or less": "Moet {{max}} tekens of minder zijn",
	"Must be {{min}} characters or more": "Moet {{min}} tekens of meer zijn",
	"Must be {{target}} characters": "Moet {{target}} tekens zijn",
	"Must be a mobile number": "Moet een mobiel nummer zijn",
	"Must be at least or equat to {{min}}": "Moet ten minste gelijk zijn aan {{min}}",
	"Must be between {{min}} and {{max}}": "Moet tussen {{min}} en {{max}} zijn",
	"Must be less or equal to {{max}}": "Moet kleiner of gelijk zijn aan {{max}}",
	"Phone number is not valid": "Telefoonnummer is ongeldig",
	"There was a problem with your payment. Please do not re-attempt the payment as this may cause you to be charged twice. Please send a message to our support team at https://app.tabeo.co.uk/support.": "Er ging iets niet goed met uw betaling. Probeer de betaling niet opnieuw uit te voeren, aangezien dit ertoe kan leiden dat het bedrag tweemaal wordt afgeschreven. Stuur een bericht naar ons ondersteuningsteam op https://app.tabeo.nl/support.",
	"There was an error. Please try again later.": "Er is een fout opgetreden. Probeer het later nog eens.",
	"You must be over {{min}} to continue": "Je moet ouder zijn dan {{min}} om door te gaan",
	"You must be under {{max}} {{unit}} old to continue": "Je moet jonger zijn dan {{max}} {{unit}} om door te gaan",
	"Your card was declined, please try again or use a different card.": "Uw kaart werd geweigerd, probeer het opnieuw of gebruik een andere kaart."
};

if (!i18n.isInitialized) {
  i18n.init({
    fallbackLng: 'en-GB',
    supportedLngs: ['en', 'en-GB', 'nl', 'nl-NL', 'it', 'it-IT', 'fr', 'fr-FR', 'es', 'es-ES', 'de', 'de-DE'],
    interpolation: {
      escapeValue: false
    },
    keySeparator: false,
    nsSeparator: false,
    debug: false,
    /**
     * React isn't used in the package
     * Just to keep compatiblity if this init runs earlier than app or scarf init
     */
    react: {
      useSuspense: false,
      transKeepBasicHtmlNodesFor: []
    },
    initImmediate: false
  });
}
if (i18n.isInitialized) {
  i18n.addResourceBundle('en-GB', 'translation', enGb, true, true);
  i18n.addResourceBundle('nl-NL', 'translation', nlNL, true, true);
  i18n.addResourceBundle('it-IT', 'translation', itIT, true, true);
  i18n.addResourceBundle('fr-FR', 'translation', frFR, true, true);
  i18n.addResourceBundle('es-ES', 'translation', esES, true, true);
  i18n.addResourceBundle('de-DE', 'translation', deDE, true, true);
}
var t = i18n.t.bind(i18n);

exports.default = i18n;
exports.t = t;
