import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import Flex from '../../Flex'

const Unselected = ({ fillColor = '#919EAB' }) => (
  <svg width="22" height="22" viewBox="0 0 22 22">
    <defs>
      <rect id="a" width="22" height="22" rx="11" />
      <filter
        id="b"
        width="109.1%"
        height="109.1%"
        x="-4.5%"
        y="-4.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dx="-2" dy="2" in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#FFF" xlinkHref="#a" />
      <use fill="#000" filter="url(#b)" xlinkHref="#a" />
      <rect width="21" height="21" x=".5" y=".5" stroke={fillColor} rx="10.5" />
    </g>
  </svg>
)

Unselected.propTypes = {
  fillColor: PropTypes.string,
}

const Selected = ({ fillColor = '#2C9FA5' }) => (
  <svg width="22" height="22" viewBox="0 0 22 22">
    <g fill={fillColor}>
      <path d="M11,22 C4.92486775,22 0,17.0751322 0,11 C0,4.92486775 4.92486775,0 11,0 C17.0751322,0 22,4.92486775 22,11 C22,17.0751322 17.0751322,22 11,22 Z M14.8669595,7.42408153 L9.49717816,12.5790717 L7.56868463,10.8258957 C7.11916125,10.4172381 6.4234681,10.4503664 6.01481048,10.8998897 C5.60615286,11.3494131 5.63928111,12.0451063 6.08880449,12.4537639 L8.77769337,14.8982083 C9.20575491,15.2873552 9.86208984,15.2784358 10.2794185,14.8778003 L16.3905296,9.01113364 C16.8287819,8.59041139 16.8429929,7.89407491 16.4222706,7.45582257 C16.0015484,7.01757024 15.3052119,7.00335929 14.8669595,7.42408153 Z" />
    </g>
  </svg>
)

Selected.propTypes = {
  fillColor: PropTypes.string,
}

const Dot = ({ selected, disabled, error, ...rest }) => {
  const primary = themeGet('colors.default.primary')(rest)
  const grey = themeGet('colors.sky.0')(rest)
  const red = themeGet('colors.default.red')(rest)
  const fillColor = disabled ? grey : error ? red : primary
  return (
    <Flex {...rest}>
      {selected ? (
        <Selected fillColor={fillColor} />
      ) : (
        <Unselected fillColor={fillColor} />
      )}
    </Flex>
  )
}

Dot.propTypes = {
  selected: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default styled(withTheme(Dot))``
