import PropTypes from 'prop-types'
import React, {
  JSXElementConstructor,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import useJSONQueryStringReducer from '../../hooks/useJSONQueryStringReducer'
import { Merge } from '../../types/helpers'
import ScarfProps from '../../types/ScarfProps'
import debounce from '../../utils/debounce'
import Flex from '../Flex'
import View from '../View'
import ActiveFilters from './ActiveFilters'
import dataTableCtx, { Action, Dispatch, State } from './context'
import FilterSelect, {
  CustomFilterWrapper,
  FilterSelectProps,
} from './FilterSelect'
import GridTableRow, { SelectableGridTableRow } from './GridTableRow'
import Head from './Head'
import List, { ListProps } from './List'
import Loader from './Loader'
import Pagination from './Pagination'
import Search from './Search'
import SelectionActions from './SelectionActions'
import SelectionBulkToggleButton from './SelectionBulkToggleButton'
import Tabs from './Tabs'

const Container = styled(View)`
  overflow: hidden;
`

const Controls = styled(View)`
  position: relative;
`

export type DataTableProps = Merge<
  ScarfProps,
  {
    tabs?: { label: string; group?: string; [key: string]: any }[]
    filters?: Omit<
      FilterSelectProps & {
        initialMode?: FilterSelectProps['mode']
        initialValue?: any
        component?: JSXElementConstructor<any>
      },
      'value' | 'mode' | 'onChange'
    >[]
    mapStateToParams?: (state: State & { limit?: number }) => any
    itemsFilter?: (item: any, state: State) => any
    resourceHook: (args: any[]) => {
      data?: { items: any[]; totalCount: number; [key: string]: any } | null
      error?: any | null
      resource?: any | null
    }
    searchPlaceholder?: string
    paginationLimit?: number
    id?: string
    renderEmpty?: ListProps['renderEmpty']
    initialState?: State
    onStateChange?: (state: State, dispatch: Dispatch) => any
    emptyWrapperProps?: ListProps['emptyWrapperProps']
    children: ListProps['renderTable']
  }
>

function DataTable({
  tabs = [],
  filters = [],
  mapStateToParams = (s) => s,
  itemsFilter = (i) => i,
  resourceHook: useResourceHook,
  searchPlaceholder,
  paginationLimit = 10,
  id = '',
  children,
  renderEmpty,
  initialState,
  onStateChange,
  emptyWrapperProps,
  ...rest
}: DataTableProps) {
  const mergedInitialState = useMemo(
    () => ({
      activeTabIndexes: [0],
      activeTabs: tabs.length ? [tabs[0]] : [],
      activeFilters: {},
      searchQuery: '',
      offset: 0,
      orderBy: {},
      selected: [],
      metadata: {},
      ...initialState,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabs]
  )

  const lastStateChange = useRef<Action>()
  const reducer = useCallback(
    (state: State, { type, payload }: Action) => {
      lastStateChange.current = { type, payload }
      switch (type) {
        case 'tabChange': {
          const tab = tabs[payload]
          const currentTabGroup = tab.group
          const sameGroupTabs = state.activeTabIndexes?.filter(
            (index) => tabs[index].group === currentTabGroup
          )
          const updatedIndexes = state.activeTabIndexes?.includes(payload)
            ? sameGroupTabs?.filter((i) => payload !== i)
            : [...(sameGroupTabs || []), payload]
          // Prevent the last active tab to be removed
          if (!updatedIndexes?.length) {
            return state
          }
          return {
            ...state,
            offset: mergedInitialState.offset,
            activeTabIndexes: updatedIndexes,
            activeTabs: tabs.filter((t, tabIndex) =>
              updatedIndexes?.includes(tabIndex)
            ),
            selected: [],
          }
        }
        case 'filterChange':
          return {
            ...state,
            offset: mergedInitialState.offset,
            activeFilters: {
              ...state.activeFilters,
              [payload.name]: {
                value: payload.value,
                mode: payload.mode || state.activeFilters?.[payload.name]?.mode,
              },
            },
            selected: [],
          }
        case 'search':
          return {
            ...state,
            offset: mergedInitialState.offset,
            searchQuery: payload,
            selected: [],
          }
        case 'paginate':
          return {
            ...state,
            offset: payload,
            selected: [],
          }
        case 'orderByChange':
          return {
            ...state,
            orderBy: payload,
            selected: [],
          }
        case 'selectedChange':
          return {
            ...state,
            selected: payload,
          }
        case 'setMetadata':
          return {
            ...state,
            metadata: payload,
          }
        case 'updateMetadata':
          return {
            ...state,
            metadata: {
              ...state.metadata,
              ...payload,
            },
          }
        default:
          return state
      }
    },
    [mergedInitialState, tabs]
  )

  const [state, dispatch] = useJSONQueryStringReducer(
    `datatable${id ? `-${id}` : ''}`,
    reducer,
    mergedInitialState
  )

  // Implement onStateChange
  const stateHash = JSON.stringify(state)
  useEffect(() => {
    if (lastStateChange.current) {
      onStateChange?.(lastStateChange.current, dispatch)
    }
  }, [stateHash, onStateChange, dispatch])

  const {
    data,
    error,
    resource = {},
  } = useResourceHook(
    mapStateToParams({
      limit: paginationLimit,
      ...state,
      // Todo: We might want to pass this later, but needs migration
      // selected: undefined,
    })
  )

  const { items, totalCount } = data || {}

  const filteredItems = items?.filter((i) => itemsFilter(i, state)) || null

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((query: string) => {
      dispatch({ type: 'search', payload: query })
    }, 400),
    [dispatch]
  )

  const [searchInputValue, setSearchInputValue] = useState(state.searchQuery)

  if (error) {
    throw error
  }

  return (
    <dataTableCtx.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        dispatch,
        resource,
        items: filteredItems,
      }}
    >
      <Container bg="white" borderRadius={1} border={1} boxShadow={1} {...rest}>
        <Controls
          pt={tabs.length ? 2 : searchPlaceholder ? 5 : 0}
          pb={tabs.length || searchPlaceholder ? 3 : 0}
        >
          <Loader loading={!filteredItems} />
          {tabs.length ? (
            <Tabs
              labels={tabs.map((s) => s.label)}
              onClick={(index) => {
                dispatch({ type: 'tabChange', payload: index })
              }}
              activeIndexes={state.activeTabIndexes}
            />
          ) : null}
          {(searchPlaceholder || !!filters.length) && (
            <Flex
              mt={tabs.length ? 2 : 0}
              border={[0, 1]}
              borderRadius={[0, 1]}
              mx={4}
              flexDirection={['column', 'row']}
              overflow="hidden"
            >
              {searchPlaceholder && (
                <Search
                  onChange={(query: string) => {
                    setSearchInputValue(query)
                    handleSearch(query)
                  }}
                  value={searchInputValue}
                  placeholder={searchPlaceholder}
                  bg="sky.3"
                  border={[1, 0]}
                  borderRadius={[1, 0]}
                  width="100%"
                />
              )}
              {!!filters.length && (
                <Flex
                  flexDirection={['column', 'row']}
                  width={['100%', 'unset']}
                  border={[1, 0]}
                  borderRadius={[1, 0]}
                  overflow="hidden"
                  bg={['white', 'sky.3']}
                  mt={[4, 0]}
                >
                  {filters.map(
                    ({ initialValue, initialMode, component, ...filter }) => {
                      const Component = component || FilterSelect
                      const value =
                        state.activeFilters?.[filter.name]?.value ||
                        initialValue
                      const mode =
                        state.activeFilters?.[filter.name]?.mode || initialMode
                      return (
                        <Component
                          key={filter.name}
                          {...filter}
                          value={value}
                          mode={component ? mode : undefined}
                          onChange={(change: any) => {
                            dispatch({ type: 'filterChange', payload: change })
                          }}
                          borderLeft={[0, 1]}
                          _notLast={{
                            borderBottom: [1, 0],
                          }}
                        />
                      )
                    }
                  )}
                </Flex>
              )}
            </Flex>
          )}
          {!!filters.length && (
            <ActiveFilters
              mx={4}
              filters={filters}
              values={state.activeFilters}
              onChange={(change: any) =>
                dispatch({ type: 'filterChange', payload: change })
              }
            />
          )}
        </Controls>
        <List
          state={state}
          dispatch={dispatch}
          items={filteredItems}
          renderTable={children}
          renderEmpty={renderEmpty}
          resource={resource}
          emptyWrapperProps={emptyWrapperProps}
        />
        <Pagination
          limit={paginationLimit}
          offset={state.offset}
          items={filteredItems}
          totalCount={totalCount || 0}
          onChange={(offset) => dispatch({ type: 'paginate', payload: offset })}
        />
      </Container>
    </dataTableCtx.Provider>
  )
}

DataTable.propTypes = {
  resourceHook: PropTypes.func.isRequired,
  tabs: PropTypes.array,
  filters: PropTypes.array,
  searchPlaceholder: PropTypes.string,
  paginationLimit: PropTypes.number,
  mapStateToParams: PropTypes.func,
  itemsFilter: PropTypes.func,
  id: PropTypes.string,
  children: PropTypes.func,
  renderEmpty: PropTypes.func,
  initialState: PropTypes.object,
  onStateChange: PropTypes.func,
  emptyWrapperProps: PropTypes.any,
}

DataTable.Head = Head
DataTable.GridTableRow = GridTableRow
DataTable.SelectionActions = SelectionActions
DataTable.SelectionBulkToggleButton = SelectionBulkToggleButton
DataTable.SelectableGridTableRow = SelectableGridTableRow
DataTable.CustomFilterWrapper = CustomFilterWrapper

export default DataTable
