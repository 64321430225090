import React from 'react'
import Svg, { G, SvgProps } from './Base'

function AddAssignee({ fill, ...props }: SvgProps) {
  return (
    <Svg {...props}>
      <G
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={fill}
      >
        <path d="M11.814 15.027v-1.01c0-1.114-.933-2.017-2.083-2.017H6.084C4.933 12 4 12.903 4 14.018v1.009" />
        <circle cx={7.907} cy={8.783} r={1.783} />
        <path d="M15.493 9v4.755M17.871 11.377h-4.755" />
      </G>
    </Svg>
  )
}

export default AddAssignee
