import { Caption, Flex, Heading, Progressbar, View } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import Card from 'components/Transaction/ActionPanel/Card'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import icon from './assets/payout_on_the_way.svg'
import InvoiceButton from './InvoiceButton'
import RefundButton from './Refund'

function PaymentOnTheWay({
  transaction,

  merchantBankDetails,
  ...rest
}) {
  const { data } = transaction
  const {
    sumMerchantNetProceedsTransfers,
    confirmedDate,
    merchantPayoutAt,
    consumerFirstName,
    isCoolingOffPeriodWaived,
    coolingOffPeriodEnds,
    isPN,
    consumerHisHer,
    metadata: { treatmentStartDate },
  } = data

  const {
    data: {
      flags: { is_cooling_off_period_capable: isCoolingOffPeriodCapable },
    },
  } = useMerchant()
  const { t } = useTranslation()

  const confirmedMoment = moment(confirmedDate)
  const arrivalMoment = moment(merchantPayoutAt)
  const now = moment()
  const totalDuration =
    moment.duration(arrivalMoment.diff(confirmedMoment)).asSeconds() + 20
  const ellapsed = moment.duration(now.diff(confirmedMoment)).asSeconds()
  const percent = (ellapsed / totalDuration) * 100
  const accountNumber = merchantBankDetails.accountNumber.slice(-3)

  const getCoolingOffText = () => {
    if (isPN && !treatmentStartDate) {
      return null
    }

    if (isCoolingOffPeriodCapable) {
      if (isCoolingOffPeriodWaived) {
        return t(`{{consumerFirstName}} has waived the cooling-off period.`, {
          consumerFirstName,
        })
      }
      if (coolingOffPeriodEnds && coolingOffPeriodEnds > moment()) {
        return t(`The cooling-off period ends on {{date}}.`, {
          date: coolingOffPeriodEnds.format('DD MMM YYYY'),
        })
      }
      if (coolingOffPeriodEnds && coolingOffPeriodEnds < moment()) {
        return t(`The cooling-off period ended on {{date}}.`, {
          date: coolingOffPeriodEnds.format('DD MMM YYYY'),
        })
      }
    }
  }

  const coolingOffSuffix = getCoolingOffText()

  const payOverTime =
    consumerHisHer === 'his'
      ? t(
          `{{consumerFirstName}}'s loan started and his first payment was collected {{elapsedTime}}.`,
          { consumerFirstName, elapsedTime: moment().to(confirmedDate) }
        )
      : t(
          `{{consumerFirstName}}'s loan started and her first payment was collected {{elapsedTime}}.`,
          { consumerFirstName, elapsedTime: moment().to(confirmedDate) }
        )
  const payNow = t(`{{consumerFirstName}} paid by card {{elapsedTime}}.`, {
    consumerFirstName,
    elapsedTime: moment().to(confirmedDate),
  })

  return (
    <Card data-testid="action-panel-payout-on-the-way" {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Payout on the way')}</Heading>
      </Flex>
      <Progressbar percent={percent} color="blue.3" mb={4} />
      <Caption color="ink.1" mb={4}>
        {`${!isPN ? payOverTime : payNow} `}
        {merchantPayoutAt &&
          (sumMerchantNetProceedsTransfers
            ? t(
                'Your payout ({{amount}}) should arrive to your bank account ending in {{accountNumber}} by {{date}}.',
                {
                  amount: format(sumMerchantNetProceedsTransfers),
                  accountNumber,
                  date: arrivalMoment.format('DD MMM YYYY'),
                }
              )
            : t(
                'Your payout should arrive to your bank account ending in {{accountNumber}} by {{date}}.',
                {
                  accountNumber,
                  date: arrivalMoment.format('DD MMM YYYY'),
                }
              ))}
        <br />
        {coolingOffSuffix}
      </Caption>
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="flex-start"
        alignItems={['stretch', 'center']}
      >
        <InvoiceButton mr={[0, 4]} mb={[4, 0]} />
        <RefundButton transaction={transaction} />
      </Flex>
    </Card>
  )
}

PaymentOnTheWay.propTypes = {
  transaction: PropTypes.object.isRequired,
  merchantBankDetails: PropTypes.object.isRequired,
}

export default PaymentOnTheWay
