import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { View, Flex, Subheading, Prompt } from '@tabeo/scarf'
import { children } from '@tabeo/sharpei/utils/propTypes'
import styled from 'styled-components'

function TabbedView({ children, initialIndex = 0, ...rest }) {
  const [activeIndex, setActiveIndex] = useState(initialIndex)
  const currentTab = React.Children.toArray(children)[activeIndex]
  return (
    <View border={1} boxShadow={1} bg="white" borderRadius={1} {...rest}>
      <Flex borderBottom={1} py={3} px={4}>
        <Flex>
          {React.Children.map(children, (child, index) => {
            if (!child) {
              return null
            }
            return (
              <Tab
                key={index}
                mr={6}
                color={activeIndex === index ? 'default.primary' : 'ink.2'}
                onClick={() => {
                  setActiveIndex(index)
                }}
                onKeyDown={e => {
                  e.keyCode === 13 && setActiveIndex(index)
                }}
                tabIndex="0"
              >
                {child.props.label}
              </Tab>
            )
          })}
        </Flex>
        <Prompt color="ink.2" display={['none', 'block']}>
          {moment().format('MMMM YYYY')}
        </Prompt>
      </Flex>
      {currentTab}
    </View>
  )
}

TabbedView.propTypes = {
  initialIndex: PropTypes.number,
  children,
}

export default TabbedView

const Tab = styled(Subheading)`
  cursor: pointer;
  outline: none;
`
