import { Banner, Button, Caption, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const BankVerificationBanner = ({ bankDetailsStatus, ...rest }) => {
  const { t } = useTranslation()

  const states = {
    new: {
      title: t('Bank account not verified'),
      content: (
        <View>
          <Caption mb={3}>
            {t(
              'We still need to verify your bank account, for this reason certain features may be restricted.'
            )}
          </Caption>
          <Link to="/bank-verification">
            <Button sizeVariant="sm">{t('Verify bank account')}</Button>
          </Link>
        </View>
      ),
    },
    correct: {
      title: t('Verify your bank details'),
      content: (
        <View>
          <Caption mb={3}>
            {t(
              'We need to verify the ownership of your bank account, some features may be restricted until the verification is done.'
            )}
          </Caption>
          <Link to="/bank-verification">
            <Button sizeVariant="sm">{t('Verify bank account')}</Button>
          </Link>
        </View>
      ),
    },
    verification_token_disclosed: {
      title: t('Bank account verification pending'),
      content: (
        <Caption>
          {t(
            "We're verifying your bank details. This usually takes a few minutes."
          )}
        </Caption>
      ),
    },
  }

  const banner = states[bankDetailsStatus]
  return !banner ? null : (
    <Banner color="sea" title={banner.title} {...rest}>
      {banner.content}
    </Banner>
  )
}

BankVerificationBanner.propTypes = {
  bankDetailsStatus: PropTypes.string,
}

export default BankVerificationBanner
