import { Component } from 'react'

class ScrollToTopOnMount extends Component {
  componentDidMount() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  render() {
    return null
  }
}

export default ScrollToTopOnMount
