import { View } from '@tabeo/scarf'
import Layout from 'pages/NewTransaction/Layout'
import PlanForm from 'pages/NewTransaction/PlanForm'
import PlanOverview from 'pages/NewTransaction/PlanOverview'
import SuccessModal from 'pages/NewTransaction/SuccessModal'
import React, { useRef } from 'react'
import { Redirect } from 'react-router-dom'
import { useMerchant } from 'resources/Merchant'
import { usePlans } from 'resources/Plans'
import Subscriptions from 'resources/Subscriptions'

/**
 * NewSubscription page
 *
 * It's an extracted functionality from the NewTransaction page.
 * Keep updated both implementations in case of changes.
 */
function NewSubscription() {
  const { data: merchant } = useMerchant()
  const { data: plans } = usePlans()

  const modal = useRef(null)

  const [state, setState] = React.useState<{ formValues: any }>({
    formValues: {},
  })

  const { sector, tradingName } = merchant || {}

  const {
    formValues: { petName, planId },
  } = state

  const plan = plans?.items?.find?.((i: any) => i.id === planId) ?? {}

  const handleFormChange = ({ values, valid }: any) => {
    setState(state => ({
      ...state,
      valid,
      formValues: values,
    }))
  }

  if (!merchant?.flags?.is_new_transaction_screen_v2_capable) {
    return <Redirect to="/new-transaction" />
  }

  return (
    <Layout>
      <View>
        <SuccessModal innerRef={modal} />
        <View boxShadow={1} borderRadius={1} border={1}>
          <PlanForm
            onFormChange={handleFormChange}
            onSubmit={Subscriptions.create}
            plans={plans?.items ?? []}
            sector={sector}
            successModal={modal}
            borderRadius={1}
          />
        </View>
      </View>
      <View>
        <PlanOverview
          {...plan}
          merchantName={tradingName}
          sector={sector}
          petName={petName}
        />
      </View>
    </Layout>
  )
}

export default NewSubscription
