import { Banner, Caption } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ManualReview = props => {
  const { t } = useTranslation()

  return (
    <Banner color="yellow" title={t('Pending manual review')} {...props}>
      <Caption>
        {t(
          'We need to run some manual checks on your business before we can send you any payouts. However, you can already create transactions and have your customers apply for finance.'
        )}
      </Caption>
    </Banner>
  )
}

ManualReview.propTypes = {
  bankDetailsStatus: PropTypes.string,
}

export default ManualReview
