// @ts-nocheck

import React, { useRef, useState } from 'react'
import { useMobile } from '../../hooks/useMatchMedia'
import { Merge } from '../../types/helpers'
import ScarfProps from '../../types/ScarfProps'
import formatDate from '../../utils/formatDate'
import Flex from '../Flex'
import DayPicker from '../Form/DayPicker'
import * as Icons from '../Icons'
import Popover from '../Popover'
import Text from '../Text'
import View from '../View'
import BasicValue from './BasicValue'
import mapFormToProps from './mapFormToProps'
import Placeholder, { PlaceholderProps } from './Placeholder'
import Select from './Select'

function formatToTime(date: Date) {
  return date.toLocaleTimeString().substring(0, 5)
}

export type DateSelectorProps = Merge<
  ScarfProps,
  {
    value?: string | number | Date
    onChange: (value?: string) => void
    invalid?: PlaceholderProps['invalid']
    hasTime?: boolean
  }
>

function DateSelector({
  value,
  onChange,
  onFocus,
  onBlur,
  invalid,
  hasTime,
  ...rest
}: DateSelectorProps) {
  const [timeOpen, setTimeOpen] = useState(false)
  const timeRef = useRef()

  const mobile = useMobile()

  const parsedValue = value ? new Date(value) : null

  return (
    <View display="inline-flex">
      <DayPicker
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {({ setOpen, open, ref }) => (
          <View
            ref={ref}
            onClick={() => {
              setOpen(!open)
            }}
            cursor="pointer"
            mr={value && 2}
            {...rest}
          >
            {!value && (
              <Placeholder
                icon={(props) => <Icons.Calendar {...props} />}
                invalid={invalid}
                _hover={{
                  bg: 'sky.3',
                }}
              >
                Set date
              </Placeholder>
            )}
            {!!value && (
              <Flex alignItems="center">
                <BasicValue
                  icon={(props) => <Icons.Calendar {...props} />}
                  invalid={invalid}
                  onDelete={() => {
                    onFocus && onFocus()
                    onChange()
                    onFocus && onBlur()
                  }}
                  _hover={{
                    bg: 'sky.3',
                  }}
                >
                  {formatDate(value)}
                </BasicValue>
              </Flex>
            )}
          </View>
        )}
      </DayPicker>
      {value && hasTime && (
        <>
          <Popover
            open={timeOpen}
            onClickOut={() => setTimeOpen(false)}
            placement="bottom-start"
            referenceElement={timeRef}
            offset={[0, 4]}
          >
            {({ ref, style, placement }) => (
              <Select
                ref={ref}
                style={mobile ? { zIndex: 1000 } : style}
                data-placement={placement}
                options={[...new Array(48)].map((_, i) => {
                  const date = new Date(+parsedValue)
                  date.setHours(0, 0, 0, 0)

                  // It calculates selected date + option's time value
                  const time = new Date(
                    +date - +new Date(0) + +new Date(0 + i * 1000 * 60 * 30)
                  )

                  return {
                    label: formatToTime(time),
                    value: time.toISOString(),
                  }
                })}
                value={{ value: parsedValue.toISOString() }}
                onChange={(e) => {
                  onChange(e.value)
                  onBlur && onBlur()
                  setTimeOpen(false)
                }}
                onBlur={onBlur}
                onFocus={onFocus}
                onClose={() => setTimeOpen(false)}
                renderItem={(item, { selected, hover, ...rest }) => (
                  <Flex
                    justifyContent="flex-start"
                    px={[5, 3]}
                    py={[3, 2]}
                    bg={hover ? 'default.primary' : undefined}
                    color={hover ? 'white' : undefined}
                    {...rest}
                  >
                    <Text
                      color={hover ? 'white' : 'default.ink'}
                      fontSize={[null, '14px']}
                    >
                      {item.label}
                    </Text>
                    <Flex flex="1" />
                    {selected && (
                      <Icons.Tick
                        light
                        fill={hover ? 'white' : undefined}
                        ml={2}
                      />
                    )}
                  </Flex>
                )}
              />
            )}
          </Popover>
          <View
            ref={timeRef}
            onClick={() => {
              setTimeOpen(!timeOpen)
            }}
            cursor="pointer"
            {...rest}
          >
            {formatToTime(parsedValue) === '00:00' ? (
              <Placeholder
                icon={(props) => <Icons.Clock {...props} />}
                invalid={invalid}
                _hover={{
                  bg: 'sky.3',
                }}
              >
                Set time
              </Placeholder>
            ) : (
              <Flex alignItems="center">
                <BasicValue
                  icon={(props) => <Icons.Clock {...props} />}
                  invalid={invalid}
                  onDelete={() => {
                    onFocus && onFocus()

                    const date = new Date(+parsedValue)
                    date.setHours(0, 0, 0, 0)
                    onChange(date.toISOString())

                    onFocus && onBlur()
                  }}
                  _hover={{
                    bg: 'sky.3',
                  }}
                >
                  {formatToTime(parsedValue)}
                </BasicValue>
              </Flex>
            )}
          </View>
        </>
      )}
    </View>
  )
}

export default mapFormToProps(DateSelector)
