import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import { Basket as B } from '@tabeo/ts-types'

interface Params {
  id: string | number
}

const subResources = formatSubresources([
  'patient',
  'merchantTreatment.treatment',
  'merchantClinician.clinician',
])

class Basket extends Resource<B, Params> {
  async fetch() {
    const { basket } = await api.get(
      `/merchant/baskets/${this.params.id}?${subResources}`
    )

    this.data = basket

    return this.data
  }

  addPatient = async (patientID: number) => {
    const response = await api.put(`/merchant/baskets/${this.params.id}`, {
      ...this.data,
      patientID,
    })

    await this.fetch()
    return response
  }

  addMerchantClinician = async (merchantClinicianID: number | null) => {
    const response = await api.put(`/merchant/baskets/${this.params.id}`, {
      ...this.data,
      merchantClinicianID,
      clinicianName: !merchantClinicianID ? '' : undefined,
    })

    await this.fetch()
    return response
  }

  addMerchantTreatment = async (merchantTreatmentID: number | null) => {
    const response = await api.put(`/merchant/baskets/${this.params.id}`, {
      ...this.data,
      merchantTreatmentID,
      treatmentName: !merchantTreatmentID ? '' : undefined,
    })

    await this.fetch()
    return response
  }

  update = async (data: Partial<B>) => {
    const response = await api.put(`/merchant/baskets/${this.params.id}`, {
      ...this.data,
      ...data,
      treatmentName:
        this.data?.treatmentName && !data.treatmentName
          ? ''
          : data.treatmentName,
      treatmentCategory:
        this.data?.treatmentCategory && !data.treatmentCategory
          ? ''
          : data.treatmentCategory,
      clinicianName:
        this.data?.clinicianName && !data.clinicianName
          ? ''
          : data.clinicianName,
    })

    await this.fetch()
    return response
  }
}

export default Basket
export const useBasket = createResourceHook(Basket)
