/* eslint-disable react/prop-types */
import React from 'react'
import { InputProp, MetaProp } from '../../types/form'
import { Merge } from '../../types/helpers'

export type FormProps = {
  input?: Partial<InputProp>
  meta?: Partial<MetaProp>
}

function mapFormToProps<T extends React.ComponentType<any>>(
  WrappedComponent: T
) {
  const hocComponent = React.forwardRef<
    Element,
    // eslint-disable-next-line @typescript-eslint/ban-types
    Merge<FormProps, T extends React.JSXElementConstructor<infer P> ? P : {}>
  >(({ input, meta, ...rest }, ref) => (
    <WrappedComponent ref={ref} {...input} invalid={!!meta?.error} {...rest} />
  ))

  hocComponent.displayName = `MapFormToProps(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`

  return hocComponent
}

export default mapFormToProps
