import React from 'react'
import { StyledSystemProps } from '../../utils/styledSystem'

export type ModuleContextType = {
  collapsible: Record<string, boolean>
  open: (id?: string) => void
  close: (id?: string) => void
  toggle: (id?: string) => void
  closeAll: () => void
  color: StyledSystemProps['color']
}

const ModuleContext = React.createContext<ModuleContextType>({
  collapsible: {},
  open: () => {},
  close: () => {},
  toggle: () => {},
  closeAll: () => {},
  color: '',
})

export default ModuleContext
