import styled from 'styled-components'
import View from './View'
import { Subheading } from './Text'

export const Table = styled(View).attrs(() => ({
  display: 'table',
}))`
  border-collapse: collapse;
  border: none;
  width: 100%;
`

Table.defaultProps = {
  minWidth: '768px',
}

export const THead = styled(View).attrs(() => ({
  bg: 'sky.3',
  display: 'table-row-group',
}))``

THead.defaultProps = {}

export const TBody = styled(View).attrs(() => ({
  display: 'table-row-group',
}))``

export const TR = styled(View).attrs(() => ({
  display: 'table-row',
}))``

export const TH = styled(Subheading)`
  text-align: left;
  vertical-align: middle;
  padding-right: ${(p) => (p.px ? String(p.px) : '20px')};

  &:first-of-type {
    padding-left: ${(p) => (p.px ? String(p.px) : '20px')};
  }
`

TH.defaultProps = {
  color: 'ink.2',
  py: 2,
  display: 'table-cell',
}

export const TD = styled(View)`
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: ${(p) => (p.px ? String(p.px) : '16px')};

  &:first-of-type {
    padding-left: ${(p) => (p.px ? String(p.px) : '16px')};
  }

  > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

TD.defaultProps = {
  py: 3,
  display: 'table-cell',
}
