import { Box, Modal } from '@tabeo/scarf'
import React from 'react'
import plane from 'assets/airplane.svg'
import { useTranslation } from 'react-i18next'
import { Button } from '@tabeo/scarf2'
import { Link } from 'react-router-dom'

export type SuccessModalProps = React.ComponentProps<typeof Modal>

const SuccessModal = React.forwardRef((props: SuccessModalProps, ref: any) => {
  const { t } = useTranslation()

  return (
    <Modal ref={ref}>
      {({ isOpen, close, data }) => {
        return isOpen ? (
          <Box
            boxShadow={1}
            mx={['auto']}
            p={10}
            maxWidth={['95%', '420px']}
            textAlign="center"
          >
            <div className="space-y-6">
              <img src={plane} className="mx-auto" />
              <div className="space-y-1">
                <h3 className="text-semibold title">
                  {t('Invitation link sent')}
                </h3>
                <p className="text-tabeo-ink-1">
                  {t('The patient will receive an email shortly')}
                </p>
              </div>
              <div className="flex flex-col space-y-4">
                <Button
                  as={Link}
                  to={`/subscriptions/${data?.id}`}
                  variant="secondary"
                  className="w-full"
                >
                  {t('View membership')}
                </Button>
                <Button onClick={close} variant="primary" className="w-full">
                  {t('Close')}
                </Button>
              </div>
            </div>
          </Box>
        ) : null
      }}
    </Modal>
  )
})

export default SuccessModal
