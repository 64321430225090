import { Prompt, Title } from '@tabeo/scarf'
import Layout from 'components/Layout'
import useAuthGuard from 'hooks/useAuthGuard'
import useFlow from 'hooks/useFlow'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'resources/Auth'
import { useMerchant } from 'resources/Merchant'
import Create from '../../components/Signup/OfficerDetails/Create'
import Update from '../../components/Signup/OfficerDetails/Update'
import Progressbar from '../../components/Signup/Progressbar'

function OfficerDetails() {
  const { t } = useTranslation()
  useAuthGuard()

  const companyTypes = {
    private_limited_company: {
      title: t('Director details'),
      desc: t(
        'Please tell us a bit more about one of the directors of your company.'
      ),
    },
    limited_liability_partnership: {
      title: t('Partner details'),
      desc: t(
        'Please tell us a bit more about one of the partners of your LLP.'
      ),
    },
    sole_trader: {
      title: t('Owner details'),
      desc: t('Please tell us a bit more about the owner of your business.'),
    },
    partnership: {
      title: t('Partner details'),
      desc: t(
        'Please tell us a bit more about one of the partners of your business.'
      ),
    },
  }
  const {
    data: merchant,
    resource: { createDirectorDetails, updateDirectorDetails },
  } = useMerchant()

  const {
    data: { user },
  } = useAuth()

  const { next, loading } = useFlow({ merchant, user })

  const handleSubmit = async values => {
    if (officers.length) {
      await updateDirectorDetails(values)
    } else {
      await createDirectorDetails(values)
    }

    await next()
  }

  const { companyType, officers } = merchant || {}
  const { title, desc } = companyTypes?.[companyType] || {}

  return (
    <Layout maxWidth="640px" isLoading={loading}>
      <Progressbar companyType={companyType} currentIndex={2} mb={6} />
      <Title mb={3}>{title}</Title>
      <Prompt mb={4}>{desc}</Prompt>
      {officers?.length ? (
        <Update onSubmit={handleSubmit} officers={officers} />
      ) : (
        <Create onSubmit={handleSubmit} />
      )}
    </Layout>
  )
}

OfficerDetails.propTypes = {}

export default OfficerDetails
