import {
  Button,
  Caption,
  Checkbox,
  Clickable,
  CompanySelect,
  Flex,
  NativeSelect,
  PasswordInput,
  PhoneInput,
  Prompt,
  Radio,
  Text,
  TextInput,
  Title,
  View,
} from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import {
  emailPCA,
  mobilePhonePCA,
  name,
  password,
  required,
} from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FieldError from 'components/Form/FieldError'
import FormError from 'components/Form/FormError'
import Layout from 'components/Layout'
import config from 'config'
import useCountry from 'hooks/useCountry'
import useFlow from 'hooks/useFlow'
import get from 'lodash/get'
import React from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { useAuth } from 'resources/Auth'
import Merchant, { useMerchant } from 'resources/Merchant'

const { ENV: env, COUNTRY, LANDING_PAGE_URL } = config

const countryCode = COUNTRY

function Register() {
  const { t } = useTranslation()
  const selected = v => (v === false ? t('Must be selected') : undefined)

  const {
    data: { user, token },
    resource: { login },
  } = useAuth()

  const { data: merchant } = useMerchant(() => {
    if (!token) {
      throw Error('waiting for token')
    }
  })

  const { loading } = useFlow(
    {
      user: token ? user : '',
      merchant: token ? merchant : '',
    },
    {
      autoRedirect: true,
    }
  )

  const country = useCountry()

  const companyIDLabels = {
    gb: t('Company name'),
    nl: t('KVK number'),
    it: t('RI/REA number'),
    fr: t('SIREN number'),
    es: t('Company number'),
    de: t('Company number'),
  }

  async function handleSubmit({
    companyType,
    companyID,
    manualCompanyID,
    firstName,
    lastName,
    mobilePhone,
    email,
    password,
    principles,
    ...companyData
  }) {
    const companyTypes = {
      private_limited_company: 'merchantPrivateLimitedCompany',
      limited_liability_partnership: 'merchantLimitedLiabilityPartnership',
      sole_trader: 'merchantSoleTrader',
      partnership: 'merchantPartnership',
    }
    // TODO: check country code
    const payload = {
      companyType,
      [companyTypes[companyType]]: {
        countryCode,
        mobilePhone,
        companyID: companyID || manualCompanyID,
        ...companyData,
      },
      merchantUser: {
        firstName,
        lastName,
        mobilePhone,
        email: email.toLowerCase(),
        password,
        countryCode,
      },
    }

    try {
      await Merchant.create(payload)

      await login({
        username: email,
        password,
      })
    } catch (e) {
      const companyRegistered = get(e, 'response.data.data.CompanyID')
      const emailRegistered = get(e, 'response.data.data.user')
      if (companyRegistered) {
        e.response.data.data.CompanyID = t(
          'This company is already registered.'
        )
      }
      if (emailRegistered) {
        e.response.data.data.user = undefined
        e.response.data.data.email = t('This email is already registered.')
      }
      return formError(e)
    }
  }

  const [searchError, setSearchError] = React.useState(null)
  function handleCompanySearchError(e, change) {
    if (![404, 200].includes(e.status)) {
      change('companyID', '')
      setSearchError(e)
    }
  }

  return (
    <Layout maxWidth="640px" isLoading={loading}>
      <View as={Form} onSubmit={handleSubmit} destroyOnUnregister>
        {({
          handleSubmit,
          submitting,
          values: { companyType, companyID, manualCompanyID },
          form: { change },
        }) => (
          <form onSubmit={handleSubmit}>
            <Title mb={3}>{t('Registration')}</Title>
            <Prompt mb={4}>
              {t('Please enter the details of our primary point of contact.')}
            </Prompt>
            <Field
              component={Radio}
              label={t('Title')}
              name="gender"
              validate={[required]}
              items={[
                { label: t('Mr'), value: 'm' },
                { label: t('Ms'), value: 'f' },
              ]}
              horizontal
            />
            <Field
              component={TextInput}
              name="firstName"
              label={t('First name')}
              placeholder={t('e.g. John')}
              validate={[required, name]}
              format={v => (v ? v.capitalize() : '')}
            />

            <Field
              component={TextInput}
              name="lastName"
              label={t('Last name')}
              placeholder={t('e.g. Smith')}
              validate={[required, name]}
            />

            <Field
              component={NativeSelect}
              label={t('Business type')}
              name="companyType"
              placeholder={t('Select from the list...')}
              validate={required}
              options={[
                {
                  label: t('Limited company (LTD)'),
                  value: 'private_limited_company',
                },
                {
                  label: t('Limited Liability Partnership (LLP)'),
                  value: 'limited_liability_partnership',
                },
                { label: t('Sole trader'), value: 'sole_trader' },
                !country.isIT && {
                  label: t('Partnership'),
                  value: 'partnership',
                },
              ].filter(Boolean)}
            />

            {country.isUK && (
              <>
                {[
                  'private_limited_company',
                  'limited_liability_partnership',
                ].includes(companyType) && (
                  <Field
                    // Remove component to force re-mount if there is an error
                    key={searchError ? 'removeCompanyId' : 'companyID'}
                    name="companyID"
                    component={CompanySelect}
                    label={companyIDLabels[countryCode]}
                    placeholder={t('e.g. Tabeo Limited')}
                    validate={!searchError ? required : undefined}
                    onError={e => handleCompanySearchError(e, change)}
                    env={env}
                    autoBlur
                  />
                )}
                {!!searchError && !companyID && (
                  <>
                    {!manualCompanyID && (
                      <FieldError
                        mt="-8px"
                        mb={5}
                        message={t(
                          'Something went wrong while searching for your company information.'
                        )}
                      />
                    )}
                    <Flex
                      alignItems="stretch"
                      justifyContent="flex-start"
                      mb={5}
                    >
                      <View width="2px" bg="sky.0" mr={3} />
                      <Field
                        component={TextInput}
                        name="manualCompanyID"
                        label={t('Company number')}
                        placeholder={t('E.g. 10363602')}
                        validate={[required]}
                        containerProps={{ mb: 0 }}
                        description={
                          <Caption color="ink.2">
                            <Trans>
                              You can find your company number by searching on
                              Companies House{' '}
                              <Clickable
                                as="a"
                                target="_blank"
                                href={`https://beta.companieshouse.gov.uk/search?q=${searchError.query}`}
                              >
                                here
                              </Clickable>
                            </Trans>
                          </Caption>
                        }
                      />
                    </Flex>
                  </>
                )}
              </>
            )}

            {country.isEU && (
              <>
                {[
                  'private_limited_company',
                  'limited_liability_partnership',
                ].includes(companyType) && (
                  <Field
                    component={TextInput}
                    name="companyID"
                    label={companyIDLabels[countryCode]}
                    validate={[required]}
                  />
                )}
              </>
            )}

            {['sole_trader', 'partnership'].includes(companyType) && (
              <Field
                component={TextInput}
                name="tradingName"
                label={t('Business name')}
                placeholder={t('e.g. Tabeo')}
                validate={[required]}
              />
            )}

            <Field
              data-no-track
              component={PhoneInput}
              name="mobilePhone"
              label={t('Phone number')}
              type="tel"
              validate={[required, mobilePhonePCA]}
              description={t('In case we need to contact you')}
            />

            <Field
              data-no-track
              component={TextInput}
              name="email"
              type="email"
              label={t('Email address')}
              placeholder={t('e.g. user@example.co.uk')}
              validate={[required, emailPCA]}
            />

            <Field
              component={PasswordInput}
              label={t('Password')}
              name="password"
              criterias
              validate={[required, password]}
            />

            <Field
              name="principles"
              component={Checkbox}
              validate={[required, selected]}
            >
              <Text>
                <Trans>
                  I agree to the{' '}
                  <Clickable
                    as="a"
                    href={`${LANDING_PAGE_URL}/terms-and-conditions`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions
                  </Clickable>{' '}
                  and{' '}
                  <Clickable
                    as="a"
                    href={`${LANDING_PAGE_URL}/privacy-policy`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </Clickable>
                  .
                </Trans>
              </Text>
            </Field>

            <Button loading={submitting}>{t('Register now')}</Button>
            <FormError />
          </form>
        )}
      </View>
    </Layout>
  )
}

export default Register
