import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as SandClockGray } from 'components/checkout-payment/assets/sand-clock-gray.svg'
import { useTranslation } from 'react-i18next'

function Expired() {
  const { t } = useTranslation()

  return (
    <ActionCard data-testid="expired-action-panel">
      <ActionCard.Header>
        <SandClockGray className="h-6 w-6" />
        {t('Expired')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t('This payment link has expired. No money was collected.')}
      </ActionCard.Body>
    </ActionCard>
  )
}

export default Expired
