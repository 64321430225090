import { DataTable, GridTable, Panel, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import { usePlans } from 'resources/Plans'
import CreatePlanButton from './CreatePlanButton'
import ListItem from './PlanListItem'
import SharePlansButton from './SharePlansButton'

function PlanListPanel({ plans, ...rest }) {
  const { data: merchant } = useMerchant()
  const { t } = useTranslation()
  if (!merchant) return null
  return (
    <Panel
      header={
        <>
          <Panel.Title>{t('Plans')}</Panel.Title>
          <View>
            {merchant.flags?.is_subscription_plan_capable && (
              <>
                <CreatePlanButton merchant={merchant} sizeVariant="sm" />
                <SharePlansButton
                  sizeVariant="sm"
                  plans={plans || []}
                  merchant={merchant}
                  ml={2}
                />
              </>
            )}
          </View>
        </>
      }
      {...rest}
    >
      <DataTable
        resourceHook={usePlans}
        paginationLimit={6}
        borderRadius={0}
        border="none"
        id="plans"
        data-testid="plans-table"
      >
        {({ items }) => (
          <GridTable
            templateColumns={[
              'minmax(65px,auto) minmax(140px, 1fr) minmax(140px, 1fr) auto auto',
            ]}
            minWidth="650px"
          >
            {items.map(item => (
              <ListItem key={item.id} data={item} />
            ))}
          </GridTable>
        )}
      </DataTable>
    </Panel>
  )
}

PlanListPanel.propTypes = {
  plans: PropTypes.array,
}

export default PlanListPanel
