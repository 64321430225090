import { Caption, Flex, Heading, Progressbar, View } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import Card from 'components/Transaction/ActionPanel/Card'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import icon from './assets/payout_on_the_way.svg'

function PaymentOnTheWay({ data, ...rest }) {
  const {
    netProceeds,
    merchantPayoutAt,
    stateMachineTransitions,
    consumerFirstName,
  } = data
  const { t } = useTranslation()
  const {
    data: { bankDetails },
  } = useMerchant()

  if (!merchantPayoutAt) {
    return null
  }

  const confirmedDate = stateMachineTransitions.find(e =>
    ['paid'].includes(e.to)
  )?.at

  const confirmedMoment = moment(confirmedDate)
  const arrivalDate = moment(merchantPayoutAt)
  const now = moment()
  const totalDuration =
    moment.duration(arrivalDate.diff(confirmedMoment)).asSeconds() + 20
  const elapsed = moment.duration(now.diff(confirmedMoment)).asSeconds()
  const percent = (elapsed / totalDuration) * 100
  const bankAccountNumber = bankDetails.accountNumber.slice(-3)

  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Payout on the way')}</Heading>
      </Flex>
      <Progressbar percent={percent} color="blue.3" mb={4} />
      <Caption color="ink.1" mb={4}>
        {t(
          '{{consumerFirstName}}’s payment was collected {{elapsedTime}} Your payout ({{netProceedsAmount}}) should arrive to your bank account ending in {{bankAccountNumber}} by {{arrivalDate}}.',
          {
            consumerFirstName,
            elapsedTime: moment().to(confirmedDate),
            netProceedsAmount: format(netProceeds),
            bankAccountNumber,
            arrivalDate: arrivalDate.format('DD MMM YYYY'),
          }
        )}
      </Caption>
    </Card>
  )
}

PaymentOnTheWay.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PaymentOnTheWay
