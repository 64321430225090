'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

require('i18next');
var index = require('../i18n/index.js');
var Raven = _interopDefault(require('raven-js'));
var finalForm = require('final-form');

var formError = function (error) {
    var _a;
    var _b, _c;
    // Log error
    Raven.captureException(error, {
        extra: {
            response: error.response,
        },
    });
    var fieldErrors = {};
    var validationErrors = (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.data;
    // Form level error
    if (!validationErrors) {
        return _a = {}, _a[finalForm.FORM_ERROR] = index.t('There was an error. Please try again later.'), _a;
    }
    Object.keys(validationErrors || {}).forEach(function (key) {
        var lovercaseErrorKey = key.charAt(0).toLowerCase() + key.slice(1);
        fieldErrors[lovercaseErrorKey] = Array.isArray(validationErrors[key])
            ? validationErrors[key].join(' ')
            : validationErrors[key];
    });
    return fieldErrors;
};

exports.default = formError;
