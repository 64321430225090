import { Text } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import BankVerificationRequired from './BankVerificationRequired'
import ManualReviewPending from './ManualReviewPending'

export const CREATE_PLAN = 'feature/plans/create'
export const CONFIRM_OFFER = 'feature/offers/confirm'

function FeatureBlockModal(props) {
  const { feature, ...rest } = props
  const { data: merchant } = useMerchant()
  const {
    status: merchantStatus,
    bankDetails: { status: bankDetailsStatus },
  } = merchant
  const { t } = useTranslation()

  const MANUAL_REVIEW_STATUSES = ['manual_review_required']

  const BANK_VERIFICATION_STATUSES = [
    'new',
    'correct',
    'verification_token_disclosed',
  ]

  if (MANUAL_REVIEW_STATUSES.includes(merchantStatus)) {
    return <ManualReviewPending {...rest} />
  }

  if (BANK_VERIFICATION_STATUSES.includes(bankDetailsStatus)) {
    return (
      <BankVerificationRequired bankDetailsStatus={bankDetailsStatus} {...rest}>
        <Text>
          {feature === CREATE_PLAN
            ? t(
                'Before you create your new Care Plan, please verify your bank details.'
              )
            : feature === CONFIRM_OFFER
            ? t(
                'Before you can complete a transaction, please verify your bank details.'
              )
            : t('Please verify your bank details before using this feature.')}
        </Text>
      </BankVerificationRequired>
    )
  }

  return null
}

FeatureBlockModal.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  feature: PropTypes.string.isRequired,
}

export default FeatureBlockModal
