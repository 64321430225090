import { Modal } from '@tabeo/scarf'
import React, { useContext } from 'react'
import { NormalizedLinkPayment } from 'types/NormalizedLinkPayment'
import { NormalizedTerminalPayment } from 'types/NormalizedTerminalPayment'

type Context = {
  payment?: NormalizedTerminalPayment | NormalizedLinkPayment
  onPaymentRefetch?: () => any
  onRefundRequest?: (payload: any) => any
  onReceiptSend?: (payload: any) => any
  requestModal?: React.RefObject<Modal>
  reviewModal?: React.RefObject<Modal>
}

export const PaymentRefundContext = React.createContext<Context>({})

export function usePaymentRefundContext() {
  return useContext(PaymentRefundContext)
}
