import React from 'react'

import styled from 'styled-components'
import ScarfProps from '../types/ScarfProps'
import View from './View'

const Container = styled(View)`
  border-radius: 6px;
  overflow: hidden;
`

Container.defaultProps = {
  height: '4px',
  bg: 'sky.2',
}

type FillProps = {
  percent: number
}

const Fill = styled(View)<FillProps>`
  height: 100%;
  width: ${(p) => p.percent}%;
  transition: width 1s ease-in-out;
  border-radius: 6px;
`

export type ProgressbarProps = ScarfProps & {
  percent?: number
  color?: ScarfProps['bg']
}

const Progressbar = ({
  percent = 0,
  color = 'default.primary',
  ...rest
}: ProgressbarProps) => (
  <Container {...rest}>
    <Fill percent={percent} bg={color} />
  </Container>
)

export default Progressbar
