import React from 'react'
import Svg, { SvgProps, G } from './Base'

function Search({ fill, ...props }: SvgProps) {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <G
        fill={fill}
        stroke="none"
        fillRule="evenodd"
        transform="translate(2 2)"
      >
        <path d="M6.182 9.818a3.637 3.637 0 110-7.274 3.637 3.637 0 010 7.274m8.824 3.903l-4.381-4.382a5.429 5.429 0 001.011-3.157 5.455 5.455 0 10-5.454 5.454 5.429 5.429 0 003.157-1.011l4.382 4.381a.904.904 0 001.285 0 .907.907 0 000-1.285" />
      </G>
    </Svg>
  )
}

export default Search
