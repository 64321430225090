import { PencilSquareIcon, PlusCircleIcon } from '@heroicons/react/20/solid'
import { NativeSelect } from '@tabeo/scarf'
import { Button, FFTextInput } from '@tabeo/scarf2'
import formError from '@tabeo/sharpei/utils/formError'
import { lengthRange, name, required } from '@tabeo/sharpei/utils/validations'
import { Clinician, MerchantTreatment } from '@tabeo/ts-types'
import FormError from 'components/Form/FormError'
import MerchantClinicianSelect from 'components/MerchantClinicianSelect'
import MerchantTreatmentSelect from 'components/MerchantTreatmentSelect'
import { Field } from 'components/nnts/form/Field'
import useTreatments from 'pages/NNTS/components/treatment/useTreatments'
import { getCategoriesBySector } from 'pages/Settings/Treatments/constants'
import { ReactNode, useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useBasket } from 'resources/Basket'
import { useMerchant } from 'resources/Merchant'
import { usePatient } from 'resources/Patient'
import Patients from 'resources/Patients'

function DataField({
  addValueLabel,
  label,
  children,
  value,
  forceForm,
}: {
  addValueLabel: string
  label: string
  children: ReactNode
  value?: string
  forceForm?: boolean
}) {
  const [show, setShow] = useState(!!forceForm)
  if (value && !show) {
    return (
      <div className="flex flex-1 items-center justify-between">
        <div>
          <p className="caption text-tabeo-ink-1">{label}</p>
          <div className="text-tabeo-ink-0">{value}</div>
        </div>
        <button type="button" onClick={() => setShow(true)}>
          <PencilSquareIcon className="h-5 w-5 text-tabeo-primary-3 hover:text-tabeo-primary-2" />
        </button>
      </div>
    )
  }
  return show ? (
    <>{children}</>
  ) : (
    <button
      type="button"
      className="flex cursor-pointer items-center gap-1 text-sm text-tabeo-primary-3 underline"
      onClick={() => setShow(true)}
    >
      <PlusCircleIcon className="h-5 w-5" />
      {addValueLabel}
    </button>
  )
}

function hasKeysWithValue(obj: any) {
  return Object.values(obj).some(v => !!v)
}
interface Props {
  onSuccess: (values: any) => void | ((values: any) => Promise<void>)
  closeLink: string
  basketId: number
}

export function TransactionDataForm({ onSuccess, closeLink, basketId }: Props) {
  const { t } = useTranslation()
  const { data: merchant } = useMerchant()

  const isPatientRequired =
    merchant?.flags?.is_patient_required_for_terminal_payment

  const isCliniciansAndTreatmentsEnabled =
    merchant?.flags?.is_clinicians_treatments_enabled

  const { data: basket, resource: basketResource } = useBasket({
    id: basketId,
  })

  const { data: patient, resource: patientResource } = usePatient(() => {
    const id = basket?.patient?.id

    if (!id) {
      throw new Error('Patient ID is missing')
    }

    return {
      id,
    }
  })

  const handleSubmit = useCallback(
    async (values: {
      firstName?: string
      lastName?: string
      clinician?: Partial<Clinician>
      clinicianName?: string
      treatment?: Partial<MerchantTreatment>
      treatmentName?: string
      otherTreatmentName?: string
      treatmentCategory?: string
    }) => {
      try {
        let patientID = patient?.id
        if (patientID) {
          await patientResource?.update({
            ...patient,
            firstName: values.firstName,
            lastName: values.lastName,
          })
        } else {
          const { id } = await Patients.create({
            firstName: values.firstName,
            lastName: values.lastName,
          })
          patientID = id
        }

        await basketResource?.update({
          ...basket,
          patientID,
          merchantClinicianID: values.clinician?.id,
          clinicianName: values.clinicianName,
          merchantTreatmentID: values.treatment?.id,
          treatmentName: values.otherTreatmentName || values.treatmentName,
          treatmentCategory: values.treatmentCategory,
        })

        return onSuccess(values)
      } catch (e) {
        return formError(e)
      }
    },
    [onSuccess, basket, basketResource, patient, patientResource]
  )

  // This is used for "other" treatment category when C&T is on
  const treatmentCategories = [
    ...getCategoriesBySector(merchant?.sector).sort((a, b) =>
      a.localeCompare(b)
    ),
    t('Other'),
  ] as string[]

  // This is used when C&T is off
  const treatmentsByCategories = useTreatments()

  const isOtherTreatment = basket?.treatmentCategory === 'General'

  const initialValues = {
    treatmentName:
      isOtherTreatment && !basket.merchantTreatmentID
        ? 'Other'
        : basket?.treatmentName,
    otherTreatmentName: !isCliniciansAndTreatmentsEnabled
      ? isOtherTreatment
        ? basket?.treatmentName
        : ''
      : null,
    treatmentCategory: basket?.treatmentCategory,
    treatment: basket?.merchantTreatment?.id ? basket?.merchantTreatment : null,
    firstName: basket?.patient?.firstName,
    lastName: basket?.patient?.lastName,
    clinician: basket?.merchantClinician?.id ? basket?.merchantClinician : null,
    clinicianName: basket?.clinicianName,
  }

  const patientName = `${initialValues?.firstName || ''} ${
    initialValues?.lastName || ''
  }`.trim()

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      destroyOnUnregister
    >
      {({ handleSubmit, dirty, values, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="divide-y rounded border">
            <>
              <div className="p-5">
                <DataField
                  addValueLabel={t('Add treatment')}
                  label={t('Treatment')}
                  value={
                    initialValues?.otherTreatmentName ||
                    initialValues?.treatmentName
                  }
                >
                  {isCliniciansAndTreatmentsEnabled ? (
                    <>
                      <Field
                        name="treatment"
                        label="Treatment"
                        component={MerchantTreatmentSelect}
                        optional
                        maxWidth="100%"
                        autoFocus={!initialValues?.treatmentName}
                      />
                      {values.treatment?.treatment?.name === 'Other' && (
                        <div className="mt-5 flex flex-col items-start gap-4 sm:flex-row">
                          <Field
                            name="treatmentName"
                            label={t('Name')}
                            placeholder={t('Type name here...')}
                            component={FFTextInput}
                            validate={[required, lengthRange(4, 500)]}
                            clearable
                            autoFocus
                          />
                          <Field
                            name="treatmentCategory"
                            label={t('Category')}
                            validate={[required]}
                            component={NativeSelect}
                            options={treatmentCategories.map(category => ({
                              value: category,
                              label: category,
                            }))}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <Field
                        name="treatmentName"
                        label={t('Treatment')}
                        placeholder={t('Select from list...')}
                        maxWidth="100%"
                        component={NativeSelect}
                        options={treatmentsByCategories
                          .flatMap(tc => tc.treatments)
                          .map(treatment => ({
                            value: treatment,
                            label: treatment,
                          }))}
                        optional
                        validate={[required, lengthRange(4, 500)]}
                        clearable
                      />
                      {values.treatmentName === 'Other' && (
                        <Field
                          name="otherTreatmentName"
                          label={t('Name')}
                          placeholder={t('Type name here...')}
                          component={FFTextInput}
                          validate={[required, lengthRange(4, 500)]}
                          clearable
                          autoFocus
                          containerClassName="mt-5"
                        />
                      )}
                    </>
                  )}
                </DataField>
              </div>
            </>
            <div className="flex flex-col items-start gap-4 p-5 sm:flex-row">
              <DataField
                label={t('Patient')}
                addValueLabel={t('Add patient')}
                value={patientName}
                forceForm={isPatientRequired && !patientName}
              >
                <Field
                  name="firstName"
                  label="Patient first name"
                  placeholder="E.g. John"
                  component={FFTextInput}
                  optional={!isPatientRequired}
                  validate={[required, name]}
                  autoFocus={!initialValues?.firstName}
                />
                <Field
                  name="lastName"
                  label="Patient last name"
                  placeholder="E.g. John"
                  component={FFTextInput}
                  optional={!isPatientRequired}
                  validate={[required, name]}
                />
              </DataField>
            </div>
            <div className="p-5">
              <DataField
                label={t('Clinician')}
                addValueLabel={t('Add clinician')}
                value={initialValues?.clinicianName}
              >
                {isCliniciansAndTreatmentsEnabled ? (
                  <Field
                    name="clinician"
                    label="Clinician"
                    component={MerchantClinicianSelect}
                    optional
                    maxWidth="100%"
                  />
                ) : (
                  <Field
                    name="clinicianName"
                    label="Clinician"
                    placeholder="E.g. Dr. John Smith"
                    component={FFTextInput}
                    optional
                    validate={[required, name]}
                    autoFocus
                  />
                )}
              </DataField>
            </div>
          </div>
          <div className="mt-6 flex flex-col space-y-4 empty:mt-0">
            {((dirty && hasKeysWithValue(values)) || isPatientRequired) && (
              <>
                <Button type="submit" variant="primary" loading={submitting}>
                  {t('Confirm')}
                </Button>
                <FormError />
              </>
            )}
            {!isPatientRequired && (
              <Button as={Link} to={closeLink} variant="secondary">
                {t('Close')}
              </Button>
            )}
          </div>
        </form>
      )}
    </Form>
  )
}
