import { GridTable, GridTableCell, GridTableRow, Panel } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import { Payout } from '@tabeo/ts-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const PayoutsPanel = ({
  items,
  showPayoutAmount,
  ...rest
}: {
  items?: Payout[]
  showPayoutAmount?: boolean
}) => {
  const { t } = useTranslation()

  if (!items) return null

  return items?.length ? (
    <Panel title={t('Payouts')} data-testid="payout-panel" {...rest}>
      <GridTable
        templateColumns={
          showPayoutAmount ? 'repeat(4, auto)' : 'repeat(3, auto)'
        }
        bg="white"
      >
        {items.map(payout => (
          <GridTableRow
            as={Link}
            to={`/payouts/${payout.id}`}
            key={payout.id}
            // @ts-ignore
            cursor="pointer"
            _hover={{
              '> *': {
                bg: 'sky.3',
              },
            }}
            _notFirst={{
              '> *': {
                borderTop: 1,
              },
            }}
          >
            <GridTableCell color="primary.3">{payout.displayID}</GridTableCell>
            {showPayoutAmount && (
              <GridTableCell>{format(payout.amount)}</GridTableCell>
            )}
            <GridTableCell
              color={showPayoutAmount ? 'ink.2' : undefined}
              textAlign={showPayoutAmount ? undefined : 'right'}
            >
              {payout.from.toUpperCase()}
            </GridTableCell>
            <GridTableCell textAlign="right" color="ink.2">
              {moment(payout.transactionAt).format('DD MMM YYYY, hh:mm a')}
            </GridTableCell>
          </GridTableRow>
        ))}
      </GridTable>
    </Panel>
  ) : null
}

export default PayoutsPanel
