import React from 'react'

function useFormInput(initialValue) {
  const [value, setValue] = React.useState(initialValue)

  function handleChange(e) {
    setValue(e.target.value)
  }

  return {
    value,
    onChange: handleChange,
  }
}

export default useFormInput
