import { Flex, Select, Text, TextInput, View } from '@tabeo/scarf'
import { lengthRange, required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import NumberInput from 'components/Form/NumberInput'
import PropTypes from 'prop-types'
import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { useTranslation } from 'react-i18next'
import { dentalPerks, vetPerks } from './benefits'

const lengthRange5to100 = lengthRange(5, 100)

class ListItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { availablePerks = [], currentPerk } = this.props
    if (
      availablePerks.join(',') === nextProps.availablePerks.join(',') &&
      nextProps.currentPerk === currentPerk
    ) {
      return false
    }
    return true
  }

  render() {
    const { name, index, availablePerks, fields, currentPerk, t } = this.props
    return (
      <>
        <View>
          <Flex>
            <Field
              name={`${name}.title`}
              label={t(`Perk {{number}}`, { number: index + 1 })}
              component={Select}
              options={availablePerks.map(f => ({ label: f }))}
              valueKey="label"
              containerProps={{ flex: 1 }}
            />
            <View>
              <Text
                onClick={() => fields.remove(index)}
                style={{ cursor: 'pointer' }}
                textAlign="right"
                color="ink.2"
                fontSize="12px"
                my={1}
              >
                {t('Remove')}
              </Text>
              <Field
                name={`${name}.claimsLimit`}
                component={NumberInput}
                minValue={0}
                ml={3}
              />
            </View>
          </Flex>
        </View>
        {currentPerk && currentPerk.title === t('Other') && (
          <Field
            name={`${name}.otherTitle`}
            placeholder={t('E.g. Dental X-Rays')}
            component={TextInput}
            validate={[required, lengthRange5to100]}
          />
        )}
      </>
    )
  }
}

ListItem.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
  availablePerks: PropTypes.array,
  fields: PropTypes.object,
  currentPerk: PropTypes.object,
  t: PropTypes.func,
}

const PerksList = ({ sector }) => {
  const { t } = useTranslation()
  const featureList = sector === 'dental_services' ? dentalPerks : vetPerks
  return !featureList.length ? null : (
    <FieldArray name="planPerks">
      {({ fields }) =>
        fields.map((name, index) => {
          const currentPerk = fields.value[index]
          const selectedPerks = fields.value
            .map(f => f.title)
            .filter(title => title !== 'Other')
          const availablePerks = featureList.filter(
            f => !selectedPerks.includes(f) || currentPerk.title === f
          )
          const props = {
            name,
            index,
            availablePerks,
            fields,
            currentPerk,
          }
          return <ListItem key={name} t={t} {...props} />
        })
      }
    </FieldArray>
  )
}

PerksList.propTypes = {
  errors: PropTypes.object,
  validPerks: PropTypes.array,
  features: PropTypes.array,
  sector: PropTypes.string,
}

export default PerksList
