import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAuth } from 'resources/Auth'
import Nav, { NavItem, NavDropdown } from './commons'

const LoggedIn = () => {
  const {
    data: { user },
    resource: { logout },
  } = useAuth()
  const { t } = useTranslation()

  const { firstName, lastName } = user || {}
  return (
    <Nav>
      <NavDropdown label={`${firstName} ${lastName}`}>
        <NavItem as={NavLink} to="/support">
          {t('Support')}
        </NavItem>
        <NavItem as="span" onClick={() => logout()}>
          {t('Logout')}
        </NavItem>
      </NavDropdown>
    </Nav>
  )
}

LoggedIn.propTypes = {}

export default LoggedIn
