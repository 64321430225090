import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as EyeYellow } from 'components/checkout-payment/assets/eye-yellow.svg'
import { useTranslation } from 'react-i18next'

export default function SignUp() {
  const { t } = useTranslation()

  return (
    <ActionCard>
      <ActionCard.Header>
        <EyeYellow className="h-6 w-6" />
        {t('Sign up started')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t(
          'The customer clicked on the link and started to sign up. They have to complete a few more fields to activate their membership.'
        )}
      </ActionCard.Body>
    </ActionCard>
  )
}
