export const SENT_STATUSES = ['new']
export const INCOMPLETE_STATUSES = [
  'personal_details_created',
  'patient_details_created',
  'payment_source_created',
  'subscription_agreement_created',
  'payment_source_creation_failed',
]

export const ACTIVE_STATUSES = [
  'subscription_agreement_accepted',
  'subscribed',
  'confirmed',
  'live',
]

export const INACTIVE_STATUSES = ['deactivated']
export const CANCELED_STATUSES = ['canceled']
export const EXPIRED_STATUSES = ['expired']
export const FAILED_STATUSES = ['failed']

export const getCurrentState = ({ status }) => {
  if (SENT_STATUSES.includes(status)) {
    return 'sent'
  }
  if (INCOMPLETE_STATUSES.includes(status)) {
    return 'incomplete'
  }
  if (ACTIVE_STATUSES.includes(status)) {
    return 'active'
  }
  if (INACTIVE_STATUSES.includes(status)) {
    return 'inactive'
  }
  if (CANCELED_STATUSES.includes(status)) {
    return 'canceled'
  }
  if (EXPIRED_STATUSES.includes(status)) {
    return 'expired'
  }
  if (FAILED_STATUSES.includes(status)) {
    return 'failed'
  }
}

const mergeClaimsWithBenefits = subscription => {
  if (!subscription.plan) {
    return subscription
  }
  const {
    availablePlanFeatureClaims,
    availablePlanPerkClaims,
    plan: { planFeatures, planPerks },
  } = subscription
  const features = (planFeatures || []).map(f => ({
    ...f,
    availableClaims: availablePlanFeatureClaims[f.id],
  }))
  const perks = (planPerks || []).map(p => ({
    ...p,
    availableClaims: availablePlanPerkClaims[p.id],
  }))

  return {
    ...subscription,
    plan: { ...subscription.plan, planFeatures: features, planPerks: perks },
  }
}

const normalize = subscription => {
  const state = getCurrentState(subscription)
  const subscriptionWithMergedBenefitsAndClaims =
    mergeClaimsWithBenefits(subscription)
  const pet = subscription?.metadata?.patient?.pet
  const person = subscription?.metadata?.patient?.person
  const name =
    (person && `${person.firstName} ${person.lastName}`) ||
    (pet && `${pet.name} ${subscription.consumerLastName}`) ||
    `${subscription.consumerFirstName} ${subscription.consumerLastName}`

  const beneficiary = {
    ...(person || pet || subscription.consumer),
    ...(subscription.metadata?.veterinaryServicesDetails ||
      subscription.metadata?.dentalServicesDetails ||
      {}),
    name,
  }
  const isForSomeoneElse = beneficiary.ownershipStatus === 'someone_else'

  const agreementAcceptedAt = subscription?.stateMachineTransitions?.find(
    s => s.to === 'subscription_agreement_accepted'
  )?.at

  return {
    ...subscriptionWithMergedBenefitsAndClaims,
    state,
    isForSomeoneElse,
    pet,
    person,
    beneficiary,
    agreementAcceptedAt,
  }
}

export default normalize
