import { Caption, Flex, Icons, Text } from '@tabeo/scarf'
import CustomOption from 'components/CustomOption'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const TreatmentOption = React.forwardRef((props, ref) => {
  const { t } = useTranslation()

  const { isFocused, option } = props
  const { name, category } = option.value?.treatment || option.value || {}

  if (option.value === 'new') {
    return (
      <CustomOption ref={ref} {...props}>
        <Flex justifyContent="flex-start">
          <Icons.X
            transform="rotate(45deg)"
            fill={isFocused ? 'white' : 'ink.0'}
            height="16px"
            width="16px"
            mr={2}
            mb="2px"
          />
          <Text color={isFocused ? 'white' : 'ink.0'} fontSize={1}>
            {t('Add new treatment')}
          </Text>
        </Flex>
      </CustomOption>
    )
  }

  return (
    <CustomOption ref={ref} {...props}>
      <Flex>
        <Caption fontWeight="semibold" color={isFocused ? 'white' : 'ink.0'}>
          {name}
        </Caption>
      </Flex>
      {category !== 'Other' && (
        <Flex>
          <Caption color={isFocused ? 'white' : undefined} fontSize={0}>
            {category}
          </Caption>
        </Flex>
      )}
    </CustomOption>
  )
})

TreatmentOption.propTypes = {
  option: PropTypes.object,
  isFocused: PropTypes.bool,
}

export default TreatmentOption
