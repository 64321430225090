import { Button, usePromise } from '@tabeo/scarf'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRefund } from 'resources/Refund'
import { usePaymentRefundContext } from '../Context'

export type CancelButtonProps = { id: string | number } & React.ComponentProps<
  typeof Button
>

function CancelButton({ id, ...rest }: CancelButtonProps) {
  const { t } = useTranslation()

  const { onPaymentRefetch } = usePaymentRefundContext()

  const { resource } = useRefund({
    id,
  })

  const [triggerCancel, cancelState] = usePromise(async () => {
    const r = await resource?.cancel()
    await onPaymentRefetch?.()
    return r
  })

  return (
    <Button
      sizeVariant="sm"
      variant="secondary"
      onClick={triggerCancel}
      loading={cancelState.pending}
      {...rest}
    >
      {t('Cancel')}
    </Button>
  )
}

export default CancelButton
