import React, { useState } from 'react'

export type HoverableProps = {
  children: (hover: boolean) => React.ReactElement
}

function Hoverable({ children }: HoverableProps) {
  const [hover, setHover] = useState(false)

  return React.cloneElement(children(hover), {
    onMouseOver: () => setHover(true),
    onMouseLeave: () => setHover(false),
    // @ts-ignore
    ...children.props,
  })
}

export default Hoverable
