// @ts-nocheck

import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Flex from '../Flex'
import Selector from '../Selector'
import { BaseProps, Caption, Subheading } from '../Text'
import View from '../View'
import SortingDown from './assets/sorting-down.svg'
import SortingIdle from './assets/sorting-idle.svg'
import SortingUp from './assets/sorting-up.svg'
import { ReactComponent as SwitchHorisontal } from './assets/switch-horisontal.svg'
import { useDataTable } from './context'

export type Column = { label: string; orderBy?: string; cellProps?: BaseProps }

export type CustomColumn = {
  type: 'selectAll'
  getId?: (item: any) => any
  cellProps?: BaseProps
}

export type HeadListItemProps = {
  column: Column | Column[] | CustomColumn
}

function HeadListItem({ column }: HeadListItemProps) {
  const {
    items,
    state: { orderBy },
    dispatch,
  } = useDataTable()
  const isMultiColumn = Array.isArray(column)
  const [currentIndex, setColumnIndex] = useState(0)

  function handleOrderByChange(change) {
    dispatch({
      type: 'orderByChange',
      payload: change,
    })
  }

  const currentColumn = isMultiColumn ? column[currentIndex] : column
  function toggleColumn() {
    const nextIndex =
      (column.length % currentIndex) + 2 === column.length
        ? 0
        : currentIndex + 1
    setColumnIndex(nextIndex)
    handleOrderByChange({
      field: column[nextIndex].orderBy,
      direction: orderBy?.direction || 'asc',
    })
  }

  function toggleOrderBy() {
    const nextDirection = orderBy?.direction === 'asc' ? 'desc' : 'asc'
    handleOrderByChange({
      field: currentColumn.orderBy,
      direction: nextDirection,
    })
  }

  // Set default as active if it's a multi column item and some other item's order is changed
  useEffect(() => {
    if (isMultiColumn) {
      const fields = column.map((c) => c.orderBy)
      if (!fields.includes(orderBy.field) && currentIndex !== 0) {
        setColumnIndex(0)
      }
    }
  }, [orderBy, isMultiColumn, currentColumn, column, currentIndex])

  if (!currentColumn) {
    return <View />
  }

  if (currentColumn?.type === 'selectAll') {
    return (
      <Caption
        onClick={() =>
          dispatch({
            type: 'selectedChange',
            payload: items.map((i) =>
              currentColumn.getId ? currentColumn.getId(i) : i?.id
            ),
          })
        }
      >
        <Selector state="idle" width="16px" height="100%" />
      </Caption>
    )
  }

  return (
    <Flex justifyContent="flex-end">
      <Flex
        onClick={
          isMultiColumn
            ? toggleColumn
            : currentColumn.orderBy
            ? toggleOrderBy
            : null
        }
        cursor={currentColumn.orderBy ? 'pointer' : null}
        color="ink.2"
        borderRadius={1}
        mr={1}
        _hover={
          isMultiColumn
            ? {
                bg: 'sky.2',
                color: 'primary.3',
                p: 1,
                m: '-4px',
                mr: 0,
              }
            : {}
        }
      >
        {isMultiColumn && (
          <View
            as={SwitchHorisontal}
            transform={
              currentIndex % 2 === 1 ? 'rotate(180deg)' : 'rotate(0deg)'
            }
          />
        )}
        <Subheading color="currentColor" ml={isMultiColumn ? '2px' : '0px'}>
          {currentColumn.label}
        </Subheading>
      </Flex>
      {currentColumn.orderBy && (
        <View
          // eslint-disable-next-line react/jsx-no-bind
          onClick={toggleOrderBy}
          display="inline-flex"
          cursor={currentColumn.orderBy ? 'pointer' : null}
          p={1}
          my="-4px"
        >
          {orderBy?.field !== currentColumn.orderBy ? (
            <View as="img" src={SortingIdle} />
          ) : orderBy.direction === 'asc' ? (
            <View as="img" src={SortingUp} />
          ) : (
            <View as="img" src={SortingDown} />
          )}
        </View>
      )}
    </Flex>
  )
}

HeadListItem.propTypes = {
  column: PropTypes.any,
}

export default HeadListItem
