import { Flex, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useApi } from 'resources/Api'
import DataCard from './DataCard'

const divider = (
  <View
    width={['auto', '1px']}
    height={['1px', 'auto']}
    alignSelf="stretch"
    bg="sky.1"
  />
)

const initialState = {
  numberInMonth: 0,
  numberInChosenDayPercentChange: 0,
  accumulatedPriceInChosenDay: 0,
  accumulatedPriceInChosenDayPercentChange: 0,
}

function Tab({ endpoint, responseDataPath, labels = {}, ...rest }) {
  const { t } = useTranslation()
  const defaultCardLabels = {
    sent: t('Requests sent'),
    completed: t(`Completed by customer`),
    paid: t('Total payout'),
  }
  const mergeLabels = { ...defaultCardLabels, ...labels }
  const { data } = useApi({ endpoint }, { refreshInterval: 0 })
  const stats = data?.[responseDataPath] ?? {}

  return (
    <Flex
      flexDirection={['column', 'row']}
      alignItems={['stretch', 'center']}
      {...rest}
    >
      <DataCard
        label={mergeLabels.sent}
        data={stats.requestsSent || initialState}
      />
      {divider}
      <DataCard
        label={mergeLabels.completed}
        data={stats.requestsCompleted || initialState}
      />
      {divider}
      <DataCard
        label={mergeLabels.paid}
        data={stats.requestsPaidOut || initialState}
      />
    </Flex>
  )
}

Tab.propTypes = {
  endpoint: PropTypes.string.isRequired,
  responseDataPath: PropTypes.string.isRequired,
  labels: PropTypes.object,
}

export default Tab
