import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'

import { View, Modal, Title, Select, Button, AddressSelect } from '@tabeo/scarf'

import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import formError from '@tabeo/sharpei/utils/formError'
import { required } from '@tabeo/sharpei/utils/validations'
import { useTranslation } from 'react-i18next'

function EditUserDetailsModal({ innerRef, initialValues, onSubmit }) {
  const { t } = useTranslation()
  return (
    <Modal ref={innerRef}>
      {({ isOpen, close }) => {
        async function handleSubmit(values) {
          try {
            await onSubmit({
              ...values,
              addressJSON: JSON.parse(values.addressJSON),
            })
            close()
          } catch (e) {
            return formError(e)
          }
        }
        return isOpen ? (
          <View
            maxWidth={['90%', '460px']}
            mx="auto"
            bg="white"
            borderRadius={1}
            boxShadow={1}
            p={[5, 10]}
          >
            <Title mb={4}>{t('Edit user details')}</Title>
            <Form onSubmit={handleSubmit} initialValues={initialValues}>
              {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    component={Select}
                    name="jobFunction"
                    label={t('Role')}
                    // TODO check
                    options={[
                      {
                        label: t('Dentist / Veterinarian'),
                        value: 'Dentist / Veterinarian',
                      },
                      {
                        label: t('Surgeon / Specialist'),
                        value: 'Surgeon / Specialist',
                      },
                      {
                        label: t('Nurse / Hygienist'),
                        value: 'Nurse / Hygienist',
                      },
                      {
                        label: t('Director / Owner'),
                        value: 'Director / Owner',
                      },
                      { label: t('Admin / Finance'), value: 'Admin / Finance' },
                      {
                        label: t('Sales / Marketing'),
                        value: 'Sales / Marketing',
                      },
                    ]}
                    validate={[required]}
                    maxWidth="100%"
                  />

                  <Field
                    component={AddressSelect}
                    name="addressJSON"
                    label={t('Trading Address')}
                    validate={[required]}
                    maxWidth="100%"
                  />

                  <Button loading={submitting} width="100%" mb={5}>
                    {t('Update')}
                  </Button>

                  <Button
                    variant="secondary"
                    type="button"
                    width="100%"
                    onClick={close}
                  >
                    {t('Close')}
                  </Button>
                  <FormError />
                </form>
              )}
            </Form>
          </View>
        ) : null
      }}
    </Modal>
  )
}

EditUserDetailsModal.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
}

export default EditUserDetailsModal
