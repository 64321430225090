import { themeGet } from '@styled-system/theme-get'
import React, { useRef, useState } from 'react'
import ReactDayPicker, {
  DayPickerProps as ReactDayPickerProps,
} from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'
import styled from 'styled-components'
import { useLocale } from '../../ScarfProvider'
import Popover, { PopoverProps } from '../Popover'
import View from '../View'

export type PickerWrapperProps = {
  alignPopupToTop?: boolean
}

const PickerWrapper = styled(View)<PickerWrapperProps>`
  :focus {
    outline: none;
  }

  .DayPicker {
    color: ${themeGet('colors.default.ink')};
    font-family: ${themeGet('font', 'Arial')};
    font-size: 16px;
    line-height: 1.5;

    :focus {
      outline: none;
    }

    ${(p) => (p.alignPopupToTop ? 'top: -330px;' : '')}

    .DayPicker-wrapper {
      border-radius: 4px;

      :focus {
        outline: none;
      }

      @media (max-width: ${themeGet('breakpoints.0')}) {
        left: 50%;
        transform: translate(-50%, 0);
      }

      .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(
          .DayPicker-Day--selected
        ) {
        color: ${themeGet('colors.primary.3')};
      }

      .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
          .DayPicker-Day--outside
        ) {
        background-color: ${themeGet('colors.primary.3')};
        border-radius: 0;
      }

      .DayPicker-Day:not(.DayPicker-Day--disabled):not(
          .DayPicker-Day--selected
        ):not(.DayPicker-Day--outside):hover {
        background-color: ${themeGet('colors.primary.6')};
        border-radius: 0;
      }
    }
  }
`

const Container = styled(View)`
  :focus {
    outline: none;
  }
`

Container.defaultProps = {
  bg: 'white',
  boxShadow: 1,
  borderRadius: 1,
  border: [0, 1],
}

export type DayPickerProps = {
  onBlur?: (e?: React.FocusEvent) => void
  onFocus?: (e?: React.FocusEvent) => void
  onChange: (value: string) => void
  value?: string
  placement?: PopoverProps['placement']
  modifiers?: ReactDayPickerProps['modifiers']
  children?: (renderProps: {
    open?: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>
    ref: React.RefObject<any>
    props: { [key: string]: any }
  }) => React.ReactNode
}

function DayPicker({
  onBlur,
  onFocus,
  onChange,
  value,
  placement = 'bottom-start',
  children,
  modifiers,
  ...rest
}: DayPickerProps) {
  const [open, setOpen] = useState<boolean | undefined>()
  const ref = useRef<Element>()
  const parsedValue = value ? new Date(value) : null
  const locale = useLocale()

  return (
    <>
      <Popover
        open={open}
        onClickOut={() => setOpen(false)}
        placement={placement}
        referenceElement={ref}
        offset={[0, 4]}
      >
        {({ ref, style, placement }) => (
          <Container
            ref={ref}
            style={style}
            data-placement={placement}
            tabIndex="0"
            onBlur={onBlur}
            onFocus={onFocus}
          >
            <PickerWrapper>
              <ReactDayPicker
                onDayClick={(e) => {
                  const date = e

                  date.setHours(0, 0, 0, 0)

                  onChange(date.toISOString())
                  onBlur && onBlur()
                  setOpen(false)
                }}
                selectedDays={parsedValue || undefined}
                month={parsedValue || undefined}
                locale={locale}
                localeUtils={MomentLocaleUtils}
                firstDayOfWeek={1}
                modifiers={modifiers}
              />
            </PickerWrapper>
          </Container>
        )}
      </Popover>
      {children?.({
        open,
        setOpen,
        ref,
        props: rest,
      })}
    </>
  )
}

export default DayPicker
