import { Attributes, Box, Caption, Flex, Prompt, Tag, View } from '@tabeo/scarf'
import Avatar from 'components/Avatar'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const PatientPanel = ({ data, ...rest }) => {
  const {
    firstName,
    lastName,
    gender,
    birthdate,
    email,
    userStatus,
    phoneNumber,
  } = data
  const { t } = useTranslation()

  return (
    <Box p={0} boxShadow={1} {...rest}>
      <View p={5}>
        <Flex>
          <Avatar name={`${firstName} ${lastName}`} mr={3} />
          <View flex="1">
            <Prompt fontWeight="semibold">
              {firstName} {lastName}
            </Prompt>
            <Caption color="ink.0">{t('Lead')}</Caption>
          </View>
        </Flex>
      </View>

      {/* TODO: check */}
      <Attributes
        p={5}
        sections={[
          [
            {
              label: t('Gender'),
              value: gender,
              formattedValue: gender === 'm' ? t('Male') : t('Female'),
            },
            {
              label: t('Date of birth'),
              value: birthdate,
              formattedValue: moment(birthdate).format('DD/MM/YYYY'),
            },
            {
              label: (
                <Flex justifyContent="flex-start">
                  {t('Email address')}{' '}
                  {userStatus && (
                    <Tag small color="green" ml={2}>
                      {t('Verified')}
                    </Tag>
                  )}
                </Flex>
              ),
              value: email,
            },
            {
              label: t('Phone number'),
              value: phoneNumber || t('Not yet available'),
            },
          ],
        ]}
        borderTop={1}
      />
    </Box>
  )
}

PatientPanel.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PatientPanel
