import { usePromise } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import { useTranslation } from 'react-i18next'
import { useSubscriptionV2 } from 'resources/SubscriptionV2'

type BulkTransferProps = {
  onClose: () => void
}

export default function BulkTransfer({ onClose }: BulkTransferProps) {
  const { t } = useTranslation()
  const { resource } = useSubscriptionV2()

  const [trigger, { pending }] = usePromise(async () => {
    if (resource?.cancel) {
      await resource.cancel()
    }
  })

  return (
    <div className="space-y-5">
      <h3 className="title">{t('Are you sure you would like to cancel?')}</h3>
      <div>
        {t(
          'Canceling will prevent the Direct Debit mandate from being activated. However, if any payments have already been initiated, they will still be processed.'
        )}
      </div>
      <div>
        {t('Make sure to verify any pending transactions before proceeding.')}
      </div>
      <div className="space-y-4">
        <Button
          variant="primary"
          className="w-full"
          loading={pending}
          onClick={trigger}
        >
          {t('Confirm')}
        </Button>
        <Button variant="secondary" className="w-full" onClick={onClose}>
          {t('Close')}
        </Button>
      </div>
    </div>
  )
}
