import { Box, Caption, Heading } from '@tabeo/scarf'

function Header({ ...rest }: React.ComponentProps<typeof Heading>) {
  return (
    <Heading
      fontWeight="semibold"
      className="flex items-center gap-3"
      {...rest}
      data-testid="action-panel-header"
    />
  )
}

function Body({ ...rest }: React.ComponentProps<typeof Caption>) {
  return <Caption color="ink.0" {...rest} />
}

function Actions({ ...rest }: React.ComponentProps<'div'>) {
  return (
    <div
      className="mt-4 flex flex-col space-y-3 desktop:flex-row desktop:space-x-3 desktop:space-y-0"
      {...rest}
    />
  )
}

function Error({ children, ...rest }: React.ComponentProps<typeof Caption>) {
  return (
    <Caption color="default.red" {...rest}>
      {children}
    </Caption>
  )
}

// eslint-disable-next-line import/prefer-default-export
export function ActionCard({
  'data-testid': dataTestId = 'action-panel',
  ...rest
}: React.ComponentProps<typeof Box>) {
  return (
    <Box
      p={5}
      boxShadow={1}
      className="space-y-4"
      data-testid={dataTestId}
      {...rest}
    />
  )
}

ActionCard.Header = Header
ActionCard.Body = Body
ActionCard.Actions = Actions
ActionCard.Error = Error
