import PropTypes from 'prop-types'
import React from 'react'
import Details from './Details'
import PaymentSource from './PaymentSource'
import Preview from './Preview'
import RefundFlowContext from './RefundFlowContext'
import Success from './Success'

function Flow({ onClose, transaction }) {
  const isPrePayoutRefund =
    (transaction?.isTabeoPlus &&
      transaction?.sumMerchantNetProceedsTransfers === 0) ||
    ['payout_scheduled', 'fulfillment_required'].includes(transaction.state) ||
    (['merchant_proceeds_transferred'].includes(transaction.status) &&
      transaction.payoutGateway === 'hsbcnet')

  const [currentStep, setStep] = React.useState(0)
  const [state, setState] = React.useState({ isPrePayoutRefund })

  const flow = [
    <Details />,
    <Preview />,
    !isPrePayoutRefund && <PaymentSource />,
    <Success />,
  ].filter(Boolean)

  const handleNext = () => {
    if (flow.length === currentStep - 1) {
      onClose()
    }

    setStep(currentStep + 1)
  }

  const handleBack = () => {
    if (flow.length === 0) {
      onClose()
    }

    setStep(currentStep - 1)
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <RefundFlowContext.Provider
      value={{
        flowState: state,
        setFlowState: setState,
        transaction,
        next: handleNext,
        back: handleBack,
        close: handleClose,
      }}
    >
      {flow[currentStep || 0]}
    </RefundFlowContext.Provider>
  )
}

Flow.propTypes = {
  onClose: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
}

export default Flow
