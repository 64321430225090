import { Button, ButtonGroup, Text, Title, View } from '@tabeo/scarf'
import { useMerchant } from 'resources/Merchant'
import illustration from '../../assets/3.png'
import { usePricingChangesContext } from '../../Context'
import CollapsibleFeesTable from './CollapsibleFeesTable'
import ExampleOptions from './ExampleOptions'

function Step3() {
  const { dontRemindMe } = usePricingChangesContext()
  const { data: merchant } = useMerchant()

  const isRegulatedLoanCapable = merchant?.flags?.is_regulated_loan_capable

  return (
    <View textAlign="center">
      <View
        as="img"
        src={illustration}
        width={['154px', '232px']}
        height={['105px', '160px']}
        mx="auto"
      />
      <Title mt={6}>Review the finance options you provide</Title>
      <Text mt={5} textAlign="left" fontSize={1}>
        It&apos;s a great time to review the finance options you offer. You
        could offer more flexible and affordable options for your patients.
        Below is an illustration of the subsidy fees for these options.
      </Text>
      <ExampleOptions mt={6} />
      <CollapsibleFeesTable mt={6} />
      {!isRegulatedLoanCapable ? (
        <>
          <View
            border={1}
            borderColor="green.4"
            bg="green.6"
            p={2}
            borderRadius={1}
            mt={6}
          >
            <Text color="green.0" textAlign="left" fontSize={1}>
              You need to be{' '}
              <b>authorised by the Financial Conduct Authority (FCA)</b> to
              offer finance for &gt; 12 months or APR &gt; 0%. Become an
              Introducer Appointed Representative (IAR) of Tabeo and offer your
              patients longer terms and interest-bearing options.
            </Text>
          </View>
          <Text mt={6} textAlign="left" fontSize={1}>
            If you&apos;re interested in adjusting the finance options you
            provide or want to learn more about the costs and benefits of being
            Tabeo&apos;s Introducer Appointed Representative (IAR), schedule a
            call for more details.
          </Text>
        </>
      ) : (
        <Text mt={6} textAlign="left" fontSize={1}>
          If you&apos;re interested in adjusting the finance options you
          provide, schedule a call with Tabeo for more details.
        </Text>
      )}
      <ButtonGroup width="100%" mt={10}>
        <Button variant="secondary" width="100%" onClick={dontRemindMe}>
          Close
        </Button>

        {isRegulatedLoanCapable ? (
          <Button
            as="a"
            href="https://calendly.com/tabeo_introduction/patient-finance-update-my-finance-options"
            target="_blank"
            rel="noopener noreferrer"
            width="100%"
          >
            Schedule a call
          </Button>
        ) : (
          <Button
            as="a"
            href="https://calendly.com/tabeo_introduction/iar-tabeo?month=2024-11"
            target="_blank"
            rel="noopener noreferrer"
            width="100%"
          >
            Schedule a call
          </Button>
        )}
      </ButtonGroup>
    </View>
  )
}

export default Step3
