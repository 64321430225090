import React, { useRef, useState } from 'react'
import { useMobile } from '../../hooks/useMatchMedia'
import { Merge } from '../../types/helpers'
import ScarfProps from '../../types/ScarfProps'
import Avatar from '../Avatar'
import AvatarList from '../Avatar/AvatarList'
import Flex from '../Flex'
import * as Icons from '../Icons'
import Popover from '../Popover'
import Text from '../Text'
import View from '../View'
import mapFormToProps from './mapFormToProps'
import Placeholder, { PlaceholderProps } from './Placeholder'
import Select, { SelectProps } from './Select'
import Hoverable from './Select/Hoverable'

export type Assignee = {
  type: string
  id: number
  name: string
}

export type AssigneeSelectorProps = Merge<
  ScarfProps,
  {
    loadOptions: SelectProps['loadOptions']
    onChange: (
      value:
        | (Assignee & { data?: any })
        | (Assignee & { data?: any })[]
        | null
        | undefined
    ) => void
    value?: Assignee | Assignee[]
    invalid?: PlaceholderProps['invalid']
    children?: (props: {
      ref: React.Ref<any>
      open: boolean
      setOpen: React.Dispatch<React.SetStateAction<boolean>>
      value: Assignee | Assignee[]
      props: ScarfProps
    }) => JSX.Element
  }
>

function AssigneeSelector({
  loadOptions,
  onChange,
  value = [],
  invalid,
  onFocus,
  onBlur,
  children,
  ...rest
}: AssigneeSelectorProps) {
  const mobile = useMobile()
  const [open, setOpen] = useState(false)
  const ref = useRef()

  return (
    <>
      <Popover
        open={open}
        onClickOut={() => setOpen(false)}
        placement="bottom-start"
        referenceElement={ref}
        offset={[0, 4]}
      >
        {({ ref, style, placement, update }) => (
          <Select
            ref={ref}
            onBlur={onBlur}
            onFocus={onFocus}
            style={mobile ? { zIndex: 1000 } : style}
            data-placement={placement}
            onHitsUpdate={update}
            placeholder="Search by name"
            loadOptions={loadOptions}
            filtering
            selectedFirst
            value={
              Array.isArray(value)
                ? value.map((v) => ({ value: v.id, data: v }))
                : value
                ? // @ts-ignore
                  { value: value.id, data: value }
                : value
            }
            onChange={(value) => {
              if (!value) {
                onChange(value)
                return setOpen(false)
              }
              if (!Array.isArray(value)) {
                setOpen(false)
                return onChange({
                  type: 'admins',
                  id: value.data.id,
                  name: value.data.name,
                  data: value.data,
                })
              }
              onChange(
                value.map(({ data }) => ({
                  type: 'admins',
                  id: data.id,
                  name: data.name,
                  data,
                }))
              )
            }}
            onClose={() => setOpen(false)}
            renderItem={(
              // @ts-ignore
              { data: { name, label } = {} },
              { hover, selected, ...rest }
            ) => (
              <Flex
                justifyContent="flex-start"
                px={[5, 3]}
                py={[3, 2]}
                bg={hover ? 'default.primary' : undefined}
                color={hover ? 'white' : undefined}
                {...rest}
              >
                {name && (
                  <Avatar
                    bg="transparent"
                    color={hover ? 'white' : 'default.primary'}
                    borderColor={hover ? 'white' : undefined}
                    name={name}
                    mr={3}
                  />
                )}
                <Text
                  color={hover ? 'white' : 'default.ink'}
                  fontSize={[null, '14px']}
                  data-testid="assignee-item"
                >
                  {name || label}
                </Text>
                <Flex flex="1" />
                {selected && (
                  <Icons.Tick light fill={hover ? 'white' : undefined} />
                )}
              </Flex>
            )}
            data-testid="assignee-selector"
          />
        )}
      </Popover>
      {children ? (
        children({
          ref,
          open,
          setOpen,
          value,
          props: rest,
        })
      ) : (
        <View
          ref={ref}
          onClick={() => {
            setOpen(!open)
          }}
          cursor="pointer"
          display="inline-block"
          {...rest}
        >
          {!(value as Assignee[]).length && (
            <Placeholder
              icon={(props) => <Icons.AddAssignee {...props} />}
              invalid={invalid}
              _hover={{
                bg: 'sky.3',
              }}
            >
              Assign person
            </Placeholder>
          )}
          {!!(value as Assignee[]).length && (
            <Flex alignItems="center">
              <AvatarList>
                {(value as Assignee[]).map(({ id, name }) => (
                  <Avatar
                    key={id}
                    borderColor={invalid ? 'default.red' : undefined}
                    color={invalid ? 'default.red' : 'default.primary'}
                    name={name}
                  />
                ))}
              </AvatarList>
              <Hoverable>
                {(hover) => (
                  <Icons.RoundPlus
                    fill="ink.1"
                    bg={hover ? 'sky.3' : 'white'}
                    border="sky.0"
                    width={['36px', '25px']}
                    height={['36px', '25px']}
                    ml={1}
                  />
                )}
              </Hoverable>
            </Flex>
          )}
        </View>
      )}
    </>
  )
}

export default mapFormToProps(AssigneeSelector)
