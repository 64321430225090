import { Modal } from '@tabeo/scarf'
import React from 'react'
import LinkPayment from '.'

const LinkPaymentModal = React.forwardRef<any, { isReminder?: boolean }>(
  ({ isReminder }, ref) => {
    return (
      <Modal closable={false} ref={ref}>
        {({ isOpen, close, data }) => {
          return isOpen ? (
            <LinkPayment {...data} modal={{ close }} isReminder={isReminder} />
          ) : null
        }}
      </Modal>
    )
  }
)

export default LinkPaymentModal
