'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var tslib_es6 = require('../tslib.es6-1ef680b2.js');
require('i18next');
require('../i18n/index.js');
var config = require('../config.js');
var Raven = _interopDefault(require('raven-js'));
var axios = _interopDefault(require('axios'));
var get = _interopDefault(require('lodash.get'));
var delay = require('./delay.js');
var jwt = require('./jwt.js');

// Creating axios instance
var api = axios.create();
var requestInterceptor = function (request) {
    request = tslib_es6.__assign({ baseURL: config.getConfig().API_URL }, request);
    return request;
};
var tokenInterceptor = function (request) {
    var token = localStorage.getItem('token');
    if (token && jwt.isValid(token)) {
        request = tslib_es6.__assign(tslib_es6.__assign({ baseURL: config.getConfig().API_URL }, request), { headers: tslib_es6.__assign(tslib_es6.__assign({}, request.headers), { Authorization: "Bearer ".concat(token) }) });
    }
    return request;
};
var cacheBusterInterceptor = function (request) {
    if (request.method === 'get') {
        request = tslib_es6.__assign(tslib_es6.__assign({}, request), { params: tslib_es6.__assign(tslib_es6.__assign({}, request.params), { _bust: new Date().getTime() }) });
    }
    return request;
};
var responseTypeInterceptor = function (response) { return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
    var type, _a, responseDataPath, config, path, responseData;
    return tslib_es6.__generator(this, function (_b) {
        type = response.data.type, _a = response.config, responseDataPath = _a.responseDataPath, config = tslib_es6.__rest(_a, ["responseDataPath"]);
        path = responseDataPath || 'data.data';
        responseData = get(response, path);
        switch (type) {
            case 'response_ready':
                return [2 /*return*/, responseData];
            case 'saga':
                return [2 /*return*/, handleSAGA(responseData, config)];
            default:
                return [2 /*return*/, response.data];
        }
    });
}); };
var handleResponseError = function (error) {
    var _a;
    if ([400, 500].includes((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) || error.type === 'saga') {
        // Log error
        Raven.captureException(error, {
            extra: {
                response: error.response,
            },
        });
    }
    return Promise.reject(error);
};
var handleSAGA = function (_a, _b) {
    var link = _a.link;
    var whilePending = _b.whilePending;
    return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var api, response, e_1, saga, generalStatus, status, message, error;
        return tslib_es6.__generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    api = axios.create();
                    api.interceptors.request.use(cacheBusterInterceptor);
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 7]);
                    return [4 /*yield*/, api.get(link)];
                case 2:
                    response = _c.sent();
                    return [3 /*break*/, 7];
                case 3:
                    e_1 = _c.sent();
                    if (!(!e_1.response && !navigator.onLine)) return [3 /*break*/, 5];
                    /**
                     * It's a network error, and we are offline
                     * Let's wait for online to recover
                     */
                    return [4 /*yield*/, new Promise(function (resolve) {
                            window.addEventListener('online', function () {
                                resolve();
                            });
                        })];
                case 4:
                    /**
                     * It's a network error, and we are offline
                     * Let's wait for online to recover
                     */
                    _c.sent();
                    return [2 /*return*/, handleSAGA({ link: link }, { whilePending: whilePending })];
                case 5: throw e_1;
                case 6: return [3 /*break*/, 7];
                case 7:
                    saga = get(response, 'data.data.saga', {});
                    generalStatus = saga.generalStatus, status = saga.status, message = saga.message;
                    if (!(status === 'failed' && generalStatus === 'failed')) return [3 /*break*/, 8];
                    error = new Error(message);
                    error.response = saga;
                    error.type = 'saga';
                    return [2 /*return*/, handleResponseError(error)
                        // Saga finished with response
                    ];
                case 8:
                    if (!['failed', 'completed'].includes(generalStatus)) return [3 /*break*/, 9];
                    return [2 /*return*/, saga];
                case 9:
                    if (!whilePending) return [3 /*break*/, 10];
                    return [2 /*return*/, whilePending(saga, function () { return handleSAGA({ link: link }, { whilePending: whilePending }); })];
                case 10: return [4 /*yield*/, delay.default(250)];
                case 11:
                    _c.sent();
                    return [2 /*return*/, handleSAGA({ link: link }, { whilePending: whilePending })];
            }
        });
    });
};
api.interceptors.request.use(requestInterceptor);
api.interceptors.request.use(tokenInterceptor);
api.interceptors.request.use(cacheBusterInterceptor);
api.interceptors.response.use(responseTypeInterceptor, handleResponseError);

exports.default = api;
