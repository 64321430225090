import { GridTableCell } from '@tabeo/scarf'
import styled from 'styled-components'

const HeaderCell = styled(GridTableCell)`
  &:first-of-type {
    padding-left: ${p => p.px};
  }
  &:last-of-type {
    padding-right: ${p => p.px};
  }
`

HeaderCell.defaultProps = {
  py: 2,
  bg: 'blue.6',
}

export default HeaderCell
