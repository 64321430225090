import { GridTableCell as Cell, GridTableRow, themeGet } from '@tabeo/scarf'
import ScarfProps from '@tabeo/scarf/dist/types/ScarfProps'
import { format } from '@tabeo/sharpei/utils/currency'
import LinkPaymentStatusTag from 'components/LinkPayment/StatusTag'
import moment from 'moment'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { NormalizedLinkPayment } from 'types/NormalizedLinkPayment'

const Row = styled(GridTableRow)<ScarfProps>`
  & > * {
    border-top: 1px solid ${themeGet('colors.sky.1')};
  }
  &:hover > * {
    background: ${themeGet('colors.sky.3', 'white')};
  }
`

export type ListItemProps = {
  data: NormalizedLinkPayment
}

const ListItem = ({ data }: ListItemProps) => {
  return (
    <Row bg="white" as={Link} to={`/link-payments/${data.id}`}>
      <Cell color="ink.2">{data.displayID}</Cell>
      <Cell data-testid="transaction-consumer">
        {data.basket.patient?.firstName} {data.basket.patient?.lastName}
      </Cell>
      <Cell data-testid="transaction-treatment-name" color="ink.2">
        {data.basket.treatmentName ||
          data.basket.merchantTreatment?.treatment?.name}
      </Cell>
      <Cell textAlign="right" color="ink.2">
        {format(data.amount)}
      </Cell>
      <Cell color="ink.2">
        <LinkPaymentStatusTag small status={data.status} />
      </Cell>
      <Cell textAlign="right" color="ink.2">
        {moment(data.updatedAt).format('DD MMM YYYY')}
      </Cell>
    </Row>
  )
}

export default ListItem
