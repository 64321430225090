import React, { useMemo } from 'react'
import ScarfProps from '../../types/ScarfProps'
import { Caption } from '../Text'
import View from '../View'

export type ItemProps<T extends React.ElementType> = {
  isLast?: boolean
  isFirst?: boolean
  innerProps?: ScarfProps
} & ScarfProps<T>

function Item<T extends React.ElementType>({
  children,
  isLast,
  isFirst,
  innerProps,
  ...rest
}: ItemProps<T>) {
  const clipPath = useMemo(() => {
    if (isLast) {
      return 'polygon(0% 0%, 100% 0%, 100% 50%, 100% 100%, 0% 100%, 12px 50%)'
    }
    if (isFirst) {
      return 'polygon(0% 0%, calc(100% - 12px) 0%, 100% 50%, calc(100% - 12px) 100%, 0% 100%, 0px 50%)'
    }
    return 'polygon(0% 0%, calc(100% - 12px) 0%, 100% 50%, calc(100% - 12px) 100%, 0% 100%, 12px 50%)'
  }, [isLast, isFirst])

  return (
    <View {...rest}>
      <View
        as="li"
        py={2}
        pl={isFirst ? 3 : 6}
        pr={isLast ? 3 : 5}
        bg="white"
        mr={isLast ? 0 : '-11px'}
        style={{
          clipPath,
        }}
        cursor="pointer"
        _hover={{ bg: 'sky.3' }}
        transition="0.2s all"
        {...innerProps}
      >
        <Caption color="ink.0">{children}</Caption>
      </View>
    </View>
  )
}

export default Item
