import { GridTableCell as Cell, GridTableRow, themeGet } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import Tag from 'components/SubscriptionStateTag'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Row = styled(GridTableRow)`
  &:first-of-type > * {
    margin-top: 8px;
  }
  & > * {
    border-top: 1px solid ${themeGet('colors.sky.1')};
  }
  &:hover > * {
    background: ${themeGet('colors.sky.3', 'white')};
  }
`

const ListItem = ({ data }) => {
  const { beneficiary } = data
  const name =
    beneficiary?.name || `${data.consumerFirstName} ${data.consumerLastName}`
  const { t } = useTranslation()
  return (
    <Row bg="white" as={Link} to={`/subscriptions/${data.id}`}>
      <Cell color="ink.2">{data.id}</Cell>
      <Cell>{name}</Cell>
      <Cell color="ink.2">{data.plan.title}</Cell>
      <Cell textAlign="right" color="ink.2">
        {t('{{amount}} / month', { amount: format(data.plan.price) })}
      </Cell>
      <Cell>
        <Tag small state={data.state} />
      </Cell>
    </Row>
  )
}

ListItem.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ListItem
