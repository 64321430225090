import { plansorchestrator } from '@tabeo/platform-api-client'
import { Title } from '@tabeo/scarf'
import SubscriptionV2StateTag from 'components/SubscriptionV2StateTag'
import { useTranslation } from 'react-i18next'

type HeaderProps = {
  data?: plansorchestrator.ModelsGetSubscriptionsSubscription
}

export default function Header({ data }: HeaderProps) {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center justify-center gap-4 desktop:flex-row desktop:items-baseline desktop:justify-between">
      <Title mr={[0, 3]}>
        {t('Membership #{{id}}', { id: data?.displayId })}
      </Title>
      <SubscriptionV2StateTag status={data?.status} data-testid="status" />
    </div>
  )
}
