import React from 'react'
import Button, { WrapProps } from './Button'
import * as Icons from './Icons'

export type AddButtonProps = WrapProps

function AddButton(props: AddButtonProps) {
  return (
    <Button
      leftIcon={(p: any) => <Icons.Plus fill="primary.3" {...p} ml={0} />}
      variant="transparent"
      textAlign="left"
      px={0}
      {...props}
    />
  )
}

export default AddButton
