import { Attributes, Panel, View } from '@tabeo/scarf'
import { Payout } from '@tabeo/ts-types'
import PropTypes from 'prop-types'
import { ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'

function BeneficiaryPanel({
  data,
  ...rest
}: {
  data: Payout
} & ComponentPropsWithoutRef<typeof Panel>) {
  const { bankAccountName, accountNumberLast4Digits, sortCode } = data
  const { t } = useTranslation()

  return (
    <Panel title={t('Beneficiary')} {...rest}>
      <View bg="white">
        <Attributes
          sections={[
            [
              {
                label: t('Bank account name'),
                value: bankAccountName,
              },
              {
                label: t('Sort code'),
                value: sortCode.replace(/(\d{2})(\d{2})(\d{2})/g, '$1-$2-$3'),
              },
              {
                label: t('Account number'),
                value: `••••${accountNumberLast4Digits.slice(-4)}`,
              },
            ],
          ]}
          p={5}
        />
      </View>
    </Panel>
  )
}

BeneficiaryPanel.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BeneficiaryPanel
