import {
  AnimateHeight,
  Button,
  Caption,
  Flex,
  Icons,
  Text,
  Title,
  usePromise,
  View,
} from '@tabeo/scarf'
import FieldError from 'components/Form/FieldError'
import { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useTerminalPayment } from 'resources/TerminalPayment'
import busyImg from '../assets/busy.png'
import TerminalPaymentContext from '../Context'

function ReaderBusy() {
  const { t } = useTranslation()
  const { dispatch, modal, state } = useContext(TerminalPaymentContext)
  const { resource } = useTerminalPayment({ id: state.terminalPaymentId })

  const [triggerCancel, { pending, error }] = usePromise(async () => {
    await resource?.cancel()
    modal.close()
  })

  const [isOpen, setOpen] = useState(false)

  return (
    <View textAlign="center">
      <View as="img" src={busyImg} width="176px" mx="auto" />
      <Title mt={6}>{t('Terminal Busy')}</Title>
      <Caption mt={1}>
        <Trans>
          Someone else might be using your terminal. Would you like to force a
          connection? This will terminate the current session.
        </Trans>
      </Caption>
      <Flex mt={6} flexDirection="column">
        <Button
          variant="secondary"
          onClick={() => dispatch({ type: 'force-connection' })}
        >
          {t('Force connection')}
        </Button>
        <Button
          variant="transparent"
          mt={4}
          onClick={triggerCancel}
          loading={pending}
        >
          {t('Cancel')}
        </Button>
        {error && (
          <FieldError message={error?.message || t('There was an error')} />
        )}
        <Flex
          onClick={() => setOpen(!isOpen)}
          cursor="pointer"
          justifyContent="flex-start"
          mt="64px"
        >
          <Text color="primary.3" textDecoration="underline">
            {t('Why does this happen?')}
          </Text>
          <Icons.ArrowRight
            fill="primary.3"
            transform={isOpen ? `rotate(270deg)` : `rotate(90deg)`}
            transition="transform 200ms linear"
            mb={isOpen ? 1 : undefined}
          />
        </Flex>

        <AnimateHeight duration={200} height={isOpen ? 'auto' : 0}>
          <Caption mt={1}>
            <Trans>
              This usually happens when 2 or more sessions try to access this
              screen at the same time. Make sure to close any other browser
              windows logged into this screen, which could also be on someone
              else&apos;s computer.
            </Trans>
          </Caption>
        </AnimateHeight>
      </Flex>
    </View>
  )
}

export default ReaderBusy
