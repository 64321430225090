import { Title, View } from '@tabeo/scarf'
import Summary from 'components/terminal/Summary'
import { SUCCESS_ANIMATION_DELAY } from 'config'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useTerminalPayment } from 'resources/TerminalPayment'
import succeededImg from '../assets/succeeded.png'
import TerminalPaymentContext from '../Context'

function PaymentSucceeded() {
  const { t } = useTranslation()
  const { state, dispatch, onSuccess } = useContext(TerminalPaymentContext)
  const { data: tp } = useTerminalPayment({ id: state.terminalPaymentId })

  useEffect(() => {
    const receiptTimeout = setTimeout(() => {
      dispatch({ type: 'show-receipt' })
      onSuccess?.()
    }, SUCCESS_ANIMATION_DELAY)

    return () => {
      clearTimeout(receiptTimeout)
    }
  }, [dispatch, onSuccess])

  return (
    <View textAlign="center">
      <View as="img" src={succeededImg} width="176px" mx="auto" />
      <Title mt={6}>{t('Payment succeeded')}</Title>
      <Summary data={tp} mt={6} />
    </View>
  )
}

export default PaymentSucceeded
