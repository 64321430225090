import { Heading, Panel, View } from '@tabeo/scarf'
import PlanOverview from 'components/PlanOverview'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

function PlanOverviewWrap(props) {
  const { id } = props
  const { t } = useTranslation()

  return (
    <Panel
      header={
        <Heading color="ink.2" textAlign="center" flex="1">
          {t('What your client will see')}
        </Heading>
      }
      mb={6}
    >
      {id ? (
        <PlanOverview {...props} />
      ) : (
        <View bg="white" p={7}>
          <Heading textAlign="center" color="ink.2">
            {t('No Plan selected')}
          </Heading>
        </View>
      )}
    </Panel>
  )
}

PlanOverviewWrap.propTypes = {
  id: PropTypes.number,
}

export default PlanOverviewWrap
