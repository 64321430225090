import PropTypes from 'prop-types'
import React from 'react'
import { Text } from '@tabeo/scarf'

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  name?: string
}

const Avatar = ({ name, children, ...rest }: Props) => (
  <Text
    width="44px"
    height="44px"
    bg="sky.3"
    border={1}
    borderRadius="50%"
    display="inline-flex"
    alignItems="center"
    justifyContent="center"
    color="primary.3"
    fontSize="20px"
    {...rest}
  >
    {children ||
      name
        ?.split(' ')
        .map(s => s.charAt(0))
        .join('')
        .slice(0, 2)}
  </Text>
)

Avatar.propTypes = {
  name: PropTypes.string,
  children: PropTypes.any,
}

export default Avatar
