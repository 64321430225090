import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import { Modal, Button, Title, Box, View, Flex } from '@tabeo/scarf'

import { children } from '@tabeo/sharpei/utils/propTypes'
import { useTranslation } from 'react-i18next'

const Link = styled(RouterLink)`
  flex: 1;
`

const BankVerificationRequired = ({
  innerRef,
  bankDetailsStatus,
  children,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <Modal ref={innerRef}>
      {({ isOpen, close }) => {
        const states = {
          correct: (
            <>
              <Title mb={4}>{t('Verify your bank details')}</Title>
              <View mb={6}>{children}</View>
              <Flex mx="auto" flexDirection={['column', 'row']}>
                <Button
                  variant="secondary"
                  onClick={close}
                  mb={[4, 0]}
                  mr={[0, 4]}
                  flex="1"
                >
                  {t('Cancel')}
                </Button>
                <Link to="/bank-verification">
                  <Button flex="1">{t('Verify Bank Details')}</Button>
                </Link>
              </Flex>
            </>
          ),
          verification_token_disclosed: (
            <>
              <Title mb={4}>{t('Bank details not verified')}</Title>
              <View mb={6}>
                {t('Please wait while we verify your bank details.')}
              </View>
              <Button variant="secondary" onClick={close}>
                {t('Ok')}
              </Button>
            </>
          ),
        }

        return (
          isOpen && (
            <Box
              maxWidth={['100%', '460px']}
              mx="auto"
              boxShadow={1}
              p={7}
              textAlign="center"
              {...rest}
            >
              {states[bankDetailsStatus]}
            </Box>
          )
        )
      }}
    </Modal>
  )
}

BankVerificationRequired.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  bankDetailsStatus: PropTypes.string,
  children,
}

export default BankVerificationRequired
