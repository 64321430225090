import { Flex, Panel, Text, View } from '@tabeo/scarf'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ListItem = ({ state, createdAt, odd }) => {
  const { t } = useTranslation()
  const stateMap = {
    sent: t(`Sent to customer`),
    opened: t(`Opened by customer`),
    canceled: t('Cancelled'),
    settled: t('Transaction settled'),
    applying: t('Application started'),
    transferred: t('Transaction transferred'),
    approved: t('Reviewing credit offer'),
    funds_on_the_way: t('Payment on the way'),
    card_declined: t('Card declined'),
    application_failed: t('Application unsuccessful'),
  }

  return (
    <Flex minHeight="50px" bg={odd ? 'sky.3' : undefined} py={3} px={5}>
      <Text>{stateMap[state] || state.replace(/_/g, ' ').capitalize()}</Text>
      <Text color="ink.2" flex="0 0 auto">
        {' '}
        {moment(createdAt).format('hh:mm a, DD MMM YYYY')}
      </Text>
    </Flex>
  )
}

ListItem.propTypes = {
  state: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  odd: PropTypes.bool,
}

const HistoryPanel = ({ history, sentAt, getCurrentState }) => {
  const { t } = useTranslation()
  const normalizedHistoryMap = [...history]
    .sort((a, b) => (a.at > b.at ? -1 : 1))
    .reduce((mem, item) => {
      const state = getCurrentState(item.to)
      mem[state] = {
        ...item,
        state,
      }
      return mem
    }, {})

  const normalizedHistory = Object.keys(normalizedHistoryMap).map(
    key => normalizedHistoryMap[key]
  )

  normalizedHistory.push({
    state: 'sent',
    id: 'first-id-1',
    at: sentAt,
  })

  return (
    <Panel title={t('History')} mb={6}>
      <View bg="white">
        {normalizedHistory.map((item, index) => (
          <ListItem
            key={item.id}
            state={item.state}
            createdAt={item.at}
            odd={index % 2 === 1}
          />
        ))}
      </View>
    </Panel>
  )
}

HistoryPanel.propTypes = {
  history: PropTypes.array.isRequired,
  sentAt: PropTypes.string.isRequired,
  getCurrentState: PropTypes.func.isRequired,
}

export default HistoryPanel
