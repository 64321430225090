import {
  Box,
  Button,
  Clickable,
  Container,
  Flex,
  Spinner,
  Text,
  Title,
  View,
  usePromise,
} from '@tabeo/scarf'
import { toast } from '@tabeo/scarf2'
import Terms from 'components/TermsAndConditionDocument'
import { Trans, useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { useDocument } from 'resources/Document'
import styled from 'styled-components'
import { useMerchant } from '../../resources/Merchant'
import Layout from './Layout'

const FixedContainer = styled(View)`
  position: fixed;
  bottom: 0;
  left: 0;
  background: white;
  width: 100%;
  z-index: 5;
`

function AcceptTerms() {
  const { t } = useTranslation()

  const { data: merchant, resource } = useMerchant()

  const [trigger, { pending }] = usePromise(async (id: number) => {
    try {
      await resource?.acceptTerms(id)
      toast(t('Merchant T&Cs accepted'))
    } catch (e) {
      toast.error(t('There was a problem, pls try again later.'))
    }
  })

  const { newTermsAndConditions } = merchant || { newTermsAndConditions: [] }

  const doc = newTermsAndConditions[0]

  const { data: document } = useDocument({ id: doc?.id })

  if (merchant && !doc) {
    return <Redirect to="/" />
  }

  return (
    <Layout>
      <Title mb={4}>{t('Update to our Merchant Terms and Conditions')}</Title>
      <Text mb={6}>
        <Trans>
          We&apos;re updating our Merchant Terms and Conditions effective 4 July
          2024. We recently emailed you to explain what&apos;s new.
          <br /> <br /> If you have not opted-in, we may assume from your
          continued use of our platform that you agree to the changes. If you
          have any questions, contact{' '}
          <Clickable
            fontWeight="medium"
            display="inline"
            as="a"
            href="mailto:growth@tabeo.co.uk"
            target="_blank"
          >
            growth@tabeo.co.uk
          </Clickable>
          .
        </Trans>
      </Text>
      <Flex mx={[-3, 0]} mb="80px">
        <Box borderRadius={[0, 1]}>
          {merchant && document ? (
            <Terms content={document.content} version={document.version} />
          ) : (
            <Spinner />
          )}
        </Box>
      </Flex>
      <FixedContainer>
        <Container
          bg={['default.sky', 'white']}
          maxWidth="720px"
          py={4}
          px={[4, 0]}
          wrapProps={{ borderTop: 1 }}
          display={['flex', 'block']}
          flexDirection="row-reverse"
        >
          <Button
            onClick={() => trigger(document?.id)}
            loading={pending}
            mr={[0, 4]}
            flex="1"
          >
            {t('Accept')}
          </Button>
        </Container>
      </FixedContainer>
    </Layout>
  )
}

export default AcceptTerms
