import { Caption, Flex, Icons, Text } from '@tabeo/scarf'
import CustomOption from 'components/CustomOption'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import formatClinicianName from 'utils/formatClinicianName'
import { getRoleLabelBySector } from '../constants'

const ClinicianOption = React.forwardRef((props, ref) => {
  const { t } = useTranslation()
  const {
    data: { sector },
  } = useMerchant()

  const { isFocused, option, isDisabled, shouldDisplayBoardIds = true } = props

  const { boards, role } = option.value?.clinician || option.value || {}

  if (option.value === 'new') {
    return (
      <CustomOption ref={ref} {...props}>
        <Flex justifyContent="flex-start">
          <Icons.X
            transform="rotate(45deg)"
            fill={isFocused ? 'white' : 'ink.0'}
            height="16px"
            width="16px"
            mr={2}
            mb="2px"
          />
          <Text color={isFocused ? 'white' : 'ink.0'} fontSize={1}>
            {t('Add new clinician')}
          </Text>
        </Flex>
      </CustomOption>
    )
  }

  return (
    <CustomOption ref={ref} {...props} opacity={isDisabled ? '0.5' : undefined}>
      <Flex>
        <Caption fontWeight="semibold" color={isFocused ? 'white' : 'ink.0'}>
          {formatClinicianName(option.value)}
        </Caption>
        {shouldDisplayBoardIds && (
          <Caption color={isFocused ? 'white' : undefined} fontSize={0}>
            {boards
              ?.map(b => `${b.board.code} #${b.registrationNumber}`)
              .join(', ')}
          </Caption>
        )}
      </Flex>
      <Flex>
        <Caption color={isFocused ? 'white' : undefined} fontSize={0}>
          {getRoleLabelBySector(role, sector)}
        </Caption>
      </Flex>
    </CustomOption>
  )
})

ClinicianOption.propTypes = {
  option: PropTypes.object,
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  isFocused: PropTypes.bool,
  onFocus: PropTypes.func,
  isDisabled: PropTypes.bool,
  shouldDisplayBoardIds: PropTypes.bool,
}

export const ClinicianOptionWithoutBoardIds = React.forwardRef((props, ref) => {
  return <ClinicianOption {...props} ref={ref} shouldDisplayBoardIds={false} />
})

ClinicianOptionWithoutBoardIds.propTypes = ClinicianOption.propTypes

export default ClinicianOption
