import styled from 'styled-components'
import View from '../../View'

const DashedContainer = styled(View)`
  :focus {
    outline: none;
  }
`
DashedContainer.defaultProps = {
  borderWidth: '1px',
  borderStyle: 'dashed',
  borderColor: 'sky.0',
  borderRadius: 1,
  overflow: 'hidden',
}

export default DashedContainer
