import { Caption, Flex, Heading, View } from '@tabeo/scarf'
import Card from 'components/Transaction/ActionPanel/Card'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import icon from './assets/declined.svg'

function Canceled({ data, ...rest }) {
  const { stateMachineTransitions } = data
  const { t } = useTranslation()

  const canceledDate = stateMachineTransitions.find(e =>
    ['canceled'].includes(e.to)
  )?.at

  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Bill cancelled')}</Heading>
      </Flex>
      <Caption color="ink.1">
        {t('This bill was cancelled on {{date}}.', {
          date: moment(canceledDate).format('D MMM YYYY'),
        })}
      </Caption>
    </Card>
  )
}

Canceled.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Canceled
