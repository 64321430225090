import { shadeColor } from '../utils/alterColor'

const ink = ['#212B36', '#454F5B', '#637381', '#637381']

const sky = ['#C4CDD5', '#DFE3E8', '#F4F6F8', '#F9FAFB', '#FBFBFB']

export const generatePalette = (color) => {
  const versions = [-0.7, -0.45, -0.2, 0, 0.4, 0.7, 0.85]
  return versions.map((shade) => shadeColor(color, shade))
}

const blue = generatePalette('#3292ff')

const teal = generatePalette('#2c9fa5')

const sea = generatePalette('#0DAB76')

const green = generatePalette('#a5d606')

const yellow = generatePalette('#ffcd37')

const red = generatePalette('#ff6966')

const orange = generatePalette('#ff7010')

const colors = {
  default: {
    ink: ink[0],
    sky: sky[2],
    blue: blue[3],
    teal: teal[3],
    sea: sea[3],
    green: green[3],
    yellow: yellow[3],
    red: red[3],
    orange: orange[3],
  },
  ink,
  sky,
  blue,
  teal,
  sea,
  green,
  yellow,
  red,
  orange,
}

export default colors
