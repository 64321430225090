import { children } from '@tabeo/sharpei/utils/propTypes'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import Notifications from 'components/Notifications'
import PropTypes from 'prop-types'
import React from 'react'

const Layout = ({ children, isLoading }) => (
  <>
    <LayoutSideNavbar isLoading={isLoading}>
      <Container>
        <Notifications mb={6} />
        {children}
      </Container>
    </LayoutSideNavbar>
  </>
)

Layout.propTypes = {
  children,
  isLoading: PropTypes.bool,
}

export default Layout
