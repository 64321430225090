import { Button, Modal, usePromise } from '@tabeo/scarf'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as PlaneYellow } from 'components/checkout-payment/assets/plane-yellow.svg'
import LinkPaymentModal from 'components/LinkPayment/Modal/Modal'
import moment from 'moment'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLinkPayment } from 'resources/LinkPayment'

function Sent() {
  const { t } = useTranslation()

  const { data, resource } = useLinkPayment()

  const [triggerCancel, cancelState] = usePromise(
    resource?.cancel || (() => {})
  )

  const modal = useRef<Modal>()

  return (
    <>
      <LinkPaymentModal ref={modal} isReminder />
      <ActionCard data-testid="sent-action-panel">
        <ActionCard.Header>
          <PlaneYellow className="h-6 w-6" />
          {t('Sent')}
        </ActionCard.Header>
        <ActionCard.Body>
          {t(
            'This payment link was sent out {{elapsedTime}} but has not been viewed yet.',
            { elapsedTime: moment().to(data?.createdAt) }
          )}
        </ActionCard.Body>
        <ActionCard.Actions>
          <Button
            sizeVariant="sm"
            variant="secondary"
            onClick={() => modal.current?.open({ id: data?.id })}
          >
            {t('Send reminder')}
          </Button>
          <Button
            sizeVariant="sm"
            variant="secondary"
            onClick={triggerCancel}
            loading={cancelState.pending}
          >
            {t('Cancel')}
          </Button>
        </ActionCard.Actions>
        {cancelState.error && (
          <ActionCard.Error>
            {t('There was an error, please try again later.')}
          </ActionCard.Error>
        )}
      </ActionCard>
    </>
  )
}

export default Sent
