import {
  AddButton,
  Button,
  Caption,
  CurrencyInput,
  Flex,
  Heading,
  Icons,
  Panel,
  Prompt,
  Switch,
  Textarea,
  TextInput,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import { children } from '@tabeo/sharpei/utils/propTypes'
import {
  lengthRange,
  required,
  valueRange,
} from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FieldError from 'components/Form/FieldError'
import FormError from 'components/Form/FormError'
import PlanAccessibilityModal from 'components/PlanAccessibilityModal'
import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import React from 'react'
import { Field as FormField, Form, FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FeaturesList from './FeaturesList'
import IconSelect from './IconSelect'
import PerksList from './PerksList'

const lengthRange5to50 = lengthRange(5, 50)
const lengthRange5to150 = lengthRange(5, 150)
const lengthRange5to5000 = lengthRange(5, 5000)

const Container = styled(View)`
  overflow: visible;
  padding-top: 22px;
  padding-bottom: 1px;
`

const Section = ({ children, ...rest }) => (
  <View
    bg="sky.3"
    border={1}
    px={5}
    py={3}
    style={{ borderLeft: 'none', borderRight: 'none' }}
    {...rest}
  >
    <Title>{children}</Title>
  </View>
)

Section.propTypes = {
  children,
}

const containerProps = { px: 5 }

const Line = styled(View)`
  height: 1px;
  width: 100%;
`

Line.defaultProps = {
  bg: 'sky.1',
  mb: 5,
}

const Title = styled(Heading)``

Title.defaultProps = {
  color: 'ink.2',
}

const isBenefitValid = f => f && f.title && f.claimsLimit !== undefined

const initialValues = {
  planFeatures: [],
  planPerks: [],
  accessibility: 'public',
}

const CustomCurrencyInput = props => {
  const { t } = useTranslation()
  return (
    <Flex justifyContent="flex-start">
      <CurrencyInput width="140px" {...props} />
      <Prompt ml={3}>{t('Per month')}</Prompt>
    </Flex>
  )
}

const PlanForm = ({ onFormChange, onSubmit, merchant, ...rest }) => {
  const shareModal = React.useRef()
  const { t } = useTranslation()

  const range5to50 = valueRange(
    500,
    50000,
    t('Please specify an amount between {{min}} and {{max}}', {
      min: format(500),
      max: format(50000),
    })
  )
  const featuresValidator = ({ planFeatures, planPerks }) => {
    const validFeatures = planFeatures.filter(isBenefitValid)
    const validPerks = planPerks.filter(isBenefitValid)
    if (!validFeatures.length && !validPerks.length) {
      return {
        _features: t(
          'You must add at least one feature or perk to create a Care Plan'
        ),
      }
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      validate={featuresValidator}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        form: {
          mutators: { push },
        },
        errors,
        submitFailed,
        submitting,
      }) => {
        return (
          <>
            <Panel
              title={<Title>{t('Description')}</Title>}
              style={{ overflow: 'visible' }}
              mb={6}
            >
              <Container bg="white" {...rest}>
                <form onSubmit={handleSubmit}>
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={onFormChange}
                  />

                  <View mb={5}>
                    <Field
                      name="title"
                      label={t('Plan name')}
                      description={t('The name of your new care plan.')}
                      component={TextInput}
                      validate={[required, lengthRange5to50]}
                      containerProps={containerProps}
                      maxWidth="100%"
                    />

                    <Field
                      name="subtitle"
                      label={t('Short description')}
                      description={t(
                        'Briefly explain the key selling points of the Care Plan without diving into too much detail.'
                      )}
                      width="100%"
                      component={Textarea}
                      validate={[required, lengthRange5to150]}
                      containerProps={containerProps}
                      minHeight="88px"
                    />

                    <Field
                      name="description"
                      label={t('Long description')}
                      description={t(
                        'Expand on the various benefits of subscribing to this plan, who this plan is for and anything else that can convince people to sign up.'
                      )}
                      width="100%"
                      resize="vertical"
                      component={Textarea}
                      validate={[required, lengthRange5to5000]}
                      containerProps={containerProps}
                      minHeight="110px"
                    />
                  </View>

                  <Section>{t('Image')}</Section>
                  <Field
                    name="imageURL"
                    sector={merchant.sector}
                    component={IconSelect}
                    validate={required}
                    containerProps={{ mb: 0, px: 5, py: 0 }}
                  />

                  <Section mb={5}>{t('Pricing')}</Section>
                  <Field
                    name="price"
                    label={t('Plan price')}
                    description={t(
                      'How much you would charge clients each month.'
                    )}
                    component={CustomCurrencyInput}
                    validate={[required, range5to50]}
                    containerProps={containerProps}
                  />

                  <Section mb={5}>{t('Benefits')}</Section>

                  <View {...containerProps} mb={5}>
                    <Prompt mb={1}>{t('Covered treatments')}</Prompt>
                    <Caption mb={5}>
                      {t('Treatments that your patients can claim.')}
                    </Caption>
                    <FeaturesList errors={errors} sector={merchant.sector} />
                    <AddButton
                      type="button"
                      onClick={() => push('planFeatures', { claimsLimit: 1 })}
                    >
                      {t('Add a treatment')}
                    </AddButton>
                  </View>

                  <Line />

                  <View {...containerProps} mb={5}>
                    <Prompt mb={1}>{t('Additional perks')}</Prompt>
                    <Caption mb={5}>
                      {t(
                        'These can be discounts, promotions and other intangible extras.'
                      )}
                    </Caption>
                    <PerksList errors={errors} sector={merchant.sector} />
                    <AddButton
                      minWidth="auto"
                      type="button"
                      onClick={() => push('planPerks', { claimsLimit: 0 })}
                    >
                      {t('Add a perk')}
                    </AddButton>
                  </View>
                </form>
              </Container>
            </Panel>
            <View
              boxShadow={1}
              bg="white"
              borderRadius={1}
              border={1}
              mb={6}
              px={5}
              py={3}
            >
              <Flex mb={4}>
                <Prompt fontSize="18px">{t('Sharing settings')}</Prompt>
                <Icons.Questionmark
                  cursor="pointer"
                  onClick={() => shareModal.current.open()}
                />
              </Flex>
              <Flex>
                <Prompt>{t('Private mode')}</Prompt>
                <FormField
                  name="accessibility"
                  component={Switch}
                  validate={required}
                  parse={v => (v === false ? 'public' : 'private')}
                  format={v => v !== 'public'}
                />
              </Flex>
              <PlanAccessibilityModal innerRef={shareModal} />
            </View>
            {/* eslint-disable-next-line no-underscore-dangle */}
            {errors._features && submitFailed && (
              <FieldError
                message={t(
                  'To build a plan please add at least a feature or a perk'
                )}
                mt="-12px"
                mb={3}
              />
            )}
            <Button
              width="100%"
              mb={[6, 0]}
              loading={submitting}
              onClick={handleSubmit}
            >
              {t('Create care plan')}
            </Button>
            <FormError />
          </>
        )
      }}
    </Form>
  )
}

PlanForm.propTypes = {
  onFormChange: PropTypes.func,
  onSubmit: PropTypes.func,
  merchant: PropTypes.object,
}

export default PlanForm
