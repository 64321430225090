import PropTypes from 'prop-types'
import React from 'react'
import ScarfProps from '../../../../types/ScarfProps'
import Flex from '../../../Flex'
import * as Icons from '../../../Icons'
import Text from '../../../Text'

function Id({ children, ...rest }: ScarfProps) {
  return (
    <Flex justifyContent="flex-start" {...rest}>
      <Icons.Id
        fill="sky.0"
        viewBox="5 5 12 12"
        height="11px"
        width="11px"
        mb="1px"
        mr={1}
      />
      <Text fontSize="12px" color="ink.2">
        {children}
      </Text>
    </Flex>
  )
}

Id.propTypes = {
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Id
