import React from 'react'
import PropTypes from 'prop-types'

import { Flex, View, Panel, Prompt, Caption } from '@tabeo/scarf'

import visa from 'components/Payment/Options/assets/visa.svg'
import mastercard from 'components/Payment/Options/assets/mastercard.svg'
import { useTranslation } from 'react-i18next'

const images = {
  visa,
  mastercard,
}

const PaymentMethodPanel = ({ paymentSource, ...rest }) => {
  const { t } = useTranslation()
  if (!paymentSource) {
    return null
  }
  const { brand, lastFourDigits, expiryMonth, expiryYear } = paymentSource
  return (
    <Panel title={t('Payment method')} {...rest}>
      <View py={3} px={5} bg="white">
        <Flex flex="1">
          <Flex justifyContent="flex-start">
            {brand && images[brand.toLowerCase()] && (
              <View
                as="img"
                src={images[brand.toLowerCase()]}
                alt={brand}
                ml={1}
                mr={3}
              />
            )}
            <Flex flex="1" justifyContent="flex-start" data-no-track>
              <Prompt mr={2}>{brand}</Prompt>
              <Flex justifyContent="flex-start">
                <Caption fontSize="10px" color="ink.1">
                  ●●●●
                </Caption>
                <Prompt>{lastFourDigits}</Prompt>
              </Flex>
            </Flex>
          </Flex>
          <Prompt color="ink.2" data-no-track>
            {expiryMonth < 10 ? '0' : ''}
            {expiryMonth}/{`${expiryYear}`.slice(-2)}
          </Prompt>
        </Flex>
      </View>
    </Panel>
  )
}

PaymentMethodPanel.propTypes = {
  paymentSource: PropTypes.object,
}

export default PaymentMethodPanel
