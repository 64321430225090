import React from 'react'
import ScarfProps from '../../types/ScarfProps'
import Attributes, { AttributeProps } from '../Attributes'
import Flex from '../Flex'
import { Spinner } from '../Icons'
import { Caption } from '../Text'
import View from '../View'

export type RadioGroupItemProps = ScarfProps & {
  item: {
    label: string
    description: string
    attributes?: AttributeProps
  }
  selected?: boolean
  disabled?: boolean
  loading?: boolean
}

const RadioGroupItem = ({
  item,
  selected,
  disabled,
  loading,
  ...rest
}: RadioGroupItemProps) => (
  <Flex
    py={3}
    px={4}
    alignItems="flex-start"
    width="100%"
    bg={selected ? 'primary.6' : 'sky.3'}
    borderWidth="1px"
    borderStyle="solid"
    borderColor={selected ? 'primary.3' : 'sky.1'}
    zIndex={selected ? '20' : '0'}
    position="relative"
    cursor={disabled ? 'not-allowed' : 'pointer'}
    opacity={disabled ? '0.7' : '1'}
    _notFirst={{
      borderTop: !selected ? 1 : undefined,
      mt: '-1px',
    }}
    _first={{
      borderRadius: '4px 4px 0 0',
    }}
    _last={{
      borderRadius: '0 0 4px 4px',
    }}
    {...rest}
  >
    {loading ? (
      <Spinner
        width="18px"
        height="18px"
        ml="-2px"
        strokeWidth="12px"
        spinning
      />
    ) : (
      <Flex
        bg={selected ? 'primary.3' : 'white'}
        width="16px"
        height="16px"
        borderRadius="50%"
        borderWidth="1px"
        borderStyle="solid"
        borderColor={selected ? 'primary.3' : 'ink.2'}
        display="inline-flex"
        justifyContent="center"
        boxShadow={selected ? 'none' : 'inset 2px -2px 0 0 rgba(0, 0, 0, 0.1)'}
      >
        <View
          width="8px"
          height="8px"
          borderRadius="50%"
          bg={selected ? 'white' : 'transparent'}
        />
      </Flex>
    )}
    <View ml={3} flex="1">
      <Caption fontWeight="semibold" color="ink.0">
        {item.label}
      </Caption>
      <Caption>{item.description}</Caption>
      {item.attributes && <Attributes {...item.attributes} mt={2} />}
    </View>
  </Flex>
)

export default RadioGroupItem
