import React from 'react'
import styled from 'styled-components'
import usePromise from '../../hooks/usePromise'
import ScarfProps from '../../types/ScarfProps'
import formatDate from '../../utils/formatDate'
import Flex from '../Flex'
import Text, { Caption, Clickable } from '../Text'
import View from '../View'

const Header = styled(Flex)``
Header.defaultProps = {
  justifyContent: 'flex-start',
  alignItems: 'baseline',
  mb: 1,
}

const AuthorName = styled(Text)``
AuthorName.defaultProps = {
  fontSize: '14px',
  lineHeight: '16px',
  color: 'ink.1',
  fontWeight: 'semibold',
}

const CreatedAt = styled(Text)``
CreatedAt.defaultProps = {
  fontSize: '14px',
  color: 'ink.2',
  ml: 2,
}

const Body = styled(Text)`
  white-space: pre-line;

  /* Forcing break */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
`
Body.defaultProps = {
  fontSize: '14px',
  lineHeight: '24px',
  color: 'ink.1',
  px: 3,
  py: 2,
  borderRadius: 1,
  border: 1,
  bg: 'white',
}

const Actions = styled(Flex)``
Actions.defaultProps = {
  justifyContent: 'flex-start',
  mt: 1,
}

const ActionLabel = styled(Clickable)``
ActionLabel.defaultProps = {
  fontSize: '14px',
  color: 'ink.2',
  fontWeight: 'regular',
  mr: 2,
}

export function linkify(text: string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g
  return text.split(urlRegex).map((part) => {
    if (part.match(urlRegex)) {
      return (
        <Clickable as="a" href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </Clickable>
      )
    }
    return part
  })
}

export interface CommentItem {
  id: number
  userName: string
  content: string
  createdAt: string
  userID: number
}

export type CommentProps = ScarfProps & {
  data: CommentItem
  onDelete?: (comment: CommentItem) => any
}

function Comment({ data, onDelete, ...rest }: CommentProps) {
  const [trigger, { pending, error }] = usePromise(onDelete || (() => null))
  const date = new Date(data.createdAt)
  const formattedDate = formatDate(date, true)
  return (
    <View {...rest}>
      <Header>
        <AuthorName data-testid="comment-author">{data?.userName}</AuthorName>
        <CreatedAt data-testid="comment-created-at">{formattedDate}</CreatedAt>
      </Header>
      <Body data-testid="comment-body">{linkify(data?.content)}</Body>
      {onDelete && (
        <Actions>
          <ActionLabel
            onClick={pending ? undefined : () => trigger(data)}
            cursor={pending ? 'progress' : undefined}
          >
            Delete
          </ActionLabel>
        </Actions>
      )}
      {!!error && (
        <Caption color="red.2" my={2}>
          {error?.message || 'Woops, there was an error.'}
        </Caption>
      )}
    </View>
  )
}

Comment.Header = Header
Comment.AuthorName = AuthorName
Comment.CreatedAt = CreatedAt
Comment.Body = Body

export default Comment
