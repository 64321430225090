import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import TwoColumns, { Column } from 'components/layouts/TwoColumns'
import { useState } from 'react'
import Form, { FormValues } from './Form'
import Overview, { formValuesToData } from './Overview'

function NewPlanV2() {
  const [formValues, setFormValues] = useState<Partial<FormValues>>()

  return (
    <LayoutSideNavbar>
      <Container>
        <TwoColumns>
          <Column>
            <Form onFormChange={setFormValues} />
          </Column>
          <Column position="sticky" top={0}>
            <Overview data={formValuesToData(formValues)} />
          </Column>
        </TwoColumns>
      </Container>
    </LayoutSideNavbar>
  )
}

export default NewPlanV2
