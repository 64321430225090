import React from 'react'

function fetchStyle(url) {
  return new Promise((resolve, reject) => {
    const link = document.createElement('link')
    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.onload = resolve
    link.onerror = reject
    link.href = url
    const headScript = document.querySelector('script')
    headScript.parentNode.insertBefore(link, headScript)
  })
}

const initialState = {
  loaded: false,
  pending: true,
  error: null,
}

function useStyle(src) {
  const [state, setState] = React.useState(initialState)
  React.useEffect(() => {
    const styles = Array.from(document.getElementsByTagName('link'))
    if (styles.find(s => s.href === src)) {
      setState({
        ...initialState,
        loaded: true,
      })
      return () => {}
    }
    fetchStyle(src)
      .then(() =>
        setState({
          ...initialState,
          loaded: true,
          pending: false,
        })
      )
      .catch(error =>
        setState({
          ...initialState,
          pending: false,
          error,
        })
      )
  }, [src])
  return state
}

export default useStyle
