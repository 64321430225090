import get from 'lodash.get'

export const LIVE_STATUSES = [
  'manual_review_omitted',
  'manual_review_passed',
  'manual_review_required',
  'payment_platform_id_creation_pending',
  'payment_platform_id_created',
  'completed',
]

const MANUAL_REVIEW_FAILED_STATUSES = ['manual_review_failed']

const SUSPENDED_STATUSES = ['suspended']

const PERSONAL_CARE_SECTORS = [
  'dental_services',
  'other_health_care',
  'optical_care',
  'cosmetic_surgery',
  'private_hospital',
  'physiotherapy',
  'skin_laser_facial_treatments',
  'hair_transplant',
  'hearing_aids',
]

const VET_SECTORS = ['veterinary_services']

const HEALTHCARE_SECTORS = [...PERSONAL_CARE_SECTORS, ...VET_SECTORS]

const RETAIL_SECTORS = ['specialist_retail']

const getState = status => {
  if (LIVE_STATUSES.includes(status)) {
    return 'live'
  }
  if (MANUAL_REVIEW_FAILED_STATUSES.includes(status)) {
    return 'manual_review_failed'
  }
  if (SUSPENDED_STATUSES.includes(status)) {
    return 'suspended'
  }
  return 'onboarding'
}

export default function (merchant) {
  const state = getState(merchant.status)
  const isNewTermsAndConditionsAvailable = !!(
    merchant.newTermsAndConditions || []
  ).length

  const isLeadGenerationEnabled = get(
    merchant,
    'flags.is_lead_generation_enabled',
    false
  )
  const isCarePlansEnabled = [
    'dental_services',
    'veterinary_services',
  ].includes(merchant.sector)

  const isWhitelabelEnabled = get(
    merchant,
    'flags.is_white_labeling_capable',
    false
  )

  const isSDC = !!merchant?.merchantRiskProfiles?.find(p =>
    ['SDC-unfunded', 'SDC-NL', 'SDC-IT', 'SDC-FR', 'SDC-FR-UNFUNDED'].includes(
      p.lenderCode
    )
  )

  const isDrSmileIt = !!merchant?.merchantRiskProfiles?.find(p =>
    ['DR.S-IT', 'DR.S-IT-UNFUNDED'].includes(p.lenderCode)
  )

  const isUK = merchant?.countryCode === 'gb'
  const isNL = merchant?.countryCode === 'nl'
  const isIT = merchant?.countryCode === 'it'
  const isFR = merchant?.countryCode === 'fr'

  const isEU = isNL || isIT || isFR

  const isAutoConfirmationFlow =
    merchant?.loanConfirmationMode === 'auto_confirmation'
  const isConsumerConfirmationFlow =
    merchant?.loanConfirmationMode === 'consumer_confirmation'
  const isMerchantConfirmationFlow =
    merchant?.loanConfirmationMode === 'merchant_confirmation'

  const isBankAccountVerified = merchant?.bankDetails?.status === 'passed'

  const hasHSBCnet = !!merchant.merchantFundConfigs?.find(
    c => c.fundType === 'hsbcnet'
  )

  const isRetail = RETAIL_SECTORS.includes(merchant.sector)
  const isVet = VET_SECTORS.includes(merchant.sector)
  const isHealthcare = HEALTHCARE_SECTORS.includes(merchant.sector)
  const uploads = [...(merchant.uploads || [])].sort((a, b) => a.id - b.id)

  const isAmexCapable = merchant?.activeFeeGroup?.isAmexCapable

  return {
    ...merchant,
    state,
    uploads,
    isNewTermsAndConditionsAvailable,
    isLeadGenerationEnabled,
    isCarePlansEnabled,
    isWhitelabelEnabled,
    isSDC,
    isDrSmileIt,
    isAutoConfirmationFlow,
    isConsumerConfirmationFlow,
    isMerchantConfirmationFlow,
    isBankAccountVerified,
    hasHSBCnet,
    isRetail,
    isVet,
    isHealthcare,
    isUK,
    isNL,
    isIT,
    isFR,
    isEU,
    isAmexCapable,
  }
}
