import { Box, Spinner, Text } from '@tabeo/scarf'
import BaseLayout from 'components/Layout'
import TermsAndConditionDocument from 'components/TermsAndConditionDocument'
import { useParams } from 'react-router-dom'
import { useDocument } from 'resources/Document'
import React from 'react'

function DocumentPage() {
  const { documentId } = useParams()
  const { data: document } = useDocument({
    id: +documentId,
  })

  if (!document) {
    return <Spinner />
  }

  const { content, type, version, countryCode } = document

  return (
    <BaseLayout bg={['white', 'sky.2']}>
      <Box variant="content">
        {type === 'merchant_terms_and_conditions' ? (
          <TermsAndConditionDocument
            version={version}
            content={content}
            countryCode={countryCode}
          />
        ) : (
          <Text
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        )}
      </Box>
    </BaseLayout>
  )
}

export default DocumentPage
