import { Caption, Flex, Heading, View } from '@tabeo/scarf'
import icon from 'components/ActionPanel/assets/settled-approved.svg'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '../../../components/Transaction/ActionPanel/Card'
import CommonActions from './CommonActions'

function Consultation({ resource, ...rest }) {
  const { firstName, appointment } = resource.data
  const { t } = useTranslation()

  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Appointment date set')}</Heading>
      </Flex>
      <Caption color="ink.1" mb={4}>
        {t(
          '{{leadFirstName}} is qualified and his appointment is booked in for {{date}}.',
          {
            leadFirstName: firstName,
            date: moment(appointment.scheduledAt).format(
              'hh:mm a, DD MMM YYYY'
            ),
          }
        )}
      </Caption>
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="flex-start"
        alignItems={['stretch', 'center']}
      >
        <CommonActions resource={resource} />
      </Flex>
    </Card>
  )
}

Consultation.propTypes = {
  resource: PropTypes.object.isRequired,
}

export default Consultation
