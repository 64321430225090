import { DataTable, GridTable, Title } from '@tabeo/scarf'
import { State } from '@tabeo/scarf/dist/components/DataTable/context'
import AmountFilter from 'components/AmountFilter'
import DatoNotifications from 'components/Notifications/DatoNotifications'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useRefunds } from 'resources/Refunds'
import ListItem from './ListItem'

function mapStateToParams(state: State) {
  const { activeTabs, activeFilters } = state

  const status = activeTabs?.flatMap(tab => tab.statuses)

  const updatedAt = activeFilters?.updatedAt?.value?.map(({ value }: any) => {
    let start
    let end

    switch (value) {
      case 'today':
        start = moment().startOf('day')
        end = null
        break
      case 'yesterday':
        start = moment().subtract(1, 'days').startOf('day')
        end = moment().subtract(1, 'days').endOf('day')
        break
      case '3-7d':
        start = moment().subtract(7, 'days')
        end = moment().subtract(2, 'days').endOf('day')
        break
      case '7-14d':
        start = moment().subtract(14, 'days')
        end = moment().subtract(7, 'days')
        break
      case '14-30d':
        start = moment().subtract(30, 'days')
        end = moment().subtract(14, 'days')
        break
      case '30+d':
        start = null
        end = moment().subtract(30, 'days')
        break
      default:
        return null
    }

    return `date_range("${start?.format('YYYY-MM-DD HH:mm:ss') || ''}"-"${
      end?.format('YYYY-MM-DD HH:mm:ss') || ''
    }")`
  })

  const amountRange = activeFilters?.amount?.value
  const amount = amountRange
    ? `range(${amountRange.min || ''}-${amountRange.max || ''})`
    : undefined

  return {
    ...state,
    conditions: {
      status,
      updatedAt,
      amount,
    },
  }
}

function Refunds() {
  const { t } = useTranslation()

  const filters = [
    {
      name: 'amount',
      label: 'Amount',
      initialValue: {
        min: '',
        max: '',
      },
      component: AmountFilter,
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      options: [
        { label: t('Today'), value: 'today' },
        { label: t('Yesterday'), value: 'yesterday' },
        { label: t('3-7 days ago'), value: '3-7d' },
        { label: t('7-14 days ago'), value: '7-14d' },
        { label: t('14-30 days ago'), value: '14-30d' },
        { label: t('More than 30 days ago'), value: '30+d' },
      ],
      initialValue: [],
      selectProps: {
        maxHeight: ['100%', '100%'],
      },
    },
  ]

  const tabs = [
    {
      label: t('All'),
      statuses: [],
      group: 'singleSelect',
    },
    {
      label: t('Pending approval'),
      statuses: ['requested'],
      group: 'groupSelect',
    },
    {
      label: t('Executed'),
      statuses: ['succeeded'],
      group: 'groupSelect',
    },
    {
      label: t('Declined'),
      statuses: ['declined'],
      group: 'groupSelect',
    },
    {
      label: t('Cancelled'),
      statuses: ['canceled'],
      group: 'groupSelect',
    },
    {
      label: t('Failed'),
      statuses: ['failed'],
      group: 'groupSelect',
    },
  ]

  return (
    <LayoutSideNavbar>
      <Container maxWidth="992px">
        <DatoNotifications />
        <Title mb={4}>{t('Refunds')}</Title>

        <DataTable
          data-testid="refunds-table"
          mapStateToParams={mapStateToParams}
          // @ts-ignore
          resourceHook={useRefunds}
          tabs={tabs}
          paginationLimit={20}
          searchPlaceholder={t(
            'Search by payment ID, patient name, email address or phone number...'
          )}
          filters={filters}
        >
          {({ items }) => (
            <GridTable
              templateColumns={[
                'minmax(139px,auto) minmax(200px, 1fr) minmax(140px, 1fr) minmax(140px, 1fr) minmax(139px, 1fr)',
              ]}
              minWidth="650px"
            >
              <DataTable.Head
                columns={[
                  { label: 'Payment ID' },
                  { label: 'Patient', orderBy: 'firstName' },
                  {
                    label: 'Amount',
                    orderBy: 'amount',
                    cellProps: {
                      justifyContent: 'flex-end',
                    },
                  },
                  {
                    label: 'Status',
                  },
                  {
                    label: 'Updated',
                    orderBy: 'updatedAt',
                    cellProps: {
                      justifyContent: 'flex-end',
                    },
                  },
                ]}
                // @ts-ignore
                display={['none', 'contents']}
              />
              {items.map(item => (
                <ListItem key={item.id} data={item} />
              ))}
            </GridTable>
          )}
        </DataTable>
      </Container>
    </LayoutSideNavbar>
  )
}

export default Refunds
