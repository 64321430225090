import { plansorchestrator } from '@tabeo/platform-api-client'
import { Panel } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useVendor } from 'resources/Vendor'
import { twMerge } from 'tailwind-merge'
import placeholderImage from './assets/placeholder.png'
import { FormValues } from './Form'

type OverviewProps = {
  data?: {
    name?: string
    imageUrl?: string
    description?: string
    price?: number
    excludedGoodsServices?: string
    treatments?: {
      name: string
      quantity?: number
    }[]
    perks?: {
      name: string
      quantity?: number
    }[]
  }
}

export function formValuesToData(
  formValues?: Partial<FormValues>
): OverviewProps['data'] {
  const { treatments, perks, imageUrl, ...rest } = formValues || {}

  return {
    ...rest,
    treatments: treatments
      ?.filter(t => t?.merchantTreatment)
      .map(t => ({
        name: t.otherName || t.merchantTreatment.treatment.name,
        quantity: t.quantity,
      })),
    perks: perks
      ?.filter(p => p?.name)
      .map(p => ({
        name: p.name,
        quantity: undefined,
      })),
    imageUrl: imageUrl || placeholderImage,
  }
}

export function planToData(
  plan?: plansorchestrator.ModelsPlan
): OverviewProps['data'] {
  if (!plan) return

  const { treatments, perks, ...rest } = plan || {}

  return {
    ...rest,
    treatments: treatments?.map(t => ({
      name: t.name,
      quantity: t.quantity,
    })),
    perks: perks?.map(p => ({
      name: p.name,
      quantity: p.quantity,
    })),
  }
}

function Overview({ data }: OverviewProps) {
  const { t } = useTranslation()
  const { data: vendor } = useVendor()
  const [isExclusionsOpen, setIsExclusionsOpen] = useState(false)

  const {
    name,
    imageUrl,
    description,
    price,
    excludedGoodsServices,
    treatments,
    perks,
  } = data || {}

  const shouldHaveExclusionsToggle =
    excludedGoodsServices && excludedGoodsServices.length > 40

  const items = [treatments, perks].flat().filter(Boolean)

  if (items.length === 0) {
    items.push({
      name: t('No items selected'),
      quantity: undefined,
    })
  }

  return (
    <Panel
      header={
        <h3 className="heading mx-auto font-normal text-tabeo-ink-2">
          {t('What your member will see')}
        </h3>
      }
      data-testid="plan-overview"
    >
      {!data ? (
        <div>
          <div className="heading px-3 py-7 text-center font-normal text-tabeo-ink-2">
            {t('No plan selected')}
          </div>
        </div>
      ) : (
        <div className="p-5">
          <div className="border-b border-tabeo-sky-1 pb-5">
            {imageUrl && !imageUrl.includes('no-image') && (
              <div className="mb-5 aspect-square w-[90px]">
                <img src={imageUrl} className="h-full w-full object-cover" />
              </div>
            )}
            <p className="text-base">{vendor?.tradingName}</p>
            <h4 className="subtitle">{name || t('Plan name')}</h4>
            <p className="heading font-normal text-tabeo-ink-2">
              {description || t('Short description')}
            </p>
            <div
              className="mt-2.5 flex items-baseline gap-1"
              data-testid="plan-price"
            >
              <span className="subtitle">{format(Number(price || 0))}</span>
              <span className="caption text-tabeo-ink-2">{t('per month')}</span>
            </div>
          </div>
          <div className="pt-5">
            <div data-testid="plan-benefits">
              <h5 className="subheading mb-4 text-tabeo-ink-1">
                {t('Included goods/services')}
              </h5>
              <ul className="space-y-4">
                {items.map((item, index) => (
                  <li
                    key={index}
                    className="flex items-center justify-between gap-2 rounded border border-tabeo-sky-1 p-2.5"
                  >
                    <span className="text-base">{item?.name}</span>
                    {!!item?.quantity && (
                      <span className="rounded-full bg-tabeo-primary-5 px-3 py-1 text-xs">
                        x{item?.quantity}
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-5">
              <h5 className="subheading text-tabeo-ink-1">
                {t('Excluded goods/services')}
              </h5>
              <div
                className={twMerge(
                  !isExclusionsOpen &&
                    'flex items-baseline justify-between gap-1'
                )}
              >
                <p
                  className={twMerge(
                    'whitespace-pre-wrap pt-1 text-xs',
                    !isExclusionsOpen &&
                      shouldHaveExclusionsToggle &&
                      'line-clamp-1'
                  )}
                >
                  {excludedGoodsServices || t('Excluded goods/services')}
                </p>
                {shouldHaveExclusionsToggle && (
                  <button
                    type="button"
                    className="anchor whitespace-nowrap text-sm"
                    onClick={() => setIsExclusionsOpen(!isExclusionsOpen)}
                  >
                    {isExclusionsOpen ? t('Show less') : t('Show more')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Panel>
  )
}

export default Overview
