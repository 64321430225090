import { Feed, Panel } from '@tabeo/scarf'
import Refund from 'components/checkout-payment/ActivityPanel/Refund'
import { getStateTransitionsForRefunds } from 'pages/TerminalPayment/ActivityPanel'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NormalizedLinkPayment } from 'types/NormalizedLinkPayment'
import State from './State'

function mapEventsToStateTransitions(data: NormalizedLinkPayment) {
  const { events } = data

  /**
   * Event names are matching with the Terminal Payment ones, because the
   * backend uses the same entity under the hood.
   */

  const createdEvent = events?.find(
    ({ eventName }) => eventName === 'CreateTerminalPaymentSuccess'
  )

  const viewedEvent = events?.find(
    ({ eventName }) => eventName === 'TerminalPaymentViewed'
  )

  const capturedEvent = events?.find(
    ({ eventName }) => eventName === 'ChargeSucceeded'
  )

  const canceledEvent = events?.find(
    ({ eventName }) => eventName === 'CancelTerminalPaymentSuccess'
  )

  const cancelFailedEvent = events?.find(
    ({ eventName }) => eventName === 'CancelTerminalPaymentFail'
  )

  const expiredEvent = events?.find(
    // TODO: update event name, CancelTerminalPaymentSuccess is what backend uses for expire
    ({ eventName }) => eventName === 'ExpireTerminalPaymentSuccess'
  )

  const settledEvent = events?.find(
    ({ eventName }) => eventName === 'MerchantTerminalPaymentPaymentReceived'
  )

  const failedEvent = events?.find(
    ({ eventName }) => eventName === 'ChargeFailed'
  )

  return [
    createdEvent && {
      id: `event-${createdEvent.id}`,
      type: 'state',
      date: createdEvent.createdAt,
      data: {
        to: 'created',
        at: createdEvent.createdAt,
      },
    },
    viewedEvent && {
      id: `event-${viewedEvent.id}`,
      type: 'state',
      date: viewedEvent.createdAt,
      data: {
        to: 'viewed',
        at: viewedEvent.createdAt,
      },
    },
    capturedEvent && {
      id: `event-${capturedEvent.id}`,
      type: 'state',
      date: capturedEvent.createdAt,
      data: {
        to: 'captured',
        at: capturedEvent.createdAt,
      },
    },
    canceledEvent && {
      id: `event-${canceledEvent.id}`,
      type: 'state',
      date: canceledEvent.createdAt,
      data: {
        to: 'canceled',
        at: canceledEvent.createdAt,
      },
    },
    cancelFailedEvent && {
      id: `event-${cancelFailedEvent.id}`,
      type: 'state',
      date: cancelFailedEvent.createdAt,
      data: {
        to: 'cancel_failed',
        at: cancelFailedEvent.createdAt,
      },
    },
    expiredEvent && {
      id: `event-${expiredEvent.id}`,
      type: 'state',
      date: expiredEvent.createdAt,
      data: {
        to: 'expired',
        at: expiredEvent.createdAt,
      },
    },
    settledEvent && {
      id: `event-${settledEvent.id}`,
      type: 'state',
      date: settledEvent.createdAt,
      data: {
        to: 'settled',
        at: settledEvent.createdAt,
      },
    },
    failedEvent && {
      id: `event-${failedEvent.id}`,
      type: 'state',
      date: failedEvent.createdAt,
      data: {
        to: 'failed',
        at: failedEvent.createdAt,
      },
    },
  ].filter(Boolean) as {
    id: string
    type: string
    date: string
    data: any
  }[]
}

export type ActivityPanelProps = {
  data: NormalizedLinkPayment
} & React.ComponentProps<typeof Panel>

function ActivityPanel({ data }: ActivityPanelProps) {
  const { t } = useTranslation()

  const sortedItems = [
    ...mapEventsToStateTransitions(data),
    ...getStateTransitionsForRefunds(data),
  ].sort((a, b) => (a.date < b.date ? 1 : -1))

  return (
    <Panel title={t('Activity')} data-testid="activity-panel">
      <Feed.Builder
        itemBuilders={{
          state: State,
          refund: Refund,
        }}
        items={sortedItems}
      />
    </Panel>
  )
}

export default ActivityPanel
