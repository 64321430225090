import {
  Heading,
  Panel,
  Prompt,
  Subtitle,
  Text,
  Title,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import { ThemedLogo as Logo } from 'components/Logo'
import PaymentOptions from 'components/Payment/Options'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function PayNow(props) {
  const { title, price, merchantName, consumerFirstName } = props
  const { t } = useTranslation()

  return (
    <Panel
      header={
        <Heading color="ink.2" textAlign="center" flex="1">
          {t('What your client will see')}
        </Heading>
      }
      mb={6}
    >
      <View p={5} bg="white" textAlign="center">
        <Logo maxWidth="200px" maxHeight="100px" mx="auto" mb={4} />
        {consumerFirstName && (
          <Title mb={2}>
            {t('Hi {{consumerFirstName}}', { consumerFirstName })}
          </Title>
        )}
        <Prompt mb={4}>{t('You can pay online at any time')}</Prompt>
        <View
          border={1}
          borderRadius={1}
          bg="sky.3"
          p={4}
          mb={5}
          maxWidth="400px"
          mx="auto"
        >
          {title && <Subtitle mb={1}>{title}</Subtitle>}
          <Heading color="default.primary">{merchantName}</Heading>
          {!!price && (
            <Text fontSize="32px" lineHeight="40px" mt={2}>
              {format(price)}
            </Text>
          )}
        </View>
        <PaymentOptions />
      </View>
    </Panel>
  )
}

PayNow.propTypes = {
  title: PropTypes.string,
  price: PropTypes.number,
  merchantName: PropTypes.string,
  consumerFirstName: PropTypes.string,
}
