import { Text, themeGet } from '@tabeo/scarf'
import styled from 'styled-components'

const BaseHtml = styled(Text)`
  * {
    box-sizing: border-box;
  }

  a {
    color: ${themeGet('colors.default.primary')};
  }

  h1,
  h2 {
    padding-bottom: 30px;
    text-align: center;
    font-weight: bold;
  }

  h3 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 16px;
  }

  ol {
    counter-reset: section;
    list-style-type: none;
    width: 100%;
    padding: 0;
  }

  div > ol > li > h5 {
    font-weight: bold;
  }

  ul {
    list-style: initial;
    margin-left: 40px;
    li {
      &:before,
      &:after {
        content: none;
      }
      p {
        margin-left: 0;
      }
    }
  }

  ol ol ol {
    padding-left: 40px;
    list-style-type: none;
    text-align: left;
  }

  li {
    position: relative;
    margin-bottom: 20px;
  }

  li li {
    margin-top: 0;
  }

  li:before {
    position: absolute;
    counter-increment: section;
    content: counters(section, '.') '. ';
    width: 30px;
    font-size: 100%;
    font-weight: bold;
  }

  li li:before {
    font-size: 14px;
    font-weight: normal;
  }

  li li li:before {
    content: counter(section, lower-alpha) ') ';
  }

  li li li li:before {
    content: counter(section, lower-roman) ') ';
  }

  li p {
    margin: 0 0 16px 40px;
  }

  li li p {
    margin: 0 0 16px 40px;
  }

  li li li p {
    margin: 0 0 16px 20px;
  }

  li div {
    margin: 0 0 16px 40px;
  }

  td {
    vertical-align: top;
  }

  td h3 {
    margin: 0;
  }

  td p {
    display: block;
    margin: 0;
  }

  table {
    font-size: 14px;
    border-collapse: collapse;
    border: 1px solid grey;
    width: 100%;
    margin-bottom: 20px;
  }

  table td {
    border: 1px solid grey;
    padding: 15px 10px;
  }

  table td:first-of-type {
    width: 180px;
  }

  span.caption {
    font-size: 12px;
  }

  .center {
    text-align: center;
  }
`

BaseHtml.defaultProps = {
  fontSize: ['14px', '16px'],
  'data-no-track': true,
}

export default BaseHtml
