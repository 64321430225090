import * as Tabs from '@radix-ui/react-tabs'
import { twMerge } from 'tailwind-merge'

export default function Content({ className, ...rest }: Tabs.TabsContentProps) {
  return (
    <Tabs.Content
      className={twMerge('mt-3 min-h-[164px] rounded border p-5', className)}
      {...rest}
    />
  )
}
