import {
  Box,
  Button,
  Caption,
  DateTime,
  Modal,
  NativeSelect,
  Text,
  TextInput,
  Title,
  View,
} from '@tabeo/scarf'
import { required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Form } from 'react-final-form'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

export const Container = styled(Box)`
  overflow: visible;
`

Container.defaultProps = {
  maxWidth: ['95%', '460px'],
  mx: 'auto',
  boxShadow: 2,
  p: [5, '40px'],
}

export class ClaimRegisterModal extends Component {
  constructor(props) {
    super(props)
    this.claimedAt = new Date(new Date().setMinutes(0))
  }

  handleSubmit = values => {
    const { onSubmit } = this.props
    return onSubmit({
      ...values,
      benefit: JSON.parse(values.benefit),
    })
  }

  render() {
    const {
      innerRef,
      benefits,
      consumer: { firstName },
      expiredAt,
      t,
    } = this.props

    // TODO:
    const options = benefits.map(b => ({
      label: `${b.title} ${
        b.claimsLimit !== null
          ? t('({{numberOfAvailableClaims}} left)', {
              numberOfAvailableClaims: b.availableClaims,
            })
          : ''
      }`,
      value: JSON.stringify(b),
    }))

    return (
      <Modal
        ref={innerRef}
        containerProps={{
          style: { overflowY: 'visible' },
          maxHeight: 'initial',
        }}
      >
        {({ isOpen, close, data }) =>
          isOpen && (
            <Container>
              <Form
                onSubmit={this.handleSubmit}
                initialValues={{
                  ...data,
                  claimedAt: this.claimedAt,
                }}
                validate={values => {
                  if (!values.benefit) {
                    return
                  }
                  const previousBillingYear = moment(expiredAt).subtract(
                    12,
                    'months'
                  )
                  const benefit = JSON.parse(values.benefit)
                  const isAvailable =
                    benefit.claimsLimit === null || benefit.availableClaims > 0
                  const benefitError = isAvailable
                    ? undefined
                    : t(
                        `The patient has already claimed all of the available '{{benefit}}' benefits in the current subscription year, please charge them separately. If you meant to register a claim from a previous subscription year please select a date before {{date}}.`,
                        {
                          benefit: benefit.title,
                          date: previousBillingYear.format('DD MMM YYYY'),
                        }
                      )
                  const isPreviousBillingYear = previousBillingYear.isAfter(
                    values.claimedAt
                  )
                  return {
                    claimNotAvaialble: !isPreviousBillingYear
                      ? benefitError
                      : undefined,
                  }
                }}
              >
                {({
                  handleSubmit,
                  submitting,
                  submitSucceeded,
                  values: { benefit },
                  errors,
                }) => {
                  const benefitType = ((benefit && JSON.parse(benefit)) || {})
                    .type
                  return submitSucceeded ? (
                    <View textAlign="center">
                      <Title mb={3}>{t('Claim registered')}</Title>
                      <Text mb={6}>
                        {t(
                          '{{leadFirstName}} will receive a confirmation notification shortly.',
                          { leadFirstName: firstName }
                        )}
                      </Text>
                      <Button variant="secondary" onClick={close}>
                        {t('Close')}
                      </Button>
                    </View>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <Title mb={3}>{t('Register a claim')}</Title>
                      <Field
                        name="benefit"
                        label={t('Treatment or perk')}
                        component={NativeSelect}
                        options={options}
                        validate={[required]}
                        autoBlur
                      />
                      {benefitType && (
                        <>
                          {benefitType === 'feature' && (
                            <Field
                              name="person"
                              label={t('Clinician (optional)')}
                              placeholder={t('E.g. Dr. Jones')}
                              component={TextInput}
                            />
                          )}
                          <Field
                            name="claimedAt"
                            label={t('Claim date')}
                            component={DateTime}
                            validate={required}
                            containerProps={{
                              width: ['100%', '155px'],
                            }}
                            alignPopupToTop
                          />
                        </>
                      )}
                      <View>
                        {errors.claimNotAvaialble && (
                          <Caption color="red.3" mb={3} mt="-8px">
                            {errors.claimNotAvaialble}
                          </Caption>
                        )}
                        <Button
                          type="submit"
                          disabled={!benefitType || !!errors.claimNotAvaialble}
                          loading={submitting}
                        >
                          {t('Register claim')}
                        </Button>
                        <FormError />
                      </View>
                    </form>
                  )
                }}
              </Form>
            </Container>
          )
        }
      </Modal>
    )
  }
}

ClaimRegisterModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  benefits: PropTypes.array.isRequired,
  consumer: PropTypes.object.isRequired,
  expiredAt: PropTypes.string.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  t: PropTypes.func,
}

export default withTranslation()(ClaimRegisterModal)
