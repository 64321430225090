import { Flex, Title, View } from '@tabeo/scarf'
import { SendReceiptPanel } from 'components/checkout-payment/SendReceiptPanel'
import { TransactionDataForm } from 'components/checkout-payment/TransactionDataForm'
import Receipt from 'components/terminal/Receipt'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useTerminalPayment } from 'resources/TerminalPayment'
import TerminalPaymentContext from '../Context'
import { ReactComponent as SucceededIcon } from '../assets/succeeded-icon.svg'

function PaymentReceipt() {
  const { t } = useTranslation()
  const { state } = useContext(TerminalPaymentContext)
  const { data: tp, resource } = useTerminalPayment({
    id: state.terminalPaymentId,
  })

  const detailPageLink = `/terminal-payments/${tp?.id}`

  const history = useHistory()
  function handleTransactionDataFormSuccess() {
    history.push(detailPageLink)
  }

  return (
    <div className="space-y-6">
      <Flex justifyContent="center" textAlign="center">
        <View as={SucceededIcon} mr={2} />
        <Title>{t('Payment succeeded')}</Title>
      </Flex>
      <Receipt data={tp} />
      {tp?.basket && resource && (
        <SendReceiptPanel
          basket={tp?.basket}
          onReceiptSend={resource.sendReceipt}
        />
      )}
      {tp?.basket?.id && (
        <TransactionDataForm
          onSuccess={handleTransactionDataFormSuccess}
          closeLink={detailPageLink}
          basketId={tp?.basket?.id}
        />
      )}
    </div>
  )
}

export default PaymentReceipt
