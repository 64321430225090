import { Attributes, Panel, View } from '@tabeo/scarf'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Person = ({ data, ...rest }) => {
  const { beneficiary, consumer, consumerEmail } = data
  const { t } = useTranslation()

  return (
    <Panel title="Parent" {...rest}>
      <View p={5} borderBottom={1}>
        <Attributes
          sections={[
            [
              {
                label: t('Name'),
                value: `${consumer.firstName} ${consumer.lastName}`,
              },
              {
                label: t('Date of birth'),
                value: consumer.birthdate,
                formattedValue: moment(consumer.birthdate).format(
                  'DD MMM YYYY'
                ),
              },
              {
                label: t('Has pet insurance'),
                value: beneficiary.medicalInsuranceStatus?.capitalize(),
              },
            ],
          ]}
        />
      </View>

      <Attributes
        p={5}
        sections={[
          [
            { label: t('Email address'), value: consumerEmail },
            { label: t('Phone number'), value: consumer.mobilePhone },
            { label: t('Home address'), value: consumer?.addressDetails.Label },
          ],
        ]}
      />
    </Panel>
  )
}

Person.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Person
