import { View } from '@tabeo/scarf'
import styled from 'styled-components'

const Dot = styled(View)`
  width: 10px;
  height: 10px;
  margin: 6px;
  margin-right: 12px;
  display: inline-block;
  border-radius: 50%;
  flex: 0 0 auto;
`

Dot.defaultProps = {
  bg: 'default.primary',
}

export default Dot
