import { Button, View } from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Modal from './Modal'

class ClaimRegistration extends Component {
  handleFormSubmit = async ({ benefit, ...values }) => {
    const { onSubmit } = this.props
    try {
      await onSubmit(benefit, values)
    } catch (e) {
      return formError(e)
    }
  }

  render() {
    const { subscription, t, tReady, ...rest } = this.props

    if (!['active', 'inactive'].includes(subscription.state)) {
      return null
    }

    const features = (subscription.plan.planFeatures || []).map(i => ({
      ...i,
      type: 'feature',
    }))
    const perks = (subscription.plan.planPerks || []).map(i => ({
      ...i,
      type: 'perk',
    }))

    const benefits = [...features, ...perks]

    return (
      <View {...rest}>
        <Button
          sizeVariant="sm"
          variant="secondary"
          onClick={() =>
            this.modal.open({
              claimedAt: new Date(),
            })
          }
        >
          {t('Register claim')}
        </Button>
        <Modal
          onSubmit={this.handleFormSubmit}
          innerRef={modal => {
            this.modal = modal
          }}
          benefits={benefits}
          consumer={subscription.consumer}
          expiredAt={subscription.expiredAt}
        />
      </View>
    )
  }
}

ClaimRegistration.propTypes = {
  subscription: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func,
  tReady: PropTypes.bool,
}

export default withTranslation()(ClaimRegistration)
