function MagnifyingGlass(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={104}
      height={93}
      viewBox="0 0 104 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.897 70.49a22.049 22.049 0 01-2.656-3.668c-9.733-18.038 15.875-74.61 65.57-55.84 3.834 1.449 5.57 2.807 6.718 4.232.429.056 3.317 2.713 3.256 3.024 46.62 44.258-45.192 83.284-72.888 52.252z"
        fill="#F4F6F8"
      />
      <path
        d="M50.747 68.55c14.631 0 26.493-11.758 26.493-26.262 0-14.504-11.862-26.262-26.493-26.262-14.632 0-26.493 11.758-26.493 26.262 0 14.504 11.861 26.262 26.493 26.262z"
        stroke="#637381"
        strokeWidth={2}
      />
      <path
        d="M50.748 63.02c11.55 0 20.915-9.282 20.915-20.732 0-11.451-9.364-20.734-20.915-20.734-11.552 0-20.916 9.283-20.916 20.734 0 11.45 9.364 20.733 20.916 20.733z"
        fill="#fff"
      />
      <path
        d="M72.708 62.675L93.972 80.99"
        stroke="#454F5B"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M96.094 77.27L79.782 63.214a3.507 3.507 0 00-4.918.341l-.914 1.043a3.435 3.435 0 00.343 4.875L90.605 83.53a3.507 3.507 0 004.918-.341l.914-1.043a3.435 3.435 0 00-.343-4.875z"
        fill="#C4CDD5"
      />
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={42}
        y={34}
        width={18}
        height={17}
      >
        <path
          d="M50.395 40.079l.352.349.352-.35 5.174-5.127a1.332 1.332 0 01.94-.386 1.343 1.343 0 01.942.386l.351-.355-.35.355a1.311 1.311 0 01.387.927 1.299 1.299 0 01-.388.927l-5.173 5.128-.358.355.358.355 5.173 5.128a1.305 1.305 0 010 1.854 1.337 1.337 0 01-1.882 0l-5.174-5.128-.351-.35-.352.35-5.174 5.128.352.355-.352-.355a1.337 1.337 0 01-1.882 0l-.352.355.352-.355a1.305 1.305 0 010-1.854l5.173-5.128.358-.355-.358-.355-5.173-5.128a1.305 1.305 0 010-1.854 1.337 1.337 0 011.882 0l5.173 5.128z"
          fill="#fff"
          stroke="#2C9FA5"
        />
      </mask>
      <g mask="url(#a)">
        <path
          d="M37.304 28.966H64.19V55.61H37.304V28.966z"
          fill="currentColor"
          stroke="currentColor"
        />
      </g>
    </svg>
  )
}

export default MagnifyingGlass
