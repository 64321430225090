import React, { useContext } from 'react'

const PricingChangesContext = React.createContext<{
  next: () => void
  remindMe: () => void
  dontRemindMe: () => void
}>({ next: () => {}, remindMe: () => {}, dontRemindMe: () => {} })

export function usePricingChangesContext() {
  return useContext(PricingChangesContext)
}

export default PricingChangesContext
