import { DataTable, ReactDayPicker, View, themeGet } from '@tabeo/scarf'
import moment from 'moment'
import { ComponentPropsWithoutRef } from 'react'
import styled from 'styled-components'

const ThemedReactDayPicker = styled(ReactDayPicker)`
  .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--selected
    ) {
    color: ${themeGet('colors.primary.3')};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ) {
    background-color: ${themeGet('colors.primary.3')};
    border-radius: 0;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--selected
    ):not(.DayPicker-Day--outside):hover {
    background-color: ${themeGet('colors.primary.6')};
    border-radius: 0;
  }
`

export default function DateFilter({
  value,
  name,
  label,
  onChange,
  daypickerProps,
  ...rest
}: {
  value: { value: string; label: string }
  name: string
  label: string
  onChange: (value: {
    name: string
    value: { value: string; label: string }
  }) => void
  daypickerProps?: ComponentPropsWithoutRef<typeof ReactDayPicker>
}) {
  const flatValue = value?.value
  return (
    <DataTable.CustomFilterWrapper label={label} value={flatValue} {...rest}>
      {({ containerProps, setOpen }) => (
        <View
          border={1}
          borderRadius={1}
          boxShadow={1}
          bg="white"
          {...containerProps}
        >
          <ThemedReactDayPicker
            selectedDays={flatValue ? new Date(flatValue) : undefined}
            onDayClick={(d: Date, modifiers) => {
              const { disabled } = modifiers || {}
              if (disabled) return

              const date = d
              date.setHours(0, 0, 0, 0)
              onChange({
                name,
                value: {
                  value: date.toISOString(),
                  label: moment(date).format('DD MMM YYYY'),
                },
              })
              setOpen(false)
            }}
            {...daypickerProps}
          />
        </View>
      )}
    </DataTable.CustomFilterWrapper>
  )
}
