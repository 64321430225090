// @ts-nocheck

import PropTypes from 'prop-types'
import React from 'react'
import { useScarfContext } from '../../ScarfProvider'
import Text from '../Text'
import Input, { InputProps } from './Input'

const countryPrefixes = {
  gb: '+44',
  nl: '+31',
  it: '+39',
  fr: '+33',
  es: '+34',
  de: '+49',
}

const countryTrunkPrefixes = {
  gb: '(0)',
  nl: '(0)',
  it: '',
  fr: '(0)',
  es: '',
  de: '(0)',
}

const masks = {
  gb: [/[1-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  nl: [/[1-9]/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/],
  it: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
  fr: [
    /[1-9]/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ],
  es: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
  de: [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
}

export type PhoneProps = InputProps

const Phone = ({ input, ...rest }: PhoneProps) => {
  const { value, onChange } = input || {}
  const { countryCode } = useScarfContext()
  const prefix = countryPrefixes[countryCode]
  const trunk = countryTrunkPrefixes[countryCode]
  function handleChange(value) {
    onChange?.(formatValue(value))
  }
  function parseValue(value) {
    return value?.replace(prefix, '')
  }
  function formatValue(value) {
    if (!value) {
      return value
    }

    return prefix + value.replace(/\D/g, '')
  }

  const handleClear = () => {
    handleChange('')
  }

  return (
    <Input
      left={
        <Text whiteSpace="nowrap" color="ink.2" mr="-4px">
          {[prefix, trunk].filter(Boolean).join(' ')}
        </Text>
      }
      mask={masks[countryCode]}
      type="tel"
      {...rest}
      input={{
        ...input,
        value: parseValue(value),
        onChange: (e) => handleChange(e.target.value),
      }}
      onClear={handleClear}
    />
  )
}

Phone.propTypes = {
  input: PropTypes.object,
}

export default Phone
