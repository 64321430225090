import {
  AnimateHeight,
  Button,
  Caption,
  Flex,
  Icons,
  Panel,
  Prompt,
  propTypes,
  Tag,
  Text,
  Tooltip,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import questionmark from 'assets/tooltip-questionmark.svg'
import useCurrency from 'hooks/useCurrency'
import moment from 'moment'
import InvoiceButton from 'pages/Transaction/ActionPanel/InvoiceButton'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import getOrdinalNum from 'utils/getOrdenal'

const TableRow = styled(Flex)``

TableRow.defaultProps = {
  py: 4,
  px: 5,
  flexDirection: ['column', 'row'],
  alignItems: ['flex-start', 'center'],
  borderBottom: 1,
}

function BalanceToDate({
  offer: {
    paymentPlan: { payments },
    merchantNetProceedsTransfers,
    sumMerchantNetProceedsTransfers,
    sumOfMerchantPaidRefunds,
    isMerchantPartLender,
  },
}) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  if (!isMerchantPartLender) {
    return null
  }

  const balance = sumMerchantNetProceedsTransfers - sumOfMerchantPaidRefunds

  const items = [
    ...merchantNetProceedsTransfers.map((transfer, index) => ({
      date: transfer.date,
      label:
        index === 0
          ? t('Loan advance')
          : t(`{{paymentCount}} payment`, {
              paymentCount: getOrdinalNum(index + 1),
            }),
      amount: `+${format(transfer.amount)}`,
    })),
    ...payments
      .filter(p => p.type === 'ref_mer')
      .filter(p => p.merchantRefundPaymentAmount > 0)
      .map(transfer => ({
        date: transfer.executedAt,
        label: t(`Refund payment`),
        amount: `-${format(transfer.merchantRefundPaymentAmount)}`,
      })),
  ].sort((a, b) => new Date(a.date) - new Date(b.date))

  if (!items?.length) {
    return null
  }

  return (
    <View borderBottom={1} py={4} px={5}>
      <Flex onClick={() => setOpen(!open)} cursor="pointer">
        <Flex mb={[1, 0]} justifyContent="flex-start">
          <Text color="ink.2">{t('Balance to date')}</Text>
          <View
            transform={open ? 'rotate(-180deg)' : 'rotate(0deg)'}
            transition="transform 200ms"
            ml={1}
          >
            <Icons.ArrowDown fill="ink.2" />
          </View>
        </Flex>
        <Text>{format(balance)}</Text>
      </Flex>
      <AnimateHeight height={!open ? 0 : 'auto'}>
        <View mt={3}>
          {items.map(({ date, label, amount }) => (
            <Flex
              _notFirst={{
                mt: 1,
              }}
              key={date}
            >
              <Caption minWidth="100px">
                {moment(date).format('DD MMM YYYY')}
              </Caption>
              <Caption flex="1">{label}</Caption>
              <Caption>{amount}</Caption>
            </Flex>
          ))}
        </View>
      </AnimateHeight>
    </View>
  )
}

BalanceToDate.propTypes = {
  offer: PropTypes.object.isRequired,
}

function BeforeAfter({ before, after, ...rest }) {
  return (
    <View display="inline-flex" justifyContent="flex-end">
      {after && (
        <Caption color="ink.2" textDecoration="line-through" mr={1}>
          {before}
        </Caption>
      )}
      <Text {...rest}>{after || before}</Text>
    </View>
  )
}

BeforeAfter.propTypes = {
  before: propTypes.children,
  after: propTypes.children,
}

const riskprofiles = {
  default: 'core',
  near_prime: 'near prime',
  in_house: 'in-house',
  it_core: 'core',
  fr_core: 'core',
  fr_sdc_core: 'core',
  tabeo_plus: 'plus',
  profile_sdc_unfunded: 'collect',
  plusdent_unfunded: 'collect',
  diamondwhites_unfunded: 'collect',
  impress_unfunded: 'collect',
  vinci_unfunded: 'collect',
  uk_pl_unfunded: 'collect',
  smilewhites_unfunded: 'collect',
  nl_collect: 'collect',
  it_collect: 'collect',
  fr_collect: 'collect',
  fr_sdc_collect: 'collect',
  nl_ifinance: 'i-finance',
}

function InvoicePanel({ data, ...rest }) {
  const {
    isPN,
    isMerchantPartLender,
    refunds,
    paymentPlan,
    price,
    feeAmount,
    feePercent,
    revisedFeePercent,
    netProceeds,
    revisedNetProceeds,
    revisedPrice,
    revisedRequiredMerchantFeeAmount,
    valueAtRisk,
    revisedValueAtRisk,
    defaultRiskProfile,
    merchantLateRefundFeeAmountToDate,
    sumMerchantNetProceedsTransfers,
  } = data

  const { t } = useTranslation()
  const { formatDivisional } = useCurrency()

  const isRefunded = !!refunds?.length
  const subsidySuffix = isPN
    ? `(1% + ${formatDivisional(20)})`
    : !isRefunded
    ? `(${parseFloat((feePercent * 100).toFixed(2))}%)`
    : `(${parseFloat((revisedFeePercent * 100).toFixed(2))}%)`

  const riskProfileName =
    paymentPlan?.riskProfileName || defaultRiskProfile?.name

  const interestBearingFree =
    paymentPlan.apr !== 0 ? t('interest-bearing') : t('interest-free')

  return (
    <Panel
      data-testid="invoice-panel"
      header={
        <>
          <Flex justifyContent="flex-start">
            <Panel.Title mr={4}>{t('Transaction')}</Panel.Title>
            {riskProfileName === 'tabeo_plus' ? (
              <Tooltip
                trigger="click"
                component={
                  <View textAlign="left">
                    <Prompt>
                      {t(
                        'Tabeo Plus is a new product designed to boost acceptance rates, book a call to find out more.'
                      )}
                    </Prompt>
                    <Button
                      as="a"
                      href="https://calendly.com/tabeo_introduction/tabeo-plus"
                      target="_blank"
                      variant="secondary"
                      sizeVariant="sm"
                      mt={3}
                    >
                      {t('Book a call')}
                    </Button>
                  </View>
                }
              >
                <Tag small cursor="pointer">
                  {t('Finance | Plus')}
                </Tag>
              </Tooltip>
            ) : (
              <Tag small>
                {isPN
                  ? t('Card')
                  : `${t('Finance')}${
                      riskProfileName
                        ? ` | ${
                            riskprofiles[riskProfileName] || riskProfileName
                          }`
                        : ''
                    }`}
              </Tag>
            )}
          </Flex>
          <Flex flex="1" />
          <InvoiceButton width="unset" />
        </>
      }
      {...rest}
    >
      <View bg="white">
        <TableRow data-testid="price">
          <Text mb={[1, 0]} color="ink.2">
            {t('Cash price')}
          </Text>
          <Flex />
          <BeforeAfter
            before={format(price)}
            after={isRefunded && format(revisedPrice)}
          />
        </TableRow>
        {feeAmount !== null && (
          <TableRow>
            <Text mb={[1, 0]} color="ink.2">
              {t('Subsidy fee')}
            </Text>
            <View display="inline-flex">
              <BeforeAfter
                before={format(feeAmount)}
                after={isRefunded && format(revisedRequiredMerchantFeeAmount)}
              />
              <Text ml={1}>{subsidySuffix}</Text>
            </View>
          </TableRow>
        )}
        {merchantLateRefundFeeAmountToDate > 0 && (
          <TableRow>
            <Flex justifyContent="flex-start" mb={[1, 0]}>
              <Text color="ink.2">{t('Late refund fees')}</Text>
              <Tooltip
                component={
                  <Text color="ink.1" textAlign="left">
                    {t(
                      'For refunds that are triggered after more than 14 days from the transaction confirmation date, we apply a fee of {{feeAmount}}% (for {{interestBearingFree}} loans). This is based on the amount to refund.',
                      {
                        feeAmount:
                          paymentPlan.apr !== 0
                            ? Math.min(feePercent * 100, 3).toFixed(1)
                            : 3,
                        interestBearingFree,
                      }
                    )}
                  </Text>
                }
              >
                <View as="img" src={questionmark} ml={2} />
              </Tooltip>
            </Flex>
            <View display="inline-flex">
              <Text>{format(merchantLateRefundFeeAmountToDate)}</Text>
            </View>
          </TableRow>
        )}
        <BalanceToDate offer={data} />
        {isMerchantPartLender &&
          sumMerchantNetProceedsTransfers > 0 &&
          (isRefunded ? revisedValueAtRisk > 0 : !!valueAtRisk) && (
            <TableRow>
              <Flex justifyContent="flex-start" mb={[1, 0]}>
                <Text color="ink.2">{t('Value at risk')}</Text>
                <Tooltip
                  component={
                    <Text color="ink.1" textAlign="left">
                      {t(
                        'This represents the amount you are to receive conditional to the patient repaying the loan.'
                      )}
                    </Text>
                  }
                >
                  <View as="img" src={questionmark} ml={2} />
                </Tooltip>
              </Flex>
              <BeforeAfter
                before={format(valueAtRisk)}
                after={
                  isRefunded &&
                  revisedValueAtRisk !== valueAtRisk &&
                  format(revisedValueAtRisk)
                }
              />
            </TableRow>
          )}
        <TableRow bg="white" data-testid="proceeds">
          <Text mb={[1, 0]} color="ink.2">
            {isMerchantPartLender && revisedPrice !== 0 && valueAtRisk !== 0
              ? t(`Max. net proceeds`)
              : t(`Net proceeds`)}
          </Text>
          {feeAmount !== null ? (
            <BeforeAfter
              fontWeight="semibold"
              before={format(netProceeds)}
              after={isRefunded && format(revisedNetProceeds)}
            />
          ) : (
            <Text color="ink.2">{t('Not available yet')}</Text>
          )}
        </TableRow>
      </View>
    </Panel>
  )
}

InvoicePanel.propTypes = {
  data: PropTypes.object.isRequired,
}

export default InvoicePanel
