import React from 'react'
import PropTypes from 'prop-types'

import Nav from 'components/Nav'
import Container from 'components/Container'

import { Spinner } from '@tabeo/scarf'

function Layout({ isLoading, children, ...rest }) {
  return (
    <>
      <Nav />
      <Container
        py={[6, '40px']}
        px={[4, 0]}
        minHeight="calc(100vh - 60px)"
        {...rest}
      >
        {isLoading ? <Spinner minHeight="calc(100vh - 60px)" /> : children}
      </Container>
    </>
  )
}

Layout.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.any,
}

export default Layout
