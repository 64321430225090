import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import AnimatedContainer from 'components/nnts/AnimatedContainer'
import useBreakpoints from 'components/nnts/hooks/useBreakpoints'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

export default function CategorySection({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <AnimatedContainer>
      <div className={twMerge('gap-5', className)}>{children}</div>
    </AnimatedContainer>
  )
}

function Label({ children }: { children: React.ReactNode }) {
  return <div className="subheading mb-2 text-tabeo-ink-1">{children}</div>
}

function Items<T>({
  items,
  compareItems,
  renderItem,
  defaultIsOpen = false,
}: {
  items: T[]
  compareItems: (a: T, b: T) => number
  renderItem: (item: T) => JSX.Element
  defaultIsOpen?: boolean
}) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(defaultIsOpen)

  // The cutoff is 9 treatments on larger screens, 6 on smaller screens
  const { xl } = useBreakpoints()
  const cutOff = xl ? 9 : 6

  return (
    <div>
      <div className="grid grid-cols-1 gap-x-4 gap-y-3 md:grid-cols-2 md:gap-y-5 xl:grid-cols-3">
        {items
          // We need to cut off the list if there are more than 9
          // (or 6 on smaller screens) treatments
          .slice(
            0,
            // If the category is open, we show all treatments
            isOpen ? items.length : cutOff
          )
          // We sort the treatments alphabetically
          .sort(compareItems)
          .map(renderItem)}
      </div>
      {
        // We need to show the "Show more" button if there are more
        // treatments} than the cut off
        items?.length > cutOff && (
          <button
            type="button"
            onClick={e => {
              e.stopPropagation()
              setIsOpen(isOpen => !isOpen)
            }}
            onBlur={e => {
              e.stopPropagation()
            }}
            className="anchor caption mt-4 inline-flex items-center gap-1"
          >
            {isOpen ? (
              <>
                {t('Show less')}
                <ChevronUpIcon className="h-5 w-5" />
              </>
            ) : (
              <>
                {t('Show more')}
                <ChevronDownIcon className="h-5 w-5" />
              </>
            )}
          </button>
        )
      }
    </div>
  )
}

CategorySection.Label = Label
CategorySection.Items = Items
