import { Subtitle, Text, View } from '@tabeo/scarf'
import ShieldTick from 'components/Images/ShieldTick'
import KnightRider from 'components/KnightRider'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Pending = props => {
  const { t } = useTranslation()
  const {
    headline = t('Processing payment'),
    description = t(`Please do not press the back button or refresh the page`),
    ...rest
  } = props
  return (
    <View textAlign="center" {...rest}>
      <ShieldTick mb={1} />
      <KnightRider mb={4} mx="auto" />
      <Subtitle color="default.primary" m={4}>
        {headline}
      </Subtitle>
      {description && (
        <Text fontSize={1} color="ink.2" mb={[0, 5]}>
          {description}
        </Text>
      )}
    </View>
  )
}

Pending.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
}

// Pending.defaultProps = {
//   headline: 'Processing payment',
//   description: `Please do not press the back button or refresh the page`,
// }

export default Pending
