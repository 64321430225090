import ColorThief from 'color-thief-browser'
import uniq from 'lodash/uniq'

const colorThief = new ColorThief()

function rgbToHsl(r, g, b) {
  r /= 255
  g /= 255
  b /= 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h
  let s
  const l = (max + min) / 2

  if (max === min) {
    h = 0
    s = 0 // achromatic
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
      default:
        h = 0
    }

    h /= 6
  }

  return [h, s, l]
}

const getUniqueHexColors = (img, maxColorCount = 7) => {
  function componentToHex(c) {
    const hex = c.toString(16)
    return hex.length === 1 ? `0${hex}` : hex
  }

  function rgbToHex(r, g, b) {
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
  }
  const colors = colorThief.getPalette(img, 100, 1)

  const uniqColors = uniq(colors).splice(0, maxColorCount)

  uniqColors.sort((a, b) => {
    const ahsl = rgbToHsl(...a)
    const asdelta = Math.abs(ahsl[1] * 100 - 100)
    const aldelta = Math.abs(ahsl[2] * 100 - 50)
    const adelta = asdelta + aldelta
    const bhsl = rgbToHsl(...b)
    const bsdelta = Math.abs(bhsl[1] * 100 - 100)
    const bldelta = Math.abs(bhsl[2] * 100 - 50)
    const bdelta = bsdelta + bldelta
    return adelta - bdelta
  })

  return uniqColors
    .map(c => rgbToHex(c[0], c[1], c[2]))
    .filter((item, pos, self) => {
      return self.indexOf(item) === pos
    })
}

export default getUniqueHexColors
