import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useBill } from '../../resources/Bill'
import Print from '../Transaction/Print'
import Bill from './Bill'

function BillRouter(props) {
  const { data } = useBill()

  return (
    <Switch>
      <Route
        path="/bills/:billId/print"
        render={routeProps =>
          data ? <Print {...props} {...routeProps} /> : null
        }
      />
      <Route
        path="/bills/:billId"
        render={routeProps => <Bill {...props} {...routeProps} />}
      />
    </Switch>
  )
}

export default BillRouter
