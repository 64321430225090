import LayoutSideNavbar from 'components/layouts/LayoutSideNavbar'
import Subpage from 'components/layouts/Subpage'
import TwoColumns, { Column } from 'components/layouts/TwoColumns'
import Notifications from 'components/Notifications'
import React from 'react'
import { useLead } from 'resources/Lead'
import ActionPanel from './ActionPanel'
import ActivityFeed from './ActivityFeed'
import AdditionalInfo from './AdditionalInfo'
import BookingPanel from './Booking/Panel'
import FinancePanel from './FinancePanel'
import Footer from './Footer'
import Funnel from './Funnel'
import Header from './Header'
import PatientPanel from './PatientPanel'
import ProductAnswers from './ProductAnswers'
import TransactionsPanel from './TransactionsPanel'
import TreatmentPanel from './TreatmentPanel'
import Uploads from './Uploads'

function Lead() {
  const { data, resource } = useLead()

  return (
    <LayoutSideNavbar isLoading={!data}>
      {data && (
        <Subpage>
          <Notifications mb={4} />
          <Header data={data} />
          <TwoColumns mt={4}>
            <Column>
              <Funnel state={data.state} />
              <ActionPanel resource={resource} />
              <FinancePanel
                firstName={data.firstName}
                productAnswers={data.productAnswers}
              />
              <BookingPanel data={data} />
              <Uploads product={data.product} uploads={data.uploads} />
              <AdditionalInfo data={data} />
              <TransactionsPanel offers={data.offers} />
              <ActivityFeed data={data} />
              <Footer />
            </Column>
            <Column>
              <PatientPanel data={data} />
              <TreatmentPanel data={data} />
              <ProductAnswers data={data} />
            </Column>
          </TwoColumns>
        </Subpage>
      )}
    </LayoutSideNavbar>
  )
}

export default Lead
