import React from 'react'

import ScarfProps from '../types/ScarfProps'
import Flex from './Flex'
import View from './View'
import AnimateHeight from './AnimateHeight'

import ArrowLeft from './Icons/ArrowLeft'
import Text from './Text'

export type ExpansionPanelProps = ScarfProps & {
  isOpenByDefault?: boolean
  header?: React.ReactNode
  title?: string
}

function ExpansionPanel({
  children,
  isOpenByDefault = false,
  header,
  title,
  ...rest
}: ExpansionPanelProps) {
  const [isOpen, setOpen] = React.useState(isOpenByDefault)
  const height = isOpen ? 'auto' : 0
  return (
    <View
      p={3}
      border={1}
      borderColor="sky.1"
      bg="sky.3"
      borderRadius={1}
      {...rest}
    >
      <Flex cursor="pointer" onClick={() => setOpen(!isOpen)}>
        {header || <Text fontWeight="semibold">{title}</Text>}
        <Flex
          transform={isOpen ? `rotate(-90deg)` : `rotate(0deg)`}
          transition="transform 200ms linear"
          ml={2}
          onClick={() => setOpen(!isOpen)}
        >
          <ArrowLeft fill="ink.1" />
        </Flex>
      </Flex>
      <AnimateHeight duration={200} height={height}>
        <View mt={2}>{children}</View>
      </AnimateHeight>
    </View>
  )
}

export default ExpansionPanel
