import {
  Button,
  Checkbox,
  Clickable,
  Prompt,
  TextInput,
  Title,
  View,
} from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import parseIban from '@tabeo/sharpei/utils/parseIban'
import { validateBank, validateIban } from '@tabeo/sharpei/utils/pca'
import {
  iban,
  length,
  lengthRange,
  regex,
  required,
} from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import Layout from 'components/Layout'
import Pending from 'components/Signup/Pending'
import config from 'config'
import useAuthGuard from 'hooks/useAuthGuard'
import useCountry from 'hooks/useCountry'
import useFlow from 'hooks/useFlow'
import React from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { useAuth } from 'resources/Auth'
import Progressbar from '../../components/Signup/Progressbar'
import { useMerchant } from '../../resources/Merchant'

const isMock = config.ENV !== 'production'
const mockBankResponse = {
  IsCorrect: true,
  IsDirectDebitCapable: true,
  StatusInformation: 'OK',
  CorrectedSortCode: '108800',
  CorrectedAccountNumber: '00012345',
  IBAN: 'GB89370400440532013000',
  Bank: 'HSBC BANK  PLC (RFB)',
  BankBIC: 'HBUKGB41',
  Branch: 'HSBC',
  BranchBIC: '73E',
  ContactAddressLine1: 'Banking Operations Coventry',
  ContactAddressLine2: 'Harry Weston Road',
  ContactPostTown: 'Coventry',
  ContactPostcode: 'CV3 2SH',
  ContactPhone: '03457 404404',
  ContactFax: '',
  FasterPaymentsSupported: true,
  CHAPSSupported: true,
}
const mockIbanResponse = {
  IsCorrect: true,
}

const lengthOfEight = length(8)
const rangeFromSevenToEight = lengthRange(7, 8)
const lengthRangeTwoHundred = lengthRange(2, 100)

function BankDetails() {
  const { t } = useTranslation()

  useAuthGuard()

  const {
    data: merchant,
    resource: { addBankDetails },
  } = useMerchant()

  const {
    data: { user },
  } = useAuth()

  const { next, loading } = useFlow({ merchant, user })

  const country = useCountry()
  const selected = v => (v === false ? t('Must be selected') : undefined)
  const companyTypes = {
    private_limited_company: t(`company's`),
    limited_liability_partnership: t(`partners'`),
    sole_trader: t(`owner's`),
    partnership: t(`partners'`),
  }

  const { companyType, name, tradingName, officers } = merchant || {}
  async function handleSubmit(values) {
    try {
      let response

      if (country.isUK) {
        response = isMock
          ? mockBankResponse
          : await validateBank(values.sortCode, values.accountNumber)
      } else if (country.isEU) {
        response = isMock ? mockIbanResponse : await validateIban(values.iban)
      }

      if (response.IsCorrect === true) {
        // Make sure we display "Bank Account Verified!" banner to this user
        // once the verification has been processed
        window.localStorage.setItem('tabeoBankBanner', 'started')

        let payload = {
          ...values,
          bankDetailsJSON: response,
        }

        if (country.isUK) {
          payload = {
            ...payload,
            sortCode: response.CorrectedSortCode,
            accountNumber: response.CorrectedAccountNumber,
            iban: response.IBAN,
          }
        }

        if (country.isEU) {
          let iban
          let accountNumber

          if (isMock) {
            const countryCode = values.iban.slice(0, 2)
            iban = `${countryCode}89370400440532013000`
            accountNumber = iban.slice(-10)
          } else {
            const result = parseIban(values.iban)
            iban = result.iban
            accountNumber = result.accountNumber
          }

          payload = {
            ...payload,
            accountNumber,
            iban,
          }
        }

        await addBankDetails(payload)
      } else {
        return { formError: t('Invalid bank details') }
      }
      next()
    } catch (e) {
      return formError(e)
    }
  }

  const [{ firstName, lastName }] = officers || [{}]
  const initialValues = {
    accountHolder:
      companyType === 'sole_trader'
        ? `${firstName} ${lastName}`
        : companyType === 'partnership'
        ? tradingName
        : name,
  }

  return (
    <View as={Form} onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting }) => {
        if (submitting) {
          return <Pending />
        }

        return (
          <Layout maxWidth="640px" isLoading={loading}>
            <Progressbar companyType={companyType} currentIndex={3} mb={6} />
            <form onSubmit={handleSubmit}>
              <Title mb={3}>{t('Bank details')}</Title>
              <Prompt mb={4}>
                {t(
                  'Tell us where you’d like us to send your payouts. Must be a UK bank account in your {{companyType}} name.',
                  { companyType: companyTypes[companyType] }
                )}
              </Prompt>
              <Field
                data-no-track
                name="accountHolder"
                component={TextInput}
                label={t('Account holder’s name')}
                placeholder={t('e.g. Tabeo')}
                validate={[required, lengthRangeTwoHundred]}
              />

              {country.isUK && (
                <Field
                  data-no-track
                  name="sortCode"
                  component={TextInput}
                  label={t('Sort code')}
                  placeholder={t('e.g. 11-22-33')}
                  type="tel"
                  mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                  validate={[required, lengthOfEight]}
                  maxWidth="160px"
                />
              )}

              {country.isUK && (
                <Field
                  name="accountNumber"
                  label={t('Account number')}
                  component={TextInput}
                  placeholder={t('e.g. 12345678')}
                  type="tel"
                  validate={[required, rangeFromSevenToEight]}
                  maxWidth="160px"
                />
              )}

              {country.isEU && (
                <Field
                  name="iban"
                  label={t('IBAN')}
                  component={TextInput}
                  placeholder={
                    {
                      nl: t('e.g. NL89BANK0123456789'),
                      it: t('e.g. IT60X0542811101000000123456'),
                      fr: t('e.g. FR1420041010050500013M02606'),
                    }[country.countryCode]
                  }
                  validate={[
                    required,
                    isMock ? regex(/^(GB|NL|FR|IT|ES|DE|LT).*$/) : iban,
                  ]}
                />
              )}

              {country.isEU && (
                <Field
                  name="bic"
                  label={t('SWIFT/BIC')}
                  component={TextInput}
                  placeholder={t('e.g. BANKNL2ABCD')}
                  validate={[required]}
                />
              )}

              <Field
                name="principles"
                component={Checkbox}
                validate={[required, selected]}
              >
                <Prompt>
                  <Trans>
                    I agree to the{' '}
                    <Clickable
                      as="a"
                      href="/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                      display="inline"
                    >
                      Merchant Terms & Conditions
                    </Clickable>
                    .
                  </Trans>
                </Prompt>
              </Field>
              <Button loading={submitting}>{t('Next')}</Button>
              <FormError />
            </form>
          </Layout>
        )
      }}
    </View>
  )
}

export default BankDetails
