import React from 'react'
import styled from 'styled-components'

import Svg, { SvgProps } from './Base'

const ArrowDown = ({ ...rest }: SvgProps) => (
  <Svg {...rest}>
    <g>
      <path d="M10.383 14.704L5.3 9.474a.938.938 0 0 1 0-1.305l.845-.869a.876.876 0 0 1 1.267 0l3.604 3.708L14.619 7.3a.876.876 0 0 1 1.267 0l.845.87a.938.938 0 0 1 0 1.303l-5.084 5.23a.872.872 0 0 1-1.264 0z" />
    </g>
  </Svg>
)

export default styled(ArrowDown)``
