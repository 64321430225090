import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import Base, { BaseProps } from '../../../Text'
import Tooltip2 from '../../../Tooltip2'
import EntityContext from '../EntityContext'

export type TextProps = BaseProps

function Text({ children, color = 'default.ink', ...rest }: TextProps) {
  const ref = useRef<Element>()
  const { hover, disabled } = useContext(EntityContext)
  const [isOverflown, setIsOverflown] = useState(true)

  const checkOverflow = useCallback(() => {
    if (ref.current) {
      const { clientWidth, scrollWidth } = ref.current
      setIsOverflown(scrollWidth > clientWidth)
    }
  }, [])

  useEffect(() => {
    checkOverflow()

    window.addEventListener('resize', checkOverflow)
    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [checkOverflow])

  return (
    <Tooltip2 title={isOverflown ? `${children}` : ''}>
      <Base
        ref={ref}
        color={disabled ? 'currentColor' : hover ? 'white' : color}
        fontSize={[null, '14px']}
        overflowX="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        {...rest}
      >
        {children}
      </Base>
    </Tooltip2>
  )
}

export default Text
