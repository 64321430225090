import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import CurrencyInput, { formatValue } from 'react-currency-input-field'
import { useScarfContext } from '../../ScarfProvider'
import { InputProp, MetaProp } from '../../types/form'
import { Merge } from '../../types/helpers'
import '../../utils/polyfill-formatToParts'
import Text from '../Text'
import { Base, HtmlInput, BaseProps } from './Input'
import * as Icons from '../Icons'

const labels: Record<
  string,
  { symbol: string; ticker: string; locale: string }
> = {
  gb: {
    symbol: '£',
    ticker: 'GBP',
    locale: 'en-GB',
  },
  nl: {
    symbol: '€',
    ticker: 'EUR',
    locale: 'nl-NL',
  },
  it: {
    symbol: '€',
    ticker: 'EUR',
    locale: 'it-IT',
  },
  fr: {
    symbol: '€',
    ticker: 'EUR',
    locale: 'fr-FR',
  },
  es: {
    symbol: '€',
    ticker: 'EUR',
    locale: 'fr-FR',
  },
  de: {
    symbol: '€',
    ticker: 'EUR',
    locale: 'de-DE',
  },
}

export type CurrencyProps = Merge<
  BaseProps,
  {
    input: Partial<InputProp>
    meta: Partial<MetaProp>
    showTicker?: boolean
    allowDecimals?: boolean
    disabled?: boolean
    clearable?: boolean
  }
>

function Currency(props: CurrencyProps) {
  const { countryCode } = useScarfContext()
  const locale = labels[countryCode]?.locale || 'en-GB'

  const {
    meta,
    input,
    showTicker,
    allowDecimals = true,
    disabled,
    type = 'tel',
    inputMode,
    clearable,
    ...rest
  } = props

  const { value, onChange, ...inputProps } = input

  const [internalValue, setInternalValue] = useState(
    value !== undefined ? String(value / 100) : ''
  )

  const handleClear = () => {
    handleValueChange('')
  }

  const handleValueChange = (value: string) => {
    setInternalValue(value)

    if (value) {
      /**
       * Library could return coma decimal format (123,45), have to transform back
       * Round the result to prevent values like 12345.000001
       */
      const transformedValue = Math.round(Number(value.replace(',', '.')) * 100)
      onChange?.(transformedValue)
    } else {
      onChange?.(value)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formatOptions = {
    allowDecimals,
    decimalScale: allowDecimals ? 2 : 0,
    decimalsLimit: allowDecimals ? 2 : 0,
    disableAbbreviations: true,
    intlConfig: { locale },
  }

  useEffect(() => {
    if (!value || !internalValue) {
      if (value || value === 0) {
        setInternalValue(
          formatValue({
            value: String(value / 100),
            ...formatOptions,
            disableGroupSeparators: true,
          })
        )
      } else {
        setInternalValue('')
      }
    }
  }, [value, internalValue, formatOptions])

  return (
    // @ts-ignore
    <Base meta={meta} {...rest}>
      <Text color="ink.1" fontWeight="regular" mr={3}>
        {labels[countryCode]?.symbol || '£'}
      </Text>
      <HtmlInput
        // @ts-ignore
        as={CurrencyInput}
        value={internalValue}
        onValueChange={handleValueChange}
        placeholder="0.00"
        type={type}
        inputMode={inputMode}
        disabled={disabled}
        {...formatOptions}
        {...inputProps}
      />
      {clearable && internalValue && (
        <Icons.Clear
          width={20}
          height={20}
          fill="ink.3"
          onClick={handleClear}
          cursor="pointer"
        />
      )}
      {showTicker && (
        <Text color="ink.1" fontWeight="regular" ml={3}>
          {labels[countryCode]?.ticker || 'GBP'}
        </Text>
      )}
    </Base>
  )
}

Currency.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  disabled: PropTypes.bool,
  showTicker: PropTypes.bool,
  countryCode: PropTypes.string,
  allowDecimals: PropTypes.bool,
  type: PropTypes.string,
  inputMode: PropTypes.string,
  clearable: PropTypes.bool,
}

export default Currency
