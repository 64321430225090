Intl.NumberFormat.prototype.formatToParts =
  Intl.NumberFormat.prototype.formatToParts ||
  function formatToParts(number) {
    const t = this.format(1234.56)
    const group = t[1]
    const decimal = t[5]

    const formatted = this.format(number)

    return formatted.split('').reduce((acc, curr) => {
      if (curr === group) {
        return [...acc, { type: 'group', value: curr }]
      }
      if (curr === decimal) {
        return [...acc, { type: 'decimal', value: curr }]
      }

      const lastPart = acc[acc.length - 1]

      if (lastPart?.type === 'decimal') {
        return [...acc, { type: 'fraction', value: curr }]
      }

      if (['integer', 'fraction'].includes(lastPart?.type)) {
        return [
          ...acc.slice(0, -1),
          { ...lastPart, value: lastPart.value + curr },
        ]
      }

      return [...acc, { type: 'integer', value: curr }]
    }, [])
  }
