import { RadioGroup } from '@headlessui/react'
import { NoSymbolIcon } from '@heroicons/react/20/solid'
import {
  ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/solid'
import {
  FFRadioGroup,
  RadioCards,
  Stepper as StepperItem,
  Tile,
  Tooltip,
} from '@tabeo/scarf2'
import AutoSave from 'components/nnts/form/AutoSave'
import { Field } from 'components/nnts/form/Field'
import { scrollToErrorDecorator } from 'components/nnts/form/scrollToError'
import { ShieldPlusIcon } from 'components/nnts/icons/24'
import { ComponentProps, useCallback } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useMerchant } from 'resources/Merchant'
import formatClinicianName from 'utils/formatClinicianName'
import { z } from 'zod'
import { ActionButton } from '../components/action-button'
import Header from '../components/header'
import { useNewTransactionFlow } from '../components/provider'
import { getMerchantClinicianById } from '../utils'

export const clinicianSchema = z.object({
  merchantClinicianId: z.number(),
})

export function ClinicianMain() {
  const {
    next,
    setStateForStep,
    state,
    modals: { cartSummaryModalRef },
    form,
    currentIndex,
    flowSteps,
  } = useNewTransactionFlow()

  const { isLast } = flowSteps[currentIndex]

  const { t } = useTranslation()

  const selectionRequired = useCallback(
    (value: any) => (!value ? t('Please select a clinician') : undefined),
    [t]
  )

  const { data: merchant } = useMerchant()

  const merchantClinicians = merchant?.merchantClinicians || []

  function handleSubmit(values: any) {
    setStateForStep('clinician', values)
    return next()
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={state.clinician}
      decorators={[scrollToErrorDecorator]}
      destroyOnUnregister
    >
      {({ handleSubmit, form: f }) => {
        form.current = f
        return (
          <form onSubmit={handleSubmit} className="h-full">
            <Header
              cartSummaryModalRef={cartSummaryModalRef}
              currentIndex={currentIndex}
            />
            <h2 className="title text-tabeo-ink-0">{t('Clinician')}</h2>
            <p className="caption mb-1 text-tabeo-ink-1">
              {t('Select a clinician from the options below.')}
            </p>

            <Field
              name="merchantClinicianId"
              validate={[selectionRequired]}
              containerClassName="gap-5"
              component={FFRadioGroup}
            >
              <div className="grid grid-cols-1 gap-x-4 gap-y-3 md:grid-cols-2 md:gap-y-5 xl:grid-cols-3">
                {merchantClinicians
                  .sort((a, b) => {
                    // Dr, Dr., Doctor, DR, DR., dr, dr. prefixes at the start of the name should be excluded from sorting
                    const prefix = /^(dr\.?|doctor)\s/i

                    const aName = formatClinicianName(a).replace(prefix, '')
                    const bName = formatClinicianName(b).replace(prefix, '')

                    return aName.localeCompare(bName)
                  })
                  .filter(({ status }) => status === 'activated')
                  .map(mc => (
                    <RadioGroup.Option
                      key={mc.id}
                      value={mc.id}
                      onClick={() => {
                        if (!isLast) {
                          setTimeout(() => f.submit(), 160)
                        }
                      }}
                      disabled={mc.clinician?.status === 'suspended'}
                    >
                      {({ checked, disabled }) => (
                        <Tooltip
                          disabled={!disabled}
                          content={p => (
                            <Tooltip.Title {...p.popper}>
                              <Tooltip.Arrow {...p.arrow} />
                              {t(
                                'This clinician is suspended. Please contact support for further information.'
                              )}
                            </Tooltip.Title>
                          )}
                          placement="top"
                        >
                          <Tile
                            key={mc.id}
                            checked={checked}
                            disabled={disabled}
                            className={
                              isLast ? 'justify-start gap-2' : undefined
                            }
                          >
                            {!disabled && isLast && <RadioCards.OptionDot />}
                            <Tile.Label>{formatClinicianName(mc)}</Tile.Label>
                            {disabled ? (
                              <Tile.Icon icon={NoSymbolIcon} />
                            ) : !isLast ? (
                              <Tile.Icon icon={ArrowRightIcon} />
                            ) : null}
                          </Tile>
                        </Tooltip>
                      )}
                    </RadioGroup.Option>
                  ))}
                <Link to="/settings?tab=people&modal=new-clinician">
                  <Tile>
                    <Tile.Label>{t('Add new clinician')}</Tile.Label>
                    <Tile.Icon icon={ArrowTopRightOnSquareIcon} />
                  </Tile>
                </Link>
              </div>
            </Field>
            <AutoSave
              debounce={0}
              onSave={(values: any) => {
                setStateForStep('clinician', values)
              }}
            />
            <button type="submit" className="hidden">
              {t('Submit')}
            </button>
          </form>
        )
      }}
    </Form>
  )
}

export function ClinicianActions() {
  const { t } = useTranslation()
  return <ActionButton>{t('Continue')}</ActionButton>
}

export function shouldRenderClinicianMobileActions() {
  return false
}

export function ClinicianStepper(props: ComponentProps<typeof StepperItem>) {
  const { state } = useNewTransactionFlow()
  const { t } = useTranslation()

  const { data: merchant } = useMerchant()

  if (!merchant) return null

  const mc = getMerchantClinicianById(
    state?.clinician?.merchantClinicianId,
    merchant
  )

  const body = formatClinicianName(mc)

  return (
    <StepperItem empty={!body} {...props}>
      <StepperItem.Header>
        <StepperItem.Icon icon={ShieldPlusIcon} />
        <StepperItem.Label>{t('Clinician')}</StepperItem.Label>
      </StepperItem.Header>
      <StepperItem.Body>{body}</StepperItem.Body>
    </StepperItem>
  )
}
