import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

import { Heading, Caption, Clickable, Flex, View } from '@tabeo/scarf'

import Card from 'components/Transaction/ActionPanel/Card'
import icon from './assets/transferred.svg'

function Transferred({ transaction, ...rest }) {
  const { data } = transaction
  const { consumerFirstName, transferredOffer } = data
  const { t } = useTranslation()

  const transferedId = transferredOffer.id

  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Application transferred')}</Heading>
      </Flex>
      <Caption color="ink.1">
        {t(
          '{{consumerFirstName}} decided to transfer this finance application to someone else.',
          { consumerFirstName }
        )}
      </Caption>
      <Caption color="ink.1">
        <Trans>
          The new transaction created is{' '}
          <Clickable as={Link} to={`/transactions/${transferredOffer.id}`}>
            {{ transferedId }}
          </Clickable>
          .
        </Trans>
      </Caption>
    </Card>
  )
}

Transferred.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default Transferred
