import { Attributes, Caption, Panel, View } from '@tabeo/scarf'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ClaimRegistration from './ClaimRegistration'

function BenefitsPanel({ resource, ...rest }) {
  const { data, registerClaim } = resource
  const {
    plan: { planFeatures, planPerks },
    expiredAt,
  } = data
  const { t } = useTranslation()
  if (!['active', 'inactive'].includes(data.state)) {
    return null
  }

  return (
    <Panel
      header={
        <>
          <Panel.Title>{t('Benefits')}</Panel.Title>
          <ClaimRegistration
            sizeVariant="sm"
            subscription={data}
            onSubmit={registerClaim}
          />
        </>
      }
      data-testid="benefits-panel"
      {...rest}
    >
      <View p={5}>
        <Attributes
          sections={[
            planFeatures.map(f => ({
              label: f.title,
              value:
                f.claimsLimit > 0
                  ? t(
                      `{{totalClaimCount}} ({{remainingClaimCount}} left to claim in this subscription year)`,
                      {
                        totalClaimCount: f.claimsLimit,
                        remainingClaimCount: f.availableClaims,
                      }
                    )
                  : t('Unlimited'),
            })),
            planPerks.map(p => ({
              label: p.title,
              value:
                p.claimsLimit > 0
                  ? t(
                      `{{totalClaimCount}} ({{remainingClaimCount}} left to claim in this subscription year)`,
                      {
                        totalClaimCount: p.claimsLimit,
                        remainingClaimCount: p.availableClaims,
                      }
                    )
                  : t('Unlimited'),
            })),
          ]}
        />
        <Caption mt={5}>
          {t(
            'The benefit counters will reset after the subscription renewal on {{date}}.',
            { date: moment(expiredAt).format('DD MMM YYYY') }
          )}
        </Caption>
      </View>
    </Panel>
  )
}

BenefitsPanel.propTypes = {
  resource: PropTypes.object,
}

export default BenefitsPanel
