import {
  ChatBubbleLeftEllipsisIcon,
  EnvelopeIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/20/solid'
import { Button, Flex, Subtitle, Text, View, propTypes } from '@tabeo/scarf'
import DatoNotifications from 'components/Notifications/DatoNotifications'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import LayoutTopNavbar from 'components/layouts/LayoutTopNavbar'
import config from 'config'
import useCalendy from 'hooks/useCalendy'
import useTrengoWidget from 'hooks/useTrengoWidget'
import { useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useRouteMatch } from 'react-router-dom'
import { useAuth } from 'resources/Auth'
import styled from 'styled-components'
import Complaint from './Complaint'
import SupportBox from './SupportBox'
import Team from './Team'
import { ReactComponent as CallIcon } from './assets/call.svg'

const { LANDING_PAGE_URL } = config

const Footer = styled(Text)`
  a {
    color: ${p => p?.theme?.colors?.primary?.[3] || '#0dab76'};
  }
`

function Wrap({ children }) {
  const {
    data: { token },
  } = useAuth()

  return token ? (
    <LayoutSideNavbar>
      <Container>{children}</Container>
    </LayoutSideNavbar>
  ) : (
    <LayoutTopNavbar>{children}</LayoutTopNavbar>
  )
}

Wrap.propTypes = {
  children: propTypes.children,
}

const Support = () => {
  const match = useRouteMatch({
    path: '/support/complaint',
  })
  const isComplaint = !!match?.isExact
  const complaintModal = useRef()
  const { t } = useTranslation()

  const {
    data: { token },
  } = useAuth()

  const { isReady: isCallendyReady, open: openCallendyPopup } = useCalendy(
    'https://calendly.com/d/dmf-3bx-yyv?hide_gdpr_banner=1'
  )

  const { isReady: isTrengoReady, open: openTrengoWidget } = useTrengoWidget()

  return (
    <Wrap>
      <DatoNotifications />
      <Flex
        flexDirection={['row', 'column']}
        flexWrap="wrap"
        justifyContent="center"
        mb={6}
      >
        <View maxWidth="574px" textAlign="center" mb={[4, 6]}>
          <Subtitle pb={[3]} display="inline-block">
            {t('Here to help')}
          </Subtitle>
          <Text color="ink.2">
            {t(
              'If you have any questions, our team is only a call, email or WhatsApp away. Monday to Friday from 9am to 6pm.'
            )}
          </Text>
        </View>
        <Team className="mb-10" />
        <div className="flex flex-col gap-6 desktop:flex-row">
          {token ? (
            <SupportBox
              title={t('Live chat')}
              description={t(
                'Contact us if you need an answer within the hour during business hours.'
              )}
              icon={ChatBubbleLeftEllipsisIcon}
            >
              <Button
                sizeVariant="sm"
                width="100%"
                onClick={() => openTrengoWidget('chat')}
                disabled={!isTrengoReady}
                variant="secondary"
                mt="auto"
              >
                {t('Talk to Tabeo')}
              </Button>
            </SupportBox>
          ) : (
            <SupportBox
              title={t('Live chat')}
              description={t(
                'Contact us if you need an answer within the hour during business hours.'
              )}
              icon={ChatBubbleLeftEllipsisIcon}
            >
              <Button
                as={Link}
                sizeVariant="sm"
                width="100%"
                to="/login?redirect=/support"
                variant="secondary"
                mt="auto"
              >
                {t('Log in')}
              </Button>
            </SupportBox>
          )}
          {!token && (
            <SupportBox
              title={t('Email')}
              description={
                <>
                  {t(
                    'Send us an email and we will get back to you within 24 hours:'
                  )}{' '}
                  <a className="anchor" href="mailto:growth@tabeo.co.uk">
                    growth@tabeo.co.uk
                  </a>
                </>
              }
              icon={EnvelopeIcon}
            >
              <Button
                as="a"
                sizeVariant="sm"
                width="100%"
                href="mailto:growth@tabeo.co.uk"
                target="_blank"
                rel="noopener noreferrer"
                variant="secondary"
                mt="auto"
              >
                {t('Send email')}
              </Button>
            </SupportBox>
          )}
          <SupportBox
            title={t('Call back')}
            description={t(
              'You can also schedule a short call if you prefer to speak to us.'
            )}
            icon={CallIcon}
          >
            <Button
              onClick={openCallendyPopup}
              variant="secondary"
              sizeVariant="sm"
              width="100%"
              disabled={!isCallendyReady}
              mt="auto"
            >
              {t('Schedule call')}
            </Button>
          </SupportBox>

          <SupportBox
            title={t('FAQ')}
            description={t(
              'Easily search for the most common questions. Our FAQs are updated regularly.'
            )}
            icon={QuestionMarkCircleIcon}
          >
            <Button
              as="a"
              sizeVariant="sm"
              width="100%"
              href="https://intercom.help/tabeo"
              target="_blank"
              rel="noopener noreferrer"
              variant="secondary"
              mt="auto"
            >
              {t('Search FAQ')}
            </Button>
          </SupportBox>
        </div>
      </Flex>
      <Footer
        fontSize="12px"
        p={5}
        textAlign="center"
        maxWidth="680px"
        mx="auto"
      >
        <Trans>
          To learn more about Tabeo, please visit our{' '}
          <a
            href={`${LANDING_PAGE_URL}/about`}
            target="_blank"
            rel="noopener noreferrer"
          >
            about
          </a>{' '}
          page. You can also review our{' '}
          <a
            href={`${LANDING_PAGE_URL}/terms-and-conditions`}
            target="_blank"
            rel="noopener noreferrer"
          >
            T&Cs
          </a>
          ,{' '}
          <a
            href={`${LANDING_PAGE_URL}/privacy-policy`}
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </a>{' '}
          and{' '}
          <a
            href={`${LANDING_PAGE_URL}/cookies`}
            target="_blank"
            rel="noopener noreferrer"
          >
            cookie policy
          </a>
          . If you wish to make a complaint, you can do so{' '}
          <a
            href="/support"
            onClick={e => {
              e.preventDefault()
              complaintModal.current.open()
            }}
          >
            here
          </a>
          .
        </Trans>
      </Footer>
      <Complaint ref={complaintModal} isOpenByDefault={isComplaint} />
    </Wrap>
  )
}

Support.propTypes = {}

export default Support
