/* eslint-disable camelcase */
import { Attributes } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import values from './values'

function InvisalignAnswers({
  productAnswers: {
    why_do_you_want_straighter_teeth: whyDoYouWantStraighterTeeth,
    what_is_your_biggest_concern_with_your_smile:
      whatIsYourBiggestConcernWithYourSmile,
    spacing,
    crowding,
    do_you_have_any_missing_teeth: doYouHaveAnyMissingTeeth,
    interested_in_teeth_whitening: interestedInTeethWhitening,

    // Legacy
    how_many_implants: howManyImplants,
    how_many_missing_teeth: howManyMissingTeeth,
    had_braces_before: hadBracesBefore,
    pain_or_discomfort: painOrDiscomfort,
  },
}) {
  const { t } = useTranslation()
  return (
    <Attributes
      sections={[
        [
          {
            label: t('Reason for straighter teeth'),
            value: whyDoYouWantStraighterTeeth?.value
              .map(v => {
                if (
                  v === 'other' &&
                  whyDoYouWantStraighterTeeth.freeTextValue
                ) {
                  return `${values.whyDoYouWantStraighterTeeth[v]}: ${whyDoYouWantStraighterTeeth.freeTextValue}`
                }

                return values.whyDoYouWantStraighterTeeth[v]
              })
              .join(', '),
          },
          {
            label: t('Biggest concern'),
            value: whatIsYourBiggestConcernWithYourSmile?.value
              .map(v => values.whatIsYourBiggestConcernWithYourSmile[v])
              .join(', '),
          },
          {
            label: t('Spacing severity'),
            value: values.spacing[spacing],
          },
          {
            label: t('Crowding severity'),
            value: values.crowding[crowding],
          },
          {
            label: t('Implants or missing teeth'),
            value: values.doYouHaveAnyMissingTeeth[doYouHaveAnyMissingTeeth],
          },
          {
            label: t('Interested in teeth whitening'),
            value:
              values.interestedInTeethWhitening[interestedInTeethWhitening],
          },

          // Legacy
          {
            label: t('Implants'),
            value: values.howManyImplants[howManyImplants],
          },
          {
            label: t('Missing teeth'),
            value: values.howManyMissingTeeth[howManyMissingTeeth],
          },
          {
            label: t('Had braces before'),
            value: values.hadBracesBefore[hadBracesBefore],
          },
          {
            label: t('Pain or discomfort'),
            value: values.painOrDiscomfort[painOrDiscomfort],
          },
        ],
      ]}
    />
  )
}

InvisalignAnswers.propTypes = {
  productAnswers: PropTypes.object.isRequired,
}

export default InvisalignAnswers
