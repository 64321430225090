import { plansorchestrator } from '@tabeo/platform-api-client'
import { Attributes, View } from '@tabeo/scarf'
import Avatar from 'components/Avatar'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

type PatientPanelProps = {
  data?: plansorchestrator.ModelsGetSubscriptionAggregate
}

export default function PatientPanel({ data }: PatientPanelProps) {
  const { t } = useTranslation()

  const { patient, customer } = data || {}

  const name = `${patient?.firstName || ''} ${patient?.lastName || ''}`.trim()

  return (
    <View
      boxShadow={1}
      borderRadius={1}
      border={1}
      overflow="hidden"
      bg="white"
      data-testid="patient-panel"
    >
      <div className="p-5">
        <div className="flex gap-3">
          <Avatar name={name} />
          <div>
            <div className="font-medium">{name}</div>
            <div className="caption text-tabeo-ink-1">
              {patient?.relationship && patient?.relationship !== 'self'
                ? t('Patient')
                : t('Customer')}
            </div>
          </div>
        </div>
        <Attributes
          sections={[
            [
              {
                label: t('Date of birth'),
                value: patient?.birthdate,
                formattedValue: t(`{{birthDate}} ({{age}} old)`, {
                  birthDate: moment(patient?.birthdate).format('DD MMM YYYY'),
                  age: moment(patient?.birthdate).toNow(true),
                }),
              },
            ],
          ]}
          className="mt-4"
        />
      </div>
      <div className="border-t border-tabeo-sky-1 p-5">
        {patient?.relationship && patient?.relationship !== 'self' && (
          <>
            <p className="caption mb-3 text-tabeo-ink-2">
              {t('This membership is paid by:')}
            </p>
            <Attributes
              sections={[
                [
                  {
                    label: t('Name'),
                    value: `${customer?.firstName} ${customer?.lastName}`,
                  },
                  {
                    label: t('Relationship to the patient'),
                    value: `${patient?.relationship.capitalize()}`,
                  },
                ],
              ]}
              className="mb-2"
            />
          </>
        )}
        <Attributes
          sections={[
            [
              {
                label: t('Email address'),
                value: customer?.email,
              },
              { label: t('Phone number'), value: customer?.mobilePhone },
              {
                label: t('Home address'),
                // @ts-ignore
                value: customer?.homeAddress?.label,
              },
            ],
          ]}
        />
      </div>
    </View>
  )
}
