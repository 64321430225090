import { useCallback, useEffect, useState } from 'react'
import { getQueryStringValue, setQueryStringValue } from '../utils/queryString'

function useSearchQueryState(key, initialValue) {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue)
  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue)
      setQueryStringValue(key, newValue)
    },
    [key]
  )

  useEffect(() => {
    function popState() {
      setValue(getQueryStringValue(key) || initialValue)
    }
    window.addEventListener('popstate', popState)
    return () => {
      window.removeEventListener('popstate', popState)
    }
  }, [key, initialValue])

  return [value, onSetValue]
}

export default useSearchQueryState
