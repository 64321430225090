import { Radio as BaseRadio, themeGet, View } from '@tabeo/scarf'
import config from 'config'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

const clusterEnv =
  config.ENV === 'demo' ? 'playground' : config.ENV || 'development'

const getImageURL = img =>
  `https://storage.googleapis.com/tabeo_${clusterEnv}_uploads/plan_icons/${img}`

const dentalIcons = [
  `dental/gold.png`,
  `dental/silver.png`,
  `dental/bronze.png`,
  `dental/relax.png`,
  `dental/normal.png`,
  `dental/cover.png`,
  `dental/supertooth.png`,
  `dental/corporate.png`,
  `dental/checkup.png`,
  `dental/implant.png`,
  `dental/invisalign.png`,
  `dental/tooth-care.png`,
  `dental/tooth.png`,
  `dental/family.png`,
  `dental/man-closeup-1.png`,
  `dental/man-closeup-2.png`,
  `dental/man.png`,
  `dental/woman-2.png`,
  `dental/woman-closeup-1.png`,
  `dental/woman-closeup-2.png`,
  `dental/woman-closeup-3.png`,
  `dental/woman-closeup.png`,
  `dental/woman.png`,
  `dental/invisalign-logo.png`,
  `dental/u-plan.png`,
  `dental/perfect-smile.png`,
].map(i => getImageURL(i))

const vetIcons = [
  `vet/dog/herding/01.png`,
  `vet/dog/herding/02.png`,
  `vet/dog/herding/03.png`,
  `vet/dog/herding/04.png`,
  `vet/dog/herding/05.png`,
  `vet/dog/herding/06.png`,
  `vet/dog/herding/07.png`,
  `vet/dog/herding/08.png`,
  `vet/dog/herding/09.png`,
  `vet/dog/herding/10.png`,
  `vet/dog/hound/01.png`,
  `vet/dog/hound/02.png`,
  `vet/dog/hound/03.png`,
  `vet/dog/hound/04.png`,
  `vet/dog/hound/05.png`,
  `vet/dog/hound/06.png`,
  `vet/dog/hound/07.png`,
  `vet/dog/hound/08.png`,
  `vet/dog/retriever/labrador-blue.png`,
  `vet/dog/retriever/labrador-doggo.png`,
  `vet/dog/retriever/labrador-pup.png`,
  `vet/dog/sporting/01.png`,
  `vet/dog/sporting/02.png`,
  `vet/dog/sporting/03.png`,
  `vet/dog/sporting/04.png`,
  `vet/dog/sporting/05.png`,
  `vet/dog/sporting/06.png`,
  `vet/dog/terrier/01.png`,
  `vet/dog/terrier/02.png`,
  `vet/dog/terrier/03.png`,
  `vet/dog/terrier/04.png`,
  `vet/dog/terrier/05.png`,
  `vet/dog/terrier/06.png`,
  `vet/dog/terrier/07.png`,
  `vet/dog/terrier/08.png`,
  `vet/dog/toy/01.png`,
  `vet/dog/toy/02.png`,
  `vet/dog/toy/03.png`,
  `vet/dog/toy/04.png`,
  `vet/dog/toy/05.png`,
  `vet/dog/toy/06.png`,
  `vet/dog/toy/07.png`,
  `vet/dog/toy/08.png`,
  `vet/dog/toy/09.png`,
  `vet/dog/toy/10.png`,
  `vet/dog/toy/11.png`,
  `vet/dog/toy/12.png`,
  `vet/cat/01.png`,
  `vet/cat/abyssinian.png`,
  `vet/cat/american-shorthair.png`,
  `vet/cat/bengal.png`,
  `vet/cat/british-short-hair.png`,
  `vet/cat/burmese.png`,
  `vet/cat/cornish-rex.png`,
  `vet/cat/kurilian-bobtail.png`,
  `vet/cat/maine-coon.png`,
  `vet/cat/munchkin.png`,
  `vet/cat/oriental.png`,
  `vet/cat/russian-blue.png`,
  `vet/cat/scottish-fold.png`,
  `vet/cat/siamese.png`,
  `vet/cat/sphinx.png`,
  `vet/cat/toyger.png`,
  `vet/cat/turkish-van.png`,
  `vet/other/camaleon.png`,
  `vet/other/hamster.png`,
  `vet/other/parrot.png`,
  `vet/other/rabbit.png`,
  `vet/farm/01-cow.png`,
  `vet/farm/02-horse.png`,
  `vet/farm/03-sheep.png`,
  `vet/farm/04-pig.png`,
  `vet/farm/05-goat.png`,
  `vet/farm/06-chickens.png`,
  `vet/farm/07-turkey.png`,
  `vet/farm/08-rabbit.png`,
  `vet/farm/09-cow.png`,
  `vet/farm/10-horse.png`,
].map(i => getImageURL(i))

const Icon = styled(View)`
  width: 80px;
  height: 80px;
`

Icon.defaultProps = {
  as: 'img',
  mx: 'auto',
}

const Item = styled(View)`
  cursor: pointer;
  outline: none;
  ${p =>
    p.selected &&
    css`
      box-shadow: 0 0 1px 2px ${themeGet('colors.default.primary')};
    `};
`

Item.defaultProps = {
  borderRadius: 1,
  border: 1,
  p: 3,
  bg: 'white',
  omitProps: ['selected'],
}

const Selector = styled(View)`
  max-height: 315px;
  overflow: auto;
  border-left: none;
  border-right: none;
`

const Radio = styled(BaseRadio)`
  &:after {
    content: '';
    width: 102px;
  }
`

const noImage = `/images/no-image.svg`

const IconSelect = ({ sector, ...rest }) => {
  const icons =
    sector === 'dental_services'
      ? [...dentalIcons, noImage]
      : [...vetIcons, noImage]
  return (
    <Selector pt={5} mx="-20px" px={5}>
      <Radio
        {...rest}
        justifyContent="space-between"
        flexWrap="wrap"
        flexDirection="row"
        items={icons.map(i => ({ value: i, label: i }))}
        renderItem={({
          item: { value },
          disabled,
          horizontal,
          ...inputProps
        }) => (
          <Item {...inputProps} mb={5}>
            <Icon src={value} />
          </Item>
        )}
      />
    </Selector>
  )
}

IconSelect.propTypes = {
  sector: PropTypes.string,
}

export default IconSelect
