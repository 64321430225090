import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from 'resources/Auth'
import sanitizeRedirectParam from 'utils/sanitizeRedirectParam'

function useAuthGuard({ redirect, skip } = {}) {
  const {
    data: { token, loginEmail },
  } = useAuth()

  const history = useHistory()
  const { pathname, search } = useLocation()

  const getQuery = () => {
    const query = new URLSearchParams()

    query.append(
      'redirect',
      (redirect && sanitizeRedirectParam(redirect)) || `${pathname}${search}`
    )

    if (loginEmail) {
      query.append('email', loginEmail)
    }

    return query.toString()
  }

  if (!token && !skip) {
    history.replace(`/login?${getQuery()}`)
  }

  return null
}

export default useAuthGuard
