// @ts-nocheck

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { children } from '../../../utils/propTypes'

import Flex from '../../Flex'
import Text from '../../Text'

import Dot from './Dot'
import { InputProp, MetaProp } from '../../../types/form'
import ScarfProps from '../../../types/ScarfProps'
import { Merge } from '../../../types/helpers'

export type ContainerProps = {
  disabled?: boolean
}

const Container = styled(Flex)<ContainerProps>`
  outline: none;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
`

export type CheckboxProps = Merge<
  ScarfProps,
  ContainerProps & {
    input?: Partial<InputProp>
    meta?: Partial<MetaProp>
    render?: (renderProsp: {
      onMouseDown: (e: React.MouseEvent) => void
      onKeyDown: (e: React.KeyboardEvent) => void
      tabIndex: number
      selected: boolean
      disabled?: boolean
      children: React.ReactNode
    }) => React.ReactNode
  }
>

class Checkbox extends Component<CheckboxProps> {
  state = {}

  componentDidMount() {
    const {
      input: { value },
    } = this.props
    if (value) {
      this.setState({ value })
    }
  }

  componentDidUpdate(previousProps) {
    const {
      input: { value },
    } = this.props
    if (value !== previousProps.input.value) {
      this.setState({
        value,
      })
    }
  }

  handleChange = () => {
    const {
      input: { onChange },
      disabled,
    } = this.props
    const { value } = this.state
    !disabled && this.setState({ value: !value }, () => onChange(!value))
  }

  handleKeyDown = (event) => {
    event.keyCode === 13 && this.handleChange(event)
  }

  render() {
    const {
      children,
      render,
      disabled,
      meta: { error, touched },
      ...rest
    } = this.props
    const { value } = this.state
    const selected = !!value

    const renderProps = {
      onMouseDown: (event) => this.handleChange(event),
      onKeyDown: (event) => this.handleKeyDown(event),
      tabIndex: 0,
      selected,
      disabled,
      children,
    }

    return render ? (
      render(renderProps)
    ) : (
      <Container
        alignItems="flex-start"
        justifyContent="flex-start"
        {...renderProps}
        {...rest}
      >
        <Dot
          selected={selected}
          disabled={disabled}
          error={!!error && touched}
          mr={3}
        />
        {typeof children === 'string' ? (
          <Text color={disabled ? 'ink.2' : undefined} textAlign="left">
            {children}
          </Text>
        ) : (
          children
        )}
      </Container>
    )
  }
}

Checkbox.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ]),
    onChange: PropTypes.func,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  render: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, children]),
}

Checkbox.defaultProps = {
  input: {
    onChange: () => {},
    value: false,
  },
  meta: {},
}

export default Checkbox
