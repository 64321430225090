import {
  AddressSelect,
  Button,
  PhoneInput,
  Prompt,
  Radio,
  Select,
  TextInput,
  Title,
  View,
} from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import { phonePCA, required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import Layout from 'components/Layout'
import useAuthGuard from 'hooks/useAuthGuard'
import useFlow from 'hooks/useFlow'
import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'resources/Auth'
import Progressbar from '../../components/Signup/Progressbar'
import { useMerchant } from '../../resources/Merchant'

function BusinessDetails() {
  useAuthGuard()

  const {
    data: merchant,
    resource: { addCompanyDetails },
  } = useMerchant()
  const {
    data: { user },
  } = useAuth()

  const { next, loading } = useFlow({ merchant, user })

  const { t } = useTranslation()
  const { companyType, name } = merchant || {}
  async function handleSubmit(values) {
    try {
      const payload = {
        tradingName: name,
        ...values,
        addressJSON: JSON.parse(values.addressJSON),
      }
      await addCompanyDetails(payload)
      await next()
    } catch (e) {
      return formError(e)
    }
  }
  const insuranceLabels = {
    dental_services: t('Are all your clinical staff registered with the GDC?'),
    optical_care: t('Are all your clinical staff registered with the GOC?'),
    cosmetic_surgery: t('Are all your clinical staff registered with the GMC?'),
    private_hospital: t('Are all your clinical staff registered with the GMC?'),
    veterinary_services: t(
      'Are all your clinical staff registered with the RCVS (or RVN?)?'
    ),
    physiotherapy: t(
      'Are you or your clinical staff registered with the HCPC?'
    ),
    skin_laser_facial_treatments: t(
      'Are you or your business registered with the CQC or equivalent?'
    ),
    medical_training: t('Do you have professional liability insurance?'),
    other_health_care: t(
      'Are you or your business registered with the CQC or equivalent?'
    ),
    hair_transplant: t(
      'Are you or your business registered with the CQC/BAHRS?'
    ),
    hearing_aids: t('Are you or your business registered with the HCPC?'),
  }

  const turnoverConfig = {
    dental_services: {
      label: t('How many surgeries do you own?'),
      options: [
        {
          label: t('1-2 treatment rooms'),
          value: 'up_to_249',
        },
        {
          label: t('3-4 treatment rooms'),
          value: 'from_250_to_499',
        },
        {
          label: t('5-6 treatment rooms'),
          value: 'from_500_to_999',
        },
        {
          label: t('7-8 treatment rooms'),
          value: 'from_1m_to_5m',
        },
        {
          label: t('9+ treatment rooms'),
          value: 'over_5m',
        },
      ],
    },
    default: {
      label: t(
        'What was your business’s total annual turnover in the last financial year?'
      ),
      options: [
        {
          label: t('Up to £249,999'),
          value: 'up_to_249',
        },
        {
          label: t('£250,000 - £499,999'),
          value: 'from_250_to_499',
        },
        {
          label: t('£500,000 - £999,999'),
          value: 'from_500_to_999',
        },
        {
          label: t('£1 million - £5 million'),
          value: 'from_1m_to_5m',
        },
        {
          label: t('£5 million or more'),
          value: 'over_5m',
        },
      ],
    },
  }
  return (
    <Layout maxWidth="640px" isLoading={loading}>
      <Progressbar companyType={companyType} currentIndex={1} mb={6} />
      <View as={Form} onSubmit={handleSubmit}>
        {({ handleSubmit, submitting, values: { sector } }) => (
          <form onSubmit={handleSubmit}>
            <Title mb={3}>{t('Business details')}</Title>
            <Prompt mb={4}>{t('Tell us about your business.')}</Prompt>
            <Field
              component={Select}
              maxWidth={['auto', '250px']}
              name="sector"
              label={t('What field do you operate in?')}
              placeholder={t('Select field...')}
              options={[
                { label: t('Dental care'), value: 'dental_services' },
                { label: t('Optical care'), value: 'optical_care' },
                { label: t('Cosmetic surgery'), value: 'cosmetic_surgery' },
                { label: t('Private hospital'), value: 'private_hospital' },
                {
                  label: t('Veterinary Services'),
                  value: 'veterinary_services',
                },
                { label: t('Physiotherapy'), value: 'physiotherapy' },
                {
                  label: t('Skin, laser, facial treatments (non-invasive)'),
                  value: 'skin_laser_facial_treatments',
                },
                { label: t('Medical training'), value: 'medical_training' },
                { label: t('Hair transplant'), value: 'hair_transplant' },
                { label: t('Hearing aids'), value: 'hearing_aids' },
              ]}
              validate={[required]}
            />

            {sector && (
              <Field
                component={Select}
                maxWidth={['auto', '250px']}
                name="turnover"
                label={
                  (turnoverConfig[sector] || turnoverConfig.default)?.label
                }
                placeholder={t('Please Select...')}
                validate={[required]}
                options={
                  (turnoverConfig[sector] || turnoverConfig.default)?.options
                }
              />
            )}

            <Field
              component={Select}
              maxWidth={['auto', '250px']}
              label={t('How many employees does your business have?')}
              name="numberOfEmployees"
              placeholder={t('Please Select...')}
              validate={[required]}
              options={[
                { label: t('0-2'), value: 'up_to_2' },
                { label: t('3-7'), value: 'from_3_to_7' },
                { label: t('8-12'), value: 'from_8_to_12' },
                { label: t('13-19'), value: 'from_13_to_19' },
                { label: t('20 or more'), value: 'over_20' },
              ]}
            />

            {[
              'private_limited_company',
              'limited_liability_partnership',
            ].includes(companyType) && (
              <Field
                component={TextInput}
                name="tradingName"
                label={t('What is your business’s trading name?')}
                placeholder={t('Enter trading name...')}
                validate={[required]}
                description={t('This is the name your customers will see')}
              />
            )}

            <Field
              component={AddressSelect}
              name="addressJSON"
              label={t('What is the business’s trading address?')}
              placeholder={t('Enter trading address...')}
              validate={[required]}
              description={t(
                'This is the address your customers will use to get to you'
              )}
            />

            <Field
              data-no-track
              component={PhoneInput}
              maxWidth={['auto', '250px']}
              name="phoneNumber"
              label={t('Business phone number')}
              type="tel"
              validate={[required, phonePCA]}
              placeholder={t('e.g. 0777-1234567')}
              description={t(
                'This is the number customers will call to get in touch with you'
              )}
            />

            {merchant.isIT && (
              <Field
                component={TextInput}
                name="vatNumber"
                label={t('VAT number')}
                validate={[required]}
              />
            )}

            {sector && (
              <Field
                component={Radio}
                label={insuranceLabels[sector]}
                name="liabilityInsurance"
                validate={[required]}
                horizontal
                items={[
                  { label: t('Yes'), value: true },
                  { label: t('No'), value: false },
                ]}
              />
            )}
            <Button loading={submitting}>{t('Next')}</Button>
            <FormError />
          </form>
        )}
      </View>
    </Layout>
  )
}

BusinessDetails.propTypes = {}

export default BusinessDetails
