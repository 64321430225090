import { Banner, Button, Caption } from '@tabeo/scarf'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import RedirectModal from './RedirectModal'

const StripeNotification = ({ resource, ...rest }) => {
  const { t } = useTranslation()

  const { data, getStripeConnectOnboardingURL } = resource
  const { restrictedAt, stripeFundGatewayState } = data
  const redirectModal = React.useRef()

  function getNotification() {
    if (stripeFundGatewayState === 'restricted_soon') {
      return (
        <Banner color="sea" title={t('Please update your details')} {...rest}>
          <Caption mb={3}>
            <Trans>
              In order to comply with payments regulation we require you to
              update some of your business and ownership details. Please update
              these details before{' '}
              {{ restrictedAt: moment(restrictedAt).format('DD MMM YYYY') }}. If
              you do not update your details before then, your payouts will be
              restricted.
            </Trans>
          </Caption>
          <Button
            sizeVariant="sm"
            color="sea.3"
            variant="secondary"
            onClick={() => redirectModal.current.open()}
          >
            {t('Update details')}
          </Button>
        </Banner>
      )
    }
    if (stripeFundGatewayState === 'pending_verification') {
      return (
        <Banner title={t("We're checking your information")} {...rest}>
          <Caption>
            <Trans>
              Thank you for updating your details which we are now checking. You
              will receive an email confirmation once the process is complete.
              In the mean time your payments will be kept on hold.
            </Trans>
          </Caption>
        </Banner>
      )
    }

    return (
      <Banner color="yellow" title={t('Your payouts are on hold')} {...rest}>
        <Caption mb={3}>
          <Trans>
            In order to comply with payments regulation we require you to update
            some of your business and ownership details. You are free to create
            new transactions, but you will not receive any payouts until your
            details are updated.
          </Trans>
        </Caption>
        <Button
          sizeVariant="sm"
          variant="secondary"
          color="yellow.2"
          onClick={() => redirectModal.current.open()}
        >
          {t('Update details')}
        </Button>
      </Banner>
    )
  }

  return (
    <>
      <RedirectModal
        innerRef={redirectModal}
        onConfirm={getStripeConnectOnboardingURL}
      />
      {getNotification()}
    </>
  )
}

StripeNotification.propTypes = {
  resource: PropTypes.object.isRequired,
}

export default StripeNotification
