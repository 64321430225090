import { Button } from '@tabeo/scarf'
import FeatureBlockModal, {
  CREATE_PLAN,
} from 'components/FeatureBlockModal/FeatureBlockModal'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

function CreatePlanButton(props) {
  const { history, location, match, staticContext, merchant, ...rest } = props
  const modal = useRef()

  const { t } = useTranslation()
  return (
    <>
      <FeatureBlockModal feature={CREATE_PLAN} innerRef={modal} />
      {merchant?.isBankAccountVerified ? (
        <Button
          variant="secondary"
          onClick={() => history.push('/new-plan')}
          {...rest}
        >
          {t('Create new plan')}
        </Button>
      ) : (
        <Button variant="secondary" onClick={() => modal.current.open()}>
          {t('Create new plan')}
        </Button>
      )}
    </>
  )
}

CreatePlanButton.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  match: PropTypes.object,
  merchant: PropTypes.object,
  staticContext: PropTypes.any,
}

export default withRouter(CreatePlanButton)
