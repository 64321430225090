function formatDate(date, withTime) {
  const dtf = new Intl.DateTimeFormat('en', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour12: true,
  })
  const dateObject = new Date(date)
  const [
    { value: MMM },
    ,
    { value: DD },
    ,
    { value: YYYY },
    ,
    { value: hh },
    ,
    { value: mm },
    ,
    ,
    ,
    { value: dayPeriod },
  ] = dtf.formatToParts(dateObject)

  return `${DD} ${MMM} ${YYYY}${
    withTime
      ? ` at ${`0${hh}`.slice(-2)}:${mm} ${dayPeriod.toLocaleLowerCase()}`
      : ''
  }`
}

export default formatDate
