import { Button, Icons, Title } from '@tabeo/scarf'
import Notifications from 'components/Notifications'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useMerchant } from 'resources/Merchant'
import { usePlans } from '../../resources/Plans'
import PlanListPanel from './PlanListPanel'
import Subscriptions from './Subscriptions'

function Plans() {
  const { data: merchant } = useMerchant()
  const { data: plans } = usePlans()
  const { t } = useTranslation()

  if (!merchant) return null

  const { flags } = merchant || {}

  return (
    <LayoutSideNavbar isLoading={!plans}>
      {plans?.items && (
        <Container>
          <Notifications mb={6} />
          <div className="mb-4 flex justify-between">
            <Title>{t('Subscriptions')}</Title>
            {flags?.is_new_transaction_screen_v2_capable &&
              flags?.is_subscription_plan_capable && (
                <Button
                  data-testid="new-subscription"
                  variant="secondary"
                  as={Link}
                  to="/new-subscription"
                  leftIcon={p => <Icons.Plus light {...p} />}
                >
                  {t('New subscription')}
                </Button>
              )}
          </div>
          <Subscriptions mb={6} />
          <PlanListPanel plans={plans?.items ?? []} />
        </Container>
      )}
    </LayoutSideNavbar>
  )
}

export default Plans
