import config from 'config'

const { LOCALE } = config

const pr = new Intl.PluralRules(LOCALE, { type: 'ordinal' })

const suffixes = {
  'en-GB': {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  },
}

export default function getOrdinalNum(n) {
  return `${n}${suffixes?.[LOCALE]?.[pr.select(n)] || ''}`
}
