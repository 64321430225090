import React from 'react'
import ScarfProps from '../../../types/ScarfProps'
import Flex from '../../Flex'
import View from '../../View'
import Code from './Code'
import Comment from './Comment'
import Date from './Date'
import Title from './Title'

export type ItemProps = ScarfProps & {
  icon: () => React.ReactNode
  isLastItem: boolean
  frameWrapperProps?: ScarfProps
  contentWrapperProps?: ScarfProps
}

function Item({
  icon,
  children,
  isLastItem,
  frameWrapperProps,
  contentWrapperProps,
  ...rest
}: ItemProps) {
  return (
    <Flex
      alignItems="stretch"
      _notFirst={{
        mt: '6px',
      }}
      _last={{
        mb: -3,
      }}
      {...rest}
    >
      <Flex flexDirection="column" mr={3} {...frameWrapperProps}>
        {icon()}
        {!isLastItem && (
          <View width="2px" bg="sky.1" flex="1" borderRadius="2px" mt="6px" />
        )}
      </Flex>
      <View flex="1" mb={3} {...contentWrapperProps}>
        {children}
      </View>
    </Flex>
  )
}

Item.Comment = Comment
Item.Title = Title
Item.Date = Date
Item.Code = Code

export default Item
