import { Button, Caption, Flex, Heading, View } from '@tabeo/scarf'
import FieldError from 'components/Form/FieldError'
import Card from 'components/Transaction/ActionPanel/Card'
import usePromise from 'hooks/usePromise'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import icon from './assets/applying.svg'

function Applying({ transaction, ...rest }) {
  const { data, sendReminder, cancel } = transaction
  const { consumerFirstName } = data
  const [triggerReminder, reminderState] = usePromise(sendReminder)
  const [triggerCancel, cancelState] = usePromise(cancel)
  const { t } = useTranslation()

  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Applying for loan')}</Heading>
      </Flex>
      <Caption color="ink.1" mb={4}>
        {t(
          '{{consumerFirstName}} has selected a payment option and created an account, but hasn’t finished the credit application.',
          { consumerFirstName }
        )}
      </Caption>
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="flex-start"
        alignItems={['stretch', 'center']}
      >
        <Button
          variant="secondary"
          sizeVariant="sm"
          mr={[0, 4]}
          mb={[4, 0]}
          onClick={triggerReminder}
          loading={reminderState.pending}
          disabled={reminderState.success}
        >
          {reminderState.success ? t('Reminder sent') : t('Send reminder')}
        </Button>
        <Button
          variant="secondary"
          sizeVariant="sm"
          onClick={triggerCancel}
          loading={cancelState.pending}
        >
          {t('Cancel transaction')}
        </Button>
      </Flex>
      {(reminderState.error || cancelState.error) && (
        <FieldError
          message={t('There was an error, please try again later.')}
          mt={4}
        />
      )}
    </Card>
  )
}

Applying.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default Applying
