import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import { Document as D } from '@tabeo/ts-types'

interface Params {
  id: string | number
}

class Document extends Resource<D, Params> {
  async fetch() {
    const subResources = formatSubresources(['content'])

    const { document } = await api.get(
      `/merchant/documents/${this.params.id}?${subResources}`
    )

    this.data = document
  }
}

export default Document
export const useDocument = createResourceHook(Document)
