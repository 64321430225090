import { View } from '@tabeo/scarf'
import { children } from '@tabeo/sharpei/utils/propTypes'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import Notifications from 'components/Notifications'
import PropTypes from 'prop-types'
import React from 'react'

const Layout = ({ children, isLoading, notification }) => (
  <LayoutSideNavbar isLoading={isLoading}>
    <Container maxWidth="1020px">
      <Notifications mb={6} />
      {notification}
      <View display="grid" gridTemplateColumns={['1fr', '7fr 5fr']} gridGap={5}>
        {children}
      </View>
    </Container>
  </LayoutSideNavbar>
)

Layout.propTypes = {
  children,
  notification: children,
  isLoading: PropTypes.bool,
}

export default Layout
