import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import moment from 'moment'
import { useRouteMatch } from 'react-router-dom'
import normalize from './normalizers/bill'

const subResources = `subResource=${[
  'consumer.user',
  'stateMachineTransitions',
  'metadata',
  'flags',
  'merchant',
  'paymentSource',
  'basket',
].join('&subResource=')}`

class Bill extends Resource {
  getInitialConfig() {
    return {
      refreshInterval: 15 * 1000,
    }
  }

  async fetch() {
    const { bill } = await api.get(
      `/merchant/bills/${this.params.id}?${subResources}`
    )

    this.data = normalize(bill)
  }

  cancel = async () => {
    const response = await api.post(
      `/merchant/bills/${this.params.id}/cancellation`,
      {}
    )

    await this.fetch()
    return response
  }

  update = async ({ scheduledAt }) => {
    const payload = {
      scheduledAt: moment(scheduledAt).set({
        hour: '6',
        minute: '0',
        second: '0',
      }),
    }

    const response = await api.put(
      `/merchant/bills/${this.params.id}/scheduled-at`,
      payload
    )

    await this.fetch()
    return response
  }

  charge = async () => {
    const response = await api.post(
      `/merchant/bills/${this.params.id}/charge-now`,
      {}
    )
    const billState = this.data.state

    await this.fetch()

    if (response.status.includes('failed')) {
      const error = new Error(response.message)
      error.response = response
      error.billState = billState

      throw error
    }

    return response
  }

  updateMetadata = async (type, payload) => {
    const response = await api.put(
      `/merchant/offers/${this.params.id}/metadata`,
      {
        type,
        [type]: payload,
      }
    )

    await this.fetch()
    return response
  }
}

export default Bill
export const useResource = createResourceHook(Bill)

export function useBill({ id } = {}) {
  const match = useRouteMatch({
    path: ['/bills/:billId'],
  })

  const {
    params: { billId },
  } = match || { params: {} }

  const billIdParam = id || billId

  return useResource(() => {
    if (!billIdParam) {
      throw new Error('Bill ID is null')
    }
    return {
      id: Number.isNaN(Number(billIdParam)) ? billIdParam : +billIdParam,
    }
  })
}
