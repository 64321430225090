import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import { Currency } from '@tabeo/ts-types'
import { NormalizedLinkPayment } from 'types/NormalizedLinkPayment'
import normalize from './normalizers/linkPayment'

const subResources = formatSubresources([
  'basket.patient',
  'basket.merchantTreatment.treatment',
  'basket.treatmentName',
])
interface Data {
  items: null | NormalizedLinkPayment[]
  totalCount: number
}

interface Params {
  searchQuery?: string
  limit?: number
  offset?: number
  conditions?: any
  orderBy?: {
    field: string
    direction: 'asc' | 'desc'
  }
}

class LinkPayments extends Resource<Data, Params> {
  getInitialData() {
    return {
      items: null,
      totalCount: 0,
    }
  }

  getInitialConfig() {
    return {
      refreshInterval: 30 * 1000,
    }
  }

  async fetch() {
    const {
      searchQuery,
      limit = 10,
      offset = 0,
      conditions = [],
      orderBy: { field = 'id', direction = 'desc' } = {
        field: 'id',
        direction: 'desc',
      },
    } = this.params

    const pagination = `&limit=${limit}&offset=${offset}`
    const jsonConditions = `&jsonConditions=${encodeURIComponent(
      JSON.stringify(conditions)
    )}`
    const order = `&order=${field}:${direction}`

    const endpoint = searchQuery
      ? `/merchant/link-payment-search?searchPhrase=${encodeURIComponent(
          searchQuery
        )}&${subResources}`
      : `/merchant/link-payments?${subResources}`

    const {
      data: { linkPayments },
      totalCount,
    } = await api.get(`${endpoint}${jsonConditions}${pagination}${order}`, {
      responseDataPath: 'data',
    })

    this.data = {
      totalCount,
      items: linkPayments.map((tp: any) => normalize(tp)),
    }

    return this.data
  }

  static create = async ({
    ...payload
  }: {
    merchantTreatmentID?: number
    merchantClinicianID?: number
    patientID?: number
    patient?: {
      gender?: string
      firstName?: string
      lastName?: string
      phoneNumber?: string
      email?: string
    }
    amount: number
    currency: Currency
    treatmentName?: string
    clinicianName?: string
  }) => {
    const {
      projections: { linkPayment },
    } = await api.post('/merchant/link-payments', payload)

    return linkPayment
  }
}

export default LinkPayments
export const useLinkPayments = createResourceHook(LinkPayments)
