import { useEffect, useState } from 'react'

function useFocus({ onFocus, onBlur }) {
  const [state, setState] = useState(() => document.hasFocus())

  useEffect(() => {
    function handleFocusChange(e) {
      const hasFocus = document.hasFocus()
      if (hasFocus) {
        onFocus && onFocus(e)
      } else {
        onBlur && onBlur(e)
      }
      setState(hasFocus)
    }

    window.addEventListener('focus', handleFocusChange)
    window.addEventListener('blur', handleFocusChange)

    return () => {
      window.removeEventListener('focus', handleFocusChange)
      window.removeEventListener('blur', handleFocusChange)
    }
  }, [onFocus, onBlur])

  return state
}

export default useFocus
