import React from 'react'
import styled from 'styled-components'
import ScarfProps from '../types/ScarfProps'
import colors from '../theme/colors'
import { Subheading } from './Text'
import { Merge } from '../types/helpers'

type ColorKeys =
  | 'grey'
  | 'green'
  | 'yellow'
  | 'red'
  | 'teal'
  | 'sea'
  | 'blue'
  | 'orange'
  | 'darkGrey'

const colorStyles: Record<ColorKeys, { color: string; background: string }> = {
  grey: {
    color: colors.ink[1],
    background: colors.sky[1],
  },
  green: {
    color: colors.green[1],
    background: colors.green[5],
  },
  yellow: {
    color: colors.yellow[1],
    background: colors.yellow[5],
  },
  red: {
    color: colors.red[1],
    background: colors.red[5],
  },
  teal: {
    color: colors.teal[2],
    background: colors.teal[5],
  },
  sea: {
    color: colors.sea[2],
    background: colors.sea[5],
  },
  blue: {
    color: colors.blue[2],
    background: colors.blue[5],
  },
  orange: {
    color: colors.orange[1],
    background: colors.orange[5],
  },
  darkGrey: {
    color: 'white',
    background: colors.ink[2],
  },
}

type TagProps = {
  small?: boolean
}

const Tag = styled(Subheading).attrs<TagProps>(
  ({ small, py, px, fontSize }) => ({
    py: py || (small ? 0 : 1),
    px: px || (small ? 2 : 3),
    fontSize: fontSize || (small ? '10px' : 0),
  })
)<TagProps>`
  display: inline-flex;
  border-radius: 16px;
  white-space: nowrap;
  ${(p) =>
    p.small &&
    `
    letter-spacing: 0.5px;
  `}
`

Tag.defaultProps = {
  lineHeight: '20px',
}

export type WrapProps = Merge<
  ScarfProps,
  TagProps & {
    yellow?: boolean
    red?: boolean
    green?: boolean
    grey?: boolean
    blue?: boolean
    sea?: boolean
    teal?: boolean
    orange?: boolean
    darkGrey?: boolean
    color?: ColorKeys
    children?: ScarfProps['children']
  }
>

const Wrap = React.forwardRef<HTMLDivElement, WrapProps>(
  (
    {
      /* eslint-disable react/prop-types */
      small,
      yellow,
      red,
      green,
      blue,
      sea,
      teal,
      grey,
      orange,
      darkGrey,
      color,
      children,
      /* eslint-enable react/prop-types */
      ...rest
    },
    ref
  ) => {
    const style = ([
      yellow ? colorStyles.yellow : false,
      red ? colorStyles.red : false,
      green ? colorStyles.green : false,
      blue ? colorStyles.blue : false,
      sea ? colorStyles.sea : false,
      teal ? colorStyles.teal : false,
      orange ? colorStyles.orange : false,
      darkGrey ? colorStyles.darkGrey : false,
    ].find((i) => i !== false) ||
      (color ? colorStyles[color] : undefined) ||
      colorStyles.grey) as { color: string; background: string }

    return (
      <Tag
        ref={ref}
        color={style.color}
        bg={style.background}
        small={small}
        {...rest}
      >
        {children}
      </Tag>
    )
  }
)

export default Wrap
