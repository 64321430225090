import {
  Button,
  Flex,
  PhoneInput,
  Subheading,
  Switch,
  TextInput,
  View,
} from '@tabeo/scarf'
import { ButtonProps } from '@tabeo/scarf/dist/components/Card'
import formError from '@tabeo/sharpei/utils/formError'
import { email, phone, required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import React from 'react'
import { Field as FormField, Form, FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'

type SendReceiptProps = {
  initialValues?: { email: string | undefined; phoneNumber: string | undefined }
  onSubmit: (values: any) => void
  successScreen: React.ReactElement
  label: string
  buttonText: string
  buttonVariant: ButtonProps['variant']
  config?: {
    email?: boolean
    phoneNumber?: boolean
  }
} & React.ComponentProps<typeof View>

function SendReceipt({
  initialValues,
  onSubmit,
  successScreen,
  label,
  buttonText,
  buttonVariant,
  config = {
    email: true,
    phoneNumber: true,
  },
  ...rest
}: SendReceiptProps) {
  const { t } = useTranslation()
  const lastValues = React.useRef<any>()

  const handleSubmit = async (values: any) => {
    try {
      const payload = Object.entries(values).reduce(
        (acc, [key, value]: any) => {
          if (value.send) {
            return {
              ...acc,
              [key]: value.value,
            }
          }
          return acc
        },
        {}
      )

      await onSubmit(payload)
    } catch (e) {
      return formError(e)
    }
  }

  const isMultiInputEnabled =
    Object.entries(config).filter(entry => entry[1]).length > 1
  const isEmailEnabled = config.email
  const isPhoneNumberEnabled = config.phoneNumber

  return (
    <View p={5} bg="sky.2" textAlign="left" borderRadius={1} {...rest}>
      <Form
        onSubmit={handleSubmit}
        mutators={{
          setFieldValue: (args, state, tools) => {
            const [field, value] = args
            tools.changeValue(state, field, () => value)
          },
        }}
        initialValues={{
          email: {
            value: initialValues?.email || '',
            send: !isMultiInputEnabled && isEmailEnabled,
          },
          phoneNumber: {
            value: initialValues?.phoneNumber || '',
            send: !isMultiInputEnabled && isPhoneNumberEnabled,
          },
        }}
        keepDirtyOnReinitialize
      >
        {({
          handleSubmit,
          submitting,
          validating,
          submitSucceeded,
          form: {
            mutators: { setFieldValue },
          },
          values,
        }) => {
          const emailValidator = (value: any, values: any) => {
            if (values.email?.send) {
              return required(value) || email(value)
            }
          }

          const phoneValidator = (value: any, values: any) => {
            if (values.phoneNumber?.send) {
              return required(value) || phone(value)
            }
          }

          return submitSucceeded ? (
            successScreen
          ) : (
            <form onSubmit={handleSubmit}>
              <Subheading color="ink.1" mb={4}>
                {label}
              </Subheading>
              {isEmailEnabled && (
                <Flex alignItems="flex-top">
                  <Field
                    name="email.value"
                    component={TextInput}
                    placeholder={t('E.g. john@email.co.uk')}
                    containerProps={{
                      width: '100%',
                      mb: 0,
                    }}
                    maxWidth="100%"
                    mt={0}
                    bg="sky.3"
                    validate={emailValidator}
                  />
                  {isMultiInputEnabled && (
                    <FormField
                      name="email.send"
                      component={Switch}
                      mt={2}
                      ml={4}
                    />
                  )}
                </Flex>
              )}
              {isPhoneNumberEnabled && (
                <Flex mt={4} alignItems="flex-top">
                  <Field
                    name="phoneNumber.value"
                    component={PhoneInput}
                    containerProps={{
                      width: '100%',
                      mb: 0,
                    }}
                    maxWidth="100%"
                    mt={0}
                    bg="sky.3"
                    validate={phoneValidator}
                  />
                  {isMultiInputEnabled && (
                    <FormField
                      name="phoneNumber.send"
                      component={Switch}
                      mt={2}
                      ml={4}
                    />
                  )}
                </Flex>
              )}
              <Button
                variant={buttonVariant}
                width="100%"
                loading={submitting}
                disabled={
                  validating ||
                  (!values.email?.send && !values.phoneNumber?.send)
                }
                mt={4}
              >
                {buttonText}
              </Button>
              <FormError />
              <FormSpy
                subscription={{
                  values: true,
                  initialValues: true,
                }}
                onChange={({ values, initialValues }) => {
                  if (!lastValues.current) {
                    if (
                      initialValues.email.value ||
                      initialValues.phoneNumber.value
                    ) {
                      // FormSpy was triggered while initialValues were being set
                      lastValues.current = values
                      return
                    }
                  }

                  if (
                    isMultiInputEnabled &&
                    lastValues.current?.email?.value !== values.email?.value
                  ) {
                    const valid = !email(values.email?.value)

                    if (!values.email?.send && valid) {
                      setFieldValue('email.send', true)
                    }

                    if (values.email?.send && !valid) {
                      setFieldValue('email.send', false)
                    }
                  }

                  if (
                    isMultiInputEnabled &&
                    lastValues.current?.phoneNumber?.value !==
                      values.phoneNumber?.value
                  ) {
                    const valid = !phone(values.phoneNumber?.value)

                    if (!values.phoneNumber?.send && valid) {
                      setFieldValue('phoneNumber.send', true)
                    }

                    if (values.phoneNumber?.send && !valid) {
                      setFieldValue('phoneNumber.send', false)
                    }
                  }

                  lastValues.current = values
                }}
              />
            </form>
          )
        }}
      </Form>
    </View>
  )
}

export default SendReceipt
