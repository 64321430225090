import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import { Payout } from '@tabeo/ts-types'

const subresources = formatSubresources(['payments', 'merchant'])

interface Params {
  searchQuery?: string
  limit?: number
  offset?: number
  conditions?: any
  orderBy?: {
    field: string
    direction: 'asc' | 'desc'
  }
}

type Data = {
  items: Payout[] | null
  totalCount: number
}

class Payouts extends Resource<Data, Params> {
  getInitialData() {
    return {
      items: null,
      totalCount: 0,
    }
  }

  getInitialConfig() {
    return {
      refreshInterval: 15 * 1000,
    }
  }

  async fetch() {
    const {
      searchQuery,
      limit = 100,
      offset = 0,
      conditions = [],
      orderBy: { field = 'transactionAt', direction = 'desc' } = {
        field: 'transactionAt',
        direction: 'desc',
      },
    } = this.params

    const searchParams = new URLSearchParams()
    if (conditions) {
      searchParams.append('jsonConditions', JSON.stringify(conditions))
    }

    if (searchQuery) {
      searchParams.append('searchPhrase', searchQuery)
    }

    searchParams.append('limit', limit.toString())
    searchParams.append('offset', offset.toString())
    searchParams.append('order', `${field}:${direction}`)

    const endpoint = searchQuery
      ? '/merchant/payout-search'
      : '/merchant/payouts'

    const {
      data: { payouts },
      totalCount,
    } = await api.get(
      `${endpoint}?${searchParams.toString()}&${subresources}`,
      {
        responseDataPath: 'data',
      }
    )
    this.data = {
      items: payouts,
      totalCount,
    }
  }
}

export default Payouts
export const usePayouts = createResourceHook(Payouts)
