import { Accordion, DL, Settings, Tooltip2, View } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getCategoriesBySector } from './constants'

const { Section, Stack } = Settings

function Treatments({ resource }) {
  const { data: merchant } = resource
  const { t } = useTranslation()

  const { merchantTreatments = [], sector } = merchant || {}

  const categories = [...getCategoriesBySector(sector), 'Other']

  const groupedTreatments = merchantTreatments.reduce(
    (acc, mt) => {
      const c = categories.find(c => c === mt.treatment.category) || 'Other'

      return {
        ...acc,
        [c]: [...acc[c], mt],
      }
    },
    categories.reduce((acc, c) => ({ ...acc, [c]: [] }), {})
  )

  const sortedGroups = Object.entries(groupedTreatments).sort(
    ([a], [b]) => categories.indexOf(a) - categories.indexOf(b)
  )

  return (
    <Section>
      <Section.Header flexWrap={['wrap', 'nowrap']}>
        <View>
          <Section.Title>{t('Treatments')}</Section.Title>
          <Section.Description>
            {t(
              'Manage the treatments that this Merchant can select in the New Transaction screen.'
            )}
          </Section.Description>
        </View>
      </Section.Header>
      <Stack overflow="hidden">
        {sortedGroups.map(
          ([category, merchantTreatments]) =>
            !!merchantTreatments.length && (
              <Stack.Item key={category} _first={{}} p={0}>
                <Accordion
                  title={category}
                  subtitle={t('{{count}} treatments', {
                    count: merchantTreatments.length,
                  })}
                  bg="sky.3"
                >
                  <DL mr="22px" my="-8px">
                    {merchantTreatments.map(mt => (
                      <DL.Item key={mt.id} py={5} _last={{ borderBottom: 0 }}>
                        <DL.DT color="ink.0">{mt.treatment.name}</DL.DT>
                        <DL.DD>
                          <DL.DD.Value color="ink.2" ml={4} flex="1">
                            {format(mt.defaultPrice)}
                          </DL.DD.Value>
                          <DL.DD.Actions justifyContent="flex-end">
                            <DL.DD.Action
                              onClick={() => resource.removeTreatment(mt.id)}
                              disabled={mt.treatment.category === 'NHS'}
                            >
                              <Tooltip2
                                title={
                                  mt.treatment.category === 'NHS'
                                    ? 'NHS treatments cannot be removed, please contact your Tabeo Account manager to remove this treatment.'
                                    : ''
                                }
                                placement="left-start"
                                maxWidth="360px"
                              >
                                <span>Remove</span>
                              </Tooltip2>
                            </DL.DD.Action>
                          </DL.DD.Actions>
                        </DL.DD>
                      </DL.Item>
                    ))}
                  </DL>
                </Accordion>
              </Stack.Item>
            )
        )}
      </Stack>
      <Section.Description mt={5}>
        {t(
          'If you need to modify an existing treatment or add a new one, please contact your Tabeo Account manager.'
        )}
      </Section.Description>
    </Section>
  )
}

Treatments.propTypes = {
  resource: PropTypes.object.isRequired,
}

export default Treatments
