import styled from 'styled-components'
import React from 'react'
import ScarfProps from '../../types/ScarfProps'
import View from '../View'

const StyledStack = styled(View)``
StyledStack.defaultProps = {
  bg: 'sky.2',
  border: 1,
  borderRadius: 1,
}

function Stack(props: ScarfProps) {
  return <StyledStack {...props} />
}

const Item = styled(View)``
Item.defaultProps = {
  p: 5,
  _notFirst: {
    borderTop: 1,
  },
  _first: {
    minHeight: '56px',
    height: '1px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    py: 0,
    pr: 3,
  },
}

Stack.Item = Item

export default Stack
