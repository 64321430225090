// @ts-nocheck

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { InputProp } from '../../../types/form'
import ScarfProps from '../../../types/ScarfProps'
import Flex from '../../Flex'
import {
  RadioItemInput,
  RadioItemLabel,
  RadioItemWrapper,
  ItemType,
  RenderProps,
} from './RadioItem'

export type RadioProps = ScarfProps & {
  disabled?: boolean
  input?: Partial<InputProp>
  items: ItemType[]
  defaultValue?: any
  renderItem?: (renderProps: RenderProps) => React.ReactNode
  horizontal?: boolean
}

class Radio extends Component<RadioProps> {
  state = {
    value: '',
    nextValue: null,
  }

  componentDidMount() {
    const {
      input: { value },
      defaultValue,
    } = this.props
    if (value || defaultValue) {
      this.setState({ value: value || defaultValue })
    }
  }

  handleChange = async (newValue) => {
    const {
      input: { onChange, value: propValue },
      disabled,
    } = this.props
    const { value: stateValue } = this.state
    const isControlled = propValue !== undefined
    if (isControlled && !disabled) {
      onChange(newValue)
    } else if (!disabled && stateValue !== newValue) {
      this.setState({
        nextValue: newValue,
      })
      try {
        await onChange(newValue)
        this.setState({ value: newValue, nextValue: null })
      } catch (e) {
        this.setState({ nextValue: null })
        throw e
      }
    }
  }

  handleKeyDown = (event, value) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      event.stopPropagation()
      this.handleChange(value)
    }
  }

  handleFocus = (index) => {
    this.setState({ focusIndex: index })
  }

  handleOnblur = () => {
    this.setState({ focusIndex: null })
  }

  render() {
    const {
      input: { value: propValue, name },
      items,
      renderItem,
      horizontal,
      disabled,
      ...rest
    } = this.props
    const { value: stateValue, focusIndex, nextValue } = this.state
    const isControlled = propValue !== undefined

    return (
      <Flex
        flexDirection={horizontal ? 'row' : 'column'}
        alignItems="flex-start"
        justifyContent="flex-start"
        {...rest}
      >
        {items.map((i, index) => {
          const value = isControlled ? propValue : stateValue
          const itemDisabled =
            disabled ||
            i?.disabled ||
            (nextValue !== null && i.value !== nextValue && i.value !== value)
          const renderProps = {
            item: {
              ...i,
              index,
            },
            onMouseDown: () => !itemDisabled && this.handleChange(i.value),
            onKeyDown: (event) =>
              !itemDisabled && this.handleKeyDown(event, i.value),
            onBlur: () => !itemDisabled && this.handleOnblur(index),
            onFocus: () => !itemDisabled && this.handleFocus(index),
            selected: i.value === value,
            focus: index === focusIndex,
            key: `${i.value}${index}`,
            disabled: itemDisabled,
            loading: i.value === nextValue,
            horizontal,
            name,
          }
          return renderItem ? (
            renderItem(renderProps)
          ) : (
            <RadioItemWrapper renderProps={renderProps} key={renderProps.key}>
              <RadioItemInput />
              <RadioItemLabel />
            </RadioItemWrapper>
          )
        })}
      </Flex>
    )
  }
}

Radio.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    onChange: PropTypes.func,
  }),
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func,
  horizontal: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
}

Radio.defaultProps = {
  input: {
    onChange: () => {},
  },
}

Radio.ItemInput = RadioItemInput
Radio.ItemLabel = RadioItemLabel
Radio.ItemWrapper = RadioItemWrapper

export default Radio as ((p: RadioProps) => JSX.Element) & {
  ItemInput: typeof RadioItemInput
  ItemLabel: typeof RadioItemLabel
  ItemWrapper: typeof RadioItemWrapper
}
