export const SENT_STATUSES = ['new']
export const OPENED_STATUSES = ['reviewed']
export const SCHEDULED_STATUSES = ['payment_source_assigned']
export const CARD_DECLINED_STATUSES = ['overdue']
export const FUNDS_ON_THE_WAY_STATUSES = [
  'paid',
  'merchant_proceeds_transferred',
]
export const SETTLED_STATUSES = ['merchant_payout_processed']
export const CANCELED_STATUSES = ['canceled']
export const EXPIRED_STATUSES = ['expired']

export const getCurrentState = status => {
  if (SENT_STATUSES.includes(status)) {
    return 'sent'
  }
  if (OPENED_STATUSES.includes(status)) {
    return 'opened'
  }
  if (SCHEDULED_STATUSES.includes(status)) {
    return 'scheduled'
  }
  if (CARD_DECLINED_STATUSES.includes(status)) {
    return 'card_declined'
  }
  if (FUNDS_ON_THE_WAY_STATUSES.includes(status)) {
    return 'funds_on_the_way'
  }
  if (SETTLED_STATUSES.includes(status)) {
    return 'settled'
  }
  if (CANCELED_STATUSES.includes(status)) {
    return 'canceled'
  }
  if (EXPIRED_STATUSES.includes(status)) {
    return 'expired'
  }
  return status
}

const normalize = bill => {
  const state = getCurrentState(bill.status)

  const pronouns = {
    consumerHeShe: bill.consumerGender === 'm' ? 'he' : 'she',
    consumerHisHer: bill.consumerGender === 'm' ? 'his' : 'her',
  }

  const netProceeds = bill.amount - bill.feeAmount

  return {
    ...bill,
    state,
    ...pronouns,
    netProceeds,
  }
}

export default normalize
