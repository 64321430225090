import { Button } from '@tabeo/scarf2'
import React from 'react'
import { Link } from 'react-router-dom'

export type SmartButtonProps = React.ComponentProps<typeof Button> & {
  href: string
}

function SmartButton({ href, ...rest }: SmartButtonProps) {
  if (href?.startsWith('http')) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        <Button {...rest} />
      </a>
    )
  }

  return (
    <Link to={href}>
      <Button {...rest} />
    </Link>
  )
}

export default SmartButton
