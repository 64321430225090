import { useState, useEffect } from 'react'
import theme from '../theme'

function matchQuery(query) {
  const mediaQueryList = window.matchMedia(query)

  return mediaQueryList.matches
}

function useMatchMedia(query) {
  const [match, setMatch] = useState(() => matchQuery(query))

  useEffect(() => {
    const onChange = () => {
      const result = matchQuery(query)

      if (match !== result) {
        setMatch(result)
      }
    }

    window.addEventListener('resize', onChange)
    return () => {
      window.removeEventListener('resize', onChange)
    }
  }, [query, match])

  return match
}

const mobileQuery = `(max-width: ${
  Number(theme.breakpoints[0].replace('px', '')) - 1
}px)`
export function useMobile() {
  return useMatchMedia(mobileQuery)
}

const desktopQuery = `(min-width: ${theme.breakpoints[0]})`
export function useDesktop() {
  return useMatchMedia(desktopQuery)
}

export default useMatchMedia
