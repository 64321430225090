import React from 'react'
import PropTypes from 'prop-types'

import {
  SimpleModal,
  Text,
  Prompt,
  Caption,
  Subheading,
  View,
} from '@tabeo/scarf'

import AutoCopyText from 'components/AutoCopyText'
import config from 'config'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const PlanListItem = styled(Prompt)`
  &:last-of-type {
    border-bottom: none;
  }
`

function ShareModal(props) {
  const { merchant, publicPlans, innerRef } = props
  const planPublicUrl = `${config.CONSUMER_APP_URL}/merchants/${merchant.slug}/plans`
  const { t } = useTranslation()
  return (
    <SimpleModal
      innerRef={innerRef}
      title={t('Share plans')}
      maxWidth={['95%', '620px']}
    >
      <Text mb={4}>
        {t(
          'Simply add this link as a button on your website to start marketing your plans online.'
        )}
      </Text>
      <Prompt mb={1}>{t('Shareable link')}</Prompt>
      <AutoCopyText value={planPublicUrl} mb={2} />
      <Caption mb={5}>
        {t('Anyone with the link will be able to sign up.')}
      </Caption>
      <Subheading color="ink.2" mb={1}>
        {t('Public plans')}
      </Subheading>
      <View bg="sky.2" borderRadius={1}>
        {publicPlans.map(p => (
          <PlanListItem key={p.id} py={3} px={5} borderBottom={1}>
            {p.title}
          </PlanListItem>
        ))}
      </View>
    </SimpleModal>
  )
}

ShareModal.propTypes = {
  merchant: PropTypes.object.isRequired,
  publicPlans: PropTypes.array,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
}

export default ShareModal
