import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Success from './Success'
import Fail from './Fail'

function StripeRouter() {
  return (
    <Switch>
      <Route path="/stripe/complete" component={Success} />
      <Route path="/stripe/interrupted" component={Fail} />
    </Switch>
  )
}

StripeRouter.propTypes = {}

export default StripeRouter
