import { HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'
import chungImg from './assets/avatar-Chung@2x.png'
import hanneImg from './assets/avatar-Hanne@2x.png'
import hayleyImg from './assets/avatar-Hayley@2x.png'
import jacImg from './assets/avatar-Jac@2x.png'
import kimImg from './assets/avatar-Kim@2x.png'
import michaelImg from './assets/avatar-Michael@2x.png'
import riyadImg from './assets/avatar-Riyad@2x.png'
import shaqImg from './assets/avatar-Shaq@2x.png'

const team = [
  { name: 'Chung', img: chungImg },
  { name: 'Hanne', img: hanneImg },
  { name: 'Hayley', img: hayleyImg },
  { name: 'Jac', img: jacImg },
  { name: 'Kim', img: kimImg },
  { name: 'Michael', img: michaelImg },
  { name: 'Riyad', img: riyadImg },
  { name: 'Shaq', img: shaqImg },
]

function Team({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={twMerge('grid grid-cols-4 gap-y-4 sm:grid-cols-8', className)}
      {...props}
    >
      {team.map(({ name, img }) => (
        <div
          key={name}
          className="-ml-3 flex flex-col items-center gap-2 sm:-ml-4"
        >
          <img
            src={img}
            alt={name}
            className="h-20 w-20 rounded-full bg-white"
          />
          <p className="caption text-tabeo-ink-2">{name}</p>
        </div>
      ))}
    </div>
  )
}

export default Team
