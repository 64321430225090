import { themeGet } from '@styled-system/theme-get'
import React from 'react'
import styled, { css } from 'styled-components'
import { InputProp } from '../../../types/form'
import ScarfProps from '../../../types/ScarfProps'
import Text, { BaseProps as TextBaseProps } from '../../Text'

export type BaseProps = {
  disabled?: boolean
  resize?: React.CSSProperties['resize']
}

const Base = styled(Text)<BaseProps>`
  cursor: text;
  position: relative;
  outline: none;
  overflow: auto;

  ${(p) =>
    p.disabled &&
    css`
      background-color: ${themeGet('colors.sky.3')};
      cursor: not-allowed;
    `}

  resize: ${(p) => p.resize};

  &::placeholder {
    color: ${themeGet('colors.ink.2')};
  }
  &:disabled {
    cursor: not-allowed;
    color: ${themeGet('colors.ink.2')};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  &:-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
`

Base.defaultProps = {
  as: 'textarea',
  bg: 'sky.2',
  borderWidth: '1px',
  borderColor: 'sky.2',
  borderStyle: 'solid',
  borderRadius: '3px',
  color: 'ink.1',
  minHeight: '150px',
  p: 2,
  fontWeight: 'regular',
  resize: 'none',
  _hover: {
    borderColor: 'sky.1',
  },
  _focus: {
    borderColor: 'sky.0',
  },
}

export type TextareaProps = ScarfProps<'textarea'> &
  TextBaseProps &
  BaseProps & {
    input?: Partial<InputProp>
  }

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ input, ...rest }, ref) => <Base {...input} {...rest} ref={ref} />
)

export default Textarea
