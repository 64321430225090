import { Box, Button, Prompt, Title } from '@tabeo/scarf'
import warningSvg from 'assets/warning.svg'
import Layout from 'components/Layout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function Suspended() {
  const { t } = useTranslation()
  return (
    <Layout bg={['white', 'sky.2']} maxWidth="460px" textAlign="center">
      <Box variant="content">
        <img src={warningSvg} className="mx-auto mb-2" />
        <Title mb={3}>{t('Account suspended')}</Title>
        <Prompt>
          {t(
            'You can’t create new transactions because your account has been temporarily suspended'
          )}
        </Prompt>
        <Button as={Link} to="/transactions" mt={5}>
          {t('Back to transactions')}
        </Button>
      </Box>
    </Layout>
  )
}

Suspended.propTypes = {}

export default Suspended
