import React from 'react'
import styled from 'styled-components'

import Svg, { SvgProps } from './Base'

const Popout = (props: SvgProps) => (
  <Svg {...props}>
    <g>
      <path d="M6.352 18.782h8.743a3.151 3.151 0 0 0 3.157-3.157v-5.343a.716.716 0 0 0-.729-.729.716.716 0 0 0-.728.729v5.343c0 .947-.754 1.7-1.7 1.7H6.352a1.69 1.69 0 0 1-1.7-1.7V6.882c0-.947.753-1.7 1.7-1.7h5.343a.716.716 0 0 0 .728-.729.716.716 0 0 0-.728-.728H6.352a3.151 3.151 0 0 0-3.157 3.157v8.743a3.151 3.151 0 0 0 3.157 3.157z" />
      <path d="M19.805 7.391V2.947c0-.049 0-.097-.024-.146 0-.025 0-.049-.025-.049 0-.024-.025-.048-.025-.073 0-.025-.024-.048-.048-.073 0-.025-.025-.048-.025-.048a.73.73 0 0 0-.194-.195l-.048-.024c-.048-.072-.096-.096-.145-.096l-.073-.025c-.048 0-.096-.025-.146-.025l-4.443.001a.716.716 0 0 0-.729.729c0 .413.316.729.729.729h2.696l-6.68 6.702c-.29.291-.29.753 0 1.02.292.29.754.29 1.02 0l6.703-6.679v2.696c0 .413.316.729.73.729.388 0 .704-.34.728-.729z" />
    </g>
  </Svg>
)

export default styled(Popout)``
