import React from 'react'
import styled from 'styled-components'
import Svg, { G, SvgProps } from './Base'

const Close = ({ fill, ...props }: SvgProps) => (
  <Svg {...props}>
    <G stroke={fill}>
      <path d="M14.536 7.464L7.464 14.536M14.536 14.536L7.464 7.464" />
    </G>
  </Svg>
)

export default styled(Close)``
