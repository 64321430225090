import { Feed, Panel, View } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import blueWaitingIcon from 'components/ActionPanel/assets/blue-waiting.svg'
import blueCardIcon from 'components/ActionPanel/assets/card_blue.svg'
import blueReadyToConfirmIcon from 'components/ActionPanel/assets/ready-to-confirm-blue.svg'
import canceledIcon from 'components/ActionPanel/assets/x-grey.svg'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getCurrentState } from '../../resources/normalizers/offer'
import applyingIcon from './ActionPanel/assets/applying.svg'
import approvedIcon from './ActionPanel/assets/approved.svg'
import openedIcon from './ActionPanel/assets/opened.svg'
import payoutOnTheWayIcon from './ActionPanel/assets/payout_on_the_way.svg'
import readyToConfirmIcon from './ActionPanel/assets/ready_to_confirm.svg'
import reviewIcon from './ActionPanel/assets/review.svg'
import sentIcon from './ActionPanel/assets/sent.svg'
import settledIcon from './ActionPanel/assets/settled.svg'
import transferredIcon from './ActionPanel/assets/transferred.svg'
import unsuccessfulIcon from './ActionPanel/assets/unsuccessful.svg'

const { DateCaption, TitleCaption: Caption } = Feed

const types = {
  SENT: 'sent',
  OPENED: 'opened',
  FUNDS_ON_THE_WAY: 'funds_on_the_way',
  SETTLED: 'settled',
  APPLYING: 'applying',
  APPLICATION_FAILED: 'application_failed',
  TRANSFERRED: 'transferred',
  APPROVED: 'approved',
  CANCELED: 'canceled',
  READY_TO_CONFIRM: 'ready_to_confirm',
  MANUAL_REVIEW_REQUIRED: 'manual_review_required',
  LOAN_STARTED: 'loan_started',
  PAYOUT_SCHEDULED: 'payout_scheduled',
  FULFILLMENT_REQUIRED: 'fulfillment_required',
  PAYOUT_CANCELED: 'payout_canceled',
}

function Title({ item, transaction }) {
  const { t } = useTranslation()
  switch (item.type) {
    case types.SENT:
      return (
        <Caption>
          {transaction.isPOT
            ? t(
                'Finance request created for {{consumerFirstName}} {{consumerLastName}}',
                {
                  consumerFirstName: transaction.consumerFirstName,
                  consumerLastName: transaction.consumerLastName,
                }
              )
            : t(
                'Payment request created for {{consumerFirstName}} {{consumerLastName}}',
                {
                  consumerFirstName: transaction.consumerFirstName,
                  consumerLastName: transaction.consumerLastName,
                }
              )}
        </Caption>
      )
    case types.OPENED:
      return (
        <Caption>
          {transaction.isPOT
            ? t('{{consumerFirstName}} opened the finance request email', {
                consumerFirstName: transaction.consumerFirstName,
              })
            : t('{{consumerFirstName}} opened the payment request email', {
                consumerFirstName: transaction.consumerFirstName,
              })}
        </Caption>
      )
    case types.FUNDS_ON_THE_WAY:
      if (transaction.isPOT) {
        return (
          <Caption>{t('A payout was issued to your bank account')}</Caption>
        )
      }

      return (
        <Caption>
          {t(
            'A payout of {{netProceedsAmount}} was issued to your bank account',
            {
              netProceedsAmount: format(transaction.netProceeds),
            }
          )}
        </Caption>
      )
    case types.SETTLED:
      if (transaction.isPOT) {
        return (
          <Caption>
            {t('The payout should have reached your bank account')}
          </Caption>
        )
      }

      return (
        <Caption>
          {t(
            'The payout of {{netProceedsAmount}} should have reached your bank account',
            {
              netProceedsAmount: format(transaction.netProceeds),
            }
          )}
        </Caption>
      )
    case types.APPLYING:
      return (
        <Caption>
          {t('{{consumerFirstName}} started the finance application', {
            consumerFirstName: transaction.consumerFirstName,
          })}
        </Caption>
      )
    case types.MANUAL_REVIEW_REQUIRED:
      return (
        <Caption>
          {t(
            '{{consumerFirstName}}’s application was sent into Manual Review',
            {
              consumerFirstName: transaction.consumerFirstName,
            }
          )}
        </Caption>
      )
    case types.APPLICATION_FAILED:
      return (
        <Caption>
          {t('{{consumerFirstName}}’s application was unsuccessful', {
            consumerFirstName: transaction.consumerFirstName,
          })}
        </Caption>
      )
    case types.TRANSFERRED:
      // eslint-disable-next-line no-case-declarations
      const hisHerText =
        transaction.consumerHisHer === 'his'
          ? t(
              '{{consumerFirstName}} transferred his application to a friend or family member',
              {
                consumerFirstName: transaction.consumerFirstName,
              }
            )
          : t(
              '{{consumerFirstName}} transferred her application to a friend or family member',
              {
                consumerFirstName: transaction.consumerFirstName,
              }
            )
      return <Caption>{hisHerText}</Caption>
    case types.APPROVED:
      return (
        <Caption>
          {t('{{consumerFirstName}}’s application was approved', {
            consumerFirstName: transaction.consumerFirstName,
          })}
        </Caption>
      )
    case types.CANCELED:
      return <Caption>{t('Transaction cancelled')}</Caption>
    case types.READY_TO_CONFIRM:
      return (
        <Caption>
          {t('{{consumerFirstName}}’s transaction was ready to confirm', {
            consumerFirstName: transaction.consumerFirstName,
          })}
        </Caption>
      )
    case types.LOAN_STARTED:
      return (
        <Caption>
          {t(
            '{{consumerFirstName}} made the first payment and started the loan',
            {
              consumerFirstName: transaction.consumerFirstName,
            }
          )}
        </Caption>
      )
    case types.PAYOUT_SCHEDULED:
      return <Caption>{t('Your payout was scheduled')}</Caption>
    case types.FULFILLMENT_REQUIRED:
      return <Caption>{t('Treatment details confirmation required')}</Caption>
    case types.PAYOUT_CANCELED:
      return <Caption>{t('Your payout was cancelled')}</Caption>
    default:
      return null
  }
}

Title.propTypes = {
  item: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
}

function Icon({ item, ...rest }) {
  let icon

  switch (item.type) {
    case types.SENT:
      icon = sentIcon
      break
    case types.OPENED:
      icon = openedIcon
      break
    case types.FUNDS_ON_THE_WAY:
      icon = payoutOnTheWayIcon
      break
    case types.SETTLED:
      icon = settledIcon
      break
    case types.APPLYING:
      icon = applyingIcon
      break
    case types.MANUAL_REVIEW_REQUIRED:
      icon = reviewIcon
      break
    case types.APPLICATION_FAILED:
      icon = unsuccessfulIcon
      break
    case types.TRANSFERRED:
      icon = transferredIcon
      break
    case types.APPROVED:
      icon = approvedIcon
      break
    case types.CANCELED:
      icon = canceledIcon
      break
    case types.READY_TO_CONFIRM:
      icon = readyToConfirmIcon
      break
    case types.LOAN_STARTED:
      icon = blueCardIcon
      break
    case types.PAYOUT_SCHEDULED:
      icon = blueWaitingIcon
      break
    case types.FULFILLMENT_REQUIRED:
      icon = blueReadyToConfirmIcon
      break
    case types.PAYOUT_CANCELED:
      icon = canceledIcon
      break
    default:
      return null
  }

  return <View as="img" src={icon} {...rest} />
}

Icon.propTypes = {
  item: PropTypes.object.isRequired,
}

function ActivityFeed({ data, ...rest }) {
  const sent = {
    id: 'sent',
    type: 'sent',
    date: data.createdAt,
    data: {},
  }
  const { t } = useTranslation()

  const activityMap = data.stateMachineTransitions.reduce((acc, s) => {
    const normalizedState = getCurrentState({ ...data, status: s.to })

    if (Object.values(types).includes(normalizedState)) {
      return {
        ...acc,
        [normalizedState]: {
          id: s.uuid,
          type: normalizedState,
          date: s.at,
          data: {
            ...s,
          },
        },
      }
    }

    return acc
  }, {})

  const history = [sent, ...Object.values(activityMap)].sort((a, b) =>
    a.date < b.date ? 1 : -1
  )

  return (
    <Panel title={t('Activity')} data-testid="activity-feed" {...rest}>
      <Feed
        data-testid="activity-log"
        history={history}
        renderIcon={h => <Icon item={h} width="24px" height="24px" />}
        renderTitle={h => <Title item={h} transaction={data} />}
        renderDate={h => (
          <DateCaption>
            {moment(h.date).format('DD MMM YYYY, hh:mm a')}
          </DateCaption>
        )}
      />
    </Panel>
  )
}

ActivityFeed.propTypes = {
  data: PropTypes.object,
}

export default ActivityFeed
