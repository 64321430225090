import { plansorchestrator } from '@tabeo/platform-api-client'
import { Accordion, Panel } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import { useTranslation } from 'react-i18next'

type DocumentsProps = {
  data?: plansorchestrator.ModelsPlan
}

function Documents({ data }: DocumentsProps) {
  const { t } = useTranslation()

  return (
    <Panel title={t('Documents')}>
      <Accordion title={t('Plan contract')} subtitle={data?.name}>
        <Button size="sm">{t('Download')}</Button>
      </Accordion>
    </Panel>
  )
}

export default Documents
