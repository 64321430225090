// `atob` and `btoa` are deprecated we should use `Buffer` instead
// We need to add `buffer` to fix: Uncaught ReferenceError: Buffer is not defined for CRA
// https://github.com/WalletConnect/walletconnect-monorepo/issues/748
import { Buffer } from 'buffer'

export function encodeToBase64string(value: any) {
  return Buffer.from(JSON.stringify(value)).toString('base64')
}

export function decodeFromBase64string(value: string) {
  try {
    return JSON.parse(Buffer.from(value, 'base64').toString('utf-8'))
  } catch (error) {
    return undefined
  }
}
