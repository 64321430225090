import React from 'react'
import styled from 'styled-components'

import Svg, { SvgProps } from './Base'

const Minus = ({ ...rest }: SvgProps) => (
  <Svg viewBox="0 0 24 24" {...rest}>
    <g>
      <path
        id="a"
        d="M6.167 10.833h11.666a1.167 1.167 0 0 1 0 2.334H6.167a1.167 1.167 0 0 1 0-2.334z"
      />
    </g>
  </Svg>
)

export default styled(Minus)``
