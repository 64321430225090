import {
  Button,
  Caption,
  Icons,
  Prompt,
  Settings,
  Title,
  View,
} from '@tabeo/scarf'
import ConfirmModal from 'components/ConfirmModal'
import usePromise from 'hooks/usePromise'
import InviteModal from 'pages/Settings/People/Users/InviteModal'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import UserItem from './UserItem'

const { Section, Stack } = Settings

function Users({ merchantUsersResource, ...rest }) {
  const { t } = useTranslation()

  const { data: merchantUsers, remove, invite } = merchantUsersResource
  const [triggerUserRemove, { pending }] = usePromise(remove)

  const inviteModal = useRef()
  const confirmModal = useRef()

  return (
    <Section {...rest}>
      <InviteModal onSubmit={invite} innerRef={inviteModal} />
      <ConfirmModal
        innerRef={confirmModal}
        confirmLabel={t('Remove user')}
        isConfirmPending={pending}
        textAlign="center"
      >
        <Title mb={4}>{t('Remove user')}</Title>
        <Prompt mb={6}>
          <Trans>
            Are you sure you want to remove this user?
            <br />
            This action cannot be reversed.
          </Trans>
        </Prompt>
      </ConfirmModal>
      <Section.Header flexWrap={['wrap', 'nowrap']}>
        <View>
          <Section.Title>{t('Users')}</Section.Title>
          <Section.Description>
            {t(
              'These are the people that can log in and perform actions on the Merchant App.'
            )}
          </Section.Description>
        </View>
        <Button
          variant="secondary"
          leftIcon={p => <Icons.Plus light {...p} />}
          mt={[5, 0]}
          onClick={() => inviteModal.current.open()}
          sizeVariant="sm"
          ug-id="invite-user-button"
        >
          {t('Invite user')}
        </Button>
      </Section.Header>
      <Stack overflow="hidden">
        {merchantUsers?.length ? (
          merchantUsers?.map(mu => (
            <UserItem
              key={mu.id}
              data={mu}
              onRemove={() =>
                confirmModal.current.open({
                  onConfirm: async () => {
                    await triggerUserRemove(mu.id)
                    confirmModal.current.close()
                  },
                })
              }
            />
          ))
        ) : (
          <Stack.Item>
            <Caption textAlign="center">
              {t('There are no clinicians yet')}
            </Caption>
          </Stack.Item>
        )}
      </Stack>
    </Section>
  )
}

Users.propTypes = {
  merchantUsersResource: PropTypes.object.isRequired,
}

export default Users
