import React from 'react'
import useIntersection from '../hooks/useIntersection'
import ScarfProps from '../types/ScarfProps'
import AnimateHeight from './AnimateHeight'
import Flex from './Flex'
import ArrowRight from './Icons/ArrowRight'
import { SvgProps } from './Icons/Base'
import Tag, { WrapProps } from './Tag'
import { Caption } from './Text'
import View from './View'

export type AccordionProps = ScarfProps & {
  title?: string
  subtitle?: string
  status?: string
  statusColor?: WrapProps['color']
  isOpenByDefault?: boolean
  isTight?: boolean
  header?:
    | React.ReactNode
    | (({
        toggle,
        isOpen,
      }: {
        toggle: () => void
        isOpen: boolean
      }) => React.ReactNode)
  iconActiveColor?: SvgProps['fill']
  children?: React.ReactNode
}

function Accordion({
  title,
  subtitle,
  status,
  statusColor,
  children,
  isOpenByDefault = false,
  isTight = false,
  iconActiveColor = 'ink.2',
  header,
  ...rest
}: AccordionProps) {
  const [isOpen, setOpen] = React.useState(isOpenByDefault)
  const [ref, isVisible] = useIntersection({ once: true })

  const height: string | number = isOpen ? 'auto' : 0

  return (
    <Flex
      ref={ref}
      pt={!isTight ? 3 : undefined}
      pr={!isTight ? [3, 5] : undefined}
      bg="white"
      alignItems="flex-start"
      data-testid="accordion-panel"
      {...rest}
    >
      <View>
        <View
          transform={isOpen ? `rotate(90deg)` : `rotate(0deg)`}
          transition="transform 200ms linear"
          ml={!isTight ? [1, 3] : undefined}
          mr={[1, 2]}
          onClick={() => setOpen(!isOpen)}
          cursor="pointer"
        >
          <ArrowRight fill={isOpen ? iconActiveColor : 'ink.2'} />
        </View>
      </View>
      <View flex="1" maxWidth="calc(100% - 26px)">
        {header ? (
          typeof header === 'function' ? (
            header({ toggle: () => setOpen(!isOpen), isOpen })
          ) : (
            header
          )
        ) : (
          <View mb={!isTight ? 3 : undefined}>
            <Flex
              justifyContent="flex-start"
              alignItems="flex-start"
              flexWrap="wrap"
            >
              {title ? (
                <Caption
                  fontWeight="semibold"
                  color="ink.0"
                  mr={2}
                  onClick={() => setOpen(!isOpen)}
                  cursor="pointer"
                  data-testid="accordion-title"
                >
                  {title}
                </Caption>
              ) : null}
              {status ? (
                <Tag small color={statusColor}>
                  {status}
                </Tag>
              ) : null}
            </Flex>
            {subtitle ? <Caption color="ink.2">{subtitle}</Caption> : null}
          </View>
        )}
        {(isOpen || isVisible) && (
          <AnimateHeight duration={200} height={height}>
            <View pt={!isTight ? 2 : undefined} pb={!isTight ? 5 : undefined}>
              {children}
            </View>
          </AnimateHeight>
        )}
      </View>
    </Flex>
  )
}

export default Accordion
