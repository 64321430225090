import { Button, Caption, Flex, Heading, View } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import FieldError from 'components/Form/FieldError'
import Card from 'components/Transaction/ActionPanel/Card'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import icon from './assets/card_declined.svg'

function CardDeclined({
  data,
  cancelModal,
  chargeModal,
  cancelState,
  chargeState,
  ...rest
}) {
  const { t } = useTranslation()
  const {
    amount,
    consumerFirstName,
    paymentSource: { lastFourDigits },
  } = data

  const hideError =
    chargeState.error?.response.status === 'charge_failed' &&
    chargeState.error?.billState === 'scheduled'

  const errorMessage =
    chargeState.error?.message === t('Charging bill failed.')
      ? t(`The customer’s card was declined.`)
      : t('There was an error, please try again later.')

  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Card declined')}</Heading>
      </Flex>
      <Caption color="ink.1" mb={4}>
        {t(
          'We weren’t able to take the payment of {{amount}} from {{consumerFirstName}}’s account ending in {{lastFourDigits}}.',
          { amount: format(amount), consumerFirstName, lastFourDigits }
        )}
      </Caption>
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="flex-start"
        alignItems={['stretch', 'center']}
      >
        <Button
          variant="secondary"
          sizeVariant="sm"
          mr={[0, 4]}
          mb={[4, 0]}
          onClick={() => chargeModal.current.open()}
        >
          {t('Charge now')}
        </Button>

        <Button
          variant="secondary"
          sizeVariant="sm"
          color="ink.2"
          onClick={() => cancelModal.current.open()}
        >
          {t('Cancel transaction')}
        </Button>
      </Flex>
      {!hideError && (cancelState.error || chargeState.error) && (
        <FieldError message={errorMessage} mt={4} />
      )}
    </Card>
  )
}

CardDeclined.propTypes = {
  data: PropTypes.object.isRequired,
  cancelState: PropTypes.object.isRequired,
  chargeState: PropTypes.object.isRequired,
  cancelModal: PropTypes.object.isRequired,
  chargeModal: PropTypes.object.isRequired,
}

export default CardDeclined
