import { Modal } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import CancelModal from './CancelModal'

export default function CancelButton() {
  const { t } = useTranslation()
  const modal = useRef<Modal>()

  return (
    <>
      <Button
        variant="secondary"
        size="sm"
        onClick={() => {
          modal.current?.open()
        }}
      >
        {t('Cancel membership')}
      </Button>
      <CancelModal ref={modal} />
    </>
  )
}
