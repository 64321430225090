import css from '@styled-system/css'
import { styledSystemProps } from './CleanTag/scarfProps'

export const omit = (obj, keys) => {
  const next = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-continue
    if (!keys.includes(key) && key[0] !== '&') continue
    next[key] = obj[key]
  }
  return next
}

const variants =
  (variants = {}, defaultProps = {}) =>
  (props) => {
    const variantKeys = Object.keys(variants)
    const variantProps = variantKeys.reduce(
      (mem, key) => ({
        ...mem,
        ...(variants[key][props[key]] || {}),
      }),
      {}
    )
    const nonDefaultProps = Object.keys(props).reduce((mem, propKey) => {
      if (
        defaultProps[propKey] !== props[propKey] &&
        Object.keys(variantProps).includes(propKey)
      ) {
        return {
          ...mem,
          [propKey]: props[propKey],
        }
      }
      return mem
    }, {})
    const merged = {
      ...variantProps,
      ...nonDefaultProps,
    }
    const cleaned = omit(merged, styledSystemProps)
    return css(cleaned)
  }

export default variants
