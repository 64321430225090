// @ts-nocheck

import get from 'lodash.get'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { InputProp } from '../../types/form'
import ScarfProps from '../../types/ScarfProps'
import Button, { WrapProps } from '../Button'
import Flex from '../Flex'
import { Spinner, Tick, X } from '../Icons'
import Text from '../Text'
import View from '../View'

const HtmlFileInput = styled(View)`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  & + label {
    display: inline-block;
  }
`

HtmlFileInput.defaultProps = { as: 'input', type: 'file' }

const Name = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

Name.defaultProps = {
  fontSize: ['16px', '14px'],
  color: 'default.primary',
  flex: '1 1 auto',
  fontWeight: 'regular',
  pl: 3,
}

const Wrap = styled(Flex)`
  overflow: hidden;
  background-image: linear-gradient(to bottom, #f9fafb, #f4f6f8);
`

const FilePreview = ({ name, onRemove, loading, uploaded, file, ...rest }) => {
  const previewLink = URL.createObjectURL(file)
  const previewLinkProps = previewLink
    ? {
        as: 'a',
        href: previewLink,
        target: '_blank',
        cursor: 'pointer',
        _hover: {
          textDecoration: 'underline',
        },
      }
    : {}
  return (
    <Wrap
      display="inline-flex"
      border={1}
      borderColor="#c4cdd5"
      borderRadius={1}
      boxShadow={2}
      height={['53px', '42px']}
      maxWidth={['auto', '225px']}
      width={['100%', 'auto']}
      {...rest}
    >
      <View borderRight={1} bg="white" flex="0 0 auto" p={2}>
        <View
          as="svg"
          width={['42px', '31px']}
          height={['30px', '22px']}
          viewBox="0 0 31 22"
        >
          <path
            fill="#C4CDD5"
            fillRule="nonzero"
            d="M2.054 1.119a.933.933 0 0 0-.934.932v17.898c0 .515.419.932.934.932h26.892a.933.933 0 0 0 .934-.932V2.051a.933.933 0 0 0-.934-.932H2.054zm0-1.119h26.892C30.08 0 31 .918 31 2.05v17.9c0 1.132-.92 2.05-2.054 2.05H2.054A2.053 2.053 0 0 1 0 19.95V2.05C0 .919.92 0 2.054 0zm15.8 14.316l4.075-2.745a1.309 1.309 0 0 1 1.46-.001l7.363 4.94-.625.93-7.363-4.942a.187.187 0 0 0-.209 0l-4.723 3.182-6.896-4.963a.187.187 0 0 0-.206-.007L.853 16.767l-.586-.953 9.876-6.057a1.309 1.309 0 0 1 1.449.053l6.261 4.506zm.634-4.994a2.799 2.799 0 0 1-2.801-2.797 2.799 2.799 0 0 1 2.801-2.796 2.799 2.799 0 0 1 2.801 2.796 2.799 2.799 0 0 1-2.801 2.797zm0-1.119a1.68 1.68 0 0 0 1.68-1.678 1.68 1.68 0 0 0-1.68-1.678 1.68 1.68 0 0 0-1.68 1.678 1.68 1.68 0 0 0 1.68 1.678z"
          />
        </View>
      </View>
      <Name {...previewLinkProps}>{name}</Name>
      {uploaded ? (
        <Tick fill="primary.3" width="22px" height="22px" mx={2} light />
      ) : loading ? (
        <Spinner fill="primary.3" width="22px" height="22px" mx={2} spinning />
      ) : onRemove ? (
        <View
          flex="0 0 auto"
          onClick={(e) => {
            e.stopPropagation()
            onRemove(e)
          }}
          height="22px"
          cursor="pointer"
        >
          <X fill="ink.2" width="22px" height="22px" mx={2} />
        </View>
      ) : null}
    </Wrap>
  )
}

FilePreview.propTypes = {
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  uploaded: PropTypes.bool,
  file: PropTypes.object,
}

export type FileInputProps = ScarfProps & {
  input: Partial<InputProp>
  accept?: string
  variant?: WrapProps['variant']
  buttonLabel?: string
  uploaded?: boolean
  name?: string
}

class FileInput extends React.Component<FileInputProps> {
  state = {
    file: null,
  }

  constructor() {
    super()
    this.id = `${new Date().getTime() + Math.floor(Math.random() * 100)}`
  }

  componentDidMount() {
    const {
      input: { value },
    } = this.props
    if (value) {
      this.setState({
        file: value,
      })
    }
  }

  handleFileSelect = (event) => {
    const file = get(event, 'target.files.0', null)
    if (file) {
      this.handleStateChange({
        file,
      })
    }
  }

  handleClearFile = () => {
    this.handleStateChange({
      file: null,
    })
  }

  handleStateChange = (newState) => {
    const { input } = this.props

    this.setState(newState, () => {
      const { onChange } = input || {}
      const { file } = this.state
      if (onChange) {
        onChange(file)
      }
    })
  }

  render() {
    const {
      accept = '.png, .jpg, .jpeg',
      buttonLabel,
      input,
      uploaded,
      variant = 'secondary',
      t,
      ...rest
    } = this.props
    const { file } = this.state

    return file ? (
      <FilePreview
        name={file.name}
        file={file}
        onRemove={this.handleClearFile}
        uploaded={uploaded}
        {...rest}
      />
    ) : (
      <View display="inline-block" width={['100%', 'auto']} {...rest}>
        <HtmlFileInput
          id={`file-${this.id}`}
          accept={accept}
          onChange={this.handleFileSelect}
        />
        <Button
          as="label"
          htmlFor={`file-${this.id}`}
          variant={variant}
          width="100%"
        >
          {buttonLabel || t('Choose file')}
        </Button>
      </View>
    )
  }
}

FileInput.propTypes = {
  accept: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  buttonLabel: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.any,
  }),
  uploaded: PropTypes.bool,
  t: PropTypes.func,
}

export default withTranslation()(
  FileInput
) as React.ComponentType<FileInputProps>
