import { Button, Modal, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

function ConfirmModal(props) {
  const { t } = useTranslation()
  const {
    innerRef,
    isConfirmPending,
    confirmLabel = t('Confirm'),
    cancelLabel = t('Cancel'),
    children,
    ...rest
  } = props
  return (
    <Modal ref={innerRef}>
      {({ isOpen, close, data: { onConfirm } = {} }) => {
        return isOpen ? (
          <View
            maxWidth={['90%', '460px']}
            mx="auto"
            bg="white"
            borderRadius={1}
            boxShadow={1}
            p={[5, 10]}
            {...rest}
          >
            {children}
            <Button
              loading={isConfirmPending}
              onClick={onConfirm}
              width="100%"
              mb={5}
              variant="secondary"
            >
              {confirmLabel}
            </Button>
            <Button type="button" width="100%" onClick={close}>
              {cancelLabel}
            </Button>
          </View>
        ) : null
      }}
    </Modal>
  )
}

ConfirmModal.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  isConfirmPending: PropTypes.bool,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  children: PropTypes.any,
}

export default ConfirmModal
