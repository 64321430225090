import React from 'react'
import styled from 'styled-components'
import variants from '../../../../utils/variants'
import TextareaAutosize, {
  TextareaAutosizeProps,
} from '../../../Form/Textarea/TextareaAutosize'

export type BaseProps = {
  variant?: 'filled' | 'transparent'
}

const Base = styled(TextareaAutosize)<BaseProps>`
  ${variants({
    variant: {
      filled: {},
      transparent: {
        '&:not(:focus):not(:hover):not(:placeholder-shown)': {
          bg: 'white',
          borderColor: 'transparent',
        },
      },
    },
  })}
`

Base.defaultProps = {
  minRows: 2,
  maxRows: Infinity,
  width: '100%',
  fontSize: ['16px', '16px'],
  placeholder: 'Enter a description...',
  variant: 'transparent',
}

export type DescriptionProps = TextareaAutosizeProps & BaseProps

function Description({ ...rest }) {
  return <Base {...rest} />
}

export default Description
