import {
  AddressSelect,
  Button,
  DateInput,
  Radio,
  Select,
  TextInput,
} from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import { date, name, required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import useCountry from 'hooks/useCountry'
import PropTypes from 'prop-types'
import React from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import SoftCheck from './SoftCheck'

const ageValidator = date(({ ellapsedYears }) =>
  ellapsedYears < 18 || ellapsedYears > 99 ? 'Invalid date' : undefined
)

function Update({ onSubmit, officers }) {
  const [currentOfficerId, setOfficerId] = React.useState(null)
  const { t } = useTranslation()
  function handleFormChange({ active, values }) {
    if (active === 'id') {
      setOfficerId(values.id)
    }
  }
  async function handleSubmit({ addressJSON, ...values }) {
    try {
      const payload = {
        officers: [
          {
            ...values,
            addressJSON: JSON.parse(addressJSON),
          },
        ],
      }
      await onSubmit(payload)
    } catch (e) {
      return formError(e)
    }
  }
  const selectedOfficer = officers.find(o => o.id === currentOfficerId) || {}
  const initialValues = {
    firstName: selectedOfficer.firstName,
    lastName: selectedOfficer.lastName,
    id: currentOfficerId,
  }

  const country = useCountry()

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting, values: { id } }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy onChange={handleFormChange} />
          <Field
            name="id"
            validate={required}
            component={Select}
            options={officers.map(o => ({
              label: `${o.firstName} ${o.lastName}`,
              value: o.id,
            }))}
          />
          {id && (
            <>
              <Field
                name="gender"
                label={t('Title')}
                validate={required}
                component={Radio}
                horizontal
                items={[
                  { label: t('Mr'), value: 'm' },
                  { label: t('Ms'), value: 'f' },
                ]}
              />
              <Field
                name="firstName"
                label={t('First name')}
                placeholder={t('e.g. John')}
                validate={[required, name]}
                component={TextInput}
              />

              <Field
                name="lastName"
                label={t('Last name')}
                placeholder={t('e.g. Smith')}
                validate={[required, name]}
                component={TextInput}
              />

              <Field
                data-no-track
                name="birthdate"
                label={t('Birth date')}
                type="tel"
                component={DateInput}
                validate={[required, ageValidator]}
              />

              <Field
                data-no-track
                name="addressJSON"
                label={t('Home address')}
                validate={[required]}
                component={AddressSelect}
                // override countryCode to allow directos to enter any address
                {...(country?.isEU ? { countryCode: '' } : {})}
              />
              <SoftCheck my={5} />
              <Button loading={submitting}>{t('Next')}</Button>
              <FormError />
            </>
          )}
        </form>
      )}
    </Form>
  )
}

Update.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  officers: PropTypes.array.isRequired,
}

export default Update
