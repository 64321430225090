import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Circle = ({
  color = '#c4cdd5',
  width = '22',
  height = '22',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 22 22" {...rest}>
    <g fill={color}>
      <circle cx="11" cy="11" r="5" />
    </g>
  </svg>
)

Circle.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default styled(Circle)``
