import React, { useContext } from 'react'
import * as Icons from '../../../Icons'
import { TickProps as IconTickProps } from '../../../Icons/Tick'
import EntityContext from '../EntityContext'
import { Merge } from '../../../../types/helpers'
import ScarfProps from '../../../../types/ScarfProps'

export type TickProps = Merge<IconTickProps, Pick<ScarfProps, 'as'>>

function Tick({ ...rest }: TickProps) {
  const { hover, selected, color } = useContext(EntityContext)

  if (!selected) {
    return null
  }

  return <Icons.Tick light fill={hover ? 'white' : color} ml={3} {...rest} />
}

export default Tick
