import { Button, Modal, usePromise } from '@tabeo/scarf'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as XRed } from 'components/checkout-payment/assets/x-red.svg'
import LinkPaymentModal from 'components/LinkPayment/Modal/Modal'
import moment from 'moment'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLinkPayment } from 'resources/LinkPayment'

function Failed() {
  const { t } = useTranslation()

  const { data, resource } = useLinkPayment()

  const chargeFailedAt = data?.charges
    ?.filter(({ status }) => status === 'failed')
    // @ts-ignore
    .at(-1)?.created_at

  const [triggerCancel, cancelState] = usePromise(
    resource?.cancel || (() => {})
  )

  const modal = useRef<Modal>()

  return (
    <>
      <LinkPaymentModal ref={modal} isReminder />
      <ActionCard data-testid="failed-action-panel">
        <ActionCard.Header>
          <XRed className="h-6 w-6" />
          {t('Card declined')}
        </ActionCard.Header>
        <ActionCard.Body>
          {t(
            'A payment was attempted {{elapsedTime}} but the card was declined.',
            { elapsedTime: moment().to(chargeFailedAt) }
          )}
        </ActionCard.Body>
        <ActionCard.Actions>
          <Button
            sizeVariant="sm"
            variant="secondary"
            onClick={() => modal.current?.open({ id: data?.id })}
          >
            {t('Send reminder')}
          </Button>
          <Button
            sizeVariant="sm"
            variant="secondary"
            onClick={triggerCancel}
            loading={cancelState.pending}
          >
            {t('Cancel')}
          </Button>
        </ActionCard.Actions>
        {cancelState.error && (
          <ActionCard.Error>
            {t('There was an error, please try again later.')}
          </ActionCard.Error>
        )}
      </ActionCard>
    </>
  )
}

export default Failed
