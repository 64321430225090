import { useEffect, useState } from 'react'

const config = {
  screens: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },
}

export type Breakpoints = Record<keyof (typeof config)['screens'], boolean>

function useBreakpoints(): Breakpoints {
  const [breakpoints, setBreakpoints] = useState<Breakpoints>(
    Object.fromEntries(
      Object.entries(config.screens).map(([key, value]) => {
        const mediaQuery = window.matchMedia(`(min-width: ${value})`)

        return [key, mediaQuery.matches]
      })
    ) as Breakpoints
  )

  useEffect(() => {
    const mediaQueries = Object.entries(config.screens).map(([key, value]) => {
      const mediaQuery = window.matchMedia(`(min-width: ${value})`)

      const listener = () => {
        setBreakpoints(prev => ({ ...prev, [key]: mediaQuery.matches }))
      }

      mediaQuery.addEventListener('change', listener)

      return () => mediaQuery.removeEventListener('change', listener)
    })

    return () => {
      mediaQueries.forEach(unsubscribe => unsubscribe())
    }
  }, [])

  return breakpoints
}

export default useBreakpoints
