import normalizeTransaction from './offer'

const WON_STATUSES = ['succeeded']
const LOST_STATUSES = ['failed', 'disqualified']
const QUALIFIED_STATUSES = ['qualified']

const questionTypes = {
  single_choice: 'singleChoiceAnswer',
  multiple_choice: 'multipleChoiceAnswer',
  date: 'dateAnswer',
  boolean: 'booleanAnswer',
  free_text: 'freeTextAnswer',
}

const getState = ({ status, appointment }) => {
  if (WON_STATUSES.includes(status)) {
    return 'won'
  }
  if (LOST_STATUSES.includes(status)) {
    return 'lost'
  }
  if (appointment) {
    const now = new Date().getTime()
    const appointmentDate = new Date(appointment.scheduledAt).getTime()
    if (now < appointmentDate) {
      return 'consultation'
    }
    return 'treatment'
  }
  if (QUALIFIED_STATUSES.includes(status)) {
    return 'qualified'
  }

  return 'new'
}

const normalize = ({
  offers = [],
  productAnswers = [],
  appointment = {},
  ...rest
}) => {
  // Parse product answer values
  const productAnswersMap = productAnswers.reduce((mem, item) => {
    if (item.type === 'multiple_choice') {
      mem[item.id] = {
        value: item[questionTypes[item.type]].Values,
        freeTextValue: item[questionTypes[item.type]].value,
      }
    } else {
      mem[item.id] = (item[questionTypes[item.type]] || {}).value
    }
    return mem
  }, {})

  const lead = {
    ...rest,
    offers: offers.map(o => normalizeTransaction(o)),
    appointment: appointment.uuid ? appointment : null,
    productAnswers: productAnswersMap,
  }

  const state = getState(lead)

  return {
    ...lead,
    state,
  }
}

export default normalize
