import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { View, Flex, Icons, themeGet } from '@tabeo/scarf'
import styled from 'styled-components'

const Input = styled(View)`
  outline: none;
  font-size: 16px;
  font-variant-numeric: tabular-nums;
  text-align: center;
  border: 1px solid ${themeGet('colors.sky.0')};
`

Input.defaultProps = {
  as: 'input',
  bg: 'sky.2',
  borderRadius: 1,
  py: 2,
  px: 3,
  maxWidth: '44px',
}

const parse = value => parseInt(value, 10) || 0
const iconProps = {
  tabIndex: '-1',
  light: true,
  style: {
    userSelect: 'none',
    outline: 'none',
  },
}

class NumberInput extends Component {
  handleIncrease = () => {
    const {
      input: { onChange, value },
      maxValue,
    } = this.props
    const parsedValue = parse(value)
    const newValue = maxValue
      ? Math.min(maxValue, parsedValue + 1)
      : parsedValue + 1
    onChange(newValue)
  }

  handleDecrease = () => {
    const {
      input: { onChange, value },
      minValue,
    } = this.props
    const parsedValue = parse(value)
    const newValue =
      minValue || minValue === 0
        ? Math.max(minValue, parsedValue - 1)
        : parsedValue - 1
    onChange(newValue)
  }

  render() {
    const {
      input: { value },
      minValue,
      maxValue,
      ...rest
    } = this.props
    const parsedValue = parse(value)
    const canIncrease = maxValue ? maxValue >= parsedValue + 1 : true
    const canDecrease =
      minValue || minValue === 0 ? minValue <= parsedValue - 1 : true
    return (
      <Flex justifyContent="flex-start" {...rest}>
        <Icons.Minus
          onClick={this.handleDecrease}
          fill={!canDecrease ? 'ink.2' : 'default.primary'}
          cursor={!canDecrease ? 'not-allowed' : 'pointer'}
          {...iconProps}
        />
        <Input value={parsedValue} readOnly mx={1} />
        <Icons.Plus
          onClick={this.handleIncrease}
          fill={!canIncrease ? 'ink.2' : 'default.primary'}
          cursor={!canIncrease ? 'not-allowed' : 'pointer'}
          {...iconProps}
        />
      </Flex>
    )
  }
}

NumberInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
}

export default NumberInput
