import { useScript } from 'components/Script'
import useStyle from './useStyle'

function useCalendy(url) {
  const { loaded: styleLoaded, error: styleError } = useStyle(
    'https://assets.calendly.com/assets/external/widget.css'
  )
  const { loaded: scriptLoaded, error: scriptError } = useScript(
    'https://assets.calendly.com/assets/external/widget.js'
  )
  function handleOpen() {
    window.Calendly.initPopupWidget({
      url,
    })
  }

  return {
    isReady: styleLoaded && scriptLoaded,
    error: styleError || scriptError,
    open: handleOpen,
  }
}

export default useCalendy
