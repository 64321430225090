import { getAvailableStep, getFlow } from 'flows'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'
import useFocus from './useFocus'

export const FlowContext = createContext()

const useFlow = (pContext, config) => {
  const uContext = useContext(FlowContext)
  const context = pContext || uContext

  const history = useHistory()
  const match = useRouteMatch()

  const freshContext = useRef(context)
  freshContext.current = context

  const mounted = useRef()

  const next = useCallback(() => {
    const ctx = freshContext.current
    const flow = getFlow(ctx)

    const { nextStep } = flow[match.path]

    const link = nextStep?.(ctx)
    if (link) {
      const { pathname, query } = link
      return history.push(generatePath(pathname, query))
    }

    const autoRedirectLink = getAvailableStep(ctx)

    if (autoRedirectLink === '/404') {
      throw new Error(
        `Invalid flow for context: ${JSON.stringify(ctx, null, 2)}`
      )
    }

    return history.push(autoRedirectLink)
  }, [history, match])

  const loading = !!Object.values(context).filter(
    c => c === undefined || c === null
  ).length

  const flow = loading ? {} : getFlow(context)

  const { isAvailable, priority, ...rest } = Object.keys(
    flow[match.path] || {}
  ).reduce(
    (obj, key) => ({
      ...obj,
      [key]: loading
        ? undefined
        : typeof flow[match.path][key] === 'function'
        ? flow[match.path][key](context)
        : flow[match.path][key],
    }),
    { next, loading, context }
  )

  const recentlyRegainFocus = useRef(false)
  useFocus({
    onFocus() {
      recentlyRegainFocus.current = true
      // If there were no auto redirects after a few secs let's switch back flag
      setTimeout(() => {
        recentlyRegainFocus.current = false
      }, 5000)
    },
  })

  useEffect(() => {
    if (
      // Initial context is loaded
      (!loading && !mounted.current) ||
      // Context changed since initial load and auto redirect feature is active
      (!loading && !!config?.autoRedirect) ||
      // User recently regain focus of the page so we allow auto flow recovery
      (!loading && !!recentlyRegainFocus.current)
    ) {
      // The page is not available anymore
      if (!isAvailable) {
        // Redirect user to currently avaialble page in the flow
        next()
        recentlyRegainFocus.current = false
      }
      mounted.current = true
    }
  }, [loading, isAvailable, context, config, next])

  if (loading || (!isAvailable && !mounted.current)) {
    return { loading: true, next, context }
  }

  return { isAvailable, ...rest }
}

export default useFlow

export function withFlow(Component) {
  return p => {
    const flow = useFlow()
    return <Component flow={flow} {...p} />
  }
}
