import { Button, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from './Modal'

function BookAppointment({ onSubmit, lead, ...rest }) {
  const modal = React.useRef()
  const { t } = useTranslation()
  return (
    <View>
      <Modal
        onSubmit={onSubmit}
        firstName={lead.firstName}
        appointment={lead.appointment}
        innerRef={modal}
      />
      <Button
        variant="secondary"
        sizeVariant="sm"
        onClick={() => modal.current.open()}
        width={['100%', 'auto']}
        {...rest}
      >
        {t('Book consultation')}
      </Button>
    </View>
  )
}

BookAppointment.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  lead: PropTypes.object.isRequired,
}

export default BookAppointment
