import { DescriptionList, Settings, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const { Section } = Settings

function Business({ resource }) {
  const { data: merchant } = resource
  const { t } = useTranslation()

  const businessTypes = {
    private_limited_company: t('Limited company (LTD)'),
    limited_liability_partnership: t('Limited Liability Partnership (LLP)'),
    sole_trader: t('Sole trader'),
    partnership: t('Partnership'),
  }

  const sectors = {
    dental_services: t('Dental care'),
    optical_care: t('Optical care'),
    cosmetic_surgery: t('Cosmetic surgery'),
    private_hospital: t('Private hospital'),
    veterinary_services: t('Veterinary Services'),
    physiotherapy: t('Physiotherapy'),
    skin_laser_facial_treatments: t(
      'Skin, laser, facial treatments (non-invasive)'
    ),
    medical_training: t('Medical training'),
    other_health_care: t('Other Healthcare'),
    other: t('Other'),
    hair_transplant: t('Hair transplant'),
    hearing_aids: t('Hearing aids'),
  }

  const isSoleTraderOrPartnership = ['sole_trader', 'partnership'].includes(
    merchant.companyType
  )

  return (
    <Section _notLast={{ borderBottom: 0, pb: 4 }}>
      <Section.Header mb="-12px">
        <View>
          <Section.Title>{t('Business information')}</Section.Title>
          <Section.Description>
            {t(
              'This is general information about your business, please help us keep this information up to date by notifying any changes.'
            )}
          </Section.Description>
        </View>
      </Section.Header>

      <DescriptionList
        data={[
          {
            label: t('Business type'),
            value: businessTypes[merchant.companyType],
          },
          !isSoleTraderOrPartnership && {
            label: t('Company name'),
            value: merchant.name,
          },
          !isSoleTraderOrPartnership && {
            label: t('Company number'),
            value: merchant.companyID,
          },
          !isSoleTraderOrPartnership && {
            label: t('Trading as'),
            value: merchant.tradingName,
          },
          !isSoleTraderOrPartnership && {
            label: t('Registered address'),
            value: merchant.registeredAddress,
          },
          isSoleTraderOrPartnership && {
            label: t('Trading name'),
            value: merchant.tradingName,
          },
          {
            label: t('Sector'),
            value: sectors[merchant.sector] || merchant.sector,
          },
          {
            label: t('VAT number'),
            value: merchant.vatNumber,
          },
          {
            label: t('FCA reference number'),
            value: merchant.fcaID,
          },
        ]}
      />
    </Section>
  )
}

Business.propTypes = {
  resource: PropTypes.any,
}

export default Business
