import { forwardRef, Ref, SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const SvgComponent = (
  { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M16.25 18.125V3.965c0-.924-.672-1.715-1.59-1.822a40.422 40.422 0 00-9.32 0c-.918.107-1.59.898-1.59 1.821v14.161l3.125-1.25L10 18.125l3.125-1.25 3.125 1.25zM6.875 8.333h6.25m-6.25 2.5H10"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgComponent)
export default ForwardRef
