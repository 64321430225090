import { Modal } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import { Basket } from '@tabeo/ts-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SendReceiptPanel } from '../SendReceiptPanel'

export type SendReceiptModalProps = {
  basket: Basket
  onReceiptSend: (params: { email: string }) => Promise<void>
} & React.ComponentProps<typeof Modal>

const SendReceiptModal = React.forwardRef(
  ({ basket, onReceiptSend, ...rest }: SendReceiptModalProps, ref: any) => {
    const { t } = useTranslation()

    return (
      <Modal ref={ref}>
        {({ isOpen, close }) => {
          return isOpen ? (
            <div
              className="mx-auto max-w-[90%] rounded border bg-white p-5 shadow-xl sm:max-w-[620px] sm:p-10"
              {...rest}
            >
              <h2 className="title mb-5 text-center">{t('Send receipt')}</h2>

              <SendReceiptPanel
                basket={basket}
                onReceiptSend={onReceiptSend}
                buttonVariant="primary"
              />

              <Button type="button" onClick={close} className="mt-6 w-full">
                {t('Close')}
              </Button>
            </div>
          ) : null
        }}
      </Modal>
    )
  }
)

export default SendReceiptModal
