import React from 'react'
import Svg, { G, SvgProps } from './Base'

function Addition({ fill, ...props }: SvgProps) {
  return (
    <Svg {...props}>
      <G
        stroke="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fill}
      >
        <path d="M12.163,9.65l9.193,0l0,2.347l-9.193,0l-0,10.003l-2.49,0l-0,-10.003l-9.029,0l-0,-2.347l9.029,0l-0,-9.65l2.49,0l-0,9.65Z" />
      </G>
    </Svg>
  )
}

export default Addition
