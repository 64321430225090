import { Box, Button, Subtitle, Text } from '@tabeo/scarf'
import Layout from 'components/Layout'
import emailNotVerifiedSvg from 'components/Signup/assets/email-not-verified.svg'
import useAuthGuard from 'hooks/useAuthGuard'
import useFlow from 'hooks/useFlow'
import usePromise from 'hooks/usePromise'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'resources/Auth'
import { useMerchant } from 'resources/Merchant'

function EmailVerification() {
  useAuthGuard()

  const { data: merchant } = useMerchant()
  const {
    data: { user },
    resource,
  } = useAuth()

  const { loading } = useFlow({ merchant, user }, { autoRedirect: true })

  const [trigger, { pending, error, success }] = usePromise(
    resource.requestEmailVerificationResend
  )
  const { t } = useTranslation()

  React.useEffect(() => {
    resource.config = {
      refreshInterval: 1000,
    }

    return () => {
      resource.config = {
        refreshInterval: 30 * 1000,
      }
    }
  }, [resource, resource.config])

  return (
    <Layout maxWidth="460px" bg={['white', 'sky.2']} isLoading={loading}>
      <Box variant="content" textAlign="center">
        <img src={emailNotVerifiedSvg} className="mx-auto mb-3" />
        <Subtitle color="sea.3" mb={2}>
          {t('Verify your email address')}
        </Subtitle>
        <Text mb={6}>
          {t(
            'Click the link in the email we’ve just sent to {{email}} to verify your address. It could have ended in your spam folder.',
            { email: user?.email }
          )}
        </Text>
        {success ? (
          <Text color="ink.2">{t('Verification email sent.')}</Text>
        ) : (
          <Button
            variant="secondary"
            onClick={() => trigger()}
            loading={pending}
            disabled={!!error}
          >
            {error ? t('There was an error') : t('Send the link again')}
          </Button>
        )}
      </Box>
    </Layout>
  )
}

export default EmailVerification
