import { Feed, View } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import moment from 'moment'
import { TFunction, useTranslation } from 'react-i18next'

import xGray from 'components/checkout-payment/assets//x-gray.svg'
import doubleLeftArrowDarkGray from 'components/checkout-payment/assets/double-left-arrow-dark-gray.svg'
import doubleLeftArrowOrange from 'components/checkout-payment/assets/double-left-arrow-orange.svg'
import xRed from 'components/checkout-payment/assets/x-red.svg'

const states = {
  NEW: 'new',
  SUCCEEDED: 'succeeded',
  CANCELED: 'canceled',
  DECLINED: 'declined',
  FAILED: 'failed',
}

function getStateVariables({ data, t }: { data: any; t: TFunction }) {
  switch (data.to) {
    case states.NEW:
      return {
        titleContent: t('Refund requested ({{amount}})', {
          amount: format(data.amount),
        }),
        Icon: (p: any) => <View as="img" src={doubleLeftArrowOrange} {...p} />,
      }
    case states.SUCCEEDED:
      return {
        titleContent: !data.isPartial
          ? t('Full refund executed ({{amount}})', {
              amount: format(data.amount),
            })
          : t('Partial refund executed ({{amount}})', {
              amount: format(data.amount),
            }),
        Icon: (p: any) => (
          <View as="img" src={doubleLeftArrowDarkGray} {...p} />
        ),
      }
    case states.CANCELED:
      return {
        titleContent: t('Refund request cancelled ({{amount}})', {
          amount: format(data.amount),
        }),
        Icon: (p: any) => <View as="img" src={xGray} {...p} />,
      }
    case states.DECLINED:
      return {
        titleContent: t('Refund request declined ({{amount}})', {
          amount: format(data.amount),
        }),
        Icon: (p: any) => <View as="img" src={xRed} {...p} />,
      }
    case states.FAILED:
      return {
        titleContent: t('Refund failed ({{amount}})', {
          amount: format(data.amount),
        }),
        Icon: (p: any) => <View as="img" src={xRed} {...p} />,
      }
    default:
      return null
  }
}

function Refund({ data, ...rest }: any) {
  const { t } = useTranslation()

  const variables = getStateVariables({ data, t })

  if (!variables) {
    return null
  }

  const { titleContent, Icon } = variables

  return (
    <Feed.Item icon={(p: any) => <Icon width="20px" {...p} />} {...rest}>
      <Feed.Item.Title mb={1}>{titleContent}</Feed.Item.Title>
      <Feed.Item.Date>
        {moment(data.at).format('DD MMM YYYY, hh:mm a')}
      </Feed.Item.Date>
    </Feed.Item>
  )
}

export default Refund
