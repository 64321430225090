import { Accordion, Attributes, Avatar, ButtonGroup, Panel } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import { PaymentRefund } from '@tabeo/ts-types'
import { t } from 'i18n'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Refund from './PaymentRefund'
import reasons from './PaymentRefund/reasons'

export const states: Record<any, { color: any; label: string }> = {
  requested: {
    color: 'orange',
    label: t('Pending approval'),
  },
  declined: {
    color: 'red',
    label: t('Declined'),
  },
  canceled: {
    color: 'grey',
    label: t('Cancelled'),
  },
  succeeded: {
    color: 'darkGrey',
    label: t('Executed'),
  },
  failed: {
    color: 'red',
    label: t('Failed'),
  },
}

type RefundsPanelProps = {
  refunds?: PaymentRefund[]
} & React.ComponentProps<typeof Panel>

const failureReasons: {
  [key in PaymentRefund['failureReason']]: string
} = {
  lost_or_stolen_card: t(
    'Card lost or stolen. Please refund the patient with an alternative method.'
  ),
  expired_or_canceled_card: t(
    'Card expired or cancelled. Please refund the patient with an alternative method.'
  ),
  charge_for_pending_refund_disputed: t(
    'Chargeback already requested. Please refund the patient with an alternative method.'
  ),
  insufficient_funds: t(
    'Please refund the patient with an alternative method.'
  ),
  declined: t('Please refund the patient with an alternative method.'),
  merchant_request: t('Please refund the patient with an alternative method.'),
  unknown: t('Please refund the patient with an alternative method.'),
}

function RefundsPanel({ refunds }: RefundsPanelProps) {
  const { t } = useTranslation()

  if (!refunds?.length) {
    return null
  }

  return (
    <Panel title={t('Refunds')} data-testid="refunds-panel">
      {refunds.map(r => {
        return (
          <Accordion
            key={r.id}
            title={t('{{amount}} - {{reason}}', {
              amount: format(r.amount),
              reason: reasons[r.reasonType] || r.reasonType,
            })}
            status={states[r.status]?.label || r.status}
            statusColor={states[r.status]?.color || 'grey'}
            subtitle={moment(r.createdAt).format('DD MMM YYYY, hh:mm a')}
          >
            <Attributes
              // @ts-ignore
              sections={[
                [
                  {
                    label: t('Requested by'),
                    value: !!r.requester?.id,
                    formattedValue: (
                      <div className="flex items-center">
                        <Avatar
                          size="small"
                          name={r.requester?.name}
                          color="primary.3"
                          mr={1}
                        />{' '}
                        {r.requester?.name}
                      </div>
                    ),
                  },
                  {
                    label: t('Requested on'),
                    value: moment(r.createdAt).format('DD MMM YYYY, hh:mm a'),
                  },
                  {
                    label: t('Internal note'),
                    value: r.reasonNote,
                  },
                ],
                r.status === 'succeeded' && [
                  {
                    label: t('Executed by'),
                    value: !!r.approver?.id,
                    formattedValue: (
                      <div className="flex items-center">
                        <Avatar
                          size="small"
                          name={r.approver?.name}
                          color="primary.3"
                          mr={1}
                        />{' '}
                        {r.approver?.name}
                      </div>
                    ),
                  },
                  {
                    label: t('Executed on'),
                    value: moment(r.updatedAt).format('DD MMM YYYY, hh:mm a'),
                  },
                ],
                r.status === 'declined' && [
                  {
                    label: t('Declined by'),
                    value: !!r.decliner?.id,
                    formattedValue: (
                      <div className="flex items-center">
                        <Avatar
                          size="small"
                          name={r.decliner?.name}
                          color="primary.3"
                          mr={1}
                        />{' '}
                        {r.decliner?.name}
                      </div>
                    ),
                  },
                  {
                    label: t('Declined on'),
                    value: moment(r.updatedAt).format('DD MMM YYYY, hh:mm a'),
                  },
                  {
                    label: t('Internal note'),
                    value: r.declineNote,
                  },
                ],
                r.status === 'canceled' && [
                  {
                    label: t('Cancelled by'),
                    value: !!r.canceler?.id,
                    formattedValue: (
                      <div className="flex items-center">
                        <Avatar
                          size="small"
                          name={r.canceler?.name}
                          color="primary.3"
                          mr={1}
                        />{' '}
                        {r.canceler?.name}
                      </div>
                    ),
                  },
                  {
                    label: t('Cancelled on'),
                    value: moment(r.updatedAt).format('DD MMM YYYY, hh:mm a'),
                  },
                ],
                r.status === 'failed' && [
                  {
                    label: t('Executed by'),
                    value: !!r.approver?.id,
                    formattedValue: (
                      <div className="flex items-center">
                        <Avatar
                          size="small"
                          name={r.approver?.name}
                          color="primary.3"
                          mr={1}
                        />{' '}
                        {r.approver?.name}
                      </div>
                    ),
                  },
                  {
                    label: t('Executed on'),
                    value: moment(r.updatedAt).format('DD MMM YYYY, hh:mm a'),
                  },
                ],
                [
                  {
                    label: t('Failure note'),
                    value: failureReasons[r.failureReason],
                  },
                ],
              ].filter(Boolean)}
            />
            {r.status === 'requested' && (
              <ButtonGroup mt={6}>
                <Refund.ReviewButton id={r.id} />
                <Refund.CancelButton id={r.id} />
              </ButtonGroup>
            )}
          </Accordion>
        )
      })}
    </Panel>
  )
}

export default RefundsPanel
