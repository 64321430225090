import styled from 'styled-components'
import Button from '../../../Button'
import Flex from '../../../Flex'
import View from '../../../View'

export const Actions = styled(View)``

Actions.defaultProps = {
  display: 'flex',
  flexDirection: 'column',
}

export const ActionButton = styled(Button)``

ActionButton.defaultProps = {
  sizeVariant: 'xs',
  variant: 'secondary',
  color: 'ink.1',
  width: '100%',
  maxWidth: ['100%', '200px'],
  mb: 2,
}

export const Main = styled(View)``

Main.defaultProps = {
  flex: [null, '1'],
  minWidth: 0,
}

export const Sidebar = styled(View)``

Sidebar.defaultProps = {
  flex: [null, '0'],
  display: 'flex',
  minWidth: '160px',
}

export const Meta = styled(Flex)``

Meta.defaultProps = {
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
}

export const State = styled(View)``

State.defaultProps = {
  pl: 2,
  pr: 3,
  py: '6px',
  border: 1,
  borderRadius: 1,
  display: 'inline-flex',
  height: '36px',
  _notLast: {
    mr: 3,
  },
}

export { default as Description } from './Description'
export { default as Id } from './Id'
export { default as MetaItem } from './MetaItem'
export { default as Title } from './Title'
