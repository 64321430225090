import PropTypes from 'prop-types'
import React, { ReactNode, useCallback, useRef, useState } from 'react'
import { useMobile } from '../../hooks/useMatchMedia'
import ScarfProps from '../../types/ScarfProps'
import { Merge } from '../../types/helpers'
import Flex from '../Flex'
import * as Icons from '../Icons'
import Select, { Item, SelectProps } from '../InteractiveForm/Select'
import Popover from '../Popover'
import Text, { Subheading } from '../Text'
import View from '../View'

export type FilterSelectProps = Merge<
  ScarfProps,
  {
    name: string
    loadOptions?: (
      query: string,
      context?: { mode?: string }
    ) => Promise<Item[]> | Item[]
    modifyValue?: ({
      value,
      mode,
    }: {
      value: SelectProps['value'] | null
      mode: SelectProps['mode']
    }) => any
    options?: SelectProps['options']
    onChange: SelectProps['onChange']
    value: SelectProps['value']
    label: string
    filtering?: SelectProps['filtering']
    selectProps?: Partial<SelectProps>
    mode: SelectProps['mode']
    modeOptions?: SelectProps['modeOptions']
    renderActiveLabel?: ({
      value,
      mode,
    }: {
      value: SelectProps['value']
      mode: SelectProps['mode']
    }) => JSX.Element
  }
>

export function CustomFilterWrapper({
  children,
  label,
  value,
  ...rest
}: {
  children: (props: any) => ReactNode
  label: string
  value: string
}) {
  const [open, setOpen] = useState(false)
  const ref = useRef()

  return (
    <View display="inline-block" width={['100%', 'unset']} {...rest}>
      <Popover
        open={open}
        onClickOut={() => setOpen(false)}
        placement="bottom-start"
        referenceElement={ref}
        offset={[0, 4]}
      >
        {({ ref, style, placement }) =>
          children({
            containerProps: { ref, style, placement },
            open,
            setOpen,
          })
        }
      </Popover>
      <Flex
        ref={ref}
        onClick={() => {
          setOpen(!open)
        }}
        cursor="pointer"
        minHeight="36px"
        alignItems="center"
        display="inline-flex"
        width="100%"
        px={3}
      >
        <Subheading color="ink.2">
          <Text
            display={['inline-block', 'none']}
            fontSize="12px"
            color="default.primary"
            fontWeight="semibold"
            mr={3}
            ml={1}
          >
            {value}
          </Text>
          {label}
        </Subheading>
        <Icons.ArrowDown
          transform={['rotate(-90deg)', 'unset']}
          width="14px"
          fill="ink.2"
          ml={1}
        />
      </Flex>
    </View>
  )
}

function FilterSelect({
  name,
  loadOptions,
  modifyValue,
  options,
  onChange,
  value,
  onFocus,
  onBlur,
  label,
  filtering,
  selectProps = {},
  mode,
  modeOptions,
  renderActiveLabel,
  ...rest
}: FilterSelectProps) {
  const [open, setOpen] = useState(false)
  const ref = useRef()
  const mobile = useMobile()

  const loadOptionsCallback = useCallback(
    (query: string) => loadOptions?.(query, { mode }),
    [loadOptions, mode]
  )

  return (
    <View display="inline-block" width={['100%', 'unset']} {...rest}>
      <Popover
        open={open}
        onClickOut={() => setOpen(false)}
        placement="bottom-start"
        referenceElement={ref}
        offset={[0, 4]}
      >
        {({ ref, style, placement, update }) => (
          <Select
            ref={ref}
            style={mobile ? { zIndex: 1000 } : style}
            data-placement={placement}
            onHitsUpdate={update}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder="Search"
            loadOptions={loadOptions ? loadOptionsCallback : undefined}
            options={options}
            filtering={filtering}
            selectedFirst={filtering}
            modeOptions={modeOptions}
            value={value}
            mode={mode}
            onChange={(value, mode) => {
              if (!Array.isArray(value)) {
                setOpen(false)
              }

              const modifiedValue = modifyValue
                ? modifyValue({ value, mode })
                : value

              onChange({ name, value: modifiedValue, mode })
            }}
            {...selectProps}
            onClose={() => setOpen(false)}
            renderItem={(item, { hover, selected, ...rest }) => (
              <Flex
                justifyContent="flex-start"
                px={[5, 3]}
                py={[5, 3]}
                bg={hover ? 'default.primary' : undefined}
                color={hover ? 'white' : undefined}
                {...rest}
              >
                <Text
                  color={hover ? 'white' : 'default.ink'}
                  fontSize={[null, '14px']}
                  minHeight="22px"
                >
                  {item.label}
                </Text>
                <Flex flex="1" />
                {selected && (
                  <Icons.Tick light fill={hover ? 'white' : undefined} />
                )}
              </Flex>
            )}
          />
        )}
      </Popover>
      <Flex
        ref={ref}
        onClick={() => {
          setOpen(!open)
        }}
        cursor="pointer"
        minHeight="36px"
        alignItems="center"
        display="inline-flex"
        width="100%"
        px={3}
      >
        <Subheading color="ink.2">
          <Text
            display={['inline-block', 'none']}
            fontSize="12px"
            color="default.primary"
            fontWeight="semibold"
            mr={3}
            ml={1}
          >
            {value ? value?.length ?? 1 : 0}
          </Text>
          {mobile && renderActiveLabel
            ? renderActiveLabel({ value, mode })
            : label}
        </Subheading>
        <Icons.ArrowDown
          transform={['rotate(-90deg)', 'unset']}
          width="14px"
          fill="ink.2"
          ml={1}
        />
      </Flex>
    </View>
  )
}

FilterSelect.propTypes = {
  loadOptions: PropTypes.func,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  invalid: PropTypes.bool,
  filtering: PropTypes.bool,
  label: PropTypes.string,
  selectProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  mode: PropTypes.string,
  modeOptions: PropTypes.array,
  renderActiveLabel: PropTypes.func,
  modifyValue: PropTypes.func,
}

export default FilterSelect
