import React from 'react'
import ScarfProps from '../../types/ScarfProps'
import View from '../View'
import { ReactComponent as Active } from './assets/active.svg'
import { ReactComponent as Empty } from './assets/empty.svg'
import { ReactComponent as SemiActive } from './assets/semi-active.svg'

export type SelectorProps = ScarfProps & {
  state?: 'active' | 'semiActive' | 'idle'
}

function Selector({ state, ...rest }: SelectorProps) {
  if (state === 'active') {
    return <View as={Active} display="flex" cursor="pointer" {...rest} />
  }
  if (state === 'semiActive') {
    return <View as={SemiActive} display="flex" cursor="pointer" {...rest} />
  }
  return <View as={Empty} display="flex" cursor="pointer" {...rest} />
}

export default Selector
