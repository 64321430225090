import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import Active from './Active'
import Canceled from './Canceled'
import CancelModal from './CancelModal'
import Inactive from './Inactive'
import Incomplete from './Incomplete'
import Sent from './Sent'

function ActionPanel(props) {
  const { state } = props?.data
  const cancelModal = useRef()
  const passProps = {
    cancelModal,
    ...props,
  }

  let Component = null
  switch (state) {
    case 'sent':
      Component = <Sent {...passProps} />
      break
    case 'incomplete':
      Component = <Incomplete {...passProps} />
      break
    case 'active':
      Component = <Active {...passProps} />
      break
    case 'inactive':
      Component = <Inactive {...passProps} />
      break
    case 'canceled':
      Component = <Canceled {...passProps} />
      break
    default:
      Component = null
  }

  return (
    <>
      <CancelModal ref={cancelModal} />
      {Component}
    </>
  )
}

ActionPanel.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ActionPanel
