import React from 'react'
import styled from 'styled-components'

import Svg, { SvgProps } from './Base'

const X = (props: SvgProps) => (
  <Svg {...props}>
    <g>
      <path d="M11 8.96l4.08-4.081a1.443 1.443 0 1 1 2.041 2.04L13.041 11l4.08 4.08a1.443 1.443 0 1 1-2.04 2.041L11 13.041l-4.08 4.08a1.443 1.443 0 1 1-2.041-2.04L8.959 11 4.88 6.92a1.443 1.443 0 1 1 2.04-2.041L11 8.959z" />
    </g>
  </Svg>
)

export default styled(X)``
