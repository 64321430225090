import { t } from 'i18n'

export const CLINICIAN_ROLES = {
  nurse: t('Nurse'),
  technician: t('Technician'),
  hygienist: t('Hygienist'),
  general_practitioner: t('General practitioner'),
  specialised_practitioner: t('Specialised practitioner'),
  specialized_surgeon: t('Specialized surgeon'),
}

export const CLINICIAN_SPECIALIZATIONS = {
  surgery: t('Surgery'),
}

export function getRoleLabelBySector(role, sector) {
  const labels = {
    dental_services: {
      nurse: t('Dental nurse'),
      technician: t('Dental technician'),
      hygienist: t('Dental hygienist'),
      general_practinioner: t('Dentist'),
      specialised_practitioner: t('Specialised dentist'),
      specialized_surgeon: t('Oral & maxillofacial surgeon'),
    },
    optical_care: {
      nurse: t('Opthalmic nurse'),
      technician: t('Opthalmic technician'),
      general_practinioner: t('Optometrist'),
      specialised_practitioner: t('Specialised ophthalmologist'),
      specialized_surgeon: t('Ophthalmic/oculoplastic surgeon'),
    },
    cosmetic_surgery: {},
    private_hospital: {},
    veterinary_services: {
      nurse: t('Veterinary nurse'),
      technician: t('Veterinary technician'),
      general_practinioner: t('Vet'),
      specialised_practitioner: t('Specialised vet'),
      specialized_surgeon: t('Veterinary surgeon'),
    },
    physiotherapy: {},
    skin_laser_facial_treatments: {
      nurse: t('Aesthetic nurse'),
      technician: t('Aesthetic technician'),
      general_practinioner: t('Cosmetic doctor'),
      specialized_surgeon: t('Plastic surgeon'),
    },
    medical_training: {},
    specialist_retail: {},
    hair_transplant: {
      nurse: t('Hair transplant nurse'),
      technician: t('Hair transplant technician'),
      general_practinioner: t('Hair transplant doctor'),
      specialised_practitioner: t('Hair transplant specialist'),
      specialized_surgeon: t('Hair transplant surgeon'),
    },
    hearing_aids: {
      nurse: t('Audiology assistant'),
      technician: t('Audiology technician'),
      general_practinioner: t('Audiometrist'),
      specialised_practitioner: t('Audiologist'),
    },
    other_health_care: {},
  }

  return labels[sector]?.[role] || CLINICIAN_ROLES[role] || role
}
