import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Caption, Flex, Heading, View } from '@tabeo/scarf'
import { useTranslation } from 'react-i18next'
import Card from 'components/Transaction/ActionPanel/Card'
import icon from './assets/left.svg'

function Expired({ data: { scheduledAt }, ...rest }) {
  const { t } = useTranslation()
  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Bill expired')}</Heading>
      </Flex>
      <Caption color="ink.1">
        {t(
          'The bill that was meant to be taken on {{date}} has now expired. This is because we were unable to collect payment since no payment details were provided.',
          { date: moment(scheduledAt).format('DD MMM YYYY') }
        )}
      </Caption>
    </Card>
  )
}

Expired.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Expired
