import PropTypes from 'prop-types'
import React from 'react'
import Parent from './Parent'
import Person from './Person'
import Pet from './Pet'

function PatientPanel({ data, ...rest }) {
  const {
    metadata,
    merchant: { sector },
  } = data
  const pet = metadata?.patient?.pet || {}

  if (pet.species) {
    return (
      <>
        <Pet data={data} {...rest} />
        <Parent data={data} />
      </>
    )
  }
  if (sector !== 'veterinary_services') {
    return <Person data={data} {...rest} />
  }
  return null
}

PatientPanel.propTypes = {
  data: PropTypes.object,
}

export default PatientPanel
