import { generatePath } from 'react-router-dom'
import signupUK from './signup/uk'

export const getFlow = () => {
  return signupUK
}

export const getAvailableStep = context => {
  const flow = getFlow(context)
  const paths = Object.keys(flow).sort((a, b) => {
    if (!flow[a]?.priority) {
      return 1
    }
    if (!flow[b]?.priority) {
      return -1
    }
    return flow[a]?.priority - flow[b]?.priority
  })

  // TODO optimize
  // eslint-disable-next-line no-restricted-syntax
  for (const path of paths) {
    const step = flow[path]
    if (step.isAvailable?.(context)) {
      const { pathname, query } = step.currentStep?.(context)
      return generatePath(pathname, query)
    }
  }

  // TODO handle empty properly
  return '/404'
}
