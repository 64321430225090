import {
  Button,
  Flex,
  Icons,
  Prompt,
  Tooltip,
  usePromise,
  View,
} from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

function CloseLead({ onSubmit, ...rest }) {
  const [triggerWon, wonStatus] = usePromise(() => onSubmit('succeeded'))
  const [triggerLost, lostStatus] = usePromise(() => onSubmit('failed'))
  const { t } = useTranslation()

  return (
    <Tooltip
      trigger="click"
      hideOnClick={false}
      component={
        <View>
          <Prompt mb={3}>{t('Did the lead convert to a treatment?')}</Prompt>
          <Flex justifyContent="flex-start" mb={1}>
            <Button
              sizeVariant="sm"
              variant="secondary"
              color="sea.3"
              mb={[3, 0]}
              onClick={triggerWon}
              loading={wonStatus.pending}
              disabled={!!wonStatus.error || lostStatus.pending}
              mr={3}
            >
              <Flex justifyContent="flex-start">
                <span>{t('Yes')}</span>
                <Icons.Tick
                  width="18px"
                  height="18px"
                  light
                  ml={1}
                  fill="sea.3"
                />
              </Flex>
            </Button>
            <Button
              sizeVariant="sm"
              variant="secondary"
              color="red.3"
              mb={[3, 0]}
              onClick={triggerLost}
              loading={lostStatus.pending}
              disabled={!!lostStatus.error || wonStatus.pending}
            >
              <Flex justifyContent="flex-start">
                <span>{t('No')}</span>
                <Icons.X width="18px" height="18px" ml={1} fill="red.3" />
              </Flex>
            </Button>
          </Flex>
        </View>
      }
      {...rest}
    >
      <Button
        variant="secondary"
        sizeVariant="sm"
        color="ink.2"
        width={['100%', 'auto']}
      >
        {t('Close lead')}
      </Button>
    </Tooltip>
  )
}

CloseLead.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default CloseLead
