// @ts-nocheck

import { themeGet } from '@styled-system/theme-get'
import PropTypes from 'prop-types'
import { Component } from 'react'

import styled from 'styled-components'
import { InputProp, MetaProp } from '../../types/form'
import { Merge } from '../../types/helpers'
import Text from '../Text'
import { Base, HtmlInput as BaseInput, HtmlInputProps } from './Input'

const HtmlInput = styled(BaseInput).attrs(() => ({
  type: 'tel',
}))`
  font-variant-numeric: tabular-nums;
  font-weight: ${themeGet('fontWeights.regular')};
`

const SmallInput = styled(HtmlInput)`
  width: 26px;
  flex-basis: auto;
`

const MediumInput = styled(HtmlInput)`
  width: 28px;
  flex-basis: auto;
`

const LargeInput = styled(HtmlInput)`
  width: 44px;
  flex-basis: auto;
`

const isDateValid = (date) => {
  const d = new Date(date)
  if (Object.prototype.toString.call(d) === '[object Date]') {
    if (!Number.isNaN(Number(d.getTime()))) {
      return true
    }
  }
}

export type DateInputProps = Merge<
  HtmlInputProps,
  {
    input?: Partial<InputProp>
    meta?: Partial<MetaProp>
    disabled?: boolean
    inputProps?: {
      dd?: HtmlInputProps
      mm?: HtmlInputProps
      yyyy?: HtmlInputProps
    }
  }
>

class DateInput extends Component<DateInputProps> {
  state = {
    focus: false,
    dd: '',
    mm: '',
    yyyy: '',
  }

  componentDidMount() {
    const {
      input: { value },
    } = this.props
    const { dd, mm, yyyy } = this.parseValueString(value)
    this.setState({
      focus: false,
      dd,
      mm,
      yyyy,
    })
  }

  componentDidUpdate() {
    // TODO: implement controlled version of the component
  }

  // eslint-disable-next-line class-methods-use-this
  parseValueString = (value = '') => {
    if (!isDateValid(value)) {
      return {
        dd: '',
        mm: '',
        yyyy: '',
      }
    }

    const date = new Date(value)
    const day = parseInt(date.getUTCDate(), 10)
    const month = parseInt(date.getUTCMonth(), 10) + 1
    return {
      dd: day < 10 ? `0${day}` : `${day}`,
      mm: month < 10 ? `0${month}` : `${month}`,
      yyyy: `${date.getUTCFullYear()}`,
    }
  }

  handleChange = ({ currentTarget: { id, value } }) => {
    const {
      input: { onChange },
    } = this.props
    let normalizedValue = value
    if (id === 'dd') {
      normalizedValue = this.normalizeDD(value)
    }
    if (id === 'mm') {
      normalizedValue = this.normalizeMM(value)
    }
    if (id === 'yyyy') {
      normalizedValue = this.normalizeYYYY(value)
    }
    this.setState(
      {
        [id]: normalizedValue,
      },
      () => {
        const { dd, mm, yyyy } = this.state
        if (Number(dd) > 0 && Number(mm) > 0 && Number(yyyy) > 999) {
          const date = new Date(Date.UTC(yyyy, mm - 1, dd)).toISOString()
          onChange && onChange(date)
        } else {
          onChange && onChange(null)
        }
      }
    )
  }

  normalizeDD = (value) => {
    let normalizedValue = value.replace(/[^0-9.]+/g, '')
    const possibleFirstChars = ['0', '1', '2', '3']

    if (
      normalizedValue[0] &&
      !possibleFirstChars.includes(normalizedValue[0])
    ) {
      normalizedValue = `0${value}`
    }

    if (
      normalizedValue[0] === '3' &&
      normalizedValue[1] &&
      !['0', '1'].includes(normalizedValue[1])
    ) {
      normalizedValue = normalizedValue.slice(0, 1)
    }

    if (value.length > 2) {
      normalizedValue = normalizedValue.slice(0, 2)
    }

    if (normalizedValue === '00') {
      normalizedValue = '0'
    }

    if (normalizedValue.length === 2) {
      const { mm } = this.state

      this.mm.focus()
      this.mm.setSelectionRange(mm.length, mm.length)
    }

    return normalizedValue
  }

  normalizeMM = (value) => {
    let normalizedValue = value.replace(/[^0-9.]+/g, '')
    const possibleFirstChars = ['0', '1']

    if (
      normalizedValue[0] &&
      !possibleFirstChars.includes(normalizedValue[0])
    ) {
      normalizedValue = `0${value}`
    }

    if (
      normalizedValue[0] &&
      normalizedValue[0] !== '0' &&
      normalizedValue[1] &&
      !['0', '1', '2'].includes(normalizedValue[1])
    ) {
      normalizedValue = normalizedValue.slice(0, 1)
    }

    if (value.length > 2) {
      normalizedValue = normalizedValue.slice(0, 2)
    }

    if (normalizedValue === '00') {
      normalizedValue = '0'
    }

    if (normalizedValue.length === 2) {
      const { yyyy } = this.state

      this.yyyy.focus()
      this.yyyy.setSelectionRange(yyyy.length, yyyy.length)
    }

    return normalizedValue
  }

  // eslint-disable-next-line class-methods-use-this
  normalizeYYYY = (value) => {
    let normalizedValue = value.replace(/[^0-9.]+/g, '')

    if (normalizedValue[0] && !['1', '2'].includes(normalizedValue[0])) {
      normalizedValue = `19${normalizedValue[0]}`
    }

    if (value.length > 4) {
      normalizedValue = normalizedValue.slice(0, 4)
    }

    return normalizedValue
  }

  handleCursorMove = (event) => {
    const {
      target: { id, selectionEnd },
      key,
    } = event

    // Backspace && ArrowLeft
    if (
      ['Backspace', 'ArrowLeft'].includes(key) &&
      id === 'yyyy' &&
      selectionEnd === 0
    ) {
      this.mm.focus()
      this.mm.setSelectionRange(2, 2)
    }
    if (
      ['Backspace', 'ArrowLeft'].includes(key) &&
      id === 'mm' &&
      selectionEnd === 0
    ) {
      this.dd.focus()
      this.dd.setSelectionRange(2, 2)
    }

    // ArrowRight
    if (['ArrowRight'].includes(key) && id === 'dd' && selectionEnd === 2) {
      this.mm.focus()
      this.mm.setSelectionRange(0, 0)
    }
    if (['ArrowRight'].includes(key) && id === 'mm' && selectionEnd === 2) {
      this.yyyy.focus()
      this.yyyy.setSelectionRange(0, 0)
    }
  }

  setFocus = () =>
    this.setState({
      focus: true,
    })

  removeFocus = () => {
    const {
      input: { onBlur },
    } = this.props
    const { dd, mm, yyyy } = this.state
    this.setState({
      focus: false,
    })

    if (dd && mm && yyyy) {
      const date = new Date(Date.UTC(yyyy, mm - 1, dd))
      onBlur && onBlur(date)
    }
  }

  render() {
    const { disabled, meta, inputProps = {}, ...rest } = this.props
    const { focus, dd, mm, yyyy } = this.state
    return (
      <Base
        justifyContent="flex-start"
        width="155px"
        minWidth="auto"
        pr="7px"
        disabled={disabled}
        {...rest}
        meta={{
          ...meta,
          focus,
        }}
      >
        <SmallInput
          id="dd"
          ref={(dd) => {
            this.dd = dd
          }}
          onChange={this.handleChange}
          onFocus={this.setFocus}
          onBlur={this.removeFocus}
          value={dd}
          onKeyDown={this.handleCursorMove}
          disabled={disabled}
          placeholder="DD"
          {...inputProps?.dd}
        />
        <Text px="6px" color="sky.0">
          /
        </Text>
        <MediumInput
          id="mm"
          ref={(mm) => {
            this.mm = mm
          }}
          onChange={this.handleChange}
          onFocus={this.setFocus}
          onBlur={this.removeFocus}
          onKeyDown={this.handleCursorMove}
          disabled={disabled}
          value={mm}
          placeholder="MM"
          {...inputProps?.mm}
        />
        <Text px="6px" color="sky.0">
          /
        </Text>
        <LargeInput
          id="yyyy"
          ref={(yyyy) => {
            this.yyyy = yyyy
          }}
          onChange={this.handleChange}
          onFocus={this.setFocus}
          onBlur={this.removeFocus}
          onKeyDown={this.handleCursorMove}
          disabled={disabled}
          value={yyyy}
          placeholder="YYYY"
          {...inputProps?.yyyy}
        />
      </Base>
    )
  }
}

DateInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
}

DateInput.defaultProps = {
  input: {},
  meta: {},
}

export default DateInput
