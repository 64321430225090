import '@tabeo/scarf/dist/index.css'
import { configure } from '@tabeo/sharpei/config'
import '@tabeo/sharpei/polyfills'
import config from 'config'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import ReactDOM from 'react-dom'
import './assets/tailwind.css'
import App from './components/App'
import './i18n/init'

moment.locale(config.LOCALE)

configure(config)

const rootEl = document.getElementById('root')
ReactDOM.render(<App />, rootEl)
