import { Box, Button, Prompt, Title } from '@tabeo/scarf'
import dinoPng from 'assets/dino.png'
import FieldError from 'components/Form/FieldError'
import Layout from 'components/Layout'
import usePromise from 'hooks/usePromise'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'

function Fail() {
  const { t } = useTranslation()

  const {
    resource: { getStripeConnectOnboardingURL },
  } = useMerchant()
  const [trigger, { pending, error }] = usePromise(handleRetry)
  async function handleRetry() {
    const url = await getStripeConnectOnboardingURL()
    window.location.href = url
  }
  return (
    <Layout maxWidth="460px" bg={['white', 'sky.2']}>
      <Box variant="content" textAlign="center">
        <img className="mx-auto mb-8 w-28" src={dinoPng} />
        <Title mb={3}>{t('Oops, looks like you got interrupted')}</Title>
        <Prompt mb={6}>{t('Click on the button below to try again.')}</Prompt>
        <Button
          variant="secondary"
          width="100%"
          onClick={trigger}
          loading={pending}
        >
          {t('Verify my details')}
        </Button>
        {error && (
          <FieldError message={error?.message || t('There was an error')} />
        )}
      </Box>
    </Layout>
  )
}

Fail.propTypes = {}

export default Fail
