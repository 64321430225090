import React from 'react'
import { ActionKind, State } from './reducer'

type Context = {
  dispatch: (action: { type: ActionKind; payload?: any }) => void
  modal: {
    close: () => void
    isOpen?: boolean
  }
  state: State
  onSuccess: () => void
}

const TerminalPaymentContext = React.createContext<Context>({
  dispatch: () => {},
  modal: {
    close: () => {},
  },
  state: {
    terminalPaymentId: 0,
    step: 'test-connection',
  },
  onSuccess: () => {},
})

export default TerminalPaymentContext
