// TODO: should we translate?
export const dentalFeatures = [
  'Check-up',
  'Hygiene treatment',
  'X-ray',
  'Other',
]

export const dentalPerks = [
  'Free consultation for whiter teeth',
  'Free consultation for teeth straightening',
  '10% discount on fillings',
  '5% discount on private treatments',
  'Free referrals to specialists',
  'Other',
]

export const vetFeatures = [
  'Annual booster vaccination',
  'Health check',
  'Nail clipping',
  'Kennel Cough vaccination',
  'Worm protection',
  'Flea protection',
  'Distemper vaccination',
  'Hepatitis vaccination',
  'Parvovirus vaccination',
  'Leptospirosis vaccination',
  'Feline Leukaemia',
  'Panleukopenia',
  'Cat flu',
  'Urine analysis',
  'Annual blood screening',
  'Microchipping',
  'Life-stage specific blood screening',
  'Year-round parasite protection',
  'Vet health check at time of vaccination',
  'Additional vet health check',
  'Nurse health examination',
  'Other',
]

export const vetPerks = [
  'Behavioural advice',
  'Puppy party',
  'Insurance claim handling',
  '5% discount on all elective treatments',
  '10% discount on prescribed medications',
  'Other',
]
