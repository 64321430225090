import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import ScarfProps from '../../../types/ScarfProps'

export type FileItem = {
  id: number
  name: string
  url: string
}

export type FileProps = {
  value: File | FileItem
  upload: (file: File) => Promise<FileItem>
  onChange: (file: FileItem | null) => void
  children: (props: {
    value: File | FileItem
    loading: boolean
    error: any
    uploaded: boolean
    setValue: (file: FileItem | null) => void
    setLoading: (loading: boolean) => void
    setError: (error: any) => void
    spacing?: ScarfProps
  }) => JSX.Element
  spacing?: ScarfProps
}

function File({ value, upload, onChange, children, ...rest }: FileProps) {
  const [{ loading, error, uploaded }, setState] = useState<{
    loading: boolean
    error: any
    uploaded: boolean
  }>({
    loading: false,
    error: null,
    uploaded: !(value instanceof window.File),
  })

  const handleUpload = useCallback(async () => {
    try {
      setState((state) => ({ ...state, loading: true }))
      const uploadedFile = await upload(value as File)
      setState((state) => ({ ...state, loading: false, uploaded: true }))

      onChange(uploadedFile)
    } catch (e) {
      setState((state) => ({ ...state, loading: false, error: e }))
    }
  }, [onChange, upload, value])

  useEffect(() => {
    if (!loading && !error && !uploaded) {
      handleUpload()
    }
  }, [error, handleUpload, loading, uploaded])

  const setLoading = (loading: boolean) =>
    setState((state) => ({ ...state, loading }))

  const setError = (error: any) => setState((state) => ({ ...state, error }))

  return children({
    value,
    loading,
    error,
    uploaded,
    setValue: onChange,
    setLoading,
    setError,
    ...rest,
  })
}

File.propTypes = {
  children: PropTypes.func.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  upload: PropTypes.func,
}

export default File
