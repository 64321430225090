import React from 'react'
import Svg, { G, SvgProps } from './Base'

function Description({ fill, ...props }: SvgProps) {
  return (
    <Svg {...props}>
      <G
        stroke={fill}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <path d="M4.53 5.824h13.587M4.53 12.294H16.31M4.53 15.529H11M4.53 9.059h9.9" />
      </G>
    </Svg>
  )
}

export default Description
