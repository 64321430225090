import { Link } from 'react-router-dom'

import { Clickable } from '@tabeo/scarf'
import styled from 'styled-components'

export const Anchor = styled(Clickable)``

Anchor.defaultProps = {
  as: 'a',
}

export default Clickable.withComponent(Link)
