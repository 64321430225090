import Canceled from 'components/checkout-payment/ActionPanel/Canceled'
import CancellationFailed from 'components/checkout-payment/ActionPanel/CancellationFailed'
import Paid from 'components/checkout-payment/ActionPanel/Paid'
import PartiallyRefunded from 'components/checkout-payment/ActionPanel/PartiallyRefunded'
import RefundRequested from 'components/checkout-payment/ActionPanel/RefundRequested'
import Refunded from 'components/checkout-payment/ActionPanel/Refunded'
import Settled from 'components/checkout-payment/ActionPanel/Settled'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { NormalizedTerminalPayment } from 'types/NormalizedTerminalPayment'
import Authorised from './Authorised'
import Created from './Created'

function ActionPanel({ data }: { data: NormalizedTerminalPayment }) {
  if (data.status === 'c') {
    return <Created />
  }

  if (data.status === 'auth') {
    return <Authorised />
  }

  if (data.status === 'p') {
    return <Paid />
  }

  // failed

  if (data.status === 's') {
    return <Settled />
  }

  // expired

  if (data.status === 'pi_cancelled') {
    return <Canceled />
  }

  if (data.status === 'pi_cancelled_error') {
    return <CancellationFailed />
  }

  if (data.status === 'refund_req') {
    return <RefundRequested />
  }

  if (data.status === 'pr') {
    return <PartiallyRefunded />
  }

  if (data.status === 'fr') {
    return <Refunded />
  }

  // despute pending

  // charged back

  return (
    <ActionCard>
      <ActionCard.Error>Unhandled status: {data.status}</ActionCard.Error>
    </ActionCard>
  )
}

export default ActionPanel
