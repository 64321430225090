import { ConditionalFields, NativeSelect, Switch, View } from '@tabeo/scarf'
import {
  Button,
  FFCurrencyInput,
  FFRadioGroup,
  FFTextArea,
  FFTextInput,
  RadioGroup,
} from '@tabeo/scarf2'
import FormError from 'components/Form/FormError'
import MerchantTreatmentSelect, {
  OTHER_VALUES,
} from 'components/MerchantTreatmentSelect'
import { Field } from 'components/nnts/form/Field'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { getCategoriesBySector } from 'pages/Settings/Treatments/constants'
import { useMemo } from 'react'
import {
  Field as FormField,
  FieldRenderProps,
  Form as FinalForm,
  FormSpy,
} from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useMerchant } from 'resources/Merchant'
import { usePlansV2 } from 'resources/PlansV2'
import { usePlansV2Images } from 'resources/PlansV2Images'
import { twMerge } from 'tailwind-merge'
import {
  priceValidationMessage,
  tabeoErrorMap,
  zodValidator,
} from 'utils/zod-validation'
import { z } from 'zod'
import AddButton from './AddButton'
import FieldArrayItem from './FieldArrayItem'
import NumberInput from './NumberInput'
import { Section, SectionContent, SectionHeader, SectionTitle } from './Section'

z.setErrorMap(tabeoErrorMap)

const FormSchema = z
  .object({
    name: z.string().min(5).max(50),
    price: z.coerce
      .number()
      .min(200, priceValidationMessage(200, 100000))
      .max(100000, priceValidationMessage(200, 100000)),
    description: z.string().max(5000),
    imageUrl: z.string(),
    treatments: z.array(
      z
        .object({
          merchantTreatment: z.object({
            treatment: z.object({
              id: z.number(),
              name: z.string(),
            }),
          }),
          quantity: z.number().min(1).max(99),
          otherName: z.string().min(5).max(50).optional(),
          otherCategory: z.string().optional(),
        })
        .superRefine((value, ctx) => {
          if (OTHER_VALUES.includes(value.merchantTreatment?.treatment.name)) {
            if (value.otherName === undefined) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                path: ['otherName'],
                received: 'undefined',
                expected: 'string',
              })
            }
            if (value.otherCategory === undefined) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                path: ['otherCategory'],
                received: 'undefined',
                expected: 'string',
              })
            }
          }
        })
    ),
    perks: z.array(z.object({ name: z.string().min(5).max(50) })),
    excludedGoodsServices: z.string().max(5000),
    internalReference: z.string().max(50),
    internalDescription: z.string().max(5000).optional(),
    allowNewMembers: z.boolean().optional(),
  })
  .partial({ treatments: true, perks: true })
  .superRefine((value, ctx) => {
    if (value.treatments === undefined && value.perks === undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        path: ['treatments'],
        received: 'undefined',
        expected: 'array',
      })
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        path: ['perks'],
        received: 'undefined',
        expected: 'array',
      })
    }
  })

export type FormValues = z.infer<typeof FormSchema>

const exclusionsDefaultValue = `Restorative treatment such as crowns, implants, veneers, root treatments etc.
Teeth whitening and any cosmetic treatments available at the Merchant.
Orthodontic appliance therapy (‘braces’).
The provision, repair or replacement of dental implants and related superstructures.
Any treatment needed as a result of a dental injury (an injury to the teeth or supporting structures (including damage to dentures whilst being worn) which is directly caused suddenly and unexpectedly by means of a direct external impact).
Fees for local and general anaesthetic, pharmaceutical items, prescription fees, and laboratory fees.
Any treatment not specified in your membership.`

function Form({
  onFormChange,
}: {
  onFormChange: (values: Partial<FormValues>) => void
}) {
  const { t } = useTranslation()
  const history = useHistory()

  const { resource } = usePlansV2()
  const { data: images } = usePlansV2Images()

  const { data: merchant } = useMerchant()

  const treatmentCategories = [
    ...getCategoriesBySector(merchant?.sector),
    t('Other'),
  ]

  const handleSubmit = async (values: any) => {
    let parsedValues: FormValues
    try {
      parsedValues = FormSchema.parse(values)
    } catch (error) {
      return {
        [FORM_ERROR]: 'Invalid form values',
      }
    }

    try {
      await resource?.create({
        name: parsedValues.name,
        price: parsedValues.price,
        description: parsedValues.description,
        imageUrl: parsedValues.imageUrl.includes('no-image')
          ? undefined
          : parsedValues.imageUrl,
        treatments: parsedValues.treatments?.map(treatment => ({
          legacyId: Number(treatment.merchantTreatment.treatment?.id),
          name:
            treatment.otherName || treatment.merchantTreatment.treatment?.name,
          category: treatment.otherCategory,
          quantity: treatment.quantity,
        })),
        perks: parsedValues.perks?.map(perk => ({
          name: perk.name,
          quantity: undefined,
        })),
        excludedGoodsServices: parsedValues.excludedGoodsServices,
        internalReference: parsedValues.internalReference,
        internalDescription: parsedValues.internalDescription,
        _private: !parsedValues.allowNewMembers,
      })

      history.push('/plans')
    } catch (error) {
      return {
        [FORM_ERROR]: t('Failed to create plan'),
      }
    }
  }

  const initialValues = useMemo(
    () => ({
      excludedGoodsServices: exclusionsDefaultValue,
      treatments: [],
      perks: [],
      allowNewMembers: true,
    }),
    []
  )

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      validate={zodValidator(FormSchema)}
      destroyOnUnregister
    >
      {({
        values,
        handleSubmit,
        submitting,
        form: {
          mutators: { push },
        },
      }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            subscription={{ values: true }}
            onChange={({ values }) =>
              onFormChange(values as Partial<FormValues>)
            }
          />
          <div className="space-y-4">
            <View
              boxShadow={1}
              borderRadius={1}
              border={1}
              overflow="hidden"
              bg="white"
            >
              <SectionHeader>
                <SectionTitle>{t('Plan details')}</SectionTitle>
              </SectionHeader>
              <Section>
                <SectionContent className="p-0">
                  <Field
                    name="imageUrl"
                    containerClassName="-mt-1 [&>[data-error=true]]:px-5 [&>[data-error=true]]:py-1"
                    component={FFRadioGroup}
                  >
                    <div className="grid h-80 grid-cols-2 gap-5 overflow-y-auto p-5 sm:grid-cols-3 xl:grid-cols-4">
                      {images?.length
                        ? [images, '/images/no-image.svg'].flat().map(url => (
                            <RadioGroup.Option key={url} value={url}>
                              {({ checked }) => (
                                <div
                                  className={twMerge(
                                    'aspect-square h-full w-full cursor-pointer rounded border border-solid border-tabeo-sky-1 p-5 transition-all hover:bg-tabeo-sky-3',
                                    checked &&
                                      'border-tabeo-primary-3 ring-1 ring-tabeo-primary-3'
                                  )}
                                >
                                  <img
                                    src={url}
                                    className="h-full w-full object-cover"
                                  />
                                </div>
                              )}
                            </RadioGroup.Option>
                          ))
                        : Array.from(Array(12).keys()).map(key => (
                            <div
                              key={key}
                              className="aspect-square animate-pulse rounded bg-tabeo-sky-2"
                            />
                          ))}
                    </div>
                  </Field>
                </SectionContent>
              </Section>
              <Section>
                <SectionContent>
                  <Field<string>
                    component={FFTextInput}
                    name="name"
                    label={t('Plan name')}
                    description={t('The name of your new plan.')}
                  />
                  <Field<string>
                    component={FFTextArea}
                    name="description"
                    label={t('Short description')}
                    description={t(
                      'Briefly explain the key selling points of the plan without diving into too much detail.'
                    )}
                  />
                  <Field<string>
                    name="price"
                    label={t('Plan price')}
                    description={t(
                      'How much you would charge clients each month.'
                    )}
                    wrapClassName="w-[180px]"
                    placeholder={t('0.00')}
                  >
                    {props => (
                      <div className="flex items-center gap-2.5">
                        <FFCurrencyInput {...props} />
                        <span className="caption">{t('Per month')}</span>
                      </div>
                    )}
                  </Field>
                </SectionContent>
              </Section>
              <Section>
                <SectionContent>
                  <Field
                    name="treatments"
                    label={t('Benefits')}
                    subLabel={t('Benefit(s) that have a defined quantity')}
                  >
                    {({ input: { name } }: FieldRenderProps<string>) => (
                      <div className="mt-5 space-y-5">
                        <FieldArray name={name}>
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <FieldArrayItem
                                key={name}
                                onRemoveClick={() => fields.remove(index)}
                              >
                                <div className="space-y-5">
                                  <div className="flex items-start gap-5">
                                    <Field<string>
                                      component={MerchantTreatmentSelect}
                                      label={t('Item {{index}}', {
                                        index: index + 1,
                                      })}
                                      name={`${name}.merchantTreatment`}
                                      maxWidth="100%"
                                    />
                                    <Field<number>
                                      key={name}
                                      name={`${name}.quantity`}
                                      containerClassName="w-auto mt-6"
                                      component={NumberInput}
                                      min={1}
                                      max={99}
                                    />
                                  </div>
                                  {OTHER_VALUES.includes(
                                    values.treatments[index].merchantTreatment
                                      ?.treatment.name
                                  ) && (
                                    <ConditionalFields>
                                      <div className="mb-5 space-y-5">
                                        <Field<string>
                                          component={FFTextInput}
                                          label={t('Specify name')}
                                          name={`${name}.otherName`}
                                          placeholder={t(
                                            'Type your answer here...'
                                          )}
                                        />
                                        {!!treatmentCategories.length && (
                                          <Field
                                            name={`${name}.otherCategory`}
                                            label={t('Specify category')}
                                            component={NativeSelect}
                                            placeholder={t(
                                              'Select from the list...'
                                            )}
                                            options={treatmentCategories.map(
                                              o => ({
                                                value: o,
                                                label: o,
                                              })
                                            )}
                                            maxWidth="100%"
                                          />
                                        )}
                                      </div>
                                    </ConditionalFields>
                                  )}
                                </div>
                              </FieldArrayItem>
                            ))
                          }
                        </FieldArray>
                        <AddButton onClick={() => push(name, { quantity: 1 })}>
                          {t('Add item')}
                        </AddButton>
                      </div>
                    )}
                  </Field>
                </SectionContent>
                <SectionContent className="border-t border-solid border-tabeo-sky-1">
                  <Field
                    name="perks"
                    label={t('Perks')}
                    subLabel={t(
                      'Items that don’t have a defined quantity. For example, 10% discount on Invisalign'
                    )}
                  >
                    {({ input: { name } }: FieldRenderProps<string>) => (
                      <div className="mt-5 space-y-5">
                        <FieldArray name={name}>
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <FieldArrayItem
                                key={name}
                                onRemoveClick={() => fields.remove(index)}
                              >
                                <Field<string>
                                  key={name}
                                  component={FFTextInput}
                                  label={t('Item {{index}}', {
                                    index: index + 1,
                                  })}
                                  name={`${name}.name`}
                                  placeholder={t('Enter item...')}
                                />
                              </FieldArrayItem>
                            ))
                          }
                        </FieldArray>
                        <AddButton onClick={() => push(name, {})}>
                          {t('Add item')}
                        </AddButton>
                      </div>
                    )}
                  </Field>
                </SectionContent>
              </Section>
              <Section>
                <SectionContent>
                  <Field<string>
                    label={t('Excluded from your membership')}
                    subLabel={t('Update template with exclusions for the plan')}
                    name="excludedGoodsServices"
                    component={FFTextArea}
                    rows={12}
                    className="mt-2"
                  />
                </SectionContent>
              </Section>
              <SectionHeader>
                <SectionTitle>{t('Plan settings')}</SectionTitle>
              </SectionHeader>
              <Section>
                <SectionContent>
                  <Field<string>
                    component={FFTextInput}
                    name="internalReference"
                    label={t('Internal reference')}
                    description={t(
                      'Give your plan a descriptive name to help your team identify it.'
                    )}
                  />
                  <Field<string>
                    component={FFTextArea}
                    name="internalDescription"
                    label={t('Internal description')}
                    description={t(
                      'Add any additional notes describing the plan to your team.'
                    )}
                  />
                  <div className="flex items-center justify-between">
                    <div>
                      <label
                        htmlFor="allowNewMembers"
                        className="font-medium text-tabeo-ink-0"
                      >
                        {t('Allow new members')}
                      </label>
                      <div className="text-sm text-tabeo-ink-2">
                        {t(
                          'Switch on to allow new members to be onboarded to this plan.'
                        )}
                      </div>
                    </div>
                    <FormField
                      id="allowNewMembers"
                      name="allowNewMembers"
                      component={Switch}
                    />
                  </div>
                </SectionContent>
              </Section>
            </View>
            <Button
              type="submit"
              variant="primary"
              loading={submitting}
              className="w-full"
            >
              {t('Create plan')}
            </Button>
            <FormError />
          </div>
        </form>
      )}
    </FinalForm>
  )
}

export default Form
