import Resource, { createResourceHook, ResourceConfig } from '@tabeo/resync'
import api from 'utils/platform-api'
import { useVendor } from './Vendor'

interface Params {
  vendorId: string
}

type Data = Array<string>

class PlansV2Images extends Resource<Data, Params> {
  getInitialData() {
    return []
  }

  getInitialConfig() {
    return {
      refreshInterval: 15 * 1000,
    }
  }

  async fetch() {
    const response =
      await api.plansorchestrator.plans.apiV1OrchestratorPlansPlansCreateImagesGet(
        {
          authorization: '', // it's required, but the auth middleware will handle it
        }
      )

    const data = response
    this.data = data

    return data
  }
}

export default PlansV2Images
export const useResource = createResourceHook(PlansV2Images)
export function usePlansV2Images(
  p?: Params | (() => Params),
  config?: ResourceConfig
) {
  const { data: vendor } = useVendor()

  let params: Params | undefined
  if (p instanceof Function) {
    params = p()
  } else {
    params = p
  }

  const vendorIdParam = params?.vendorId || vendor?.id

  return useResource(() => {
    if (!vendorIdParam) {
      throw new Error('Vendor ID is null')
    }

    return {
      ...params,
      vendorId: vendorIdParam,
    }
  }, config)
}
