import { Button, Caption, Flex, Heading, View } from '@tabeo/scarf'
import FieldError from 'components/Form/FieldError'
import Card from 'components/Transaction/ActionPanel/Card'
import usePromise from 'hooks/usePromise'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import icon from './assets/card_declined.svg'

function PNCardDeclined({ transaction, ...rest }) {
  const { data, sendReminder, cancel } = transaction
  const { consumerFirstName, consumerHisHer, stateMachineTransitions } = data
  const [triggerReminder, reminderState] = usePromise(sendReminder)
  const [triggerCancel, cancelState] = usePromise(cancel)
  const event = stateMachineTransitions.find(e =>
    ['pay_now_payment_failed'].includes(e.to)
  )
  const { t } = useTranslation()

  const hisHerText =
    consumerHisHer === 'his'
      ? t(
          '{{consumerFirstName}} attempted to make a payment {{elapsedTime}}, but his card was declined.',
          {
            consumerFirstName,
            elapsedTime: moment().to(event.at),
          }
        )
      : t(
          '{{consumerFirstName}} attempted to make a payment {{elapsedTime}}, but her card was declined.',
          {
            consumerFirstName,
            elapsedTime: moment().to(event.at),
          }
        )
  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Card declined')}</Heading>
      </Flex>
      <Caption color="ink.1" mb={4}>
        {hisHerText}
      </Caption>
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="flex-start"
        alignItems={['stretch', 'center']}
      >
        <Button
          variant="secondary"
          sizeVariant="sm"
          mr={[0, 4]}
          mb={[4, 0]}
          onClick={triggerReminder}
          loading={reminderState.pending}
          disabled={reminderState.success}
        >
          {reminderState.success ? t('Reminder sent') : t('Send reminder')}
        </Button>
        <Button
          variant="secondary"
          sizeVariant="sm"
          onClick={triggerCancel}
          loading={cancelState.pending}
        >
          {t('Cancel transaction')}
        </Button>
      </Flex>
      {(reminderState.error || cancelState.error) && (
        <FieldError
          message={t('There was an error, please try again later.')}
          mt={4}
        />
      )}
    </Card>
  )
}

PNCardDeclined.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default PNCardDeclined
