import React from 'react'
import ScarfProps from '../../types/ScarfProps'
import View from '../View'

export type ContextType = any

export const Context = React.createContext<ContextType>({})

export type BuilderProps = ScarfProps & {
  items: {
    type: string
    id: string | number
    data?: any
    [key: string]: unknown
  }[]
  itemBuilders: {
    [key: string]: React.FC<{
      data: any
      index: number
      isLastItem: boolean
    }>
  }
  context?: Record<string, unknown>
}

function Builder({ items = [], itemBuilders, context, ...rest }: BuilderProps) {
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <Context.Provider value={{ items, ...context }}>
      <View p={4} {...rest}>
        {items.map((item, index) => {
          const ItemBuilder = itemBuilders[item.type]

          if (!ItemBuilder) {
            // @ts-ignore
            if (process.env !== 'production') {
              // eslint-disable-next-line no-console
              console.warn(`Builder missing for type ${item?.type}`, { item })
            }
            return null
          }

          return (
            <ItemBuilder
              data={item.data}
              key={`${item.type}-${item.id}`}
              index={index}
              isLastItem={index === items.length - 1}
            />
          )
        })}
      </View>
    </Context.Provider>
  )
}

Builder.Context = Context

export default Builder
