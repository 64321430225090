import React, { useEffect, useRef, useState } from 'react'
import ScarfProps from '../types/ScarfProps'
import Button from './Button'
import Flex from './Flex'
import Popover from './Popover'
import { Prompt } from './Text'
import View from './View'

export type ConfirmationProps = ScarfProps & {
  content: React.ReactNode
  children: React.ReactNode
  rejectText?: string
  confirmText?: string
  onConfirm?: () => void
  onReject?: () => void
  onOpenChange?: (open: boolean) => void
}

function Confirmation({
  content,
  children,
  onConfirm,
  onReject,
  confirmText = 'Yes',
  rejectText = 'Cancel',
  onOpenChange,
  ...rest
}: ConfirmationProps) {
  const [open, setOpen] = useState(false)

  const ref = useRef()

  useEffect(() => {
    onOpenChange && onOpenChange(open)
  }, [onOpenChange, open])

  return (
    <>
      <Popover
        open={open}
        referenceElement={ref}
        onClickOut={() => setOpen(false)}
      >
        {({ arrowProps, update, forceUpdate, ...popper }) => (
          <View
            bg="white"
            border={1}
            borderRadius={1}
            boxShadow={1}
            p={4}
            {...popper}
            {...rest}
            display={
              popper?.['data-popper-reference-hidden'] ? 'none' : undefined
            }
          >
            <Prompt mb={3}>{content}</Prompt>
            <Flex justifyContent="flex-start">
              <Button
                sizeVariant="sm"
                variant="secondary"
                color="red.3"
                onClick={() => {
                  onConfirm && onConfirm()
                  setOpen(false)
                }}
                mr={3}
              >
                {confirmText}
              </Button>
              <Button
                sizeVariant="sm"
                variant="secondary"
                color="ink.2"
                onClick={() => {
                  onReject && onReject()
                  setOpen(false)
                }}
              >
                {rejectText}
              </Button>
            </Flex>
            <Popover.Arrow
              {...arrowProps}
              _before={{ bg: 'white', borderTop: 1, borderLeft: 1 }}
            />
          </View>
        )}
      </Popover>
      {React.cloneElement(children, {
        ref,
        onClick: (e) => {
          setOpen(!open)
          children.props.onClick && children.props.onClick(e)
        },
      })}
    </>
  )
}

export default Confirmation
