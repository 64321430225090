import {
  Caption,
  Flex,
  GridTable,
  GridTableCell,
  GridTableRow,
  Heading,
  Panel,
  Prompt,
  Subheading,
  Subtitle,
  Text,
  Tooltip,
  View,
} from '@tabeo/scarf'
import api from '@tabeo/sharpei/utils/api'
import { format } from '@tabeo/sharpei/utils/currency'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import { feeCalculator, getVisibleNearPrimeProfile } from 'utils/finance'
import formatClinicianName from 'utils/formatClinicianName'
import formatFee from 'utils/formatFee'
import icon from './assets/tooltip-icon.svg'

const secondPayment = moment().add(28, 'days').date()
let desiredCalendarDay
if (secondPayment > 28 || secondPayment < 8) {
  desiredCalendarDay = 8
} else if (secondPayment > 18) {
  desiredCalendarDay = 28
} else {
  desiredCalendarDay = 18
}

const initialValues = {
  treatmentDate: new Date(),
  desiredCalendarDay,
}

const getFirstPaymentDate = treatmentDate => {
  const treatmentMoment = moment(treatmentDate)
  const date = new Date()
  const day = parseInt(date.getUTCDate(), 10)
  const month = parseInt(date.getUTCMonth(), 10)
  const year = `${date.getUTCFullYear()}`
  const nowMoment = moment(Date.UTC(year, month, day))
  const dayDiff = treatmentMoment.diff(nowMoment, 'days')
  if (dayDiff < 7) {
    return treatmentDate
  }
  return treatmentMoment.subtract(7, 'days')
}

class PayOverTimeOverview extends Component {
  state = {
    calculation: {},
    fees: [],
  }

  formValues = {}

  componentDidUpdate(prevProps) {
    const { price, numberOfPayments, possiblePaymentOptions } = this.props
    if (
      (prevProps.price !== price ||
        prevProps.numberOfPayments !== numberOfPayments) &&
      price >= 25000
    ) {
      const fees = possiblePaymentOptions.map(option => {
        return {
          ...feeCalculator(option.profile.name, option, price),
          riskProfileName: option.profile.name,
        }
      })
      this.setState({
        fees,
      })
      this.fetchCalculation()
    }
  }

  handleFormChange = ({ values }) => {
    this.formValues = values
    const { price } = this.props
    if (price >= 25000) {
      this.fetchCalculation()
    }
  }

  fetchCalculation = async () => {
    const { treatmentDate, desiredCalendarDay } = this.formValues
    const { price, numberOfPayments, interestRate, rebateDays, paymentOption } =
      this.props

    const payload = {
      calculation: {
        firstPaymentDate: getFirstPaymentDate(treatmentDate),
        desiredCalendarDay,
        price,
        numberOfPayments,
        interestRate,
        rebateDays,
        maxLoanAmount: price,
        firstPaymentAmount:
          paymentOption.profile?.name === 'tabeo_plus'
            ? parseInt(price * 0.2, 10)
            : undefined,
      },
    }

    try {
      const { calculation } = await api.post(`/utilities/amortize`, payload)
      this.setState({ calculation })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  render() {
    const {
      title,
      otherTitle,
      price,
      sector,
      petName,
      numberOfPayments,
      interestRate,
      rebateDays,
      paymentOption,
      possiblePaymentOptions,
      t,
      merchantClinician,
      ...rest
    } = this.props
    const {
      calculation: {
        monthlyInvoicePaymentAmount,
        totalInterestPaymentAmount,
        totalPaymentAmount,
        firstInvoicePaymentAmount,
      },
      fees,
    } = this.state

    const riskProfileName = paymentOption?.profile?.name
    const isDeferredPaymentPlan = riskProfileName === 'nl_collect'

    if (riskProfileName === 'nl_ifinance') {
      return null
    }

    if (
      !price ||
      price < 25000 ||
      !title ||
      (sector === 'veterinary_services' && !petName) ||
      !riskProfileName
    ) {
      return (
        <Panel
          header={
            <Heading color="ink.2" textAlign="center" flex="1">
              {isDeferredPaymentPlan ? t('Calculator') : t('Loan calculator')}
            </Heading>
          }
        >
          <View bg="white" p={7}>
            <Heading textAlign="center" color="ink.2">
              {isDeferredPaymentPlan
                ? t(
                    'Start typing in the Transaction section on the left to calculate the preview'
                  )
                : t(
                    'Start typing in the Transaction section on the left to calculate the loan preview'
                  )}
            </Heading>
          </View>
        </Panel>
      )
    }

    return (
      <Panel
        header={
          <>
            <Heading color="ink.2" textAlign="center" flex="1">
              {isDeferredPaymentPlan ? t('Calculator') : t('Loan calculator')}
            </Heading>
            <Tooltip
              component={
                <TooltipContent
                  options={fees}
                  price={price}
                  loanOption={paymentOption}
                />
              }
              tippyPopperStyle={{ maxWidth: 'none' }}
            >
              <View as="img" src={icon} cursor="pointer" />
            </Tooltip>
          </>
        }
        mb={6}
        {...rest}
        data-testid="loan-calculator"
      >
        <Form onSubmit={() => {}} initialValues={initialValues}>
          {() => {
            return (
              <View bg="white">
                <FormSpy
                  onChange={this.handleFormChange}
                  subscription={{ values: true }}
                />
                <Flex
                  py={5}
                  px={7}
                  borderBottom={1}
                  alignItems="flex-start"
                  flexDirection="column"
                  justifyContent="flex-start"
                  data-testid="loan-item"
                >
                  <View>
                    <Subtitle>
                      {sector === 'veterinary_services'
                        ? t(`{{petName}}'s {{treatmentName}}`, {
                            petName,
                            treatmentName: title,
                          })
                        : otherTitle || title}
                    </Subtitle>
                    {merchantClinician && (
                      <Text color="ink.3">
                        {t('With {{name}}', {
                          name: formatClinicianName(merchantClinician),
                        })}
                      </Text>
                    )}
                  </View>
                  <Subtitle
                    fontWeight="semibold"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {format(price)}
                  </Subtitle>
                </Flex>
                <View
                  py={5}
                  px={7}
                  borderBottom={1}
                  data-testid="price-per-month"
                >
                  <Prompt mb={1}>{t('Your customer would pay')}</Prompt>
                  {isDeferredPaymentPlan ? (
                    <Heading fontWeight="semibold">
                      {t('{{numberOfPayments}} payments of {{amount}}', {
                        amount: format(monthlyInvoicePaymentAmount),
                        numberOfPayments,
                      })}
                    </Heading>
                  ) : (
                    <Heading fontWeight="semibold">
                      {t(
                        '{{amount}} per month for {{numberOfPayments}} months',
                        {
                          amount: format(monthlyInvoicePaymentAmount),
                          numberOfPayments,
                        }
                      )}
                    </Heading>
                  )}
                  {riskProfileName === 'tabeo_plus' && (
                    <Caption mt={2}>
                      First payment of {format(firstInvoicePaymentAmount)} then{' '}
                      {numberOfPayments - 1} payments of{' '}
                      {format(monthlyInvoicePaymentAmount)}
                    </Caption>
                  )}
                </View>
                <View py={5} px={7} borderBottom={1}>
                  <Prompt mb={1}>
                    {isDeferredPaymentPlan
                      ? t('The cost of credit to your patient would be')
                      : t('The loan would cost an interest of')}
                  </Prompt>
                  <Heading
                    fontWeight="semibold"
                    mb={4}
                    data-testid="loan-interest"
                  >
                    {t(
                      '{{totalInterestPaymentAmount}} ({{interestRate}}% APR)',
                      {
                        totalInterestPaymentAmount: format(
                          totalInterestPaymentAmount
                        ),
                        interestRate: (interestRate * 100).toFixed(1),
                      }
                    )}
                  </Heading>
                  <Prompt mb={1}>
                    {t('This means the total to pay back would be')}
                  </Prompt>
                  <Heading
                    fontWeight="semibold"
                    data-testid="total-payment-amount"
                  >
                    {format(totalPaymentAmount)}
                  </Heading>
                </View>
                {rebateDays > 0 && (
                  <View py={5} px={7}>
                    <Prompt mb={1}>
                      {t(
                        'The total to pay back if your customer repays in full within 12 months would be'
                      )}
                    </Prompt>
                    <Heading fontWeight="semibold" mb={3}>
                      {format(price)}
                    </Heading>
                    <Caption>
                      {t(
                        'If the customer repays in full within the first 12 months the lender will offer a rebate for the interest accrued so that APR is equivalent to 0.0%'
                      )}
                    </Caption>
                  </View>
                )}
              </View>
            )
          }}
        </Form>
      </Panel>
    )
  }
}

PayOverTimeOverview.propTypes = {
  title: PropTypes.string,
  otherTitle: PropTypes.string,
  price: PropTypes.number,
  sector: PropTypes.string,
  petName: PropTypes.string,
  numberOfPayments: PropTypes.number,
  interestRate: PropTypes.number,
  rebateDays: PropTypes.number,
  paymentOption: PropTypes.object,
  possiblePaymentOptions: PropTypes.array,
  t: PropTypes.func,
  merchantClinician: PropTypes.any,
}

PayOverTimeOverview.defaultProps = {
  title: '',
  price: 0,
  numberOfPayments: 12,
  interestRate: 0,
  rebateDays: 0,
}

export default function (props) {
  const { t } = useTranslation()
  return <PayOverTimeOverview {...props} t={t} />
}

export const riskProfileLabels = {
  default: 'Tabeo Core',
  near_prime: 'Near Prime',
  in_house: 'In-house',
  it_core: 'Tabeo Core',
  fr_core: 'Tabeo Core',
  fr_sdc_core: 'Tabeo Core',
  tabeo_plus: 'Tabeo Plus',
  profile_sdc_unfunded: 'Collect',
  plusdent_unfunded: 'Collect',
  diamondwhites_unfunded: 'Collect',
  impress_unfunded: 'Collect',
  vinci_unfunded: 'Collect',
  uk_pl_unfunded: 'Collect',
  smilewhites_unfunded: 'Collect',
  nl_collect: 'Collect',
  it_collect: 'Collect',
  fr_collect: 'Collect',
  fr_sdc_collect: 'Collect',
}

const TooltipContent = ({ options = [], price, loanOption }) => {
  const { t } = useTranslation()
  const { data } = useMerchant()

  const nearPrimeProfile = data?.merchantRiskProfiles.find(
    p => p.name === 'near_prime'
  )

  const nearPrimeProfileOptions =
    nearPrimeProfile?.loanOptions.map(o => ({
      ...o,
      ...feeCalculator('near_prime', o, price),
      riskProfileName: 'near_prime',
    })) || []

  const visibleNearPrimeOption = getVisibleNearPrimeProfile(
    loanOption,
    nearPrimeProfileOptions
  )

  return (
    <View textAlign="left">
      <Prompt color="default.primary" mb={3}>
        {t('Subsidy fee calculator')}
      </Prompt>
      <GridTable
        templateColumns="auto auto auto auto"
        mx="-16px"
        width="auto"
        data-testid="subsidy-fee-table"
      >
        <GridTableRow>
          <GridTableCell py={1} pl={0}>
            <Subheading color="ink.2">{t('Product')}</Subheading>
          </GridTableCell>
          <GridTableCell py={1}>
            <Subheading color="ink.2">{t('APR')}</Subheading>
          </GridTableCell>
          <GridTableCell py={1}>
            <Subheading color="ink.2">{t('Term')}</Subheading>
          </GridTableCell>
          <GridTableCell py={1} pr={0}>
            <Subheading color="ink.2" textAlign="right">
              {t('Subsidy fee')}
            </Subheading>
          </GridTableCell>
        </GridTableRow>
        {[
          ...(options || []),
          ...(visibleNearPrimeOption ? [visibleNearPrimeOption] : []),
        ].map(o => {
          let label = o.isRegulated ? 'Regulated' : 'Unregulated'
          if (o.riskProfileName === 'near_prime') {
            label = 'Near Prime'
          }

          return (
            <GridTableRow
              key={o.numberOfMonths + o.feeAmount + o.riskProfileName}
              data-testid="table-row"
            >
              <GridTableCell py={1} pl={0}>
                <Caption color="ink.0" data-testid="risk-profile-cell">
                  {label}
                </Caption>
              </GridTableCell>
              <GridTableCell py={1}>
                <Caption color="ink.0" data-testid="interest-rate-cell">
                  {(o.interestRate * 100).toFixed(1)}%
                </Caption>
              </GridTableCell>
              <GridTableCell py={1}>
                <Caption color="ink.0" data-testid="number-of-months-cell">
                  {t('{{numberOfMonths}} months', {
                    numberOfMonths: o.numberOfMonths,
                  })}
                </Caption>
              </GridTableCell>
              <GridTableCell py={1} pr={0}>
                <Caption
                  color="ink.0"
                  textAlign="right"
                  data-testid="subside-fee-cell"
                >
                  {o.vat ? (
                    <span>
                      {format(o.feeAmount)} ({formatFee(o.netFee)}% + {t('VAT')}
                      )
                    </span>
                  ) : (
                    <span>
                      {format(o.feeAmount)} ({formatFee(o.fee)} %)
                    </span>
                  )}
                </Caption>
              </GridTableCell>
            </GridTableRow>
          )
        })}
      </GridTable>
    </View>
  )
}

TooltipContent.propTypes = {
  options: PropTypes.array,
  price: PropTypes.number,
  loanOption: PropTypes.object,
}
