import {
  Caption as BaseCaption,
  Container,
  Flex,
  Prompt,
  propTypes,
  Subheading,
  Subtitle,
  themeGet,
  Title,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import LoadingScreen from 'components/LoadingScreen'
import Nav from 'components/Nav'
import useCurrency from 'hooks/useCurrency'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useOffer } from 'resources/Offer'
import styled, { createGlobalStyle } from 'styled-components'
import formatClinicianName from 'utils/formatClinicianName'
import { useMerchant } from '../../resources/Merchant'

// eslint-disable-next-line

const GlobalStyle = createGlobalStyle`
@media print {
  *{
    font-family: ${themeGet('font', 'arial')} !important;
    -webkit-print-color-adjust: exact;
  }
  #navbar {
    display: none;
  }
  #print-container{
    background-color: white !important;
  }
}
`

const Caption = styled(BaseCaption)``

Caption.defaultProps = {
  fontWeight: 'regular',
  color: 'black',
}

const InvoiceContainer = styled(Container)`
  @media print {
    background-color: white !important;
  }
`

const Table = styled(View)`
  overflow: hidden;
`

Table.defaultProps = {
  border: '1px solid #b2b2b2',
  borderRadius: 1,
}

const TableRow = styled(Flex)`
  &:last-of-type {
    border-bottom: none;
  }
`

TableRow.defaultProps = {
  p: '6px 12px',
  borderBottom: '1px solid #b2b2b2',
}

function BeforeAfter({ before, after, ...rest }) {
  return (
    <View display="inline-flex" justifyContent="flex-end">
      {after && (
        <Caption color="ink.2" textDecoration="line-through" mr={1}>
          {before}
        </Caption>
      )}
      <Caption {...rest}>{after || before}</Caption>
    </View>
  )
}

BeforeAfter.propTypes = {
  before: propTypes.children,
  after: propTypes.children,
}

function Invoice() {
  const { t } = useTranslation()

  const labels = {
    service: {
      saleTitle: t('Procedure'),
      itemTitle: t('Procedure'),
      person: t('Performed by'),
      date: t('Procedure date'),
    },
    good: {
      saleTitle: t('Sale Details'),
      itemTitle: t('Item'),
      person: t('Sold by'),
      date: t('Sale date'),
    },
  }

  React.useEffect(() => {
    setTimeout(() => {
      window.print()
    }, 200)
  }, [])

  const {
    data: {
      bankDetails: { accountNumber },
    },
  } = useMerchant()
  const { data } = useOffer()
  const { formatDivisional } = useCurrency()

  if (!data) {
    return <LoadingScreen />
  }
  const {
    merchantPayoutAt,
    title,
    merchant,
    consumerFirstName,
    consumerLastName,
    consumer,
    consumerEmail,
    id,
    price,
    feeAmount,
    metadata,
    productType,
    isPN,
    isTabeoCollect,
    isTabeoPlus,
    isMerchantPartLender,
    consumerHisHer,
    type,
    refunds,
    feePercent,
    netProceeds,
    revisedNetProceeds,
    revisedPrice,
    revisedRequiredMerchantFeeAmount,
    merchantNetProceedsTransfers,
    valueAtRisk,
    valueAtRiskNetOfMerchantRefunds,
    sumMerchantNetProceedsTransfers,
    merchantClinician,
    individual,
    fulfillment,
  } = data || {}

  const { date: appointmentDate } = metadata?.appointment || {}
  const { name } = individual || {}
  const { startedAt } = fulfillment || {}

  const actor = merchantClinician
    ? formatClinicianName(merchantClinician)
    : name
  const date = startedAt || appointmentDate

  const isRefunded = !!refunds.length

  const hisHer =
    consumerHisHer === 'his'
      ? t(`Customer changed his mind`)
      : t(`Customer changed her mind`)
  const refundReasons = {
    mind_change: hisHer,
    lower_cost: t('Cost was lower than expected'),
    dissatisfaction:
      productType === 'good'
        ? t(`Customer was unhappy with the product.`)
        : t('Customer was unhappy with the service.'),
  }

  const retentionText = refund =>
    t(
      ` The customer was still charged {{amount}} because “{{refundReason}}”.`,
      {
        amount: format(price - refund.originalPayInAmount),
        refundReason: refund.retentionReason,
      }
    )

  const subsidySuffix = isPN
    ? `(1% + ${formatDivisional(20)})`
    : type === 'bill'
    ? '(1.9%)'
    : `(${(feePercent * 100).toFixed(1)}%)`
  return (
    <>
      <GlobalStyle />
      <Nav />
      <InvoiceContainer
        display="flex"
        width="21cm"
        wrapProps={{
          id: 'print-container',
          style: {
            height: 'auto',
            minHeight: '100%',
          },
        }}
      >
        <View p={5} flex="1" bg="white" pb="38px">
          <Caption>{moment().format('D MMMM YYYY')}</Caption>

          <Flex alignItems="flex-start" mb="10px">
            <View>
              <Title fontWeight="semibold">{title}</Title>
              <Subtitle>{merchant.tradingName}</Subtitle>
            </View>
            <View textAlign="right">
              <Title fontWeight="semibold">
                {format(isRefunded ? netProceeds : revisedNetProceeds)}
              </Title>
            </View>
          </Flex>

          <View>
            <Subheading>{t('Transaction id')}</Subheading>
            <Caption>{id}</Caption>
          </View>

          <View textAlign="right" width="345px" ml="auto">
            <Prompt fontWeight="semibold">{merchant.tradingName}</Prompt>
            <Caption>{merchant.phoneNumber}</Caption>
            <Caption>{merchant.tradingAddress}</Caption>
          </View>

          <View mb={5}>
            <Subheading mb={2}>{t('Customer')}</Subheading>
            <Table>
              <TableRow>
                <Caption color="ink.2">{t('Name:')}</Caption>
                <Caption>
                  {consumerFirstName} {consumerLastName}
                </Caption>
              </TableRow>
              {consumer?.mobilePhone && (
                <TableRow>
                  <Caption color="ink.2">{t('Phone:')}</Caption>
                  <Caption data-no-track>{consumer.mobilePhone}</Caption>
                </TableRow>
              )}
              <TableRow>
                <Caption color="ink.2">{t('Email:')}</Caption>
                <Caption data-no-track>{consumerEmail}</Caption>
              </TableRow>
              {consumer?.address && (
                <TableRow>
                  <Caption color="ink.2">{t('Address:')}</Caption>
                  <Caption data-no-track>{consumer.address}</Caption>
                </TableRow>
              )}
            </Table>
          </View>

          <View mb={5}>
            <Subheading mb={2}>{labels[productType].saleTitle}</Subheading>
            <Table>
              <TableRow>
                <Caption color="ink.2">
                  {labels[productType].itemTitle}:
                </Caption>
                <Caption>{title}</Caption>
              </TableRow>
              {actor && (
                <TableRow>
                  <Caption color="ink.2">{labels[productType].person}:</Caption>
                  <Caption>{actor}</Caption>
                </TableRow>
              )}
              {date && (
                <TableRow>
                  <Caption color="ink.2">{labels[productType].date}:</Caption>
                  <Caption>{moment(date).format('D MMMM YYYY')}</Caption>
                </TableRow>
              )}
            </Table>
          </View>

          <View mb={5}>
            <Subheading mb={2}>{t('Summary')}</Subheading>
            <Table>
              <TableRow>
                <Caption color="ink.2">{t('Subtotal:')}</Caption>
                <BeforeAfter
                  before={format(price)}
                  after={isRefunded && format(revisedPrice)}
                />
              </TableRow>
              <TableRow>
                <Caption color="ink.2">{t('Subsidy fee:')}</Caption>
                <View display="inline-flex">
                  <BeforeAfter
                    before={format(feeAmount)}
                    after={
                      isRefunded && format(revisedRequiredMerchantFeeAmount)
                    }
                  />
                  <Caption ml={1}>{subsidySuffix}</Caption>
                </View>
              </TableRow>
              {isTabeoCollect && (
                <TableRow>
                  <Caption color="ink.2">{t('Received so far:')}</Caption>
                  <Caption>{format(sumMerchantNetProceedsTransfers)}</Caption>
                </TableRow>
              )}
              {isTabeoPlus && merchantNetProceedsTransfers?.[0] && (
                <TableRow>
                  <Caption color="ink.2">{t('Loan advance:')}</Caption>
                  <Caption>
                    {format(merchantNetProceedsTransfers?.[0]?.amount)}
                  </Caption>
                </TableRow>
              )}
              {isMerchantPartLender && !!valueAtRisk && (
                <TableRow>
                  <Caption color="ink.2">{t('Value at risk:')}</Caption>
                  <BeforeAfter
                    before={format(valueAtRisk)}
                    after={
                      isRefunded && format(valueAtRiskNetOfMerchantRefunds)
                    }
                  />
                </TableRow>
              )}
              <TableRow bg="white">
                <Caption color="ink.2">
                  {isMerchantPartLender
                    ? t(`Max. net proceeds:`)
                    : t(`Net proceeds:`)}
                </Caption>
                {feeAmount !== null ? (
                  <BeforeAfter
                    fontWeight="semibold"
                    before={format(netProceeds)}
                    after={isRefunded && format(revisedNetProceeds)}
                  />
                ) : (
                  <Caption>{t('Not availale yet')}</Caption>
                )}
              </TableRow>
            </Table>
            {refunds.map(refund => (
              <Caption color="ink.2" textAlign="left" key={refund.id} mt={3}>
                {t(
                  `Refunded on {{date}} because “{{refundReason}}".{{retentionReason}}`,
                  {
                    date: moment(refund.scheduledAt).format('DD MMM YYYY'),
                    refundReason:
                      refundReasons[refund.refundReason] || refund.refundReason,

                    retentionReason: refund.retentionReason
                      ? retentionText(refund)
                      : '',
                  }
                )}
              </Caption>
            ))}
          </View>

          <View mb={5}>
            <Subheading mb={2}>{t('Payment')}</Subheading>
            <Table>
              {merchantPayoutAt && (
                <TableRow>
                  <Caption color="ink.2">{t('Settlement date:')}</Caption>
                  <Caption>
                    {moment(merchantPayoutAt).format('D MMMM YYYY')}
                  </Caption>
                </TableRow>
              )}
              <TableRow>
                <Caption color="ink.2">{t('Transfer from:')}</Caption>
                <Caption>
                  {t('Tabeo Broker Limited, 02031233, 40-29-12')}
                </Caption>
              </TableRow>
              <TableRow>
                <Caption color="ink.2">{t('Beneficiary account:')}</Caption>
                <Caption>
                  *****
                  {accountNumber.slice(-3)}
                </Caption>
              </TableRow>
              <TableRow>
                <Caption color="ink.2">{t('Reference:')}</Caption>
                <Caption>{t('TABEO')}</Caption>
              </TableRow>
            </Table>
          </View>

          <Caption
            lineHeight="auto"
            fontSize="12px"
            fontWeight="light"
            color="ink.1"
          >
            {t(
              'Tabeo Broker Limited is Incorporated in England & Wales, registration number 10416530. Registered address C/O Fox Williams, 10 Finsbury Square, Finsbury, London EC2A 1AF. Authorised and regulated by the FCA, Reference number (FRN): 777539.'
            )}
          </Caption>
        </View>
      </InvoiceContainer>
    </>
  )
}

export default Invoice
