import { TerminalPayment } from '@tabeo/ts-types'
import { NormalizedTerminalPayment } from 'types/NormalizedTerminalPayment'
import checkoutPaymentNormalizer from './checkoutPayment'

const normalize = (
  terminalPayment: TerminalPayment
): NormalizedTerminalPayment => {
  const checkoutPayment = checkoutPaymentNormalizer(terminalPayment)

  return {
    ...terminalPayment,
    ...checkoutPayment,
    _type: 'terminal_payment',
  }
}

export default normalize
