import { Flex, Icons, Select, Text } from '@tabeo/scarf'
import Input from '@tabeo/scarf/dist/components/Form/Input'
import { ClinicianOptionWithoutBoardIds } from 'pages/Settings/People/Clinicians/Modal/ClinicianOption'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useMerchant } from 'resources/Merchant'
import formatClinicianName from 'utils/formatClinicianName'

const isActivated = ({ status }: any) => status === 'activated'

export const clinicianSort = (a: any, b: any) => {
  if (a.usage === b.usage) {
    const aName = formatClinicianName(a)
    const bName = formatClinicianName(b)

    return aName.localeCompare(bName)
  }

  return b.usage - a.usage
}

type MerchantClinicianSelectProps = {
  input?: Input
} & React.ComponentProps<typeof Select>

const MerchantClinicianSelect = React.forwardRef<
  any,
  MerchantClinicianSelectProps
>(({ input, ...rest }: any, ref) => {
  const { data } = useMerchant()
  const { t } = useTranslation()
  const history = useHistory()

  if (!data) return null

  const { merchantClinicians } = data

  return (
    <Select
      ref={ref}
      loadOptions={async (q: string) => {
        const options = (
          q
            ? merchantClinicians
                .sort(clinicianSort)
                .filter(isActivated)
                .filter(
                  ({ clinician: { firstName, lastName } }: any) =>
                    firstName.toLowerCase().includes(q.toLowerCase()) ||
                    lastName.toLowerCase().includes(q.toLowerCase())
                )
            : merchantClinicians.sort(clinicianSort).filter(isActivated)
        ).map((mc: any) => ({
          value: mc,
          disabled: mc?.clinician?.status === 'suspended',
        }))

        return { options: [...options, { value: 'new' }] }
      }}
      valueRenderer={(mc: any) => formatClinicianName(mc)}
      optionComponent={ClinicianOptionWithoutBoardIds}
      autoload
      clearable
      clearRenderer={() => (
        <Icons.Clear mr={2} height="20px" width="20px" fill="ink.3" />
      )}
      placeholder={
        <Flex justifyContent="flex-start">
          <Icons.Search fill="#919EAB" mr={2} ml="-2px" />
          <Text color="ink.2">{t('Select from the list…')}</Text>
        </Flex>
      }
      selectMenuStyle={{
        maxHeight: '352px',
      }}
      {...rest}
      input={{
        ...input,
        onChange: (v: any) => {
          if (v === 'new') {
            history.push('/settings?tab=people&modal=new-clinician')
            return
          }

          return input.onChange(v)
        },
      }}
    />
  )
})

export default MerchantClinicianSelect
