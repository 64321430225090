import { Flex, Heading, Icons, Subtitle, Text, View } from '@tabeo/scarf'
import Dot from 'components/Dot'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import placeholderIcon from './idle.svg'

const { Tick } = Icons

const Price = ({ amount, ...rest }) => {
  const amountStr = `${amount}`
  const integer = amountStr.slice(0, -2)
  const decimal = amountStr.slice(-2)
  const { t } = useTranslation()
  return (
    <View
      display="inline-flex"
      alignItems="baseline"
      justifyContent="flex-start"
      {...rest}
    >
      <View>
        <Text fontSize="30px">{t('£')}</Text>
      </View>
      <View>
        <Text fontSize="64px" fontWeight="semibold">
          {integer}
        </Text>
      </View>
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignSelf="center"
      >
        <Text fontSize="32px" lineHeight="32px">
          .{decimal}
        </Text>
        <Text
          fontSize="10px"
          lineHeight="24px"
          letterSpacing="2.4px"
          fontWeight="semibold"
        >
          {t('/MONTH')}
        </Text>
      </Flex>
    </View>
  )
}

Price.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

const Icon = styled(View)`
  width: 180px;
`

Icon.defaultProps = {
  as: 'img',
  mx: 'auto',
  alt: 'icon',
}

const Section = styled(Heading)`
  letter-spacing: 2.4px;
  text-transform: uppercase;
`

Section.defaultProps = {
  color: 'ink.2',
}

const BreakLine = styled(View)`
  white-space: pre-line;
`

const Overview = props => {
  const { t } = useTranslation()
  const {
    title = t('Plan name'),
    merchantName = t('Name of your business'),
    subtitle = t('Short description'),
    description = t('Long description'),
    imageURL,
    price,
    planFeatures,
    planPerks,
    showPlaceholders,
    petName,
  } = props
  const isNoImageSelected = imageURL && imageURL.indexOf('no-image') > -1

  const planTitlePrefix = petName ? `${petName}'s ` : ''
  const planTitle = planTitlePrefix + title

  return (
    <View bg="white" pt={7} pb={5} px={5} textAlign="center">
      <Subtitle mb={3}>{merchantName}</Subtitle>
      {(imageURL || showPlaceholders) && !isNoImageSelected && (
        <Icon src={imageURL || placeholderIcon} mx="auto" mb={6} />
      )}
      <Subtitle mb={3} fontWeight="semibold">
        {planTitle}
      </Subtitle>
      <Heading color="ink.2" mb={6}>
        <BreakLine>{subtitle}</BreakLine>
      </Heading>
      <Price amount={price} mb={6} mx="auto" />
      {!!planFeatures.length && (
        <Section mb={4}>{t('Included treatments')}</Section>
      )}
      {planFeatures.length ? (
        planFeatures.map(f => (
          <Flex
            key={`${f.id}-${f.title}`}
            bg="sky.2"
            p="10px"
            justifyContent="flex-start"
            borderRadius="5px"
            mb={4}
          >
            <Tick light mr={2} />
            <Heading textAlign="left">
              {!!f.claimsLimit && `${f.claimsLimit}x `}
              {f.title}
            </Heading>
          </Flex>
        ))
      ) : showPlaceholders ? (
        <Heading
          textAlign="center"
          color="ink.2"
          bg="sky.2"
          p={3}
          borderRadius={1}
          mb={7}
        >
          {t('No treatments selected')}
        </Heading>
      ) : null}
      {(!!planPerks.length || showPlaceholders) && (
        <Section mb={4}>
          {planFeatures.length ? t('Additional perks') : t('Perks')}
        </Section>
      )}
      {planPerks.length ? (
        planPerks.map(p => (
          <Flex
            key={`${p.id}-${p.title}`}
            bg="sky.2"
            p="10px"
            justifyContent="flex-start"
            alignItems="flex-start"
            borderRadius="5px"
            mb={4}
          >
            <Dot />
            <Heading textAlign="left">
              {!!p.claimsLimit && `${p.claimsLimit}x `}
              {p.title}
            </Heading>
          </Flex>
        ))
      ) : showPlaceholders ? (
        <Heading
          textAlign="center"
          color="ink.2"
          bg="sky.2"
          p={3}
          borderRadius={1}
          mb={7}
        >
          {t('No additional perks selected')}
        </Heading>
      ) : null}
      <Section mt={7} mb={4}>
        {t('ABOUT THIS PLAN')}
      </Section>
      <Text>
        <BreakLine>{description}</BreakLine>
      </Text>
    </View>
  )
}

Overview.propTypes = {
  imageURL: PropTypes.string,
  title: PropTypes.string,
  merchantName: PropTypes.string,
  subtitle: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  planFeatures: PropTypes.array,
  planPerks: PropTypes.array,
  description: PropTypes.string,
  showPlaceholders: PropTypes.bool,
  petName: PropTypes.string,
}

Overview.defaultProps = {
  // title: 'Plan name',
  // merchantName: 'Name of your business',
  // subtitle: 'Short description',
  // description: 'Long description',
  price: '000',
  planFeatures: [],
  planPerks: [],
}

export default Overview
