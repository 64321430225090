import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { linkify } from '../../Comments/Comment'
import Text, { BaseProps } from '../../Text'

const Content = styled(Text)`
  white-space: pre-line;

  /* Forcing break */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
`
Content.defaultProps = {
  fontSize: 1,
  p: 3,
  borderRadius: 1,
  bg: 'sky.2',
}

function Comment({ children, ...rest }: BaseProps) {
  return <Content {...rest}>{linkify(children)}</Content>
}

Comment.propTypes = {
  children: PropTypes.any,
}

export default Comment
