import styled from 'styled-components'
import variants from '../../../../utils/variants'
import TextareaAutosize from '../../../Form/Textarea/TextareaAutosize'

export type TitleProps = {
  variant?: 'filled' | 'transparent'
}

const Title = styled(TextareaAutosize)<TitleProps>`
  ${variants({
    variant: {
      filled: {},
      transparent: {
        '&:not(:focus):not(:hover)': {
          bg: 'white',
          borderColor: 'transparent',
        },
      },
    },
  })}
`

Title.defaultProps = {
  minRows: 1,
  maxRows: Infinity,
  maxLength: 100,
  width: '100%',
  fontSize: ['22px', '18px'],
  placeholder: 'Enter a title…',
  variant: 'transparent',
  fontWeight: 'semibold',
}

export default Title
