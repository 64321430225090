import React from 'react'
import ScarfProps from '../../types/ScarfProps'
import Flex from '../Flex'
import View from '../View'
import { ReactComponent as ArrowLeft } from './assets/arrow-left.svg'
import Item from './Item'

export type BreadcrumbProps = {
  onBackClick: () => void
} & ScarfProps

function Breadcrumb({ onBackClick, children, ...rest }: BreadcrumbProps) {
  const items = React.Children.toArray(children)

  return (
    <View
      as="nav"
      display="flex"
      bg="white"
      border={1}
      borderColor="sky.0"
      boxShadow={2}
      borderRadius={1}
      width="fit-content"
      overflow="hidden"
      {...rest}
    >
      <Flex
        borderRight={1}
        borderColor="sky.0"
        p={2}
        color="ink.0"
        transition="0.2s background"
        _hover={{ bg: 'sky.3' }}
        cursor="pointer"
        onClick={() => onBackClick()}
      >
        <View as={ArrowLeft} />
      </Flex>
      <View as="ol" display="flex" bg="sky.0">
        {items.map((item, i) =>
          // @ts-ignore
          React.cloneElement(item, {
            isLast: i === items.length - 1,
            isFirst: i === 0,
          })
        )}
      </View>
    </View>
  )
}

Breadcrumb.Item = Item
export default Breadcrumb
