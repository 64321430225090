import { useEffect, useState } from 'react'

function useTimeout(ms = 1000) {
  const [didTimeout, setDidTimeout] = useState(false)
  useEffect(() => {
    const currentTimer = setTimeout(() => {
      setDidTimeout(true)
    }, ms)
    return () => {
      clearTimeout(currentTimer)
    }
  }, [ms])
  return didTimeout
}

export default useTimeout
