import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import { useRouteMatch } from 'react-router-dom'
import { NormalizedLinkPayment } from 'types/NormalizedLinkPayment'
import normalize from './normalizers/linkPayment'

interface Params {
  id: string | number
}

const subResources = formatSubresources([
  'basket.patient',
  'basket.merchantTreatment.treatment',
  'basket.merchantClinician.clinician',
  'charges',
  'events',
  'payouts',
])
class LinkPayment extends Resource<NormalizedLinkPayment, Params> {
  getInitialConfig() {
    return {
      refreshInterval: 30 * 1000,
    }
  }

  async fetch() {
    const transfersConditions = {
      type: 'merchant_proceed',
      owner_type: 'link_payments',
      owner_id: this.params.id,
      status: 'succeeded',
    }

    const [{ linkPayment }, { transfers }, { refunds }] = await Promise.all([
      api.get(`/merchant/link-payments/${this.params.id}?${subResources}`),
      api.get(
        `/merchant/transfers?jsonConditions=${encodeURIComponent(
          JSON.stringify(transfersConditions)
        )}&subResource=payout`
      ),
      api.get(
        `/merchant/link-payments/${
          this.params.id
        }/refunds?&order=createdAt:desc&limit=1000&${formatSubresources([
          'requester',
          'approver',
          'decliner',
          'canceler',
        ])}`
      ),
    ])

    this.data = normalize({
      ...linkPayment,
      transfers,
      refunds,
    })

    return this.data
  }

  sendLink = async (payload: {
    email?: string
    phoneNumber?: string
    isReminder?: boolean
  }) => {
    const response = await api.put(
      `/merchant/link-payments/${this.params.id}/send`,
      payload
    )

    await this.fetch()
    return response
  }

  cancel = async () => {
    const response = await api.put(
      `/merchant/link-payments/${this.params.id}/cancel`
    )

    await this.fetch()
    return response
  }

  sendReceipt = async (payload: { email?: string; phoneNumber?: string }) => {
    const response = await api.put(
      `/merchant/link-payments/${this.params.id}/receipt/send`,
      payload
    )

    await this.fetch()
    return response
  }

  requestRefund = async (payload: {
    amount: number
    reasonType: string
    reasonNote?: string
  }) => {
    const response = await api.post(
      `/merchant/link-payments/${this.params.id}/refund-requests`,
      payload
    )

    await this.fetch()
    return response
  }
}

export default LinkPayment
export const useResource = createResourceHook(LinkPayment)

export function useLinkPayment(p?: Params | (() => Params)) {
  const match = useRouteMatch<{
    linkPaymentId?: string
  }>({
    path: ['/link-payments/:linkPaymentId'],
  })

  let params: Params | undefined
  if (p instanceof Function) {
    params = p()
  } else {
    params = p
  }

  const { id } = params || {}
  const {
    params: { linkPaymentId },
  } = match || { params: {} }

  const linkPaymentIdParam = id || linkPaymentId

  return useResource(() => {
    if (!linkPaymentIdParam) {
      throw new Error('LinkPayment ID is null')
    }
    return {
      id: Number.isNaN(Number(linkPaymentIdParam))
        ? linkPaymentIdParam
        : +linkPaymentIdParam,
    }
  })
}
