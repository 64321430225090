import React from 'react'
import styled from 'styled-components'

import Svg, { SvgProps } from './Base'

const Alert = (props: SvgProps) => (
  <Svg {...props}>
    <g>
      <path d="M11 19a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-13a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V7a1 1 0 0 0-1-1zm0 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
    </g>
  </Svg>
)

export default styled(Alert)``
