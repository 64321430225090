import i18n from 'i18next'
import deDE from './locales/de-DE/translation.json'
import enGb from './locales/en-GB/translation.json'
import esES from './locales/es-ES/translation.json'
import frFR from './locales/fr-FR/translation.json'
import itIT from './locales/it-IT/translation.json'
import nlNL from './locales/nl-NL/translation.json'

if (!i18n.isInitialized) {
  i18n.init({
    fallbackLng: 'en-GB',
    supportedLngs: [
      'en',
      'en-GB',
      'nl',
      'nl-NL',
      'it',
      'it-IT',
      'fr',
      'fr-FR',
      'es',
      'es-ES',
      'de',
      'de-DE',
    ],
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    nsSeparator: false,
    debug: false,
    react: {
      useSuspense: false,
      transKeepBasicHtmlNodesFor: [],
    },
    initImmediate: false,
  })
}

if (i18n.isInitialized) {
  i18n.addResourceBundle('en-GB', 'translation', enGb, true, true)
  i18n.addResourceBundle('nl-NL', 'translation', nlNL, true, true)
  i18n.addResourceBundle('it-IT', 'translation', itIT, true, true)
  i18n.addResourceBundle('fr-FR', 'translation', frFR, true, true)
  i18n.addResourceBundle('es-ES', 'translation', esES, true, true)
  i18n.addResourceBundle('de-DE', 'translation', deDE, true, true)
}

export default i18n

export const t = i18n.t.bind(i18n)
