import React, { useEffect, useCallback } from 'react'
import Textarea, { TextareaProps } from '.'

function getLineHeight(el: HTMLTextAreaElement) {
  let lineHeight
  // @ts-ignore
  if (el.currentStyle) {
    // @ts-ignore
    lineHeight = el.currentStyle['line-height']
    // @ts-ignore
  } else if (window.getComputedStyle)
    // @ts-ignore
    lineHeight = document.defaultView
      .getComputedStyle(el, null)
      .getPropertyValue('line-height')
  lineHeight = Number(lineHeight.replace('px', ''))

  return lineHeight
}

export type TextareaAutosizeProps = TextareaProps & {
  minRows?: number
  maxRows?: number
}

const TextareaAutosize = React.forwardRef<
  HTMLTextAreaElement,
  TextareaAutosizeProps
  // eslint-disable-next-line react/prop-types
>(({ input, minRows = 1, maxRows = Infinity, ...rest }, ref) => {
  const inputRef = React.useMemo(() => {
    if (ref) {
      return ref as React.MutableRefObject<HTMLTextAreaElement>
    }

    return React.createRef<HTMLTextAreaElement>()
  }, [ref])

  const resize = useCallback(() => {
    const textarea = inputRef.current

    if (textarea) {
      const lineHeight = getLineHeight(textarea)

      textarea.rows = minRows
      const currentRows = Math.floor(textarea.scrollHeight / lineHeight)

      textarea.rows = Math.min(Math.max(currentRows, minRows), maxRows)
    }
  }, [inputRef, minRows, maxRows])

  useEffect(() => {
    resize()

    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [resize])

  // eslint-disable-next-line react/prop-types
  const value = input?.value ?? ''

  useEffect(() => {
    resize()
  }, [resize, value])

  return (
    <Textarea
      ref={inputRef}
      minHeight={null}
      rows={minRows}
      {...input}
      {...rest}
    />
  )
})

export default TextareaAutosize
