import { Modal, View } from '@tabeo/scarf'
import React from 'react'
import { useSubscriptionV2 } from 'resources/SubscriptionV2'
import BulkTransfer from './BulkTransfer'
import Default from './Default'

export type CancelModalProps = React.ComponentProps<typeof Modal>

const CancelModal = React.forwardRef(
  ({ ...rest }: CancelModalProps, ref: any) => {
    const { data } = useSubscriptionV2()

    const status = data?.subscription?.status

    return (
      <Modal ref={ref}>
        {({ isOpen, close }) => {
          return isOpen ? (
            <View
              maxWidth={['90%', '620px']}
              mx="auto"
              bg="white"
              borderRadius={1}
              boxShadow={1}
              p={[5, 10]}
              {...rest}
            >
              {status === 'bulk_transfer' ? (
                <BulkTransfer onClose={close} />
              ) : (
                <Default onClose={close} />
              )}
            </View>
          ) : null
        }}
      </Modal>
    )
  }
)

export default CancelModal
