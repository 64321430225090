// @ts-nocheck

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'
import { InputProp, MetaProp } from '../../types/form'
import { Merge } from '../../types/helpers'
import Text from '../Text'
import { Base, BaseProps, HtmlInput as BaseInput } from './Input'

const HtmlInput = styled(BaseInput).attrs(() => ({
  type: 'tel',
}))``

const MediumInput = styled(HtmlInput)`
  width: 22px;
  flex-basis: auto;
  font-variant-numeric: tabular-nums;
`

export type TimeInputProps = Merge<
  BaseProps,
  {
    input?: Partial<InputProp>
    meta?: Partial<MetaProp>
    disabled?: boolean
  }
>

class TimeInput extends Component<TimeInputProps> {
  constructor(props) {
    super(props)
    const {
      input: { value: { hh, mm } = { hh: '', mm: '' } },
    } = props
    this.state = {
      focus: false,
      hh: `${hh}`,
      hhLocal: hh !== '' ? this.prefix(this.getLocalHour(hh)) : `${hh}`,
      mm: mm !== '' ? this.prefix(mm) : `${mm}`,
    }
  }

  handleChange = ({ currentTarget: { id, value } }) => {
    const {
      input: { onChange },
    } = this.props
    let normalizedValue = value
    if (id === 'hhLocal') {
      normalizedValue = this.normalizeHH(value)
    }
    if (id === 'mm') {
      normalizedValue = this.normalizeMM(value)
    }
    this.setState(
      ({ hh }) => ({
        [id]: normalizedValue,
        hh: id === 'hhLocal' ? this.getUTCHour(value) : `${hh}`,
      }),
      () => {
        const { hh, mm } = this.state
        if (hh && mm) {
          onChange && onChange({ hh, mm })
        }
      }
    )
  }

  handleBlur = ({ currentTarget: { id, value } }) => {
    if (id === 'hhLocal') {
      this.setState({ hhLocal: this.prefix(value) })
    }
    if (id === 'mm') {
      this.setState({ mm: this.prefix(value) })
    }
    this.removeFocus()
  }

  // eslint-disable-next-line class-methods-use-this
  prefix = (value) => (value !== '' ? `00${value}`.slice(-2) : value)

  // eslint-disable-next-line class-methods-use-this
  getUTCHour = (localHour) => {
    const date = new Date()
    date.setHours(localHour)
    return `${date.getUTCHours()}`
  }

  // eslint-disable-next-line class-methods-use-this
  getLocalHour = (utcHour) => {
    const date = new Date()
    date.setUTCHours(utcHour)
    return `${date.getHours()}`
  }

  normalizeHH = (value) => {
    let normalizedValue = value.replace(/[^0-9.]+/g, '')
    const possibleFirstChars = ['0', '1', '2']

    if (
      normalizedValue[0] &&
      !possibleFirstChars.includes(normalizedValue[0])
    ) {
      normalizedValue = `0${value}`
    }

    if (value.length > 2) {
      normalizedValue = normalizedValue.slice(0, 2)
    }

    if (normalizedValue.length === 2 && this.mm) {
      this.mm.focus()

      const { mm } = this.state
      this.mm.setSelectionRange(mm.length, mm.length)
    }

    return normalizedValue
  }

  normalizeMM = (value) => {
    let normalizedValue = value.replace(/[^0-9.]+/g, '')
    const isMidnight = this.hh && this.hh.value === '24'
    const possibleFirstChars = isMidnight
      ? ['0']
      : ['0', '1', '2', '3', '4', '5']

    if (
      normalizedValue[0] &&
      !possibleFirstChars.includes(normalizedValue[0])
    ) {
      normalizedValue = `0${isMidnight ? '0' : value}`
    }

    if (value.length > 2) {
      normalizedValue = normalizedValue.slice(0, 2)
    }

    return normalizedValue
  }

  handleCursorMove = (event) => {
    const {
      target: { id, selectionEnd },
      key,
    } = event

    // Backspace && ArrowLeft
    if (
      ['Backspace', 'ArrowLeft'].includes(key) &&
      id === 'mm' &&
      selectionEnd === 0
    ) {
      this.hh.focus()
      this.hh.setSelectionRange(2, 2)
    }

    // ArrowRight
    if (
      ['ArrowRight'].includes(key) &&
      id === 'hhLocal' &&
      selectionEnd === 2
    ) {
      this.mm.focus()
      this.mm.setSelectionRange(0, 0)
    }
  }

  setFocus = () =>
    this.setState({
      focus: true,
    })

  removeFocus = () => {
    const {
      input: { onBlur },
    } = this.props
    const { hh, mm } = this.state
    if (hh && mm) {
      this.setState({
        focus: false,
      })
      if (hh && mm) {
        onBlur && onBlur({ mm, hh })
      }
    }
  }

  render() {
    const { disabled, meta } = this.props
    const { focus, hhLocal, mm } = this.state
    return (
      <Base
        justifyContent="flex-start"
        display="inline-flex"
        minWidth="auto"
        {...this.props}
        meta={{
          ...meta,
          focus,
        }}
      >
        <MediumInput
          id="hhLocal"
          ref={(hh) => {
            this.hh = hh
          }}
          onChange={this.handleChange}
          onFocus={this.setFocus}
          onBlur={this.handleBlur}
          value={hhLocal}
          onKeyDown={this.handleCursorMove}
          disabled={disabled}
          placeholder="00"
        />
        <Text px="2px" color="ink.2">
          :
        </Text>
        <MediumInput
          id="mm"
          ref={(mm) => {
            this.mm = mm
          }}
          onChange={this.handleChange}
          onFocus={this.setFocus}
          onBlur={this.handleBlur}
          onKeyDown={this.handleCursorMove}
          disabled={disabled}
          value={mm}
          placeholder="00"
        />
      </Base>
    )
  }
}

TimeInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
}

TimeInput.defaultProps = {
  input: {},
  meta: {},
}

export default TimeInput
