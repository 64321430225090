import { BanknotesIcon } from '@heroicons/react/24/outline'
import { useMobile } from '@tabeo/scarf'
import {
  Button,
  FFCurrencyInput,
  FFRadioCards,
  Stepper as StepperItem,
} from '@tabeo/scarf2'
import { format } from '@tabeo/sharpei/utils/currency'
import { required, valueRange } from '@tabeo/sharpei/utils/validations'
import FormError from 'components/Form/FormError'
import AutoSave from 'components/nnts/form/AutoSave'
import { Field } from 'components/nnts/form/Field'
import { scrollToErrorDecorator } from 'components/nnts/form/scrollToError'
import { MAX_PAYMENT_AMOUNT, MIN_PAYMENT_AMOUNT, getCurrency } from 'config'
import { ComponentProps, useCallback } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import { z } from 'zod'
import Header from '../components/header'
import AutoSelectPaymentMethod from '../components/payment/AutoSelectPaymentMethod'
import Cards from '../components/payment/Cards'
import { BillActions } from '../components/payment/bill'
import {
  FinanceActions,
  FinancePaymentOptions,
} from '../components/payment/finance'
import { LinkActions } from '../components/payment/link'
import { TerminalActions } from '../components/payment/terminal'
import { useNewTransactionFlow } from '../components/provider'
import { NNTSState } from '../types'
import { getMerchantTreatmentById } from '../utils'

export const paymentSchema = z.object({
  price: z.number().min(MIN_PAYMENT_AMOUNT).max(MAX_PAYMENT_AMOUNT),
  paymentMethod: z.string().min(1),
})

export const financeWithReferencePaymentSchema = paymentSchema.extend({
  reference: z.string().min(1),
})

export const billPaymentSchema = paymentSchema.extend({
  scheduledAt: z.string().min(1),
})

export function PaymentMain() {
  const {
    next,
    state,
    setState,
    setStateForStep,
    modals: { cartSummaryModalRef },
    form,
    currentIndex,
  } = useNewTransactionFlow()
  const { data: merchant } = useMerchant()
  const isMobile = useMobile()
  const { t } = useTranslation()

  const priceValidator = useCallback(
    (v: any) =>
      valueRange(
        MIN_PAYMENT_AMOUNT,
        MAX_PAYMENT_AMOUNT,
        t(`Please specify an amount between {{min}} and {{max}}`, {
          min: format(MIN_PAYMENT_AMOUNT),
          max: format(MAX_PAYMENT_AMOUNT),
        })
      )(v),
    [t]
  )

  function handleSubmit(values: any) {
    if (!merchant) {
      return {
        formError: 'Merchant not found',
      }
    }

    // Save latest form values to state
    const currency = getCurrency(merchant?.countryCode)
    const newState = {
      ...state,
      payment: {
        ...state.payment,
        ...values,
        currency,
      },
    }
    setState(newState)

    return next()
  }

  if (!merchant) return null

  const mt = getMerchantTreatmentById(
    state?.treatment?.merchantTreatmentId,
    merchant
  )

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        ...state.payment,
        price: state.payment?.price || mt?.defaultPrice,
      }}
      decorators={[scrollToErrorDecorator]}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, form: f }) => {
        form.current = f
        return (
          <form onSubmit={handleSubmit} className="h-full">
            <Header
              cartSummaryModalRef={cartSummaryModalRef}
              currentIndex={currentIndex}
            />
            <h2 className="title text-tabeo-ink-0">{t('Payment')}</h2>
            <p className="caption mb-6 text-tabeo-ink-1">
              {t(
                'Prepare payment by entering a price, then selecting a payment method.'
              )}
            </p>

            <div className="mb-7 border-b pb-6">
              <Field
                component={FFCurrencyInput}
                name="price"
                label={
                  <div>
                    {t('Price')}{' '}
                    <span className="text-tabeo-ink-2">
                      {t('(including VAT)')}
                    </span>
                  </div>
                }
                placeholder="0.00"
                validate={[required, priceValidator]}
                clearable
                wrapClassName="sm:max-w-[300px]"
                autoFocus={!isMobile}
              />
              <AutoSelectPaymentMethod />
            </div>
            <Field
              component={FFRadioCards}
              name="paymentMethod"
              validate={required}
            >
              <Cards />
              <FinancePaymentOptions />
            </Field>
            <FormError />
            <AutoSave
              debounce={0}
              onSave={(values: any) => {
                setStateForStep('payment', values)
              }}
            />
            <button type="submit" className="hidden">
              {t('Submit')}
            </button>
          </form>
        )
      }}
    </Form>
  )
}

export function PaymentActions() {
  const { form, state } = useNewTransactionFlow()
  const { t } = useTranslation()

  // Render the correct action button based on the payment method
  const { paymentMethod } = state.payment || {}
  const isFinance = paymentMethod?.startsWith('finance')
  if (isFinance) {
    return <FinanceActions />
  }

  const isLink = paymentMethod === 'link'
  if (isLink) {
    return <LinkActions />
  }

  const isTerminal = paymentMethod === 'terminal'
  if (isTerminal) {
    return <TerminalActions />
  }

  const isBill = paymentMethod === 'bill'
  if (isBill) {
    return <BillActions />
  }

  // Fallback button to trigger validation via form submit
  return (
    <Button
      variant="primary"
      className="w-full"
      type="submit"
      onClick={() => form.current?.submit()}
    >
      {t('Continue')}
    </Button>
  )
}

export function shouldRenderPaymentMobileActions(state: NNTSState) {
  return Number(state?.payment?.price) > 0
}

export function PaymentStepper(props: ComponentProps<typeof StepperItem>) {
  const { state } = useNewTransactionFlow()
  const { t } = useTranslation()

  const { price } = state.payment || {}

  const body = price ? format(price) : null

  return (
    <StepperItem empty={!body} {...props}>
      <StepperItem.Header>
        <StepperItem.Icon icon={BanknotesIcon} />
        <StepperItem.Label>{t('Payment')}</StepperItem.Label>
      </StepperItem.Header>
      <StepperItem.Body>{body}</StepperItem.Body>
    </StepperItem>
  )
}
