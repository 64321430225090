import { Button, Caption, Flex, Heading, View } from '@tabeo/scarf'
import FieldError from 'components/Form/FieldError'
import Card from 'components/Transaction/ActionPanel/Card'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import icon from './assets/waiting.svg'

function Scheduled({
  data,
  cancelModal,
  cancelState,
  updateModal,
  updateState,
  chargeModal,
  chargeState,
  ...rest
}) {
  const { scheduledAt, consumerFirstName } = data
  const { t } = useTranslation()

  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Payment scheduled')}</Heading>
      </Flex>
      <Caption color="ink.1" mb={4}>
        {t(
          '{{consumerFirstName}} has saved a payment method and confirmed payment for {{date}}. The payment will be taken at 6am.',
          { consumerFirstName, date: moment(scheduledAt).format('D MMMM YYYY') }
        )}
      </Caption>
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="flex-start"
        alignItems={['stretch', 'center']}
      >
        <Button
          variant="secondary"
          sizeVariant="sm"
          onClick={() => updateModal.current.open()}
          mr={[0, 4]}
          mb={[4, 0]}
        >
          {t('Change payment day')}
        </Button>
        <Button
          variant="secondary"
          sizeVariant="sm"
          mr={[0, 4]}
          mb={[4, 0]}
          onClick={() => chargeModal.current.open()}
        >
          {t('Charge now')}
        </Button>

        <Button
          variant="secondary"
          sizeVariant="sm"
          color="ink.2"
          onClick={() => cancelModal.current.open()}
        >
          {t('Cancel bill')}
        </Button>
      </Flex>
      {(cancelState.error || chargeState.error || updateState.error) && (
        <FieldError
          message={t('There was an error, please try again later.')}
          mt={4}
        />
      )}
    </Card>
  )
}

Scheduled.propTypes = {
  data: PropTypes.object.isRequired,
  cancelState: PropTypes.object.isRequired,
  updateState: PropTypes.object.isRequired,
  cancelModal: PropTypes.object.isRequired,
  updateModal: PropTypes.object.isRequired,
  chargeModal: PropTypes.object.isRequired,
  chargeState: PropTypes.object.isRequired,
}

export default Scheduled
