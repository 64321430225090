import { Attributes, Panel } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'

const TreatmentPanel = ({ data, ...rest }) => {
  const {
    product: { name, price: productPrice },
    price,
  } = data
  const {
    data: { isRetail },
  } = useMerchant()
  const { t } = useTranslation()
  return (
    <Panel title={isRetail ? 'Purchase' : 'Treatment'} {...rest}>
      <Attributes
        p={5}
        bg="white"
        sections={[
          [
            {
              label: t('Name'),
              value: name,
            },
            {
              label: t('Price'),
              value: format(price || productPrice),
            },
            // {
            //   label: 'Clinician',
            //   value: appointment?.personName,
            // },
          ],
        ]}
      />
    </Panel>
  )
}

TreatmentPanel.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TreatmentPanel
