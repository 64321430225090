import React from 'react'
import Svg, { G, SvgProps } from './Base'

function Copy({ fill, ...props }: SvgProps) {
  return (
    <Svg {...props}>
      <G
        stroke={fill}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      >
        <path d="M6.5,15.5l-2.25,0c-1.243,0 -2.25,-1.007 -2.25,-2.25l0,-9c0,-1.243 1.007,-2.25 2.25,-2.25l9,-0c1.243,-0 2.25,1.007 2.25,2.25l0,2.25m-6.75,13.5l9,0c1.243,0 2.25,-1.007 2.25,-2.25l0,-9c0,-1.243 -1.007,-2.25 -2.25,-2.25l-9,0c-1.243,0 -2.25,1.007 -2.25,2.25l0,9c0,1.243 1.007,2.25 2.25,2.25Z" />
      </G>
    </Svg>
  )
}

export default Copy
