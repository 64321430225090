import { useMerchant } from 'resources/Merchant'
import { z } from 'zod'
import { FlowStep, Modules, NNTSState, Step } from '../types'

type Product = z.infer<typeof paymentMethodSchema>
type FlowConfig = Record<Product, Step[]>

const defaultFlowConfig = {
  terminal: [],
  link: [],
  finance: ['treatment', 'patient'],
  bill: ['treatment', 'patient'],
} satisfies FlowConfig

const clinicianAndTreatmentFlowConfig = {
  terminal: [],
  link: [],
  finance: ['clinician', 'treatmentCT', 'patient'],
  bill: ['clinician', 'treatmentCT', 'patient'],
} satisfies FlowConfig

const paymentMethodSchema = z.enum(['terminal', 'link', 'finance', 'bill'])

export function useFlowSteps(state: NNTSState, modules: Modules): FlowStep[] {
  // Get feature flags for the merchant
  const { data: merchant } = useMerchant()
  const isCliniciansTreatmentsEnabled =
    merchant?.flags?.is_clinicians_treatments_enabled

  // Determinate payment method
  const { payment } = state
  const result = paymentMethodSchema.safeParse(
    payment?.paymentMethod?.split('-')?.[0]
  )

  // Adjust the flow config based on the payment method
  let flow = ['payment'] as Step[]
  let possibleFlowSteps = [] as Step[]
  if (result.success) {
    if (isCliniciansTreatmentsEnabled) {
      flow = [...flow, ...clinicianAndTreatmentFlowConfig[result.data]]
      possibleFlowSteps = Object.values(clinicianAndTreatmentFlowConfig).flat()
    } else {
      flow = [...flow, ...defaultFlowConfig[result.data]]
      possibleFlowSteps = Object.values(defaultFlowConfig).flat()
    }
  }

  const uniquePossibleFlowSteps = [
    'payment',
    ...new Set(possibleFlowSteps),
  ] as Step[]

  // Extend modules with stateful data such, isValid, etc.
  const flowSteps = uniquePossibleFlowSteps.map(step => {
    const module = modules[step]
    const moduleState = state[module.stateKey]
    const schema = module.getSchema({ paymentMethod: payment?.paymentMethod })
    const res = schema.safeParse(moduleState)

    const isValid = res.success
    const isLast = false
    const isFirst = false

    // If the module is valid prepend it to the flow if it's not already there
    if (moduleState?.isInitiallyValid && !flow.includes(step)) {
      flow.unshift(step)
    }

    // Return the module with the extended data
    return {
      ...module,
      id: step,
      isValid,
      isLast,
      isFirst,
    }
  })

  // Based on the desired flow for the selected payment method, order the steps
  // and handle possible valid steps that can be jumped over
  const orderedFlow = flow.reduce(
    (acc, step) => {
      const flowStep = flowSteps.find((s: FlowStep) => s.id === step)

      if (!flowStep) {
        throw new Error(`Flow step ${step} not found`)
      }

      if (step !== 'payment') {
        const moduleState = state[flowStep.stateKey]
        // Move current step just above payment if it's valid
        if (moduleState?.isInitiallyValid) {
          acc.unshift(step)
          // Order all steps bafore patient by alphabet
          const indexOfPayment = acc.indexOf('payment')
          const stepsBeforePayment = acc.slice(0, indexOfPayment)
          // Steps before payment should always be ordered like this:
          const orderedStepsBeforePayment = [
            'clinician',
            'treatment',
            'treatmentCT',
            'patient',
          ].filter(s => stepsBeforePayment.includes(s as Step)) as Step[]
          const stepsAfterPayment = acc.slice(indexOfPayment)
          acc = [...orderedStepsBeforePayment, ...stepsAfterPayment]
        } else {
          // Otherwise just push it to the end
          acc.push(step)
        }
      }
      return acc
    },
    ['payment'] as Step[]
  )

  // Return the ordered flow steps
  return orderedFlow.map(step => {
    const fs = flowSteps.find(s => s.id === step)
    return {
      ...fs,
      isLast: orderedFlow.indexOf(step) === orderedFlow.length - 1,
      isFirst: orderedFlow.indexOf(step) === 0,
    }
  }) as FlowStep[]
}
