import { Icons, Modal, MultiActionButton, Panel } from '@tabeo/scarf'
import { Tooltip } from '@tabeo/scarf2'
import { accountingFormat, format } from '@tabeo/sharpei/utils/currency'
import Tally from 'components/Tally'
import config, { getAppUrlForCountry } from 'config'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { NormalizedLinkPayment } from 'types/NormalizedLinkPayment'
import { NormalizedTerminalPayment } from 'types/NormalizedTerminalPayment'
import SendReceiptModal from './SendReceiptModal'

export type SummaryPanelProps = {
  data: NormalizedTerminalPayment | NormalizedLinkPayment
  onReceiptSend: (payload: any) => any
} & React.ComponentProps<typeof Panel>

function SummaryPanel({ data, onReceiptSend, ...rest }: SummaryPanelProps) {
  const { t } = useTranslation()

  const modal = useRef<Modal>()

  const { amount, adjustedAmount, upfrontFee, paidAmount } = data

  const netProceeds = (adjustedAmount ?? amount) - upfrontFee

  return (
    <>
      <SendReceiptModal
        ref={modal}
        basket={data.basket}
        onReceiptSend={onReceiptSend}
      />
      <Panel
        data-testid="summary-panel"
        header={
          <div className="flex w-full items-center justify-between py-2.5 desktop:py-0">
            <Panel.Title>{t('Summary')}</Panel.Title>
            {['p', 's', 'refund_req', 'pr', 'fr'].includes(data.status) && (
              <MultiActionButton
                variant="secondary"
                width="unset"
                sizeVariant="sm"
                secondaryActions={[
                  {
                    label: 'Send receipt',
                    onClick: () => {
                      modal.current?.open()
                    },
                  },
                ]}
                onClick={() => {
                  window.open(
                    `${getAppUrlForCountry('pay', config.COUNTRY)}/receipt/${
                      // eslint-disable-next-line no-underscore-dangle
                      data._type === 'terminal_payment' ? 'TP' : 'PL'
                    }/${data.accessToken}`,
                    '_blank'
                  )
                }}
              >
                {t('View receipt')}
              </MultiActionButton>
            )}
          </div>
        }
        {...rest}
      >
        <Tally border={0}>
          <Tally.Item>
            <Tally.Label>{t('Total (including VAT)')}</Tally.Label>
            <Tally.DiffValue diff={amount !== adjustedAmount && format(amount)}>
              {format(adjustedAmount)}
            </Tally.DiffValue>
          </Tally.Item>
          <Tally.Item>
            <Tally.Label>
              {t('Est. Transaction Fees')}{' '}
              <Tooltip
                title="Transaction Fees may be subjected to adjustments at month's end. For the final breakdown, please refer to your monthly invoice."
                wrapperClassName="inline"
              >
                <Icons.Questionmark ml={1} />
              </Tooltip>
            </Tally.Label>
            <Tally.DiffValue diff={false}>{format(upfrontFee)}</Tally.DiffValue>
          </Tally.Item>
          <Tally.Divider />
          <Tally.Item>
            <Tally.Label color="ink.0" fontWeight="semibold">
              {t('Net proceeds')}
            </Tally.Label>
            <Tally.DiffValue
              diff={
                !!paidAmount &&
                paidAmount !== adjustedAmount &&
                format(amount - upfrontFee)
              }
              fontWeight="semibold"
            >
              {netProceeds < 0
                ? accountingFormat(netProceeds)
                : format(netProceeds)}
            </Tally.DiffValue>
          </Tally.Item>
        </Tally>
      </Panel>
    </>
  )
}

export default SummaryPanel
