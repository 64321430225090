import { Box, Caption, Flex, Heading, Prompt, View } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function Plan({ data, ...rest }) {
  const {
    plan: { id, imageURL, title, price },
    agreementAcceptedAt,
  } = data
  const { t } = useTranslation()

  const momentDate = moment(agreementAcceptedAt)

  return (
    <Box
      p={5}
      boxShadow={1}
      as={Link}
      to={`/plans/${id}`}
      _hover={{
        bg: 'sky.4',
      }}
      data-testid="plan-panel"
      {...rest}
    >
      <Flex>
        <View as="img" width="82px" height="82px" src={imageURL} mr={5} />
        <View flex="1">
          <Heading color="primary.3">{title}</Heading>
          <Prompt mt={1}>
            {t('{{amount}} a month', { amount: format(price) })}
          </Prompt>
          {agreementAcceptedAt && (
            <Caption mt={1}>
              {t('Member since {{date}} ({{elapsedTime}})', {
                date: momentDate.format('DD MMM YYYY'),
                elapsedTime: momentDate.toNow(true),
              })}
            </Caption>
          )}
        </View>
      </Flex>
    </Box>
  )
}

Plan.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Plan
