// @ts-nocheck

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { InputProp, MetaProp } from '../../types/form'
import { Props } from '../../types/helpers'
import ScarfProps from '../../types/ScarfProps'
import { children } from '../../utils/propTypes'
import * as Icons from '../Icons'
import Text from '../Text'
import View from '../View'

export type SelectProps = ScarfProps<'select'>

const Select = styled(Text)<SelectProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
`
Select.defaultProps = {
  as: 'select',
  backgroundColor: 'sky.2',
  borderStyle: 'solid',
  borderWidth: '1px',
  width: '100%',
  height: '38px',
  pl: 3,
  pr: '36px',
  cursor: 'pointer',
  fontSize: '16px',
  borderRadius: '3px',
}

export type NativeSelectProps = SelectProps & {
  input?: Partial<InputProp>
  meta?: Partial<MetaProp>
  options: Props<{ value?: any; label?: string }, 'option'>[]
  autoBlur?: boolean
  variant?: 'default' | 'outline-v2'
}

export default function NativeSelect({
  input,
  meta,
  options,
  placeholder: placeholderProp,
  width = ['100%'],
  maxWidth = ['100%', '350px'],
  autoBlur = false,
  children,
  variant = 'outline-v2',
  disabled = false,
  ...rest
}: NativeSelectProps) {
  const { t } = useTranslation()
  const placeholder = placeholderProp || t('Select from the list...')
  const selectedIndex = options?.findIndex((o) => o.value === input?.value)

  const outlineV2Props =
    variant === 'outline-v2'
      ? {
          height: '42px',
          backgroundColor: 'sky.3',
          borderRadius: '4px',
          _hover: disabled ? {} : { bg: 'sky.2' },
        }
      : {}

  return (
    <View width={width} maxWidth={maxWidth} position="relative">
      <Icons.DoubleArrow
        position="absolute"
        transform="translateY(-50%)"
        top="50%"
        right="8px"
        cursor="pointer"
        style={{ pointerEvents: 'none' }}
        fill={disabled ? 'ink.2' : undefined}
      />
      <Select
        color={input?.value ? 'ink.0' : 'ink.2'}
        borderColor={
          meta?.error && meta?.touched
            ? 'red.3'
            : meta?.touched && variant === 'outline-v2'
            ? 'ink.2'
            : 'sky.0'
        }
        {...input}
        value={
          children ? input?.value : selectedIndex > -1 ? `${selectedIndex}` : ''
        }
        onChange={({ target: { value } }) => {
          const selectedValue = children ? value : options[+value]?.value

          if (input) {
            if (input.onChange) input.onChange(selectedValue)
            if (autoBlur && input.onBlur) input.onBlur(selectedValue)
          }
        }}
        disabled={disabled}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        {...outlineV2Props}
        {...rest}
      >
        {children || (
          <>
            <option value="" disabled>
              {placeholder}
            </option>
            {options.map(({ value, label, ...option }, index) => (
              <option
                key={`${value}`}
                value={`${index}`}
                className={disabled && 'text-tabeo-sky-0'}
                {...option}
              >
                {label || value}
              </option>
            ))}
          </>
        )}
      </Select>
    </View>
  )
}

NativeSelect.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  autoBlur: PropTypes.bool,
  children,
}
