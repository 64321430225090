'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var propTypes = require('prop-types');

var children = propTypes.oneOfType([propTypes.arrayOf(propTypes.node), propTypes.node]);
var responsiveStyle = propTypes.oneOfType([
    propTypes.number,
    propTypes.string,
    propTypes.arrayOf(propTypes.oneOfType([propTypes.number, propTypes.string])),
]);

exports.children = children;
exports.responsiveStyle = responsiveStyle;
