import { themeGet } from '@styled-system/theme-get'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Merge } from '../../../types/helpers'
import ScarfProps from '../../../types/ScarfProps'
import Flex from '../../Flex'
import * as Icons from '../../Icons'
import { Item } from '../Select'
import DashedContainer from './DashedContainer'
import EntitySelect, { EntitySelectProps, EntityType } from './EntitySelect'
import EntityTypeSelect from './EntityTypeSelect'

const Actions = styled(Flex)`
  z-index: 1;
  right: ${themeGet('space.1')}px;
  position: absolute;

  cursor: pointer;
`

const StyledDashedContainer = styled(DashedContainer)`
  @media (min-width: ${themeGet('breakpoints.0')}) {
    ${Actions} {
      opacity: 0;
      transition: 0.2s all;
    }

    :hover {
      ${Actions} {
        opacity: 1;
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 0px 8px 8px rgba(255, 255, 255, 0.9);
      }
    }
  }

  overflow: hidden;
`

export interface EntityItem {
  type?: string
  id?: string | number | null
  data?: any
}

export type SingleEntitySelectorProps = Merge<
  ScarfProps,
  {
    types: EntityType[]
    value?: EntityItem
    onChange: (value?: {
      type: string
      id?: string | number | null
      data?: any
    }) => void
    disabledValues?: EntitySelectProps['disabledValues']
  }
>

function SingleEntitySelector({
  types,
  onChange,
  value,
  onFocus,
  disabledValues,
  ...rest
}: SingleEntitySelectorProps) {
  const [entityTypeOpen, setEntityTypeOpen] = useState(false)

  const [entityOpen, setEntityOpen] = useState(false)

  useEffect(() => {
    if (!value?.type) {
      setEntityTypeOpen(true)
    }

    if (value?.type && !value?.data) {
      setTimeout(() => setEntityOpen(true), 0)
    }
  }, [value])

  const { type, id, data } = value || {}

  const selectedType = types.find((o) => o.type === type)

  return (
    <StyledDashedContainer
      width="100%"
      height="40px"
      tabIndex="0"
      position="relative"
      onFocus={onFocus}
      borderStyle={data ? 'solid' : 'dashed'}
      borderColor={data ? 'sky.1' : 'sky.0'}
      {...rest}
    >
      <Flex height="100%">
        <EntityTypeSelect
          options={types.map((o) => ({ label: o.label, value: o.type }))}
          value={
            type
              ? {
                  value: type,
                  label: selectedType?.label,
                }
              : undefined
          }
          onChange={(e) => {
            onChange({
              type: (e as Item)?.value as string,
              id: null,
              data: null,
            })
          }}
          open={entityTypeOpen}
          toggle={() => setEntityTypeOpen(!entityTypeOpen)}
          borderRight={1}
          borderStyle={type ? 'solid' : 'dashed'}
          borderColor={type ? 'sky.2' : 'sky.0'}
        />
        <EntitySelect
          value={
            id &&
            data && {
              value: id,
              data,
            }
          }
          onChange={(item) => {
            if (type) {
              if (item) {
                const { data } = item as Item

                onChange({ type, id: data.id, data })
              } else {
                onChange({ type, id: null, data: null })
              }
            }
          }}
          open={entityOpen}
          toggle={() => setEntityOpen(!entityOpen)}
          disabledValues={[
            ...(disabledValues as any[]),
            ...(selectedType?.disabledValues || []),
          ]}
          type={selectedType}
          mr={['56px', 0]}
        />
        <Actions>
          <Icons.Popout
            fill="ink.2"
            onClick={() => selectedType?.link?.(value)}
            height="16px"
            width="16px"
            mr={2}
            mt="2px"
          />
          <Icons.Trash fill="ink.2" onClick={() => onChange()} />
        </Actions>
      </Flex>
    </StyledDashedContainer>
  )
}

SingleEntitySelector.propTypes = {
  types: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      label: PropTypes.string,
      renderItem: PropTypes.func,
      loader: PropTypes.func,
      placeholder: PropTypes.string,
      disabledValues: PropTypes.array,
    })
  ),
  disabledValues: PropTypes.array,
  value: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    data: PropTypes.any,
  }),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
}

export default SingleEntitySelector
