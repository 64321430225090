import {
  Button,
  ButtonGroup,
  Modal,
  Prompt,
  Title,
  usePromise,
  View,
} from '@tabeo/scarf'
import React from 'react'
import { useSubscription } from 'resources/Subscription'
import { useTranslation } from 'react-i18next'

const CancelModal = React.forwardRef((props, ref) => {
  const {
    resource: { cancel },
  } = useSubscription()
  const [trigger, { pending, error }] = usePromise(cancel)
  const { t } = useTranslation()
  if (error) {
    throw error
  }

  return (
    <Modal ref={ref}>
      {({ isOpen, close }) => {
        return isOpen ? (
          <View
            maxWidth={['90%', '460px']}
            mx="auto"
            bg="white"
            borderRadius={1}
            boxShadow={1}
            p={[5, 10]}
            textAlign="center"
          >
            <Title mb={3}>{t('Cancel subscription')}</Title>
            <Prompt mb={6}>
              {t(
                'Are you sure you wish to cancel this subscription? This action will not be reversible.'
              )}{' '}
            </Prompt>
            <ButtonGroup>
              <Button variant="secondary" onClick={close} flex="1">
                {t('Cancel')}
              </Button>
              <Button
                onClick={async () => {
                  await trigger()
                  close()
                }}
                loading={pending}
                flex="1"
              >
                {t('Confirm')}
              </Button>
            </ButtonGroup>
          </View>
        ) : null
      }}
    </Modal>
  )
})

CancelModal.propTypes = {}

export default CancelModal
