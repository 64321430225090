import { Button, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import DeleteModal from './DeleteModal'

function Footer({ state }) {
  const deleteModal = React.useRef()
  const { t } = useTranslation()
  if (['lost', 'wont'].includes(state)) {
    return null
  }
  return (
    <View>
      <DeleteModal innerRef={deleteModal} />
      <Button
        variant="secondary"
        onClick={() => deleteModal.current.open()}
        color="ink.2"
      >
        {t('Delete lead')}
      </Button>
    </View>
  )
}

Footer.propTypes = {
  state: PropTypes.string.isRequired,
}

export default Footer
