import {
  Attributes,
  Box,
  Button,
  Caption,
  Modal,
  Panel,
  Prompt,
  View,
} from '@tabeo/scarf'
import { toast } from '@tabeo/scarf2'
import { Basket } from '@tabeo/ts-types'
import Avatar from 'components/Avatar'
import moment from 'moment'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useBasket } from 'resources/Basket'
import { usePatient } from 'resources/Patient'
import Patients from 'resources/Patients'
import UpsertModal from './UpsertModal'

export type PatientPanelProps = {
  basket: Basket
} & React.ComponentProps<typeof View>

function PatientPanel({ basket: pBasket, ...rest }: PatientPanelProps) {
  const { t } = useTranslation()
  const { data: basket, resource: basketResource } = useBasket({
    id: pBasket.id,
  })
  const { data: patient, resource: patientResource } = usePatient(() => {
    if (!basket?.patientID) {
      throw new Error('Missing patient ID')
    }

    return {
      id: basket.patientID,
    }
  })

  const modal = useRef<Modal>()

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    address,
    birthdate,
    externalID,
  } = patient || basket?.patient || pBasket.patient || {}

  const Wrapper = ({ children, ...rest }: any) => {
    if (firstName || lastName) {
      return (
        <Box p={0} boxShadow={1} {...rest}>
          <div className="flex items-center p-5">
            <div className="flex flex-1">
              <Avatar name={`${firstName || ''} ${lastName || ''}`.trim()} />
              <div className="ml-3 flex-1">
                <Prompt data-testid="patient-name" fontWeight="semibold">
                  {firstName} {lastName}
                </Prompt>
                <Caption color="ink.0">{t('Patient')}</Caption>
              </div>
            </div>
            <Button
              variant="secondary"
              onClick={() => modal.current?.open()}
              sizeVariant="sm"
            >
              {t('Edit')}
            </Button>
          </div>
          {children}
        </Box>
      )
    }

    return (
      <Panel
        header={
          <>
            <Panel.Title>{t('Patient')}</Panel.Title>
            <Button
              sizeVariant="sm"
              variant="secondary"
              onClick={() => modal.current?.open()}
            >
              {patient?.id ? t('Edit') : t('Add')}
            </Button>
          </>
        }
        {...rest}
      >
        {children}
      </Panel>
    )
  }

  const attributes = [
    {
      label: t('Email address'),
      value: email,
    },
    {
      label: t('Phone number'),
      value: phoneNumber,
    },
    {
      label: t('Date of birth'),
      value: birthdate,
      formattedValue: moment(birthdate).format('DD MMM YYYY'),
    },
    {
      label: t('Patient ID'),
      value: externalID,
    },
    {
      label: t('Home address'),
      value: address?.addressDetails?.Label,
    },
  ]

  return (
    <>
      <UpsertModal
        data={patient}
        ref={modal}
        onSubmit={async p => {
          if (p.id) {
            await patientResource?.update(p)
            toast.success(t('Patient details updated'))
          } else {
            const patient = await Patients.create(p)
            await basketResource?.addPatient(patient.id)
            toast.success(t('Patient details added'))
          }
        }}
      />
      <Wrapper data-testid="patient-panel" {...rest}>
        {attributes.find(a => !!a.value) || firstName || lastName ? (
          <Attributes
            p={5}
            sections={[attributes]}
            borderTop={firstName || lastName ? 1 : 0}
          />
        ) : (
          <div className="caption p-5 text-tabeo-ink-2">
            {t('Patient details not provided.')}
          </div>
        )}
      </Wrapper>
    </>
  )
}

export default PatientPanel
