import { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

export type SummaryCartProps = ComponentPropsWithoutRef<'div'>

function SummaryCart({ className, ...rest }: SummaryCartProps) {
  return (
    <div className={twMerge('flex h-full flex-col', className)} {...rest} />
  )
}

export type BodyProps = ComponentPropsWithoutRef<'div'>

function Body({ className, ...rest }: BodyProps) {
  return (
    <div
      className={twMerge(
        'flex-1 space-y-3 overflow-y-auto p-4 md:space-y-4 md:bg-tabeo-sky-3 md:p-5',
        className
      )}
      {...rest}
    />
  )
}

SummaryCart.Body = Body

export type FooterProps = ComponentPropsWithoutRef<'div'>

function Footer({ className, ...rest }: FooterProps) {
  return (
    <div
      className={twMerge(
        'space-y-4 border-b border-t border-tabeo-sky-0 bg-tabeo-sky-3 p-4 md:border-b-0 md:px-5 md:py-6',
        className
      )}
      {...rest}
    />
  )
}

SummaryCart.Footer = Footer

export default SummaryCart
