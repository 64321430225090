import React from 'react'
import styled from 'styled-components'
import ScarfProps from '../../types/ScarfProps'
import variants from '../../utils/variants'
import Text from '../Text'
import Tooltip2 from '../Tooltip2'
import View from '../View'

type CircleProps = {
  sizeVariant?: 'small' | 'normal' | 'fixed'
}

const Circle = styled(View)<CircleProps>`
  ${variants({
    sizeVariant: {
      small: {
        width: '18px',
        height: '18px',
        fontSize: '8px',
      },
      normal: {
        width: ['36px', '25px'],
        height: ['36px', '25px'],
        fontSize: ['14px', '10px'],
      },
      fixed: {
        width: ['25px'],
        height: ['25px'],
        fontSize: ['10px'],
      },
    },
  })}
`

Circle.defaultProps = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: '50%',
  borderColor: 'sky.0',
  bg: 'white',
  sizeVariant: 'normal',
}

export type AvatarProps = ScarfProps &
  CircleProps & {
    name?: string
  }

function Avatar({
  children,
  name,
  color = 'ink.2',
  sizeVariant,
  ...rest
}: AvatarProps) {
  return (
    <Tooltip2 title={name}>
      <Circle
        display="flex"
        justifyContent="center"
        alignItems="center"
        cursor="default"
        sizeVariant={sizeVariant}
        color={color}
        {...rest}
      >
        <Text fontSize="inherit" color="inherit" lineHeight="1">
          {children ||
            name
              ?.toUpperCase()
              .split(' ')
              .map((s: string) => s.charAt(0))
              .join('')
              .slice(0, 2)}
        </Text>
      </Circle>
    </Tooltip2>
  )
}

// https://styled-components.com/docs/advanced#caveat
const StyledAvatar = styled(Avatar)``

export default StyledAvatar
