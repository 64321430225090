import React from 'react'
import { Box } from '@tabeo/scarf'

export default function Card(props) {
  return <Box {...props} />
}
Card.defaultProps = {
  p: 5,
  boxShadow: 1,
}
