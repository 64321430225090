import React from 'react'
import { Tooltip as BaseTooltip } from 'react-tippy'
import { createGlobalStyle, ThemeProvider, useTheme } from 'styled-components'

type TooltipStyleProps = {
  tippyPopperStyle?: {
    maxWidth?: string
  }
}

const TooltipStyle = createGlobalStyle<TooltipStyleProps>`
  [data-tooltipped]{
    display: inline-flex !important;
  }
  .tippy-tooltip {
    background: #f9fafb !important;
    border: 1px solid #c4cdd5 !important;
    padding: 12px 16px !important;
    color: #454f5b !important;
    border-radius: 4px !important;
    box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.2) !important;
    max-width: none;
    pointer-events: auto;
  }
  .tippy-popper {
    ${({ tippyPopperStyle }) =>
      tippyPopperStyle?.maxWidth && `max-width: ${tippyPopperStyle.maxWidth}`}
  }
  .tippy-popper .leave {
    opacity: 0;
  }
  .arrow-regular{
    width: 9px !important;
    height: 9px !important;
    background: #f9fafb !important;
    border-bottom: 1px solid #c4cdd5 !important;
    border-left: 1px solid #c4cdd5 !important;
    border-top: none !important;
    border-right: none !important;
  }
  .tippy-popper[x-placement^=top] [x-arrow] {
    transform: rotate(-45deg);
    bottom: -6px !important;
  }
  .tippy-popper[x-placement^=bottom] [x-arrow] {
    transform: rotate(135deg);
    top: -6px !important;
  }
  .tippy-popper[x-placement^=left] [x-arrow] {
    transform: rotate(225deg);
    right: -6px !important;
  }
  .tippy-popper[x-placement^=right] [x-arrow] {
    transform: rotate(45deg);
    left: -6px !important;
  }
  body.tippy-touch{
    cursor: default !important;
  }
`

export type TooltipProps = TooltipStyleProps & {
  children: React.ReactNode
  component: React.ReactNode
  onShow?: () => void
  onHide?: () => void
}

// Full list of supported props:
// https://github.com/tvkhoa/react-tippy#props

function Tooltip({
  children,
  component,
  onShow = () => {},
  onHide = () => {},
  tippyPopperStyle,
  ...rest
}: TooltipProps) {
  const theme = useTheme()
  return (
    <>
      <TooltipStyle tippyPopperStyle={tippyPopperStyle} />
      {/* @ts-ignore */}
      <BaseTooltip
        html={<ThemeProvider theme={theme}>{component}</ThemeProvider>}
        position="bottom"
        arrow
        animation="none"
        duration={0}
        hideDuration={0}
        distance={20}
        trigger="mouseenter"
        useContext
        // @ts-ignore
        onShow={() => {
          document.body.classList.contains('tippy-touch') &&
            document.body.setAttribute('style', 'cursor: pointer !important')
          onShow()
        }}
        onHide={() => {
          document.body.classList.contains('tippy-touch') &&
            document.body.setAttribute('style', 'cursor: default !important')
          onHide()
        }}
        {...rest}
      >
        {children}
      </BaseTooltip>
    </>
  )
}

export default Tooltip
