import { Flex, Title } from '@tabeo/scarf'
import Tag from 'components/TransactionStateTag'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Header = ({ transaction, ...rest }) => {
  const { id, state } = transaction
  const { t } = useTranslation()

  return (
    <Flex
      alignItems={['center', 'top']}
      flexDirection={['column', 'row']}
      data-testid="transaction-header"
      {...rest}
    >
      <Flex
        alignItems={['center', 'baseline']}
        justifyContent={['center', 'flex-start']}
        flex="1 1 auto"
        flexWrap="wrap"
        flexDirection={['column', 'row']}
        textAlign={['center', 'left']}
        mb={[5, 0]}
        mr={[0, 4]}
      >
        <Title mr={[0, 3]}>{t('Transaction #{{id}}', { id })}</Title>
      </Flex>
      <Tag state={state} />
    </Flex>
  )
}

Header.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default Header
