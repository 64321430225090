const uk = {
  '/signup/register': {
    isAvailable: context => {
      const { user } = context

      return !user
    },
    currentStep: () => {
      return {
        pathname: '/signup/register',
      }
    },
  },
  '/signup/email-verification': {
    isAvailable: context => {
      const { user, merchant } = context

      if (user?.user?.status === 'activated') {
        return false
      }

      if (['new'].includes(merchant?.status)) {
        return true
      }
    },
    currentStep: () => {
      return {
        pathname: '/signup/email-verification',
      }
    },
  },
  '/signup/business-details': {
    isAvailable: context => ['new'].includes(context.merchant?.status),
    currentStep: () => {
      return {
        pathname: '/signup/business-details',
      }
    },
  },
  '/signup/officer-details': {
    isAvailable: context =>
      ['details_updated', 'invitation_code_accepted'].includes(
        context.merchant?.status
      ),
    currentStep: () => {
      return {
        pathname: '/signup/officer-details',
      }
    },
  },
  '/signup/bank-details': {
    isAvailable: context =>
      ['officers_updated'].includes(context.merchant?.status) &&
      !context.merchant.localData?.isCheckRunning,
    currentStep: () => {
      return {
        pathname: '/signup/bank-details',
      }
    },
  },
  '/signup/approved': {
    isAvailable: context =>
      ['manual_review_omitted'].includes(context.merchant?.status) &&
      !context.merchant?.metadata?.customFields?.didVisitFinishPage,
    currentStep: () => {
      return {
        pathname: '/signup/approved',
      }
    },
  },
  '/signup/manual-review': {
    isAvailable: context =>
      ['manual_review_required'].includes(context.merchant?.status) &&
      !context.merchant?.metadata?.customFields?.didVisitFinishPage,
    currentStep: () => {
      return {
        pathname: '/signup/manual-review',
      }
    },
  },
  '/signup/risk-check-failed': {
    isAvailable: context =>
      ['risk_checks_failed'].includes(context.merchant?.status),
    currentStep: () => {
      return {
        pathname: '/signup/risk-check-failed',
      }
    },
  },
  '/': {
    isAvailable: context => {
      return context?.merchant?.state !== 'onboarding'
    },
    currentStep: () => {
      return {
        pathname: '/',
      }
    },
  },
}

export default uk
