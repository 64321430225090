// @ts-nocheck

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import colors from '../../theme/colors'
import { InputProp, MetaProp } from '../../types/form'
import { Merge } from '../../types/helpers'
import { children } from '../../utils/propTypes'
import { BaseProps } from '../Text'
import { Base } from './Input'

const style = {
  base: {
    color: colors.default.ink,
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: '24px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: colors.ink[3],
    },
  },
  invalid: {
    color: colors.default.ink,
  },
}

export type StripeInputProps = Merge<
  BaseProps,
  {
    input?: Partial<InputProp>
    meta?: Partial<MetaProp>
    children: React.ReactElement
  }
>

class StripeInput extends Component<StripeInputProps> {
  state = {
    focus: false,
    touched: false,
    error: null,
  }

  handleFocus = () => {
    this.setState({
      focus: true,
    })
  }

  handleBlur = () => {
    this.setState({
      focus: false,
      touched: true,
    })
  }

  handleOnChange = ({ error, complete }) => {
    const {
      input: { onChange },
    } = this.props
    if (error) {
      this.setState({
        error: error.type,
      })
    } else {
      this.setState({
        error: null,
      })
    }
    if (complete) {
      onChange && onChange(`complete-${new Date().getTime()}`)
    } else {
      onChange && onChange('')
    }
  }

  render() {
    const { children, meta = {}, ...rest } = this.props
    const { focus, touched, error } = this.state

    return (
      <Base
        display="block"
        meta={{
          focus,
          touched: meta.touched || touched,
          error: meta.error || error,
        }}
        {...rest}
      >
        {React.cloneElement(children, {
          style,
          onFocus: this.handleFocus,
          onBlur: this.handleBlur,
          onChange: this.handleOnChange,
        })}
      </Base>
    )
  }
}

StripeInput.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  children,
}

StripeInput.defaultProps = {
  input: {},
}

export default StripeInput
