import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

export default function Script({ src, children, async, fallback }) {
  const { loaded } = useScript(src)

  if (async || loaded) {
    return children
  }

  return fallback || null
}

Script.propTypes = {
  src: PropTypes.string.isRequired,
  children: PropTypes.node,
  async: PropTypes.bool,
  fallback: PropTypes.node,
}

export function useScript(src) {
  const [{ loaded, error }, setState] = useState({
    loaded: false,
    error: false,
  })

  useEffect(() => {
    const scripts = Array.from(document.getElementsByTagName('script'))
    if (scripts.find(s => s.src === src)) {
      setState({
        loaded: true,
        error: false,
      })
      return () => {}
    }

    const script = document.createElement('script')
    script.src = src
    script.async = true

    const onLoad = () => {
      setState({
        loaded: true,
        error: false,
      })
    }

    const onError = () => {
      script.remove()
      setState({
        loaded: true,
        error: true,
      })
    }

    script.addEventListener('load', onLoad)
    script.addEventListener('error', onError)

    document.body.appendChild(script)

    return () => {
      script.removeEventListener('load', onLoad)
      script.removeEventListener('error', onError)
    }
  }, [src])

  return { loaded, error }
}
