import {
  AnimateHeight,
  Caption,
  Flex,
  GridTable,
  GridTableRow,
  Icons,
  Subheading,
  Tag,
  themeGet,
  View,
} from '@tabeo/scarf'
import { useState } from 'react'
import { useTheme } from 'styled-components'
import Cell from '../Step2/Cell'
import HeaderCell from '../Step2/HeaderCell'

export const feeMatrix = {
  3: {
    0: { fee: 0.048, type: 'current', oldFee: 0.05 },
    0.059: {},
    0.079: {},
    0.099: {},
    0.109: {},
    0.119: {},
    0.129: {},
    0.149: {},
  },
  6: {
    0: { fee: 0.058, type: 'current', oldFee: 0.06 },
    0.059: {},
    0.079: {},
    0.099: {},
    0.109: {},
    0.119: {},
    0.129: {},
    0.149: {},
  },
  9: {
    0: { fee: 0.07, type: 'current', oldFee: 0.0725 },
    0.059: {},
    0.079: {},
    0.099: {},
    0.109: {},
    0.119: {},
    0.129: {},
    0.149: {},
  },
  10: {
    0.0: { fee: 0.075, type: 'current', oldFee: 0.077 },
    0.059: {},
    0.079: {},
    0.099: {},
    0.109: {},
    0.119: {},
    0.129: {},
    0.149: {},
  },
  12: {
    0: { fee: 0.083, type: 'highlighted', oldFee: 0.085 },
    0.059: { fee: 0.059, type: 'current', oldFee: 0.062 },
    0.079: {},
    0.099: { fee: 0.042, type: 'highlighted', oldFee: 0.045 },
    0.109: {},
    0.119: {},
    0.129: {},
    0.149: {},
  },
  18: {
    0: { fee: 0.109, type: 'current', oldFee: 0.11 },
    0.059: { fee: 0.0705, type: 'current', oldFee: 0.0735 },
    0.079: {},
    0.099: { fee: 0.045, type: 'current', oldFee: 0.048 },
    0.109: {},
    0.119: {},
    0.129: {},
    0.149: {},
  },
  24: {
    0: { fee: 0.133, type: 'highlighted', oldFee: 0.134 },
    0.059: { fee: 0.082, type: 'current', oldFee: 0.085 },
    0.079: { fee: 0.065, type: 'current', oldFee: 0.068 },
    0.099: { fee: 0.049, type: 'highlighted', oldFee: 0.052 },
    0.109: { fee: 0.042, type: 'current', oldFee: 0.045 },
    0.119: { fee: 0.032, type: 'current', oldFee: 0.035 },
    0.129: { fee: 0.022, type: 'current', oldFee: 0.025 },
    0.149: { fee: 0.005, type: 'current', oldFee: 0.015 },
  },
  36: {
    0: { fee: 0.183, type: 'current', oldFee: 0.186 },
    0.059: { fee: 0.111, type: 'current', oldFee: 0.114 },
    0.079: { fee: 0.083, type: 'current', oldFee: 0.086 },
    0.099: { fee: 0.063, type: 'current', oldFee: 0.063 },
    0.109: { fee: 0.05, type: 'current', oldFee: 0.053 },
    0.119: { fee: 0.037, type: 'current', oldFee: 0.04 },
    0.129: { fee: 0.027, type: 'current', oldFee: 0.03 },
    0.149: { fee: 0.005, type: 'current', oldFee: 0.005 },
  },
  48: {
    0: { fee: 0.235, type: 'current', oldFee: 0.235 },
    0.059: { fee: 0.145, type: 'current', oldFee: 0.145 },
    0.079: { fee: 0.11, type: 'current', oldFee: 0.11 },
    0.099: { fee: 0.076, type: 'current', oldFee: 0.076 },
    0.109: { fee: 0.067, type: 'current', oldFee: 0.067 },
    0.119: { fee: 0.045, type: 'current', oldFee: 0.045 },
    0.129: { fee: 0.035, type: 'current', oldFee: 0.035 },
    0.149: { fee: 0.015, type: 'current', oldFee: 0.02 },
  },
  60: {
    0: { fee: 0.282, type: 'current', oldFee: 0.282 },
    0.059: { fee: 0.174, type: 'current', oldFee: 0.174 },
    0.079: { fee: 0.12, type: 'current', oldFee: 0.12 },
    0.099: { fee: 0.097, type: 'current', oldFee: 0.097 },
    0.109: { fee: 0.081, type: 'current', oldFee: 0.081 },
    0.119: { fee: 0.05, type: 'current', oldFee: 0.05 },
    0.129: { fee: 0.04, type: 'current', oldFee: 0.04 },
    0.149: { fee: 0.015, type: 'current', oldFee: 0.03 },
  },
}

const styles = {
  current: { bg: 'sky.2', color: 'ink.0' },
  additional: { bg: 'blue.4', color: 'blue.0' },
  highlighted: { bg: 'blue.1', color: 'white' },
}

function CollapsibleFeesTable({ ...rest }) {
  const [isOpen, setOpen] = useState(false)

  const theme = useTheme()
  const getThemedColor = color =>
    themeGet(`colors.${color}`)({ theme }) || color

  return (
    <View border={1} borderRadius={1} overflow="hidden" {...rest}>
      <Flex
        onClick={() => setOpen(!isOpen)}
        cursor="pointer"
        px={5}
        py={3}
        justifyContent="flex-start"
      >
        <View
          transform={isOpen ? `rotate(90deg)` : `rotate(0deg)`}
          transition="transform 200ms linear"
          alignSelf="flex-start"
        >
          <Icons.ArrowRight fill="ink.2" />
        </View>
        <Caption color="ink.0" fontWeight="semibold" ml="6px" textAlign="left">
          Click to view all loan term and interest&nbsp;options
        </Caption>
      </Flex>
      <AnimateHeight duration={200} height={isOpen ? 'auto' : 0}>
        <View borderTop={1}>
          <Flex
            justifyContent="flex-start"
            p={3}
            flexWrap="wrap"
            mb="-8px"
            mr="-4px"
          >
            <Tag
              style={{
                color: getThemedColor(styles.current.color),
              }}
              bg={styles.current.bg}
              small
              mr={1}
              mb={2}
            >
              Available options
            </Tag>
            <Tag
              style={{
                color: getThemedColor(styles.highlighted.color),
              }}
              bg={styles.highlighted.bg}
              small
              mr={1}
              mb={2}
            >
              Highlighted options
            </Tag>
          </Flex>
          <View overflow="auto">
            <GridTable
              templateColumns="minmax(84px, auto) 72px 72px 72px 72px 80px 80px 80px 80px"
              overflow="hidden"
              minWidth="612px"
              mx="auto"
              borderTop={1}
            >
              <GridTableRow>
                <HeaderCell />
                {Object.keys(Object.values(feeMatrix)[0]).map(apr => (
                  <HeaderCell key={apr}>
                    <Subheading color="blue.1">
                      {(apr * 100).toFixed(1)}% APR
                    </Subheading>
                  </HeaderCell>
                ))}
              </GridTableRow>
              {Object.entries(feeMatrix).map(([key, value]) => (
                <GridTableRow key={key}>
                  <Cell>
                    <Caption color="ink.0">{key} months</Caption>
                  </Cell>
                  {Object.entries(value).map(([valueKey, { fee, type }]) => (
                    <Cell bg={styles[type]?.bg || 'white'} key={key + valueKey}>
                      <Caption color={styles[type]?.color || 'ink.1'}>
                        {fee ? `${(fee * 100).toFixed(2)}%` : '-'}
                      </Caption>
                    </Cell>
                  ))}
                </GridTableRow>
              ))}
            </GridTable>
          </View>
        </View>
      </AnimateHeight>
    </View>
  )
}

export default CollapsibleFeesTable
