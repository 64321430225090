import { Box, Caption, Flex, Heading, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'

const SupportBox = ({ title, description, icon: Icon, children }) => (
  <Box
    display="flex"
    flexDirection="column"
    position="relative"
    p={5}
    boxShadow={1}
  >
    <View>
      <Flex justifyContent="flex-start" alignItems="center" mb={3}>
        <Icon className="mr-3 h-6 w-6 text-tabeo-primary-3" />
        <Heading>{title}</Heading>
      </Flex>
      <Caption color="default.ink" mb={children && 4}>
        {description}
      </Caption>
    </View>
    {children}
  </Box>
)

SupportBox.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  children: PropTypes.node,
}

export default SupportBox
