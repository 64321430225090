import styled from 'styled-components'
import Flex from '../../../Flex'
import View from '../../../View'

export const Actions = styled(View)`
  white-space: nowrap;
`

Actions.defaultProps = {
  opacity: [1, 0],
  transition: 'all 0.2s',
}

export const Indicators = styled(Flex)``

Indicators.defaultProps = {
  alignSelf: ['unset', 'flex-end'],
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  bg: 'inherit',
}

export const Line = styled(Flex)``

Line.defaultProps = {
  flexDirection: ['column', 'row'],
  alignItems: ['flex-start', 'center'],
  bg: 'inherit',
}

export { default as ActionIconButton } from './ActionIconButton'
export { default as Highlight } from './Highlight'
export { default as Id } from './Id'
export { default as Title } from './Title'
