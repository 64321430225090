import { Button, RadioCards } from '@tabeo/scarf2'
import { format } from '@tabeo/sharpei/utils/currency'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import { useNewTransactionFlow } from '../provider'
import CardLogos from './CardLogos'

export function LinkPaymentOption({ disabled }: { disabled?: boolean }) {
  const { state } = useNewTransactionFlow()
  const { t } = useTranslation()
  const { data } = useMerchant()
  const isAmexCapable = data?.activeFeeGroup?.isAmexCapable

  return (
    <RadioCards.Option value="link" disabled={disabled}>
      <div className="flex items-start gap-x-2">
        <RadioCards.OptionDot />

        <div className="flex flex-1 items-start justify-between">
          <div className="space-y-2">
            <div className="font-medium">{t('Link')}</div>
            <CardLogos amex={isAmexCapable} />
          </div>
          <div className="flex flex-col items-end gap-2">
            {format(state.payment?.price)}
          </div>
        </div>
      </div>
    </RadioCards.Option>
  )
}

export function LinkActions() {
  const { t } = useTranslation()
  const { form, flowSteps, currentIndex } = useNewTransactionFlow()
  const { submitting } = form.current?.getState() || {}

  const { isLast } = flowSteps[currentIndex]
  if (!isLast) {
    return (
      <Button
        variant="primary"
        className="w-full"
        type="submit"
        onClick={form.current?.submit}
      >
        {t('Continue')}
      </Button>
    )
  }

  return (
    <Button
      variant="primary"
      className="w-full"
      type="submit"
      onClick={form.current?.submit}
      loading={submitting}
    >
      {t('Generate payment link')}
    </Button>
  )
}
