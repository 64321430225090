import PropTypes from 'prop-types'
import { useMerchant } from '../../../resources/Merchant'
import Applying from './Applying'
import Approved from './Approved'
import FulfillmentRequired from './FulfillmentRequired'
import LoanStarted from './LoanStarted'
import Opened from './Opened'
import PaymentOnTheWay from './PaymentOnTheWay'
import PayoutScheduled from './PayoutScheduled'
import PNCardDeclined from './PNCardDeclined'
import POTCardDeclined from './POTCardDeclined'
import ReadyToConfirm from './ReadyToConfirm'
import Refunded from './Refunded'
import Review from './Review'
import Sent from './Sent'
import Settled from './Settled'
import Transferred from './Transferred'
import Unsuccessful from './Unsuccessful'

function ActionPanel(props) {
  const {
    data: { bankDetails },
  } = useMerchant()
  const {
    transaction: {
      data: { state, isPOT, revisedPrice, isVoided, price },
    },
  } = props

  if (isVoided) {
    return null
  }

  if (state === 'sent') {
    return <Sent {...props} />
  }
  if (state === 'opened') {
    return <Opened {...props} />
  }
  if (state === 'applying' && isPOT) {
    return <Applying {...props} />
  }
  if (state === 'manual_review_required' && isPOT) {
    return <Review {...props} />
  }
  if (state === 'approved' && isPOT) {
    return <Approved {...props} />
  }
  if (state === 'ready_to_confirm' && isPOT) {
    return <ReadyToConfirm {...props} />
  }
  if (state === 'application_failed') {
    return <Unsuccessful {...props} />
  }
  if (state === 'transferred') {
    return <Transferred {...props} />
  }
  if (state === 'card_declined' && isPOT) {
    return <POTCardDeclined {...props} />
  }
  if (state === 'card_declined' && !isPOT) {
    return <PNCardDeclined {...props} />
  }
  if (state === 'fulfillment_required') {
    return <FulfillmentRequired {...props} />
  }
  if (state === 'funds_on_the_way') {
    return <PaymentOnTheWay merchantBankDetails={bankDetails} {...props} />
  }
  if (
    ['settled', 'payout_canceled'].includes(state) &&
    revisedPrice !== null &&
    revisedPrice !== price
  ) {
    return <Refunded {...props} />
  }
  if (state === 'settled') {
    return <Settled {...props} />
  }
  if (state === 'loan_started') {
    return <LoanStarted {...props} />
  }
  if (state === 'payout_scheduled') {
    return <PayoutScheduled {...props} />
  }

  return null
}

ActionPanel.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default ActionPanel
