import { Flex, View } from '@tabeo/scarf'
import ScarfProps from '@tabeo/scarf/dist/types/ScarfProps'

import amexSvg from './assets/amex.svg'
import applePaySvg from './assets/apple-pay.svg'
import googlePaySvg from './assets/google-pay.svg'
import mastercardSvg from './assets/mastercard.svg'
import visaSvg from './assets/visa.svg'

type PaymentOptionsProps = {
  visa?: boolean
  mastercard?: boolean
  amex?: boolean
  applePay?: boolean
  googlePay?: boolean
} & ScarfProps

function PaymentOptions({
  visa = true,
  mastercard = true,
  amex = false,
  applePay = true,
  googlePay = true,
  ...rest
}: PaymentOptionsProps) {
  return (
    <Flex mx="auto" justifyContent="center" className="space-x-3" {...rest}>
      {visa && <View as="img" src={visaSvg} alt="visa" />}
      {mastercard && <View as="img" src={mastercardSvg} alt="mastercard" />}
      {amex && <View as="img" src={amexSvg} alt="amex" />}
      {applePay && <View as="img" src={applePaySvg} alt="apple pay" />}
      {googlePay && <View as="img" src={googlePaySvg} alt="google pay" />}
    </Flex>
  )
}

export default PaymentOptions
