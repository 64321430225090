import { useRef } from 'react'
import { FormSpy } from 'react-final-form'

function AutoSave({
  debounce,
  onSave,
}: {
  debounce: number
  onSave: (values: any) => void
}) {
  const debounceRef = useRef<number | null>(null)
  const previousValuesRef = useRef<any>(null)
  const previousDidSubmit = useRef<boolean>(false)

  return (
    <FormSpy
      subscription={{ values: true, submitFailed: true, submitSucceeded: true }}
    >
      {({ values = {}, submitFailed, submitSucceeded }) => {
        const didSubmit = submitFailed || submitSucceeded

        if (
          JSON.stringify(previousValuesRef.current) ===
            JSON.stringify(values) &&
          previousDidSubmit.current === didSubmit
        ) {
          return null
        }

        if (debounceRef.current) {
          window.clearTimeout(debounceRef.current)
        }
        debounceRef.current = window.setTimeout(() => {
          onSave(values)
        }, debounce)

        previousValuesRef.current = values
        previousDidSubmit.current = didSubmit

        return null
      }}
    </FormSpy>
  )
}

export default AutoSave
