import { DataTable, GridTable } from '@tabeo/scarf'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ACTIVE_STATUSES,
  INACTIVE_STATUSES,
  INCOMPLETE_STATUSES,
  SENT_STATUSES,
} from 'resources/normalizers/subscription'
import { useSubscriptions } from 'resources/Subscriptions'
import ListItem from './ListItem'

function Subscriptions(props) {
  const { t } = useTranslation()
  return (
    <DataTable
      tabs={[
        { label: t('All'), statuses: [], group: 'singleSelect' },
        { label: t('Sent'), statuses: SENT_STATUSES },
        { label: t('Pending'), statuses: INCOMPLETE_STATUSES },
        { label: t('Active'), statuses: ACTIVE_STATUSES },
        { label: t('Paused'), statuses: INACTIVE_STATUSES },
      ]}
      resourceHook={useSubscriptions}
      paginationLimit={6}
      searchPlaceholder={t('Search a subscription by client name')}
      data-testid="subscriptions-table"
      {...props}
    >
      {({ items }) => (
        <GridTable
          templateColumns={[
            'minmax(65px,auto) minmax(140px, 1fr) minmax(140px, 1fr) auto auto',
          ]}
          minWidth="650px"
        >
          {items.map(item => (
            <ListItem key={item.id} data={item} />
          ))}
        </GridTable>
      )}
    </DataTable>
  )
}

export default Subscriptions
