import React from 'react'
import { MetaProp } from '../../types/form'
import ScarfProps from '../../types/ScarfProps'
import { Subheading } from '../Text'
import View from '../View'

export type InnerComponentProps<T> = {
  label?: React.ReactNode
  component: React.ComponentType<T>
  meta?: Partial<MetaProp>
  containerProps?: ScarfProps
}

export type InteractiveFieldProps<T> = T &
  InnerComponentProps<T> & {
    ref?: React.Ref<any>
  }

const InteractiveField: <T>(
  props: InteractiveFieldProps<T>
) => JSX.Element | null = React.forwardRef<any, InnerComponentProps<any>>(
  (
    { label, component: Component, meta = {}, containerProps = {}, ...rest },
    ref
  ) => (
    <View {...containerProps}>
      {label && (
        <Subheading
          fontSize="10px"
          color={meta.error ? 'default.red' : 'ink.2'}
          mb={1}
        >
          {label}
        </Subheading>
      )}
      <Component ref={ref} meta={meta} {...rest} />
    </View>
  )
)

export default InteractiveField
