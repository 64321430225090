import { Banner, Flex, Heading, Icons, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function LeadNotification({ lead, ...rest }) {
  const { t } = useTranslation()
  return (
    <Banner color="sea" {...rest}>
      <Flex>
        <Heading color="default.sea">
          {t("Transaction for {{firstName}} {{lastName}}'s {{productName}}", {
            firstName: lead.firstName,
            lastName: lead.lastName,
            productName: lead.product.name,
          })}
        </Heading>
        <View as={Link} to="/" display="inline-flex">
          <Icons.X fill="ink.2" />
        </View>
      </Flex>
    </Banner>
  )
}

LeadNotification.propTypes = {
  lead: PropTypes.object.isRequired,
}

export default LeadNotification
