'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../tslib.es6-1ef680b2.js');
require('i18next');
var index = require('../i18n/index.js');
var config = require('../config.js');
require('raven-js');
require('axios');
require('lodash.get');
var delay = require('./delay.js');
require('./jwt.js');
var api = require('./api.js');
var getSaga = require('./getSaga.js');

var stripe = null;
var getStripe = function () {
    var stripeKey = config.getConfig().STRIPE_KEY;
    if (stripe) {
        return stripe;
    }
    // @ts-ignore
    stripe = window ? window.Stripe(stripeKey) : {};
    return stripe;
};
var getSCASaga = function (uuid) { return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
    var _a, error, saga, errorMessage;
    return tslib_es6.__generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, getSaga.default(uuid)];
            case 1:
                _a = _b.sent(), error = _a.error, saga = _a.saga;
                if (error) {
                    errorMessage = saga.generalStatus === 'failed'
                        ? index.t('There was a problem with your payment. Please do not re-attempt the payment as this may cause you to be charged twice. Please send a message to our support team at https://app.tabeo.co.uk/support.')
                        : index.t('Your card was declined, please try again or use a different card.');
                    return [2 /*return*/, {
                            error: new Error(errorMessage),
                            saga: saga,
                        }];
                }
                return [2 /*return*/, { error: null, saga: saga }];
        }
    });
}); };
var getSCAClientSecret = function (endpoint, payload) {
    if (payload === void 0) { payload = {}; }
    // @ts-ignore
    return api.default.post(endpoint, payload, {
        // @ts-ignore
        whilePending: function (saga, next) { return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
            return tslib_es6.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(saga.status === 'client_secret_received')) return [3 /*break*/, 1];
                        return [2 /*return*/, saga];
                    case 1: return [4 /*yield*/, delay.default(250)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, next()];
                }
            });
        }); },
    });
};
var handlePayment = function (endpoint, payload) { return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
    var response, error, clientSecret, uuid, stripe, stripeError, _a, sagaError, saga;
    return tslib_es6.__generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, getSCAClientSecret(endpoint, payload)];
            case 1:
                response = _b.sent();
                if (response.status.toString().includes('failed')) {
                    error = new Error(response.message);
                    error.response = response;
                    throw error;
                }
                clientSecret = response.projections.clientSecret.id, uuid = response.uuid;
                stripe = getStripe();
                return [4 /*yield*/, stripe.handleCardPayment(clientSecret)];
            case 2:
                stripeError = (_b.sent()).error;
                if (stripeError) {
                    throw stripeError;
                }
                return [4 /*yield*/, getSCASaga(uuid)];
            case 3:
                _a = _b.sent(), sagaError = _a.error, saga = _a.saga;
                if (sagaError) {
                    throw sagaError;
                }
                return [2 /*return*/, saga];
        }
    });
}); };

exports.getSCAClientSecret = getSCAClientSecret;
exports.getSCASaga = getSCASaga;
exports.getStripe = getStripe;
exports.handlePayment = handlePayment;
