import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import BaseHtml from './BaseHtml'

const Wrap = styled(BaseHtml)`
  table td.spacer {
    width: 180px;
  }

  table td.signature-desc {
    max-width: 280px;
  }

  table:nth-of-type(3) td:nth-of-type(2) {
    width: 70%;
  }

  div.planFeatures {
    p {
      margin-left: 0;
    }
  }
`
// TODO: if change in language revisit

const SubscriptionTermsAndConditions = ({ document: { content } }) => {
  const replacedContent = content.replace(
    /-consumerSignedAt-|-consumerFirstNameSignature- -consumerLastNameSignature-|-officerFullNameSignature- trading as -merchantTradingNameSignature-|-merchantNameSignature- trading as -merchantTradingNameSignature-|-officerFullNameSignature-, for and on behalf of -merchantNameSignature-|-tabeoLimitedDirectorSignature-|-tabeoBrokerDirectorSignature-/g,
    ''
  )
  return (
    <Wrap
      dangerouslySetInnerHTML={{
        __html: replacedContent,
      }}
    />
  )
}

SubscriptionTermsAndConditions.propTypes = {
  document: PropTypes.string.isRequired,
}

export default SubscriptionTermsAndConditions
