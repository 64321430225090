import get from 'lodash.get'
import React, { createContext, useContext, useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import {
  createGlobalStyle,
  DefaultTheme,
  ThemeProvider,
} from 'styled-components'
import './css/external-styles.css'
import './css/font.css'
import './css/monospace.css'
import './css/reset.css'
import i18n from './i18n'
import theme from './theme'
import { generatePalette } from './theme/colors'

const GlobalStyle = createGlobalStyle`
  ${
    // SC v5 bug, use external css file instead
    '' /* @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700'); */
  }
  html, body, #root {
    min-height: 100%;
  }
`

// eslint-disable-next-line @typescript-eslint/default-param-last
const setPrimaryColor = (palette = 'teal', theme: DefaultTheme) => {
  const primary = get(theme, `colors.${palette}`) || generatePalette(palette)
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary,
      default: {
        ...theme.colors.default,
        primary: primary[3],
      },
    },
  }
}

export type ContextType = {
  locale: string
  countryCode: string
  primaryColor?: string
  pcaKey: string
}

export const FALLBACK_PCA_KEY = 'UA97-PP22-CG98-HT84'

export const Context = createContext<ContextType>({
  locale: 'en-GB',
  countryCode: 'gb',
  pcaKey: FALLBACK_PCA_KEY,
})

export const useLocale = () => {
  const { locale } = useContext(Context)
  return locale
}

export const useScarfContext = () => useContext(Context)

export type ScarfProviderProps = {
  theme?: DefaultTheme
  primaryColor?: string
  locale?: string
  countryCode?: string
  children: React.ReactNode
  pcaKey: string
}

const ScarfProvider = ({
  theme: propsTheme,
  primaryColor,
  locale = 'en-GB',
  countryCode = 'gb',
  children,
  pcaKey,
}: ScarfProviderProps) => {
  const mergedTheme = { ...theme, ...propsTheme }

  useEffect(() => {
    if (i18n.language !== locale) {
      i18n.changeLanguage(locale)
    }
  }, [locale])

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <Context.Provider value={{ locale, countryCode, primaryColor, pcaKey }}>
      <Context.Consumer>
        {({ primaryColor }) => {
          const themeWithPrimaryColor = setPrimaryColor(
            primaryColor,
            mergedTheme
          )

          return (
            <I18nextProvider i18n={i18n}>
              <ThemeProvider theme={themeWithPrimaryColor}>
                <GlobalStyle />
                {children}
              </ThemeProvider>
            </I18nextProvider>
          )
        }}
      </Context.Consumer>
    </Context.Provider>
  )
}

export default ScarfProvider
