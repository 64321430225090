import {
  Box,
  Button,
  Caption,
  PasswordInput,
  Subheading,
  TextInput,
  View,
} from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import parseQuery from '@tabeo/sharpei/utils/parseQuery'
import {
  email as emailValidate,
  required,
} from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import Layout from 'components/Layout'
import Logo from 'components/Logo'
import DatoNotifications from 'components/Notifications/DatoNotifications'
import config, { getAppUrlForCountry } from 'config'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link, Redirect, withRouter } from 'react-router-dom'
import { useAuth } from 'resources/Auth'
import sanitizeRedirectParam from 'utils/sanitizeRedirectParam'

const { COUNTRY } = config

function LoginForm(props) {
  const {
    location: { state, search },
  } = props

  const {
    data: { token },
    resource: { login },
  } = useAuth()
  const { t } = useTranslation()
  const handleLogin = async values => {
    try {
      await login(values)
    } catch (err) {
      if (err.response && err.response.status === 401) {
        return {
          formError: t(
            'The email and password combination you have used doesn’t match with our records.'
          ),
        }
      }

      if (err.message.startsWith('invalid_country')) {
        const country = err.message.split(':')?.[1]
        const url = getAppUrlForCountry('merchant', country)
        return {
          formError: (
            <span>
              <Trans>
                This account is registered in a different country, please use
                this link to login:
                <br />
                <a href={`${url}/login`}>{{ url }}</a>
              </Trans>
            </span>
          ),
        }
      }

      if (err.message === 'invalid_role') {
        const url = getAppUrlForCountry('app', COUNTRY)
        return {
          formError: (
            <span>
              <Trans>
                You are registered as a Consumer. Please go to{' '}
                <a href={`${url}/login`}>{{ url }}</a> and log in from there
              </Trans>
            </span>
          ),
        }
      }
      if (err.message === 'deleted_user') {
        return {
          formError: t('This user was deleted, please contact support.'),
        }
      }
      return formError(err)
    }
  }

  const { email, redirect } = parseQuery(search)

  if (token) {
    const to = get(state, 'from', '/')
    return <Redirect to={(redirect && sanitizeRedirectParam(redirect)) || to} />
  }

  const initialValues = {
    username: email,
  }

  return (
    <>
      <Layout maxWidth="460px" bg={['white', 'sky.2']}>
        <DatoNotifications />

        <Box variant="content" px={[0, '60px']}>
          <View textAlign="center">
            <Logo
              width={['140px', '160px']}
              maxHeight="none"
              mb={2}
              mx="auto"
              display="block"
            />
            <Subheading color="ink.2">{t('Merchant')}</Subheading>
          </View>

          <View height="1px" bg="sky.1" my={8} />

          <Form onSubmit={handleLogin} initialValues={initialValues}>
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  label={t('Email address')}
                  component={TextInput}
                  placeholder={t('Email address')}
                  name="username"
                  type="email"
                  validate={[required, emailValidate]}
                  maxWidth="100%"
                />
                <Field
                  label={t('Password')}
                  component={PasswordInput}
                  placeholder={t('Password')}
                  name="password"
                  validate={[required]}
                  maxWidth="100%"
                />
                <Button loading={submitting} width="100%" mt={2}>
                  {t('Sign in')}
                </Button>
                <FormError />
              </form>
            )}
          </Form>
          <View textAlign="center" mt={5}>
            <Caption color="ink.0" as={Link} to="/password-reset">
              {t('Forgot your password?')}
            </Caption>
          </View>
        </Box>
      </Layout>
    </>
  )
}

LoginForm.propTypes = {
  location: PropTypes.object.isRequired,
}

export default withRouter(LoginForm)
