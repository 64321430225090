import {
  AddressSelect,
  Button,
  Checkbox,
  Clickable,
  PasswordInput,
  Select,
  Text,
  TextInput,
} from '@tabeo/scarf'
import { name, password, required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import config from 'config'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'

const { LANDING_PAGE_URL } = config
const selected = v => (v === false ? t('Must be selected') : undefined)

const RegisterForm = ({ onSubmit, initialValues }) => {
  const { t } = useTranslation()

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting, values: { principles } }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="firstName"
            component={TextInput}
            label={t('First Name')}
            validate={[required, name]}
          />

          <Field
            name="lastName"
            component={TextInput}
            label={t('Last Name')}
            validate={[required, name]}
          />

          <Field
            component={Select}
            name="jobFunction"
            label={t('Job Function')}
            placeholder={t('Please Select...')}
            options={[
              {
                label: t('Dentist / Veterinarian'),
                value: 'Dentist / Veterinarian',
              },
              {
                label: t('Surgeon / Specialist'),
                value: 'Surgeon / Specialist',
              },
              {
                label: t('Nurse / Hygienist'),
                value: 'Nurse / Hygienist',
              },
              {
                label: t('Director / Owner'),
                value: 'Director / Owner',
              },
              {
                label: t('Admin / Finance'),
                value: 'Admin / Finance',
              },
              {
                label: t('Sales / Marketing'),
                value: 'Sales / Marketing',
              },
            ]}
          />

          <Field
            component={AddressSelect}
            name="addressJSON"
            label={t('Trading Address')}
            validate={[required]}
          />

          <Field
            component={PasswordInput}
            label={t('Password')}
            name="password"
            validate={[required, password]}
            criterias
          />

          <Field
            name="principles"
            component={Checkbox}
            validate={[required, selected]}
          >
            <Text>
              <Trans>
                I agree to the{' '}
                <Clickable
                  as="a"
                  href={`${LANDING_PAGE_URL}/terms-and-conditions`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </Clickable>{' '}
                and{' '}
                <Clickable
                  as="a"
                  href={`${LANDING_PAGE_URL}/privacy-policy`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </Clickable>
                .
              </Trans>
            </Text>
          </Field>

          <Button loading={submitting} disabled={!principles}>
            {t('Sign Up')}
          </Button>

          <FormError />
        </form>
      )}
    </Form>
  )
}

RegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
}

export default RegisterForm
