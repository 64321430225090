import React from 'react'
import { themeGet } from '@styled-system/theme-get'
import styled, { css } from 'styled-components'

import ScarfProps from '../types/ScarfProps'
import View from './View'
import Flex from './Flex'
import Tabs from './Tabs'
import { Heading } from './Text'

type TabProps = {
  active?: boolean
  isPreviousActive?: boolean
}

const Tab = styled(View).attrs(() => ({
  flex: 1,
  textAlign: 'center',
  borderBottom: 1,
  py: 3,
}))<TabProps>`
  cursor: pointer;
  border-left: ${themeGet('borders.1')};
  background: ${themeGet('colors.sky.2')};
  &:first-of-type {
    border-left: 0;
  }
  &:last-of-type {
    border-right: 0;
  }
  ${(p) =>
    p.active &&
    css`
      background: ${themeGet('colors.sky.3')};
      border-radius: 5px 5px 0 0;
      border-bottom-color: ${themeGet('colors.sky.3')};
      border-right: ${themeGet('borders.1')};
    `}
  ${(p) =>
    p.isPreviousActive &&
    `
    border-left: 0;
  `}
`

export type TabbedPanelProps = ScarfProps & {
  initialIndex?: number
  onChange?: (index: number, title: string) => void
}

const TabbedPanel = ({
  initialIndex,
  onChange,
  children,
  ...rest
}: TabbedPanelProps) => (
  <View
    boxShadow={1}
    borderRadius={1}
    border={1}
    style={{ overflow: 'hidden' }}
    {...rest}
  >
    <View>
      <Tabs initialIndex={initialIndex}>
        {({ activeIndex, changeActiveIndex }) => {
          const currentTab = React.Children.toArray(children)[activeIndex]

          if (!React.isValidElement(currentTab)) {
            return null
          }

          return (
            <>
              <Flex justifyContent="strecth" bg="sky.3">
                {React.Children.toArray(children).map(
                  (child, index) =>
                    React.isValidElement(child) && (
                      <Tab
                        key={index}
                        onClick={() => {
                          changeActiveIndex(index)
                          onChange && onChange(index, child.props.title)
                        }}
                        active={activeIndex === index}
                        isPreviousActive={activeIndex === index - 1}
                      >
                        <Heading
                          color={
                            activeIndex === index ? 'default.primary' : 'ink.2'
                          }
                        >
                          {child.props.title}
                        </Heading>
                      </Tab>
                    )
                )}
              </Flex>
              {React.cloneElement(currentTab, currentTab.props)}
            </>
          )
        }}
      </Tabs>
    </View>
  </View>
)

export default TabbedPanel
