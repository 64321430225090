import { Breadcrumb } from '@tabeo/scarf'
import LayoutSideNavbar from 'components/layouts/LayoutSideNavbar'
import SubPage from 'components/layouts/Subpage'
import TwoColumns, { Column } from 'components/layouts/TwoColumns'
import DatoNotifications from 'components/Notifications/DatoNotifications'
import { BankAccount } from 'components/PaymentMethodPanel'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSubscriptionV2 } from 'resources/SubscriptionV2'
import ActionPanel from './ActionPanel'
import BenefitsPanel from './BenefitsPanel'
import ClinicianPanel from './ClinicianPanel'
import Documents from './Documents'
import Header from './Header'
import PatientPanel from './PatientPanel'
import PlanPanel from './PlanPanel'

export default function SubscriptionV2() {
  const { t } = useTranslation()
  const { data } = useSubscriptionV2()

  return (
    <LayoutSideNavbar isLoading={!data}>
      {data && (
        <SubPage
          renderBreadcrumb={props => (
            <Breadcrumb {...props} mb={4}>
              <Breadcrumb.Item as={Link} to="/plans">
                {t('Plans')}
              </Breadcrumb.Item>
              <Breadcrumb.Item
                as={Link}
                to={`/subscriptions/${data?.subscription?.id}`}
              >
                {data?.subscription?.displayId}
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
        >
          <DatoNotifications />
          <Header data={data.subscription} />
          <TwoColumns mt={4}>
            <Column>
              <ActionPanel data={data.subscription} />
              <PlanPanel data={data.plan} />
              <BenefitsPanel data={data.subscription} />
            </Column>
            <Column>
              <PatientPanel data={data} />
              <ClinicianPanel vendorClinician={data.vendorClinician} />
              <BankAccount
                data={
                  data?.paymentMethod
                    ? {
                        // @ts-ignore
                        bankName: data?.paymentMethod?.bank_name,
                        accountNumberEnding:
                          // @ts-ignore
                          data?.paymentMethod?.account_number,
                      }
                    : undefined
                }
              />
              <Documents data={data} />
            </Column>
          </TwoColumns>
        </SubPage>
      )}
    </LayoutSideNavbar>
  )
}
