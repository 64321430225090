import { Breadcrumb, Title } from '@tabeo/scarf'
import { AdditionalDetailsPanel } from 'components/AdditionalDetailsPanel'
import ClinicianPanel from 'components/ClinicianPanel'
import DatoNotifications from 'components/Notifications/DatoNotifications'
import PatientPanel from 'components/PatientPanel'
import PayoutsPanel from 'components/PayoutsPanel'
import TreatmentPanel from 'components/TreatmentPanel'
import LayoutSideNavbar from 'components/layouts/LayoutSideNavbar'
import SubPage from 'components/layouts/Subpage'
import TwoColumns, { Column } from 'components/layouts/TwoColumns'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useTerminalPayment } from 'resources/TerminalPayment'
import PaymentMethodPanel from '../../components/checkout-payment/PaymentMethodPanel'
import PaymentRefund from '../../components/checkout-payment/PaymentRefund'
import RefundsPanel from '../../components/checkout-payment/RefundsPanel'
import SummaryPanel from '../../components/checkout-payment/SummaryPanel'
import ActionPanel from './ActionPanel'
import ActivityPanel from './ActivityPanel'

function TerminalPayment() {
  const { t } = useTranslation()
  const { data, resource } = useTerminalPayment()
  const { charges } = data || {}
  const charge = charges?.at(-1)

  return (
    <LayoutSideNavbar isLoading={!data}>
      {data && (
        <SubPage
          renderBreadcrumb={props => (
            <Breadcrumb {...props} mb={4}>
              <Breadcrumb.Item as={Link} to="/terminal-payments">
                {t('Terminal')}
              </Breadcrumb.Item>
              <Breadcrumb.Item as={Link} to={`/terminal-payments/${data?.id}`}>
                {data?.displayID}
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
        >
          <DatoNotifications />
          <Title mr={[0, 3]}>
            {t('Terminal Payment {{id}}', { id: data?.displayID })}
          </Title>
          <TwoColumns mt={4}>
            <Column>
              <PaymentRefund.Provider
                payment={data}
                onPaymentRefetch={resource?.fetch}
                onRefundRequest={resource?.requestRefund}
                onReceiptSend={resource?.sendReceipt}
              >
                <ActionPanel data={data} />
                <SummaryPanel
                  data={data}
                  onReceiptSend={v => resource?.sendReceipt(v)}
                />
                <PayoutsPanel items={data?.payouts} />
                <AdditionalDetailsPanel basket={data.basket} />
                <RefundsPanel refunds={data?.refunds} />
                <ActivityPanel data={data} />
              </PaymentRefund.Provider>
            </Column>
            <Column>
              <PatientPanel basket={data.basket} />
              <ClinicianPanel basket={data.basket} />
              <TreatmentPanel basket={data.basket} />
              {charge && (
                <PaymentMethodPanel
                  brand={charge.brand}
                  lastFourDigits={charge.last4}
                  expiryMonth={charge.expMonth}
                  expiryYear={charge.expYear}
                  funding={charge.funding}
                />
              )}
            </Column>
          </TwoColumns>
        </SubPage>
      )}
    </LayoutSideNavbar>
  )
}

export default TerminalPayment
