import { Caption, Flex, Heading, View } from '@tabeo/scarf'
import Card from 'components/Transaction/ActionPanel/Card'
import PropTypes from 'prop-types'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import icon from './assets/transferred.svg'
import InvoiceButton from './InvoiceButton'
import RefundButton from './Refund'

function Refunded({ transaction, ...rest }) {
  const { data } = transaction

  const { t } = useTranslation()

  const isPartiallyRefunded = data?.revisedPrice > 0

  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} transform="rotate(180deg)" />
        <Heading fontWeight="semibold">
          {isPartiallyRefunded ? t('Partially refunded') : t('Fully refunded')}
        </Heading>
      </Flex>
      <Caption color="ink.1" mb={4}>
        {isPartiallyRefunded ? (
          <Trans>
            This transaction was partially refunded. For more information,
            please review the refunds panel.
          </Trans>
        ) : (
          <Trans>
            This transaction was fully refunded. For more information, please
            review the refunds panel.
          </Trans>
        )}
      </Caption>
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="flex-start"
        alignItems={['stretch', 'center']}
      >
        <InvoiceButton />
        {isPartiallyRefunded && (
          <RefundButton ml={[0, 4]} mt={[4, 0]} transaction={transaction} />
        )}
      </Flex>
    </Card>
  )
}

Refunded.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default Refunded
