import { Caption, Flex } from '@tabeo/scarf'
import { AdditionalDetailsPanel } from 'components/AdditionalDetailsPanel'
import BillStateTag from 'components/BillStateTag'
import Notifications from 'components/Notifications'
import ContactPanel from 'components/Transaction/ContactPanel'
import Header from 'components/Transaction/Header'
import HistoryPanel from 'components/Transaction/HistoryPanel'
import PaymentMethodPanel from 'components/Transaction/PaymentMethodPanel'
import LayoutSideNavbar from 'components/layouts/LayoutSideNavbar'
import Subpage from 'components/layouts/Subpage'
import { useTranslation } from 'react-i18next'
import { useBill } from 'resources/Bill'
import { getCurrentState } from '../../resources/normalizers/bill'
import ActionPanel from './ActionPanel'
import BreakdownPanel from './BreakdownPanel'

function Bill() {
  const { data } = useBill()

  const {
    id,
    consumerFirstName,
    consumerLastName,
    consumerEmail,
    consumerPhoneNumber,
    consumer,
    title,
    stateMachineTransitions,
    state,
    createdAt,
    paymentSource,
    amount,
    netProceeds,
    feeAmount,
    consumerGender,
    basket,
  } = data || {}

  const consumerUserStatus = consumer?.user?.status
  const { t } = useTranslation()

  return (
    <LayoutSideNavbar isLoading={!data}>
      {data && (
        <Subpage maxWidth="620px">
          <Notifications mb={6} />

          <Header
            consumerFirstName={consumerFirstName}
            consumerLastName={consumerLastName}
            title={title}
            state={state}
            tag={<BillStateTag state={state} />}
            mb={6}
          />

          <ActionPanel data={data} mb={6} />

          <ContactPanel
            email={consumerEmail}
            phone={consumerPhoneNumber}
            isUserVerified={consumerUserStatus === 'activated'}
            mb={6}
          />

          <BreakdownPanel
            price={amount}
            netProceeds={netProceeds}
            feeAmount={feeAmount}
            state={state}
            consumerGender={consumerGender}
            mb={6}
          />

          <AdditionalDetailsPanel basket={basket} mb={6} />

          <PaymentMethodPanel paymentSource={paymentSource} mb={6} />

          <HistoryPanel
            history={stateMachineTransitions}
            sentAt={createdAt}
            getCurrentState={getCurrentState}
            mb={6}
          />

          <Flex justifyContent="flex-end">
            <Caption>{t('Bill ID: {{id}}', { id })}</Caption>
          </Flex>
        </Subpage>
      )}
    </LayoutSideNavbar>
  )
}

export default Bill
