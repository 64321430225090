import { Button, Caption, Flex, Heading, View } from '@tabeo/scarf'
import FieldError from 'components/Form/FieldError'
import Card from 'components/Transaction/ActionPanel/Card'
import usePromise from 'hooks/usePromise'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import icon from './assets/approved.svg'

function Approved({ transaction, ...rest }) {
  const { data, sendReminder, cancel } = transaction
  const {
    consumerFirstName,
    consumerHeShe,
    stateMachineTransitions,
    isIFinance,
    isMerchantConfirmationFlow,
  } = data
  const [triggerReminder, reminderState] = usePromise(sendReminder)
  const [triggerCancel, cancelState] = usePromise(cancel)
  const { t } = useTranslation()
  const event = stateMachineTransitions.find(e =>
    ['manual_review_omitted', 'manual_review_passed'].includes(e.to)
  )
  const signEvent = stateMachineTransitions.find(e =>
    ['loan_agreement_accepted'].includes(e.to)
  )
  const elapsedTime = moment().to(signEvent?.at || event.at)
  let hisHerText =
    consumerHeShe === 'he'
      ? t(
          "{{consumerFirstName}}'s application was approved {{elapsedTime}}, but he still needs to sign the loan agreement and make the first payment, his loan won’t start until then.",
          {
            consumerFirstName,
            elapsedTime,
          }
        )
      : t(
          "{{consumerFirstName}}'s application was approved {{elapsedTime}}, but she still needs to sign the loan agreement and make the first payment, her loan won’t start until then.",
          {
            consumerFirstName,
            elapsedTime,
          }
        )

  if (signEvent && !isMerchantConfirmationFlow) {
    hisHerText = t(
      'The customer has signed the loan agreement {{elapsedTime}}, but now needs to make the first payment in order for their loan to start. Your payout will be issued shortly after the customer starts the loan.',
      { elapsedTime }
    )
  }

  if (signEvent && isMerchantConfirmationFlow) {
    hisHerText = t(
      'The customer has signed the loan agreement but now needs to add their debit card in order for you to start this loan. Once they save their debit card you will be able to confirm the loan.'
    )
  }

  if (isIFinance) {
    hisHerText =
      consumerHeShe === 'he'
        ? t(
            "{{consumerFirstName}}'s application was approved {{elapsedTime}}, but still needs to finish setting up his loan.",
            {
              consumerFirstName,
              elapsedTime,
            }
          )
        : t(
            "{{consumerFirstName}}'s application was approved {{elapsedTime}}, but still needs to finish setting up her loan.",
            {
              consumerFirstName,
              elapsedTime,
            }
          )
  }

  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Loan approved')}</Heading>
      </Flex>
      <Caption color="ink.1" mb={4}>
        {hisHerText}
      </Caption>
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="flex-start"
        alignItems={['stretch', 'center']}
      >
        <Button
          variant="secondary"
          sizeVariant="sm"
          mr={[0, 4]}
          mb={[4, 0]}
          onClick={triggerReminder}
          loading={reminderState.pending}
          disabled={reminderState.success}
        >
          {reminderState.success ? t('Reminder sent') : t('Send reminder')}
        </Button>
        <Button
          variant="secondary"
          sizeVariant="sm"
          onClick={triggerCancel}
          loading={cancelState.pending}
        >
          {t('Cancel transaction')}
        </Button>
      </Flex>
      {(reminderState.error || cancelState.error) && (
        <FieldError
          message={t('There was an error, please try again later.')}
          mt={4}
        />
      )}
    </Card>
  )
}

Approved.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default Approved
