import React from 'react'
import Svg, { G, SvgProps } from './Base'

function Assignee({ fill, ...props }: SvgProps) {
  return (
    <Svg {...props}>
      <G
        fill="none"
        fillRule="evenodd"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(0 .5)"
      >
        <path d="M15.907 16.013V14.68a2.667 2.667 0 00-2.667-2.667H8.574a2.667 2.667 0 00-2.667 2.667v1.333" />
        <circle cx={10.907} cy={8.013} r={2} />
      </G>
    </Svg>
  )
}

export default Assignee
