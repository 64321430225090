// @ts-ignore
import css from '@styled-system/css'

const hover = '&:hover'
const active = '&:active, &[data-active=true]'
const focus = '&:focus'
const visited = '&:visited'
const even = '&:nth-of-type(even)'
const odd = '&:nth-of-type(odd)'
const disabled =
  '&:disabled, &:disabled:focus, &:disabled:hover, &[aria-disabled=true], &[aria-disabled=true]:focus, &[aria-disabled=true]:hover'
const checked = '&[aria-checked=true]'
const mixed = '&[aria-checked=mixed]'
const selected = '&[aria-selected=true]'
const invalid = '&[aria-invalid=true]'
const pressed = '&[aria-pressed=true]'
const readOnly = '&[aria-readonly=true], &[readonly]'
const first = '&:first-of-type'
const last = '&:last-of-type'
const expanded = '&[aria-expanded=true]'
const grabbed = '&[aria-grabbed=true]'
const notFirst = '&:not(:first-of-type)'
const notLast = '&:not(:last-of-type)'
const groupHover = '[role=group]:hover &'

export type PseudoProps<T = any> = {
  _after?: T
  _focus?: T
  _selected?: T
  _focusWithin?: T
  _hover?: T
  _invalid?: T
  _active?: T
  _disabled?: T
  _grabbed?: T
  _pressed?: T
  _expanded?: T
  _visited?: T
  _before?: T
  _readOnly?: T
  _first?: T
  _notFirst?: T
  _notLast?: T
  _last?: T
  _placeholder?: T
  _checked?: T
  _groupHover?: T
  _mixed?: T
  _odd?: T
  _even?: T
}

function pseudoStyles({
  _after,
  _focus,
  _selected,
  _focusWithin,
  _hover,
  _invalid,
  _active,
  _disabled,
  _grabbed,
  _pressed,
  _expanded,
  _visited,
  _before,
  _readOnly,
  _first,
  _notFirst,
  _notLast,
  _last,
  _placeholder,
  _checked,
  _groupHover,
  _mixed,
  _odd,
  _even,
  theme,
}: PseudoProps & { theme: any }) {
  return css({
    [hover]: _hover,
    [focus]: _focus,
    [active]: _active,
    [visited]: _visited,
    [disabled]: _disabled,
    [selected]: _selected,
    [invalid]: _invalid,
    [expanded]: _expanded,
    [grabbed]: _grabbed,
    [readOnly]: _readOnly,
    [first]: _first,
    [notFirst]: _notFirst,
    [notLast]: _notLast,
    [last]: _last,
    [odd]: _odd,
    [even]: _even,
    [mixed]: _mixed,
    [checked]: _checked,
    [pressed]: _pressed,
    [groupHover]: _groupHover,
    '&:before': _before,
    '&:after': _after,
    '&:focus-within': _focusWithin,
    '&::placeholder': _placeholder,
  })({ theme })
}

export default pseudoStyles
