import { GridTableCell } from '@tabeo/scarf'
import styled from 'styled-components'

const Cell = styled(GridTableCell).attrs(p => ({
  bg: p.bg ? p.bg : p.odd ? 'sky.2' : 'white',
}))`
  &:first-of-type {
    padding-left: ${p => p.px};
  }
  &:last-of-type {
    padding-right: ${p => p.px};
  }
`

Cell.defaultProps = {
  py: 2,
  borderTop: 1,
}

export default Cell
