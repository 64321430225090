import Canceled from 'components/checkout-payment/ActionPanel/Canceled'
import CancellationFailed from 'components/checkout-payment/ActionPanel/CancellationFailed'
import Paid from 'components/checkout-payment/ActionPanel/Paid'
import PartiallyRefunded from 'components/checkout-payment/ActionPanel/PartiallyRefunded'
import RefundRequested from 'components/checkout-payment/ActionPanel/RefundRequested'
import Refunded from 'components/checkout-payment/ActionPanel/Refunded'
import Settled from 'components/checkout-payment/ActionPanel/Settled'
import { NormalizedLinkPayment } from 'types/NormalizedLinkPayment'
import Expired from './Expired'
import Failed from './Failed'
import Sent from './Sent'
import Viewed from './Viewed'

function ActionPanel({ data }: { data: NormalizedLinkPayment }) {
  if (data.status === 'c') {
    return <Sent />
  }

  if (data.status === 'viewed') {
    return <Viewed />
  }

  if (data.status === 'p') {
    return <Paid />
  }

  // @ts-ignore
  if (data.status === 'charge_failed') {
    return <Failed />
  }

  if (data.status === 's') {
    return <Settled />
  }

  // @ts-ignore
  if (data.status === 'expired') {
    return <Expired />
  }

  if (data.status === 'pi_cancelled') {
    return <Canceled />
  }

  if (data.status === 'pi_cancelled_error') {
    return <CancellationFailed />
  }

  if (data.status === 'refund_req') {
    return <RefundRequested />
  }

  if (data.status === 'pr') {
    return <PartiallyRefunded />
  }

  if (data.status === 'fr') {
    return <Refunded />
  }

  // despute pending

  // charged back

  return null
}

export default ActionPanel
