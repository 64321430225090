import * as Sentry from '@sentry/react'
import dino from 'assets/dino.png'
import Layout from 'components/Layout'
import { useEffect } from 'react'
import Failed from './Failed'

export default function ErrorBoundaryWrapper({
  children,
}: {
  children: React.ReactNode
}) {
  useEffect(() => {
    const img = new Image()
    img.src = dino
  }, [])
  return (
    <Sentry.ErrorBoundary
      fallback={
        <Layout bg={['white', 'sky.2']} isLoading={false}>
          <Failed />
        </Layout>
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
