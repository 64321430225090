import styled from 'styled-components'
import ScarfProps from '../types/ScarfProps'
import View from './View'

export type FlexProps = ScarfProps // & {}

const Flex = styled(View)<FlexProps>``

Flex.defaultProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

export default Flex
