import { Flex, View } from '@tabeo/scarf'
import { children } from '@tabeo/sharpei/utils/propTypes'
import LayoutSideNavbar from 'components/layouts/LayoutSideNavbar'
import Subpage from 'components/layouts/Subpage'
import Notifications from 'components/Notifications'
import React from 'react'

const SubPage = ({ left, right }) => (
  <LayoutSideNavbar>
    <Subpage>
      <View>
        <Notifications mb={6} mx={[0, '10px']} />
        <Flex alignItems="flex-start" flexDirection={['column', 'row']}>
          <View mr={[0, '10px']} flex="1">
            {left}
          </View>
          <View
            mx={[0, '10px']}
            width={['100%', '380px']}
            position="sticky"
            style={{ top: 0 }}
          >
            {right}
          </View>
        </Flex>
      </View>
    </Subpage>
  </LayoutSideNavbar>
)

SubPage.propTypes = {
  left: children,
  right: children,
}

export default SubPage
