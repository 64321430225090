import { GridTableCell as Cell, GridTableRow as Row } from '@tabeo/scarf'
import ScarfProps from '@tabeo/scarf/dist/types/ScarfProps'
import { format } from '@tabeo/sharpei/utils/currency'
import { Payout } from '@tabeo/ts-types'
import moment from 'moment'
import PropTypes from 'prop-types'

function ListItem({ data, ...rest }: { data: Payout } & ScarfProps) {
  return (
    <>
      <Row
        _notFirst={{
          '> *': {
            borderTop: 1,
          },
        }}
        {...rest}
      >
        <Cell color="ink.2">
          {moment(data.transactionAt).format('DD MMM YYYY')}
        </Cell>
        <Cell fontWeight="semibold" color="ink.0" textAlign="right">
          {format(data.amount)}
        </Cell>
        <Cell color="ink.2">{data.from.toUpperCase()}</Cell>
        <Cell color="ink.2">{data.displayID}</Cell>
      </Row>
    </>
  )
}

ListItem.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ListItem
