import Connecting from 'components/terminal/Connecting'
import useStripeTerminal from 'hooks/useStripeTerminal'
import { useContext, useEffect, useRef } from 'react'
import TerminalPaymentContext from '../Context'

function ConnectReader() {
  const { connect } = useStripeTerminal()
  const { dispatch, state } = useContext(TerminalPaymentContext)

  const didInit = useRef(false)

  useEffect(() => {
    const fn = async () => {
      try {
        await connect({ retry: true, force: state.forceConnection })
        dispatch({ type: 'reader-connected' })
      } catch (e) {
        dispatch({ type: 'reader-busy' })
      }
    }

    if (!didInit.current) {
      didInit.current = true
      fn()
    }
  }, [connect, dispatch, state.forceConnection])

  return <Connecting minHeight="340px" />
}

export default ConnectReader
