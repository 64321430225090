import { Box, Button, Prompt, Subtitle } from '@tabeo/scarf'
import Layout from 'components/Layout'
import tickSvg from 'components/Signup/assets/tick.svg'
import useAuthGuard from 'hooks/useAuthGuard'
import useFlow from 'hooks/useFlow'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'resources/Auth'
import { useMerchant } from 'resources/Merchant'

function ManualReview() {
  const { t } = useTranslation()
  useAuthGuard()

  const {
    data: merchant,
    resource: { setCustomField },
  } = useMerchant()
  const {
    data: { user },
  } = useAuth()

  const history = useHistory()

  const { loading } = useFlow({ merchant, user })

  return (
    <Layout maxWidth="460px" bg={['white', 'sky.2']} isLoading={loading}>
      <Box variant="content" textAlign="center">
        <img className="mx-auto" src={tickSvg} />
        <Subtitle mb={3} color="sea.3">
          {t('Welcome, {{firstName}}', { firstName: user?.firstName })}
        </Subtitle>
        <Trans>
          <Prompt mb={6}>
            One of our team members will contact you soon to finish your setup.
          </Prompt>
          <Prompt mb={6}>
            In the meantime, you can start using Tabeo to send payment links to
            your customers.
          </Prompt>
        </Trans>
        <Button
          onClick={() => {
            setCustomField('didVisitFinishPage', true)
            history.push('/new-transaction')
          }}
        >
          {t('Start using Tabeo')}
        </Button>
      </Box>
    </Layout>
  )
}

export default ManualReview
