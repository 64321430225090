import { Caption, Flex, Heading, View } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import Card from 'components/Transaction/ActionPanel/Card'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import InvoiceButton from './InvoiceButton'
import RefundButton from './Refund'
import icon from './assets/settled.svg'

function Settled({ transaction, ...rest }) {
  const { data } = transaction
  const {
    sumMerchantNetProceedsTransfers,
    consumerFirstName,
    isCoolingOffPeriodWaived,
    coolingOffPeriodEnds,
    transfers,
    payoutGateway,
    netProceeds,
    isPN,
    isIFinance,
    metadata: { treatmentStartDate },
  } = data

  const {
    data: {
      flags: { is_cooling_off_period_capable: isCoolingOffPeriodCapable },
    },
  } = useMerchant()
  const { t } = useTranslation()

  const getCoolingOffText = () => {
    if (isPN && !treatmentStartDate) {
      return null
    }

    if (isCoolingOffPeriodCapable) {
      if (isCoolingOffPeriodWaived) {
        return t(`{{consumerFirstName}} has waived the cooling-off period.`, {
          consumerFirstName,
        })
      }
      if (coolingOffPeriodEnds && coolingOffPeriodEnds > moment()) {
        return t(`The cooling-off period ends on {{date}}.`, {
          date: coolingOffPeriodEnds.format('DD MMM YYYY'),
        })
      }
      if (coolingOffPeriodEnds && coolingOffPeriodEnds < moment()) {
        return t(`The cooling-off period ended on {{date}}.`, {
          date: coolingOffPeriodEnds.format('DD MMM YYYY'),
        })
      }
    }
  }

  const coolingOffSuffix = getCoolingOffText()

  const transfer = transfers?.[0]

  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Transaction settled')}</Heading>
      </Flex>
      <Caption color="ink.1" mb={4}>
        {payoutGateway === 'hsbcnet' && transfer
          ? t(
              'Your payout of {{amount}} should have arrived in your bank account ending in {{bankNumber}}.',
              {
                bankNumber:
                  transfer.payout.destinationBankAccountNumber.slice(-3),
                amount: format(transfer.amount),
              }
            )
          : isIFinance
          ? t('Your payout of {{amount}} was issued to your bank account.', {
              amount: format(sumMerchantNetProceedsTransfers || netProceeds),
            })
          : t(
              `This transaction is settled, your payout ({{amount}}) should have arrived in your bank account.`,
              { amount: format(sumMerchantNetProceedsTransfers || netProceeds) }
            )}

        <br />
        {coolingOffSuffix}
      </Caption>
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="flex-start"
        alignItems={['stretch', 'center']}
      >
        <RefundButton mr={[0, 4]} mb={[4, 0]} transaction={transaction} />
        <InvoiceButton />
      </Flex>
    </Card>
  )
}

Settled.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default Settled
