import {
  Accordion,
  Attributes,
  Button,
  Confirmation,
  Flex,
  Settings,
} from '@tabeo/scarf'
import usePromise from 'hooks/usePromise'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import formatClinicianName from 'utils/formatClinicianName'
import { getRoleLabelBySector } from './constants'

const { Stack } = Settings

function ClinicianItem({ resource, data, onEditClick, onLinked }) {
  const { t } = useTranslation()
  const {
    id,
    status,
    email: displayEmail,
    phone: displayPhone,
    clinician: { role, email, phone, boards },
    usage,
  } = data

  const {
    data: { sector },
  } = useMerchant()

  const [remove, removeStatus] = usePromise(resource.removeClinician)
  const [toggle, toggleStatus] = usePromise(() =>
    resource.toggleClinicianStatus(data)
  )

  const statuses = {
    activated: {
      color: 'green',
      label: t('Active'),
    },
    deactivated: {
      color: 'grey',
      label: t('Inactive'),
    },
  }

  return (
    <Stack.Item p={0} key={id} _first={{}}>
      <Accordion
        title={formatClinicianName(data)}
        subtitle={getRoleLabelBySector(role, sector)}
        status={statuses[status].label}
        statusColor={statuses[status].color}
        bg="sky.3"
      >
        <Attributes
          sections={[
            [
              ...(boards?.map(b => ({
                label: `${b.board.code} Number`,
                value: b.registrationNumber,
              })) || []),
            ],
            [
              {
                label: t('Email address'),
                value: displayEmail || email,
              },
              {
                label: t('Phone number'),
                value: displayPhone || phone,
              },
            ],
          ]}
        />
        <Flex justifyContent="flex-start" flexWrap="wrap" mt={5} mb="-16px">
          <Button
            variant="secondary"
            sizeVariant="sm"
            mr={4}
            mb={4}
            onClick={onEditClick}
          >
            {t('Edit')}
          </Button>
          <Button
            variant="secondary"
            sizeVariant="sm"
            mr={4}
            mb={4}
            loading={toggleStatus.pending}
            onClick={() => toggle()}
          >
            {status === 'activated'
              ? t('No longer works here')
              : t('Works here again')}
          </Button>
          <Confirmation
            content={t('Are you sure you want to remove?')}
            onConfirm={() => {
              if (usage > 0) {
                onLinked()
              } else {
                remove(id)
              }
            }}
          >
            <Button
              variant="secondary"
              sizeVariant="sm"
              mb={4}
              loading={removeStatus.pending}
            >
              {t('Remove')}
            </Button>
          </Confirmation>
        </Flex>
      </Accordion>
    </Stack.Item>
  )
}

ClinicianItem.propTypes = {
  data: PropTypes.any.isRequired,
  resource: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onLinked: PropTypes.func.isRequired,
}

export default ClinicianItem
