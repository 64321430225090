/* eslint-disable no-bitwise */
import get from 'lodash.get'

export function shadeColor(c, percent) {
  const color = c || '#222222'
  const f = parseInt(color.slice(1), 16)
  const t = percent < 0 ? 0 : 255
  const p = percent < 0 ? percent * -1 : percent
  const R = f >> 16
  const G = (f >> 8) & 0x00ff
  const B = f & 0x0000ff
  return `#${(
    0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  )
    .toString(16)
    .slice(1)}`
}

const alterColor =
  (colorKey, amount) =>
  ({ theme }) => {
    const color = get(theme, `colors.${colorKey}`)
    return shadeColor(color, amount)
  }

export default alterColor
