import {
  Accordion,
  GridTableCell as Cell,
  GridTable,
  Panel,
  GridTableRow as Row,
} from '@tabeo/scarf'
import { accountingFormat, format } from '@tabeo/sharpei/utils/currency'
import { Payout, PayoutPayment } from '@tabeo/ts-types'
import moment from 'moment'
import { ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Summary, { getSummaryStatsForPayments } from './Summary'

function ListItem({
  payment,
  showFees,
  ...rest
}: {
  payment: PayoutPayment
  showFees: boolean
} & ComponentPropsWithoutRef<typeof Row>) {
  const { t } = useTranslation()
  const links = {
    terminal_payment: `/terminal-payments/${payment.ownerID}`,
    link_payment: `/link-payments/${payment.ownerID}`,
    offer: `/transactions/${payment.ownerID}`,
    hsbcnet_transfer: `/transactions/${payment.ownerID}`,
    plan: `/plans/${payment.ownerID}`,
    subscription: `/subscriptions/${payment.ownerID}`,
    bill: `/bills/${payment.ownerID}`,
    manual: '',
  }
  const labels = {
    payment: t('Incoming payment'),
    hsbcnet_transfer: t('Incoming payment'),
    refund: t('Refund'),
  }
  const label = labels[payment.type]
  const displayIDs = {
    terminal_payment: `TP${payment.ownerID}`,
    link_payment: `PL${payment.ownerID}`,
    offer: payment.ownerID,
    plan: payment.ownerID,
    subscription: payment.ownerID,
    bill: payment.ownerID,
    manual: null,
  }
  const displayID = displayIDs[payment.ownerType]
  const linkTo = links[payment.ownerType]
  const date = moment(payment.transactionAt).format('DD MMM YYYY, hh:mm a')
  return (
    <Row
      {...(linkTo ? { as: Link, to: linkTo } : {})}
      // @ts-ignore
      _notFirst={{
        // @ts-ignore
        '>*': {
          borderTop: 1,
        },
      }}
      _hover={{
        // @ts-ignore
        '>*': {
          bg: 'sky.3',
        },
      }}
      {...rest}
    >
      <Cell color="ink.2">
        <div className="flex gap-2 text-tabeo-ink-0">
          {displayID && (
            <span className="text-tabeo-primary-3">{displayID}</span>
          )}
          {label}
        </div>
        <div className="caption mt-1 text-tabeo-ink-2">{date}</div>
      </Cell>
      {showFees && (
        <>
          <Cell
            color="ink.0"
            display="inline-flex"
            alignItems="center"
            justifyContent="flex-end"
            data-testid="gross-amount"
          >
            {payment.type === 'refund'
              ? accountingFormat(payment.gross)
              : format(payment.gross)}
          </Cell>
          <Cell
            color="ink.0"
            display="inline-flex"
            alignItems="center"
            justifyContent="flex-end"
            data-testid="fee-amount"
          >
            {accountingFormat(payment.fee)}
          </Cell>
        </>
      )}
      <Cell
        color="ink.0"
        display="inline-flex"
        alignItems="center"
        justifyContent="flex-end"
        data-testid="net-amount"
      >
        {format(payment.net)}
      </Cell>
    </Row>
  )
}

function TransactionsPanel({
  data,
  ...rest
}: {
  data: Payout
} & ComponentPropsWithoutRef<typeof Panel>) {
  const { t } = useTranslation()
  const { payments } = data

  const groupedPayments = payments?.reduce((acc, payment) => {
    const { ownerType } = payment
    if (!acc[ownerType]) {
      acc[ownerType] = []
    }
    acc[ownerType].push(payment)
    return acc
  }, {} as Record<PayoutPayment['ownerType'], PayoutPayment[]>)

  const groupedPaymentsWithAtLeastOnePayment = Object.entries(
    groupedPayments ?? {}
  ).filter(([, payments]) => payments.length > 0) as [
    PayoutPayment['ownerType'],
    PayoutPayment[]
  ][]

  // We have to merge the terminal_payment and link_payment owner types into one group
  // other groups can stay as they are
  const mergedGroups = groupedPaymentsWithAtLeastOnePayment.reduce(
    (acc, [ownerType, payments]) => {
      if (['terminal_payment', 'link_payment'].includes(ownerType)) {
        acc.terminal_payment = [...(acc.terminal_payment ?? []), ...payments]
      } else if (['subscription', 'plan'].includes(ownerType)) {
        acc.subscription = [...(acc.subscription ?? []), ...payments]
      } else {
        acc[ownerType] = payments
      }
      return acc
    },
    {} as Record<PayoutPayment['ownerType'], PayoutPayment[]>
  )

  const mergedGroupsArray = Object.entries(mergedGroups) as [
    PayoutPayment['ownerType'],
    PayoutPayment[]
  ][]

  const ownerTypeLabels = {
    terminal_payment: t('Cards'),
    link_payment: t('Cards'),
    offer: t('Finance'),
    plan: t('Care Plans'),
    subscription: t('Care Plans'),
    bill: t('Bills'),
    manual: t('Other'),
  }

  return (
    <Panel title={t('Transactions')} {...rest}>
      {mergedGroupsArray.map(([ownerType, payments]) => {
        const { totlatNet } = getSummaryStatsForPayments(payments)
        const shouldFeesBeDisplayed = payments.some(payment => payment.fee < 0)
        return (
          <Accordion
            key={ownerType}
            isOpenByDefault={mergedGroupsArray.length === 1}
            isTight
            header={({ toggle }) => (
              <button
                type="button"
                className="mb-3 flex w-full justify-between"
                onClick={() => toggle()}
              >
                <span className="caption font-medium">
                  {ownerTypeLabels[ownerType]}
                </span>{' '}
                <span className="caption">{format(totlatNet)}</span>
              </button>
            )}
            px={5}
            mt={3}
            borderBottom={1}
          >
            <div className="relative overflow-x-auto">
              <GridTable
                bg="white"
                templateColumns={
                  shouldFeesBeDisplayed
                    ? '1fr repeat(3, minmax(80px, auto))'
                    : '1fr repeat(1, minmax(80px, auto))'
                }
                minWidth={shouldFeesBeDisplayed ? '394px' : undefined}
              >
                <Row>
                  <Cell color="ink.1" bg="sky.2" py={1}>
                    <span className="subheading">{t('Description')}</span>
                  </Cell>
                  {shouldFeesBeDisplayed && (
                    <>
                      <Cell color="ink.1" bg="sky.2" textAlign="right" py={1}>
                        <span className="subheading">{t('Gross')}</span>
                      </Cell>
                      <Cell color="ink.1" bg="sky.2" textAlign="right" py={1}>
                        <span className="subheading">{t('Est. Fees')}</span>
                      </Cell>
                    </>
                  )}
                  <Cell color="ink.1" bg="sky.2" textAlign="right" py={1}>
                    <span className="subheading">
                      {shouldFeesBeDisplayed ? t('Net') : t('Amount')}
                    </span>
                  </Cell>
                </Row>
                {payments?.map(payment => (
                  <ListItem
                    key={payment.id}
                    payment={payment}
                    showFees={shouldFeesBeDisplayed}
                  />
                ))}
              </GridTable>
            </div>
            <Summary payments={payments} showFees={shouldFeesBeDisplayed} />
          </Accordion>
        )
      })}
      <div className="flex items-center justify-between px-5 py-4">
        <div className="caption font-medium">{t('Total payout')}</div>
        <div className="heading">{format(data.totalNetPayout)}</div>
      </div>
    </Panel>
  )
}

export default TransactionsPanel
