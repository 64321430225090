import { Button, Modal, usePromise } from '@tabeo/scarf'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as EyeYellow } from 'components/checkout-payment/assets/eye-yellow.svg'
import LinkPaymentModal from 'components/LinkPayment/Modal/Modal'
import moment from 'moment'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLinkPayment } from 'resources/LinkPayment'

function Viewed() {
  const { t } = useTranslation()

  const { data, resource } = useLinkPayment()

  const [triggerCancel, cancelState] = usePromise(
    resource?.cancel || (() => {})
  )

  const modal = useRef<Modal>(null)

  const viewedEvent = data?.events?.find(
    e => e.eventName === 'TerminalPaymentViewed'
  )

  return (
    <>
      <LinkPaymentModal ref={modal} isReminder />
      <ActionCard data-testid="viewed-action-panel">
        <ActionCard.Header>
          <EyeYellow className="h-6 w-6" />
          {t('Viewed')}
        </ActionCard.Header>
        <ActionCard.Body>
          {t(
            'This payment link was viewed {{elapsedTime}} but no payment has been attempted yet.',
            { elapsedTime: moment().to(viewedEvent?.createdAt) }
          )}
        </ActionCard.Body>
        <ActionCard.Actions>
          <Button
            sizeVariant="sm"
            variant="secondary"
            onClick={() => modal.current?.open({ id: data?.id })}
          >
            {t('Send reminder')}
          </Button>
          <Button
            sizeVariant="sm"
            variant="secondary"
            onClick={triggerCancel}
            loading={cancelState.pending}
          >
            {t('Cancel')}
          </Button>
        </ActionCard.Actions>
        {cancelState.error && (
          <ActionCard.Error>
            {t('There was an error, please try again later.')}
          </ActionCard.Error>
        )}
      </ActionCard>
    </>
  )
}

export default Viewed
