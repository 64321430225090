import React from 'react'
import ScarfProps from '../../types/ScarfProps'
import Flex from '../Flex'
import Icon, { AnimatedSpinnerProps } from '../Icons/Spinner'

export type SpinnerProps = ScarfProps & {
  icon?: AnimatedSpinnerProps
}

const Spinner = ({ icon, ...rest }: SpinnerProps) => (
  <Flex {...rest} justifyContent="center">
    <Icon spinning {...defaultIconProps} {...icon} />
  </Flex>
)

const defaultIconProps = {
  strokeWidth: '3px',
  size: '100px',
  fill: 'default.primary',
}

Spinner.defaultProps = {
  icon: defaultIconProps,
  // eslint-disable-next-line react/default-props-match-prop-types
  minHeight: '60vh',
  // ie 11 bug: https://github.com/philipwalton/flexbugs/issues/231
  // eslint-disable-next-line react/default-props-match-prop-types
  height: '1px',
}

export default Spinner
