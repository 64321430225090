import {
  Caption,
  Flex,
  Subheading,
  Subtitle,
  Text,
  themeGet,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import negative from './assets/negative.svg'
import positive from './assets/positive.svg'
import same from './assets/same.svg'

const Container = styled(View)`
  &:hover {
    background-color: ${themeGet('colors.sky.3')};
  }
  * {
    user-select: none;
  }
`

Container.defaultProps = {
  flex: '1',
  py: 3,
  px: 4,
  cursor: 'pointer',
}

const percentageStates = {
  positive: {
    icon: positive,
    color: 'sea.3',
  },
  negative: {
    icon: negative,
    color: 'red.3',
  },
  same: {
    icon: same,
    color: 'yellow.2',
  },
}

function DataCard({ label, data, ...rest }) {
  const [byNumber, set] = useState(true)
  const { t } = useTranslation()
  const value = byNumber ? data.numberInMonth : data.accumulatedPriceInChosenDay
  const percentageFloat = byNumber
    ? data.numberInChosenDayPercentChange
    : data.accumulatedPriceInChosenDayPercentChange
  const percentage = Math.round(percentageFloat)

  const percentageState =
    percentage === 0 ? 'same' : percentage < 0 ? 'negative' : 'positive'
  return (
    <Container onClick={() => set(!byNumber)} {...rest}>
      <Subheading color="ink.2">{label}</Subheading>

      <View>
        <Flex justifyContent="flex-start" alignItems="baseline">
          <Subtitle mb={[0, 1]} mr={[1, 0]}>
            {byNumber ? value : format(value, t('£'), 0)}
          </Subtitle>
          <Text display={['inline', 'none']} ml={1} color="ink.2">
            {!byNumber
              ? data.numberInMonth
              : format(data.accumulatedPriceInChosenDay, t('£'), 0)}
          </Text>
        </Flex>
        <Flex justifyContent="flex-start">
          <View
            as="img"
            src={percentageStates[percentageState].icon}
            height="20px"
          />
          <Caption color={percentageStates[percentageState].color}>
            {percentage}%
            <Caption pl={1} display={['inline']} color="ink.2">
              {t('from last month')}
            </Caption>
          </Caption>
        </Flex>
      </View>
    </Container>
  )
}

DataCard.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}

export default DataCard
