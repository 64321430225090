import PropTypes from 'prop-types'
import React from 'react'
import LoanStartedDefault from './Default'
import LoanStartedUnfunded from './Unfunded'

function LoanStarted({ transaction, ...rest }) {
  const { data } = transaction

  if (data?.paymentPlan?.lender?.payoutType === 'unfunded') {
    return <LoanStartedUnfunded transaction={transaction} {...rest} />
  }

  return <LoanStartedDefault transaction={transaction} {...rest} />
}

LoanStarted.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default LoanStarted
