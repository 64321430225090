import React from 'react'

import styled from 'styled-components'
import ScarfProps from '../types/ScarfProps'
import View from './View'
import theme from '../theme'

const Wrap = styled(View)`
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
`
const Content = styled(View)``

Content.defaultProps = {
  maxWidth: theme.maxWidths[0],
  width: '100%',
  mx: 'auto',
}

export type ContainerProps = ScarfProps & {
  wrapStyle?: React.CSSProperties
  wrapProps?: ScarfProps
}

const Container = ({
  children,
  bg = 'default.sky',
  wrapStyle,
  wrapProps,
  ...rest
}: ContainerProps) => (
  <Wrap bg={bg} style={{ height: 'auto', ...wrapStyle }} {...wrapProps}>
    <Content {...rest}>{children}</Content>
  </Wrap>
)

export default Container
