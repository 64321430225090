import { Button, Modal, Text, Title, View } from '@tabeo/scarf'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

const key = 'tabeo-user-settings'
const expirationDate = '20200701T000000Z'

function BillIntroduction() {
  const { t } = useTranslation()
  const [settings, setSettings] = useState(() => {
    const item = window.localStorage.getItem(key)

    return JSON.parse(item) || { billIntroduction: true }
  })

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(settings))
  }, [settings])

  const expired = moment() > moment(expirationDate)

  if (!settings.billIntroduction || expired) {
    return null
  }

  return (
    <Modal isOpenByDefault closable={false}>
      {({ isOpen, close }) =>
        isOpen ? (
          <View
            textAlign="center"
            maxWidth={['90%', '460px']}
            mx="auto"
            bg="white"
            borderRadius={1}
            boxShadow={1}
            p={[5, 10]}
          >
            <Title mb={5}>{t('Introducing: Bills')}</Title>
            <Trans>
              <Text mb={5}>
                Bills are a new way to schedule payments in the future.
              </Text>
              <Text mb={5}>
                You can now send your customers a bill and choose when you want
                the payment to be collected.
              </Text>
              <Text mb={5}>
                Bills can be used to take deposits for consultations and are
                also great for collecting payment for treatments that need to be
                paid in advance.
              </Text>
              <Button
                primary
                width="100%"
                mb={5}
                as="a"
                target="_blank"
                href="https://calendly.com/tabeo-customer-success/bills-scheduled-payments"
              >
                Book a demo
              </Button>
            </Trans>
            <Button
              variant="secondary"
              width="100%"
              onClick={() => {
                setSettings({ billIntroduction: false })
                close()
              }}
            >
              {t('Close')}
            </Button>
          </View>
        ) : null
      }
    </Modal>
  )
}

export default BillIntroduction
