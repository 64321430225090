import React from 'react'
import ScarfProps from '../../../types/ScarfProps'
import View from '../../View'

export type RowProps = ScarfProps

function Row({ ...rest }: RowProps) {
  return <View py={[4]} px={[5]} borderTop={1} {...rest} />
}

export default Row
