import { Flex, Subtitle, Title, View } from '@tabeo/scarf'
import { Merge } from '@tabeo/scarf/dist/types/helpers'
import { Button } from '@tabeo/scarf2'
import { format } from '@tabeo/sharpei/utils/currency'
import Tally from 'components/Tally'
import { TransactionDataForm } from 'components/checkout-payment/TransactionDataForm'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useLinkPayment } from 'resources/LinkPayment'
import Share from '../Share'

type LinkPaymentProps = Merge<
  React.ComponentProps<typeof View>,
  {
    id: string | number
    modal: {
      close: () => void
    }
    isReminder?: boolean
  }
>

function LinkPayment({ id, isReminder, modal }: LinkPaymentProps) {
  const { t } = useTranslation()

  const { data: linkPayment } = useLinkPayment(() => {
    if (!id) {
      throw new Error('No id provided')
    }

    return { id }
  })

  const detailPageLink = `/link-payments/${linkPayment?.id}`

  const history = useHistory()
  function handleTransactionDataFormSuccess() {
    history.push(detailPageLink)
  }

  return (
    <View
      maxWidth={['90%', '620px']}
      mx="auto"
      bg="white"
      borderRadius={1}
      boxShadow={1}
      p={[5, 10]}
    >
      <div className="space-y-6">
        <Flex justifyContent="center">
          <Title>{t('Share payment link')}</Title>
        </Flex>

        {linkPayment && (
          <Share linkPaymentId={linkPayment.id} isReminder={isReminder} />
        )}

        {!isReminder && (
          <Tally>
            <Tally.Item>
              <Subtitle fontWeight="semibold">{t('Price')}</Subtitle>
              <Subtitle fontWeight="semibold">
                {format(linkPayment?.amount)}
              </Subtitle>
            </Tally.Item>
          </Tally>
        )}

        {linkPayment?.basket.id && !isReminder && (
          <TransactionDataForm
            basketId={linkPayment?.basket.id}
            onSuccess={handleTransactionDataFormSuccess}
            closeLink={detailPageLink}
          />
        )}

        {isReminder && (
          <Button onClick={modal.close} className="w-full">
            {t('Close')}
          </Button>
        )}
      </div>
    </View>
  )
}

export default LinkPayment
