'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var tslib_es6 = require('../tslib.es6-1ef680b2.js');
require('i18next');
require('../i18n/index.js');
var config = require('../config.js');
var Raven = _interopDefault(require('raven-js'));
var axios = _interopDefault(require('axios'));

var FALLBACK_TIMEOUT = 2000;
// PHONE
var PHONE_BASE_URL = 'https://services.postcodeanywhere.co.uk/PhoneNumberValidation/' +
    'Interactive/Validate/v2.10/json3.ws?Key=';
var validatePhoneNumber = function (number) { return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
    var API_KEY, endpoint, mockedValidResponse, response, e_1;
    return tslib_es6.__generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                API_KEY = config.getConfig().PCA_API_KEY;
                endpoint = "".concat(PHONE_BASE_URL).concat(API_KEY, "&Phone=").concat(encodeURIComponent("".concat(number)));
                mockedValidResponse = { IsValid: 'Yes', NumberType: 'Mobile' };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                if (!config.features.pcaPhoneValidation) {
                    return [2 /*return*/, mockedValidResponse];
                }
                return [4 /*yield*/, axios
                        .get(endpoint, {
                        timeout: FALLBACK_TIMEOUT,
                    })
                        .then(function (response) { return response.data.Items[0]; })];
            case 2:
                response = _a.sent();
                if ((response === null || response === void 0 ? void 0 : response.Error) === '8' &&
                    (response === null || response === void 0 ? void 0 : response.Description) === 'Key daily limit exceeded') {
                    return [2 /*return*/, mockedValidResponse];
                }
                return [2 /*return*/, response];
            case 3:
                e_1 = _a.sent();
                Raven.captureException(e_1);
                return [2 /*return*/, mockedValidResponse];
            case 4: return [2 /*return*/];
        }
    });
}); };
// EMAIL
var EMAIL_BASE_URL = 'https://services.postcodeanywhere.co.uk/EmailValidation/Interactive/' +
    'Validate/v2.00/json3.ws?Timeout=3000&Key=';
var validateEmail = function (email) { return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
    var API_KEY, endpoint, mockedValidResponse, response, e_2;
    return tslib_es6.__generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                API_KEY = config.getConfig().PCA_API_KEY;
                endpoint = "".concat(EMAIL_BASE_URL).concat(API_KEY, "&Email=").concat(encodeURIComponent(email));
                mockedValidResponse = { ResponseCode: 'Valid' };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                if (!config.features.pcaEmailValidation) {
                    return [2 /*return*/, mockedValidResponse];
                }
                return [4 /*yield*/, axios
                        .get(endpoint, {
                        timeout: FALLBACK_TIMEOUT,
                    })
                        .then(function (response) { return response.data.Items[0]; })];
            case 2:
                response = _a.sent();
                if ((response === null || response === void 0 ? void 0 : response.Error) === '8' &&
                    (response === null || response === void 0 ? void 0 : response.Description) === 'Key daily limit exceeded') {
                    return [2 /*return*/, mockedValidResponse];
                }
                return [2 /*return*/, response];
            case 3:
                e_2 = _a.sent();
                Raven.captureException(e_2);
                return [2 /*return*/, mockedValidResponse];
            case 4: return [2 /*return*/];
        }
    });
}); };
// BANK
var BANK_BASE_URL = "".concat('https://services.postcodeanywhere.co.uk/BankAccountValidation/Interactive/' +
    'Validate/v2.00/json3.ws?Key=');
var validateBank = function (sortCode, accountNumber) { return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
    var API_KEY, endpoint, mockedValidResponse, response, e_3;
    return tslib_es6.__generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                API_KEY = config.getConfig().PCA_API_KEY;
                endpoint = "".concat(BANK_BASE_URL).concat(API_KEY, "&AccountNumber=").concat(encodeURIComponent(accountNumber), "&SortCode=").concat(encodeURIComponent(sortCode));
                mockedValidResponse = {
                    IsCorrect: true,
                    IsDirectDebitCapable: true,
                    StatusInformation: 'OK',
                    CorrectedSortCode: sortCode,
                    CorrectedAccountNumber: accountNumber,
                    IBAN: '',
                    Bank: 'Bank account',
                    BankBIC: '',
                    Branch: '',
                    BranchBIC: '',
                    ContactAddressLine1: '',
                    ContactAddressLine2: '',
                    ContactPostTown: '',
                    ContactPostcode: '',
                    ContactPhone: '',
                    ContactFax: '',
                    FasterPaymentsSupported: true,
                    CHAPSSupported: true,
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                if (!config.features.pcaBankValidation) {
                    return [2 /*return*/, mockedValidResponse];
                }
                return [4 /*yield*/, axios
                        .get(endpoint, {
                        timeout: FALLBACK_TIMEOUT,
                    })
                        .then(function (response) { return response.data.Items[0]; })];
            case 2:
                response = _a.sent();
                if ((response === null || response === void 0 ? void 0 : response.Error) === '8' &&
                    (response === null || response === void 0 ? void 0 : response.Description) === 'Key daily limit exceeded') {
                    return [2 /*return*/, mockedValidResponse];
                }
                return [2 /*return*/, response];
            case 3:
                e_3 = _a.sent();
                Raven.captureException(e_3);
                return [2 /*return*/, mockedValidResponse];
            case 4: return [2 /*return*/];
        }
    });
}); };
// IBAN
var IBAN_BASE_URL = "".concat('https://services.postcodeanywhere.co.uk/InternationalBankValidation/Interactive/' +
    'Validate/v1/json3.ws?Key=');
var validateIban = function (iban) { return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
    var API_KEY, endpoint, mockedValidResponse, response, e_4;
    return tslib_es6.__generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                API_KEY = config.getConfig().PCA_API_KEY;
                endpoint = "".concat(IBAN_BASE_URL).concat(API_KEY, "&IBAN=").concat(encodeURIComponent(iban));
                mockedValidResponse = {
                    IsCorrect: true,
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                if (!config.features.pcaIbanValidation) {
                    return [2 /*return*/, mockedValidResponse];
                }
                return [4 /*yield*/, axios
                        .get(endpoint, {
                        timeout: FALLBACK_TIMEOUT,
                    })
                        .then(function (response) { return response.data.Items[0]; })];
            case 2:
                response = _a.sent();
                if ((response === null || response === void 0 ? void 0 : response.Error) === '8' &&
                    (response === null || response === void 0 ? void 0 : response.Description) === 'Key daily limit exceeded') {
                    return [2 /*return*/, mockedValidResponse];
                }
                return [2 /*return*/, response];
            case 3:
                e_4 = _a.sent();
                Raven.captureException(e_4);
                return [2 /*return*/, mockedValidResponse];
            case 4: return [2 /*return*/];
        }
    });
}); };

exports.BANK_BASE_URL = BANK_BASE_URL;
exports.EMAIL_BASE_URL = EMAIL_BASE_URL;
exports.IBAN_BASE_URL = IBAN_BASE_URL;
exports.PHONE_BASE_URL = PHONE_BASE_URL;
exports.validateBank = validateBank;
exports.validateEmail = validateEmail;
exports.validateIban = validateIban;
exports.validatePhoneNumber = validatePhoneNumber;
