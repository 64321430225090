import {
  Button,
  Caption,
  Flex,
  Icons,
  Spinner,
  StripeInput,
  Subheading,
  TextInput,
  View,
} from '@tabeo/scarf'
import {
  getSCAClientSecret,
  getSCASaga,
  getStripe,
} from '@tabeo/sharpei/utils/stripe'
import { required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FieldError from 'components/Form/FieldError'
import FormError from 'components/Form/FormError'
import config from 'config'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Form } from 'react-final-form'
import { withTranslation } from 'react-i18next'
import {
  CardElement,
  Elements,
  injectStripe,
  PaymentRequestButtonElement,
} from 'react-stripe-elements'

const { CURRENCY, COUNTRY } = config

const CardInput = props => (
  <StripeInput {...props}>
    <CardElement hidePostalCode />
  </StripeInput>
)

class PaymentSourceSCAForm extends Component {
  state = {
    isPaymentRequestButtonAvailable: null,
    paymentRequestError: null,
    clientSecret: null,
    SCASaga: null,
  }

  async componentDidMount() {
    const {
      stripe,
      price,
      onStripeResponse,
      onStripeError,
      onSCASagaPending,
      onSCASagaError,
      onSuccess,
      stripeMethod,
      SCASagaEndpoint,
      SCASagaPayload,
      t,
    } = this.props
    const { title = t('Active card check') } = this.props
    const { clientSecret } = this.state
    // TODO: check currency
    this.paymentRequest = stripe.paymentRequest({
      country: COUNTRY.toUpperCase(),
      currency: CURRENCY,
      total: {
        label: title,
        amount: price,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })

    this.paymentRequest.on(
      'paymentmethod',
      async ({ complete, ...stripeResponse }) => {
        const {
          paymentMethod: { id },
        } = stripeResponse

        // Trigger callback
        onStripeResponse(stripeResponse)

        // We need to create an isolated stripe instance without elements
        const isolatedStripe = getStripe()
        // Initiate setupIntent
        const { error } = await isolatedStripe[stripeMethod](clientSecret, {
          payment_method: id,
        })

        if (error) {
          onStripeError(error)
          this.setState({
            paymentRequestError: error.message,
          })
          return complete('fail')
        }

        onSCASagaPending()
        const { error: SCASagaError, saga } = await getSCASaga(SCASaga.uuid)

        if (SCASagaError) {
          this.setState({
            paymentRequestError: SCASagaError.message,
          })
          onSCASagaError(SCASagaError)
          return complete('fail')
        }

        try {
          await onSuccess(saga)
          complete('success')
        } catch (e) {
          this.setState({
            paymentRequestError: e.message,
          })
          onSCASagaError(e)
          return complete('fail')
        }
      }
    )

    const [isPaymentRequestButtonAvailable, SCASaga] = await Promise.all([
      this.paymentRequest.canMakePayment(),
      getSCAClientSecret(SCASagaEndpoint, SCASagaPayload),
    ])

    this.setState({
      isPaymentRequestButtonAvailable: !!isPaymentRequestButtonAvailable,
      clientSecret: SCASaga.projections.clientSecret.id,
      SCASaga,
    })
  }

  // eslint-disable-next-line camelcase
  handleSubmit = async ({ name, address_zip }) => {
    const {
      stripe,
      onStripeResponse,
      onStripeError,
      onSCASagaPending,
      onSCASagaError,
      onSuccess,
      stripeMethod,
    } = this.props
    const { clientSecret, SCASaga } = this.state

    // Initiate setupIntent
    const stripeResponse = await stripe[stripeMethod](clientSecret, {
      payment_method_data: {
        billing_details: {
          name,
          address: {
            // eslint-disable-next-line camelcase
            postal_code: address_zip,
          },
        },
      },
    })

    const { error: stripeError } = stripeResponse

    onStripeResponse(stripeResponse)

    // Stripe js level error
    if (stripeError) {
      onStripeError(stripeError)
      return {
        card: stripeError.message,
      }
    }

    onSCASagaPending()

    const { error: SCASagaError, saga } = await getSCASaga(SCASaga.uuid)

    // Core API level error
    if (SCASagaError) {
      onSCASagaError(SCASagaError)
      return {
        formError: SCASagaError.message,
      }
    }

    // Success
    return onSuccess(saga)
  }

  render() {
    const {
      initialValues,
      onStripeResponse,
      onStripeError,
      onSCASagaPending,
      onSCASagaError,
      onSuccess,
      stripeMethod,
      SCASagaEndpoint,
      SCASagaPayload,
      additionalWarningMessage,
      t,
      tReady,
      ...rest
    } = this.props
    const {
      submitButtonLabel = t('Submit'),
      disclamerLabel = t(
        'Our payments are secured with 256-Bit RSA Encryption'
      ),
    } = this.props
    const {
      isPaymentRequestButtonAvailable,
      clientSecret,
      paymentRequestError,
    } = this.state
    // Wait for stripe to evaluate if Payment Request Button is supported or not.
    // Wait fo setupIntent client secrec
    if (isPaymentRequestButtonAvailable === null || !clientSecret) {
      return <Spinner minHeight="250px" />
    }

    return (
      <View textAlign="left" {...rest}>
        {isPaymentRequestButtonAvailable && (
          <>
            <View maxWidth={['100%']}>
              <PaymentRequestButtonElement
                paymentRequest={this.paymentRequest}
                className="PaymentRequestButton"
                style={{
                  paymentRequestButton: {
                    theme: 'dark',
                    height: '40px',
                  },
                }}
              />
            </View>
            {paymentRequestError && (
              <FieldError mt={4} message={paymentRequestError} />
            )}
            <Flex my={5}>
              <View flex="1" height="1px" bg="sky.0" />
              <Subheading px={3} color="ink.2">
                {t('or')}
              </Subheading>
              <View flex="1" height="1px" bg="sky.0" />
            </Flex>
          </>
        )}
        <Form onSubmit={this.handleSubmit} initialValues={initialValues}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="card"
                label={t('Card details')}
                validate={required}
                component={CardInput}
                maxWidth="100%"
              />
              <View display={['block', 'flex']}>
                <Field
                  name="name"
                  label={t('Name on card')}
                  placeholder={t('E.g. John Smith')}
                  component={TextInput}
                  validate={required}
                  containerProps={{ flex: 1, mr: [0, 5] }}
                  data-no-track
                />
                <Field
                  name="address_zip"
                  label={t('Billing postcode')}
                  component={TextInput}
                  validate={required}
                  placeholder={t('E.g. W24UJ')}
                  maxWidth="130px"
                  data-no-track
                />
              </View>
              {additionalWarningMessage && (
                <Flex
                  alignItems="flex-start"
                  bg="sky.4"
                  border={1}
                  borderRadius={1}
                  mb={3}
                  p={3}
                >
                  <Icons.Alert fill="red.3" mr={2} />
                  <Caption>{additionalWarningMessage}</Caption>
                </Flex>
              )}
              <Button loading={submitting} width="100%" mt={1}>
                {submitButtonLabel}
              </Button>
              <FormError />
            </form>
          )}
        </Form>
        <View textAlign="center">
          <Flex
            justifyContent="flex-start"
            mt={4}
            display="inline-flex"
            mx="auto"
          >
            <Icons.Lock width="18px" fill="ink.2" mr={2} />
            <Caption letterSpacing="0" textAlign="left">
              {disclamerLabel}
            </Caption>
          </Flex>
        </View>
      </View>
    )
  }
}

PaymentSourceSCAForm.propTypes = {
  onStripeResponse: PropTypes.func,
  onStripeError: PropTypes.func,
  onSCASagaPending: PropTypes.func,
  onSCASagaError: PropTypes.func,
  onSuccess: PropTypes.func,
  stripe: PropTypes.object.isRequired,
  SCASagaEndpoint: PropTypes.string.isRequired,
  SCASagaPayload: PropTypes.object,
  stripeMethod: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.number,
  submitButtonLabel: PropTypes.string,
  disclamerLabel: PropTypes.string,
  initialValues: PropTypes.object,
  additionalWarningMessage: PropTypes.string,
  t: PropTypes.func,
  tReady: PropTypes.bool,
}

PaymentSourceSCAForm.defaultProps = {
  price: 1,
  stripeMethod: 'handleCardSetup',
  // title: 'Active card check',
  // submitButtonLabel: 'Submit',
  // disclamerLabel: 'Our payments are secured with 256-Bit RSA Encryption',
  onStripeResponse: () => {},
  onStripeError: () => {},
  onSCASagaPending: () => {},
  onSCASagaError: () => {},
  onSuccess: () => {},
}

const WrappedForm = injectStripe(withTranslation()(PaymentSourceSCAForm))

const WrappedWrappedForm = props => (
  <Elements
    fonts={[{ cssSrc: 'https://fonts.googleapis.com/css?family=Roboto:400' }]}
  >
    <WrappedForm {...props} />
  </Elements>
)

export default WrappedWrappedForm
