import StateFunnel from 'components/StateFunnel'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import React from 'react'

const getCurrentStep = status => {
  if (['sent'].includes(status)) {
    return t('sent')
  }
  if (['opened'].includes(status)) {
    return t('opened')
  }
  if (['applying'].includes(status)) {
    return t('applying')
  }
  if (['manual_review_required'].includes(status)) {
    return t('review')
  }
  if (['approved'].includes(status)) {
    return t('approved')
  }
  if (['confirm', 'ready_to_confirm'].includes(status)) {
    return t('confirm')
  }
}

const TransactionFunnel = ({
  data: { isPN, state, isMerchantConfirmationFlow },
  ...rest
}) => {
  const currentStep = getCurrentStep(state)

  const pnFlow = [t('sent'), t('opened'), t('paid')]
  const potFlow = [
    t('sent'),
    t('opened'),
    t('applying'),
    currentStep === t('review') ? t('review') : false,
    t('approved'),
    isMerchantConfirmationFlow ? t('confirm') : false,
  ].filter(Boolean)

  const flow = isPN ? pnFlow : potFlow
  const isCardDeclined = state === 'card_declined'

  const currentIndex = isCardDeclined
    ? isPN
      ? 1
      : 3
    : flow.indexOf(currentStep)

  const showFunnelbar = [
    'sent',
    'opened',
    'applying',
    'manual_review_required',
    'approved',
    'card_declined',
    'ready_to_confirm',
  ].includes(state)

  return showFunnelbar ? (
    <StateFunnel
      states={flow}
      currentIndex={currentIndex}
      data-testid="transaction-funnel"
      {...rest}
    />
  ) : null
}

TransactionFunnel.propTypes = {
  data: PropTypes.shape({
    isPN: PropTypes.bool,
    state: PropTypes.string.isRequired,
    isMerchantConfirmationFlow: PropTypes.bool,
  }),
}

export default TransactionFunnel
