import formError from '@tabeo/sharpei/utils/formError'
import { FormApi } from 'final-form'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useMerchant } from 'resources/Merchant'
import { NormalizedMerchant } from 'types/NormalizedMerchant'
import { decodeFromBase64string } from 'utils/base64'
import { useFlowSteps } from '../hooks/useFlowSteps'
import { handlePaymentSubmission } from '../payment-handlers'
import { Modules, NNTSState, ProviderCtx, Step } from '../types'

export const newTransactionCtx = React.createContext<ProviderCtx>({
  modals: {
    cartSummaryModalRef: { current: null },
    legacySuccessModalRef: { current: null },
    linkPaymentModalRef: { current: null },
    terminalPaymentModalRef: { current: null },
  },
  steps: [],
  flowSteps: [],
  next: () => {},
  previous: () => {},
  form: {
    current: undefined,
  },
  state: {},
  setState: () => {},
  currentIndex: 0,
  currentStep: undefined,
  setCurrentStep: () => {},
  setStateForStep: () => {},
  reset: () => {},
})

export function useNewTransactionFlow() {
  return React.useContext(newTransactionCtx)
}

function extendNNTSStepStateWithInitiallyValid(
  state: NNTSState,
  merchant: NormalizedMerchant,
  modules: Modules
) {
  const isCliniciansTreatmentsEnabled =
    merchant?.flags?.is_clinicians_treatments_enabled

  const paymentMethod = state.payment?.paymentMethod

  const validators = {
    treatment: (isCliniciansTreatmentsEnabled
      ? modules.treatmentCT
      : modules.treatment
    ).getSchema({ paymentMethod }),
    patient: modules.patient.getSchema({ paymentMethod }),
    clinician: modules.clinician.getSchema({ paymentMethod }),
  }

  const newState = {
    ...state,
    treatment: {
      ...state.treatment,
      isInitiallyValid: validators.treatment.safeParse(state.treatment).success,
    },
    patient: {
      ...state.patient,
      isInitiallyValid: validators.patient.safeParse(state.patient).success,
    },
    clinician: {
      ...state.clinician,
      isInitiallyValid: validators.clinician.safeParse(state.clinician).success,
    },
  }

  return newState
}

function transformPrefilledInitialState(
  state: NNTSState,
  merchant: NormalizedMerchant
): NNTSState {
  const isCliniciansTreatmentsEnabled =
    merchant?.flags?.is_clinicians_treatments_enabled

  let treatment
  if (Object.keys(state.treatment || {}).length) {
    if (isCliniciansTreatmentsEnabled) {
      treatment = state.treatment
      const otherTreatment = merchant?.merchantTreatments?.find(
        mt => mt.treatment?.name === 'Other'
      )
      treatment = {
        merchantTreatment: otherTreatment,
        merchantTreatmentId: `${otherTreatment?.id}-Other`,
        otherTreatmentName:
          state.treatment?.otherTreatmentName || state.treatment?.treatmentName,
        otherTreatmentCategory: 'General',
      }
    } else {
      treatment = {
        treatmentName: 'other-General',
        otherTreatmentName:
          state?.treatment?.otherTreatmentName ||
          state?.treatment?.treatmentName,
        treatmentCategory: 'General',
      }
    }
  }

  return {
    ...state,
    treatment,
  }
}

export function Provider({
  children,
  modals,
  modules,
}: {
  children: React.ReactNode
  modals: ProviderCtx['modals']
  modules: any
}) {
  const { data: merchant } = useMerchant()

  // Setup the state
  const [state, setReactState] = useState(() => {
    if (!merchant) {
      return {}
    }
    // Read optional initial state from the URL
    const url = new URL(window.location.href)
    const intialEncoded = url.searchParams.get('initialState')
    const initial = decodeFromBase64string(intialEncoded || '') || {}
    url.searchParams.delete('initialState')
    window.history.replaceState({}, '', url.toString())
    return transformPrefilledInitialState(initial, merchant)
  })

  // Extend the state with initially valid based on the current paymentMethod
  const previousPaymentMethod = useRef(state?.payment?.paymentMethod)
  useEffect(() => {
    if (!merchant) {
      return
    }
    if (previousPaymentMethod.current === state?.payment?.paymentMethod) {
      return
    }
    setState(s => extendNNTSStepStateWithInitiallyValid(s, merchant, modules))
    previousPaymentMethod.current = state?.payment?.paymentMethod
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state?.payment?.paymentMethod,
    merchant?.flags?.is_clinicians_treatments_enabled,
  ])

  // Keep the state in a ref to guarantee that the state is always up to date
  // when used in callbacks
  const stateRef = useRef(state)
  function setState(s: NNTSState | ((s: NNTSState) => NNTSState)) {
    if (typeof s === 'function') {
      s = s(stateRef.current)
    }
    stateRef.current = s
    setReactState(s)
  }

  const flowSteps = useFlowSteps(stateRef.current, modules)
  const steps = flowSteps.map(step => step.id)

  const {
    params: { stepId },
  }: {
    params: { stepId: Step | undefined }
  } = useRouteMatch()

  const currentStep = stepId

  // Helper function to set the state for the current step
  const setStateForStep = (step: Step, data: any) => {
    setState((s: NNTSState) => ({
      ...s,
      [step]: data,
    }))
  }

  // http://localhost:3000/nnts?initialState=eyJ0cmVhdG1lbnQiOnsidHJlYXRtZW50TmFtZSI6IlNtaWxlRGlyZWN0Q2x1YiBDbGVhciBBbGlnbmVycyJ9LCJwYXRpZW50Ijp7ImdlbmRlciI6Im0iLCJmaXJzdE5hbWUiOiJQZXRlciIsImxhc3ROYW1lIjoiR29tYm9zIn0sInBheW1lbnQiOnsicHJpY2UiOiIxMjM0In19
  const { push, replace } = useHistory()
  const setCurrentStep = useCallback(
    (stepId: Step | undefined, shouldReplace = false) => {
      const url = new URL(window.location.href)
      const search = url.searchParams.toString()

      const pushOrReplace = shouldReplace ? replace : push
      pushOrReplace(
        `/new-transaction${stepId ? `/${stepId}` : ''}${
          search ? `?${search}` : ''
        }`
      )

      // Scroll to top after step transition
      setTimeout(() => {
        const layoutContent = document.getElementById('layout-content')
        if (layoutContent) {
          layoutContent.scrollTop = 0
        }
      }, 0)
    },
    [push, replace]
  )

  const form = React.useRef<FormApi<any, any>>()

  function reset() {
    // Reset final-form
    const f = form.current
    if (f) {
      f.reset()
      f.getRegisteredFields().forEach(field => {
        if (f.getRegisteredFields().includes(field)) {
          f.resetFieldState(field)
        }
      })
    }

    // Reset state
    setState({})

    // Reset current step
    setCurrentStep(steps[0])
  }

  const currentIndex = steps.indexOf(currentStep as Step)

  // Handle invalid step in URL
  useEffect(() => {
    if (steps.indexOf(currentStep as Step) === -1) {
      setCurrentStep(steps[0], true)
    }
  }, [currentStep, setCurrentStep, steps])

  const nextStep = steps[currentIndex + 1] || null
  async function next() {
    // This should never happen
    if (!merchant) {
      throw new Error('Merchant not loaded')
    }

    if (nextStep) {
      return setCurrentStep(nextStep)
    }

    try {
      await handlePaymentSubmission(stateRef?.current, merchant, modals)
      reset()
    } catch (e) {
      return formError(e)
    }
  }

  const previousStep = steps[currentIndex - 1] || null
  function previous() {
    previousStep && setCurrentStep(previousStep)
  }

  return (
    <newTransactionCtx.Provider
      value={{
        modals,
        steps,
        flowSteps,
        next,
        previous,
        form,
        setStateForStep,
        state,
        setState,
        currentIndex,
        currentStep,
        setCurrentStep,
        reset,
      }}
    >
      {children}
    </newTransactionCtx.Provider>
  )
}
