import { Button, Caption, Icons, Settings, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import ClinicianItem from './ClinicianItem'
import LinkedModal from './LinkedModal'
import AddOrEditClinicianModal from './Modal'

const { Section, Stack } = Settings

function Clinicians({ resource, ...rest }) {
  const modal = useRef()
  const linkedModal = useRef()
  const { merchantClinicians } = resource.data
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get('modal') === 'new-clinician') {
      modal.current.open()
    }
  }, [location.search])

  return (
    <Section {...rest}>
      <AddOrEditClinicianModal
        ref={modal}
        onSubmit={(payload, id) =>
          id
            ? resource.updateClinician(id, payload)
            : resource.addClinician(payload)
        }
        onClose={() => {
          const searchParams = new URLSearchParams(location.search)
          if (searchParams.get('modal') === 'new-clinician') {
            searchParams.delete('modal')
            history.replace({
              search: searchParams.toString(),
            })
          }
        }}
      />
      <LinkedModal ref={linkedModal} />
      <Section.Header flexWrap={['wrap', 'nowrap']}>
        <View>
          <Section.Title>{t('Clinicians')}</Section.Title>
          <Section.Description>
            {t(
              'These are members of the clinical staff that operate at the practice.'
            )}
          </Section.Description>
        </View>
        <Button
          variant="secondary"
          leftIcon={p => <Icons.Plus light {...p} />}
          mt={[5, 0]}
          onClick={() => modal.current.open()}
          sizeVariant="sm"
          ug-id="add-clinician-button"
        >
          {t('Add clinician')}
        </Button>
      </Section.Header>
      <Stack overflow="hidden">
        {merchantClinicians?.length ? (
          merchantClinicians?.map(mc => (
            <ClinicianItem
              key={mc.id}
              data={mc}
              resource={resource}
              onEditClick={() => modal.current.open(mc)}
              onLinked={() => linkedModal.current.open()}
            />
          ))
        ) : (
          <Stack.Item>
            <Caption textAlign="center">
              {t('There are no clinicians yet')}
            </Caption>
          </Stack.Item>
        )}
      </Stack>
    </Section>
  )
}

Clinicians.propTypes = {
  resource: PropTypes.object.isRequired,
}

export default Clinicians
