import ActionCard from 'components/ActionPanel/ActionCard'
import icon from 'components/ActionPanel/assets/sent.svg'
import moment from 'moment'
import PropType from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

function Sent({ data }) {
  const merchnatUserName = `${data.merchantUser.firstName} ${data.merchantUser.lastName[0]}.`
  const { t } = useTranslation()
  return (
    <ActionCard
      icon={icon}
      title={t('Invitation sent')}
      body={t(
        `{{merchnatUserName}} invited {{consumerFirstName}} to join {{plan}} {{elapsedTime}}. {{consumerFirstName}} hasn’t started signing up yet.`,
        {
          merchnatUserName,
          consumerFirstName: data.consumerFirstName,
          plan: data.plan.title,
          elapsedTime: moment().to(data.createdAt),
        }
      )}
    />
  )
}

Sent.propTypes = {
  data: PropType.object.isRequired,
}

export default Sent
