import {
  Attributes,
  Avatar,
  Button,
  Modal,
  Prompt,
  Radio,
  Textarea,
  Title,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import formError from '@tabeo/sharpei/utils/formError'
import { required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import TabbedRadioItem from 'components/Form/TabbedRadioItem'
import moment from 'moment'
import React from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { useRefund } from 'resources/Refund'
import { usePaymentRefundContext } from '../Context'
import reasons from '../reasons'
import Processing from './Processing'
import Succeeded from './Succeeded'

type Value = {
  decision: 'approve' | 'decline'
  note?: string
}

export type ReviewProps = {
  id: string | number
  modal: {
    close: () => void
  }
} & React.ComponentProps<typeof Modal>

function Review({ id, modal, ...rest }: ReviewProps) {
  const { t } = useTranslation()

  const { data: refund, resource } = useRefund({ id })
  const { payment, onPaymentRefetch, onReceiptSend } = usePaymentRefundContext()

  const handleSubmit = async (values: Value) => {
    try {
      if (values.decision === 'decline') {
        await resource?.decline(values.note)
        modal.close()
      }

      if (values.decision === 'approve') {
        await resource?.approve(values.note)
      }

      await onPaymentRefetch?.()
    } catch (e) {
      return formError(e)
    }
  }

  if (!refund || !payment) {
    return null
  }

  return (
    <View
      maxWidth={['90%', '620px']}
      mx="auto"
      bg="white"
      borderRadius={1}
      boxShadow={1}
      p={[5, 10]}
      {...rest}
    >
      <Form onSubmit={handleSubmit} initialValues={{}} keepDirtyOnReinitialize>
        {({
          handleSubmit,
          submitting,
          submitSucceeded,
          values: { decision },
        }) => {
          if (submitting && decision === 'approve') {
            return <Processing />
          }

          if (submitSucceeded && decision === 'approve') {
            return (
              <Succeeded
                payment={payment}
                refund={refund}
                close={modal.close}
                onReceiptSend={onReceiptSend}
              />
            )
          }

          return (
            <form onSubmit={handleSubmit}>
              <Title mb={5}>{t('Review refund request')}</Title>
              <Attributes
                sections={[
                  [
                    {
                      label: t('Refund'),
                      value: refund.amount,
                      formattedValue: format(refund.amount),
                    },
                    {
                      label: t('Requested on'),
                      value: moment(refund.createdAt).format(
                        'DD MMM YYYY, hh:mm a'
                      ),
                    },
                    {
                      label: t('Requested by'),
                      value: !!refund.requester?.id,
                      formattedValue: (
                        <div className="flex items-center">
                          <Avatar
                            size="small"
                            name={refund.requester?.name}
                            color="primary.3"
                            mr={1}
                          />{' '}
                          {refund.requester?.name}
                        </div>
                      ),
                    },
                    {
                      label: t('Reason'),
                      value: refund.reasonType,
                      formattedValue:
                        reasons[refund.reasonType] || refund.reasonType,
                    },
                    {
                      label: t('Internal note'),
                      value: refund.reasonNote,
                    },
                  ],
                ]}
                mb={6}
              />
              <Field
                name="decision"
                label={t('Decision')}
                component={Radio}
                width="100%"
                horizontal
                items={[
                  { label: t('Approve'), value: 'approve' },
                  { label: t('Decline'), value: 'decline' },
                ]}
                validate={required}
                renderItem={(p: any) => <TabbedRadioItem {...p} />}
              />
              {decision === 'decline' && (
                <Field
                  name="note"
                  label={
                    <Prompt mb={1} fontWeight="semibold">
                      <Trans>
                        Internal note{' '}
                        <Prompt display="inline" color="ink.2">
                          (optional)
                        </Prompt>
                      </Trans>
                    </Prompt>
                  }
                  component={Textarea}
                  width="100%"
                  display="block"
                  borderColor="sky.1"
                />
              )}
              <div className="mt-10">
                <Button loading={submitting} mb={4} width="100%">
                  {decision === 'approve'
                    ? t('Confirm and refund ({{amount}})', {
                        amount: format(refund.amount),
                      })
                    : decision === 'decline'
                    ? t('Decline refund request')
                    : t('Confirm')}
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  onClick={modal.close}
                  width="100%"
                >
                  {t('Close')}
                </Button>
              </div>
              <FormError />
            </form>
          )
        }}
      </Form>
    </View>
  )
}

export default Review
