import React from 'react'
import PropTypes from 'prop-types'
import { Container as Base } from '@tabeo/scarf'

import { responsiveStyle } from '@tabeo/sharpei/utils/propTypes'

const Container = ({ bg, wrapProps, ...rest }) => (
  <Base
    wrapProps={{
      style: {
        height: 'auto',
        minHeight: 'calc(100vh - 60px)',
      },
      ...wrapProps,
      bg,
    }}
    {...rest}
  />
)

Container.propTypes = {
  wrapProps: PropTypes.object,
  bg: responsiveStyle,
}

export default Container
