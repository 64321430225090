import React from 'react'
import PropTypes from 'prop-types'

import { Flex, Icons, Text } from '@tabeo/scarf'

function FieldError({ message, ...rest }) {
  return (
    <Flex
      textAlign="left"
      alignItems="top"
      justifyContent="flex-start"
      {...rest}
    >
      <Icons.Alert fill="red.3" mt="2px" mr={1} />
      <Text color="red.2">{message}</Text>
    </Flex>
  )
}

FieldError.propTypes = {
  message: PropTypes.string.isRequired,
}

export default FieldError
