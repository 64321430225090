import { View } from '@tabeo/scarf'
import get from 'lodash.get'
import PropTypes from 'prop-types'
import React from 'react'
import { withTheme } from 'styled-components'

const Image = ({ theme, ...props }) => {
  const primaryColor = get(theme, 'colors.default.primary')
  return (
    <View
      as="svg"
      width="160px"
      height="160px"
      mx="auto"
      {...props}
      viewBox="0 0 160 160"
    >
      <defs>
        <path
          id="a"
          d="M24.902 40.836L13.905 29.838a1.692 1.692 0 0 1 0-2.393l2.392-2.392c.661-.661 1.732-.661 2.393 0L26.1 32.46l15.868-15.868c.66-.66 1.732-.66 2.392 0l2.393 2.393c.66.66.66 1.732 0 2.392L27.295 40.836c-.66.66-1.732.66-2.393 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F4F6F8"
          stroke="#DFE3E8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M80.496 129.801c-16.936-7.444-28.13-16.257-33.583-26.44-5.453-10.183-7.68-30.875-6.681-62.076 4.583.278 11.256-.903 20.017-3.543 8.76-2.64 15.509-5.22 20.247-7.742 4.738 2.52 11.487 5.102 20.248 7.742 8.761 2.64 15.433 3.82 20.017 3.543.998 31.2-1.228 51.893-6.68 62.076-5.453 10.183-16.648 18.996-33.585 26.44z"
        />
        <path
          fill="#FFF"
          d="M80.997 121.833c-14.219-6.253-23.616-13.656-28.194-22.21-4.577-8.554-6.447-25.935-5.608-52.143 3.848.233 9.449-.76 16.804-2.977 7.354-2.217 13.02-4.385 16.997-6.503 3.978 2.118 9.644 4.285 17 6.503 7.354 2.218 12.955 3.21 16.803 2.977.839 26.208-1.031 43.59-5.608 52.143-4.578 8.554-13.976 15.957-28.194 22.21z"
        />
        <g transform="translate(51 51)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <g fill={primaryColor} mask="url(#b)">
            <path d="M0 0h59v59H0z" />
          </g>
        </g>
      </g>
    </View>
  )
}

Image.propTypes = {
  theme: PropTypes.object,
}

export default withTheme(Image)
