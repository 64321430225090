import React from 'react'
import styled, { DefaultTheme, withTheme } from 'styled-components'
import get from 'lodash.get'

import Svg, { SvgProps } from './Base'

const Questionmark = ({
  fill = 'default.primary',
  theme,
  ...rest
}: SvgProps & { theme: DefaultTheme }) => (
  <Svg {...rest} viewBox="0 0 24 24">
    <defs>
      <circle id="questionmark-a" cx="12" cy="12" r="11" />
      <path
        id="questionmark-c"
        d="M11.086 14.117v-.5c0-.573.104-1.05.312-1.43.209-.38.573-.773 1.094-1.18.62-.489 1.02-.869 1.2-1.14.18-.27.269-.594.269-.969 0-.437-.146-.773-.438-1.007-.291-.235-.71-.352-1.257-.352-.495 0-.953.07-1.375.211-.422.14-.834.31-1.235.508L9 6.883A6.877 6.877 0 0 1 12.398 6c1.021 0 1.831.25 2.43.75.6.5.899 1.19.899 2.07 0 .39-.058.739-.172 1.043a2.993 2.993 0 0 1-.52.871c-.232.276-.631.636-1.2 1.079-.483.38-.808.695-.972.945-.164.25-.246.586-.246 1.008v.351h-1.531zm-.32 2.492c0-.786.382-1.18 1.148-1.18.375 0 .662.104.86.31.197.205.296.495.296.87 0 .37-.1.663-.3.88-.201.215-.486.323-.856.323s-.654-.105-.851-.316c-.198-.21-.297-.506-.297-.887z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use
        fill="#000"
        filter="url(#questionmark-b)"
        xlinkHref="#questionmark-a"
      />
      <use fill="#F9FAFB" stroke="#DFE3E8" xlinkHref="#questionmark-a" />
      <mask id="questionmark-d" fill="#fff">
        <use xlinkHref="#questionmark-c" />
      </mask>
      <g
        fill={get(theme, `colors.${fill}`) || fill}
        mask="url(#questionmark-d)"
      >
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </Svg>
)

export default styled(withTheme(Questionmark))``
