/* eslint-disable no-underscore-dangle */
import { Button, Caption, Title, View } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import { PaymentRefund } from '@tabeo/ts-types'
import Tally from 'components/Tally'
import SendReceipt from 'components/terminal/SendReceipt'
import { ReactComponent as SucceededIcon } from 'components/terminal/TerminalPayment/assets/succeeded-icon.svg'
import succeededImg from 'components/terminal/TerminalPayment/assets/succeeded.png'
import Success from 'components/terminal/TerminalPayment/Success'
import { SUCCESS_ANIMATION_DELAY } from 'config'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NormalizedLinkPayment } from 'types/NormalizedLinkPayment'
import { NormalizedTerminalPayment } from 'types/NormalizedTerminalPayment'

type SucceededProps = {
  payment: NormalizedTerminalPayment | NormalizedLinkPayment
  refund: PaymentRefund
  close: () => void
  onReceiptSend?: (payload: any) => any
}

function Succeeded({ payment, refund, close, onReceiptSend }: SucceededProps) {
  const { t } = useTranslation()

  const [showSummary, setShowSummary] = useState(false)

  useEffect(() => {
    const summaryTimeout = setTimeout(() => {
      setShowSummary(true)
    }, SUCCESS_ANIMATION_DELAY)

    return () => {
      clearTimeout(summaryTimeout)
    }
  }, [setShowSummary])

  const onSubmit = async (payload: {
    email?: string
    phoneNumber?: string
  }) => {
    await onReceiptSend?.(payload)
  }

  if (!showSummary) {
    return (
      <View width="100%" textAlign="center">
        <View as="img" src={succeededImg} width="160px" mx="auto" />
        <Title mt={6}>{t('Refund executed')}</Title>
      </View>
    )
  }

  const charge = payment.charges?.at(-1)

  return (
    <div className="text-center">
      <div className="mb-6 flex items-center justify-center">
        <SucceededIcon className="mr-2" />
        <Title>{t('Refund executed')}</Title>
      </div>

      <Tally>
        <Tally.Item>
          <Tally.Label>
            {payment._type === 'terminal_payment'
              ? t('Terminal Payment ID')
              : t('Link Payment ID')}
          </Tally.Label>
          <Tally.Value data-testid="payment-id">
            {payment.displayID || payment.id}
          </Tally.Value>
        </Tally.Item>
        <Tally.Divider />
        <Tally.Item>
          <Tally.Label>{t('Amount refunded')}</Tally.Label>
          <Tally.Value>{format(refund.amount)}</Tally.Value>
        </Tally.Item>
        {charge && (
          <Tally.Item>
            <Tally.Label>{t('Refund issued to')}</Tally.Label>
            <Tally.CardValue
              brand={charge.brand}
              lastFourDigits={charge.last4}
            />
          </Tally.Item>
        )}
      </Tally>

      <Caption mt={2} textAlign="left">
        {t("Refunds take 5-10 days to appear on customers' bank statements.")}
      </Caption>

      <SendReceipt
        mt={6}
        onSubmit={onSubmit}
        successScreen={<Success />}
        label={t('Send a copy of this receipt to')}
        buttonText={t('Send receipt')}
        buttonVariant="secondary"
        config={{ email: true, phoneNumber: true }}
        initialValues={{
          email: payment?.basket?.patient?.email,
          phoneNumber: payment?.basket?.patient?.phoneNumber,
        }}
      />

      <Button mt={6} onClick={() => close()} width="100%">
        {t('Close')}
      </Button>
    </div>
  )
}

export default Succeeded
