import React from 'react'
import styled from 'styled-components'

import Svg, { SvgProps } from './Base'

const DoubleArrow = (props: SvgProps) => (
  <Svg {...props}>
    <g>
      <path d="M11.654 16.727a1 1 0 0 1-1.31 0L7.314 14.1A.908.908 0 0 1 7 13.415c0-.23.186-.415.415-.415h7.172c.228 0 .413.185.413.413a.905.905 0 0 1-.312.684l-3.034 2.63zm0-11.454l3.034 2.63a.905.905 0 0 1 .312.684.413.413 0 0 1-.413.413H7.415A.415.415 0 0 1 7 8.585c0-.263.114-.513.313-.686l3.03-2.626a1 1 0 0 1 1.31 0z" />
    </g>
  </Svg>
)

export default styled(DoubleArrow)``
