import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'

export default function useTreatments(): {
  name: string
  treatments: string[]
}[] {
  const { data: merchant } = useMerchant()
  const { t } = useTranslation()

  if (!merchant) {
    return []
  }

  const dentalTreatments = merchant.isSDC
    ? [
        {
          name: 'General',
          treatments: [
            t('SmileDirectClub Clear Aligners'),
            t('SmileDirectClub Nighttime Clear Aligners'),
            t('FastTrack SmileDirectClub Clear Aligners'),
            t('FastTrack SmileDirectClub Nighttime Clear Aligners'),
          ],
        },
        {
          name: t('Other'),
          treatments: [t('Other')],
        },
      ]
    : [
        ...(merchant.flags?.is_nhs_treatments_capable
          ? [
              {
                name: 'NHS',
                treatments: [t('NHS Band 1'), t('NHS Band 2'), t('NHS Band 3')],
              },
            ]
          : []),
        {
          name: 'General',
          treatments: [
            t('Check-up'),
            t('Dental Hygiene'),
            t('Deposit'),
            t('Emergency Dental Care'),
            t('Filling(s)'),
            t('Teeth Whitening'),
            t('Tooth Extraction'),
            t('Scale and Polish'),
            t('Root Canal Treatment'),
            t('Fluoride Treatment'),
            t('Inlay/Onlay'),
            t('Scan'),
            t('Sealants'),
            t('Wisdom Tooth Extraction'),
            t('X-ray'),
          ],
        },
        {
          name: 'Specialist',
          treatments: [
            t('Consultation'),
            t('Bridge'),
            t('Crown'),
            t('Denture'),
            t('Implant & Crown'),
            t('Invisalign'),
            t('Veneers'),
            t('Bonding'),
            t('Fixed Braces'),
            t('Implant'),
            t('Lingual braces'),
            t('Retainers'),
            t('Clear Aligners'),
            t('Enamel Shaping'),
            t('Crown Lengthening'),
            t('Botox'),
            t('Fillers'),
            t('Soft Tissue Grafting'),
            t('Scaling and Root Planing'),
          ],
        },
        {
          name: t('Other'),
          treatments: [t('Other')],
        },
      ]

  const opticalTreatments = [
    {
      name: 'General',
      treatments: [
        t('Contact lenses'),
        t('Eye exam'),
        t('New frame'),
        t('New lenses'),
        t('Allergy treatment'),
        t('Red eye treatment'),
        t('Dry eye treatment'),
        t('Conjunctivitis treatment'),
        t('Referral'),
        t('New frame & lenses'),
      ],
    },
    {
      name: 'Specialist',
      treatments: [
        t('Cataract surgery'),
        t('LASIK'),
        t('Glaucoma treatment'),
        t('Corneal Topography'),
        t('Visual acuity test'),
        t('Retinal exam'),
        t('Glaucoma test'),
        t('Color vision test'),
        t('PRK'),
        t('LASEK'),
        t('Diabetic eye care'),
        t('Macular degeneration treatment'),
        t('Capsulotomy'),
        t('Corneal transplant'),
      ],
    },
    {
      name: t('Other'),
      treatments: [t('Other')],
    },
  ].filter(Boolean)

  const veternaryTreatments = [
    {
      name: 'General',
      treatments: [
        t('Annual vaccinations'),
        t('Flea, worm and tick protection'),
        t('Flystrike'),
        t('Micro chipping'),
        t('Referral'),
        t('Dental treatment'),
        t('Nose to tail check'),
        t('Urine exam'),
        t('Blood test'),
        t('Blood pressure test'),
      ],
    },
    {
      name: 'Shop',
      treatments: [t('Medication'), t('Food'), t('Snacks')],
    },
    {
      name: 'Specialist',
      treatments: [
        t('Cruciate Surgery'),
        t('Collapse/Syncope'),
        t('Diarrhoea and Vomiting'),
        t('Fracture Repair'),
        t('Heart Murmur Investigation'),
        t('Inappetance'),
        t('Lump Removal'),
        t('Respiratory Distress'),
        t('Spinal Surgery'),
        t('Chronic Gastrointestinal Disease'),
        t('Chronic Kidney Disease'),
        t('Cushings Disease'),
        t('Diabetes Mellitus'),
        t('Lameness Investigation'),
        t('Neoplasia'),
        t('Ocular Surgery'),
        t('Patellar Surgery'),
        t('Polyuria/Polydipisa'),
      ],
    },
    {
      name: t('Other'),
      treatments: [t('Other')],
    },
  ]

  const hairTransplantTreatments = [
    {
      name: 'General',
      treatments: [
        t('Follicular Unit Transplantation (FUT)'),
        t('Follicular Unit Extraction (FUE)'),
        t('Direct Hair Implantation (DHI)'),
        t('PRP Therapy'),
        t('Low-Level Laser Therapy (LLLT)'),
        t('Topical Hair Loss Solutions'),
        t('Hair Analysis'),
        t('Hair Transplantation Assessment'),
        t('Aftercare Advice'),
        t('Scalp Micro-pigmentation (SMP) Evaluation'),
        t('Hair transplant treatment'),
      ],
    },
    {
      name: t('Other'),
      treatments: [t('Other')],
    },
  ]

  const hearingAidTreatments = [
    {
      name: 'General',
      treatments: [
        t('Hearing assessement'),
        t('Hearing aid fitting'),
        t('Wax removal'),
        t('Hearing aid adjustment and maintenance'),
        t('Deposit'),
        t('Tinnitus management'),
        t('Aural rehabilitation'),
      ],
    },
    {
      name: 'Device',
      treatments: [
        t('Amplifon'),
        t('Bernafon'),
        t('Oticon'),
        t('Phonak'),
        t('ReSound'),
        t('Signia'),
        t('Starkey'),
        t('Widex '),
        t('Unitron '),
      ],
    },
    {
      name: t('Other'),
      treatments: [t('Other')],
    },
  ]

  if (merchant.sector === 'dental_services') {
    return dentalTreatments
  }

  if (merchant.sector === 'optical_care') {
    return opticalTreatments
  }

  if (merchant.sector === 'veterinary_services') {
    return veternaryTreatments
  }

  if (merchant.sector === 'hair_transplant') {
    return hairTransplantTreatments
  }

  if (merchant.sector === 'hearing_aids') {
    return hearingAidTreatments
  }

  return []
}
