import { Button } from '@tabeo/scarf'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePaymentRefundContext } from '../Context'

export type RequestButtonProps = React.ComponentProps<typeof Button>

function RequestButton({ ...rest }: RequestButtonProps) {
  const { t } = useTranslation()

  const { requestModal, payment } = usePaymentRefundContext()
  const hasFailedRefund = !!payment?.refunds?.some(r => r.status === 'failed')

  return (
    <Button
      onClick={() =>
        requestModal?.current?.open({
          payment,
        })
      }
      variant="secondary"
      sizeVariant="sm"
      tooltipProps={
        hasFailedRefund
          ? {
              title: t(
                'Refund attempt unsuccessful. Please refund the patient via an alternative method.'
              ),
              maxWidth: '260px',
            }
          : undefined
      }
      disabled={hasFailedRefund}
      {...rest}
    >
      {t('Request refund')}
    </Button>
  )
}

export default RequestButton
