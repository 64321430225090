import React, { useContext } from 'react'
import ScarfProps from '../../../types/ScarfProps'
import View from '../../View'
import ModuleContext from '../ModuleContext'

export type HighlightProps = ScarfProps

function Highlight({ ...rest }: HighlightProps) {
  const { color } = useContext(ModuleContext)

  return (
    <View
      bg={color}
      height="3px"
      width={['100%', 'calc(100% + 2px)']}
      position="absolute"
      top="-1px"
      left={[0, '-1px']}
      right={[0, '-1px']}
      zIndex="1"
      borderTopRightRadius={[0, 1]}
      borderTopLeftRadius={[0, 1]}
      {...rest}
    />
  )
}

export default Highlight
