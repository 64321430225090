import { Caption, Flex, Icons, TextInput } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

const Input = styled(TextInput)`
  &:after {
    background: none !important;
  }
  overflow: hidden;
`

const Right = styled(Flex)`
  position: absolute;
  top: ${p => (p.visibility === 'hidden' ? '-100px' : '8px')};
  right: 10px;
  opacity: ${p => (p.visibility === 'hidden' ? 0 : 1)};
  transition: opacity 150ms linear;
`

Right.defaultProps = {
  bg: 'sky.2',
  pl: 1,
}

class AutoCopyText extends Component {
  state = {
    copied: false,
  }

  toggleCopied = () => {
    const { copied } = this.state

    this.setState({
      copied: !copied,
    })
  }

  handleOnClick = e => {
    const { copied } = this.state

    const $el = e.target.parentElement.querySelector('input')
    if ($el) {
      clearTimeout(this.timer)
      $el.select()
      document.execCommand('copy')
      this.toggleCopied()
      this.timer = setTimeout(() => {
        copied && this.toggleCopied()
      }, 2000)
    }
  }

  render() {
    const { value, t, tReady, ...rest } = this.props
    const { copied } = this.state
    return (
      <Input
        input={{ value, readOnly: true }}
        maxWidth="100%"
        right={
          <Right visibility={copied ? 'visible' : 'hidden'}>
            <Icons.Tick light fill="primary.2" display="inline-block" />
            <Caption color="primary.2">{t('Copied')}</Caption>
          </Right>
        }
        onClick={this.handleOnClick}
        {...rest}
      />
    )
  }
}

AutoCopyText.propTypes = {
  value: PropTypes.string,
  t: PropTypes.func,
  tReady: PropTypes.bool,
}

export default withTranslation()(AutoCopyText)
