import {
  GridTableCell as Cell,
  GridTableRow,
  Tag,
  themeGet,
} from '@tabeo/scarf'
import ScarfProps from '@tabeo/scarf/dist/types/ScarfProps'
import { format } from '@tabeo/sharpei/utils/currency'
import { PaymentRefund } from '@tabeo/ts-types'
import { states } from 'components/checkout-payment/RefundsPanel'
import moment from 'moment'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Row = styled(GridTableRow)<ScarfProps>`
  & > * {
    border-top: 1px solid ${themeGet('colors.sky.1')};
  }
  &:hover > * {
    background: ${themeGet('colors.sky.3', 'white')};
  }
`

export type ListItemProps = { data: PaymentRefund }

const ListItem = ({ data }: ListItemProps) => {
  return (
    <Row
      bg="white"
      as={Link}
      to={
        data.paymentMethodType === 'terminal_payments'
          ? `/terminal-payments/${data.paymentMethodID}`
          : `/link-payments/${data.paymentMethodID}`
      }
    >
      <Cell color="ink.2">
        {data.paymentMethodType === 'terminal_payments' ? 'TP' : 'PL'}
        {data.paymentMethodID}
      </Cell>
      <Cell data-testid="transaction-consumer">
        {data.basket?.patient?.firstName} {data.basket?.patient?.lastName}
      </Cell>
      <Cell textAlign="right" color="ink.2">
        {format(data.amount)}
      </Cell>
      <Cell color="ink.2">
        <Tag small color={states[data.status]?.color}>
          {states[data.status]?.label || data.status}
        </Tag>
      </Cell>
      <Cell textAlign="right" color="ink.2">
        {moment(data.updatedAt).format('DD MMM YYYY')}
      </Cell>
    </Row>
  )
}

export default ListItem
