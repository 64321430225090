import { Button } from '@tabeo/scarf'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePaymentRefundContext } from '../Context'

export type ReviewButtonProps = { id: string | number } & React.ComponentProps<
  typeof Button
>

function ReviewButton({ id, ...rest }: ReviewButtonProps) {
  const { t } = useTranslation()

  const { reviewModal } = usePaymentRefundContext()

  return (
    <Button
      onClick={() => reviewModal?.current?.open({ id })}
      variant="primary"
      sizeVariant="sm"
      {...rest}
    >
      {t('Review')}
    </Button>
  )
}

export default ReviewButton
