import { Caption, Flex, Text, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import formatClinicianName from 'utils/formatClinicianName'
import { getRoleLabelBySector } from '../constants'

function ClinicianRecord({ data, ...rest }) {
  const { t } = useTranslation()
  const { role, specializations, boards } = data || {}
  const {
    data: { sector },
  } = useMerchant()

  return (
    <View bg="sky.2" mb={5} borderRadius={1} {...rest}>
      <Flex p={3} borderBottom={1} justifyContent="flex-start">
        <Text fontWeight="semibold">{formatClinicianName(data)}</Text>
      </Flex>
      <View p={3}>
        {boards?.map(b => (
          <Flex
            key={b.board.code + b.registrationNumber}
            justifyContent="flex-start"
            _notFirst={{ mt: 1 }}
          >
            <Caption flex={1} mr={5}>
              {t('{{code}} number', { code: b.board.code })}
            </Caption>
            <Caption flex={1} color="ink.0">
              {b.registrationNumber}
            </Caption>
          </Flex>
        ))}
        <Flex justifyContent="flex-start" mt={1}>
          <Caption flex={1} mr={5}>
            {t('Role')}
          </Caption>
          <Caption flex={1} color="ink.0">
            {getRoleLabelBySector(role, sector)}
          </Caption>
        </Flex>
        {!!specializations?.length && (
          <Flex justifyContent="flex-start" mt={1}>
            <Caption flex={1} mr={5}>
              {t('Specialisations')}
            </Caption>
            <Caption flex={1} color="ink.0">
              {specializations.join(', ')}
            </Caption>
          </Flex>
        )}
      </View>
    </View>
  )
}

ClinicianRecord.propTypes = {
  data: PropTypes.any,
}

export default ClinicianRecord
