import { useScarfContext } from '@tabeo/scarf'
import {
  format as sharpeiFormat,
  getDecimalSeparator,
  getSymbol,
  getThousandSeparator,
} from '@tabeo/sharpei/utils/currency'
import { useCallback } from 'react'

const useCurrency = () => {
  const { countryCode } = useScarfContext()
  const symbol = getSymbol(countryCode)
  const thousand = getThousandSeparator(countryCode)
  const decimal = getDecimalSeparator(countryCode)

  const format = useCallback(
    (amount, _, places) => {
      return sharpeiFormat(amount, symbol, places, thousand, decimal)
    },
    [symbol, thousand, decimal]
  )

  const formatDivisional = useCallback(
    amount => {
      if (countryCode === 'gb') {
        return `${amount}p`
      }
      return sharpeiFormat(amount, symbol, 2, thousand, decimal)
    },
    [countryCode, decimal, symbol, thousand]
  )

  return { symbol, format, formatDivisional }
}

export default useCurrency
