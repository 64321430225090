// @ts-nocheck

import { TFunction } from 'i18next'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { useTranslation } from 'react-i18next'
import { Options } from 'react-select'
import { useScarfContext } from '../../../ScarfProvider'
import Text from '../../Text'
import Select, { I18nWrapProps } from '../Select'
import AddressListItem from './AddressListItem'
import {
  getAddressDetails,
  getAddressSuggestionOptions,
  getAddressSuggestions,
} from './pca'

export type AddressSelectProps = I18nWrapProps & {
  initialOptions?: Options
  countryCode?: string
  t: TFunction
  pcaKey: string
}

class AddressSelect extends Component<AddressSelectProps> {
  state = {
    options: [],
    isFetching: false,
    query: null,
  }

  constructor() {
    super()
    this.handleSearch = this.handleSearch.bind(this)
    this.handleOptionSearch = this.handleOptionSearch.bind(this)
    this.handleAddressDetailLookup = this.handleAddressDetailLookup.bind(this)
    this.handleAddressListItemSelect =
      this.handleAddressListItemSelect.bind(this)

    // This it to solve:
    // Stateless function components cannot be given refs. Attempts to access this ref will fail
    this.OptionComponent = createOptionComponent(
      this.handleAddressListItemSelect
    )
  }

  componentDidMount() {
    const { input, initialOptions = [] } = this.props
    const i = input.value ? JSON.parse(input.value) : {}

    // Add default option to initialOptions if exist
    i.Id &&
      initialOptions.push({
        label: i.Label,
        value: input.value,
      })

    this.setState({
      options: [...initialOptions],
    })
  }

  // This method gets called when the user first starts to type an address into the input filed
  handleSearch(input) {
    const { countryCode, pcaKey } = this.props
    // If the input is empty, lets clear the options
    if (!input.trim()) {
      return this.setState({ isFetching: false, query: null })
    }
    // Setting loading spinner
    this.setState({
      isFetching: true,
      query: input,
    })
    // Cooldown of 500 ms
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      // Fetch results
      getAddressSuggestions(pcaKey, input.trim(), countryCode).then((data) => {
        const payload = {
          options: data.map((i) => ({
            value: JSON.stringify(i),
            label: i.Text + (i.Description ? ` - ${i.Description}` : ''),
          })),
          isFetching: false,
        }
        // Render select with populated options
        this.setState(payload)
      })
    }, 500)
  }

  // This method gets called if the selected address is part of a group address container
  handleOptionSearch(containerId) {
    const { countryCode, pcaKey } = this.props
    // Loading and reset options
    this.setState({
      // Uncommented to fix dropdown closing when fetching addresses in for the group
      // options: [],
      isFetching: true,
    })
    // Fetch addresses in the area
    getAddressSuggestionOptions(pcaKey, containerId, countryCode).then(
      (data) => {
        const payload = {
          options: data.map((i) => ({
            value: JSON.stringify(i),
            label: i.Text + (i.Description ? ` - ${i.Description}` : ''),
          })),
          isFetching: false,
        }
        // Render populated options
        this.setState(payload)
      }
    )
  }

  // This method gets called when the final address is found to fetch all details of it
  handleAddressDetailLookup(id) {
    const { countryCode, pcaKey } = this.props
    // Set loader
    this.setState({
      isFetching: false,
    })
    // Fetch results
    return getAddressDetails(pcaKey, id, countryCode).then((response) => {
      // Remove line breaks from Label field
      response.Label = response.Label.replace(/\r?\n|\r/g, ', ')
      // Format option data
      const payload = {
        label: response.Label,
        value: JSON.stringify(response),
      }
      // Render final option
      this.setState({
        isFetching: false,
        options: [payload],
      })
      return payload
    })
  }

  // Custom handler when a list item gets clicked
  handleAddressListItemSelect(option, event, onSelect) {
    const { countryCode } = this.props
    const optionObject = JSON.parse(option.value)
    // Address lookup returned a grouped response
    if (!optionObject.Line1 && optionObject.Type !== 'Address') {
      this.handleOptionSearch(optionObject.Id, countryCode)
      // Address lookup returned the address Id that is needed for a full lookup
    } else if (optionObject.Type === 'Address') {
      this.handleAddressDetailLookup(optionObject.Id, countryCode).then(
        (option) => onSelect(option)
      )
      // If we have all needed data, select local object
    } else {
      onSelect(option)
    }
  }

  render() {
    const { options, isFetching, query } = this.state
    const { t } = this.props
    let noResultsText
    if (!query) {
      noResultsText = t('Type to search')
    } else if (isFetching) {
      noResultsText = t('Loading...')
    } else {
      noResultsText = t('No results found')
    }

    return (
      <Select
        placeholder={t('Enter address or postcode here...')}
        {...this.props}
        async
        onSelectResetsInput
        onInputChange={this.handleSearch}
        isLoading={isFetching}
        options={options}
        noResultsText={<Text color="ink.2">{noResultsText}</Text>}
        onInputKeyDown={(e) => {
          // tab, enter, up, down
          if (
            e.keyCode === 9 ||
            e.keyCode === 13 ||
            e.keyCode === 38 ||
            e.keyCode === 40
          ) {
            e.stopPropagation()
            e.preventDefault()
          }
          if (e.keyCode === 8 || e.keyCode === 46) {
            this.setState({
              options: [],
            })
          }
        }}
        optionComponent={this.OptionComponent}
      />
    )
  }
}

AddressSelect.propTypes = {
  input: PropTypes.object,
  t: PropTypes.func,
  initialOptions: PropTypes.array,
  countryCode: PropTypes.string,
}

// This it to solve:
// Stateless function components cannot be given refs. Attempts to access this ref will fail
const createOptionComponent = (onBeforeSelect) =>
  // eslint-disable-next-line react/prefer-stateless-function
  class Option extends Component {
    render() {
      return <AddressListItem onBeforeSelect={onBeforeSelect} {...this.props} />
    }
  }

export type AddressSelectWrapProps = Omit<
  AddressSelectProps,
  'countryCode' | 't' | 'pcaKey'
>

export default (p: AddressSelectWrapProps) => {
  const { countryCode, pcaKey } = useScarfContext()
  const { t } = useTranslation()
  return (
    <AddressSelect countryCode={countryCode} pcaKey={pcaKey} t={t} {...p} />
  )
}
