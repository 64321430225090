function formatClinicianName(value) {
  // Handle if merchantClinician is passed in
  if (value?.displayName || value?.clinician) {
    return value.displayName || formatClinicianName(value.clinician)
  }

  if (value) {
    const { firstName, lastName } = value || {}
    return `${firstName} ${lastName}`
  }

  return ''
}

export default formatClinicianName
