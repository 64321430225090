import { Heading, Panel } from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import Overview from 'components/PlanOverview'
import config from 'config'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { usePlans } from 'resources/Plans'
import styled from 'styled-components'
import { useMerchant } from '../../resources/Merchant'
import Form from './Form'
import Layout from './Layout'

const { CURRENCY } = config

const Title = styled(Heading)``

Title.defaultProps = {
  color: 'ink.2',
  textAlign: 'center',
  flex: '1',
}

const cleanBenefits = benefits =>
  benefits.reduce((mem, benefit) => {
    const isValid = benefit.title && benefit.claimsLimit !== undefined
    if (isValid) {
      mem.push({
        ...benefit,
        claimsLimit: benefit.claimsLimit || null,
        title: benefit.otherTitle || benefit.title,
      })
    }
    return mem
  }, [])

function NewPlan() {
  const [state, setState] = React.useState({
    formValues: {},
    submitted: false,
  })
  const { data: merchant } = useMerchant()
  const { tradingName, sector } = merchant
  const {
    resource: { create },
  } = usePlans()
  const { t } = useTranslation()

  const handleFormChange = ({ values }) => {
    const normalizedValues = Object.keys(values).reduce((mem, key) => {
      if (values[key] === '') {
        mem[key] = undefined
      } else if (['planPerks', 'planFeatures'].includes(key)) {
        mem[key] = cleanBenefits(values[key])
      } else {
        mem[key] = values[key]
      }
      return mem
    }, {})
    setState({
      formValues: normalizedValues,
    })
  }

  const handleSubmit = async ({ imageURL, ...values }) => {
    const planFeatures = cleanBenefits(values.planFeatures)
    const planPerks = cleanBenefits(values.planPerks)
    const isNoImageSelected = imageURL && imageURL.indexOf('no-image') > -1
    const payload = {
      ...values,
      planFeatures,
      planPerks,
      imageURL: isNoImageSelected ? null : imageURL,
      currency: CURRENCY,
      paymentSchedule: 'monthly',
    }

    try {
      await create(payload)
    } catch (e) {
      return formError(e)
    }

    setState({
      submitted: true,
    })
  }

  const { formValues, submitted } = state

  if (submitted) {
    return <Redirect to="/plans" />
  }

  return (
    <Layout
      left={
        <Form
          onFormChange={handleFormChange}
          onSubmit={handleSubmit}
          merchant={merchant}
        />
      }
      right={
        <Panel title={<Title>{t('What your patient will see')}</Title>}>
          <Overview
            merchantName={tradingName}
            sector={sector}
            showPlaceholders
            {...formValues}
          />
        </Panel>
      }
    />
  )
}

export default NewPlan
