import React from 'react'

import { Caption, Flex, Heading, View } from '@tabeo/scarf'
import { useTranslation } from 'react-i18next'
import Card from './Card'

type ActionCardProps = {
  icon: string
  title: string | React.ReactNode
  body: string | React.ReactNode
  actions?: React.ReactNode
  error?: string | Error
} & React.ComponentPropsWithoutRef<typeof Card>

function ActionCard({
  icon,
  title,
  body,
  actions,
  error,
  ...rest
}: ActionCardProps) {
  const { t } = useTranslation()
  return (
    <Card data-testid="action-card" {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{title}</Heading>
      </Flex>
      <Caption color="ink.0">{body}</Caption>
      {actions && (
        <Flex
          flexDirection={['column', 'row']}
          justifyContent="flex-start"
          alignItems={['stretch', 'center']}
          mt={4}
        >
          {actions}
        </Flex>
      )}
      {error && (
        <Caption color="default.red" mt={2}>
          {t('There was an error: {{error}}', {
            error: error instanceof Error ? error.message : error,
          })}
        </Caption>
      )}
    </Card>
  )
}

export default ActionCard
