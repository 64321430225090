// ADDRESS
const ADDRESS_BASE_URL =
  'https://services.postcodeanywhere.co.uk/Capture/Interactive'

export const getAddressSuggestions = async (
  pcaKey,
  addressString,
  countryCode = 'gb'
) => {
  const endpoint = `${ADDRESS_BASE_URL}/Find/v1.00/json3.ws?Key=${pcaKey}&Text=${encodeURI(
    addressString
  )}&Countries=${countryCode}`
  const res = await fetch(endpoint)
  const { Items } = await res.json()
  return Items
}

export const getAddressSuggestionOptions = async (
  pcaKey,
  addressContainer,
  countryCode = 'gb'
) => {
  const endpoint = `${ADDRESS_BASE_URL}/Find/v1.00/json3.ws?Key=${pcaKey}&Container=${encodeURI(
    addressContainer
  )}&Countries=${countryCode}`
  const res = await fetch(endpoint)
  const { Items } = await res.json()
  return Items
}

export const getAddressDetails = async (
  pcaKey,
  addressId,
  countryCode = 'gb'
) => {
  const endpoint = `${ADDRESS_BASE_URL}/Retrieve/v1.00/json3.ws?Key=${pcaKey}&Id=${encodeURI(
    addressId
  )}&Countries=${countryCode}`
  const res = await fetch(endpoint)
  const { Items } = await res.json()
  return Items[0]
}
