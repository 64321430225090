import { useState, useCallback, useRef, useEffect } from 'react'

const initialState = {
  success: false,
  pending: false,
  error: null,
  value: null,
  args: [],
}
function usePromise(promise, deps = []) {
  const mounted = useRef(true)
  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  const [state, setState] = useState(initialState)

  useEffect(() => {
    setState(initialState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  const trigger = useCallback(
    async (...args) => {
      mounted.current &&
        setState({
          ...initialState,
          pending: true,
          args,
        })
      try {
        const value = await promise(...args)
        mounted.current &&
          setState({
            ...initialState,
            pending: false,
            success: true,
            value,
          })
      } catch (e) {
        mounted.current &&
          setState({
            ...initialState,
            error: e,
          })
      }
    },
    [promise]
  )
  return [trigger, state]
}

export default usePromise
