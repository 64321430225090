import { StateFunnel } from '@tabeo/scarf'
import { LinkPaymentStatus } from '@tabeo/ts-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const funnel = ['c', 'viewed', 'p']

function Funnel({ status, ...rest }: { status: LinkPaymentStatus }) {
  const { t } = useTranslation()
  const labels = [t('Sent'), t('Viewed'), t('Paid')]
  const currentIndex = funnel.indexOf(status)

  // If the status is not in the funnel or is the last step, don't render the funnel
  return funnel.includes(status) && currentIndex !== funnel.length - 1 ? (
    <StateFunnel
      pastStepProps={{ bg: 'yellow.5', color: 'yellow.1' }}
      activeStepProps={{ bg: 'yellow.3', color: 'yellow.0' }}
      futureStepProps={{ bg: 'white', color: 'ink.2' }}
      states={labels}
      currentIndex={currentIndex}
      {...rest}
    />
  ) : null
}

export default Funnel
