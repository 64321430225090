import { Attributes, Button, Panel } from '@tabeo/scarf'
import { OTHER_VALUES } from 'components/MerchantTreatmentSelect'
import TreatmentModal from 'components/TreatmentModal'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import { useOffer } from 'resources/Offer'
import formatClinicianName from 'utils/formatClinicianName'
import UpdateModal from './UpdateModal'

const TreatmentPanel = ({ data, ...rest }) => {
  const {
    title,
    isCoolingOffPeriodWaived,
    coolingOffPeriodEnds,
    isPOT,
    isPN,
    fulfillment,
    merchantTreatment,
    merchantClinician,
    reference,
    individual,
    metadata: { appointment, treatmentStartDate, patientName },
    isVoided,
    basket,
  } = data
  const { t } = useTranslation()
  const {
    data: {
      isRetails,
      isSDC,
      flags: { is_cooling_off_period_capable: isCoolingOffPeriodCapable },
    },
  } = useMerchant()
  const { resource } = useOffer()
  const potModal = useRef()
  const pnModal = useRef()

  if (isRetails) {
    return null
  }

  const getCoolingOffText = () => {
    if (isPN && !treatmentStartDate) {
      return t(
        `Treatment was already provided at the time this transaction was created.`
      )
    }

    if (isCoolingOffPeriodCapable) {
      if (isCoolingOffPeriodWaived) {
        return t(`Waived`)
      }
      if (coolingOffPeriodEnds && coolingOffPeriodEnds > moment()) {
        return t(`Ends on {{date}}`, {
          date: coolingOffPeriodEnds.format('DD MMM YYYY'),
        })
      }
      if (coolingOffPeriodEnds && coolingOffPeriodEnds < moment()) {
        return t(`Ended on {{date}}`, {
          date: coolingOffPeriodEnds.format('DD MMM YYYY'),
        })
      }
    }
  }

  const coolingOffText = getCoolingOffText()

  const handleMetadataSubmission = async values => {
    const { patientName, date, individual, merchantClinician } = values
    await resource.updateMetadata('appointment', { date })
    await resource.updateMetadata('patientName', patientName)
    if (individual) {
      await resource.createOrUpdateIndividual(individual)
    } else {
      await resource.createOrUpdateClinician(merchantClinician)
    }
  }

  const handleTreatmentSubmission = async values => {
    const { patientName, ...treatment } = values
    await resource.createOrUpdateTreatment(treatment)
    await resource.updateMetadata('patientName', patientName)
  }

  const clinicianName = merchantClinician
    ? formatClinicianName(merchantClinician)
    : individual?.name

  return (
    <>
      <UpdateModal
        ref={pnModal}
        data={data}
        onSubmit={handleMetadataSubmission}
      />
      <TreatmentModal
        ref={potModal}
        offer={data}
        onSubmit={handleTreatmentSubmission}
      />

      <Panel
        data-testid="treatment-panel"
        header={
          <>
            <Panel.Title>{t('Treatment')}</Panel.Title>
            {!isVoided && (
              <Button
                sizeVariant="sm"
                variant="secondary"
                onClick={() => {
                  if (isPOT) {
                    potModal.current.open()
                  } else if (isPN) {
                    pnModal.current.open()
                  }
                }}
              >
                {t('Edit')}
              </Button>
            )}
          </>
        }
        {...rest}
      >
        <Attributes
          p={5}
          bg="white"
          sections={[
            [
              {
                label: isSDC ? t('Case ID') : t('Reference'),
                value: reference,
              },
              {
                label: t('Name'),
                value:
                  merchantTreatment &&
                  !OTHER_VALUES.includes(merchantTreatment.treatment.name)
                    ? merchantTreatment.treatment.name
                    : title,
              },
              {
                label: t('Patient name'),
                value: patientName,
              },
              isPOT && {
                label: t('Start date'),
                value: fulfillment?.startedAt,
                formattedValue: moment(fulfillment?.startedAt).format(
                  'DD MMM YYYY'
                ),
              },
              isPN && {
                label: t('Appointment date'),
                value:
                  appointment?.date &&
                  moment(appointment?.date).format('DD MMM YYYY, hh:mm a'),
              },
              {
                label: t('Clinician'),
                value: clinicianName,
              },
              isPOT && {
                label: t('Fulfillment reference'),
                value: fulfillment?.reference,
              },
              {
                label: t('Cooling-off period'),
                value: coolingOffText,
              },
              {
                label: t('PMS invoice number(s)'),
                value: basket?.reference,
              },
            ].filter(Boolean),
          ]}
        />
      </Panel>
    </>
  )
}

TreatmentPanel.propTypes = {
  data: PropTypes.object,
}

export default TreatmentPanel
