import { Attributes, MultiActionButton, Panel } from '@tabeo/scarf'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import DatoNotifications from 'components/Notifications/DatoNotifications'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'resources/Auth'
import EditUserDetailsModal from './EditUserDetailsModal'

function Account() {
  const {
    data: { user },
    resource: { updateUser },
  } = useAuth()
  const { t } = useTranslation()
  const history = useHistory()
  const editUserDetailsModal = React.useRef()

  if (!user) {
    return null
  }

  return (
    <LayoutSideNavbar>
      <Container maxWidth="780px">
        <DatoNotifications />
        <EditUserDetailsModal
          onSubmit={updateUser}
          innerRef={editUserDetailsModal}
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            jobFunction: user.jobFunction,
            addressJSON: JSON.stringify(user.addressDetails),
          }}
        />
        <Panel
          header={
            <>
              <Panel.Title>{t('User details')}</Panel.Title>
              <MultiActionButton
                variant="secondary"
                sizeVariant="sm"
                onClick={() => editUserDetailsModal.current.open()}
                secondaryActions={[
                  {
                    label: t('Change password'),
                    onClick: () => history.push('/password-change'),
                  },
                ]}
              >
                {t('Update')}
              </MultiActionButton>
            </>
          }
          mb={6}
        >
          <Attributes
            bg="white"
            p={5}
            sections={[
              [
                {
                  label: t('Name'),
                  value: `${user.firstName} ${user.lastName}`,
                },
                { label: t('Email address'), value: user.email },
                { label: t('Job function'), value: t(user.jobFunction) },
                {
                  label: t('Trading address'),
                  value: user.addressDetails.Label,
                },
              ],
            ]}
          />
        </Panel>
      </Container>
    </LayoutSideNavbar>
  )
}

export default Account
