import { themeGet } from '@styled-system/theme-get'
import React from 'react'
import styled from 'styled-components'
import ScarfProps from '../../../types/ScarfProps'
import Box from '../../Box'
import {
  ActionIconButton,
  Actions,
  Highlight,
  Id,
  Indicators,
  Line,
  Title,
} from './components'
import DefaultBuilder, {
  DefaultBuilderProps,
  TicketItem,
} from './DefaultBuilder'
import TicketContext from './TicketContext'

const StyledBox = styled(Box)`
  @media (min-width: ${themeGet('breakpoints.0')}) {
    :hover {
      ${Actions} {
        opacity: 1;
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 0px 8px 8px rgba(255, 255, 255, 0.9);
      }
    }
  }
`

StyledBox.defaultProps = {
  p: null,
  px: 5,
  py: 3,
  position: 'relative',
  boxShadow: 1,
  cursor: 'pointer',
  transition: 'all 0.2s',
}

export type TicketProps = ScarfProps & {
  data?: TicketItem
  Builder?: React.ComponentType<DefaultBuilderProps>
  color?: string
  isResolved?: boolean
  isStarHidden?: boolean
  isDeleteHidden?: boolean
  onAction?: (action: string) => void
}

function Ticket({
  children,
  data,
  Builder = DefaultBuilder,
  color = 'default.primary',
  isResolved,
  isStarHidden,
  isDeleteHidden,
  onAction,
  ...rest
}: TicketProps) {
  return (
    <TicketContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ color, isResolved, isStarHidden, isDeleteHidden }}
    >
      <StyledBox
        bg={isResolved ? 'sky.3' : 'white'}
        _hover={{
          bg: isResolved ? 'sky.3' : 'sky.4',
        }}
        {...rest}
      >
        {children ||
          (data ? (
            <Builder data={data} onAction={onAction || (() => {})} />
          ) : null)}
      </StyledBox>
    </TicketContext.Provider>
  )
}

Ticket.Highlight = Highlight
Ticket.Id = Id
Ticket.Title = Title
Ticket.Indicators = Indicators
Ticket.Actions = Actions
Ticket.Line = Line
Ticket.ActionIconButton = ActionIconButton
Ticket.Context = TicketContext

export default Ticket
