import { Button } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { encodeToBase64string } from 'utils/base64'
import BookAppointment from '../Booking/Button'
import CloseLead from './CloseLead'

function CommonActions({ resource }) {
  const { data } = resource
  const { state } = data
  const { t } = useTranslation()

  if (['lost', 'won'].includes(state)) {
    return null
  }

  const hasActions = ['qualified', 'consultation', 'treatment'].includes(state)

  return hasActions ? (
    <>
      <BookAppointment
        lead={data}
        onSubmit={async status => {
          const createOrUpdate = data.appointment
            ? resource.updateAppointment
            : resource.createAppointment
          await createOrUpdate(status)
        }}
        mr={[0, 4]}
        mb={[4, 0]}
      />
      <Button
        variant="secondary"
        sizeVariant="sm"
        as={Link}
        to={`/new-transaction?initialState=${encodeToBase64string({
          patient: {
            firstName: data.firstName,
            lastName: data.lastName,
            gender: data.gender,
          },
          payment: {
            email: data.email,
            mobileNumber: data.phoneNumber,
            leadId: data.id,
          },
        })}`}
        mr={[0, 4]}
        mb={[4, 0]}
      >
        {t('Create transaction')}
      </Button>
      <CloseLead
        onSubmit={async status => {
          await resource.setStatus(status)
        }}
      />
    </>
  ) : null
}

CommonActions.propTypes = {
  resource: PropTypes.object.isRequired,
}

export default CommonActions
