import {
  space,
  SpaceProps,
  color,
  ColorProps,
  typography,
  TypographyProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  background,
  BackgroundProps,
  border,
  BorderProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  style,
  compose,
  ResponsiveValue,
  Theme,
} from 'styled-system'

import pseudoStyles, { PseudoProps } from './pseudoStyles'

const cursor = style({
  prop: 'cursor',
  cssProperty: 'cursor',
})

const transform = style({
  prop: 'transform',
})

const transition = style({
  prop: 'transition',
})

const boxSizing = style({
  prop: 'boxSizing',
})

const filter = style({
  prop: 'filter',
})

const visibility = style({
  prop: 'visibility',
})

const whiteSpace = style({
  prop: 'whiteSpace',
})

const textOverflow = style({
  prop: 'textOverflow',
})

export const systemStyles = compose(
  // styled-system
  space,
  color,
  typography,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
  // custom
  cursor,
  transform,
  transition,
  boxSizing,
  filter,
  visibility,
  whiteSpace,
  textOverflow
)

type CustomProps = {
  cursor?: ResponsiveValue<string, Theme> | undefined
  transition?: ResponsiveValue<string, Theme> | undefined
  transform?: ResponsiveValue<string, Theme> | undefined
  boxSizing?: ResponsiveValue<string, Theme> | undefined
  filter?: ResponsiveValue<string, Theme> | undefined
  visibility?: ResponsiveValue<string, Theme> | undefined
  whiteSpace?: ResponsiveValue<string, Theme> | undefined
  textOverflow?: ResponsiveValue<string, Theme> | undefined
}

export interface SystemProps
  extends SpaceProps,
    ColorProps,
    TypographyProps,
    LayoutProps,
    FlexboxProps,
    GridProps,
    BackgroundProps,
    BorderProps,
    PositionProps,
    ShadowProps,
    CustomProps {}

export interface StyledSystemProps
  extends SystemProps,
    PseudoProps<SystemProps> {}

const styledSystem = (props: any) => ({
  ...systemStyles(props),
  ...pseudoStyles(props),
})

export default styledSystem
