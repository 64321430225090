import { plansorchestrator } from '@tabeo/platform-api-client'
import Resource, { createResourceHook, ResourceConfig } from '@tabeo/resync'
import api from 'utils/platform-api'
import { useVendor } from './Vendor'

interface Params {
  vendorId: string
  limit?: number
  offset?: number
}

interface Data {
  items: plansorchestrator.ModelsSelectPlansAggregate[] | null
  totalCount: number
}

class PlansV2 extends Resource<Data, Params> {
  getInitialData() {
    return {
      items: null,
      totalCount: 0,
    }
  }

  getInitialConfig() {
    return {
      refreshInterval: 15 * 1000,
    }
  }

  async fetch() {
    const { limit, offset } = this.params

    const response =
      await api.plansorchestrator.plans.apiV1OrchestratorPlansPlansSelectPost({
        vendorId: this.params.vendorId,
        limit,
        offset,
      })

    const data = {
      totalCount: response.paginationSummary?.total || 0,
      items: response.plans || null,
    }

    this.data = data

    return data
  }

  create = async (
    payload: Omit<plansorchestrator.ModelsCreatePlanRequest, 'vendorId'>
  ) => {
    const response =
      await api.plansorchestrator.plans.apiV1OrchestratorPlansPlansCreatePost({
        body: {
          vendorId: String(this.params.vendorId),
          currency: 'GBP',
          ...payload,
        },
      })

    await this.fetch()
    return response
  }
}

export default PlansV2
export const useResource = createResourceHook(PlansV2)
export function usePlansV2(
  p?: Params | (() => Params),
  config?: ResourceConfig
) {
  const { data: vendor } = useVendor()

  let params: Params | undefined
  if (p instanceof Function) {
    params = p()
  } else {
    params = p
  }

  const vendorIdParam = params?.vendorId || vendor?.id

  return useResource(() => {
    if (!vendorIdParam) {
      throw new Error('Vendor ID is null')
    }

    return {
      ...params,
      vendorId: vendorIdParam,
    }
  }, config)
}
