import React from 'react'
import PropTypes from 'prop-types'

import { Tag } from '@tabeo/scarf'
import { t } from 'i18n'

const states = {
  new: {
    color: 'green',
    label: t('New enquiry'),
  },
  qualified: {
    color: 'green',
    label: t('Qualified'),
  },
  consultation: {
    color: 'yellow',
    label: t('Consultation booked'),
  },
  treatment: {
    color: 'yellow',
    label: t('Treatment'),
  },
  lost: {
    color: 'grey',
    label: t('Lost'),
  },
  won: {
    color: 'grey',
    label: t('Won'),
  },
}

const LeadStateTag = ({ state, ...rest }) => {
  const currentState = states[state] || {}
  const color = currentState.color || 'grey'
  const label = currentState.label || state
  return (
    <Tag color={color} {...rest}>
      {label}
    </Tag>
  )
}

LeadStateTag.propTypes = {
  state: PropTypes.string.isRequired,
}

export default LeadStateTag
