import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import { Payout as TPayout } from '@tabeo/ts-types'
import { useRouteMatch } from 'react-router-dom'

interface Params {
  id: number
}

class Payout extends Resource<TPayout, Params> {
  async fetch() {
    const subResources = formatSubresources(['merchant', 'payments'])

    const { payout } = await api.get(
      `/merchant/payouts/${this.params.id}?${subResources}`
    )

    this.data = payout
  }
}

export default Payout
export const useResource = createResourceHook(Payout)
export function usePayout({
  id,
}: {
  id?: string | number
} = {}) {
  const match = useRouteMatch<{
    payoutId: string
  }>({
    path: ['/payouts/:payoutId'],
  })

  const {
    params: { payoutId },
  } = match || { params: {} }

  const payoutIdParam = id || payoutId

  return useResource(() => {
    if (!payoutIdParam) {
      throw new Error('ID is null')
    }
    return {
      id: Number.isNaN(Number(payoutIdParam)) ? +payoutIdParam : +payoutIdParam,
    }
  })
}
