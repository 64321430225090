import { Component } from 'react'

export type ToggleProps = {
  defaultValue?: boolean
  children: ({
    value,
    toggle,
  }: {
    value: boolean
    toggle: () => void
  }) => React.ReactNode
}

class Toggle extends Component<ToggleProps, { value: boolean }> {
  constructor(props: ToggleProps) {
    super(props)
    this.state = {
      value: !!props.defaultValue,
    }
  }

  componentDidUpdate({ defaultValue: prevDefaultValue }: ToggleProps) {
    const { defaultValue } = this.props
    if (defaultValue !== undefined && defaultValue !== prevDefaultValue) {
      this.handleToggle()
    }
  }

  handleToggle = () => {
    this.setState(({ value }) => ({ value: !value }))
  }

  render() {
    const { children } = this.props
    const { value } = this.state

    return children({
      value,
      toggle: this.handleToggle,
    })
  }
}

export default Toggle
