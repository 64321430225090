import { Basket, PaymentCharge, PaymentRefund } from '@tabeo/ts-types'
import formatClinicianName from 'utils/formatClinicianName'

type Params = {
  amount: number
  basket: Basket
  charges?: PaymentCharge[]
  refunds?: PaymentRefund[]
}

const normalize = (payment: Params) => {
  const { amount, refunds, basket, charges } = payment

  const paidAmount =
    charges?.reduce(
      (acc, c) => (c.status === 'succeeded' ? acc + c.amount : acc),
      0
    ) || 0

  const refundedAmount =
    refunds?.reduce(
      (acc, r) => (r.status === 'succeeded' ? acc + r.amount : acc),
      0
    ) || 0

  const adjustedAmount = amount - refundedAmount

  let clinicianName = null
  if (basket.clinicianName) {
    clinicianName = basket.clinicianName
  }

  if (basket.merchantClinician?.id) {
    clinicianName = formatClinicianName(basket.merchantClinician)
  }

  return {
    adjustedAmount,
    paidAmount,
    refundedAmount,
    clinicianName,
  }
}

export default normalize
