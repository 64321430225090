import React, { Fragment } from 'react'

import styled from 'styled-components'
import ScarfProps from '../types/ScarfProps'
import Flex from './Flex'
import { Subheading } from './Text'
import Svg, { SvgProps } from './Icons/Base'

const Step = styled(Flex).attrs(() => ({
  as: Subheading,
}))`
  &:first-of-type {
    padding-left: 8px;
    border-radius: 16px 0 0 16px;
  }
  &:last-of-type {
    padding-right: 8px;
    border-radius: 0 16px 16px 0;
  }
`

Step.defaultProps = {
  flex: '1 0',
  height: '32px',
  justifyContent: 'center',
}

const SeperatorEnd = ({ fill }: { fill?: SvgProps['fill'] }) => (
  <Svg
    width="9px"
    height="32px"
    viewBox="0 0 9 32"
    fill={fill}
    style={{ display: 'block' }}
  >
    <g transform="translate(-408.000000, 0.000000)">
      <path d="M408,0 L408,32 L416.125134,18.591176 C417.090213,16.9985161 417.090213,15.0014839 416.125134,13.408824 L408,0 Z" />
    </g>
  </Svg>
)

const SeperatorStart = ({ fill }: { fill?: SvgProps['fill'] }) => (
  <Svg
    width="10px"
    height="32px"
    viewBox="0 0 10 32"
    style={{ marginLeft: '-5px', display: 'block' }}
    fill={fill}
  >
    <g transform="translate(-410.000000, 0.000000)">
      <path d="M410.330859,0 L420.330859,0 L420.330859,16 L420.330859,32 L410.330859,32 L418.444232,18.5879792 C419.40673,16.9968962 419.40673,15.0031038 418.444232,13.4120208 L410.330859,0 Z" />
    </g>
  </Svg>
)

type FunnelbarProps = ScarfProps & {
  steps: {
    label?: React.ReactNode
    children?: React.ReactNode
    props: ScarfProps
  }[]
}

const Funnelbar = ({ steps, ...rest }: FunnelbarProps) => (
  <Flex {...rest}>
    {steps.map((s, i) => (
      <Fragment key={`${s.label}-${i}`}>
        {i > 0 && <SeperatorStart fill={s.props.bg as any} />}
        <Step {...s.props}>
          {s.children ? s.children : s.label ? s.label : null}
        </Step>
        {i < steps.length - 1 && <SeperatorEnd fill={s.props.bg as any} />}
      </Fragment>
    ))}
  </Flex>
)

export default Funnelbar
