import {
  Accordion,
  Attributes,
  Box,
  Caption,
  Flex,
  Heading,
  Prompt,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

function Details({ data, ...rest }) {
  const {
    imageURL,
    price,
    title,
    subtitle,
    description,
    planFeatures,
    planPerks,
    numberOfActiveSubscriptions,
  } = data
  const { t } = useTranslation()
  return (
    <Box p={0} boxShadow={1} {...rest}>
      <Flex p={5} borderBottom={1}>
        <View as="img" width="82px" height="82px" src={imageURL} mr={5} />
        <View flex="1" data-testid="plan-header">
          <Heading color="primary.3" data-testid="plan-title">
            {title}
          </Heading>
          <Prompt mt={1} data-testid="plan-price">
            {t('{{amount}} a month', { amount: format(price) })}
          </Prompt>
          <Caption mt={1} data-testid="plan-subscriptions">
            {t('{{numberOfActiveSubscriptions}} active subscriptions', {
              numberOfActiveSubscriptions,
            })}
          </Caption>
        </View>
      </Flex>
      <Accordion
        title={t('Plan details')}
        borderBottom={1}
        data-testid="plan-details"
      >
        {/* TODO: check formatted */}
        <Attributes
          sections={[
            [
              {
                label: t('Name'),
                value: title,
              },
              {
                label: t('Price'),
                value: price,
                formattedValue: t(`{{amount}} a month`, {
                  amount: format(price),
                }),
              },
              { label: t('Short description'), value: subtitle },
              { label: t('Long description'), value: description },
            ],
          ]}
        />
      </Accordion>
      <Accordion
        title={t('Included treatments')}
        borderBottom={1}
        data-testid="included-treatments-section"
      >
        <Attributes
          sections={[
            planFeatures.map(f => ({
              label: f.title,
              value: f.claimsLimit > 0 ? `${f.claimsLimit}x` : ' ',
            })),
          ]}
        />
      </Accordion>
      <Accordion
        title={t('Additional perks')}
        data-testid="additional-perks-section"
      >
        <Attributes
          sections={[
            planPerks.map(p => ({
              label: p.title,
              value: p.claimsLimit > 0 ? `${p.claimsLimit}x` : ' ',
            })),
          ]}
        />
      </Accordion>
    </Box>
  )
}

Details.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Details
