import plane from 'components/terminal/TerminalPayment/assets/plane.svg'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

export default function Success() {
  const { t } = useTranslation()

  return (
    <div className="text-center">
      <motion.div
        initial={{
          scale: 1.1,
          x: -40,
          y: 30,
          opacity: 0.7,
        }}
        animate={{
          scale: 1,
          x: 0,
          y: 0,
          opacity: 1,
        }}
      >
        <img src={plane} className="mx-auto h-[102px]" />
      </motion.div>
      <p className="font-medium">{t('Payment link sent')}</p>
    </div>
  )
}
