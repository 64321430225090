import { Box, Button, Text, themeGet, Title, View } from '@tabeo/scarf'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import usePromise from 'hooks/usePromise'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useMerchant } from '../../resources/Merchant'

const Code = styled(Text)`
  font-family: ${themeGet('monospace')};
`

Code.defaultProps = {
  bg: 'sky.2',
  color: 'ink.2',
  borderRadius: 1,
  maxWidth: 'fit-content',
}

function CompanyDetails() {
  const {
    data: { bankDetails, isUK, isEU },
    resource: { updateBankDetailsStatus },
  } = useMerchant()
  const [trigger, { pending }] = usePromise(updateBankDetailsStatus)
  const history = useHistory()
  const { t } = useTranslation()

  React.useEffect(() => {
    window.localStorage.setItem('tabeoBankBanner', 'started')
  }, [])

  if (
    bankDetails.status === 'verification_token_disclosed' ||
    bankDetails.status === 'passed'
  ) {
    return <Redirect to="/" />
  }

  return (
    <LayoutSideNavbar>
      <Container bg={['white', 'sky.2']} maxWidth="492px" mx="auto">
        <Box variant="content">
          <Title mb={3} textAlign="center">
            {t('Verify your bank details')}
          </Title>
          <Trans>
            <Text mb={3}>
              To verify you bank account please send a photo of a{' '}
              <View as="span" fontWeight="semibold">
                bank statement
              </View>{' '}
              or your business’{' '}
              <View as="span" fontWeight="semibold">
                checkbook
              </View>{' '}
              to:
            </Text>
          </Trans>

          <Code mb={5} px={4} py={2} mx="auto">
            {t('merchant@tabeo.co.uk')}
          </Code>

          <Text fontWeight="semibold" mb={2}>
            {t('We need to be able to read:')}
          </Text>
          <View
            display="grid"
            gridTemplateColumns="auto 1fr"
            gridColumnGap={5}
            gridRowGap={3}
            mb={6}
          >
            {isUK && (
              <>
                <View>
                  <Text>{t('Account number')}</Text>
                </View>
                <View>
                  <Code px={1} pb="2px" fontSize={0}>
                    {bankDetails.accountNumber}
                  </Code>
                </View>
                <View>
                  <Text>{t('Sort code')}</Text>
                </View>
                <View>
                  <Code px={1} pb="2px" fontSize={0}>
                    {bankDetails.sortCode.replace(
                      /(\d{2})(\d{2})(\d{2})/g,
                      '$1-$2-$3'
                    )}
                  </Code>
                </View>
              </>
            )}
            {isEU && (
              <>
                <View>
                  <Text>{t('IBAN')}</Text>
                </View>
                <View>
                  <Code px={1} pb="2px" fontSize={0}>
                    {bankDetails.iban}
                  </Code>
                </View>
                <View>
                  <Text>{t('SWIFT/BIC')}</Text>
                </View>
                <View>
                  <Code px={1} pb="2px" fontSize={0}>
                    {bankDetails.bic}
                  </Code>
                </View>
              </>
            )}
          </View>

          <Button
            mb={4}
            onClick={() => trigger()}
            loading={pending}
            width="100%"
          >
            {t('I have provided the document')}
          </Button>
          <Button onClick={history.goBack} width="100%" variant="secondary">
            {t('Close')}
          </Button>
        </Box>
      </Container>
    </LayoutSideNavbar>
  )
}

CompanyDetails.propTypes = {}

export default CompanyDetails
