import { themeGet } from '@styled-system/theme-get'
import styled from 'styled-components'
import { style, ResponsiveValue, Theme } from 'styled-system'
import ScarfProps from '../types/ScarfProps'
import View from './View'

const textDecoration = style({
  prop: 'textDecoration',
})

export type BaseProps = ScarfProps & {
  textDecoration?: ResponsiveValue<string, Theme> | undefined
}

const Base = styled(View)<BaseProps>`
  ${textDecoration}
  font-family: ${themeGet('font', 'Arial')};
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

Base.defaultProps = {
  fontWeight: 'regular',
  color: 'default.ink',
}

export const Title = styled(Base)``

Title.defaultProps = {
  fontSize: [5, 6],
  fontWeight: 'semibold',
  // @ts-ignore
  'data-testid': 'title',
}

export const Subtitle = styled(Base)``

Subtitle.defaultProps = {
  fontSize: [4],
  // @ts-ignore
  'data-testid': 'subtitle',
}

export const Heading = styled(Base)``

Heading.defaultProps = {
  fontSize: [2, 3],
}

export const Subheading = styled(Base)`
  text-transform: uppercase;
`

Subheading.defaultProps = {
  fontSize: 0,
  letterSpacing: 'medium',
  fontWeight: 'semibold',
}

export const Prompt = styled(Base)``

Prompt.defaultProps = {
  fontSize: [2],
  lineHeight: 3,
}

export const Paragraph = styled(Base)``

Paragraph.defaultProps = {
  fontSize: 2,
  fontWeight: 'regular',
  lineHeight: 3,
}

export const Caption = styled(Base)``

Caption.defaultProps = {
  fontSize: 1,
  fontWeight: 'regular',
  color: 'ink.2',
  lineHeight: 3,
}

export const Clickable = styled(Base)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

Clickable.defaultProps = {
  color: 'default.primary',
  fontWeight: 'semibold',
  cursor: 'pointer',
}

export default Paragraph
