import Notifications from 'components/Notifications'
import PaymentMethodPanel from 'components/PaymentMethodPanel'
import PayoutsPanel from 'components/PayoutsPanel'
import InvoicePanel from 'components/Transaction/InvoicePanel'
import LayoutSideNavbar from 'components/layouts/LayoutSideNavbar'
import SubPage from 'components/layouts/Subpage'
import TwoColumns, { Column } from 'components/layouts/TwoColumns'
import { useOffer } from 'resources/Offer'
import ActionPanel from './ActionPanel'
import ActivityFeed from './ActivityFeed'
import FinanceDetailsPanel from './FinanceDetailsPanel'
import Header from './Header'
import PatientPanel from './PatientPanel'
import PaymentOptionsPanel from './PaymentOptionsPanel'
import PaymentsPanel from './PaymentsPanel'
import RefundsPanel from './RefundsPanel'
import TransactionFunnel from './TransactionFunnel'
import TreatmentPanel from './TreatmentPanel'

export default function Transaction() {
  const { data, resource } = useOffer()

  const { paymentPlan, isPOT } = data || {}

  return (
    <LayoutSideNavbar isLoading={!data}>
      {data && (
        <SubPage>
          <Notifications mb={4} />
          <Header transaction={data} />

          <TwoColumns mt={4}>
            <Column>
              <TransactionFunnel data={data} />
              <ActionPanel transaction={resource} />
              {isPOT &&
                (paymentPlan.id ? (
                  <FinanceDetailsPanel data={data} />
                ) : (
                  <PaymentOptionsPanel data={data} />
                ))}
              <PayoutsPanel items={data.payouts} showPayoutAmount />
              <InvoicePanel data={data} />
              <RefundsPanel data={data} />
              <PaymentsPanel data={data} />
              <ActivityFeed data={data} />
            </Column>
            <Column>
              <PatientPanel data={data} />
              <TreatmentPanel data={data} />
              <PaymentMethodPanel data={data} />
            </Column>
          </TwoColumns>
        </SubPage>
      )}
    </LayoutSideNavbar>
  )
}
