import { useTranslation } from 'react-i18next'
import { Text as BaseText } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import React from 'react'

const Text = styled(BaseText)``

Text.defaultProps = {
  lineHeight: 'normal',
}

const Paragraphs = ({ merchant }) => {
  const { t } = useTranslation()
  const {
    name: merchantName,
    tradingAddress,
    companyID,
    flags: {
      is_regulated_loan_capable: isRegulatedLoanCapable,
      is_appointed_representative: isAppointedRepresentative,
      is_appointed_representative_of_3rd_party:
        isAppointedRepresentativeOf3rdParty,
    },
    companyType,
    fcaID,
    officers,
    arPrincipalBusinessName,
    registeredAddress,
    tradingName: merchantTradingName,
  } = merchant

  const officerNames = (
    ['sole_trader'].includes(companyType) ? officers.slice(0, 1) : officers
  )?.map(({ firstName, lastName }) => `${firstName} ${lastName}`)

  const lf = new Intl.ListFormat('en')
  const officersFormattedList = lf.format(officerNames)

  // not authorized
  if (!isRegulatedLoanCapable) {
    return null
  }

  // appointed representative of tabeo
  if (isAppointedRepresentative) {
    if (companyType === 'sole_trader') {
      return (
        <Text fontSize="10px" color="ink.2" mb={2}>
          {t(
            '{{officersFormattedList}} trading as {{merchantTradingName}} is an Appointed Representative of Tabeo Broker Limited ("Tabeo").',
            {
              officersFormattedList,
              merchantTradingName,
            }
          )}
        </Text>
      )
    }
    if (companyType === 'partnership') {
      return (
        <Text fontSize="10px" color="ink.2" mb={2}>
          {t(
            '{{officersFormattedList}} partnership, trading as {{merchantTradingName}} is an Appointed Representative of Tabeo Broker Limited ("Tabeo").',
            {
              officersFormattedList,
              merchantTradingName,
            }
          )}
        </Text>
      )
    }
    return (
      <Text fontSize="10px" color="ink.2" mb={2}>
        {t(
          '{{merchantName}} trading as {{merchantTradingName}} is an Appointed Representative of Tabeo Broker Limited ("Tabeo").',
          {
            merchantName,
            merchantTradingName,
          }
        )}
      </Text>
    )
  }

  // appointed representative of 3rd party
  if (isAppointedRepresentativeOf3rdParty) {
    if (companyType === 'sole_trader') {
      return (
        <Text fontSize="10px" color="ink.2" mb={2}>
          {t(
            '{{officersFormattedList}} is an Appointed Representative of {{arPrincipalBusinessName}} which is authorised and regulated by the Financial Conduct Authority. {{officersFormattedList}} has their business trading address at {{tradingAddress}}.',
            {
              officersFormattedList,
              arPrincipalBusinessName,
              tradingAddress,
            }
          )}
        </Text>
      )
    }
    if (companyType === 'partnership') {
      return (
        <Text fontSize="10px" color="ink.2" mb={2}>
          {t(
            '{{officersFormattedList}} partnership, is an Appointed Representative of {{arPrincipalBusinessName}} which is authorised and regulated by the Financial Conduct Authority. {{officersFormattedList}} partnership has its business trading address at {{tradingAddress}}.',
            {
              officersFormattedList,
              arPrincipalBusinessName,
              tradingAddress,
            }
          )}
        </Text>
      )
    }
    return (
      <>
        <Text fontSize="10px" color="ink.2" mb={2}>
          {t(
            '{{merchantName}} trading as {{merchantTradingName}} is an Appointed Representative of {{arPrincipalBusinessName}} which is authorised and regulated by the Financial Conduct Authority.',
            {
              merchantName,
              merchantTradingName,
              arPrincipalBusinessName,
            }
          )}
        </Text>
        <Text fontSize="10px" color="ink.2" mb={2}>
          {t(
            '{{merchantName}} trading as {{merchantTradingName}} is incorporated in England & Wales (registration number {{companyID}}) with its registered office at {{registeredAddress}}.',
            {
              merchantName,
              merchantTradingName,
              companyID,
              registeredAddress,
            }
          )}
        </Text>
      </>
    )
  }

  // authorized
  if (companyType === 'sole_trader') {
    return (
      <Text fontSize="10px" color="ink.2" mb={2}>
        {t(
          '{{officersFormattedList}} trading as {{merchantTradingName}} with its address at {{tradingAddress}} is authorised and regulated by the Financial Conduct Authority and is entered on the Financial Services Register, reference number {{fcaID}}.',
          {
            officersFormattedList,
            merchantTradingName,
            tradingAddress,
            fcaID,
          }
        )}
      </Text>
    )
  }
  if (companyType === 'partnership') {
    return (
      <Text fontSize="10px" color="ink.2" mb={2}>
        {t(
          '{{officersFormattedList}} partnership, trading as {{merchantTradingName}} with its address at {{tradingAddress}} which is authorised and regulated by the Financial Conduct Authority and is entered on the Financial Services Register, reference number {{fcaID}}.',
          {
            officersFormattedList,
            merchantTradingName,
            tradingAddress,
            fcaID,
          }
        )}
      </Text>
    )
  }
  return (
    <Text fontSize="10px" color="ink.2" mb={2}>
      {t(
        '{{merchantName}} trading as {{merchantTradingName}} with its registered address at {{registeredAddress}} is authorised and regulated by the Financial Conduct Authority and is entered on the Financial Services Register, reference number {{fcaID}}.',
        {
          merchantName,
          merchantTradingName,
          registeredAddress,
          fcaID,
        }
      )}
    </Text>
  )
}

Paragraphs.propTypes = {
  merchant: PropTypes.object.isRequired,
}

const Legal = ({ merchant }) => {
  const { t } = useTranslation()
  const {
    tradingName: merchantTradingName,
    flags: { is_regulated_loan_capable: isRegulatedLoanCapable },
  } = merchant

  return (
    <>
      <Text fontSize="10px" color="ink.2" mb={2}>
        {t(
          'Tabeo handles payments for {{merchantTradingName}} and many other businesses in the UK. To learn more visit https://tabeo.co.uk/.',
          {
            merchantTradingName,
          }
        )}
      </Text>
      <Paragraphs merchant={merchant} />
      <Text fontSize="10px" color="ink.2">
        {t(
          'Tabeo may also act as credit broker and loan servicer. Depending on your term and credit profile, Tabeo will only introduce you to one suitable lender. Tabeo Broker Limited, incorporated in England & Wales (registration number 10416530), with its registered office at C/O Fox Williams, 10 Finsbury Square, Finsbury, London EC2A 1AF.'
        )}
        {isRegulatedLoanCapable && (
          <>
            {' '}
            {t(
              'Tabeo Broker Limited is authorised and regulated by the Financial Conduct Authority and entered on the Financial Services Register, reference number 777539.'
            )}
          </>
        )}
      </Text>
    </>
  )
}

Legal.propTypes = {
  merchant: PropTypes.object.isRequired,
}

export default Legal
