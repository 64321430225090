import { GridTableCell as Cell, GridTableRow, themeGet } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import StatusTag from 'components/TransactionStateTag'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Row = styled(GridTableRow)`
  &:first-of-type > * {
    margin-top: 8px;
  }
  & > * {
    border-top: 1px solid ${themeGet('colors.sky.1')};
  }
  &:hover > * {
    background: ${themeGet('colors.sky.3', 'white')};
  }
`

const ListItem = ({ data }) => {
  return (
    <Row bg="white" as={Link} to={`/transactions/${data.id}`}>
      <Cell color="ink.2" data-testid="transaction-id">
        {data.id}
      </Cell>
      <Cell data-testid="transaction-consumer">
        {data.consumerFirstName} {data.consumerLastName}
      </Cell>
      <Cell color="ink.2" data-testid="treatment-title">
        {data.title}
      </Cell>
      <Cell textAlign="right" color="ink.2" data-testid="price">
        {format(data.price)}
      </Cell>
      <Cell data-testid="transaction-state">
        <StatusTag small state={data.state} mx={5} />
      </Cell>
    </Row>
  )
}

ListItem.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ListItem
