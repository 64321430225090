import {
  Flex,
  Icons,
  Panel,
  Prompt,
  Tag,
  Text,
  Tooltip,
  View,
} from '@tabeo/scarf'
import { accountingFormat, format } from '@tabeo/sharpei/utils/currency'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const TableRow = styled(Flex)``

TableRow.defaultProps = {
  py: 4,
  px: 5,
  flexDirection: ['column', 'row'],
  alignItems: ['flex-start', 'center'],
  borderBottom: 1,
}

function BreakdownPanel(props) {
  const {
    price,
    feeAmount,
    netProceeds,
    isPN,
    state,
    payments = [],
    consumerGender,
    ...rest
  } = props
  const { t } = useTranslation()
  const placehoder = [
    'canceled',
    'settled',
    'funds_release_pending',
    'confirmed',
  ].includes(state)
    ? t('£0.00')
    : t('Not yet available')

  const display = number =>
    number !== null && state !== 'canceled'
      ? accountingFormat(number)
      : placehoder

  const refunds = payments.filter(p => p.type === 'ref_mer')

  const hisHer = consumerGender === 'm' ? 'his' : 'her'

  const hisHerText =
    hisHer === 'his'
      ? t(`Patient changed his mind`)
      : t(`Patient changed her mind`)

  const refundReasons = {
    mind_change: hisHerText,
    lower_cost: t('Cost of treatment was lower than expected'),
    dissatisfaction: t('Patient was unhappy with the treatment'),
  }

  const retentionReasonText = refund =>
    t(
      ` The customer was still charged {{amount}} because “{{refundReason}}”.`,
      {
        amount: format(price - refund.originalPayInAmount),
        refundReason: refund.retentionReason,
      }
    )

  return (
    <Panel
      header={
        <>
          <Flex justifyContent="flex-start">
            <Panel.Title mr={4}>{t('Breakdown')}</Panel.Title>
            <Tag small>{t('Bill')}</Tag>
          </Flex>
        </>
      }
      {...rest}
    >
      <View bg="white">
        <TableRow>
          <Prompt mb={[1, 0]} color="ink.2">
            {t('Cost (including VAT)')}
          </Prompt>
          <Prompt>{format(price)}</Prompt>
        </TableRow>
        {feeAmount !== null && (
          <TableRow>
            <Prompt mb={[1, 0]} color="ink.2">
              {t('Tabeo fee (1.9%)')}
            </Prompt>
            <Prompt>{display(-feeAmount)}</Prompt>
          </TableRow>
        )}
        {refunds.map(refund => (
          <Fragment key={refund.id}>
            <TableRow>
              <Flex justifyContent="flex-start" mb={[1, 0]}>
                <Prompt color="ink.2" mr={3}>
                  {t('Refunded to customer')}
                </Prompt>
                <Tooltip
                  component={
                    <Text textAlign="left">
                      {t(
                        `Refunded on {{date}} because “{{refundReason}}".{{retentionReason}}`,
                        {
                          date: moment(refund.scheduledAt).format(
                            'DD MMM YYYY'
                          ),
                          refundReason:
                            refundReasons[refund.refundReason] ||
                            refund.refundReason,

                          retentionReason: refund.retentionReason
                            ? retentionReasonText(refund)
                            : '',
                        }
                      )}
                    </Text>
                  }
                >
                  <Icons.Questionmark cursor="pointer" />
                </Tooltip>
              </Flex>
              <Prompt>{display(-refund.originalPayInAmount)}</Prompt>
            </TableRow>
            <TableRow>
              <Prompt mb={[1, 0]} color="ink.2">
                {t('Tabeo fee refund')}
              </Prompt>
              <Prompt>{display(refund.refundedFeeAmount)}</Prompt>
            </TableRow>
            <TableRow>
              <Prompt mb={[1, 0]} color="ink.2">
                {t('Late refund fee')}
              </Prompt>
              <Prompt>{display(-refund.lateRefundFeeAmount)}</Prompt>
            </TableRow>
          </Fragment>
        ))}
        <TableRow bg="white">
          <Prompt mb={[1, 0]} color="ink.2">
            {t('Net proceeds')}
          </Prompt>
          {feeAmount !== null ? (
            <Prompt fontWeight="semibold">{format(netProceeds)}</Prompt>
          ) : (
            <Prompt color="ink.2">{t('Not availale yet')}</Prompt>
          )}
        </TableRow>
      </View>
    </Panel>
  )
}

BreakdownPanel.propTypes = {
  price: PropTypes.number,
  feeAmount: PropTypes.number,
  netProceeds: PropTypes.number,
  payments: PropTypes.array,
  state: PropTypes.string.isRequired,
  isPN: PropTypes.bool,
  consumerGender: PropTypes.string,
}

export default BreakdownPanel
