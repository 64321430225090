import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import { useRouteMatch } from 'react-router-dom'
import normalize from './normalizers/subscription'

class Subscription extends Resource {
  async fetch() {
    const subResources = formatSubresources([
      'merchant',
      'merchantUser',
      'metadata',
      'consumer',
      'consumer.addressDetails',
      'stateMachineTransitions',
      'plan.planFeatures',
      'planFeatureClaims',
      'availablePlanFeatureClaims',
      'plan.planPerks',
      'planPerkClaims',
      'availablePlanPerkClaims',
      'payments',
      'paymentSource',
    ])

    const { subscription } = await api.get(
      `/merchant/subscriptions/${this.params.subscriptionId}?${subResources}`
    )
    const normalizedSub = normalize(subscription)
    this.data = {
      ...this.data,
      ...normalizedSub,
    }
    return normalizedSub
  }

  registerClaim = async (benefit, payload) => {
    const endpoint = `/merchant/subscriptions/${this.params.subscriptionId}/plan-${benefit.type}s/${benefit.id}/claims`
    const response = await api.post(endpoint, payload)
    await this.fetch()
    return response
  }

  cancel = async () => {
    const endpoint = `/merchant/subscriptions/${this.params.subscriptionId}/unsubscribe`
    const response = await api.put(endpoint, {})
    await this.fetch()
    return response
  }
}

export default Subscription
export const useResource = createResourceHook(Subscription)
export function useSubscription() {
  const match = useRouteMatch({
    path: ['/subscriptions/:subscriptionId'],
  })

  const {
    params: { subscriptionId },
  } = match || { params: {} }

  return useResource(() => {
    if (!subscriptionId) {
      throw new Error('Missing params')
    }
    return {
      subscriptionId: +subscriptionId,
    }
  })
}
