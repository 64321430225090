import React from 'react'
import PropTypes from 'prop-types'

import { Funnelbar, Subheading, Icons, Flex, View } from '@tabeo/scarf'
import { useTranslation } from 'react-i18next'

const Label = ({ label, index, color }) => (
  <View>
    <Subheading display={['none', 'block']} color={color}>
      {label}
    </Subheading>
    <Subheading display={['block', 'none']} color={color}>
      {index + 1}
    </Subheading>
  </View>
)

Label.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}

const pastSteps = {
  children: <Icons.Tick light fill="white" />,
  props: {
    bg: 'sea.4',
  },
}

function Progressbar({ companyType, currentIndex, ...rest }) {
  const { t } = useTranslation()
  const labels = [
    t('Account'),
    t('Business'),
    companyType === 'private_limited_company'
      ? t('Director')
      : companyType === 'sole_trader'
      ? t('Owner')
      : t('Partner'),
    t('Bank details'),
  ]
  const durations = ['', t('30 sec'), t('30 sec'), t('20 sec')].map(
    (duration, index) => (
      <Subheading
        color={index === currentIndex ? 'sea.3' : 'ink.2'}
        flex="1"
        key={index}
        textAlign="center"
        fontWeight="light"
      >
        {index >= currentIndex ? duration : ''}
      </Subheading>
    )
  )
  const currentStep = {
    children: (
      <Label index={currentIndex} label={labels[currentIndex]} color="white" />
    ),
    props: {
      bg: 'sea.3',
    },
  }
  const steps = labels.map((label, index) => {
    if (currentIndex === index) {
      return currentStep
    }
    if (index < currentIndex) {
      return pastSteps
    }
    return {
      children: <Label index={index} label={label} color="ink.2" />,
      props: {
        bg: 'sky.2',
      },
    }
  })
  return (
    <View {...rest}>
      <Funnelbar steps={steps} mb={2} />
      <Flex display={['none', 'flex']}>{durations}</Flex>
    </View>
  )
}

Progressbar.propTypes = {
  companyType: PropTypes.string.isRequired,
  currentIndex: PropTypes.number.isRequired,
}

export default Progressbar
