import { Flex, Title } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Header = ({ plan: { id }, ...rest }) => {
  const { t } = useTranslation()
  return (
    <Flex
      flexWrap="wrap"
      alignItems="baseline"
      justifyContent="flex-start"
      {...rest}
    >
      <Title mr={3}>{t('Plan #{{id}}', { id })}</Title>
    </Flex>
  )
}

Header.propTypes = {
  plan: PropTypes.object.isRequired,
}

export default Header
