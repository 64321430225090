export const SENT_STATUSES = ['new']

export const OPENED_STATUSES = ['reviewed', 'pay_now_product_file_created']

export const APPLYING_STATUSES = [
  'payment_plan_created',
  'consumer_details_created',
  'patient_details_created',
  'consumer_employment_details_created',
  'consumer_living_details_created',
  'risk_file_created', // depricated
  'partner_income_verification_pending',
  'payment_plan_resolved',
  'initial_payment_plan_accepted', // depricated
  'consumer_personal_details_update_required',
  'consumer_personal_details_updated',
  'risk_checked',
  'risk_check_pending',
  'ifinance_review_pending',
]

export const MANUAL_REVIEW_REQUIRED_STATUSES = ['manual_review_required']

export const LOAN_OFFER_MADE_STATUSES = [
  'consumer_id_questions_omitted',
  'consumer_id_questions_created',
  'consumer_id_questions_passed',
  'manual_review_omitted',
  'manual_review_passed',
  'payment_plan_scheduled',
  'payment_source_created',
  'bank_account_created',
  'pay_over_time_product_file_created',
  'first_payment_authorized', // depricated
  'loan_agreement_created',
  'ifinance_approved',

  // Merge pending release statuses:
  'pay_now_payment_pending',
  'loan_agreement_accepted',
  'pay_now_payment_authorization_succeeded',
]

export const PAYMENT_ERROR_STATUSES = [
  'payment_source_failed',
  'bank_account_failed',
  'pay_now_payment_authorization_failed',
  'pay_now_payment_failed',
  'first_payment_authorization_failed',
  'first_payment_capture_failed',
]

export const CONTACT_SUPPORT_STATUSES = ['subscription_failed']

export const FULFILLMENT_REQUIRED_STATUSES = ['fulfillment_required']

export const OPEN_STATUSES = [
  ...SENT_STATUSES,
  ...OPENED_STATUSES,
  ...APPLYING_STATUSES,
  ...MANUAL_REVIEW_REQUIRED_STATUSES,
  ...LOAN_OFFER_MADE_STATUSES,
  ...PAYMENT_ERROR_STATUSES,
  ...CONTACT_SUPPORT_STATUSES,
  ...FULFILLMENT_REQUIRED_STATUSES,
]

export const LOAN_STARTED_STATUSES = ['first_payment_captured', 'subscribed']

export const PAYOUT_SCHEDULED_STATUSES = ['payout_scheduled']

export const PAYOUT_CANCELED_STATUSES = [
  'initial_partial_merchant_proceeds_transfer_cancelled',
  'merchant_proceeds_transfer_cancelled',
]

export const FUNDS_ON_WAY_STATUSES = [
  'pay_now_payment_succeeded',
  'confirmed',
  'merchant_proceeds_transferred',
]

export const SETTLED_STATUSES = [
  'merchant_payout_processed',
  'final_partial_merchant_proceeds_transferred',
]

export const WON_STATUSES = [...FUNDS_ON_WAY_STATUSES, ...SETTLED_STATUSES]

export const TRANSFERRED_STATUSES = ['transferred']

export const CANCELED_STATUSES = ['canceled']

export const FAILED_STATUSES = [
  'manual_review_failed',
  'consumer_get_id_questions_failed',
  'consumer_id_questions_failed',
  'risk_checks_failed',
  'expired',
]

export const LOST_STATUSES = [...FAILED_STATUSES, ...CANCELED_STATUSES]

export const CLOSED_STATUSES = [
  ...SETTLED_STATUSES,
  ...FUNDS_ON_WAY_STATUSES,
  ...LOST_STATUSES,
  ...TRANSFERRED_STATUSES,
]
