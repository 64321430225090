import { Button, Flex, Icons, Modal, Subtitle, Text, View } from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

const productTypeToInstructions = {
  invisalign: 'aligners',
  clear_correct: 'aligners',
}

function SendModal({ onSubmit, firstName, innerRef, productType }) {
  const { t } = useTranslation()
  const instructions = {
    aligners: [
      t('Complete the smile test'),
      t('Upload photos of current smile'),
    ],
  }
  const currentInstructions =
    instructions[productTypeToInstructions[productType] || 'aligners'] // Temp fallback to aligner
  return (
    <Modal ref={innerRef}>
      {({ isOpen, close }) =>
        isOpen ? (
          <View
            maxWidth={['90%', '460px']}
            mx="auto"
            bg="white"
            p={[5, '40px']}
            borderRadius={1}
            textAlign="center"
          >
            <Form
              onSubmit={async () => {
                try {
                  await onSubmit()
                } catch (e) {
                  return formError(e)
                }
              }}
            >
              {({ handleSubmit, submitSucceeded, submitting, submitError }) => {
                return submitSucceeded ? (
                  <View>
                    <Icons.Tick
                      light
                      width="60px"
                      height="60px"
                      mx="auto"
                      mb={4}
                    />
                    <Subtitle color="default.primary" mb={3}>
                      {t('Information requested')}
                    </Subtitle>
                    <Text mb={6}>
                      {t(
                        'We’ve sent a request to {{leadFirstName}}’s email address.',
                        { leadFirstName: firstName }
                      )}
                    </Text>
                    <Button onClick={close} variant="secondary" width="100%">
                      {t('Close')}
                    </Button>
                  </View>
                ) : (
                  <View>
                    <form onSubmit={handleSubmit}>
                      <Subtitle color="default.primary" mb={3}>
                        {t('Sending reminder')}
                      </Subtitle>
                      <Text mb={4}>
                        {t(
                          '{{leadFirstName}} will be reminded to complete the enquiry',
                          { leadFirstName: firstName }
                        )}
                      </Text>
                      <View display="inline-block">
                        {currentInstructions.map(q => (
                          <Flex mb={3} key={q} justifyContent="flex-start">
                            <View
                              height="10px"
                              width="10px"
                              bg="default.primary"
                              mr={3}
                              borderRadius="50%"
                            />
                            <Text>{q}</Text>
                          </Flex>
                        ))}
                      </View>
                      {submitError ? (
                        <Button variant="secondary" disabled mt={3}>
                          {t('There was an error')}
                        </Button>
                      ) : (
                        <Button loading={submitting} width="100%" mt={3}>
                          {t('Send reminder')}
                        </Button>
                      )}
                      <Button
                        onClick={close}
                        variant="secondary"
                        width="100%"
                        mt={4}
                      >
                        {t('Close')}
                      </Button>
                    </form>
                  </View>
                )
              }}
            </Form>
          </View>
        ) : null
      }
    </Modal>
  )
}

SendModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  firstName: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
}

function SendReminder({ onSubmit, firstName, productType, ...rest }) {
  const modal = React.useRef()
  return (
    <View {...rest}>
      <SendModal
        onSubmit={onSubmit}
        firstName={firstName}
        innerRef={modal}
        productType={productType}
      />
      <Button
        width={['100%', 'auto']}
        variant="secondary"
        sizeVariant="sm"
        onClick={() => modal.current.open()}
      >
        Send reminder
      </Button>
    </View>
  )
}

SendReminder.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
}

export default SendReminder
