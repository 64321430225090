import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@tabeo/scarf2'
import { accountingFormat, format } from '@tabeo/sharpei/utils/currency'
import { PayoutPayment } from '@tabeo/ts-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

export function getSummaryStatsForPayments(payments: PayoutPayment[]) {
  const totalFees = payments.reduce((acc, payment) => acc + payment.fee, 0)
  const refunds = payments.reduce((acc, payment) => {
    if (payment.type === 'refund') {
      return acc + payment.gross
    }
    return acc
  }, 0)
  const totalGross = payments.reduce((acc, payment) => {
    if (payment.type === 'payment') {
      return acc + payment.gross
    }
    return acc
  }, 0)
  const totlatNet = payments.reduce((acc, payment) => acc + payment.net, 0)

  return {
    totlatNet,
    totalFees,
    totalGross,
    refunds,
  }
}

export default function Summary({
  payments,
  showFees,
}: {
  payments: PayoutPayment[]
  showFees: boolean
}) {
  const { t } = useTranslation()

  const { totlatNet, totalFees, totalGross, refunds } =
    getSummaryStatsForPayments(payments)

  const shouldShowIncomingPayments = showFees || (!!totalGross && !!refunds)
  const shouldShowRefunds = !!refunds && !!totalGross
  const shouldShowEstimatedFees = showFees

  const shouldShowSubtotalTally =
    shouldShowIncomingPayments || shouldShowRefunds || shouldShowEstimatedFees

  return (
    <>
      <div
        className={cn(
          'space-y-3 bg-tabeo-sky-2',
          shouldShowSubtotalTally ? 'p-4' : ''
        )}
      >
        {shouldShowIncomingPayments && (
          <div className="flex justify-between">
            <div className="caption">{t('Incoming payments')}</div>
            <div className="caption">{format(totalGross)}</div>
          </div>
        )}
        {shouldShowRefunds && (
          <div className="flex justify-between">
            <div className="caption">{t('Refunds')}</div>
            <div className="caption">{accountingFormat(refunds)}</div>
          </div>
        )}
        {shouldShowEstimatedFees && (
          <div className="flex justify-between">
            <div className="caption flex">
              {t('Estimated fees')}
              <Tooltip
                content={p => (
                  <Tooltip.Title {...p.popper}>
                    <Tooltip.Arrow {...p.arrow} />
                    Transaction Fees may be estimated based on your Pricing Plan
                    and collected upfront prior to funds reaching your account.
                    Final Transaction Fees will be available on your monthly
                    invoice.
                  </Tooltip.Title>
                )}
              >
                <InformationCircleIcon className="ml-1 h-5 w-5 text-tabeo-primary-3" />
              </Tooltip>
            </div>
            <div className="caption">{accountingFormat(totalFees)}</div>
          </div>
        )}
      </div>
      <div className="mb-5 bg-tabeo-sky-2 px-4 pb-3">
        <div
          className={cn(
            'flex items-center justify-between pt-3 font-medium',
            shouldShowSubtotalTally ? 'border-t' : ''
          )}
        >
          <div className="caption">{t('Net amount')}</div>
          <div className="font-medium">{format(totlatNet)}</div>
        </div>
      </div>
    </>
  )
}
