import { themeGet } from '@styled-system/theme-get'
import React from 'react'
import styled from 'styled-components'
import ScarfProps from '../types/ScarfProps'
import Flex from './Flex'
import { Caption } from './Text'
import View from './View'

const StyledFlex = styled(Flex)`
  &:not(:last-of-type) {
    margin-bottom: ${themeGet('space.2')}px;
  }
`

const notAvailable: any[] = [undefined, null, false, '']

function shouldRenderItem({
  value,
  placeholder,
}: {
  value?: React.ReactNode
  placeholder?: React.ReactNode
}) {
  return !notAvailable.includes(value) || placeholder
}

export type AttributeProps = ScarfProps & {
  labelWidth?: string | number
  defaultWidth?: string | number
  sections?: {
    label: React.ReactNode
    value?: React.ReactNode
    formattedValue?: React.ReactNode
    placeholder?: React.ReactNode
  }[][]
}

function Attributes({
  sections = [],
  defaultWidth,
  labelWidth,
  ...rest
}: AttributeProps) {
  if (defaultWidth) {
    // eslint-disable-next-line no-console
    console.warn(
      'Attributes: `defaultWidth` prop is no longer needed as we use `useLayoutEffect` to prevent flickering after first paint. Please remove it.'
    )
  }
  const [width, setWidth] = React.useState('small')
  const container = React.useRef<HTMLElement>()
  function handleContainerSizeChange() {
    if (container.current) {
      setWidth(
        container.current.offsetWidth > 577
          ? 'large'
          : container.current.offsetWidth > 400
          ? 'medium'
          : 'small'
      )
    }
  }
  React.useLayoutEffect(() => {
    handleContainerSizeChange()
    window.addEventListener('resize', handleContainerSizeChange)
    return () => window.removeEventListener('resize', handleContainerSizeChange)
  }, [width])

  const shouldRenderComponent = sections.find((attributes) =>
    attributes.find(shouldRenderItem)
  )

  return shouldRenderComponent ? (
    <View ref={container} {...rest}>
      {sections.map((attributes, index) =>
        !attributes.find(shouldRenderItem) ? null : (
          <View key={index} mb={index < sections.length - 1 ? 5 : 0}>
            {attributes.map(({ label, value, formattedValue, placeholder }) =>
              shouldRenderItem({ value, placeholder }) ? (
                <StyledFlex
                  justifyContent="flex-start"
                  flexDirection={[
                    'column',
                    width !== 'small' ? 'row' : 'column',
                  ]}
                  alignItems={[
                    'stretch',
                    width !== 'small' ? 'flex-start' : 'stretch',
                  ]}
                  key={`${label}${value}`}
                >
                  <Caption
                    width={[
                      '100%',
                      labelWidth ||
                        (width === 'large'
                          ? '220px'
                          : width === 'medium'
                          ? '200px'
                          : '100%'),
                    ]}
                    mr={[0, 5]}
                    color="ink.2"
                    data-testid="attribute-label"
                  >
                    {label}
                  </Caption>
                  <Caption
                    color="ink.0"
                    flex="1"
                    fontSize={[2, 1]}
                    style={{
                      wordBreak: 'break-word',
                      whiteSpace: 'pre-wrap',
                    }}
                    data-testid="attribute-value"
                  >
                    {!notAvailable.includes(value)
                      ? formattedValue || value
                      : placeholder}
                  </Caption>
                </StyledFlex>
              ) : null
            )}
          </View>
        )
      )}
    </View>
  ) : null
}

export default Attributes
