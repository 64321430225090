import { Text, Title } from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import Layout from 'components/Layout'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Form from './Form'

class Reset extends Component {
  state = {
    isSuccess: false,
  }

  handleSubmit = values => {
    const { requestPasswordResetEmail } = this.props

    // Submit verification email request
    return (
      requestPasswordResetEmail(values)
        .then(() => {
          this.setState({
            isSuccess: true,
          })
        })
        // Return server errors to the form
        .catch(e => {
          return formError(e)
        })
    )
  }

  render() {
    const { isSuccess } = this.state
    const { t } = this.props

    return (
      <Layout bg="white" maxWidth="640px">
        <Title mb={5}>{t('Reset Password')}</Title>
        {!isSuccess ? (
          <>
            <Text mb={5}>
              {t(
                'Enter your email address and we will send you a link to reset your password.'
              )}
            </Text>
            <Form onSubmit={this.handleSubmit} />
          </>
        ) : (
          <Text mb={5}>
            {t(
              'A link to reset your password has been sent please check your inbox.'
            )}
          </Text>
        )}
      </Layout>
    )
  }
}

Reset.propTypes = {
  requestPasswordResetEmail: PropTypes.func.isRequired,
  t: PropTypes.func,
}

export default withTranslation()(Reset)
