import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import { Basket, CountryCode, Currency } from '@tabeo/ts-types'

interface Params {
  searchQuery?: string
  limit?: number
  offset?: number
  conditions?: any
  orderBy?: {
    field: string
    direction: 'asc' | 'desc'
  }
}

export type ReconciliationPayment = {
  id: number
  displayID: string
  basketID: number
  basket?: Basket
  merchantID: number
  patientID: number
  merchantTreatmentID: number
  merchantClinicianID: number
  patientName: string
  treatmentName: string
  amount: number
  currency: Currency
  countryCode: CountryCode
  status: string
  type: 'terminal' | 'link'
  chargedAt: string
  createdAt: string
  updatedAt: string
}

interface Data {
  items: null | ReconciliationPayment[]
  totalCount: number
}

class ReconciliationPayments extends Resource<Data, Params> {
  getInitialData() {
    return {
      items: null,
      totalCount: 0,
    }
  }

  getInitialConfig() {
    return {
      refreshInterval: 30 * 1000,
    }
  }

  async fetch() {
    const {
      searchQuery,
      limit = 100,
      offset = 0,
      conditions = [],
      orderBy: { field = 'chargedAt', direction = 'desc' } = {
        field: 'chargedAt',
        direction: 'desc',
      },
    } = this.params

    const searchParams = new URLSearchParams()
    if (conditions) {
      searchParams.append('jsonConditions', JSON.stringify(conditions))
    }

    if (searchQuery) {
      searchParams.append('searchPhrase', searchQuery)
    }

    searchParams.append('limit', limit.toString())
    searchParams.append('offset', offset.toString())
    searchParams.append('order', `${field}:${direction}`)

    const subResources = formatSubresources([
      'basket.merchantClinician.clinician',
      'basket.patient',
    ])

    const {
      data: { payments },
      totalCount,
    } = await api.get(
      `/merchant/reconciliation/payments?${searchParams.toString()}&${subResources}`,
      {
        responseDataPath: 'data',
      }
    )
    this.data = {
      items: payments,
      totalCount,
    }
  }
}

export default ReconciliationPayments
export const useReconciliationPayments = createResourceHook(
  ReconciliationPayments
)
