import * as Tabs from '@radix-ui/react-tabs'
import { twMerge } from 'tailwind-merge'

export default function Tab({ className, ...rest }: Tabs.TabsTriggerProps) {
  return (
    <Tabs.Trigger
      className={twMerge(
        'caption flex w-full flex-col items-center justify-start gap-1 rounded border border-tabeo-sky-0 px-4 py-3 font-medium ring-tabeo-primary-3 hover:bg-tabeo-sky-2 aria-selected:border-tabeo-primary-3 aria-selected:bg-tabeo-primary-6 aria-selected:ring-1 desktop:flex-row desktop:gap-2',
        className
      )}
      {...rest}
    />
  )
}
