import React, { useContext } from 'react'
import ModuleContext from '../ModuleContext'
import AnimateHeight, { AnimateHeightProps } from '../../AnimateHeight'

export type CollapsibleProps = AnimateHeightProps & {
  id?: string
  isOpen?: boolean
}

function Collapsible({
  id = 'default',
  isOpen: controlledIsOpen,
  ...rest
}: CollapsibleProps) {
  const { collapsible } = useContext(ModuleContext)

  const isOpen =
    controlledIsOpen !== undefined ? controlledIsOpen : collapsible[id]

  const height = isOpen ? 'auto' : 0

  return <AnimateHeight duration={200} height={height} {...rest} />
}

export default Collapsible
