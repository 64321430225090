import React from 'react'
import Svg, { G, SvgProps } from './Base'

function Trash({ fill, ...props }: SvgProps) {
  return (
    <Svg {...props}>
      <G
        stroke={fill}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M5.5 7.455H16.136M14.955 8.282V16.2c0 .718-.53 1.3-1.182 1.3h-5.91c-.652 0-1.181-.582-1.181-1.3V8.282M8.455 7.1V5.8c0-.718.529-1.3 1.181-1.3H12c.653 0 1.182.582 1.182 1.3v1.3M9.636 10.409v4.727M12 10.409v4.727" />
      </G>
    </Svg>
  )
}

export default Trash
