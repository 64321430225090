import React from 'react'
import Svg, { Path, SvgProps } from './Base'

function Phone({ fill, ...rest }: SvgProps) {
  return (
    <Svg {...rest} viewBox="0 0 25 24">
      <g
        transform="translate(.5)"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path
          fill="none"
          stroke={fill}
          strokeWidth={1.5}
          d="M18.545 15.256v1.97a1.312 1.312 0 01-1.435 1.314c-2.025-.22-3.97-.91-5.68-2.017a12.828 12.828 0 01-3.95-3.941 12.989 12.989 0 01-2.02-5.695 1.312 1.312 0 011.309-1.432h1.975a1.316 1.316 0 011.317 1.13c.083.63.238 1.25.46 1.845a1.31 1.31 0 01-.296 1.386l-.836.835a10.524 10.524 0 003.95 3.941l.836-.834a1.32 1.32 0 011.388-.296 8.435 8.435 0 001.85.46 1.314 1.314 0 011.132 1.334z"
        />
      </g>
    </Svg>
  )
}

export default Phone
