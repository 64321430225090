import React, { useContext, useMemo } from 'react'
import ScarfProps from '../types/ScarfProps'
import * as Icons from './Icons'
import Text from './Text'

export type Context = {
  status?: 'warning' | 'error' | 'default'
}

const AlertContext = React.createContext<Context>({})

const icons = {
  warning: {
    Icon: Icons.Alert,
    iconColor: 'yellow.3',
  },
  error: {
    Icon: Icons.Alert,
    iconColor: 'red.3',
  },
  default: {
    Icon: Icons.Questionmark,
    iconColor: 'blue.5',
  },
}

function Icon({ ...rest }) {
  const { status } = useContext(AlertContext)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { Icon, iconColor } = icons[status!] || {}

  if (!Icon) {
    return null
  }

  return <Icon fill={iconColor} height="28px" width="28px" mr={2} {...rest} />
}

const colors = {
  warning: {
    bg: 'yellow.6',
    color: 'yellow.2',
    borderColor: 'yellow.5',
  },
  error: {
    bg: 'red.6',
    color: 'red.2',
    borderColor: 'red.5',
  },
  default: {
    bg: 'blue.6',
    color: 'blue.2',
    borderColor: 'blue.5',
  },
}

export type Props = ScarfProps & {
  status?: 'warning' | 'error' | 'default'
}

function Alert({ status = 'default', children, ...rest }: Props) {
  const value = useMemo(() => ({ status }), [status])

  return (
    <AlertContext.Provider value={value}>
      <Text
        fontWeight="semibold"
        fontSize="14px"
        display="flex"
        alignItems="center"
        p={3}
        border={1}
        borderRadius={1}
        {...(colors[status] || { color: 'currentColor' })}
        {...rest}
      >
        {children}
      </Text>
    </AlertContext.Provider>
  )
}

Alert.Icon = Icon

export default Alert
