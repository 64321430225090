import { Button, Modal, Text, Title, View } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import FormError from 'components/Form/FormError'
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import charged from './assets/charged.svg'

export const Icon = styled('img')`
  margin: 12px auto 30px;
  display: block;
`

Icon.defaultProps = {
  src: charged,
}

function Success({ close, consumerFirstName }) {
  const { t } = useTranslation()
  return (
    <>
      <Title mb={3}>{t('Customer charged succesfully')}</Title>
      <Icon />
      <Text mb={6} color="ink.1">
        {t(
          '{{consumerFirstName}} will receive a confirmation any moment now.',
          { consumerFirstName }
        )}
      </Text>
      <Button variant="secondary" width="100%" onClick={close}>
        {t('Close')}
      </Button>
    </>
  )
}

Success.propTypes = {
  close: PropTypes.func,
  consumerFirstName: PropTypes.string.isRequired,
}

function Charge({ innerRef, onConfirm, amount, consumerFirstName }) {
  const { t } = useTranslation()
  return (
    <Modal ref={innerRef}>
      {({ isOpen, close }) =>
        isOpen ? (
          <View
            textAlign="center"
            maxWidth={['90%', '460px']}
            mx="auto"
            bg="white"
            borderRadius={1}
            boxShadow={1}
            p={[5, 10]}
          >
            <Form onSubmit={onConfirm}>
              {({ handleSubmit, submitting, submitSucceeded }) => {
                return !submitSucceeded ? (
                  <View
                    bg="white"
                    borderRadius="0 0 4px 4px"
                    textAlign="center"
                  >
                    <form onSubmit={handleSubmit}>
                      <Title mb={3}>{t('Charge customer now?')}</Title>
                      <Text mb={5}>
                        {t(
                          'Please make sure you have notified the customer prior to changing the charge date.'
                        )}
                      </Text>
                      <Button loading={submitting} width="100%" mb={5}>
                        {t('Charge customer {{amount}} now', {
                          amount: format(amount),
                        })}
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        width="100%"
                        onClick={close}
                      >
                        {t('Close')}
                      </Button>
                      <FormError />
                    </form>
                  </View>
                ) : (
                  <Success
                    close={close}
                    consumerFirstName={consumerFirstName}
                  />
                )
              }}
            </Form>
          </View>
        ) : null
      }
    </Modal>
  )
}

Charge.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  onConfirm: PropTypes.func.isRequired,
  amount: PropTypes.number,
  consumerFirstName: PropTypes.string,
}

export default Charge
