import { z } from 'zod'
import { t } from 'i18n'
import { format } from '@tabeo/sharpei/utils/currency'
import { emailPCA } from '@tabeo/sharpei/utils/validations'

export function zodValidator<T>(schema: z.ZodType<T>) {
  return async (values: any) => {
    try {
      await schema.parseAsync(values)
      return {}
    } catch (error) {
      const fieldErrors: any = {}
      const { errors } = error as z.ZodError

      errors.forEach(error => {
        let current = fieldErrors

        error.path.forEach((path, index) => {
          const nextPath = error.path[index + 1]

          if (nextPath === undefined) {
            current[path] = error.message
            return
          }

          if (Number.isInteger(nextPath)) {
            current[path] = current[path] || []
            current = current[path]
            return
          }

          current[path] = current[path] || {}
          current = current[path]
        })
      })

      return fieldErrors
    }
  }
}

export const tabeoErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === 'invalid_type') {
    if (['undefined', 'nan', 'null'].includes(issue.received)) {
      return { message: t('Field is required') }
    }
  }

  if (issue.code === 'too_small') {
    if (issue.type === 'number') {
      return {
        message: t('Must be at least or equat to {{min}}', {
          min: issue.minimum,
        }),
      }
    }

    if (issue.type === 'string') {
      return {
        message: t(`Must be {{min}} characters or more`, {
          min: issue.minimum,
        }),
      }
    }
  }

  if (issue.code === 'too_big') {
    if (issue.type === 'number') {
      return {
        message: t(`Must be less or equal to {{max}}`, { max: issue.maximum }),
      }
    }

    if (issue.type === 'string') {
      return {
        message: t(`Must be {{max}} characters or less`, {
          max: issue.maximum,
        }),
      }
    }
  }

  if (issue.code === 'invalid_string') {
    if (issue.validation === 'email') {
      return {
        message: t('Invalid email address format'),
      }
    }
  }

  return { message: issue.message || ctx.defaultError }
}

export const priceValidationMessage = (min: number, max: number) => {
  return {
    message: t(`Please specify an amount between {{min}} and {{max}}`, {
      min: format(min),
      max: format(max),
    }),
  }
}

export const zodEmailPCA = z.string().refine(async value => {
  const error = await emailPCA(value)
  return !error
}, t('Email address is not valid'))
