import React from 'react'
import Svg, { G, SvgProps } from './Base'

function Calendar({ fill, ...props }: SvgProps) {
  return (
    <Svg {...props}>
      <G
        transform="translate(5.5 5.5)"
        stroke={fill}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect y={1.1} width={11} height={9.9} rx={2} />
        <path d="M8.25 0v3.3M2.75 0v3.3M0 3.85h11" />
      </G>
    </Svg>
  )
}

export default Calendar
