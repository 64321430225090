import { debounce } from '@tabeo/scarf'
import { useCallback } from 'react'

function useFetcher(useResource, options = {}) {
  const {
    params = { limit: 50 },
    config = { refreshInterval: 0 },
    debounceTime = 1000,
    mapItemToOption = i => ({
      value: i.id,
      data: i,
    }),
    filterItems = () => true,
  } = options || {}

  const { resource } = useResource(params)
  resource.config = {
    ...resource.config,
    ...config,
  }

  /* eslint-disable */
  const fetch = useCallback(
    debounce(
      async query =>
        (await resource.fetch(query)).items
          .filter(filterItems)
          .map(mapItemToOption),
      debounceTime
    ),
    [resource, filterItems, mapItemToOption, debounceTime]
  )
  /* eslint-enable */

  return fetch
}

export default useFetcher
