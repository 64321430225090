import { useTranslation } from 'react-i18next'
import { ReactComponent as TickGreen } from 'components/checkout-payment/assets/tick-green.svg'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import moment from 'moment'
import { useSubscriptionV2 } from 'resources/SubscriptionV2'
import CancelButton from '../CancelButton'

export default function Active() {
  const { t } = useTranslation()
  const { data } = useSubscriptionV2()

  const nextPaymentDate = data?.subscription?.nextPaymentDate

  return (
    <ActionCard>
      <ActionCard.Header>
        <TickGreen className="h-6 w-6" />
        {t('Membership active')}
      </ActionCard.Header>
      <ActionCard.Body>
        {nextPaymentDate
          ? t(
              'The membership is active. The next payment will be collected on {{date}}.',
              {
                date: moment(nextPaymentDate).format('DD MMM YYYY'),
              }
            )
          : t('The membership is active.')}
      </ActionCard.Body>
      <ActionCard.Actions>
        <CancelButton />
      </ActionCard.Actions>
    </ActionCard>
  )
}
