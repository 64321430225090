import React, { useEffect } from 'react'
import { Merge } from '../types/helpers'
import Button, { WrapProps } from './Button'
import ArrowDown from './Icons/ArrowDown'
import Popover, { PopoverProps } from './Popover'
import Text from './Text'
import View from './View'

export type MultiActionButtonProps = Merge<
  WrapProps,
  {
    secondaryActions: {
      label: string
      onClick?: (
        e: React.MouseEvent,
        props: {
          open: boolean
          setOpen: React.Dispatch<React.SetStateAction<boolean>>
          ref: any
        }
      ) => void
      [key: string]: unknown
    }[]
    onClick?: (
      e: React.MouseEvent,
      props: {
        open: boolean
        setOpen: React.Dispatch<React.SetStateAction<boolean>>
        ref: any
      }
    ) => void
    popOverPlacement?: PopoverProps['placement']
  }
>

const MultiActionButton = ({
  children,
  secondaryActions,
  popOverPlacement = 'bottom-end',
  variant = 'secondary',
  sizeVariant = 'md',
  onClick,
  ...rest
}: MultiActionButtonProps) => {
  const [open, setOpen] = React.useState(false)
  const buttonRef = React.useRef<HTMLButtonElement>()

  // Chrome will no longer fire click events on nested elements inside a disabled button
  // so we need to manually handle the click event on the arrow
  const arrowRef = React.useRef<HTMLDivElement>(null)
  useEffect(() => {
    function handleClick(e: globalThis.MouseEvent) {
      e.stopPropagation()
      setOpen((o) => !o)
    }
    const $arrow = arrowRef.current
    if (arrowRef.current) {
      arrowRef.current.addEventListener('click', handleClick)
    }
    return () => {
      if ($arrow) {
        $arrow.removeEventListener('click', handleClick)
      }
    }
  }, [arrowRef, setOpen])

  return (
    <Button
      ref={buttonRef}
      right={
        <View
          ref={arrowRef}
          px={sizeVariant === 'md' ? 2 : 2}
          py={sizeVariant === 'md' ? 2 : sizeVariant === 'sm' ? '7px' : '5px'}
          ml={sizeVariant === 'md' ? 5 : 4}
          borderLeft="1px solid"
          borderLeftColor={variant === 'secondary' ? 'sky.0' : 'white'}
          cursor="pointer"
        >
          <ArrowDown
            fill={variant === 'secondary' ? 'primary.3' : 'white'}
            width={sizeVariant === 'md' ? '20px' : '16px'}
          />
        </View>
      }
      variant={variant}
      sizeVariant={sizeVariant}
      {...rest}
      onClick={(e: React.MouseEvent) =>
        onClick?.(e, { open, setOpen, ref: buttonRef })
      }
    >
      {children}
      <Popover
        open={open}
        referenceElement={buttonRef}
        placement={popOverPlacement}
        onClickOut={() => setOpen(false)}
        offset={[0, 8]}
      >
        {({ attributes, style, ref }) => (
          <View
            bg="white"
            boxShadow={1}
            border={1}
            borderRadius={1}
            minWidth={`${buttonRef.current?.offsetWidth}px`}
            py={1}
            ref={ref}
            // @ts-ignore
            attributes={attributes}
            style={style}
          >
            {secondaryActions.map(({ label, ...a }, i) => (
              <Text
                key={label + i}
                px={3}
                py={2}
                fontSize={sizeVariant === 'md' ? '16px' : '14px'}
                color="ink.1"
                _hover={{
                  bg: 'sky.2',
                }}
                _notLast={{
                  borderBottom: 1,
                }}
                cursor="pointer"
                display="block"
                {...a}
                onClick={async (e: React.MouseEvent) => {
                  e.stopPropagation()
                  setOpen(false)
                  await a?.onClick?.(e, { open, setOpen, ref: buttonRef })
                }}
              >
                {label}
              </Text>
            ))}
          </View>
        )}
      </Popover>
    </Button>
  )
}

export default MultiActionButton
