import { Tag } from '@tabeo/scarf2'
import { t } from 'i18n'

type TagProps = React.ComponentPropsWithoutRef<typeof Tag>

const states = {
  sent: {
    color: 'yellow',
    label: t('Sent'),
  },
  sign_up: {
    color: 'yellow',
    label: t('Sign up'),
  },
  pending: {
    color: 'green',
    label: t('Pending'),
  },
  active: {
    color: 'green',
    label: t('Active'),
  },
  paused: {
    color: 'red',
    label: t('Paused'),
  },
  overdue: {
    color: 'red',
    label: t('Overdue'),
  },
  no_direct_debit: {
    color: 'red',
    label: t('No direct debit'),
  },
  canceled: {
    color: 'gray',
    label: t('Cancelled'),
  },
  ended: {
    color: 'gray',
    label: t('Ended'),
  },
  bulk_transfer: {
    color: 'green',
    label: t('Bulk transfer'),
  },
} as const

export type SubscriptionV2StateTagProps = TagProps & { status?: string }

export default function SubscriptionV2StateTag({
  status,
  ...rest
}: SubscriptionV2StateTagProps) {
  const currentState = status
    ? states[status as keyof typeof states]
    : undefined
  const color = currentState?.color || 'gray'
  const label = currentState?.label || status

  return (
    <Tag color={color} {...rest}>
      {label}
    </Tag>
  )
}
