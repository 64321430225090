import { NoSymbolIcon } from '@heroicons/react/20/solid'
import { HandThumbUpIcon } from '@heroicons/react/24/outline'
import { ExposedError } from '@stripe/terminal-js'
import { Button, Caption, Title, usePromise, View } from '@tabeo/scarf'
import FieldError from 'components/Form/FieldError'
import PaymentOptions from 'components/Payment/Options'
import amexSvg from 'components/Payment/Options/assets/amex.svg'
import Summary from 'components/terminal/Summary'
import useStripeTerminal from 'hooks/useStripeTerminal'
import { useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useApi } from 'resources/Api'
import { useMerchant } from 'resources/Merchant'
import { useTerminalPayment } from 'resources/TerminalPayment'
import cardReaderImg from '../assets/card-reader.png'
import nonUk from '../assets/non-uk.png'
import TerminalPaymentContext from '../Context'

function CollectPaymentMethod() {
  const { t } = useTranslation()

  const {
    collectPaymentMethod,
    cancelCollectPaymentMethod,
    isSimulated,
    clearReaderDisplay,
  } = useStripeTerminal()
  const { dispatch, state, modal } = useContext(TerminalPaymentContext)
  const { data: tp, resource } = useTerminalPayment({
    id: state.terminalPaymentId,
  })

  const { data } = useApi(() => {
    if (!tp?.id) {
      throw new Error('Missing ID')
    }

    return {
      endpoint: `/merchant/terminal-payments/${tp.id}/payment-intent`,
    }
  })

  const pi = data?.paymentIntent

  const [triggerCancel, { pending, error }] = usePromise(async () => {
    await cancelCollectPaymentMethod()
    await resource?.cancel()
    modal.close()
  })

  const { data: merchant } = useMerchant()

  const { isAmexCapable, isInternationalCardsCapable } =
    merchant?.activeFeeGroup || {}

  const didInit = useRef(false)

  useEffect(() => {
    const fn = async () => {
      try {
        // Flow crashes when simulated terminal called with beta options
        const options = isSimulated
          ? undefined
          : {
              config_override: {
                update_payment_intent: true,
                payment_intent_id: pi?.id,
              },
            }

        const paymentIntent = await collectPaymentMethod(
          pi?.clientSecret,
          // @ts-ignore
          options
        )

        // @ts-ignore
        const brand = paymentIntent.payment_method?.card_present?.brand
        if (
          ![...['visa', 'mastercard'], isAmexCapable && 'amex']
            .filter(Boolean)
            .includes(brand.toLowerCase())
        ) {
          paymentIntent.last_payment_error = {
            type: 'card_error',
            decline_code: 'brand',
          }
          dispatch({ type: 'payment-declined', payload: paymentIntent })
          await clearReaderDisplay()
          return
        }

        // @ts-ignore
        const country = paymentIntent.payment_method?.card_present?.country
        if (!isInternationalCardsCapable && country.toLowerCase() !== 'gb') {
          paymentIntent.last_payment_error = {
            type: 'card_error',
            decline_code: 'country',
          }
          dispatch({ type: 'payment-declined', payload: paymentIntent })
          await clearReaderDisplay()
          return
        }

        dispatch({ type: 'payment-method-collected', payload: paymentIntent })
      } catch (e) {
        if ((e as ExposedError).code === 'canceled') {
          return
        }
        dispatch({ type: 'error' })
      }
    }

    if (!didInit.current && pi) {
      didInit.current = true
      fn()
    }
  }, [
    collectPaymentMethod,
    dispatch,
    pi,
    isSimulated,
    isAmexCapable,
    isInternationalCardsCapable,
    clearReaderDisplay,
  ])

  return (
    <View textAlign="center">
      <View as="img" src={cardReaderImg} width="176px" mx="auto" />
      <Title mt={6}>{t('Tap or Insert Card')}</Title>
      <Caption mt={1}>{t('Please do not refresh this page')}</Caption>
      <Summary data={tp} mt={6} />
      <div className="mt-6 flex justify-between">
        <div className="flex flex-1 items-center gap-2">
          <HandThumbUpIcon className="h-5 w-5 text-tabeo-green-2" />
          <Caption>{t('Accepted payment types')}</Caption>
        </div>
        <PaymentOptions amex={isAmexCapable} />
      </div>
      {(!isAmexCapable || !isInternationalCardsCapable) && (
        <div className="mt-2 flex  justify-between">
          <div className="flex flex-1 items-center gap-2">
            <NoSymbolIcon className="h-5 w-5 text-tabeo-ink-2" />
            <Caption>{t('Excluded payment types')}</Caption>
          </div>
          <div className="flex items-center gap-2">
            {!isInternationalCardsCapable && (
              <img src={nonUk} className="h-8" />
            )}
            {!isAmexCapable && <img src={amexSvg} className="h-8" />}
          </div>
        </div>
      )}
      <View mt={6}>
        <Button variant="transparent" onClick={triggerCancel} loading={pending}>
          {t('Cancel')}
        </Button>
        {error && (
          <FieldError message={error?.message || t('There was an error')} />
        )}
      </View>
    </View>
  )
}

export default CollectPaymentMethod
