import React from 'react'
import styled from 'styled-components'
import ScarfProps from '../../../../types/ScarfProps'
import Text from '../../../Text'
import View from '../../../View'

const StyledText = styled(Text)`
  white-space: nowrap;
`

export type MetaItemProps = ScarfProps & {
  title?: string
}

function MetaItem({ title, children, ...rest }: MetaItemProps) {
  return (
    <View mb={2} {...rest}>
      {title && (
        <StyledText fontSize={['14px', '12px']} color="ink.2" mr={[0, 1]}>
          {title}
        </StyledText>
      )}
      <StyledText fontSize={['16px', '12px']}>{children}</StyledText>
    </View>
  )
}

export default MetaItem
