import { Button } from '@tabeo/scarf'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import readerImg from './assets/reader-wifi.png'

export default function RegisterReader() {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center justify-center p-5 desktop:p-10">
      <img src={readerImg} alt="Reader" className="h-[160px] w-[160px]" />
      <h2 className="title mt-6">{t('Register your reader')}</h2>
      <p className="mt-1">
        {t('Set up your reader and start accepting terminal payments.')}
      </p>
      <div className="mt-6 flex flex-col space-y-4 text-center">
        <Link to="/settings?tab=payments&modal=new-reader">
          <Button>{t('Register reader')}</Button>
        </Link>
        <Button
          variant="transparent"
          as="a"
          href="https://intercom.help/tabeo/en/articles/6913358-how-to-set-up-your-card-reader"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('Quick setup guide')}
        </Button>
      </div>
    </div>
  )
}
