import { Attributes, Panel, View } from '@tabeo/scarf'
import { Payout } from '@tabeo/ts-types'
import moment from 'moment'
import PropTypes from 'prop-types'
import { ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'

function OverviewPanel({
  data,
  ...rest
}: {
  data: Payout
} & ComponentPropsWithoutRef<typeof Panel>) {
  const { t } = useTranslation()
  const sections = [
    [
      { label: t('Sender'), value: data.from.toUpperCase() },
      {
        label: t('Date'),
        value: data.transactionAt,
        formattedValue: moment(data.transactionAt).format(
          'DD MMM YYYY, hh:mm a'
        ),
      },
      {
        label: t('Est. arrival date'),
        value: data.transactionAt,
        formattedValue: moment(data.arrivalDate).format('DD MMM YYYY'),
      },
      { label: t('Statement descriptor'), value: data.statementDescriptor },
    ],
  ]

  return (
    <Panel title={t('Overview')} {...rest}>
      <View p={5} bg="white" borderBottom={1}>
        <Attributes sections={sections} />
      </View>
    </Panel>
  )
}

OverviewPanel.propTypes = {
  data: PropTypes.object.isRequired,
}

export default OverviewPanel
