import { Accordion, Attributes, Button, Settings } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'resources/Auth'
import dateWithEllapsedDays from 'utils/dateWithEllapsedDays'

const { Stack } = Settings

function UserItem({ data, onRemove }) {
  const {
    id,
    firstName,
    lastName,
    email,
    address,
    mobilePhone,
    jobFunction,
    createdAt,
    updatedAt,
    lastLogin,
    status,
    type,
  } = data

  const {
    data: { user },
  } = useAuth()

  const { t } = useTranslation()

  return (
    <Stack.Item p={0} _first={{}}>
      <Accordion
        title={`${firstName} ${lastName}`}
        status={type === 'active' ? t('Active') : t('Invited')}
        statusColor={status === 'activated' ? 'green' : 'yellow'}
        bg="sky.3"
      >
        <Attributes
          sections={[
            [
              { label: t('Email'), value: email },
              type === 'active' && {
                label: t('Phone number'),
                value: mobilePhone || t('Not provided'),
              },
              {
                label: t('Job function'),
                value: jobFunction || t('Not provided'),
              },
              { label: t('Address'), value: address },
            ],
            type === 'active' && [
              {
                label: t('Created on'),
                value: dateWithEllapsedDays(createdAt),
              },
              {
                label: t('Last updated'),
                value: dateWithEllapsedDays(updatedAt),
              },
              {
                label: t('Last login'),
                value: dateWithEllapsedDays(lastLogin),
              },
            ],
          ].filter(Boolean)}
        />
        {user.id !== id && (
          <Button
            sizeVariant="sm"
            variant="secondary"
            onClick={onRemove}
            mt={5}
          >
            {t('Remove')}
          </Button>
        )}
      </Accordion>
    </Stack.Item>
  )
}

UserItem.propTypes = {
  data: PropTypes.any.isRequired,
  onRemove: PropTypes.func,
}

export default UserItem
