import { plansorchestrator } from '@tabeo/platform-api-client'
import { View } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

type PlanPanelProps = {
  data?: plansorchestrator.ModelsGetSubscriptionsPlan
}

export default function PlanPanel({ data }: PlanPanelProps) {
  const { t } = useTranslation()

  return (
    <View
      boxShadow={1}
      borderRadius={1}
      border={1}
      overflow="hidden"
      bg="white"
      as={Link}
      to={`/plans/${data?.id}`}
      data-testid="plan-panel"
    >
      <div className="flex items-center gap-5 p-5">
        {data?.imageUrl && (
          <div className="aspect-square w-20">
            <img src={data?.imageUrl} className="h-full w-full object-cover" />
          </div>
        )}
        <div className="space-y-1">
          <h2 className="heading font-normal text-tabeo-primary-3">
            {data?.name}{' '}
            {data?.internalReference ? ` / ${data?.internalReference}` : ''}
          </h2>
          <p className="text-base text-tabeo-ink-0">
            {t('{{amount}} a month', { amount: format(data?.price) })}
          </p>
        </div>
      </div>
    </View>
  )
}
