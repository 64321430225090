import { View } from '@tabeo/scarf'
import { Merge } from '@tabeo/scarf/dist/types/helpers'
import React, { useMemo, useReducer } from 'react'
import TerminalPaymentContext from './Context'
import reducer from './reducer'
import CapturePayment from './steps/CapturePayment'
import CollectPaymentMethod from './steps/CollectPaymentMethod'
import ConnectReader from './steps/ConnectReader'
import Error from './steps/Error'
import PaymentFailed from './steps/PaymentFailed'
import PaymentReceipt from './steps/PaymentReceipt'
import PaymentSucceeded from './steps/PaymentSucceeded'
import ProcessPayment from './steps/ProcessPayment'
import ReaderBusy from './steps/ReaderBusy'
import TestConnection from './steps/TestConnection'

type TerminalPaymentProps = Merge<
  React.ComponentProps<typeof View>,
  {
    id: number
    modal: {
      close: () => void
      isOpen?: boolean
    }
    onSuccess?: () => void
  }
>

function TerminalPayment({
  id,
  modal,
  onSuccess = () => {},
}: TerminalPaymentProps) {
  const [state, dispatch] = useReducer(reducer, {
    step: 'test-connection',
    terminalPaymentId: id,
  })

  const terminalPaymentContextValue = useMemo(
    () => ({ state, dispatch, modal, onSuccess }),
    [state, dispatch, modal, onSuccess]
  )

  const { step } = state

  return (
    <View
      maxWidth={['90%', '620px']}
      mx="auto"
      bg="white"
      borderRadius={1}
      boxShadow={1}
      p={[5, 10]}
    >
      <TerminalPaymentContext.Provider value={terminalPaymentContextValue}>
        {step === 'test-connection' && <TestConnection />}
        {step === 'connect-reader' && <ConnectReader />}
        {step === 'collect-payment-method' && <CollectPaymentMethod />}
        {step === 'process-payment' && <ProcessPayment />}
        {step === 'capture-payment' && <CapturePayment />}
        {step === 'payment-succeeded' && <PaymentSucceeded />}
        {step === 'payment-receipt' && <PaymentReceipt />}
        {step === 'payment-failed' && <PaymentFailed />}
        {step === 'reader-busy' && <ReaderBusy />}
        {step === 'error' && <Error />}
      </TerminalPaymentContext.Provider>
    </View>
  )
}

export default TerminalPayment
