import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'

const subResources = `subResource=${[
  'numberOfActiveSubscriptions',
  'planFeatures',
  'planPerks',
].join('&subResource=')}`

class Plans extends Resource {
  getInitialData() {
    return {
      items: null,
      totalCount: 0,
    }
  }

  getInitialConfig() {
    return {
      refreshInterval: 15 * 1000,
    }
  }

  async fetch() {
    const { activeTabs = [], searchQuery, limit = 50, offset = 0 } = this.params
    const statuses = activeTabs.reduce((mem, tab) => {
      mem = [...mem, ...tab.statuses]
      return mem
    }, [])
    const condition = `&condition=status:[${statuses.join(',')}]`
    const pagination = `&limit=${limit}&offset=${offset}`
    const endpoint = searchQuery
      ? `/merchant/plan-search?order=id:desc&searchPhrase=${searchQuery}&${subResources}`
      : `/merchant/plans?order=id:desc&${subResources}`

    const {
      data: { plans },
      totalCount,
    } = await api.get(`${endpoint}${condition}${pagination}`, {
      responseDataPath: 'data',
    })

    const data = {
      totalCount,
      items: plans,
    }

    this.data = data

    return data
  }

  create = async payload => {
    const response = await api.post('/merchant/plans', payload)
    await this.fetch()
    return response
  }
}

export default Plans
export const usePlans = createResourceHook(Plans)
