import { useTranslation } from 'react-i18next'
import { ReactComponent as ClockGreen } from 'components/checkout-payment/assets/clock-green.svg'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import CancelButton from '../CancelButton'

export default function Pending() {
  const { t } = useTranslation()

  return (
    <ActionCard>
      <ActionCard.Header>
        <ClockGreen className="h-6 w-6" />
        {t('Direct debit mandate is pending')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t(
          "Tabeo has initiated contact with the customer's bank to set up the direct debit mandate. This process typically takes up to six working days. It's worth noting that users have the option to leave the contract within the 14-day cooling off period. With a success rate of 99%, once the contract is accepted, the customer is committed."
        )}
      </ActionCard.Body>
      <ActionCard.Actions>
        <CancelButton />
      </ActionCard.Actions>
    </ActionCard>
  )
}
