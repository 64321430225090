import { Placement, State } from '@popperjs/core'
import React, { useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import Portal from './Portal'
import View from './View'

const Arrow = styled(View)`
  position: absolute;
  width: 16px;
  height: 16px;

  background: transparent;

  &[placement*='bottom'] {
    top: -8px;
    &::before {
      transform: translateX(0px) rotate(45deg);
    }
  }
  &[placement*='top'] {
    bottom: -8px;
    &::before {
      transform: translateX(0px) rotate(225deg);
    }
  }
  &[placement*='right'] {
    left: -8px;
    &::before {
      transform: translateX(0px) rotate(315deg);
    }
  }
  &[placement*='left'] {
    right: -8px;
    &::before {
      transform: translateX(0px) rotate(135deg);
    }
  }
  &:before {
    /** 
        Rotated object will stay in the parent container...
        It resolves issue with start and end placement on left and right side.
    */
    width: ${100 / Math.sqrt(2)}%;
    height: ${100 / Math.sqrt(2)}%;
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    content: '';
    transform-origin: center center;
  }
`

Arrow.defaultProps = {}

export type PopoverProps = {
  children: (props: {
    style: React.CSSProperties
    arrowProps: {
      ref: any
      style: React.CSSProperties
      placement: string
    }
    ref: any
    attributes?: Record<string, string>
    placement?: Placement
    state: State
    update: (() => Promise<Partial<State>>) | null
    forceUpdate: (() => void) | null
    [key: string]: unknown
  }) => React.ReactNode
  open?: boolean
  onClickOut?: () => void
  referenceElement: any
  placement?: Placement
  offset?: number[]
}

function Popover({
  children,
  open,
  onClickOut,
  referenceElement,
  placement = 'auto',
  offset = [0, 0],
}: PopoverProps) {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const [arrowElement, setArrowElement] = useState(null)

  const { styles, attributes, ...rest } = usePopper(
    referenceElement.current,
    popperElement,
    {
      placement,
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
        {
          name: 'offset',
          options: {
            // @ts-ignore
            offset: arrowElement ? [offset[0], offset[1] + 8] : offset,
          },
        },
      ],
    }
  )

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (
        open &&
        // @ts-ignore
        !popperElement?.contains(e.target) &&
        // @ts-ignore
        !referenceElement.current?.contains(e.target)
      ) {
        onClickOut && onClickOut()
      }
    }

    if (open) {
      window.addEventListener('click', onClick)
    }

    return () => window.removeEventListener('click', onClick)
  }, [onClickOut, open, popperElement, referenceElement])

  return open ? (
    <Portal>
      {children({
        ref: setPopperElement,
        style: { zIndex: 1000, ...styles.popper },
        attributes: attributes.popper,
        ...attributes.popper,
        arrowProps: {
          ref: setArrowElement,
          style: { ...styles.arrow },
          // @ts-ignore
          placement: attributes.popper?.['data-popper-placement'],
        },
        ...rest,
      })}
    </Portal>
  ) : null
}

Popover.Arrow = Arrow

export default Popover
