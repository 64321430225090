import styled from 'styled-components'
import React from 'react'
import ScarfProps from '../../types/ScarfProps'
import Flex from '../Flex'
import { Caption, Prompt } from '../Text'
import View from '../View'

const StyledSection = styled(View)``
StyledSection.defaultProps = {
  _notLast: {
    borderBottom: 1,
    pb: 6,
  },
  _notFirst: {
    mt: 6,
  },
}

function Section(props: ScarfProps) {
  return <StyledSection {...props} />
}

const Header = styled(Flex)`
  &:not(:only-child):not(:last-child) {
    padding-bottom: 16px;
  }
`
Header.defaultProps = {
  justifyContent: 'space-between',
}

const Title = styled(Prompt)``
Title.defaultProps = {
  color: 'ink.0',
  fontWeight: 'semibold',
}

const Description = styled(Caption)``
Description.defaultProps = {
  color: 'ink.2',
  mt: 1,
}

const Body = styled(View)``
Body.defaultProps = {
  mt: 5,
}

Section.Header = Header
Section.Title = Title
Section.Description = Description
Section.Body = Body

export default Section
