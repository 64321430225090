import React from 'react'
import Svg, { G, SvgProps } from './Base'

function Document({ fill, ...props }: SvgProps) {
  return (
    <Svg viewBox="0 0 25 24" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(-.674)">
        <G stroke={fill} fill="none">
          <path
            strokeWidth={1.1}
            d="M5.235 4v16a1 1 0 001 1h12a1 1 0 001-1V7.925a.998.998 0 00-.306-.72l-4.07-3.925a1 1 0 00-.694-.28h-7.93a1 1 0 00-1 1z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.231 6.913h4.004m-4.004 3.375h7.823m-7.823 3.375h7.823m-7.823 3.375h7.823"
          />
          <path strokeWidth={1.1} d="M14.568 3v3.54c0 .551.448 1 1 1h3.667" />
        </G>
      </g>
    </Svg>
  )
}

export default Document
