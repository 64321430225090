import { Subheading } from '@tabeo/scarf'
import config from 'config'
import React from 'react'

const { ENV: env } = config
const style = {
  bottom: '12px',
  right: '0',
  height: 'auto',
  width: 'auto',
  zIndex: 1100,
  left: '50%',
  transform: 'translateX(-50%)',
  position: 'fixed',
  display: 'inline-table',
}

function EnvWarning(props) {
  return env !== 'production' ? (
    <Subheading
      color="white"
      bg="sea.3"
      borderRadius="16px"
      fontSize="12px"
      py="6px"
      px={4}
      style={style}
      {...props}
    >
      {env}
    </Subheading>
  ) : null
}

export default EnvWarning
