import {
  Button,
  DatePicker,
  Flex,
  Modal,
  TextInput,
  Title,
  View,
} from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import { required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import MerchantClinicianSelect from 'components/MerchantClinicianSelect'
import { t } from 'i18n'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'

export const labels = {
  service: {
    type: t('Appointment'),
    date: t('Appointment date'),
    person: t('Clinician'),
    personPlaceholder: t('E.g. Dr. John Smith'),
  },
  good: {
    type: t('Sale'),
    date: t('Sale date'),
    person: t('Staff Name'),
    personPlaceholder: t('E.g. John Smith'),
  },
}

const UpdateModal = React.forwardRef(({ onSubmit, data, ...rest }, ref) => {
  const {
    metadata: { appointment, treatmentStartDate },
    productType,
    state,
    coolingOffPeriodEnds,
    isPN,
    individual,
    merchantClinician,
  } = data || {}
  const {
    data: {
      flags: {
        is_cooling_off_period_capable: isCoolingOffPeriodCapable,
        is_clinicians_treatments_enabled: isCliniciansTreatmentsEnabled,
      },
    },
  } = useMerchant()

  const showDatePicker = ![
    'sent',
    'opened',
    'applying',
    'approved',
    'card_declined',
  ].includes(state)

  const { t } = useTranslation()

  const isCoolingOffDescriptionAvailable =
    isCoolingOffPeriodCapable &&
    !!coolingOffPeriodEnds &&
    coolingOffPeriodEnds > new Date() &&
    ((isPN && treatmentStartDate) || !isPN)

  return (
    <Modal ref={ref}>
      {({ isOpen, close }) => {
        async function handleSubmit(values) {
          try {
            await onSubmit(values)
            close()
          } catch (e) {
            return formError(e)
          }
        }

        return isOpen ? (
          <View
            maxWidth={['90%', '540px']}
            mx="auto"
            bg="white"
            borderRadius={1}
            boxShadow={1}
            p={[5, 10]}
          >
            <Title mb={5}>
              {productType === 'service'
                ? t('Update appointment')
                : t('Update sale')}
            </Title>
            <View
              as={Form}
              onSubmit={handleSubmit}
              initialValues={{ ...appointment, individual, merchantClinician }}
              {...rest}
            >
              {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="patientName"
                    label={t('Patient name')}
                    component={TextInput}
                    validate={required}
                    maxWidth="100%"
                  />
                  {showDatePicker && (
                    <Field
                      name="date"
                      label={labels[productType].date}
                      component={DatePicker}
                      description={
                        isCoolingOffDescriptionAvailable
                          ? t(`Cooling off period ends {{date}}`, {
                              date: moment(coolingOffPeriodEnds).format(
                                'DD/MM/YYYY'
                              ),
                            })
                          : undefined
                      }
                      validate={required}
                      datePickerProps={{
                        modifiers: {
                          disabled: {
                            before: isCoolingOffDescriptionAvailable
                              ? new Date(coolingOffPeriodEnds)
                              : undefined,
                          },
                          today: isCoolingOffDescriptionAvailable
                            ? new Date(coolingOffPeriodEnds)
                            : new Date(),
                        },
                        initialMonth: isCoolingOffDescriptionAvailable
                          ? new Date(coolingOffPeriodEnds)
                          : undefined,
                      }}
                      maxWidth="100%"
                    />
                  )}

                  {!isCliniciansTreatmentsEnabled || individual?.name ? (
                    <Field
                      name="individual.name"
                      label={labels[productType].person}
                      component={TextInput}
                      placeholder={labels[productType].personPlaceholder}
                      validate={required}
                      maxWidth="100%"
                    />
                  ) : (
                    <Field
                      name="merchantClinician"
                      label={labels[productType].person}
                      component={MerchantClinicianSelect}
                      validate={required}
                      maxWidth="100%"
                    />
                  )}

                  <Flex
                    mt={6}
                    justifyContent="flex-start"
                    flexDirection={['column', 'row']}
                  >
                    <Button loading={submitting} mr={[0, 5]} mb={[5, 0]}>
                      {t('Update')}
                    </Button>
                    <Button variant="secondary" type="button" onClick={close}>
                      {t('Close')}
                    </Button>
                  </Flex>

                  <FormError />
                </form>
              )}
            </View>
          </View>
        ) : null
      }}
    </Modal>
  )
})

UpdateModal.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default UpdateModal
