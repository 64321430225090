import { Button } from '@tabeo/scarf'
import { useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'
import Nav, { NavItem } from './commons'

function LoggedOut() {
  const { t } = useTranslation()
  return (
    <Nav>
      <NavItem as={NavLink} to="/support">
        {t('Support')}
      </NavItem>
      <NavItem
        as="a"
        target="_blank"
        rel="noopener noreferrer"
        href="https://tabeo.typeform.com/homepage"
      >
        {t('Book demo')}
      </NavItem>
      <Button my={[2, 3]} mx={3} as={Link} sizeVariant="sm" to="/login">
        {t('Log in')}
      </Button>
    </Nav>
  )
}

LoggedOut.propTypes = {}

export default LoggedOut
