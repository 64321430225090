import { Caption, Panel, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ImplantAnswers from './ImplantAnswers'
import InvisalignAnswers from './InvisalignAnswers'

const answersByProduct = {
  invisalign: { component: InvisalignAnswers },
  implant: { component: ImplantAnswers },
}

const ProductAnswers = ({
  data: { productAnswers = {}, product },
  ...rest
}) => {
  const hasAnswers = Object.keys(productAnswers).length
  const { t } = useTranslation()

  const { component: Answers } = answersByProduct[product.type] || {
    component: InvisalignAnswers,
  }

  return (
    <Panel title={t('Qualification information')} {...rest}>
      <View py={3} px={5} bg="white">
        {hasAnswers ? (
          <Answers productAnswers={productAnswers} />
        ) : (
          <Caption textAlign="center">
            {t(
              "The patient hasn't submitted any qualification information yet"
            )}
          </Caption>
        )}
      </View>
    </Panel>
  )
}
ProductAnswers.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProductAnswers
