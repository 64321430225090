import * as Sentry from '@sentry/react'
import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import getFingerprint from '@tabeo/sharpei/utils/getFingerprint'
import { getJWT } from '@tabeo/sharpei/utils/jwt'
import config from 'config'
import refreshAccessToken from 'utils/refreshAccessToken'

const initialState = {
  token: null,
  user: null,
}

const { COUNTRY } = config

class Auth extends Resource {
  constructor(params) {
    const config = {
      refreshInterval: 30 * 1000,
    }
    const token = getJWT('token') || getJWT('refreshToken')
    const data = {
      ...initialState,
      token,
      isCookiePolicyAccepted: !!localStorage.getItem('tabeoCookieBanner'),
    }
    super(params, config, data)

    api.interceptors.response.use(null, async error => {
      const originalRequest = error.config
      if (
        error.response?.status === 401 &&
        !originalRequest.url.startsWith('/refresh') &&
        !originalRequest.url.startsWith('/login')
      ) {
        if (!originalRequest.didRetry) {
          originalRequest.didRetry = true
          await this.refreshAccessToken()
          return api(originalRequest)
        }
      }
      return Promise.reject(error)
    })
  }

  refreshAccessToken = async () => {
    try {
      const { token } = await refreshAccessToken()

      this.data = {
        ...this.data,
        token,
      }
    } catch (error) {
      this.logout({ clientSide: true })
    }
  }

  acceptCookiePolicy = () => {
    localStorage.setItem('tabeoCookieBanner', new Date().getTime())
    this.data = {
      ...this.data,
      isCookiePolicyAccepted: true,
    }
  }

  login = async credentials => {
    const { token, refresh } = await api.post('/login', {
      ...credentials,
      fingerprint: getFingerprint(),
    })
    try {
      const apiconfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const { user } = await api.get(`/user`, apiconfig)
      if (user.role !== 'merchant') {
        throw new Error('invalid_role')
      }
      const { merchant } = await api.get(`/merchant`, apiconfig)

      if (merchant.countryCode !== COUNTRY) {
        throw new Error(`invalid_country:${merchant.countryCode}`)
      }

      window.localStorage.setItem('token', token)
      window.localStorage.setItem('refreshToken', refresh)

      this.data = {
        ...this.data,
        token,
      }

      this.sync()
    } catch (e) {
      if (e?.response?.status === 403) {
        throw new Error('deleted_user')
      }
      throw e
    }
  }

  logout = async ({ redirect = '/login', clientSide } = {}) => {
    if (!clientSide) {
      await api.post('/logout', {
        fingerprint: getFingerprint(),
      })
    }
    Sentry.setUser(null)
    window.heap?.resetIdentity()
    window.localStorage.removeItem('refreshToken')
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('merchantSlug')
    window.dispatchEvent(
      new StorageEvent('storage', { key: 'merchantSlug', newValue: null })
    )
    window.location.href = redirect
  }

  async fetch() {
    const subResources = formatSubresources([
      'user',
      'addressDetails',
      'metadata',
    ])

    if (this.data.token) {
      const { merchantUser } = await api.get(`/merchant-user?${subResources}`)
      this.data = {
        ...this.data,
        user: {
          ...merchantUser.user,
          ...merchantUser,
        },
      }
    }
  }

  updateUser = async payload => {
    await api.put(`/merchant-user`, payload)
    this.fetch()
  }

  patchModalValue = async (modalId, data) => {
    const modals = this.data?.user?.metadata?.modals || {}

    const payload = {
      type: 'modals',
      modals: {
        ...modals,
        [modalId]: {
          ...modals[modalId],
          ...data,
        },
      },
    }
    const response = await api.put(`/merchant-user/metadata`, payload)
    await this.fetch()
    return response
  }

  requestPasswordResetEmail = async payload => {
    await api.post('/public/user/password-token', payload)
  }

  resetPassword = async payload => {
    await api.put('/public/user/password', payload)
  }

  changePassword = async payload => {
    await api.put('/user/password', payload)
  }

  requestEmailVerificationResend = async () => {
    await api.post('/user/email-verification-message', {})
  }

  verifyEmail = async payload => {
    await api.post('/public/email-verification', payload)
    this.fetch()
  }
}

export default Auth
export const useAuth = createResourceHook(Auth)
