import { SimpleModal, Text } from '@tabeo/scarf'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function CoolingOffModal({ innerRef }) {
  const { t } = useTranslation()

  return (
    <SimpleModal
      innerRef={innerRef}
      title={t('Cooling-off period')}
      closeButtonProps={{ width: '100%' }}
    >
      <Trans>
        <Text mb={3}>
          The date you have selected as the &apos;treatment start date&apos;
          falls within the 14-day cooling-off period.
        </Text>
        <Text mb={3}>
          In order to receive treatment within the cooling-off period, the
          patient will need to waive their right to cancel this transaction
          without charge.
        </Text>
        <Text mb={3}>
          Don&apos;t worry, they will be able to do this easily during the
          Finance application.
        </Text>
        <Text>
          As soon as this is done, you&apos;ll be able to provide treatment on
          this date without trouble.
        </Text>
      </Trans>
    </SimpleModal>
  )
}

CoolingOffModal.propTypes = {
  innerRef: PropTypes.any,
}

export default CoolingOffModal
