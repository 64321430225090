import PropTypes from 'prop-types'
import DocumentV1 from './DocumentV1'
import DocumentV2 from './DocumentV2'

function TermsAndConditionDocument(props) {
  const { content, version: strVersion, countryCode } = props

  const version = parseInt(strVersion, 10)
  const Document =
    countryCode === 'gb' && version === 1
      ? DocumentV1
      : version <= 9
      ? DocumentV2
      : // Style is part of the content, so we need to render it as HTML
        'div'

  return (
    <Document
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  )
}

TermsAndConditionDocument.propTypes = {
  content: PropTypes.string,
  version: PropTypes.string,
  countryCode: PropTypes.string,
}

export default TermsAndConditionDocument
