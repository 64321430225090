import { useEffect, useRef, useState } from 'react'

export type IntersectionHookParams = {
  root?: IntersectionObserverInit['root']
  rootMargin?: IntersectionObserverInit['rootMargin']
  threshold?: IntersectionObserverInit['threshold']
  once?: boolean
}

function useIntersection({
  root,
  rootMargin,
  threshold,
  once,
}: IntersectionHookParams = {}): [
  React.MutableRefObject<Element | undefined>,
  boolean
] {
  const ref = useRef<Element>()
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const r = ref.current

    let observer: IntersectionObserver

    if (window.IntersectionObserver) {
      observer = new IntersectionObserver(
        ([entry]) => {
          setIntersecting(entry.isIntersecting)

          if (once && entry.isIntersecting && r) {
            observer.unobserve(r)
          }
        },
        { root, rootMargin, threshold }
      )

      if (r) {
        observer?.observe(r)
      }
    } else {
      setIntersecting(true)
    }

    return () => {
      if (r) {
        observer?.unobserve(r)
      }
    }
  }, [once, root, rootMargin, threshold])

  return [ref, isIntersecting]
}

export default useIntersection
