import { motion } from 'framer-motion'
import { ComponentPropsWithoutRef } from 'react'
import useMeasure from 'react-use-measure'

export default function AnimatedContainer({
  children,
  transition = {
    type: 'spring',
    bounce: 0.2,
    duration: 0.4,
  },
}: ComponentPropsWithoutRef<'div'> & {
  transition?: ComponentPropsWithoutRef<typeof motion.div>['transition']
}) {
  const [ref, bounds] = useMeasure()
  return (
    <motion.div
      animate={{
        height: bounds.height > 0 ? bounds.height : undefined,
      }}
      transition={transition}
    >
      <div ref={ref}>{children}</div>
    </motion.div>
  )
}
