import {
  Flex,
  Heading,
  Prompt,
  Subheading,
  TableCard,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import Card from 'components/Transaction/ActionPanel/Card'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import icon from '../assets/payout_on_the_way.svg'
import InvoiceButton from '../InvoiceButton'
import RefundButton from '../Refund'

const { Card: DataCard, Row } = TableCard

function LoanStarted({ transaction, ...rest }) {
  const { data } = transaction

  const { paymentPlan } = data
  const { t } = useTranslation()

  const { remainingSinglePaymentAmount, numberOfMonths, chosenPaymentDay } =
    paymentPlan

  return (
    <Card {...rest} p={0}>
      <Flex justifyContent="flex-start" py={4} px={5}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Loan started')}</Heading>
      </Flex>
      <View py={3} px={5} bg="white" borderTop={1} borderBottom={1}>
        <Row>
          <DataCard>
            <Subheading color="ink.2" mb={1}>
              {t('Monthly payment')}
            </Subheading>
            <Prompt>
              {t('{{amount}} over {{numberOfMonths}} months', {
                amount: format(remainingSinglePaymentAmount),
                numberOfMonths,
              })}
            </Prompt>
          </DataCard>
          <DataCard>
            <Subheading color="ink.2" mb={1}>
              {t('Payment date')}
            </Subheading>
            <Prompt>
              {t('{{chosenPaymentDay}} of each month', {
                chosenPaymentDay: moment(chosenPaymentDay, 'DD').format('Do'),
              })}
            </Prompt>
          </DataCard>
        </Row>
      </View>
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="flex-start"
        alignItems={['stretch', 'center']}
        py={4}
        px={5}
      >
        <InvoiceButton mr={[0, 4]} mb={[4, 0]} />
        <RefundButton transaction={transaction} />
      </Flex>
    </Card>
  )
}

LoanStarted.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default LoanStarted
