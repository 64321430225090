import React from 'react'
import Svg, { Circle, Path, SvgProps } from './Base'

function Tag({ fill, ...props }: SvgProps) {
  return (
    <Svg {...props}>
      <g fill="none" fillRule="evenodd">
        <Path
          d="M15.45 5.528h-3.304a1 1 0 00-.711.297L5.72 11.607a.997.997 0 00-.007 1.399l3.115 3.212a.997.997 0 001.407-.014l5.924-5.983a1 1 0 00.29-.704v-2.99a.997.997 0 00-1-1z"
          stroke={fill}
          fill="none"
        />
        <Circle fill={fill} cx={13.972} cy={8.007} r={1} stroke="none" />
      </g>
    </Svg>
  )
}

export default Tag
