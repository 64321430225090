import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as XRed } from 'components/checkout-payment/assets/x-red.svg'
import { useTranslation } from 'react-i18next'

function CancellationFailed() {
  const { t } = useTranslation()

  return (
    <>
      <ActionCard data-testid="failed-action-panel">
        <ActionCard.Header>
          <XRed className="h-6 w-6" />
          {t('Cancellation failed')}
        </ActionCard.Header>
        <ActionCard.Body>
          {t('The attempt to cancel this payment failed.')}
        </ActionCard.Body>
      </ActionCard>
    </>
  )
}

export default CancellationFailed
