import { useTranslation } from 'react-i18next'
import PaymentRefund from 'components/checkout-payment/PaymentRefund'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as DoubleLeftArrowDarkGray } from '../assets/double-left-arrow-dark-gray.svg'

function PartiallyRefunded() {
  const { t } = useTranslation()

  return (
    <ActionCard data-testid="pr-action-panel">
      <ActionCard.Header>
        <DoubleLeftArrowDarkGray className="h-6 w-6" />
        {t('Partially refunded')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t(
          'This payment was partially refunded. For more information, please review the Refunds panel.'
        )}
      </ActionCard.Body>
      <ActionCard.Actions>
        <PaymentRefund.RequestButton />
      </ActionCard.Actions>
    </ActionCard>
  )
}

export default PartiallyRefunded
