import { Box } from '@tabeo/scarf'
import Document from 'components/Document'
import LayoutSideNavbar from 'components/layouts/LayoutSideNavbar'
import Subpage from 'components/layouts/Subpage'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useApi } from 'resources/Api'

function SubscriptionTermsAndConditions() {
  const { subscriptionId } = useParams()
  const { data } = useApi({
    endpoint: `/merchant/subscriptions/${subscriptionId}/subscription-agreement?subResource=content`,
  })

  return (
    <LayoutSideNavbar isLoading={!data}>
      {data && (
        <Subpage>
          <Box>
            <Document document={data.document} />
          </Box>
        </Subpage>
      )}
    </LayoutSideNavbar>
  )
}

export default SubscriptionTermsAndConditions
