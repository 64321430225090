import { Button, Flex, Prompt, Title, View } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import FieldError from 'components/Form/FieldError'
import PaymentOptions from 'components/Payment/Options'
import PaymentPending from 'components/Payment/Pending'
import Source from 'components/Payment/SourceSCA'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useOffer } from 'resources/Offer'
import { useRefundFlow } from './RefundFlowContext'

const initialState = {
  error: null,
  isPending: false,
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'PENDING':
      return {
        error: null,
        isPending: true,
      }

    case 'ERROR':
      return {
        error: payload,
        isPending: false,
      }
    default:
      return state
  }
}

function PaymentSource() {
  const { resource } = useOffer()
  const { next, close, transaction, flowState } = useRefundFlow()

  const [{ error, isPending }, dispatch] = React.useReducer(
    reducer,
    initialState
  )
  const {
    id,
    paymentPlan: { principalAmount },
    revisedPrice,
  } = transaction
  const { refundReason, retentionReason, retentionAmount, executionAmount } =
    flowState
  const payload = {
    amount: (revisedPrice || principalAmount) - retentionAmount,
    refundReason,
    retentionReason,
  }

  const { t } = useTranslation()

  function handlePending() {
    dispatch({
      type: 'PENDING',
    })
  }

  function handleError(e) {
    dispatch({
      type: 'ERROR',
      payload: e,
    })
  }

  return isPending ? (
    <PaymentPending data-ga-id="merchant-offer-refund-payment-pending" />
  ) : (
    <View>
      <Title textAlign="center" mb={3}>
        {t('Your refund bill')}
      </Title>
      <Flex p={5} border={1} bg="sky.3" borderRadius={1} mb={6}>
        <Prompt color="ink.2">{t('Refund amount due')}</Prompt>
        <Prompt fontWeight="semibold">{format(executionAmount)}</Prompt>
      </Flex>
      <Prompt mb={6}>
        {t('Please pay with your business’s debit or credit card')}
      </Prompt>
      <PaymentOptions mb={6} />
      <Source
        // We need to reset the form if the SCA saga fails to force
        // a new stripe sca cliet secret fetch from Core
        key={error ? error.message : 'no-error'}
        onSuccess={async () => {
          await resource.fetch()
          next()
        }}
        onStripeResponse={handlePending}
        onSCASagaError={handleError}
        onStripeError={handleError}
        title={t('Refund amount')}
        price={executionAmount}
        stripeMethod="handleCardPayment"
        SCASagaEndpoint={`/merchant/offers/${id}/refund-sca`}
        SCASagaPayload={payload}
        submitButtonLabel={t('Pay Now')}
        additionalWarningMessage={t(
          "Please make sure to use your business' card and not the patient's debit card."
        )}
      />
      {!!error && <FieldError message={error.message} mt={4} />}
      <Button variant="secondary" width="100%" onClick={close} mt={6}>
        {t('Close')}
      </Button>
    </View>
  )
}

export default PaymentSource
