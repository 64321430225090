import React from 'react'
import { useAuth } from 'resources/Auth'
import { useMerchant } from 'resources/Merchant'
import LoggedOut from './LoggedOut'
import OnBoarding from './OnBoarding'

function Navbar() {
  const {
    data: { token },
  } = useAuth()
  const { data: merchant } = useMerchant(() => {
    if (!token) {
      throw Error('waiting for auth')
    }
  })
  return token && merchant ? <OnBoarding /> : <LoggedOut />
}

Navbar.propTypes = {}

export default Navbar
