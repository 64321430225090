import { Flex, Select, Text, TextInput, View } from '@tabeo/scarf'
import { lengthRange, required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import NumberInput from 'components/Form/NumberInput'
import PropTypes from 'prop-types'
import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { useTranslation } from 'react-i18next'
import { dentalFeatures, vetFeatures } from './benefits'

const lengthRange5to100 = lengthRange(5, 100)

class ListItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { availableFeatures = [], currentFeature } = this.props
    if (
      availableFeatures.join(',') === nextProps.availableFeatures.join(',') &&
      nextProps.currentFeature === currentFeature
    ) {
      return false
    }
    return true
  }

  render() {
    const { name, index, availableFeatures, fields, currentFeature, t } =
      this.props
    return (
      <>
        <View>
          <Flex>
            <Field
              name={`${name}.title`}
              label={t(`Treatment {{index}}`, { index: index + 1 })}
              component={Select}
              options={availableFeatures.map(f => ({ label: f }))}
              valueKey="label"
              containerProps={{ flex: 1 }}
            />
            <View>
              <Text
                onClick={() => fields.remove(index)}
                style={{ cursor: 'pointer' }}
                textAlign="right"
                color="ink.2"
                fontSize="12px"
                my={1}
              >
                {t('Remove')}
              </Text>
              <Field
                name={`${name}.claimsLimit`}
                component={NumberInput}
                minValue={0}
                ml={3}
              />
            </View>
          </Flex>
        </View>
        {currentFeature && currentFeature.title === t('Other') && (
          <Field
            name={`${name}.otherTitle`}
            placeholder={t('E.g. Dental X-Rays')}
            component={TextInput}
            validate={[required, lengthRange5to100]}
          />
        )}
      </>
    )
  }
}

ListItem.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
  availableFeatures: PropTypes.array,
  fields: PropTypes.object,
  currentFeature: PropTypes.object,
  t: PropTypes.func,
}

const FeaturesList = ({ sector }) => {
  const { t } = useTranslation()
  const featureList =
    sector === 'dental_services' ? dentalFeatures : vetFeatures
  return !featureList.length ? null : (
    <FieldArray name="planFeatures">
      {({ fields }) =>
        fields.map((name, index) => {
          const currentFeature = fields.value[index]
          const selectedFeatures = fields.value
            .map(f => f.title)
            .filter(title => title !== 'Other')
          const availableFeatures = featureList.filter(
            f => !selectedFeatures.includes(f) || currentFeature.title === f
          )
          const props = {
            name,
            index,
            availableFeatures,
            fields,
            currentFeature,
          }
          return <ListItem key={name} t={t} {...props} />
        })
      }
    </FieldArray>
  )
}

FeaturesList.propTypes = {
  errors: PropTypes.object,
  validFeatures: PropTypes.array,
  features: PropTypes.array,
  sector: PropTypes.string,
}

export default FeaturesList
