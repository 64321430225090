import { DateTime, Modal, NativeSelect, View } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import formError from '@tabeo/sharpei/utils/formError'
import FormError from 'components/Form/FormError'
import MerchantClinicianSelect from 'components/MerchantClinicianSelect'
import { Field } from 'components/nnts/form/Field'
import { FORM_ERROR } from 'final-form'
import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useSubscriptionV2 } from 'resources/SubscriptionV2'
import { zodValidator } from 'utils/zod-validation'
import { z } from 'zod'

export type RegisterClaimModalProps = React.ComponentProps<typeof Modal>

const FormSchema = z.object({
  merchantClinician: z
    .object({
      clinician: z.object({
        id: z.number(),
      }),
    })
    .nullable()
    .optional(),
  benefitId: z.string(),
  claimDate: z.string(),
})

export type FormValues = z.infer<typeof FormSchema>

const RegisterClaimModal = React.forwardRef(
  ({ ...rest }: RegisterClaimModalProps, ref: any) => {
    const { t } = useTranslation()
    const { data, resource } = useSubscriptionV2()

    const options = [
      data?.subscription?.treatments?.map(({ treatment }) => ({
        id: treatment?.id,
        name: treatment?.name,
        type: 'treatment',
      })),
      data?.subscription?.perks?.map(({ perk }) => ({
        id: perk?.id,
        name: perk?.name,
        type: 'perk',
      })),
    ]
      .flat()
      .filter(Boolean) as {
      id: string
      name: string
      type: 'treatment' | 'perk'
    }[]

    return (
      <Modal ref={ref}>
        {({ isOpen, close }) => {
          async function handleSubmit(values: any) {
            let parsedValues: FormValues
            try {
              parsedValues = FormSchema.parse(values)
            } catch (error) {
              return {
                [FORM_ERROR]: 'Invalid form values',
              }
            }

            try {
              await resource?.registerClaim({
                // @ts-ignore
                clinicianId: parsedValues.merchantClinician?.clinician.id,
                claimDate: parsedValues.claimDate,
                treatments: options
                  .filter(
                    o =>
                      o.id === parsedValues.benefitId && o.type === 'treatment'
                  )
                  .map(o => ({ id: o.id, quantity: 1 })),
                perks: options
                  .filter(
                    o => o.id === parsedValues.benefitId && o.type === 'perk'
                  )
                  .map(o => ({ id: o.id, quantity: 1 })),
              })
              close()
            } catch (e) {
              return formError(e)
            }
          }

          return isOpen ? (
            <View
              maxWidth={['90%', '460px']}
              mx="auto"
              bg="white"
              borderRadius={1}
              boxShadow={1}
              p={[5, 10]}
              {...rest}
            >
              <h3 className="title mb-3">{t('Register a claim')}</h3>
              <Form
                onSubmit={handleSubmit}
                initialValues={{}}
                keepDirtyOnReinitialize
                destroyOnUnregister
                validate={zodValidator(FormSchema)}
              >
                {({ handleSubmit, submitting }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="space-y-4">
                      <Field
                        name="benefitId"
                        label={t('Goods/services')}
                        component={NativeSelect}
                        options={options.map(o => ({
                          label: o?.name,
                          value: o?.id,
                        }))}
                        autoBlur
                        maxWidth="100%"
                      />
                      <Field
                        name="merchantClinician"
                        label={t('Clinician')}
                        component={MerchantClinicianSelect}
                        optional
                        maxWidth="100%"
                      />
                      <Field
                        name="claimDate"
                        label={t('Claim date')}
                        component={DateTime}
                        justifyContent="flex-start"
                        alignPopupToTop
                      />
                    </div>
                    <div className="mt-6 flex flex-col-reverse gap-4 desktop:flex-row desktop:justify-end">
                      <Button variant="secondary" type="button" onClick={close}>
                        {t('Cancel')}
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        loading={submitting}
                      >
                        {t('Register claim')}
                      </Button>
                    </div>

                    <FormError />
                  </form>
                )}
              </Form>
            </View>
          ) : null
        }}
      </Modal>
    )
  }
)

export default RegisterClaimModal
