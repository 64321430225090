import {
  ConnectionStatus,
  DisconnectEvent,
  FetchConnectionTokenFn,
  PaymentStatus,
  Terminal,
  loadStripeTerminal,
} from '@stripe/terminal-js'
import api from '@tabeo/sharpei/utils/api'
import { useEffect, useRef, useState } from 'react'
import StripeTerminalContext, { Cache } from './StripeTerminalContext'

const tokenProdiver = async () => {
  const data = await api.get('/merchant/card-readers/token')
  return data?.cardReader?.token
}

function StripeTerminalProvider({
  children,
  tokenProvider = tokenProdiver,
  skipInitialization = false,
}: {
  children: JSX.Element
  tokenProvider?: FetchConnectionTokenFn
  skipInitialization?: boolean
}): JSX.Element {
  const [loading, setLoading] = useState(false)

  const [terminal, setTerminal] = useState<Terminal>()

  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>()
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>()

  const cache = useRef<Cache>({})

  useEffect(() => {
    if (!terminal && !loading && !skipInitialization) {
      setLoading(true)
      loadStripeTerminal().then(st => {
        const t = st?.create({
          onFetchConnectionToken: tokenProvider,
          onUnexpectedReaderDisconnect: (e: DisconnectEvent) => {
            // eslint-disable-next-line no-console
            console.error('onUnexpectedReaderDisconnect', e)
          },
          onConnectionStatusChange: ({ status }) => {
            setConnectionStatus(status)
          },
          onPaymentStatusChange: ({ status }) => {
            setPaymentStatus(status)
          },
        })

        setTerminal(t)
        setLoading(false)
      })
    }
  }, [terminal, loading, tokenProvider, skipInitialization])

  if (skipInitialization) {
    return children
  }

  return (
    <StripeTerminalContext.Provider
      value={{ terminal, paymentStatus, connectionStatus, cache }}
    >
      {children}
    </StripeTerminalContext.Provider>
  )
}

export default StripeTerminalProvider
