import { themeGet } from '@styled-system/theme-get'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import styled from 'styled-components'
import { useMobile } from '../../../hooks/useMatchMedia'
import { Merge } from '../../../types/helpers'
import ScarfProps from '../../../types/ScarfProps'
import Flex from '../../Flex'
import * as Icons from '../../Icons'
import Popover from '../../Popover'
import Text, { Caption } from '../../Text'
import Select, { Item, SelectProps } from '../Select'

const StyledFlex = styled(Flex)`
  :hover {
    background: ${themeGet('colors.sky.3')};
  }
`

StyledFlex.defaultProps = {
  height: '100%',
  flex: '1',
  maxWidth: '100px',
  justifyContent: 'center',
}

export type EntityTypeSelectProps = Merge<
  ScarfProps,
  {
    open?: boolean
    toggle: () => void
    value?: Item
    onChange: SelectProps['onChange']
    options?: Item[]
  }
>

function EntityTypeSelect({
  open,
  toggle,
  value,
  onChange,
  options,
  ...rest
}: EntityTypeSelectProps) {
  const mobile = useMobile()
  const ref = useRef<Element>()

  const width = ref.current?.clientWidth

  return (
    <>
      <Popover
        open={open}
        onClickOut={() => toggle()}
        placement="bottom-start"
        referenceElement={ref}
        offset={[0, 4]}
      >
        {({ ref, style, placement, update }) => (
          <Select
            ref={ref}
            style={mobile ? { zIndex: 1000 } : style}
            data-placement={placement}
            minWidth={[`${width}px`]}
            options={options}
            value={value}
            onHitsUpdate={update}
            onChange={(e) => {
              onChange(e)
              toggle()
            }}
            onClose={() => toggle()}
            renderItem={(item, { selected, hover, ...rest }) => (
              <Flex
                justifyContent="flex-start"
                px={[5, 3]}
                py={[3, 2]}
                bg={hover ? 'default.primary' : undefined}
                color={hover ? 'white' : undefined}
                {...rest}
              >
                <Text
                  color={hover ? 'white' : 'default.ink'}
                  fontSize={[null, '14px']}
                  data-testid="entity-type-item"
                >
                  {item.label}
                </Text>
                <Flex flex="1" />
                {selected && (
                  <Icons.Tick light fill={hover ? 'white' : undefined} ml={2} />
                )}
              </Flex>
            )}
            data-testid="entity-type-select"
          />
        )}
      </Popover>
      <StyledFlex
        ref={ref}
        onClick={() => {
          toggle()
        }}
        cursor="pointer"
        minWidth="100px"
        {...rest}
      >
        <Caption fontSize={['16px', '14px']}>
          {value?.label || 'Entity'}
        </Caption>
      </StyledFlex>
    </>
  )
}

EntityTypeSelect.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  onChange: PropTypes.func,
  options: PropTypes.array,
}

export default EntityTypeSelect
