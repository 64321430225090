// @ts-nocheck

import { themeGet } from '@styled-system/theme-get'
import { TFunction } from 'i18next'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { DayPickerProps } from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils, {
  formatDate as formatDateMoment,
  parseDate,
} from 'react-day-picker/moment'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useLocale } from '../../ScarfProvider'
import { InputProp, MetaProp } from '../../types/form'
import { Merge } from '../../types/helpers'
import { Clear } from '../Icons'
import { Base, BaseProps, HtmlInput } from './Input'

const Picker = styled(Base).attrs((p) => ({
  py: '6px',
  width: p.width ?? ['100%', p.clearable ? 'fit-content' : '160px'],
}))`
  min-width: auto;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .DayPickerInput {
    flex: 1 0 auto;
    display: block;
    width: auto;
    input {
      font-family: ${themeGet('font', 'Arial')};
      font-size: 16px;
      line-height: 1.5;
      background: none;
      border: none;
      width: 100%;
      min-width: auto;
      outline: none;
      font-weight: ${themeGet('fontWeights.regular')};
      &::placeholder {
        color: ${themeGet('colors.ink.2')};
      }
      &:disabled {
        cursor: not-allowed;
        color: ${themeGet('colors.ink.2')};
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
      }
      &:-webkit-contacts-auto-fill-button {
        visibility: hidden;
        display: none !important;
        pointer-events: none;
        position: absolute;
        right: 0;
      }
    }

    .DayPickerInput-OverlayWrapper {
      font-family: ${themeGet('font', 'Arial')};
      font-size: 16px;
      line-height: 1.5;
      ${(p) =>
        p.alignPopupToTop ? 'top: -330px;' : ''} .DayPickerInput-Overlay {
        border-radius: 4px;

        @media (max-width: ${themeGet('breakpoints.0')}) {
          left: 50%;
          transform: translate(-50%, 0);
        }

        .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(
            .DayPicker-Day--selected
          ) {
          color: ${themeGet('colors.primary.3')};
        }

        .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
            .DayPicker-Day--outside
          ) {
          background-color: ${themeGet('colors.primary.3')};
          border-radius: 0;
        }

        .DayPicker:not(.DayPicker--interactionDisabled)
          .DayPicker-Day:not(.DayPicker-Day--disabled):not(
            .DayPicker-Day--selected
          ):not(.DayPicker-Day--outside):hover {
          background-color: ${themeGet('colors.primary.6')};
          border-radius: 0;
        }
      }
    }
  }
`

function formatDate(value, format) {
  if (!value) {
    return ''
  }
  return formatDateMoment(value, format)
}

export type DatePickerProps = Merge<
  BaseProps,
  {
    disabled?: boolean
    placeholder?: string
    input?: Partial<InputProp>
    meta?: Partial<MetaProp>
    datePickerProps?: DayPickerProps
    alignPopupToTop?: boolean
    locale?: string
    t: TFunction
    format?: string
    clearable?: boolean
  }
>

class DatePicker extends Component<DatePickerProps> {
  // https://github.com/gpbl/react-day-picker/issues/600
  monthChanged = false

  state = {
    focus: false,
    value: '',
  }

  componentDidMount() {
    const {
      input: { value },
      format,
    } = this.props
    this.setState({
      value: formatDate(value, format),
    })
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ input: { value: nextValue } }) {
    const {
      input: { value },
      format,
    } = this.props
    if (value !== nextValue) {
      this.setState({
        value: formatDate(nextValue, format),
      })
    }
  }

  handleChange = (value) => {
    const {
      input: { onChange },
    } = this.props
    this.setState(
      {
        value,
      },
      () => {
        const date = new Date(value)
        const day = parseInt(date.getUTCDate(), 10)
        const month = parseInt(date.getUTCMonth(), 10)
        const year = `${date.getUTCFullYear()}`
        const stringifiedValue = new Date(
          Date.UTC(year, month, day)
        ).toISOString()
        onChange && onChange(stringifiedValue)
      }
    )

    return value
  }

  handleFocus = () => {
    const { disabled } = this.props

    if (!disabled) {
      this.picker.input.focus()
      this.setState({
        focus: true,
      })
    }
  }

  handleBlur = () => {
    const {
      input: { onBlur },
    } = this.props
    this.setState({
      focus: false,
    })

    // https://github.com/gpbl/react-day-picker/issues/600
    setTimeout(() => {
      const { value } = this.state
      if (!this.monthChanged || value) {
        onBlur && onBlur()
      }
    }, 200)
  }

  setMonthChangedFlag = () => {
    this.monthChanged = true
  }

  handleClear = () => {
    this.setState({
      value: '',
    })
  }

  render() {
    const {
      t,
      meta,
      input,
      datePickerProps,
      disabled,
      placeholder = t('DD / MM / YYYY'),
      alignPopupToTop,
      locale,
      format,
      clearable,
      ...rest
    } = this.props
    const { focus, value } = this.state

    return (
      <Picker
        {...rest}
        meta={{
          ...meta,
          focus,
        }}
        disabled={disabled}
        tabIndex="-1"
        onFocus={this.handleFocus}
        alignPopupToTop={alignPopupToTop}
        clearable={clearable}
      >
        {disabled ? (
          <HtmlInput disabled placeholder={placeholder} />
        ) : (
          <DayPickerInput
            ref={(picker) => {
              this.picker = picker
            }}
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder={placeholder}
            onDayChange={this.handleChange}
            keepFocus
            onChange={(e) => e.preventDefault()}
            value={value}
            dayPickerProps={{
              onMonthChange: this.setMonthChangedFlag,
              locale,
              localeUtils: MomentLocaleUtils,
              ...datePickerProps,
            }}
            format={format}
            inputProps={{
              onBlur: this.handleBlur,
              onKeyDown: (e) => e.preventDefault(),
            }}
          />
        )}
        {clearable && value && (
          <Clear
            width={20}
            height={20}
            fill="ink.3"
            onClick={disabled ? null : this.handleClear}
            cursor={disabled ? 'not-allowed' : 'pointer'}
          />
        )}
      </Picker>
    )
  }
}

DatePicker.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  datePickerProps: PropTypes.object,
  alignPopupToTop: PropTypes.bool,
  locale: PropTypes.string,
  t: PropTypes.func.isRequired,
  format: PropTypes.string,
}

DatePicker.defaultProps = {
  input: {},
  meta: {},
  format: 'DD / MM / YYYY',
}

export type DatePickerWrapperProps = Omit<DatePickerProps, 'locale' | 't'>

export default (props: DatePickerWrapperProps) => {
  const locale = useLocale()
  const { t } = useTranslation()

  return <DatePicker {...props} locale={locale} t={t} />
}
