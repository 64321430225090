import { View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import Consultation from './Consultation'
import Disqualified from './Disqualified'
import New from './New'
import Qualified from './Qualified'
import Treatment from './Treatment'

function ActionPanel({ resource, ...rest }) {
  const {
    data: { state },
  } = resource
  const props = {
    resource,
  }

  const disqualifyModal = React.useRef()

  function getCurrentPanel() {
    switch (state) {
      case 'new':
        return {
          component: New,
          componentProps: {
            disqualifyModal,
          },
        }
      case 'qualified':
        return {
          component: Qualified,
        }
      case 'disqualified':
        return {
          component: Disqualified,
        }
      case 'consultation':
        return {
          component: Consultation,
        }
      case 'treatment':
        return {
          component: Treatment,
        }

      default:
        return {}
    }
  }

  const { component: Panel, componentProps = {} } = getCurrentPanel()

  return (
    <>
      {Panel ? (
        <View {...rest}>
          <Panel {...props} {...componentProps} />
        </View>
      ) : null}
    </>
  )
}

ActionPanel.propTypes = {
  resource: PropTypes.object.isRequired,
}

export default ActionPanel
