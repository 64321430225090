import { plansorchestrator } from '@tabeo/platform-api-client'
import { Panel, View } from '@tabeo/scarf'
import { useTranslation } from 'react-i18next'
import formatClinicianName from 'utils/formatClinicianName'

export type ClinicianPanelProps = {
  vendorClinician: plansorchestrator.ModelsGetSubscriptionAggregate['vendorClinician']
} & React.ComponentProps<typeof View>

export default function ClinicianPanel({
  vendorClinician,
  ...rest
}: ClinicianPanelProps) {
  const { t } = useTranslation()

  if (!vendorClinician) {
    return null
  }

  const vendorClinicianRemapped = {
    // @ts-ignore
    displayName: vendorClinician.display_name,
    clinician: {
      // @ts-ignore
      firstName: vendorClinician.clinician.first_name,
      // @ts-ignore
      lastName: vendorClinician.clinician.last_name,
    },
  }

  return (
    <Panel
      title={t('Preferred clinician')}
      data-testid="clinician-panel"
      {...rest}
    >
      <div className="p-5">{formatClinicianName(vendorClinicianRemapped)}</div>
    </Panel>
  )
}
