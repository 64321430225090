'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('./tslib.es6-1ef680b2.js');
require('i18next');
var index = require('./i18n/index.js');

var getEnvironment = function () {
    var key = process.env.REACT_APP_API_ENV || process.env.NEXT_PUBLIC_API_ENV || '';
    switch (key) {
        case 'master':
        case 'production':
            return 'production';
        case 'playground':
        case 'demo':
            return 'demo';
        case 'staging':
            return 'staging';
        case 'local':
            return 'local';
        default:
            return 'development';
    }
};
var env = getEnvironment();
var FALLBACK_PCA_KEY = 'UA97-PP22-CG98-HT84';
var getApiUrl = function (environment) {
    switch (environment) {
        case 'production':
            return "https://api.tabeo.co.uk/v1";
        case 'staging':
            return "https://staging.api.tabeo.co.uk/v1";
        case 'demo':
            return "https://demo.api.tabeo.co.uk/v1";
        case 'local':
            return "http://core.tabeo.local/v1";
        default:
            return "https://development.api.tabeo.co.uk/v1";
    }
};
var getStripeKey = function (environment) {
    var STRIPE_KEYS = {
        production: 'pk_live_q0Z92g8ypGCZ9TRp1ikYrJPz',
        staging: 'pk_test_TOSV7Y0lyJd50zQTbVoKkOXK',
        development: 'pk_test_yM1hmY4bq4AOpQatSsDoaAZL',
        demo: 'pk_test_waNNA0J3yUXiBnsRvjf9Cktt',
        local: 'pk_test_yM1hmY4bq4AOpQatSsDoaAZL',
    };
    return STRIPE_KEYS[environment] || STRIPE_KEYS.development;
};
var defaultConfig = {
    ENV: env,
    API_URL: getApiUrl(env),
    PCA_API_KEY: FALLBACK_PCA_KEY,
    STRIPE_KEY: getStripeKey(env),
    LOCALE: 'en-GB',
    COUNTRY: 'gb',
};
var config = defaultConfig;
function configure(newConfig) {
    config = tslib_es6.__assign(tslib_es6.__assign({}, defaultConfig), newConfig);
    index.default.changeLanguage(config.LOCALE);
}
var features = {
    get pcaPhoneValidation() {
        var FF = config.FEATURE_FLAGS;
        return (FF === null || FF === void 0 ? void 0 : FF.pcaPhoneValidation) !== false;
    },
    get pcaEmailValidation() {
        var FF = config.FEATURE_FLAGS;
        return (FF === null || FF === void 0 ? void 0 : FF.pcaEmailValidation) !== false;
    },
    get pcaBankValidation() {
        var FF = config.FEATURE_FLAGS;
        return (FF === null || FF === void 0 ? void 0 : FF.pcaBankValidation) !== false;
    },
    get pcaIbanValidation() {
        var FF = config.FEATURE_FLAGS;
        return (FF === null || FF === void 0 ? void 0 : FF.pcaIbanValidation) !== false;
    },
};
function getConfig() {
    return config;
}

exports.FALLBACK_PCA_KEY = FALLBACK_PCA_KEY;
exports.configure = configure;
exports.features = features;
exports.getConfig = getConfig;
