import { SimpleModal, Text } from '@tabeo/scarf'
import React from 'react'

const LinkedModal = React.forwardRef((props, ref) => {
  return (
    <SimpleModal
      innerRef={ref}
      title="Clinician linked to transaction"
      closeButtonProps={{ width: '100%', variant: 'secondary', mt: 2 }}
      textAlign="center"
      p={[5, 10]}
    >
      <Text mb={6} mt="-4px">
        This clinician is linked to one or more transactions, this suggests that
        they have worked at this practice.
      </Text>
      <Text>
        Please consider using “no longer works here” or unlinking the clinician
        from all transactions.
      </Text>
    </SimpleModal>
  )
})

export default LinkedModal
