import React from 'react'
import Button, { WrapProps } from '../Button'
import Selector from '../Selector'
import { Caption } from '../Text'
import { useDataTable } from './context'

export type SelectionBulkToggleButtonProps = WrapProps

function SelectionBulkToggleButton(props: SelectionBulkToggleButtonProps) {
  const { items, state, dispatch } = useDataTable()

  if (!state.selected?.length) {
    return null
  }

  return (
    <Button
      leftIcon={() => (
        <Selector
          state={
            items?.length === state?.selected?.length ? 'active' : 'semiActive'
          }
          width="16px"
          height="16px"
          ml="7px"
          mr={3}
        />
      )}
      variant="secondary"
      sizeVariant="sm"
      onClick={() => {
        dispatch({
          type: 'selectedChange',
          payload: [],
        })
      }}
      {...props}
    >
      <Caption color="ink.1" fontWeight="semibold">
        {state.selected?.length} Selected
      </Caption>
    </Button>
  )
}

export default SelectionBulkToggleButton
