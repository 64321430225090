export const TREATMENT_SECTORS = {
  dental_services: 'Dental services',
  veterinary_services: 'Veterinary services',
  optical_care: 'Optical care',
  other_health_care: 'Other health care',
  cosmetic_surgery: 'Cosmetic surgery',
  private_hospital: 'Private hospital',
  physiotherapy: 'Physiotherapy',
  skin_laser_facial_treatments: 'Skin, laser, facial treatments',
  medical_training: 'Medical training',
  hair_transplant: 'Hair transplant',
  hearing_aids: 'Hearing aids',
  specialist_retail: 'Specialist retail',
}

export function getCategoriesBySector(sector) {
  const values = {
    dental_services: [
      'NHS',
      'General',
      'Tooth Replacement',
      'Teeth Straightening',
      'Cosmetic',
    ],
    optical_care: [
      'General',
      'Vision Correction',
      'Diagnostics',
      'Eye Conditions',
    ],
    cosmetic_surgery: [],
    private_hospital: [],
    veterinary_services: [
      'General',
      'Diagnostics',
      'Goods',
      'Specialist',
      'Fertility',
    ],
    physiotherapy: [],
    skin_laser_facial_treatments: [],
    medical_training: [],
    specialist_retail: [],
    hair_transplant: ['Surgical', 'Non-surgical', 'Consultation'],
    hearing_aids: ['General', 'Diagnostics', 'Device'],
    other_health_care: [],
  }

  return [...(values[sector] || [])]
}
