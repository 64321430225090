import { Attributes, Box, Caption, Flex, Prompt, Tag, View } from '@tabeo/scarf'
import Avatar from 'components/Avatar'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Person = ({ data, ...rest }) => {
  const { isForSomeoneElse, beneficiary, consumer, consumerEmail } = data
  const { name } = beneficiary
  const { t } = useTranslation()

  const relationships = {
    child_parent: t('child/parent'),
    partner_spouse: t('partner/spouse'),
  }

  const insurances = {
    paid_by_me: t('Has medial insurance'),
    provided_by_employer: t('Has medical insurance, paid by employer'),
    no: t('No medical insurance'),
  }
  const relationship =
    relationships?.[beneficiary.relationship] || beneficiary.relationship

  return (
    <Box p={0} boxShadow={1} data-testid="patient-panel" {...rest}>
      <View p={5} borderBottom={1}>
        <Flex>
          <Avatar name={name} mr={3} />
          <View flex="1">
            <Prompt fontWeight="semibold">{name}</Prompt>
            <Caption color="ink.0">{t('Customer')}</Caption>
          </View>
        </Flex>
        <Attributes
          mt={5}
          sections={[
            [
              {
                label: t('Date of birth'),
                value: beneficiary.birthdate,
                formattedValue: t(`{{birthDate}} ({{age}} old)`, {
                  birthDate: moment(beneficiary.birthdate).format(
                    'DD MMM YYYY'
                  ),
                  age: moment(beneficiary.birthdate).toNow(true),
                }),
              },
              {
                label: t('Insurance'),
                value: !isForSomeoneElse && beneficiary.medicalInsuranceStatus,
                formattedValue:
                  insurances?.[beneficiary.medicalInsuranceStatus] ||
                  beneficiary.medicalInsuranceStatus,
              },
            ],
          ]}
        />
      </View>

      {isForSomeoneElse && (
        <View p={5} borderBottom={1}>
          <Caption mb={3}>
            {t('This subscription is paid by the patient’s {{relationship}}', {
              relationship,
            })}
          </Caption>
          <Attributes
            sections={[
              [
                {
                  label: t('Name'),
                  value: `${consumer.firstName} ${consumer.lastName}`,
                },
                {
                  label: t('Date of birth'),
                  value: consumer.birthdate,
                  formattedValue: moment(consumer.birthdate).format(
                    'DD MMM YYYY'
                  ),
                },
                {
                  label: t('Relationship with patient'),
                  value: relationship,
                },
                {
                  label: t('Insurance'),
                  value: beneficiary.medicalInsuranceStatus,
                  formattedValue:
                    insurances?.[beneficiary.medicalInsuranceStatus] ||
                    beneficiary.medicalInsuranceStatus,
                },
              ],
            ]}
          />
        </View>
      )}

      <Attributes
        p={5}
        sections={[
          [
            {
              label: (
                <Flex justifyContent="flex-start">
                  {t('Email address')}{' '}
                  {consumer?.user?.status === 'activated' && (
                    <Tag small color="green" ml={2}>
                      {t('Verified')}
                    </Tag>
                  )}
                </Flex>
              ),
              value: consumerEmail,
            },
            { label: t('Phone number'), value: consumer?.mobilePhone },
            {
              label: t('Home address'),
              value: consumer?.addressDetails?.Label,
            },
          ],
        ]}
      />
    </Box>
  )
}

Person.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Person
