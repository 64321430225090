import { useEffect } from 'react'
import { useTheme } from 'styled-components'

function TailwindColorConfig() {
  const theme = useTheme()

  const primaryColors = theme.colors.primary

  useEffect(() => {
    const root = document.documentElement

    Object.entries(primaryColors).forEach(([key, value]) => {
      root.style.setProperty(`--tabeo-primary-${key}`, value)
    })

    root.style.setProperty(`--tabeo-primary`, primaryColors[3])
  }, [primaryColors])

  return null
}

export default TailwindColorConfig
