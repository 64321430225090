import {
  AnimateHeight,
  Button,
  Caption,
  Clickable,
  Flex,
  Icons,
  Modal,
  PhoneInput,
  Prompt,
  Select,
  TextInput,
  Title,
  View,
} from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import { email, emailPCA, phonePCA } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import RecordNotFound from 'components/RecordNotFound'
import useFetcher from 'hooks/useFetcher'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { useClinicians } from 'resources/Clinicians'
import { useMerchant } from 'resources/Merchant'
import formatClinicianName from 'utils/formatClinicianName'
import ClinicianOption from './ClinicianOption'
import ClinicianRecord from './ClinicianRecord'

const AddOrEditClinicianModal = React.forwardRef(
  ({ onSubmit, ...rest }, ref) => {
    const { t } = useTranslation()

    const [notFound, setNotFound] = useState()
    const cached = useRef()

    const {
      data: { merchantClinicians },
    } = useMerchant()

    const fetchClinicians = useFetcher(useClinicians, {
      mapItemToOption: i => {
        return {
          value: i,
          label: formatClinicianName(i),
        }
      },
      filterItems: i => {
        return !merchantClinicians.map(mt => mt.clinician.id).includes(i.id)
      },
    })

    return (
      <Modal ref={ref} {...rest}>
        {({ isOpen, close, data }) => {
          async function handleSubmit({
            clinician,
            displayName,
            email,
            phone,
          }) {
            try {
              const payload = {
                clinicianId: clinician?.id,
                displayName,
                email,
                phone,
              }
              await onSubmit(payload, data?.id)
              close()
            } catch (e) {
              return formError(e)
            }
          }

          return isOpen ? (
            <View
              maxWidth={['90%', '460px']}
              mx="auto"
              bg="white"
              borderRadius={1}
              boxShadow={1}
              p={[5, 10]}
            >
              <Title mb={3}>
                {data ? t('Edit clinician') : t('Add clinician')}
              </Title>
              <View as={Form} onSubmit={handleSubmit} initialValues={data}>
                {({ handleSubmit, submitting, values: { clinician } }) => {
                  const c = clinician || cached.current
                  cached.current = clinician

                  return (
                    <form onSubmit={handleSubmit}>
                      {!data && (
                        <Field
                          name="clinician"
                          label={t('Search by name or registration number')}
                          component={Select}
                          loadOptions={async q => {
                            if (q.length < 3) {
                              return { options: undefined }
                            }

                            const options = await fetchClinicians(q)

                            const mappedOptions = options.map(o => ({
                              ...o,
                              disabled: o.value.status === 'suspended',
                            }))

                            return { options: mappedOptions }
                          }}
                          optionComponent={ClinicianOption}
                          valueRenderer={v => formatClinicianName(v)}
                          placeholder={t('Start typing to search...')}
                          maxWidth="100%"
                          autoload
                          onOptionsLoaded={options => {
                            if (!options.length) {
                              setNotFound(true)
                            } else {
                              setNotFound(false)
                            }
                          }}
                          clearable
                          clearRenderer={() => (
                            <Icons.X
                              mr={1}
                              height="20px"
                              width="20px"
                              fill="ink.2"
                            />
                          )}
                          containerProps={{ 'ug-id': 'clinician-field' }}
                          autoFocus
                        />
                      )}
                      <AnimateHeight
                        duration={200}
                        height={clinician?.id ? 'auto' : 0}
                      >
                        <ClinicianRecord data={c} mb={2} />
                        <Caption mb={5}>
                          Found an issue with these details?{' '}
                          <Clickable
                            fontWeight="medium"
                            display="inline"
                            as="a"
                            href="mailto:merchant@tabeo.co.uk"
                            target="_blank"
                          >
                            Drop us an email
                          </Clickable>
                        </Caption>

                        <Field
                          name="displayName"
                          label={
                            <Prompt mb={1} fontWeight="semibold">
                              <Trans>
                                Preferred name{' '}
                                <Prompt display="inline" color="ink.2">
                                  (optional)
                                </Prompt>
                              </Trans>
                            </Prompt>
                          }
                          subLabel={t(
                            'This will be used for patient communication'
                          )}
                          component={TextInput}
                          maxWidth="100%"
                          placeholder={t('E.g. {{title}} {{lastName}}', {
                            title: c?.title || '',
                            lastName: c?.lastName.split(' ')[0] || '',
                          })}
                        />

                        <Flex
                          flexDirection={['column', 'row']}
                          alignItems="stretch"
                          mb={6}
                        >
                          <Field
                            component={TextInput}
                            name="email"
                            type="email"
                            label={
                              <Trans>
                                <Prompt mb={1} fontWeight="semibold">
                                  Email address{' '}
                                  <Prompt display="inline" color="ink.2">
                                    (optional)
                                  </Prompt>
                                </Prompt>
                              </Trans>
                            }
                            placeholder={t('e.g. user@example.co.uk')}
                            validate={v => {
                              if (v) {
                                return email(v) || emailPCA(v)
                              }
                            }}
                            containerProps={{
                              mr: [0, 5],
                              mb: [5, 0],
                              flex: 1,
                            }}
                          />

                          <Field
                            component={PhoneInput}
                            name="phone"
                            label={
                              <Trans>
                                <Prompt mb={1} fontWeight="semibold">
                                  Phone number{' '}
                                  <Prompt display="inline" color="ink.2">
                                    (optional)
                                  </Prompt>
                                </Prompt>
                              </Trans>
                            }
                            type="tel"
                            validate={v => {
                              if (v) {
                                return phonePCA(v)
                              }
                            }}
                            placeholder={t('e.g. 0777-1234567')}
                            maxWidth="100%"
                            containerProps={{ mb: 0, flex: 1 }}
                          />
                        </Flex>
                      </AnimateHeight>
                      <AnimateHeight
                        duration={200}
                        height={!clinician?.id && notFound ? 'auto' : 0}
                      >
                        <RecordNotFound
                          title={t('No record found')}
                          subtitle={t(
                            'Seems like this clinician hasn’t worked with us before. Email us to request for this clinician to be added to our list'
                          )}
                          buttonText={t('Request new clinician')}
                          buttonProps={{
                            as: 'a',
                            href: 'mailto:merchant@tabeo.co.uk',
                            target: '_blank',
                            leftIcon: p => <Icons.Mail {...p} />,
                          }}
                        />
                      </AnimateHeight>

                      <View mt={1}>
                        <Button
                          loading={submitting}
                          width="100%"
                          mb={4}
                          disabled={!clinician?.id}
                        >
                          {data ? t('Confirm changes') : t('Add to team')}
                        </Button>
                        <Button
                          variant="secondary"
                          type="button"
                          onClick={close}
                          width="100%"
                        >
                          {t('Cancel')}
                        </Button>
                      </View>
                      <FormError />
                    </form>
                  )
                }}
              </View>
            </View>
          ) : null
        }}
      </Modal>
    )
  }
)

AddOrEditClinicianModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default AddOrEditClinicianModal
