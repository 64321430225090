import { StateFunnel } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

function Progressbar({ data, ...rest }) {
  const { t } = useTranslation()
  if (['active', 'failed', 'inactive', 'canceled'].includes(data.state)) {
    return null
  }

  const currentIndex = data.state === 'sent' ? 0 : 1

  return (
    <StateFunnel
      states={[t('Sent'), t('Signing up'), t('Start')]}
      pastStepProps={{ bg: 'yellow.4', color: 'yellow.2' }}
      activeStepProps={{ bg: 'yellow.3', color: 'yellow.1' }}
      futureStepProps={{ bg: 'white', color: 'ink.2' }}
      currentIndex={currentIndex}
      {...rest}
    />
  )
}

Progressbar.propTypes = {
  data: PropTypes.object,
}

export default Progressbar
