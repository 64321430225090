import React from 'react'
import Svg, { Circle, Path, SvgProps } from './Base'

function QuestionMarkOutline({ fill, ...rest }: SvgProps) {
  return (
    <Svg viewBox="0 0 25 24" {...rest}>
      <g transform="translate(.5)" fill="none" fillRule="evenodd">
        <Circle cx={12} cy={12} r={8} stroke={fill} fill="none" />
        <Path
          fill={fill}
          stroke="none"
          d="M12.663 13.883c.008-.61.152-1.073.431-1.39l.781-.768c.643-.669.965-1.348.965-2.038 0-.774-.238-1.377-.714-1.809-.476-.431-1.14-.647-1.99-.647-.825 0-1.489.218-1.99.654-.502.436-.757 1.028-.765 1.777h1.542c0-.351.111-.63.334-.835.222-.205.515-.308.879-.308.38 0 .67.105.866.315.197.21.295.513.295.91 0 .301-.084.58-.254.838-.11.17-.37.454-.783.854-.413.4-.69.767-.829 1.101-.14.335-.21.783-.21 1.346h1.442zm-.68 2.793c.28 0 .496-.078.648-.235a.802.802 0 00.228-.584c0-.24-.078-.44-.234-.6-.157-.158-.37-.238-.642-.238-.266 0-.478.078-.634.232a.814.814 0 00-.235.606.81.81 0 00.225.584c.15.157.365.235.644.235z"
        />
      </g>
    </Svg>
  )
}

export default QuestionMarkOutline
