import {
  Button,
  DatePicker,
  Flex,
  Modal,
  Prompt,
  TextInput,
  Title,
  View,
} from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import { required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import TreatmentStartDateInfo from 'components/TreatmentStartDateInfo'
import { t } from 'i18n'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import { useMerchant } from 'resources/Merchant'
import MerchantClinicianSelect from './MerchantClinicianSelect'

const TreatmentModal = React.forwardRef(
  ({ onSubmit, offer, isConfirm, ...rest }, ref) => {
    const {
      fulfillment,
      individual,
      merchantClinician,
      confirmedDate: loanStartedAt,
      coolingOffPeriodEnds,
      merchantNetProceedsTransfers,
      metadata: { patientName },
    } = offer
    const {
      data: { flags },
    } = useMerchant()

    const {
      is_direct_to_consumer: isDirectToConsumer,
      is_fulfillment_required: isFulfillmentRequired,
      is_cooling_off_period_capable: isCoolingOffPeriodCapable,
      is_clinicians_treatments_enabled: isCliniciansTreatmentsEnabled,
    } = flags

    const hasCoolingOffPeriod =
      isCoolingOffPeriodCapable &&
      !!coolingOffPeriodEnds &&
      coolingOffPeriodEnds > new Date()

    const isPaidOut = !!merchantNetProceedsTransfers.length

    const getDatePicketSubLabel = () => {
      let datePickerSubLabel = ''

      if (!isPaidOut) {
        if (isFulfillmentRequired) {
          if (!loanStartedAt) {
            if (isCoolingOffPeriodCapable) {
              datePickerSubLabel += t(
                'We will issue your payout on this date provided the loan will be confirmed and this date won’t fall within the cooling-off period.'
              )
            } else {
              datePickerSubLabel += t(
                'We will issue your payout on this date provided the loan will be confirmed.'
              )
            }
          } else {
            datePickerSubLabel += t('We will issue your payout on this date.')

            if (hasCoolingOffPeriod) {
              datePickerSubLabel += ` ${t(
                'This date cannot fall within the cooling-off period, which will end on {{date}}.',
                {
                  date: moment(coolingOffPeriodEnds).format('DD MMM YYYY'),
                }
              )}`
            }
          }
        }
      } else if (hasCoolingOffPeriod) {
        datePickerSubLabel += t(
          'The cooling-off period will end on {{date}}. You cannot provide treatment before then unless the patient waives the right to withdraw from the treatment.',
          {
            date: moment(coolingOffPeriodEnds).format('DD MMM YYYY'),
          }
        )
      }

      return datePickerSubLabel
    }

    const getDatePickerDescription = ({ fulfillment }) => {
      let datePickerDescription = ''

      if (!isPaidOut) {
        if (isFulfillmentRequired) {
          if (loanStartedAt) {
            if (fulfillment) {
              if (moment().isAfter(fulfillment.startedAt)) {
                datePickerDescription += t(
                  'Your payout will be issued immediately and will arrive in 1-3 business days.'
                )
              } else {
                datePickerDescription += t(
                  'Your payout will be issued on {{date}} and will arrive in 1-3 business days.',
                  {
                    date: moment(fulfillment.startedAt).format('DD MMM YYYY'),
                  }
                )
              }
            }
          }
        }
      }

      return datePickerDescription
    }

    return (
      <Modal ref={ref}>
        {({ isOpen, close }) => {
          async function handleSubmit({
            fulfillment,
            individual,
            merchantClinician,
            patientName,
          }) {
            try {
              await onSubmit({
                fulfillment,
                individual,
                merchantClinician,
                patientName,
              })
              close()
            } catch (e) {
              return formError(e)
            }
          }

          return isOpen ? (
            <View
              maxWidth={['90%', '460px']}
              mx="auto"
              bg="white"
              borderRadius={1}
              boxShadow={1}
              p={[5, 10]}
            >
              <Title mb={3}>
                {isConfirm
                  ? t('Confirm treatment details')
                  : t('Update treatment details')}
              </Title>
              <View
                as={Form}
                onSubmit={handleSubmit}
                initialValues={{
                  fulfillment,
                  individual,
                  merchantClinician,
                  patientName,
                }}
                keepDirtyOnReinitialize
                {...rest}
              >
                {({ handleSubmit, submitting, values }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Field
                        name="fulfillment.startedAt"
                        label={
                          <Flex justifyContent="flex-start" mb={1}>
                            <Prompt mr={3} fontWeight="semibold">
                              {t('Treatment start date')}
                            </Prompt>
                            <TreatmentStartDateInfo />
                          </Flex>
                        }
                        subLabel={getDatePicketSubLabel()}
                        component={DatePicker}
                        description={getDatePickerDescription(values)}
                        validate={required}
                        datePickerProps={{
                          modifiers: {
                            disabled: {
                              before: hasCoolingOffPeriod
                                ? new Date(coolingOffPeriodEnds)
                                : undefined,
                            },
                            today: hasCoolingOffPeriod
                              ? new Date(coolingOffPeriodEnds)
                              : new Date(),
                          },
                          initialMonth: hasCoolingOffPeriod
                            ? new Date(coolingOffPeriodEnds)
                            : undefined,
                        }}
                        maxWidth="100%"
                      />

                      {!isCliniciansTreatmentsEnabled || individual?.name ? (
                        <Field
                          name="individual.name"
                          label={
                            isDirectToConsumer
                              ? t('Clinician name (optional)')
                              : t('Clinician name')
                          }
                          component={TextInput}
                          placeholder="E.g. John Smith"
                          validate={!isDirectToConsumer && required}
                          maxWidth="100%"
                        />
                      ) : (
                        <Field
                          name="merchantClinician"
                          label={
                            isDirectToConsumer
                              ? t('Clinician name (optional)')
                              : t('Clinician name')
                          }
                          component={MerchantClinicianSelect}
                          validate={!isDirectToConsumer && required}
                          maxWidth="100%"
                        />
                      )}

                      <Field
                        name="fulfillment.reference"
                        label={
                          isDirectToConsumer
                            ? t('Fulfilment reference')
                            : t('Fulfilment reference (optional)')
                        }
                        subLabel={
                          isDirectToConsumer
                            ? t('This is the unique shipment tracking number.')
                            : t(
                                'This is a unique reference that identifies this treamtent. It could be a product serial number or an aligner patient ID.'
                              )
                        }
                        validate={isDirectToConsumer && required}
                        component={TextInput}
                        maxWidth="100%"
                      />

                      <View mt={6}>
                        <Button loading={submitting} mb={4} width="100%">
                          {isConfirm
                            ? t('Confirm treatment')
                            : t('Update treatment')}
                        </Button>
                        <Button
                          variant="secondary"
                          type="button"
                          onClick={close}
                          width="100%"
                        >
                          {t('Cancel')}
                        </Button>
                      </View>

                      <FormError />
                    </form>
                  )
                }}
              </View>
            </View>
          ) : null
        }}
      </Modal>
    )
  }
)

TreatmentModal.propTypes = {
  offer: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isConfirm: PropTypes.bool,
}

export default TreatmentModal
