import { Button, TextInput } from '@tabeo/scarf'
import { email, required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

const RequestEmailForm = ({ onSubmit }) => {
  const { t } = useTranslation()

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            component={TextInput}
            label={t('Email Address')}
            type="email"
            name="email"
            validate={[required, email]}
            disabled={submitting}
          />

          <Button type="submit" loading={submitting} disabled={submitting}>
            {t('Send Link')}
          </Button>

          <FormError />
        </form>
      )}
    </Form>
  )
}

RequestEmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default RequestEmailForm
