import styled from 'styled-components'
import { Caption } from '../../Text'

const Title = styled(Caption)`
  b {
    font-weight: 500;
  }
  i {
    font-style: italic;
  }
`
Title.defaultProps = {
  color: 'ink.0',
}

export default Title
