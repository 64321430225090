import React, { useContext } from 'react'
import Button, { WrapProps } from '../../../Button'
import TicketContext from '../TicketContext'

export type ActionIconButtonProps = WrapProps

const ActionIconButton = React.forwardRef<any, ActionIconButtonProps>(
  // eslint-disable-next-line react/prop-types
  ({ children, ...rest }, ref) => {
    const { color } = useContext(TicketContext)

    return (
      <Button
        ref={ref}
        sizeVariant="xs"
        variant="secondary"
        px={['5px', 1]}
        height={['38px', '32px']}
        color={color}
        {...rest}
      >
        {children}
      </Button>
    )
  }
)

export default ActionIconButton
