import { Tag as BaseTag } from '@tabeo/scarf'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const states = {
  sent: {
    color: 'yellow',
    label: t('Sent'),
  },
  opened: {
    color: 'yellow',
    label: t('Opened'),
  },
  applying: {
    color: 'yellow',
    label: t('Applying for loan'),
  },
  approved: {
    color: 'green',
    label: t('Loan approved'),
  },
  ready_to_confirm: {
    color: 'green',
    label: t('Ready to confirm'),
  },
  card_declined: {
    color: 'red',
    label: t('Card declined'),
  },
  contact_support: {
    color: 'grey',
    label: t('Contact support'),
  },
  funds_on_the_way: {
    color: 'grey',
    label: t('Payout on the way'),
  },
  settled: {
    color: 'grey',
    label: t('Settled'),
  },
  canceled: {
    color: 'grey',
    label: t('Cancelled'),
  },
  application_failed: {
    color: 'red',
    label: t('Unsuccessful'),
  },
  loan_started: {
    color: 'grey',
    label: t('Loan started'),
  },
  manual_review_required: {
    color: 'yellow',
    label: t('Manual review'),
  },
  fulfillment_required: {
    color: 'blue',
    label: t('Confirm treatment'),
  },
  payout_scheduled: {
    color: 'grey',
    label: t('Payout scheduled'),
  },
  payout_canceled: {
    color: 'grey',
    label: t('Payout cancelled'),
  },
}
const Tag = styled(BaseTag)`
  display: inline-flex !important;
`

const TransactionStateTag = ({ state, ...rest }) => {
  const currentState = states[state] || {}
  const color = currentState.color || 'grey'
  const label = currentState.label || state
  return (
    <Tag color={color} {...rest}>
      {label}
    </Tag>
  )
}

TransactionStateTag.propTypes = {
  state: PropTypes.string.isRequired,
}

export default TransactionStateTag
