import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'
import { Button, View } from '@tabeo/scarf'

import ShareModal from './ShareModal'

class SharePlansButton extends Component {
  render() {
    const { plans, merchant, t, tReady, ...rest } = this.props
    const publicPlans = plans.filter(p => p.accessibility === 'public')
    if (!publicPlans.length) {
      return null
    }

    return (
      <View display="inline-block">
        <ShareModal
          innerRef={shareModal => {
            this.shareModal = shareModal
          }}
          publicPlans={publicPlans}
          merchant={merchant}
        />
        <Button
          variant="secondary"
          onClick={() => this.shareModal.open()}
          {...rest}
        >
          {t('Share plans')}
        </Button>
      </View>
    )
  }
}

SharePlansButton.propTypes = {
  plans: PropTypes.array,
  merchant: PropTypes.object,
  t: PropTypes.func,
  tReady: PropTypes.bool,
}

export default withTranslation()(SharePlansButton)
