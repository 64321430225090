import { StateFunnel } from '@tabeo/scarf'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import React from 'react'

const funnel = ['new', 'qualified', 'consultation', 'treatment']
const labels = [
  t('New enquiry'),
  t('Qualified'),
  t('Consultation'),
  t('Treatment'),
]

function Funnel({ state, ...rest }) {
  const currentIndex = funnel.indexOf(state)
  return !['won', 'lost', 'disqualified'].includes(state) ? (
    <StateFunnel
      pastStepProps={{ bg: 'yellow.4', color: 'yellow.1' }}
      activeStepProps={{ bg: 'yellow.3', color: 'yellow.0' }}
      futureStepProps={{ bg: 'white', color: 'ink.2' }}
      states={labels}
      currentIndex={currentIndex}
      {...rest}
    />
  ) : null
}

Funnel.propTypes = {
  state: PropTypes.string.isRequired,
}

export default Funnel
