import * as Sentry from '@sentry/react'
import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import {
  Fulfillment,
  Individual,
  MerchantClinician,
  OfferMetadata,
} from '@tabeo/ts-types'
import { useRouteMatch } from 'react-router-dom'
import { NormalizedOffer } from 'types/NormalizedOffer'
import normalize from './normalizers/offer'

export const subResources = formatSubresources([
  'consumer',
  'consumer.addressDetails',
  'stateMachineTransitions',
  'metadata',
  'flags',
  'metadata',
  'merchant',
  'paymentPlans.payments',
  'paymentPlans.lender',
  'consumerUserStatus',
  'legacyOffer',
  'transferredOffer',
  'paymentSource',
  'bankAccount',
  'declinedReasons',
  'manualReviewRequirements',
  'individual',
  'fulfillment',
  'merchantTreatment.treatment',
  'merchantClinician.clinician',
  'loan',
  'shortLinks',
  'payouts',
  'basket',
])

interface Params {
  id: string | number
}

class Offer extends Resource<NormalizedOffer, Params> {
  async fetch() {
    const transfersConditions = {
      type: 'merchant_proceed',
      owner_type: 'offers',
      owner_id: this.params.id,
      status: 'succeeded',
    }

    const pendingTransfersCondition = {
      owner_type: 'offers',
      owner_id: this.params.id,
      status: 'waiting_feedback',
    }

    const [{ offer }, { transfers }, { transfers: pendingTransfers }] =
      await Promise.all([
        api.get(`/merchant/offers/${this.params.id}?${subResources}`),
        api.get(
          `/merchant/transfers?jsonConditions=${encodeURIComponent(
            JSON.stringify(transfersConditions)
          )}&subResource=payout`
        ),
        api.get(
          `/merchant/transfers?jsonConditions=${encodeURIComponent(
            JSON.stringify(pendingTransfersCondition)
          )}&subResource=payout`
        ),
      ])

    Sentry.setTag('offer.id', offer.id)
    Sentry.setTag('offer.title', offer.title)

    this.data = {
      ...this.data,
      ...normalize(offer),
      transfers,
      pendingTransfers,
    }
  }

  sendReminder = async () =>
    api.post(`/merchant/offers/${this.params.id}/reminder`, {})

  confirm = async () => {
    const payOverTimeEndpoint = `/merchant/offers/${this.params.id}/capture-first-payment`
    const payNowEndpoint = `/merchant/offers/${this.params.id}/pay-now-confirmation`

    const response = await api.post(
      this.data?.type === 'pay_over_time'
        ? payOverTimeEndpoint
        : payNowEndpoint,
      {}
    )

    await this.fetch()
    return response
  }

  createOrUpdateTreatment = async ({
    fulfillment,
    individual,
    merchantClinician,
  }: {
    fulfillment: Partial<Fulfillment>
    individual: Partial<Individual>
    merchantClinician: Partial<MerchantClinician>
  }) => {
    const promises = [
      this.createOrUpdateFulfillment(fulfillment, { fetch: false }),
    ]
    if (individual?.name) {
      promises.push(this.createOrUpdateIndividual(individual, { fetch: false }))
    } else {
      promises.push(
        this.createOrUpdateClinician(merchantClinician, { fetch: false })
      )
    }

    await Promise.all(promises)

    this.fetch()
  }

  createOrUpdateFulfillment = (
    ...args: [Partial<Fulfillment>, { fetch: boolean }]
  ) =>
    this.data?.fulfillment
      ? this.updateFulfillment(...args)
      : this.createFulfillment(...args)

  createFulfillment = async (
    payload: Partial<Fulfillment>,
    { fetch } = { fetch: true }
  ) => {
    const response = await api.post(
      `/merchant/offers/${this.params.id}/fulfillment`,
      payload
    )

    if (fetch) {
      await this.fetch()
    }
    return response
  }

  updateFulfillment = async (
    payload: Partial<Fulfillment>,
    { fetch } = { fetch: true }
  ) => {
    const response = await api.put(
      `/merchant/offers/${this.params.id}/fulfillment`,
      payload
    )

    if (fetch) {
      await this.fetch()
    }
    return response
  }

  createOrUpdateClinician = (
    ...args: [Partial<MerchantClinician>, { fetch: boolean }]
  ) =>
    this.data?.merchantClinician
      ? this.updateClinician(...args)
      : this.createClinician(...args)

  createClinician = async (
    payload: Partial<MerchantClinician>,
    { fetch } = { fetch: true }
  ) => {
    const response = await api.post(
      `/merchant/offers/${this.params.id}/clinician`,
      { merchantClinicianID: payload?.id }
    )

    if (fetch) {
      await this.fetch()
    }
    return response
  }

  updateClinician = async (
    payload: Partial<MerchantClinician>,
    { fetch } = { fetch: true }
  ) => {
    const response = await api.put(
      `/merchant/offers/${this.params.id}/clinician`,
      { merchantClinicianID: payload?.id }
    )

    if (fetch) {
      await this.fetch()
    }
    return response
  }

  createOrUpdateIndividual = (
    ...args: [Partial<Individual>, { fetch: boolean }]
  ) =>
    this.data?.individual
      ? this.updateIndividual(...args)
      : this.createIndividual(...args)

  createIndividual = async (
    payload: Partial<Individual>,
    { fetch } = { fetch: true }
  ) => {
    const response = await api.post(
      `/merchant/offers/${this.params.id}/individual`,
      payload
    )

    if (fetch) {
      await this.fetch()
    }
    return response
  }

  updateIndividual = async (
    payload: Partial<Individual>,
    { fetch } = { fetch: true }
  ) => {
    const response = await api.put(
      `/merchant/offers/${this.params.id}/individual`,
      payload
    )

    if (fetch) {
      await this.fetch()
    }
    return response
  }

  cancel = async () => {
    const response = await api.post(
      `/merchant/offers/${this.params.id}/cancellation`,
      {}
    )
    await this.fetch()
    return response
  }

  updateMetadata = async <T extends keyof OfferMetadata>(
    type: T,
    payload: OfferMetadata[T]
  ) => {
    const response = await api.put(
      `/merchant/offers/${this.params.id}/metadata`,
      {
        type,
        [type]: payload,
      }
    )

    await this.fetch()
    return response
  }

  refundPreProceeds = async (payload: {
    refundReason: 'mind_change' | 'lower_cost' | 'dissatisfaction'
    retentionReason: string
    amount: number
  }) => {
    const response = await api.post(
      `/merchant/offers/${this.params.id}/refund-pre-proceeds`,
      payload
    )

    if (response.status.includes('failed')) {
      const error = new Error(response.message) as any
      error.response = response

      throw error
    }

    await this.fetch()
    return response
  }

  createNewOverviewShortlink = async () => {
    const fallbackShortlinkToken = this.data?.shortLinks?.[0]?.token || null

    // We should thow an error here, but we don't want to break the app
    // if the backend is broken
    if (!fallbackShortlinkToken) {
      return
    }

    const endpoint = `/public/short-links/${fallbackShortlinkToken}/short-links`

    // Remove try/catch when backend is ready
    try {
      await api.post(endpoint, {
        sendNotification: false,
      })
    } catch (e) {
      Sentry.captureException(e)
    }
    await this.fetch()
  }
}

export default Offer
export const useResource = createResourceHook(Offer)
export function useOffer(p: Params | (() => Params)) {
  const match = useRouteMatch<{
    offerId?: string
  }>({
    path: ['/transactions/:offerId'],
  })

  let params: Params
  if (p instanceof Function) {
    params = p()
  } else {
    params = p
  }

  const { id } = params || {}
  const {
    params: { offerId },
  } = match || { params: {} }

  const offerIdParam = id || offerId

  return useResource(() => {
    if (!offerIdParam) {
      throw new Error('Offer ID is null')
    }
    return {
      id: Number.isNaN(Number(offerIdParam)) ? offerIdParam : +offerIdParam,
    }
  })
}
