import {
  Caption,
  Flex,
  Heading,
  Panel,
  Prompt,
  Subheading,
  Text,
  Title,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import { ThemedLogo as Logo } from 'components/Logo'
import PaymentOptions from 'components/Payment/Options'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'

export default function BillOverview({
  title,
  amount,
  consumerFirstName,
  merchantName,
  scheduledAt,
}) {
  const hidePreview = !title || !amount || !scheduledAt
  const {
    data: { isRetail },
  } = useMerchant()
  const { t } = useTranslation()
  return (
    <Panel
      header={
        <Heading color="ink.2" textAlign="center" flex="1">
          {t('What your client will see')}
        </Heading>
      }
      mb={6}
    >
      {hidePreview ? (
        <View bg="white" p={7}>
          <Heading textAlign="center" color="ink.2">
            {t(
              'Start typing in the Transaction section on the left to see the bill preview'
            )}
          </Heading>
        </View>
      ) : (
        <>
          <View p={5} bg="white" textAlign="center" borderBottom={1}>
            <Logo maxWidth="200px" maxHeight="100px" mx="auto" mb={4} />
            {consumerFirstName && (
              <Title mb={2}>
                {t('Hi {{consumerFirstName}}', { consumerFirstName })}
              </Title>
            )}
            <Prompt color="ink.2">
              {t(
                '{{merchantName}} has scheduled a payment for your upcoming {{title}}.',
                { merchantName, title }
              )}
            </Prompt>
          </View>
          <View bg="white">
            {!!title && (
              <Flex borderBottom={1} px={5} py={4}>
                <Subheading color="ink.2">
                  {isRetail ? 'Purchase' : 'Treatment'}
                </Subheading>
                <Text>{title}</Text>
              </Flex>
            )}
            {!!amount && (
              <Flex borderBottom={1} px={5} py={4}>
                <Subheading color="ink.2">{t('Price')}</Subheading>
                <Text>{format(amount)}</Text>
              </Flex>
            )}
            {!!scheduledAt && (
              <Flex borderBottom={1} px={5} py={4}>
                <Subheading color="ink.2">{t('Payment date')}</Subheading>
                <Text>{moment(scheduledAt).format('DD MMM YYYY')}</Text>
              </Flex>
            )}
          </View>
          <View bg="white" textAlign="center" px={10} py={7}>
            <Caption mb={2}>
              {t('We accept the following payment methods:')}
            </Caption>
            <PaymentOptions />
          </View>
        </>
      )}
    </Panel>
  )
}

BillOverview.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.number,
  merchantName: PropTypes.string,
  scheduledAt: PropTypes.string,
  consumerFirstName: PropTypes.string,
}
