import { Button, PasswordInput } from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import { password, required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

const PasswordResetForm = ({ onSubmit, promptCurrentPassword }) => {
  const { t } = useTranslation()
  const checkPasswordMatch = (value, values) => {
    if (value !== values.password) {
      return t(`The passwords don’t match`)
    }
    return undefined
  }

  async function handleSubmit(payload) {
    try {
      await onSubmit(payload)
    } catch (error) {
      return formError(error)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          {promptCurrentPassword && (
            <Field
              component={PasswordInput}
              label={t('Current Password')}
              name="currentPassword"
              validate={[required, password]}
              disabled={submitting}
            />
          )}
          <Field
            component={PasswordInput}
            label={t('New Password')}
            name="password"
            validate={[required, password]}
            disabled={submitting}
            criterias
          />

          <Field
            component={PasswordInput}
            label={t('Confirm Password')}
            name="rePassword"
            validate={[required, checkPasswordMatch]}
            disabled={submitting}
          />

          <Button type="submit" loading={submitting} disabled={submitting}>
            {t('Confirm Password')}
          </Button>

          <FormError />
        </form>
      )}
    </Form>
  )
}

PasswordResetForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  promptCurrentPassword: PropTypes.bool,
}

export default PasswordResetForm
