import { View } from '@tabeo/scarf'
import { ThemeDataConsumer } from 'components/App/ThemeData'
import React from 'react'
import styled from 'styled-components'
import logo from './logo.svg'

const Logo = styled(View)`
  object-fit: contain;
`

Logo.defaultProps = {
  alt: 'logo',
  maxHeight: ['24px', '32px'],
  src: logo,
  as: 'img',
  mx: 'auto',
}

export default Logo

export class ThemedLogo extends React.Component {
  state = { didTimeout: false }

  mounted = false

  componentDidMount() {
    this.mounted = true
    this.timer = setTimeout(() => {
      this.mounted && this.setState({ didTimeout: true })
    }, 1000)
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    const { didTimeout } = this.state
    return (
      <ThemeDataConsumer>
        {({ theme, isMerchantLoaded }) => {
          const logo = theme ? theme.logo : null
          if ((didTimeout || isMerchantLoaded) && !logo) {
            return <Logo {...this.props} />
          }
          if (logo) {
            return <Logo src={logo} {...this.props} />
          }
          return null
        }}
      </ThemeDataConsumer>
    )
  }
}
