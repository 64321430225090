import React, { useContext } from 'react'
import ScarfProps from '../../../types/ScarfProps'
import Flex from '../../Flex'
import * as Icons from '../../Icons'
import ModuleContext from '../ModuleContext'

export type HeaderProps = ScarfProps & {
  defaultCollapse?: boolean
  collapseId?: string
  onToggle?: (id: string) => void
  isOpen?: boolean
}

function Header({
  children,
  defaultCollapse,
  collapseId = 'default',
  onToggle,
  isOpen: controlledIsOpen,
  ...rest
}: HeaderProps) {
  const { collapsible, toggle } = useContext(ModuleContext)

  const isOpen =
    controlledIsOpen !== undefined ? controlledIsOpen : collapsible[collapseId]

  const handleClick = () => {
    if (onToggle) {
      onToggle(collapseId)
    } else {
      toggle(collapseId)
    }
  }

  const hasControl = defaultCollapse || collapseId !== 'default' || onToggle

  return (
    <Flex
      py={[4]}
      px={[5]}
      onClick={hasControl && handleClick}
      cursor={hasControl && 'pointer'}
      {...rest}
    >
      {children}
      {hasControl && (
        <Icons.ArrowRight
          transform={isOpen ? `rotate(-90deg)` : `rotate(90deg)`}
          transition="transform 200ms linear"
          mr="-8px"
        />
      )}
    </Flex>
  )
}

export default Header
