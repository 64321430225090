import {
  Button,
  ButtonGroup,
  Caption,
  GridTable,
  GridTableRow,
  Subheading,
  Text,
  Title,
  View,
} from '@tabeo/scarf'
import illustration from '../assets/4.png'
import { usePricingChangesContext } from '../Context'
import Cell from './Step2/Cell'
import HeaderCell from './Step2/HeaderCell'

function Step1() {
  const { next, dontRemindMe } = usePricingChangesContext()

  return (
    <View textAlign="center">
      <View
        as="img"
        src={illustration}
        width={['132px', '200px']}
        height={['120px', '180px']}
        mx="auto"
      />
      <Title mt={6}>Our prices are based on market data</Title>
      <Text mt={5} textAlign="left" fontSize={1}>
        Tabeo is <b>reducing its subsidy fees</b>. Inflation has returned to
        below 2.0% as UK benchmark rates rallied after the budget on October
        30th. At present, we expect further reduction in benchmark rates in the
        next 12 months but in smaller steps.
      </Text>
      <View overflow="auto" mt={6}>
        <GridTable
          templateColumns={[
            'minmax(120px, auto) minmax(92px, auto) minmax(92px, auto)',
            'minmax(206px, auto) 206px 206px',
          ]}
          border={1}
          borderRadius={1}
          overflow="hidden"
          minWidth="300px"
          mx="auto"
        >
          <GridTableRow>
            <HeaderCell display="flex" alignItems="end">
              <Subheading ml={2} textAlign="left" color="blue.1">
                Date
              </Subheading>
            </HeaderCell>
            <HeaderCell>
              <Subheading
                color="blue.1"
                display={['flex', 'block']}
                flexDirection="column"
              >
                <span>SONIA</span> <span>(1 year)</span>
              </Subheading>
            </HeaderCell>
            <HeaderCell>
              <Subheading
                color="blue.1"
                display={['flex', 'block']}
                flexDirection="column"
              >
                <span>UK inflation</span> <span>(CPI)</span>
              </Subheading>
            </HeaderCell>
          </GridTableRow>
          <GridTableRow>
            <Cell>
              <Caption ml={2} textAlign="left" color="ink.0">
                September 2023
              </Caption>
            </Cell>
            <Cell>
              <Caption color="ink.0">5.44%</Caption>
            </Cell>
            <Cell>
              <Caption color="ink.0">6.7%</Caption>
            </Cell>
          </GridTableRow>
          <GridTableRow>
            <Cell odd="true">
              <Caption ml={2} textAlign="left" color="ink.0">
                December 2023
              </Caption>
            </Cell>
            <Cell odd="true">
              <Caption color="ink.0">4.73%</Caption>
            </Cell>
            <Cell odd="true">
              <Caption color="ink.0">3.9%</Caption>
            </Cell>
          </GridTableRow>
          <GridTableRow>
            <Cell>
              <Caption ml={2} textAlign="left" color="ink.0">
                March 2024
              </Caption>
            </Cell>
            <Cell>
              <Caption color="ink.0">4.87%</Caption>
            </Cell>
            <Cell>
              <Caption color="ink.0">3.2%</Caption>
            </Cell>
          </GridTableRow>
          <GridTableRow>
            <Cell odd="true">
              <Caption ml={2} textAlign="left" color="ink.0">
                June 2024
              </Caption>
            </Cell>
            <Cell odd="true">
              <Caption color="ink.0">4.90%</Caption>
            </Cell>
            <Cell odd="true">
              <Caption color="ink.0">2.0%</Caption>
            </Cell>
          </GridTableRow>
          <GridTableRow>
            <Cell>
              <Caption ml={2} textAlign="left" color="ink.0">
                September 2024
              </Caption>
            </Cell>
            <Cell>
              <Caption color="ink.0">4.29%</Caption>
            </Cell>
            <Cell>
              <Caption color="ink.0">1.7%</Caption>
            </Cell>
          </GridTableRow>
          <GridTableRow>
            <Cell>
              <Caption ml={2} textAlign="left" color="ink.0">
                November 2024
              </Caption>
            </Cell>
            <Cell>
              <Caption color="ink.0">4.43%</Caption>
            </Cell>
            <Cell>
              <Caption color="ink.0">-</Caption>
            </Cell>
          </GridTableRow>
        </GridTable>
      </View>
      <Text mt={6} textAlign="left" fontSize={1}>
        Since our last pricing change in December 2023, the 1 year SONIA rate
        decreased by about 0.30%.
        <br />
        <br />
        Tabeo is committed to remaining competitive. We will{' '}
        <b>review our subsidy fees</b> regularly over the next 12 months.
      </Text>
      <ButtonGroup width="100%" mt={10}>
        <Button variant="secondary" width="100%" onClick={dontRemindMe}>
          Close
        </Button>
        <Button width="100%" onClick={next}>
          Next
        </Button>
      </ButtonGroup>
    </View>
  )
}

export default Step1
