import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'
import { Caption } from '../../Text'

const AddressLine1 = styled(Caption).attrs(({ isFocused }) => ({
  color: isFocused ? 'white' : 'ink.2',
}))``

const AddressLine2 = styled.div.attrs(({ isFocused }) => ({
  color: isFocused ? 'white' : 'colors.default.primary',
}))`
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 5px;
`

const Option = styled.div.attrs(({ className }) => ({
  className: `Select-option${className}`,
  role: 'option',
}))`
  :not(:last-of-type) {
    border-bottom: 1px solid #e2e2e2;
  }
`

class ListItem extends Component {
  constructor(props) {
    super(props)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.handleTouchStart = this.handleTouchStart.bind(this)
    this.handleTouchMove = this.handleTouchMove.bind(this)
    this.handleTouchEnd = this.handleTouchEnd.bind(this)
  }

  handleMouseEnter(event) {
    const { option } = this.props

    this.onFocus(option, event)
  }

  handleMouseMove(event) {
    const { isFocused, option } = this.props

    if (isFocused) return
    this.onFocus(option, event)
  }

  handleMouseDown(event) {
    const { onSelect, option, onBeforeSelect } = this.props
    event.preventDefault()
    event.stopPropagation()
    if (onBeforeSelect) {
      return onBeforeSelect(option, event, onSelect)
    }
    onSelect(option, event)
  }

  handleTouchEnd(event) {
    // Check if the view is being dragged, In this case
    // we don't want to fire the click event (because the user only wants to scroll)
    if (this.dragging) return
    this.handleMouseDown(event)
  }

  handleTouchMove() {
    // Set a flag that the view is being dragged
    this.dragging = true
  }

  handleTouchStart() {
    // Set a flag that the view is not being dragged
    this.dragging = false
  }

  onFocus(event) {
    const { isFocused, onFocus, option } = this.props

    if (!isFocused) {
      onFocus(option, event)
    }
  }

  render() {
    const {
      option,
      className,
      instancePrefix,
      optionIndex,
      isFocused,
      onBeforeSelect,
      ...rest
    } = this.props
    const optionObject = option.value && JSON.parse(option.value)
    let addressLine1
    let addressLine2
    // Final Address
    if (optionObject.Label) {
      const addressArray = optionObject.Label.split(', ')
      addressLine1 = addressArray.splice(0, addressArray.length - 3).join(', ')
      addressLine2 = addressArray.reverse().splice(0, 3).reverse().join(', ')
      // Quick lookup result
    } else {
      addressLine1 = optionObject.Text
      addressLine2 = optionObject.Description
    }

    return (
      <Option
        className={className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
        id={`${instancePrefix}-option-${optionIndex}`}
        onClick={this.handleMouseDown}
        {...rest}
      >
        <AddressLine1 isFocused={isFocused}>{addressLine1}</AddressLine1>
        <AddressLine2 isFocused={isFocused}>{addressLine2}</AddressLine2>
      </Option>
    )
  }
}

ListItem.propTypes = {
  option: PropTypes.object,
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onBeforeSelect: PropTypes.func.isRequired,
  isFocused: PropTypes.bool,
  optionIndex: PropTypes.number,
  instancePrefix: PropTypes.string,
  onFocus: PropTypes.func,
}

export default ListItem
