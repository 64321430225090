import { DescriptionList, Settings, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const { Section } = Settings

function Contact({ resource }) {
  const { data: merchant } = resource
  const { t } = useTranslation()

  return (
    <Section _notLast={{ borderBottom: 0, pb: 4 }}>
      <Section.Header mb="-12px">
        <View>
          <Section.Title>{t('Contact details')}</Section.Title>
          <Section.Description>
            {t(
              'These are shown to your patients in case they need to reach you (E.g. the phone number at reception).'
            )}
          </Section.Description>
        </View>
      </Section.Header>
      <DescriptionList
        data={[
          {
            label: t('Email address'),
            value: merchant.email,
          },
          {
            label: t('Phone number'),
            value: merchant.phoneNumber,
          },
          {
            label: t('Trading address'),
            value: merchant.tradingAddress,
          },
        ]}
      />
    </Section>
  )
}

Contact.propTypes = {
  resource: PropTypes.any,
}

export default Contact
