import { View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import Clinicians from './Clinicians'
import Users from './Users'

function People({ resource, merchantUsersResource }) {
  const isCliniciansTreatmentsEnabled =
    resource.data?.flags.is_clinicians_treatments_enabled

  return (
    <View>
      <Users merchantUsersResource={merchantUsersResource} />
      {isCliniciansTreatmentsEnabled && <Clinicians resource={resource} />}
    </View>
  )
}

People.propTypes = {
  resource: PropTypes.object.isRequired,
  merchantUsersResource: PropTypes.object.isRequired,
}

export default People
