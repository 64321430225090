import { Box, Subtitle, Text, View } from '@tabeo/scarf'
import dino from 'assets/dino.png'
import Link from 'components/Link'
import PropTypes from 'prop-types'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const Icon = styled(View)``

Icon.defaultProps = {
  as: 'img',
  mx: 'auto',
  src: dino,
  width: '160px',
}

const Failed = ({ type = 'internal', error }) => {
  const { t } = useTranslation()

  const errors = {
    network: {
      title: t('Check your connection'),
      body: (
        <Text mb={6}>
          <Trans>
            It looks like there’s a problem with your internet connection. A
            Dinosaur might be chewing on your cables.
            <br />
            <br />
            Try refreshing your page, or{' '}
            <Link to="/support">contact support</Link>.
          </Trans>
        </Text>
      ),
    },
    not_found: {
      title: t('Page not found'),
      body: (
        <Text mb={6}>
          {t(
            'For some reason you can’t access this page or it may not exist. Please check if you are logged in.'
          )}
        </Text>
      ),
    },
    internal: {
      title: t('Something went wrong'),
      body: (
        <Text mb={6}>
          <Trans>
            Please try refreshing your page. If the problem continues, you can
            contact <Link to="/support">support</Link>.
            <br />
            <br />
            We’re sorry for the inconvenience.
          </Trans>
        </Text>
      ),
    },
  }

  const title = error?.title || errors[type].title
  const description = error?.description || errors[type].body
  return (
    <Box variant="content" textAlign="center" maxWidth="460px" mx="auto">
      <Icon mb={5} />
      <Subtitle color="default.primary" mb={4}>
        {title}
      </Subtitle>
      <Text>{description}</Text>
    </Box>
  )
}

Failed.propTypes = {
  type: PropTypes.string,
  error: PropTypes.object,
}

export default Failed
