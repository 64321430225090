import { Button, Caption, Flex, Heading, View } from '@tabeo/scarf'
import icon from 'components/ActionPanel/assets/ready-to-confirm-blue.svg'
import Card from 'components/Transaction/ActionPanel/Card'
import TreatmentModal from 'components/TreatmentModal'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import RefundButton from './Refund'

function FulfillmentRequired({ transaction, ...rest }) {
  const { data, createOrUpdateTreatment } = transaction
  const modal = useRef()
  const { t } = useTranslation()

  return (
    <>
      <TreatmentModal
        ref={modal}
        onSubmit={createOrUpdateTreatment}
        offer={data}
        isConfirm
      />
      <Card {...rest}>
        <Flex justifyContent="flex-start" mb={4}>
          <View as="img" src={icon} mr={4} />
          <Heading fontWeight="semibold">
            {t('Confirm treatment details to release your payout')}
          </Heading>
        </Flex>
        <Caption color="ink.1" mb={4}>
          {t(
            'To confirm the details you’ll need to provide a treatment start date and the clinician who has or will provide the treatment. Your payout will be issued on the treatment start date.'
          )}
        </Caption>
        <Flex
          flexDirection={['column', 'row']}
          justifyContent="flex-start"
          alignItems={['stretch', 'center']}
        >
          <Button
            variant="primary"
            sizeVariant="sm"
            mr={[0, 4]}
            mb={[4, 0]}
            onClick={() => modal.current.open()}
          >
            {t('Confirm treatment details')}
          </Button>
          <RefundButton mr={[0, 4]} mb={[4, 0]} transaction={transaction} />
        </Flex>
      </Card>
    </>
  )
}

FulfillmentRequired.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default FulfillmentRequired
