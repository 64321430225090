import React from 'react'
import styled from 'styled-components'

import Svg, { Path, SvgProps } from './Base'

const Plus = ({ fill, light, ...props }: SvgProps & { light?: boolean }) =>
  light ? (
    <Svg {...props}>
      <Path
        fill={fill}
        d="M10.833 10.833V6.167a1.167 1.167 0 0 1 2.334 0v4.666h4.666a1.167 1.167 0 0 1 0 2.334h-4.666v4.666a1.167 1.167 0 0 1-2.334 0v-4.666H6.167a1.167 1.167 0 0 1 0-2.334h4.666z"
      />
    </Svg>
  ) : (
    <Svg fill={fill} {...props} fillRule="evenodd" viewBox="0 0 24 24">
      <rect width="24" height="24" rx="12" />
      <path
        fill="#FFF"
        d="M10.833 10.833V6.167a1.167 1.167 0 0 1 2.334 0v4.666h4.666a1.167 1.167 0 0 1 0 2.334h-4.666v4.666a1.167 1.167 0 0 1-2.334 0v-4.666H6.167a1.167 1.167 0 0 1 0-2.334h4.666z"
      />
    </Svg>
  )

export default styled(Plus)``
