import React from 'react'
import { useMerchant } from '../../resources/Merchant'
import BankVerification from './BankVerification'
import DatoNotifications from './DatoNotifications'
import ManualReview from './ManualReview'
import Stripe from './Stripe'

const MANUAL_REVIEW_STATUSES = ['manual_review_required']
const BANK_VERIFICATION_STATUSES = [
  'new',
  'correct',
  'verification_token_disclosed',
]

function Notifications(props) {
  const { data: merchant, resource } = useMerchant()
  const {
    status: merchantStatus,
    bankDetails: { status: bankDetailsStatus } = {},
    stripeFundGatewayState,
  } = merchant

  const getSystemNotification = () => {
    if (MANUAL_REVIEW_STATUSES.includes(merchantStatus)) {
      return <ManualReview {...props} />
    }

    if (BANK_VERIFICATION_STATUSES.includes(bankDetailsStatus)) {
      return (
        <BankVerification bankDetailsStatus={bankDetailsStatus} {...props} />
      )
    }
    // TODO: remove stripeFundGatewayState !== undefined
    if (
      stripeFundGatewayState !== undefined &&
      stripeFundGatewayState !== 'completed' &&
      ['payment_platform_id_created'].includes(merchantStatus)
    ) {
      return <Stripe resource={resource} {...props} />
    }

    return null
  }

  return (
    <>
      <DatoNotifications />
      {getSystemNotification()}
    </>
  )
}

export default Notifications
