import { View, themeGet } from '@tabeo/scarf'
import styled from 'styled-components'

export default styled(View)`
  width: 180px;
  height: 4px;
  background: #f4f6f8;
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  &::after {
    width: 80px;
    height: 4px;
    background: ${themeGet('colors.default.primary', '#2c9fa5')};
    content: '';
    position: absolute;
    left: 80px;
    top: 0;
    animation-name: slide;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }
  @keyframes slide {
    0% {
      left: -85px;
    }
    50% {
      left: 185px;
    }
    100% {
      left: -85px;
    }
  }
`
