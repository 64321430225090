import { t } from 'i18n'

const reasons: Record<any, string> = {
  duplicated: t('Duplicate'),
  patient_unhappy: t('Patient unhappy with the treatment'),
  patient_changed_mind: t('Patient not continuing treatment'),
  discount: t('Discount'),
  other: t('Other'),
}

export default reasons
