import config from 'config'

const { COUNTRY } = config

function useCountry(code) {
  const countryCode = code || COUNTRY
  const isUK = countryCode === 'gb'
  const isNL = countryCode === 'nl'
  const isIT = countryCode === 'it'
  const isFR = countryCode === 'fr'
  const isES = countryCode === 'es'
  const isDE = countryCode === 'de'

  const isEU = isNL || isIT || isFR || isES || isDE

  return {
    isUK,
    isNL,
    isIT,
    isFR,
    isES,
    isDE,
    isEU,
    countryCode,
  }
}

export default useCountry
