import { FieldLayout } from '@tabeo/scarf2'
import { FieldValidator } from 'final-form'
import { ReactNode } from 'react'
import { FieldProps, Field as FinalFormField, useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'

type DefaultFieldProps<FieldValue> = FieldProps<FieldValue, any>

// @ts-ignore
// TODO: Adding Omit<DefaultFieldProps<FieldValue>, "validate">
// removes all of the props from the DefaultFieldProps interface
export interface CustomFieldProps<FieldValue>
  extends DefaultFieldProps<FieldValue> {
  label?: string | ReactNode
  subLabel?: string | ReactNode
  optional?: boolean
  description?: string | ReactNode
  containerClassName?: string
  validate?: FieldValidator<FieldValue> | FieldValidator<FieldValue>[]
  componentRef?: React.RefObject<any>
}

const optionalValidatorWrapper = (fn: any) => (value: any) =>
  value ? fn(value) : undefined

const composeValidators =
  (...validators: any[]) =>
  (value: any, values: any) =>
    validators.reduce(
      (error, validator) => error || validator(value, values),
      undefined
    )

export function Field<FieldValue>({
  label,
  subLabel,
  optional,
  description,
  name,
  validate,
  containerClassName,
  componentRef,
  ...rest
}: CustomFieldProps<FieldValue>) {
  const { meta } = useField(name)
  const { t } = useTranslation()

  let validator =
    optional && validate ? optionalValidatorWrapper(validate) : validate
  if (Array.isArray(validate)) {
    const arrayOfValidators = optional
      ? validate.map(optionalValidatorWrapper)
      : validate
    validator = composeValidators(...arrayOfValidators)
  }

  const error = meta.error || meta.submitError
  const showError = error && typeof error === 'string' && meta.touched

  return (
    <FieldLayout
      label={label}
      optional={optional}
      subLabel={subLabel}
      description={description}
      error={showError ? error : undefined}
      containerClassName={containerClassName}
      optionalLabel={t('(optional)')}
    >
      <FinalFormField
        name={name}
        validate={validator as FieldValidator<any>}
        {...rest}
        ref={componentRef}
      />
    </FieldLayout>
  )
}
