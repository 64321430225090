import { useCallback, useEffect, useRef, useState } from 'react'

export type StateType<V = any, E = any> = {
  success: boolean
  pending: boolean
  error: E
  value: V
  args: any[]
}

const initialState: StateType = {
  success: false,
  pending: false,
  error: null,
  value: null,
  args: [],
}

interface Options {
  onSuccess?: (value: any) => void
  onError?: (error: any) => void
}

function usePromise<V = any, E = any>(
  promise: (...args: any[]) => any,
  options: Options = {}
): [(...args: any[]) => Promise<void>, StateType<V, E>, () => void] {
  const mounted = useRef(true)
  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )

  const onSuccess = useCallback((value: any) => {
    options.onSuccess?.(value)
    // eslint-disable-next-line
  }, [])

  const onError = useCallback((error: any) => {
    options.onError?.(error)
    // eslint-disable-next-line
  }, [])

  const [state, setState] = useState<StateType<V, E>>(initialState)
  const trigger = useCallback(
    async (...args: any[]) => {
      mounted.current &&
        setState({
          ...initialState,
          pending: true,
          args,
        })
      try {
        const value = await promise(...args)
        mounted.current &&
          setState({
            ...initialState,
            pending: false,
            success: true,
            value,
            args,
          })
        onSuccess(value)
      } catch (e) {
        mounted.current &&
          setState({
            ...initialState,
            error: e as any,
            args,
          })
        onError(e)
      }
    },
    [promise, onSuccess, onError]
  )

  const reset = useCallback(() => {
    mounted.current && setState(initialState)
  }, [])

  return [trigger, state, reset]
}

export default usePromise
