import { Breadcrumb, Title } from '@tabeo/scarf'
import LayoutSideNavbar from 'components/layouts/LayoutSideNavbar'
import SubPage from 'components/layouts/Subpage'
import TwoColumns, { Column } from 'components/layouts/TwoColumns'
import Subscriptions from 'pages/PlansV2/Subscriptions'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { usePlanV2 } from 'resources/PlanV2'
import Details from './Details'
import Documents from './Documents'
import Sharing from './Sharing'

function PlanV2() {
  const { t } = useTranslation()
  const { data } = usePlanV2()

  return (
    <LayoutSideNavbar isLoading={!data}>
      {data && (
        <SubPage
          renderBreadcrumb={props => (
            <Breadcrumb {...props} mb={4}>
              <Breadcrumb.Item as={Link} to="/plans">
                {t('Plans')}
              </Breadcrumb.Item>
              <Breadcrumb.Item as={Link} to={`/plans/${data?.id}`}>
                {data?.displayId}
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
        >
          <Title mr={[0, 3]}>
            {t('Plan #{{id}}', { id: data?.displayId })}
          </Title>
          <TwoColumns mt={4}>
            <Column>
              <Details data={data} />
              <Subscriptions planId={data?.id} />
            </Column>
            <Column>
              <Sharing data={data} />
              <Documents data={data} />
            </Column>
          </TwoColumns>
        </SubPage>
      )}
    </LayoutSideNavbar>
  )
}

export default PlanV2
