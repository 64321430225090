import { useMobile } from '@tabeo/scarf'
import DatoNotifications from 'components/Notifications/DatoNotifications'
import PricingChanges from 'components/PricingChanges'
import TermsUpdate from 'components/TermsUpdate'
import Layout from 'components/nnts/Layout'
import SummaryCart from 'components/nnts/SummaryCart'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { useCardReaders } from 'resources/CardReaders'
import { useNewTransactionFlow } from './provider'
import Stepper from './stepper'

function Flow() {
  const { currentStep, currentIndex, state, flowSteps } =
    useNewTransactionFlow()
  const isMobile = useMobile()

  // Calculate direction for animation. When navigating forward, the new page
  // should slide in from the bottom. When navigating backwards, the new page
  // should slide in from the top.
  const previousStep = useRef(0)
  useEffect(() => {
    previousStep.current = currentIndex
  }, [currentIndex])
  const direction = currentIndex > previousStep.current ? 1 : -1
  const animationAxis = useRef(isMobile ? 'x' : 'y')

  // Pre-fetch card readers so when the user gets to the payment step, the
  // readers are already loaded and the corrent payment option is selected.
  const { data: readersData } = useCardReaders()
  // Prevent the flow from rendering if the card readers are not loaded yet
  // to avoid payment method auto-selection issues.
  if (readersData?.items === null) {
    return null
  }

  // Find the component for the current step
  const currentPage = flowSteps.find(page => {
    return page.id === currentStep
  })

  const Main = currentPage?.main
  const Actions = currentPage?.actions
  const shouldRenderMobileActions =
    isMobile &&
    (currentPage?.shouldRenderMobileActions?.(state) || currentPage?.isLast)

  if (!Main || !Actions) {
    return null
  }

  return (
    <Layout>
      <Layout.Main>
        <div className="mb-5 space-y-4">
          <DatoNotifications />
          <PricingChanges className="mb-0" />
          <TermsUpdate />
        </div>
        <AnimatePresence initial={false}>
          <motion.div
            key={currentPage.id}
            initial={{
              [animationAxis.current]: `${direction * 100}%`,
              opacity: 0,
            }}
            animate={{ [animationAxis.current]: 0, opacity: 1 }}
            exit={{
              opacity: 0,
              position: 'absolute',
              transition: {
                duration: 0.1,
              },
            }}
            transition={{
              type: 'spring',
              bounce: 0.2,
              duration: 0.4,
            }}
            className="h-full"
          >
            <Main />
          </motion.div>
        </AnimatePresence>
      </Layout.Main>
      <Layout.Sidebar>
        <SummaryCart>
          <SummaryCart.Body>
            <Stepper />
          </SummaryCart.Body>
          <SummaryCart.Footer>
            <Actions />
          </SummaryCart.Footer>
        </SummaryCart>
      </Layout.Sidebar>
      {shouldRenderMobileActions && (
        <Layout.MobileFooter>
          <Actions />
        </Layout.MobileFooter>
      )}
    </Layout>
  )
}

export default Flow
