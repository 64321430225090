import { Caption, Flex, Icons, Text, Title } from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import Layout from 'components/Layout'
import Link from 'components/Link'
import Form from 'components/PasswordResetForm'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

class Reset extends Component {
  state = {
    isSuccess: false,
  }

  handleSubmit = values => {
    const { resetPassword, token } = this.props

    // Submit the new password to reset current one
    return (
      resetPassword({
        ...values,
        passwordToken: token,
      })
        .then(() => {
          this.setState({
            isSuccess: true,
          })
        })
        // Return server errors to the form
        .catch(e => {
          return formError(e)
        })
    )
  }

  render() {
    const { isSuccess } = this.state
    const { t } = this.props

    return (
      <Layout maxWidth="640px">
        <Title mb={5}>{t('New Password')}</Title>
        {!isSuccess ? (
          <>
            <Text mb={5}>
              {t(
                'Please set your new password and make sure to store it somewhere safe'
              )}
            </Text>
            <Form onSubmit={this.handleSubmit} />
            <Flex justifyContent="flex-start" mt={5}>
              <Icons.Lock fill="ink.2" mr={2} />
              <Caption color="ink.2">
                {t('This website is protected by 128-bit SSL security')}
              </Caption>
            </Flex>
          </>
        ) : (
          <>
            <Text mb={5}>
              {t(
                'Thank you for re-setting your password. To protect your data, you have to sign in again.'
              )}
            </Text>
            <Caption mt={5}>
              <Link to="/login">{t('Sign In')}</Link>
            </Caption>
          </>
        )}
      </Layout>
    )
  }
}
Reset.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  token: PropTypes.string,
  t: PropTypes.func,
}

export default withTranslation()(Reset)
