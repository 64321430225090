import Connecting from 'components/terminal/Connecting'
import useStripeTerminal from 'hooks/useStripeTerminal'
import { useContext, useEffect, useRef } from 'react'
import TerminalPaymentContext from '../Context'

function TestConnection() {
  const { clearReaderDisplay } = useStripeTerminal()
  const { dispatch } = useContext(TerminalPaymentContext)

  const didInit = useRef(false)

  useEffect(() => {
    const fn = async () => {
      try {
        await clearReaderDisplay()
        dispatch({ type: 'reader-connected' })
      } catch {
        dispatch({ type: 'reader-not-connected' })
      }
    }

    if (!didInit.current) {
      didInit.current = true
      fn()
    }
  }, [clearReaderDisplay, dispatch])

  return <Connecting minHeight="340px" />
}

export default TestConnection
