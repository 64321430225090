import LayoutSideNavbar from 'components/layouts/LayoutSideNavbar'
import Subpage from 'components/layouts/Subpage'
import TwoColumns, { Column } from 'components/layouts/TwoColumns'
import Notifications from 'components/Notifications'
import { PaymentSource } from 'components/PaymentMethodPanel'
import React from 'react'
import { useSubscription } from 'resources/Subscription'
import ActionPanel from './ActionPanel'
import ActivityFeed from './ActivityFeed'
import BenefitsPanel from './BenefitsPanel'
import Documents from './Documents'
import Header from './Header'
import PatientPanel from './PatientPanel'
import Plan from './Plan'
import Progressbar from './Progressbar'

function Subscription() {
  const { data, resource } = useSubscription()

  return (
    <LayoutSideNavbar isLoading={!data}>
      {data && (
        <Subpage>
          <Notifications mb={4} />
          <Header subscription={data} />
          <TwoColumns mt={4}>
            <Column>
              <Progressbar data={data} />
              <ActionPanel data={data} />
              <Plan data={data} />

              <BenefitsPanel resource={resource} />
              <ActivityFeed data={data} />
            </Column>
            <Column>
              <PatientPanel data={data} />
              <PaymentSource data={data.paymentSource} />
              <Documents data={data} />
            </Column>
          </TwoColumns>
        </Subpage>
      )}
    </LayoutSideNavbar>
  )
}

export default Subscription
