import { getAppUrlForCountry } from 'config'

const getDocumentUrl = document => {
  const host = getAppUrlForCountry('assets', document.countryCode)
  const endpoint = document.accessToken
    ? `/public/assets/documents/${document.accessToken}`
    : `/merchant/documents/${document.id}`
  return `${host}${endpoint}`
}

export default getDocumentUrl
