import React from 'react'
import Svg, { Path, Rect, SvgProps } from './Base'

function Id({ fill, bg = '#FFF', ...props }: SvgProps) {
  return (
    <Svg {...props}>
      <g transform="translate(5 5)" fill="none" fillRule="evenodd">
        <Rect fill={fill} width={12} height={12} rx={4} />
        <Path
          d="M8.727 9.069L6 7.599l-2.727 1.47v-5.45c0-.46.349-.833.779-.833h3.896c.43 0 .78.373.78.833v5.45z"
          fill={bg as any}
          stroke="none"
        />
      </g>
    </Svg>
  )
}

export default Id
