import React from 'react'
import styled from 'styled-components'
import ScarfProps from '../../types/ScarfProps'
import { Caption } from '../Text'
import View from '../View'
import mapFormToProps from './mapFormToProps'

const DashedContainer = styled(View)``

DashedContainer.defaultProps = {
  borderWidth: '1px',
  borderStyle: 'dashed',
  borderColor: 'sky.0',
  borderRadius: 1,
}

const StyledCaption = styled(Caption)`
  white-space: nowrap;
`

export type PlaceholderProps = ScarfProps & {
  invalid?: boolean
  icon?: (props: {
    fill: ScarfProps<'svg'>['fill']
    height: ScarfProps<'svg'>['height']
    width: ScarfProps<'svg'>['width']
  }) => React.ReactNode
}

function Placeholder({
  children,
  icon,
  color = 'sky.0',
  invalid,
  ...rest
}: PlaceholderProps) {
  return (
    <DashedContainer
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      height={['40px', '25px']}
      borderColor={invalid ? 'default.red' : undefined}
      {...rest}
    >
      {icon &&
        icon({
          fill: invalid ? 'default.red' : (color as string),
          height: ['30px', '22px'],
          width: ['30px', '22px'],
        })}
      <StyledCaption
        color={invalid ? 'default.red' : color}
        fontSize={['16px', '14px']}
        pl={icon ? '2px' : 2}
        pr={2}
      >
        {children}
      </StyledCaption>
    </DashedContainer>
  )
}

export default mapFormToProps(Placeholder)
