import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { Modal } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import SummaryCart from 'components/nnts/SummaryCart'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentActions } from '../steps/payment'
import Stepper from './stepper'

const MobileSummaryReviewModal = React.forwardRef<
  any,
  React.ComponentProps<typeof Modal>
>((props, ref) => {
  const { t } = useTranslation()
  return (
    <Modal ref={ref} closable={false} {...props}>
      {({ isOpen, close, data }) => {
        const finished = data?.finished

        return isOpen ? (
          <SummaryCart className="fixed inset-0 z-50 bg-white">
            <SummaryCart.Body>
              <div className="flex items-center">
                {!finished && (
                  <Button
                    leadingIcon={p => <ArrowLeftIcon {...p} />}
                    onClick={close}
                    className="mr-4"
                    size="sm"
                  />
                )}
                <h1 className="title text-tabeo-ink-0">{t('Summary')}</h1>
              </div>
              <div className="mt-5 space-y-3">
                <Stepper onNavigate={() => close()} />
              </div>
            </SummaryCart.Body>
            {finished && (
              <SummaryCart.Footer>
                <PaymentActions />
              </SummaryCart.Footer>
            )}
          </SummaryCart>
        ) : null
      }}
    </Modal>
  )
})

export default MobileSummaryReviewModal
