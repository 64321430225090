import { Modal } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import React, { ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

export type HeaderProps = ComponentPropsWithoutRef<'div'> & {
  cartSummaryModalRef: React.RefObject<Modal>
  currentIndex: number
}

function Header({ className, cartSummaryModalRef, currentIndex }: HeaderProps) {
  const showViewSummary = currentIndex !== 0

  const { t } = useTranslation()
  return (
    <div
      className={twMerge(
        'mb-4 flex items-center justify-between md:mb-5',
        className
      )}
    >
      <h1 className="subheading text-tabeo-ink-2">
        {t('Create new transaction')}
      </h1>
      {showViewSummary && (
        <Button
          onClick={() => cartSummaryModalRef.current?.open()}
          className="desktop:hidden"
          size="sm"
        >
          {t('View summary')}
        </Button>
      )}
    </div>
  )
}

export default Header
