import { Box, Button, Subtitle, Text } from '@tabeo/scarf'
import emailVerifiedSvg from 'assets/email-verified.svg'
import Layout from 'components/Layout'
import usePromise from 'hooks/usePromise'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Redirect } from 'react-router-dom'
import { useAuth } from 'resources/Auth'

function EmailVerify({ match }) {
  const { t } = useTranslation()
  const { token } = match.params
  const {
    resource: { verifyEmail },
  } = useAuth()

  const [trigger, { pending, error, success }] = usePromise(verifyEmail)

  React.useEffect(() => {
    trigger({ token })
  }, [trigger, token])

  if (pending) {
    return <Layout isLoading bg={['white', 'sky.2']} />
  }
  if (error) {
    return <Redirect to="/" />
  }
  if (success) {
    return (
      <Layout maxWidth="460px" bg={['white', 'sky.2']}>
        <Box variant="content" textAlign="center">
          <img src={emailVerifiedSvg} className="mx-auto mb-3" />
          <Subtitle color="sea.3" mb={2}>
            {t('Email verified')}
          </Subtitle>
          <Text mb={6}>{t('Thank you for verifying your email address.')}</Text>
          <Link to="/signup">
            <Button>{t('Continue signup')}</Button>
          </Link>
        </Box>
      </Layout>
    )
  }

  return null
}

EmailVerify.propTypes = {
  match: PropTypes.object,
}

export default EmailVerify
