import React from 'react'
import { useParams } from 'react-router-dom'

import { useAuth } from 'resources/Auth'
import Reset from './Reset'
import RequestEmail from './RequestEmail'

const PasswordReset = () => {
  const { token } = useParams()
  const {
    resource: { requestPasswordResetEmail, resetPassword },
  } = useAuth()

  return token ? (
    <Reset token={token} resetPassword={resetPassword} />
  ) : (
    <RequestEmail requestPasswordResetEmail={requestPasswordResetEmail} />
  )
}

PasswordReset.propTypes = {}

export default PasswordReset
