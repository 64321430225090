import React from 'react'
import Svg, { G, SvgProps } from './Base'

function Revert({ fill, ...props }: SvgProps) {
  return (
    <Svg {...props}>
      <G fill="none" fillRule="evenodd" strokeLinecap="round" stroke={fill}>
        <path d="M5 9h9a3 3 0 110 6H9.998h0" />
        <path d="M7.003 7l-2 2 2 2" />
      </G>
    </Svg>
  )
}

export default Revert
