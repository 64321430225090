import { Flex, Text, View } from '@tabeo/scarf'
import { ThemedLogo } from 'components/Logo'
import config from 'config'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from 'resources/Auth'
import styled, { css } from 'styled-components'

const { LANDING_PAGE_URL } = config
function findAncestor(el, sel) {
  while (
    // eslint-disable-next-line no-cond-assign
    (el = el.parentElement) &&
    !(el.matches || el.matchesSelector).call(el, sel)
  );
  return el
}

function isTouchDevice() {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  const mq = query => {
    return window.matchMedia(query).matches
  }
  if (
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof window.DocumentTouch)
  ) {
    return true
  }
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}

const NavItemWrap = styled(Text)`
  &.active {
    color: ${p => p.theme.colors.primary[3]};
  }
`

NavItemWrap.defaultProps = {
  px: [4],
  py: 3,
  cursor: 'pointer',
  color: 'ink.1',
  w: ['100%', 'auto'],
  display: 'flex',
  alignItems: 'center',
}

export function NavItem({ children, ...rest }) {
  return (
    <NavItemWrap {...rest}>
      <span>{children}</span>
    </NavItemWrap>
  )
}

NavItem.propTypes = {
  children: PropTypes.node,
}

export const Hamburger = styled(View)`
  &:before,
  &,
  &:after {
    content: ' ';
    height: 3px;
    background: currentColor;
    transition: transform;
    transition-duration: 100ms;
    border-radius: 4px;
  }
  &:before {
    transform: translateY(-6px);
    display: inherit;
  }
  &:after {
    transform: translateY(3px);
    display: inherit;
  }
  ${p =>
    p.active &&
    css`
      & {
        background: transparent;
      }
      &:before {
        transform: translateY(0) rotate(45deg);
      }
      &:after {
        transform: translateY(-3px) rotate(-45deg);
      }
    `};
`

Hamburger.defaultProps = {
  display: ['block', 'none'],
  width: '22px',
}

function Nav(props) {
  const {
    data: { token },
  } = useAuth()
  const [isOpen, setOpen] = React.useState(false)
  React.useEffect(() => {
    function toggleMenu(e) {
      const isNestedDropdownClick = !!findAncestor(
        e.target,
        '[data-role=select]'
      )
      if (!isNestedDropdownClick) {
        setOpen(!isOpen)
      }
    }
    if (isOpen) {
      window.addEventListener('click', toggleMenu)
      document.body.style.cursor = 'pointer'
    } else {
      document.body.style.cursor = 'unset'
    }
    return () => window.removeEventListener('click', toggleMenu)
  }, [isOpen, setOpen])
  return (
    <Flex
      style={{ position: 'sticky', top: 0, zIndex: 499 }}
      bg="white"
      boxShadow={1}
      display={[isOpen ? 'block' : 'flex', 'flex']}
      px={[0, 3]}
      py={[2, 0]}
      width={1}
      id="navbar"
    >
      <Flex flex="1" px={[3, 0]}>
        <View
          display="flex"
          as={token ? Link : 'a'}
          href={token ? undefined : LANDING_PAGE_URL}
          to={token ? '/' : undefined}
        >
          <ThemedLogo />
        </View>
        <Hamburger
          onClick={() => setOpen(!isOpen)}
          color="ink.1"
          active={isOpen}
        />
      </Flex>
      <Flex
        display={[isOpen ? 'flex' : 'none', 'flex']}
        flexDirection={['column', 'row']}
        alignItems={['flex-start', 'stretch']}
        my={[2, 0]}
        {...props}
      />
    </Flex>
  )
}

Nav.propTypes = {}

export default Nav

export function NavDropdown({ label, children }) {
  const [isOpen, setOpen] = React.useState(false)

  const isTouchable = isTouchDevice()

  function toggle(e) {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    setOpen(isOpen => !isOpen)
  }

  return (
    <View
      onMouseEnter={!isTouchable ? toggle : undefined}
      onMouseLeave={!isTouchable ? toggle : undefined}
      onClick={isTouchable ? toggle : undefined}
      position="relative"
      data-role="select"
      width={[1, 'auto']}
    >
      <NavItem height="100%">{label}</NavItem>
      <View
        position={['relative', 'absolute']}
        display={[isOpen ? 'flex' : 'none']}
        flexDirection="column"
        bg="white"
        pl={[3, 0]}
        boxShadow={['none', 1]}
        borderRadius={['none', '0 0 4px 4px']}
        borderTop={1}
        minWidth="200px"
        style={{
          right: 0,
          top: '100%',
          whiteSpace: 'nowrap',
        }}
      >
        {children}
      </View>
    </View>
  )
}

NavDropdown.propTypes = {
  children: PropTypes.any,
  label: PropTypes.any,
}
