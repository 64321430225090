import {
  Attributes,
  Box,
  Caption,
  Flex,
  Prompt,
  Subheading,
  View,
} from '@tabeo/scarf'
import Avatar from 'components/Avatar'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import dateWithEllapsedTime from 'utils/dateWithEllapsedDays'

const Pet = ({ data, ...rest }) => {
  const { beneficiary } = data
  const { t } = useTranslation()
  const { name, birthdate, isNeutered, sex, species, speciesLabel, weight } =
    beneficiary
  const speciesRelated = beneficiary[species]

  const splittedSpeciesLabel = speciesLabel.replace(/_/gi, ' ')
  const formattedSpeciesLabel = splittedSpeciesLabel.capitalize()

  const breed = (speciesRelated || {}).breed || ''
  const splittedBreedLabel = breed.replace(/_/gi, ' ')
  const formattedBreedLabel = splittedBreedLabel.capitalize()
  return (
    <Box p={0} boxShadow={1} {...rest}>
      <View p={5} borderBottom={1}>
        <Flex>
          <Avatar name={name} mr={3} />
          <View flex="1">
            <Prompt fontWeight="semibold">{name}</Prompt>
            <Caption color="ink.0">{formattedSpeciesLabel}</Caption>
          </View>
        </Flex>
        <Attributes
          mt={5}
          sections={[
            [
              {
                label: t('Breed'),
                value: formattedBreedLabel,
              },
              {
                label: t('Gender'),
                value: sex === 'm' ? t('Male') : t('Female'),
              },
              {
                label: t('Date of birth'),
                value: birthdate,
                formattedValue: t(`{{birthDate}} ({{age}} old)`, {
                  birthDate: moment(birthdate).format('DD MMM YYYY'),
                  age: moment(birthdate).toNow(true),
                }),
              },
            ],
          ]}
        />
      </View>

      <View p={5} borderBottom={1}>
        <Subheading mb={3}>{t('At time of signup')}</Subheading>
        <Attributes
          sections={[
            [
              {
                label: t('Neutered'),
                value: isNeutered ? t('Yes') : t('No'),
              },
              {
                label: t('Activity level'),
                value: speciesRelated?.activityLevel?.capitalize(),
              },
              {
                label: t('Weight'),
                value: t(`{{weight}} kg`, { weight }),
              },
              {
                label: t('Latest booster'),
                value: speciesRelated?.lastVaccinatedAt,
                formattedValue: dateWithEllapsedTime(
                  speciesRelated?.lastVaccinatedAt
                ),
              },
            ],
          ]}
        />
      </View>
    </Box>
  )
}

Pet.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Pet
