import { useTranslation } from 'react-i18next'

function FieldArrayItem({
  onRemoveClick,
  children,
}: {
  onRemoveClick: () => void
  children: React.ReactNode
}) {
  const { t } = useTranslation()

  return (
    <div className="relative">
      {children}
      <button
        type="button"
        onClick={onRemoveClick}
        className="caption absolute right-0 top-0 text-tabeo-ink-2"
      >
        {t('Remove')}
      </button>
    </div>
  )
}

export default FieldArrayItem
