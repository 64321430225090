import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'

interface Params {
  endpoint: string
}

class Api extends Resource<any, Params> {
  async fetch() {
    const { endpoint, ...rest } = this.params
    if (!endpoint) {
      throw new Error('Paramater `endpoint` is required')
    }
    const response = await api.get(endpoint, rest)
    this.data = response
  }
}

export default Api
export const useApi = createResourceHook(Api)
