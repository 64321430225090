import { number, string, node, oneOfType, arrayOf } from 'prop-types'

export const children = oneOfType([arrayOf(node), node])

export const responsiveStyleProps = oneOfType([
  number,
  string,
  arrayOf(oneOfType([number, string])),
])

export default {
  children,
  responsiveStyleProps,
}
