import {
  Button,
  Caption,
  CurrencyInput,
  Modal,
  NativeSelect,
  Prompt,
  Text,
  Textarea,
  Title,
  View,
} from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import { required, valueRange } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import React from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'

import { format } from '@tabeo/sharpei/utils/currency'
import Tally from 'components/Tally'
import succeededImg from 'components/terminal/TerminalPayment/assets/succeeded.png'
import reasons from '../reasons'

export type RequestModalProps = {
  onSubmit?: (v: any) => void
} & React.ComponentProps<typeof Modal>

const RequestModal = React.forwardRef(
  ({ onSubmit, ...rest }: RequestModalProps, ref: any) => {
    const { t } = useTranslation()

    return (
      <Modal ref={ref}>
        {({ isOpen, close, data }) => {
          async function handleSubmit(values: any) {
            try {
              await onSubmit?.(values)
            } catch (e) {
              return formError(e)
            }
          }

          const { payment } = data || {}

          const charge = payment?.charges?.at(-1)

          return isOpen ? (
            <View
              maxWidth={['90%', '620px']}
              mx="auto"
              bg="white"
              borderRadius={1}
              boxShadow={1}
              p={[5, 10]}
              {...rest}
            >
              <Form
                onSubmit={handleSubmit}
                initialValues={{
                  amount: payment?.adjustedAmount,
                }}
                keepDirtyOnReinitialize
              >
                {({
                  handleSubmit,
                  submitting,
                  submitSucceeded,
                  values: { reasonType },
                }) => {
                  const internalNoteValidator = (value: any, values: any) => {
                    if (values.reasonType === 'other') {
                      return required(value)
                    }

                    return undefined
                  }

                  return submitSucceeded ? (
                    <View width="100%" textAlign="center">
                      <View
                        as="img"
                        src={succeededImg}
                        width="160px"
                        mx="auto"
                      />
                      <Title mt={6}>{t('Refund request submitted')}</Title>
                      <Text mt={1}>
                        {t(
                          'Your refund request is ready to be reviewed and approved by someone in your team with the appropriate user permissions.'
                        )}
                      </Text>
                      <Button type="button" onClick={close} width="100%" mt={6}>
                        {t('Close')}
                      </Button>
                    </View>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <Title mb={3}>{t('Refund payment')}</Title>

                      <Text mb={4}>
                        {t(
                          'Your refund request will need to be reviewed and approved by someone in your team with the appropriate authorisation.'
                        )}
                      </Text>

                      <Field
                        name="amount"
                        label={t('Refund')}
                        // @ts-ignore
                        component={CurrencyInput}
                        validate={[
                          required,
                          valueRange(
                            1,
                            payment?.adjustedAmount,
                            t(
                              `Please specify an amount between {{min}} and {{max}}`,
                              {
                                min: format(1),
                                max: format(payment?.adjustedAmount),
                              }
                            )
                          ),
                        ]}
                        maxWidth="50%"
                      />

                      <Field
                        name="reasonType"
                        label={t('Reason')}
                        component={NativeSelect}
                        validate={required}
                        options={Object.entries(reasons).map(
                          ([key, value]) => ({
                            value: key,
                            label: value,
                          })
                        )}
                        maxWidth="100%"
                      />

                      {reasonType && (
                        <Field
                          name="reasonNote"
                          label={
                            <Prompt mb={1} fontWeight="semibold">
                              {reasonType === 'other' ? (
                                <Trans>Internal note</Trans>
                              ) : (
                                <Trans>
                                  Internal note{' '}
                                  <Prompt display="inline" color="ink.2">
                                    (optional)
                                  </Prompt>
                                </Trans>
                              )}
                            </Prompt>
                          }
                          component={Textarea}
                          width="100%"
                          display="block"
                          validate={internalNoteValidator}
                          borderColor="sky.1"
                          description={
                            reasonType === 'other'
                              ? t(
                                  'Leaving an internal note is required if the selected refund reason is “other”.'
                                )
                              : undefined
                          }
                        />
                      )}

                      {charge && (
                        <Tally mt={6}>
                          <Tally.Item>
                            <Tally.Label>
                              {t('Refund will be issued to')}
                            </Tally.Label>
                            <Tally.CardValue
                              brand={charge.brand}
                              lastFourDigits={charge.last4}
                            />
                          </Tally.Item>
                        </Tally>
                      )}

                      <Caption mt={2}>
                        {t(
                          "Refunds take 5-10 days to appear on customers' bank statements."
                        )}
                      </Caption>

                      <div className="mt-10">
                        <Button loading={submitting} mb={4} width="100%">
                          {t('Request refund')}
                        </Button>
                        <Button
                          variant="secondary"
                          type="button"
                          onClick={close}
                          width="100%"
                        >
                          {t('Close')}
                        </Button>
                      </div>

                      <FormError />
                    </form>
                  )
                }}
              </Form>
            </View>
          ) : null
        }}
      </Modal>
    )
  }
)

export default RequestModal
