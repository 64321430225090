'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var ibanPatternMap = {
    GB: /^GB\d{2}[A-Z]{4}\d{6}(\d{8})$/,
    NL: /^NL\d{2}[A-Z]{4}(\d{10})$/,
    IT: /^IT\d{2}[A-Z]\d{5}\d{5}([0-9A-Z]{12})$/,
    FR: /^FR\d{2}\d{5}\d{5}([0-9A-Z]{11})\d{2}$/,
    DE: /^DE\d{2}\d{8}(\d{10})$/,
    ES: /^ES\d{2}\d{4}\d{4}\d{2}(\d{10})$/,
    LT: /^LT\d{2}\d{5}(\d{11})$/,
};
function parseIban(iban) {
    return Object.entries(ibanPatternMap).reduce(function (acc, _a) {
        var countryCode = _a[0], pattern = _a[1];
        if (acc) {
            return acc;
        }
        var found = iban.match(pattern);
        if (found) {
            return { countryCode: countryCode, iban: iban, accountNumber: found[1] };
        }
        return null;
    }, null);
}

exports.default = parseIban;
exports.ibanPatternMap = ibanPatternMap;
