import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export type PortalProps = {
  children: React.ReactNode
}

function Portal({ children }: PortalProps) {
  const [node] = useState(() => {
    const element = document.createElement('div')
    document.body.appendChild(element)

    return element
  })

  useEffect(
    () => () => {
      document.body.removeChild(node)
    },
    [node]
  )

  return createPortal(children, node)
}

export default Portal
