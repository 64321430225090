import { Caption, Flex, Heading, View } from '@tabeo/scarf'
import icon from 'components/ActionPanel/assets/card_blue.svg'
import Card from 'components/Transaction/ActionPanel/Card'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

function LoanStarted({ transaction, ...rest }) {
  const { data } = transaction

  const { consumerFirstName } = data
  const { t } = useTranslation()

  return (
    <Card {...rest}>
      <Flex justifyContent="flex-start" mb={4}>
        <View as="img" src={icon} mr={4} />
        <Heading fontWeight="semibold">{t('Loan started')}</Heading>
      </Flex>
      <Caption color="ink.1">
        {t(
          'We are collecting {{consumerFirstName}}’s first payment, this marks the official start of the loan.',
          { consumerFirstName }
        )}
      </Caption>
    </Card>
  )
}

LoanStarted.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default LoanStarted
