import { Flex, Icons, Text } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const { Alert } = Icons

const ErrorMessage = styled(Text)`
  a {
    color: inherit !important;
    text-decoration: underline;
  }
`

ErrorMessage.defaultProps = {
  letterSpacing: 'normal',
  lineHeight: 2,
  color: 'red.2',
}

const AlertIcon = styled(Alert)`
  display: inline-block;
  vertical-align: bottom;
  margin-right: 4px;
`
AlertIcon.defaultProps = {
  fill: 'colors.default.red',
  width: 22,
  height: 22,
}

function FormError(props) {
  const [state, setState] = useState({})
  const { error, submitting } = state
  const { initialError, ...rest } = props
  const { t } = useTranslation()

  // Fix FormSpy - Cannot update a component while rendering a different component
  // https://github.com/final-form/react-final-form/issues/809#issuecomment-670454285
  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  function handleChange({
    visited = {},
    submitErrors = {},
    dirty,
    submitting,
  }) {
    const fieldKeys = Object.keys(visited)
    const errorKeys = Object.keys(submitErrors)
    const { formError, hasFieldErrors } = errorKeys.reduce(
      (error, item) => {
        if (!fieldKeys.includes(item)) {
          error.formError =
            submitErrors.formError ||
            t('There was an error. Please try again later.')
        } else {
          error.hasFieldErrors = true
        }

        return error
      },
      {
        formError: dirty ? null : initialError,
        hasFieldErrors: false,
      }
    )

    function update() {
      setState({
        error: !hasFieldErrors ? formError : null,
        submitting,
      })
    }

    // When FormError is freshly mounted, then the FormSpy is faster than the useEffect
    // Let's wait for the next tick to update the state
    if (!mounted.current) {
      setTimeout(() => {
        if (mounted.current) {
          update()
        }
      }, 0)
    } else {
      update()
    }
  }

  useEffect(() => {
    if (initialError) {
      setState(s => ({
        ...s,
        error: initialError,
      }))
    }
  }, [initialError])

  return (
    <>
      <FormSpy
        onChange={handleChange}
        subscription={{
          visited: true,
          submitErrors: true,
          dirty: true,
          submitting: true,
        }}
      />
      {!submitting && error && (
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          mt={4}
          data-error
          {...rest}
        >
          <AlertIcon fill="default.red" />
          <ErrorMessage>{error?.message || error}</ErrorMessage>
        </Flex>
      )}
    </>
  )
}

FormError.propTypes = {
  initialError: PropTypes.any,
}

export default FormError
