import { CurrencyInput, DataTable, Flex, Prompt, View } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import { useEffect, useState } from 'react'

export default function AmountFilter({
  value,
  name,
  label,
  onChange,
  ...rest
}: any) {
  const [min, setMin] = useState(value.min)
  const [max, setMax] = useState(value.max)

  // String value is used in the filters preview
  const stringValue =
    min && max
      ? `${format(min)} - ${format(max)}`
      : min
      ? `${format(min)} <`
      : `< ${format(max)}`

  // When the filter is removed we need to reset the state
  useEffect(() => {
    if (!value?.min && !value?.max) {
      setMin('')
      setMax('')
    }
  }, [value])

  // Update data-table state when individual inputs are chenged also remove the filter if both are empty
  function handleChange() {
    onChange({
      name,
      value:
        min || max
          ? {
              min,
              max,
              label: stringValue,
              value: stringValue,
            }
          : undefined,
    })
  }

  return (
    <DataTable.CustomFilterWrapper label={label} value={stringValue} {...rest}>
      {({ containerProps }) => (
        <Flex
          bg="white"
          alignItems="center"
          border={1}
          borderRadius={1}
          p={4}
          boxShadow={1}
          {...containerProps}
        >
          <View>
            <Prompt fontWeight="semibold">Min.</Prompt>
            <CurrencyInput
              placeholder="0.00"
              input={{
                value: min,
                onChange: (value: number) => setMin(value),
                onBlur: handleChange,
              }}
              meta={{}}
              allowDecimals
              maxWidth="135px"
            />
          </View>
          <View height="1px" width="8px" bg="sky.1" mx={2} mt="24px" />
          <View>
            <Prompt fontWeight="semibold">Max.</Prompt>
            <CurrencyInput
              placeholder="10,000.00"
              input={{
                value: max,
                onChange: (value: number) => setMax(value),
                onBlur: handleChange,
              }}
              meta={{}}
              allowDecimals
              maxWidth="135px"
            />
          </View>
        </Flex>
      )}
    </DataTable.CustomFilterWrapper>
  )
}
