import { Button, Modal, Subtitle, Text, usePromise, View } from '@tabeo/scarf'
import FieldError from 'components/Form/FieldError'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useLead } from 'resources/Lead'
import { useTranslation } from 'react-i18next'

function DeleteModal({ innerRef }) {
  const { resource } = useLead()
  const history = useHistory()
  const { t } = useTranslation()
  async function handleDeletion(...args) {
    resource.config = {
      refreshInterval: 0,
    }
    await resource.remove(...args)
    history.replace('/leads')
  }
  const [trigger, { pending, error }] = usePromise(handleDeletion)
  return (
    <Modal ref={innerRef}>
      {({ isOpen, close }) =>
        isOpen ? (
          <View
            maxWidth={['90%', '460px']}
            mx="auto"
            bg="white"
            p={[5, '40px']}
            borderRadius={1}
            textAlign="center"
          >
            <View>
              <Subtitle color="default.red" mb={3}>
                {t('Confirm lead deletion')}
              </Subtitle>
              <Text mb={5}>
                {t(
                  'Are you sure you want to delete this lead? this action cannot be reverted.'
                )}
              </Text>
              {error ? (
                <FieldError message={error.message} mt={3} />
              ) : (
                <Button
                  onClick={trigger}
                  loading={pending}
                  variant="warning"
                  width="100%"
                  mt={3}
                >
                  {t('Delete lead')}
                </Button>
              )}
              <Button
                onClick={close}
                variant="secondary"
                width="100%"
                color="ink.2"
                mt={4}
              >
                {t('Close')}
              </Button>
            </View>
          </View>
        ) : null
      }
    </Modal>
  )
}

DeleteModal.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
}

export default DeleteModal
