import React from 'react'
import PropTypes from 'prop-types'

import TabbedView from './TabbedView'
import Tab from './Tab'

function Analytics({ tabs = [], ...props }) {
  return (
    <TabbedView {...props} data-testid="analytics">
      {tabs.map(t => (
        <Tab
          key={t.endpoint}
          label={t.label}
          responseDataPath={t.responseDataPath}
          endpoint={t.endpoint}
          labels={t.cardLabels}
        />
      ))}
    </TabbedView>
  )
}

Analytics.propTypes = {
  tabs: PropTypes.array,
}

export default Analytics
