// @ts-ignore
import schema from 'hast-util-sanitize/lib/github'
import breaks from 'remark-breaks'
import parse from 'remark-parse'
// @ts-ignore
import react from 'remark-react'
import unified from 'unified'

export type Options = {
  components: Record<string, React.ComponentType<any>>
}

export type Data = string

export function markdownToReact(data: Data, options: Options) {
  return unified()
    .use(parse)
    .use(breaks)
    .use(react, {
      remarkReactComponents: options.components,
      sanitize: {
        ...schema,
        protocols: {
          ...schema.protocols,
          href: [...schema.protocols.href, 'tel'],
        },
      },
    })
    .processSync(data).result as React.ReactNode
}
