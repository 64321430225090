import { plansorchestrator, PlatformApiError } from '@tabeo/platform-api-client'
import { getJWT } from '@tabeo/sharpei/utils/jwt'
import refreshAccessToken from 'utils/refreshAccessToken'

export const authMiddleware = {
  pre: context => {
    return new Promise(resolve => {
      const token = getJWT('token')

      resolve({
        ...context,
        init: {
          ...context.init,
          headers: {
            ...context.init?.headers,
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
          },
        },
      })
    })
  },
  post: async context => {
    if (
      context.response?.status === 401 &&
      !context.url.includes('/login') &&
      !context.url.includes('/refresh')
    ) {
      return refreshTokenAndRetry(context)
    }

    return context.response
  },
  onError: async context => {
    // We hit a fetch error like CORS or similar,
    // let's retry once again with a refreshed token
    const err = context.error as Error
    if (
      err.name === 'TypeError' &&
      (err.message === 'Failed to fetch' || err.message === 'Load failed')
    ) {
      return refreshTokenAndRetry(context)
    }

    return Promise.reject(context.response)
  },
} satisfies plansorchestrator.Middleware

async function refreshTokenAndRetry(
  context: plansorchestrator.ResponseContext | plansorchestrator.ErrorContext
) {
  const headers = new Headers(context.init.headers)
  if (!headers.has('retry')) {
    await refreshAccessToken()
    return context.fetch(context.url, {
      ...context.init,
      headers: {
        ...context.init.headers,
        retry: 'true',
      },
    })
  }

  return Promise.reject(context.response)
}

export const errorMiddleware = {
  post: async context => {
    if (!context.response.ok) {
      const response = await context.response.json()
      return Promise.reject(new PlatformApiError(response))
    }
  },
} satisfies plansorchestrator.Middleware
