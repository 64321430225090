import { Text } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import SubscriptionTermsAndConditions from './SubscriptionTermsAndConditions'

function index({ document, ...rest }) {
  const { type, content } = document
  if (type === 'subscription_agreement') {
    return <SubscriptionTermsAndConditions document={document} {...rest} />
  }
  return (
    <Text
      dangerouslySetInnerHTML={{
        __html: content,
      }}
      {...rest}
    />
  )
}

index.propTypes = {
  document: PropTypes.shape({
    content: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
}

export default index
