import { Button, Caption, Title, View } from '@tabeo/scarf'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import dinoImg from '../assets/dino.png'
import TerminalPaymentContext from '../Context'

function Error() {
  const { t } = useTranslation()
  const { dispatch } = useContext(TerminalPaymentContext)

  return (
    <View textAlign="center">
      <View as="img" src={dinoImg} width="176px" mx="auto" />
      <Title mt={6}>{t('Oops, looks like you got interrupted')}</Title>
      <Caption mt={1}>{t('Please do not refresh this page')}</Caption>
      <View mt={6}>
        <Button
          variant="secondary"
          onClick={() => dispatch({ type: 'reload-reader' })}
          width="100%"
        >
          {t('Try again')}
        </Button>
      </View>
    </View>
  )
}

export default Error
