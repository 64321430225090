import { Flex, Icons, Prompt, SimpleModal } from '@tabeo/scarf'
import useCountry from 'hooks/useCountry'
import React from 'react'
import { useTranslation } from 'react-i18next'

function SoftCheck(props) {
  const { t } = useTranslation()
  const modal = React.useRef()
  const country = useCountry()

  if (!country.isUK) {
    return null
  }

  return (
    <>
      <Flex
        justifyContent="flex-start"
        flexDirection={['column', 'row']}
        alignItems={['flex-start', 'center']}
        {...props}
      >
        <Prompt mr={[0, 4]} mb={[2, 0]}>
          {t(
            'By continuing, you give Tabeo permission to conduct a soft credit check.'
          )}
        </Prompt>
        <Icons.Questionmark
          cursor="pointer"
          onClick={() => modal.current.open()}
        />
      </Flex>
      <SimpleModal title={t('Soft credit check')} innerRef={modal}>
        <Prompt mb={6}>
          {t(
            'As part of our onboarding, we run ‘soft’ credit searches on the owners/directors of the businesses we partner with.'
          )}
        </Prompt>

        <Prompt mb={6}>
          {t(
            `We are required by our regulator to ensure that companies we work with are run by people with sound financial judgment. We also do this to better understand our partners. The 'soft' check has no impact on your credit score.`
          )}
        </Prompt>

        <Prompt>
          {t(
            `In some cases, we may need to get details and consent from the other owners of the business if there are any. We'd contact you separately in that case.`
          )}
        </Prompt>
      </SimpleModal>
    </>
  )
}

SoftCheck.propTypes = {}

export default SoftCheck
