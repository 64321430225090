import { State } from '@tabeo/scarf/dist/components/DataTable/context'
import moment from 'moment'

export function mapStateToParams(state: State) {
  const { activeFilters } = state

  const selectedDate = activeFilters?.date?.value?.value
  const startDate = selectedDate ? moment(selectedDate) : undefined
  const endDate = selectedDate
    ? moment(selectedDate).add(1, 'day').startOf('day')
    : undefined

  const product = activeFilters?.product?.value?.value

  const transactionAt = selectedDate
    ? `date_range("${startDate?.format('YYYY-MM-DD') || ''}"-"${
        endDate?.format('YYYY-MM-DD') || ''
      }")`
    : undefined

  const amountRange = activeFilters?.amount?.value
  const amount = amountRange
    ? `range(${amountRange.min || ''}-${amountRange.max || ''})`
    : undefined

  return {
    ...state,
    conditions: {
      transactionAt,
      amount,
      product,
    },
  }
}
