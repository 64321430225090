import styled from 'styled-components'
import { style, ResponsiveValue, Theme } from 'styled-system'
import View from './View'
import { Caption } from './Text'
import ScarfProps from '../types/ScarfProps'

const templateColumns = style({
  prop: 'templateColumns',
  cssProperty: 'gridTemplateColumns',
})

const gap = style({
  prop: 'gap',
  cssProperty: 'gridGap',
})

type GridTableProps = ScarfProps & {
  templateColumns?: ResponsiveValue<string, Theme> | undefined
  gap?: ResponsiveValue<string, Theme> | undefined
}

export const GridTable = styled(View)<GridTableProps>`
  ${gap};
  ${templateColumns};
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: table;
  }
`

GridTable.defaultProps = {
  display: 'grid',
  width: '100%',
}

export const GridTableRow = styled(View)`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: table-row;
  }
`

GridTableRow.defaultProps = {
  display: 'contents',
}

export const GridTableCell = styled(Caption)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:first-of-type {
    padding-left: ${(p) => (p.px ? `calc(${String(p.px)}*2)` : '16px')};
  }
  &:last-of-type {
    padding-right: ${(p) => (p.px ? `calc(${String(p.px)}*2)` : '16px')};
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: table-cell;
  }
`

GridTableCell.defaultProps = {
  // @ts-ignore
  'data-role': 'cell',
  py: 3,
  px: '8px',
  color: 'ink.0',
  fontWeight: 'regular',
}
