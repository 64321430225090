function LoadingScreen() {
  return null
  // (
  //   <>
  //     <Container>
  //       <Spinner />
  //     </Container>
  //   </>
  // )
}

export default LoadingScreen
