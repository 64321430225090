import {
  Button,
  Caption,
  Clickable,
  Flex,
  Icons,
  Prompt,
  Text,
  Title,
  Tooltip,
  View,
} from '@tabeo/scarf'
import { accountingFormat, format } from '@tabeo/sharpei/utils/currency'
import formError from '@tabeo/sharpei/utils/formError'
import FormError from 'components/Form/FormError'
import PaymentPending from 'components/Payment/Pending'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import { useOffer } from 'resources/Offer'
import { useRefundFlow } from './RefundFlowContext'

function LabelWithTooltip({ children, hint, ...rest }) {
  return (
    <Flex {...rest}>
      <Prompt mr={3}>{children}</Prompt>
      <Tooltip component={<Text textAlign="left">{hint}</Text>}>
        <Icons.Questionmark cursor="pointer" />
      </Tooltip>
    </Flex>
  )
}
LabelWithTooltip.propTypes = {
  children: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
}

function Preview({ ...rest }) {
  const {
    data: offer,
    resource: { refundPreProceeds },
  } = useOffer()
  const { next, back, transaction, flowState } = useRefundFlow()
  const [showPayout, setShowPayout] = useState(false)

  const {
    feeAmount,
    price,
    paymentPlan: { apr, principalAmount },
    feePercent,
    revisedPrice,
  } = transaction

  const { isPrePayoutRefund, ...refund } = flowState

  const { t } = useTranslation()
  const { data: merchant } = useMerchant()

  const {
    flags: { has_late_refund_fee: hasLateRefundFee },
  } = merchant

  const interestBearingFree =
    apr !== 0 ? t('interest-bearing') : t('interest-free')

  const handleSubmit = async () => {
    if (isPrePayoutRefund) {
      try {
        const payload = {
          amount: (revisedPrice || principalAmount) - refund.retentionAmount,
          refundReason: refund.refundReason,
          retentionReason: refund.retentionReason,
        }
        await refundPreProceeds(payload)
      } catch (e) {
        return formError(e)
      }
    }

    next()
  }

  return (
    <Form onSubmit={handleSubmit} destroyOnUnregister>
      {({ handleSubmit, submitting }) =>
        submitting ? (
          <PaymentPending headline={t('Processing refund')} />
        ) : (
          <View {...rest}>
            <form onSubmit={handleSubmit}>
              <Title mb={3} textAlign="center">
                {t('Your refund bill')}
              </Title>
              <View borderRadius={1} border={1}>
                <Flex p={4} _notFirst={{ borderTop: 1 }}>
                  <Prompt>
                    {t('Previous cash price')}
                    <Caption display="inline" ml={1}>
                      {t('(before refund)')}
                    </Caption>
                  </Prompt>
                  <Prompt>
                    {format(
                      offer.revisedPrice || offer.paymentPlan?.principalAmount
                    )}
                  </Prompt>
                </Flex>
                <Flex p={4} _notFirst={{ borderTop: 1 }}>
                  <Prompt>
                    {t('Adjusted cash price')}
                    <Caption display="inline" ml={1}>
                      {t('(after refund)')}
                    </Caption>
                  </Prompt>
                  <Prompt>{format(refund.revisedPrice)}</Prompt>
                </Flex>
                <Flex p={4} _notFirst={{ borderTop: 1 }} bg="sky.3">
                  <LabelWithTooltip
                    hint={t(
                      `If the customer has paid more than the final treatment cost, the refund will be processed to their bank account. If they have paid less than the final treatment cost, their loan will be adjusted accordingly.`
                    )}
                  >
                    {t('Total refund amount')}
                  </LabelWithTooltip>
                  <Prompt>{format(refund.refundAmount)}</Prompt>
                </Flex>
                <Flex p={4} _notFirst={{ borderTop: 1 }}>
                  <LabelWithTooltip
                    hint={t(
                      `You previously paid a {{amount}}% ({{fee}}) fee based on the original treatment cost of {{cost}}. This amount represents the fee you are to be refunded now that the treatment cost is lower.`,
                      {
                        amount: ((feeAmount / price) * 100).toFixed(1),
                        fee: format(feeAmount),
                        cost: format(price),
                      }
                    )}
                  >
                    {t('Subsidy fee discount')}
                  </LabelWithTooltip>
                  <Prompt>
                    {accountingFormat(-refund.merchantFeeDiscount)}
                  </Prompt>
                </Flex>
                {hasLateRefundFee && (
                  <Flex p={4} _notFirst={{ borderTop: 1 }}>
                    <LabelWithTooltip
                      hint={t(
                        'For refunds that are triggered after more than 14 days from the transaction confirmation date, we apply a fee of {{feeAmount}}% (for {{interestBearingFree}} loans). This is based on the amount to refund.',
                        {
                          feeAmount:
                            apr !== 0
                              ? Math.min(feePercent * 100, 3).toFixed(1)
                              : 3,
                          interestBearingFree,
                        }
                      )}
                    >
                      {t('Late refund fee')}
                    </LabelWithTooltip>
                    <Prompt>
                      {refund.lateRefundFee === 0 ? '+' : ''}
                      {accountingFormat(refund.lateRefundFee)}
                    </Prompt>
                  </Flex>
                )}
                {refund.varDiscount > 0 && (
                  <Flex p={4} _notFirst={{ borderTop: 1 }}>
                    <LabelWithTooltip
                      hint={t(
                        'This is a discount for the amount that you have not yet received for this transaction.'
                      )}
                    >
                      {t('Value at risk discount')}
                    </LabelWithTooltip>
                    <Prompt>{accountingFormat(-refund.varDiscount)}</Prompt>
                  </Flex>
                )}
                <Flex p={4} _notFirst={{ borderTop: 1 }} bg="sky.3">
                  <Prompt fontWeight="semibold">{t('Payment due')}</Prompt>
                  <Prompt fontWeight="semibold">
                    {format(refund.executionAmount)}
                  </Prompt>
                </Flex>
              </View>

              <Button width="100%" loading={submitting} mt={6} mb={4}>
                {isPrePayoutRefund
                  ? t('Confirm refund')
                  : t('Choose payment method')}
              </Button>
              <Button
                type="button"
                onClick={back}
                variant="secondary"
                width="100%"
              >
                {t('Back')}
              </Button>
              <FormError />

              <View mt={4}>
                <View textAlign="center">
                  <Clickable
                    fontWeight="regular"
                    onClick={() => setShowPayout(true)}
                    py={2}
                    px={5}
                    display="inline-block"
                  >
                    {t('What will my total payout be?')}
                  </Clickable>
                </View>

                {showPayout && (
                  <View borderRadius={1} border={1} mt={6}>
                    <Flex p={4} _notFirst={{ borderTop: 1 }}>
                      <Prompt>
                        {t('Previous net proceeds')}
                        <Caption display="inline" ml={1}>
                          {t('(before refund)')}
                        </Caption>
                      </Prompt>
                      <Prompt>{format(refund.currentNetProceeds)}</Prompt>
                    </Flex>
                    <Flex p={4} _notFirst={{ borderTop: 1 }} bg="sky.3">
                      <Prompt fontWeight="semibold">
                        {t('Adjusted net proceeds')}
                        <Caption display="inline" ml={1} color="ink.0">
                          {t('(after refund)')}
                        </Caption>
                      </Prompt>
                      <Prompt fontWeight="semibold">
                        {format(refund.finalNetProceeds)}
                      </Prompt>
                    </Flex>
                  </View>
                )}
              </View>
            </form>
          </View>
        )
      }
    </Form>
  )
}

export default Preview
