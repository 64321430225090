import {
  Button,
  Caption,
  Clickable,
  Flex,
  Icons,
  Modal,
  Prompt,
  Subtitle,
  Text,
  View,
} from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import Dot from 'components/Dot'
import FormError from 'components/Form/FormError'
import Toggle from 'components/Toggle'
import config from 'config'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import getColors from './getColorsFromImg'

const { COUNTRY } = config

const initialState = {
  file: null,
  previewImg: null,
  colors: null,
  dimensionsWarning: null,
}

const HtmlFileInput = styled(View)`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  & + label {
    display: inline-block;
  }
`

HtmlFileInput.defaultProps = {
  as: 'input',
  type: 'file',
}

class LogoUploadModal extends Component {
  state = initialState

  handleFileSelect = event => {
    const file = get(event, 'target.files.0', null)

    if (!file) {
      return
    }

    // Get preview image temp src
    const previewImg = URL.createObjectURL(file)

    // Store file and preview in state
    this.setState({
      file,
      previewImg,
    })

    // Get colors from image
    const img = new Image()
    img.crossOrigin = 'Anonymous'
    img.onload = () => {
      const colors = getColors(img)
      const areDimensionsValid = img.width >= 575 && img.height >= 200
      this.setState({
        colors,
        dimensionsWarning: !areDimensionsValid,
      })
    }
    img.src = previewImg
  }

  handleUpload = async () => {
    const { file, colors } = this.state
    const { uploadMerchantFile, updateTheme } = this.props
    if (file) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append(
        'instruction',
        JSON.stringify({
          type: 'merchant_logo_image',
          countryCode: COUNTRY,
        })
      )
      const { uploads } = await uploadMerchantFile(formData)
      const { id } = uploads[uploads.length - 1]
      await updateTheme({
        uploadID: id,
        primaryColor: colors[0],
      })
      this.setState({ file: null })
    }
  }

  render() {
    const { innerRef, t } = this.props
    const { previewImg, file, dimensionsWarning } = this.state
    return (
      <Modal ref={innerRef}>
        {({ isOpen, close }) =>
          isOpen ? (
            <View
              bg="white"
              mx="auto"
              maxWidth="460px"
              width="100%"
              p={[5, 10]}
              borderRadius={1}
              height="100%"
            >
              <Form
                onSubmit={async values => {
                  try {
                    await this.handleUpload(values)
                    this.setState(initialState)
                    close()
                  } catch (e) {
                    const err = e.response.data?.data
                    if (Object.keys(err).includes('fileSize')) {
                      return {
                        formError: t(
                          'File size is too large. Try uploading an image that is 1MB or less'
                        ),
                      }
                    }
                    return formError(e)
                  }
                }}
              >
                {({ handleSubmit, submitting }) => (
                  <>
                    <Subtitle textAlign="center" mb={4}>
                      {t('Upload your logo')}
                    </Subtitle>
                    <Text textAlign="center" mb={5}>
                      {t(
                        'This image is what your customer will see instead of the Tabeo logo.'
                      )}
                    </Text>
                    <View
                      p={5}
                      boxShadow="0 4px 3px -4px rgba(0, 0, 0, 0.2)"
                      bg="sky.2"
                      border={1}
                      mb={5}
                    >
                      {previewImg && (
                        <>
                          <View
                            bg="white"
                            border={1}
                            borderRadius={1}
                            p={3}
                            textAlign="center"
                          >
                            <View
                              as="img"
                              width={1}
                              src={previewImg}
                              alt="preview"
                            />
                          </View>
                          {dimensionsWarning && (
                            <Flex
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              mt={3}
                            >
                              <Icons.Alert fill="red.3" mr={2} />
                              <Caption color="red.3">
                                {t(
                                  'The image you selected is smaller than the reccomended size, this means your logo may look blurred on some of your customers’ devices.'
                                )}
                              </Caption>
                            </Flex>
                          )}
                        </>
                      )}
                      {!file && (
                        <View textAlign="center">
                          <HtmlFileInput
                            id="logo-file"
                            name="file"
                            accept=".png, .jpg, .jpeg"
                            onChange={this.handleFileSelect}
                          />
                          <Button
                            as="label"
                            htmlFor="logo-file"
                            variant="secondary"
                          >
                            {t('Choose file to upload')}
                          </Button>
                        </View>
                      )}
                    </View>

                    {file ? (
                      <View mx="auto" display="flex" flexDirection="column">
                        <View mx="auto">
                          <Trans>
                            <Prompt mb={3}>
                              Before confirming make sure your logo is:
                            </Prompt>
                            <Flex justifyContent="flex-start" mb={4}>
                              <Dot />
                              <Prompt>Not looking pixellated or blurred</Prompt>
                            </Flex>
                            <Flex justifyContent="flex-start" mb={4}>
                              <Dot />
                              <Prompt>Readable and understandable</Prompt>
                            </Flex>
                            <Flex justifyContent="flex-start" mb={4}>
                              <Dot />
                              <Prompt>Displaying colours correctly</Prompt>
                            </Flex>
                          </Trans>
                        </View>
                        <View>
                          <form onSubmit={handleSubmit}>
                            {file && (
                              <Flex justifyContent="space-around">
                                <Button
                                  disabled={submitting}
                                  variant="secondary"
                                  onClick={() => this.setState(initialState)}
                                  type="button"
                                >
                                  {t('Upload again')}
                                </Button>
                                <Button loading={submitting}>
                                  {t('Confirm logo')}
                                </Button>
                              </Flex>
                            )}
                            <FormError />
                          </form>
                        </View>
                      </View>
                    ) : (
                      <Toggle>
                        {({ value, toggle }) => (
                          <View mx="auto" display="flex" flexDirection="column">
                            {!value && (
                              <Clickable
                                textAlign="center"
                                color="default.primary"
                                onClick={toggle}
                              >
                                {t('View uploading guidelines')}
                              </Clickable>
                            )}
                            {value && (
                              <View
                                mx="auto"
                                display="flex"
                                flexDirection="column"
                              >
                                <Flex justifyContent="flex-start" mb={4}>
                                  <Dot />
                                  <Prompt>
                                    {t('PNG and JPEG formats only')}
                                  </Prompt>
                                </Flex>
                                <Flex justifyContent="flex-start" mb={4}>
                                  <Dot />
                                  <Prompt>{t('Maximum file size 1MB')}</Prompt>
                                </Flex>
                                <Flex justifyContent="flex-start" mb={4}>
                                  <Dot />
                                  <Prompt>
                                    {t('Reccomended minimum height 200px')}
                                  </Prompt>
                                </Flex>
                                <Flex justifyContent="flex-start" mb={4}>
                                  <Dot />
                                  <Prompt>
                                    {t('Reccomended minimum width 575px')}
                                  </Prompt>
                                </Flex>
                              </View>
                            )}
                          </View>
                        )}
                      </Toggle>
                    )}
                  </>
                )}
              </Form>
            </View>
          ) : null
        }
      </Modal>
    )
  }
}

LogoUploadModal.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  updateTheme: PropTypes.func.isRequired,
  uploadMerchantFile: PropTypes.func.isRequired,
  t: PropTypes.func,
}

export default function (props) {
  const { t } = useTranslation()
  return <LogoUploadModal {...props} t={t} />
}
