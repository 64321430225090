import { Caption, Spinner, Title, View } from '@tabeo/scarf'
import Summary from 'components/terminal/Summary'
import { useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useTerminalPayment } from 'resources/TerminalPayment'
import TerminalPaymentContext from '../Context'

function CapturePayment() {
  const { t } = useTranslation()
  const { dispatch, state } = useContext(TerminalPaymentContext)

  const { data: tp, resource } = useTerminalPayment({
    id: state.terminalPaymentId,
  })

  const didInit = useRef(false)

  useEffect(() => {
    const fn = async () => {
      try {
        await resource?.capturePayment()
        dispatch({ type: 'payment-captured' })
      } catch (e) {
        dispatch({ type: 'error' })
      }
    }

    if (!didInit.current) {
      didInit.current = true
      fn()
    }
  }, [dispatch, resource])

  return (
    <View textAlign="center">
      <Spinner minHeight="160px" />
      <Title mt={6}>{t('Capturing')}</Title>
      <Caption mt={1}>{t('Please do not refresh this page')}</Caption>
      <Summary data={tp} mt={6} />
    </View>
  )
}

export default CapturePayment
