import { Tooltip } from '@tabeo/scarf2'
import { format } from '@tabeo/sharpei/utils/currency'
import { Merchant } from '@tabeo/ts-types'
import { MAX_PAYMENT_AMOUNT, MIN_PAYMENT_AMOUNT } from 'config'
import { motion } from 'framer-motion'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useCardReaders } from 'resources/CardReaders'
import { useMerchant } from 'resources/Merchant'
import { BillPaymentOption } from './bill'
import { LinkPaymentOption } from './link'
import { TerminalPaymentOption } from './terminal'

export function useCardOptions(merchant?: Merchant) {
  const {
    input: { value: price },
  } = useField('price')
  const { data: readersData } = useCardReaders()

  if (!merchant || !readersData?.items) {
    return []
  }
  const { flags } = merchant

  const { items: readers } = readersData

  const disabled = price < MIN_PAYMENT_AMOUNT || price > MAX_PAYMENT_AMOUNT

  return [
    flags?.is_terminal_payment_capable &&
      Number(readers?.length) > 0 && {
        value: 'terminal',
        disabled,
      },
    flags?.is_link_payment_capable && {
      value: 'link',
      disabled,
    },
    flags?.is_bills_capable && {
      value: 'bill',
      disabled,
    },
  ].filter(Boolean) as {
    value: string
    disabled: boolean
  }[]
}

export default function Cards() {
  const { data: merchant } = useMerchant()
  const cardOptions = useCardOptions(merchant)
  const { t } = useTranslation()

  if (cardOptions.length === 0) {
    return null
  }

  return (
    <motion.div
      className="mb-6 space-y-4"
      style={{
        opacity: 0,
        scale: 0.98,
      }}
      animate={{
        opacity: 1,
        scale: 1,
      }}
    >
      <div className="subheading text-tabeo-ink-1">{t('Cards')}</div>
      {cardOptions.map(({ value, disabled }) => {
        if (value === 'terminal') {
          return (
            <Tooltip
              key={value}
              disabled={!disabled}
              content={p => (
                <Tooltip.Title {...p.popper}>
                  <Tooltip.Arrow {...p.arrow} />
                  {t(
                    'Price must be between {{minPrice}} - {{maxPrice}} to enable Terminal.',
                    {
                      minPrice: format(MIN_PAYMENT_AMOUNT),
                      maxPrice: format(MAX_PAYMENT_AMOUNT),
                    }
                  )}
                </Tooltip.Title>
              )}
              placement="top"
            >
              <TerminalPaymentOption disabled={disabled} />
            </Tooltip>
          )
        }
        if (value === 'link') {
          return (
            <Tooltip
              key={value}
              disabled={!disabled}
              content={p => (
                <Tooltip.Title {...p.popper}>
                  <Tooltip.Arrow {...p.arrow} />
                  {t(
                    'Price must be between {{minPrice}} - {{maxPrice}} to enable Link.',
                    {
                      minPrice: format(MIN_PAYMENT_AMOUNT),
                      maxPrice: format(MAX_PAYMENT_AMOUNT),
                    }
                  )}
                </Tooltip.Title>
              )}
              placement="top"
            >
              <LinkPaymentOption disabled={disabled} />
            </Tooltip>
          )
        }
        if (value === 'bill') {
          return (
            <Tooltip
              key={value}
              disabled={!disabled}
              content={p => (
                <Tooltip.Title {...p.popper}>
                  <Tooltip.Arrow {...p.arrow} />
                  {t(
                    'Price must be between {{minPrice}} - {{maxPrice}} to enable Scheduled.',
                    {
                      minPrice: format(MIN_PAYMENT_AMOUNT),
                      maxPrice: format(MAX_PAYMENT_AMOUNT),
                    }
                  )}
                </Tooltip.Title>
              )}
              placement="top"
            >
              <BillPaymentOption disabled={disabled} />
            </Tooltip>
          )
        }
        return null
      })}
    </motion.div>
  )
}
