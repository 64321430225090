import { Box, Button, Prompt, Title } from '@tabeo/scarf'
import successSvg from 'assets/success.svg'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function Success() {
  const { t } = useTranslation()

  return (
    <LayoutSideNavbar>
      <Container maxWidth="460px" bg={['white', 'sky.2']} mx="auto">
        <Box variant="content" textAlign="center">
          <img className="mx-auto mb-8 w-24" src={successSvg} />
          <Title mb={3}>{t('Business details updated')}</Title>
          <Prompt mb={6}>
            {t(
              'Thank you for updating your details. We are now checking these and will update you shortly.'
            )}
          </Prompt>
          <Button
            variant="secondary"
            width="100%"
            as={Link}
            color="sea.3"
            to="/"
          >
            {t('Back to your dashboard')}
          </Button>
        </Box>
      </Container>
    </LayoutSideNavbar>
  )
}

Success.propTypes = {}

export default Success
