import { Panel, Prompt, Subheading, TableCard, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const { Row, Card } = TableCard

// TODO: should we translate this?
const values = {
  how_did_you_hear_about_us: {
    instagram: 'Clicked an Instagram ad',
    facebook: 'Clicked a Facebook ad',
    google: 'Browsing on Google',
    friend: 'Referred by a friend',
    dentist: 'Referred by my dentist',
  },
}

const AdditionalInfo = ({ data: { productAnswers }, ...rest }) => {
  /* eslint-disable camelcase */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { comments, how_did_you_hear_about_us } = productAnswers
  const isAvailable = comments || how_did_you_hear_about_us
  const { t } = useTranslation()

  return isAvailable ? (
    <Panel title={t('Additional information')} {...rest}>
      <View py={3} px={5} bg="white">
        {how_did_you_hear_about_us && (
          <Row>
            <Card>
              <Subheading color="ink.2" mb={1}>
                {t('Channel')}
              </Subheading>
              <Prompt minHeight="24px">
                {values.how_did_you_hear_about_us[how_did_you_hear_about_us] ||
                  ''}
              </Prompt>
            </Card>
          </Row>
        )}
        {comments && (
          <Row>
            <Card>
              <Subheading color="ink.2" mb={1}>
                {t('Comments or questions')}
              </Subheading>
              <Prompt minHeight="24px">{comments || ''}</Prompt>
            </Card>
          </Row>
        )}
      </View>
    </Panel>
  ) : null
}

AdditionalInfo.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AdditionalInfo
