import { Spinner, Subtitle, Text } from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import Layout from 'components/Layout'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { useApi } from 'resources/Api'
import { useAuth } from 'resources/Auth'
import MerchantUsers from 'resources/MerchantUsers'
import Form from './Form'

function MerchantUserRegister() {
  const { t } = useTranslation()
  const { token: inviteToken } = useParams()
  const [submitting, setSubmitting] = useState(false)

  const { data, error } = useApi({
    endpoint: `/merchant-user-invitations/${inviteToken}?subResource=merchant`,
  })

  const {
    data: { token: authToken },
    resource: { login, logout },
  } = useAuth()

  const history = useHistory()

  React.useEffect(() => {
    if (authToken && !submitting) {
      logout({ redirect: `/merchant-user-registration/${inviteToken}` })
    }
  }, [logout, authToken, inviteToken, submitting])

  async function handleSubmit(values) {
    try {
      setSubmitting(true)

      await MerchantUsers.register({
        ...values,
        merchant: undefined,
        merchantID: undefined,
        addressJSON: JSON.parse(values.addressJSON),
        token: inviteToken,
      })

      await login({
        password: values.password,
        username: values.email,
      })

      history.push('/')
    } catch (e) {
      return formError(e)
    }

    setSubmitting(false)
  }

  if (error) {
    const status = error?.response?.status
    const isNotFound = status === 404
    if (isNotFound) {
      const error = new Error('Not found')
      error.title = t('This user was deleted')
      error.description = t(
        'Please reach out to the team that sent you the invitation to invite you again.'
      )
      throw error
    }
    return <Redirect to="/" />
  }

  // Display loader white fetching
  if (!data) {
    return <Spinner />
  }

  const {
    merchantUserInvitation: {
      firstName,
      merchant: { tradingName: company } = {},
    },
  } = data

  return (
    <Layout maxWidth="640px">
      <Subtitle mb={3}>{t('Merchant User Registration')}</Subtitle>
      <Text mb={2}>
        {t(
          'Hi, {{firstName}} you have been invited to join {{company}} on Tabeo.',
          { firstName, company }
        )}
      </Text>
      <Text mb={6}>{t('Please register your account.')}</Text>
      <Form
        onSubmit={handleSubmit}
        initialValues={data.merchantUserInvitation}
      />
    </Layout>
  )
}

MerchantUserRegister.propTypes = {}

export default MerchantUserRegister
