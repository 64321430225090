import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Flex from '../Flex'
import * as Icons from '../Icons'
import { Caption } from '../Text'
import View from '../View'

const Placeholder = () => <View width="22px" height="22px" />

const Page = styled(Caption)`
  user-select: none;
`

export type PaginationProps = {
  limit: number
  offset: number
  totalCount: number
  onChange: (offset: number) => void
  items: any[] | null
}

const Pagination = ({
  limit,
  offset,
  items,
  totalCount,
  onChange,
  ...rest
}: PaginationProps) => {
  const { t } = useTranslation()
  const numberOfPages = Math.ceil(totalCount / limit)
  const pages = Array.from(Array(numberOfPages).keys())
  const currentPage = offset / limit
  return totalCount > limit ? (
    <Flex borderTop={1} p={3} {...rest} data-testid="pagination">
      <View flex="1" display={['block', 'none']} />
      <Caption flex="1" display={['none', 'block']}>
        {t('Showing {{visibleItems}} of {{totalItems}} results', {
          visibleItems: items?.length,
          totalItems: totalCount,
        })}
      </Caption>
      <Flex>
        {currentPage > 0 ? (
          <Icons.ArrowLeft
            onClick={() => onChange((currentPage - 1) * limit)}
            cursor="pointer"
          />
        ) : (
          <Placeholder />
        )}
        {pages.map((p) =>
          p < currentPage - 4 || p > currentPage + 4 ? null : (
            <View
              key={p}
              bg={currentPage === p ? 'default.primary' : 'white'}
              onClick={() => onChange(p * limit)}
              borderRadius={1}
              mx={1}
              cursor="pointer"
            >
              <Page
                color={currentPage === p ? 'white' : 'ink.2'}
                textAlign="center"
                width="24px"
                lineHeight="24px"
              >
                {p + 1}
              </Page>
            </View>
          )
        )}
        {currentPage < numberOfPages - 1 ? (
          <Icons.ArrowRight
            onClick={() => onChange((currentPage + 1) * limit)}
            cursor="pointer"
          />
        ) : (
          <Placeholder />
        )}
      </Flex>
      <View flex="1" />
    </Flex>
  ) : null
}

Pagination.propTypes = {
  onChange: PropTypes.func.isRequired,
  limit: PropTypes.number,
  offset: PropTypes.number,
  items: PropTypes.array,
  totalCount: PropTypes.number,
}

export default Pagination
