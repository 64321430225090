import { Attributes, Panel } from '@tabeo/scarf'
import { Basket } from '@tabeo/ts-types'
import { ComponentProps } from 'react'

export function AdditionalDetailsPanel({
  basket,
  ...rest
}: ComponentProps<typeof Panel> & {
  basket: Basket
}) {
  if (!basket?.reference) return null

  return (
    <Panel title="Additional details" {...rest}>
      <Attributes
        className="p-5"
        sections={[
          [
            {
              label: 'PMS invoice number(s)',
              value: basket.reference,
            },
          ],
        ]}
      />
    </Panel>
  )
}
