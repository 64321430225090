import {
  AddButton,
  Button,
  Checkbox,
  Clickable,
  ConditionalFields,
  CurrencyInput,
  DatePicker,
  Flex,
  Heading,
  Icons,
  NativeSelect,
  PhoneInput,
  Prompt,
  Radio,
  Text,
  TextInput,
  Tooltip2,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import formError from '@tabeo/sharpei/utils/formError'
import {
  email,
  emailPCA,
  lengthRange,
  mobilePhonePCA,
  name,
  phone,
  required,
  valueRange,
} from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import TabbedRadioItem from 'components/Form/TabbedRadioItem'
import MerchantClinicianSelect from 'components/MerchantClinicianSelect'
import MerchantTreatmentSelect, {
  OTHER_VALUES,
} from 'components/MerchantTreatmentSelect'
import Toggle from 'components/Toggle'
import TreatmentStartDateInfo from 'components/TreatmentStartDateInfo'
import config from 'config'
import useCountry from 'hooks/useCountry'
import moment from 'moment'
import {
  DEFAULT_TIER_1_MAXIMUM_LOAN_AMOUNT,
  DEFAULT_TIER_1_MINIMUM_LOAN_AMOUNT,
  DEFAULT_TIER_2_MAXIMUM_LOAN_AMOUNT,
  DEFAULT_TIER_2_MINIMUM_LOAN_AMOUNT,
} from 'pages/NNTS/components/payment/useFinanceOptions'
import { getCategoriesBySector } from 'pages/Settings/Treatments/constants'
import PropTypes from 'prop-types'
import React from 'react'
import { Form, FormSpy } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import ApplyWithClientModal from './ApplyWithClientModal'
import CoolingOffModal from './CoolingOffModal'
import normalize from './paymentFormNormalizer'
import { useTransactionTitles } from './transactionTitleOptionsBySector'

const { CURRENCY } = config

const lengthRangeFiveHundred = lengthRange(5, 100)

function MaxTerm({ apr, rebateDays = 0, ...rest }) {
  const { t } = useTranslation()
  return (
    <Flex
      flexDirection={['column', 'row']}
      alignItems={['stretch', 'center']}
      justifyContent={['flex-start', 'space-between']}
    >
      <View flex="1" mb={[5, 0]}>
        <Trans>
          <Prompt mb={1} fontWeight="semibold">
            Term{' '}
            <Prompt display="inline" color="ink.2">
              (number of months)
            </Prompt>
          </Prompt>
        </Trans>
        <Radio {...rest} />
      </View>
      <View ml={[0, 6]}>
        <Prompt mb={1} fontWeight="semibold">
          {t('APR')}
        </Prompt>
        <View
          borderRadius={1}
          border={1}
          py="6px"
          px={3}
          bg="sky.2"
          minWidth="88px"
        >
          {rebateDays > 0 ? (
            <Text color="ink.2">{t('Option')}</Text>
          ) : (
            <Text color="ink.2">{(apr * 100).toFixed(1)}%</Text>
          )}
        </View>
      </View>
    </Flex>
  )
}

MaxTerm.propTypes = {
  apr: PropTypes.number,
  rebateDays: PropTypes.number,
}

function TreatmentStartDateField({
  isCoolingOffPeriodCapable,
  isCoolingOffPeriodWaiverCapable,
  ...rest
}) {
  return (
    <Field
      name="startedAt"
      width="100%"
      maxWidth="100%"
      clearable
      component={DatePicker}
      datePickerProps={{
        modifiers: {
          disabled: {
            before:
              isCoolingOffPeriodCapable && !isCoolingOffPeriodWaiverCapable
                ? moment().add(15, 'days').startOf().toDate()
                : new Date(),
          },
        },
      }}
      {...rest}
    />
  )
}

TreatmentStartDateField.propTypes = {
  isCoolingOffPeriodCapable: PropTypes.bool,
  isCoolingOffPeriodWaiverCapable: PropTypes.bool,
}

class PaymentForm extends React.Component {
  constructor(props) {
    super(props)

    this.defaultRiskProfile = props.merchant.merchantRiskProfiles.sort(
      (a, b) => a.order - b.order
    )?.[0]

    const {
      merchant: { flags, merchantRiskProfiles },
    } = props
    const loanOptions = flags.is_risk_profile_changing_capable
      ? merchantRiskProfiles.reduce((mem, profile) => {
          const options = profile.loanOptions.map(o => ({
            ...o,
            profile,
          }))
          return [...mem, ...options]
        }, [])
      : this.defaultRiskProfile.loanOptions.map(o => ({
          ...o,
          profile: this.defaultRiskProfile,
        }))

    this.loanOptions = loanOptions
      .map(o => ({
        ...o,
        profile: {
          ...o.profile,
          loanOptions: o.profile.loanOptions.sort(
            (a, b) => a.numberOfMonths - b.numberOfMonths
          ),
        },
      }))
      .sort((a, b) => a.numberOfMonths - b.numberOfMonths)

    this.defaultInitialValues = {
      type: 'pay_over_time',
      maxTerm: 12,
      skipInitialPaymentOptionsEmail: false,
      currency: CURRENCY,
      customFields: {
        consumerVersion: 3,
      },
    }

    this.formCache = React.createRef()
    this.formCache.current = {}

    this.coolingOffModal = React.createRef()
  }

  normalize = ({
    merchantTreatment,
    merchantClinician,
    startedAt,
    ...values
  }) => {
    const possiblePaymentOptions = this.getAvailablePaymentOptions(values)
    const paymentOption = this.loanOptions.find(
      o => o.numberOfMonths === values.maxTerm
    )

    const title = merchantTreatment?.treatment?.name || values.title
    const otherTitle = OTHER_VALUES.includes(title)
      ? values.otherTitle
      : undefined

    return normalize({
      ...values,
      title,
      otherTitle,
      merchantTreatmentID: merchantTreatment?.id,
      merchantTreatment,
      merchantClinicianID: merchantClinician?.id,
      merchantClinician,
      fulfillment: startedAt
        ? {
            startedAt,
          }
        : undefined,
      paymentOption,
      possiblePaymentOptions,
      initialPaymentPlanOptions: possiblePaymentOptions.map(
        o => o.numberOfMonths
      ),
      riskProfileName: paymentOption?.profile?.name,
      riskProfileNames: [
        ...new Set(possiblePaymentOptions.map(po => po?.profile?.name)),
      ],
    })
  }

  handleFormSpyChange = change => {
    const { onFormChange } = this.props
    const { values } = change

    return onFormChange({
      ...change,
      values: {
        ...this.normalize(values),
        clinicianName: values.person,
      },
    })
  }

  handleSubmit = async values => {
    const { onSubmit, successModal, t } = this.props
    try {
      const normalizedValues = this.normalize(values)

      const { consumerInfix, consumerLastName, treatmentCategory } =
        normalizedValues

      const transaction = await onSubmit({
        ...normalizedValues,
        title: normalizedValues.otherTitle || normalizedValues.title,
        individual:
          values.person || !normalizedValues.merchantClinician?.id
            ? {
                name: values.person,
                type: 'clinician',
              }
            : undefined,
        fulfillment: values.startedAt
          ? {
              startedAt: values.startedAt,
            }
          : undefined,
        consumerLastName:
          consumerInfix && !consumerLastName.startsWith(consumerInfix)
            ? `${consumerInfix} ${consumerLastName}`
            : consumerLastName,
        customFields: {
          ...normalizedValues.customFields,
          consumerInfix,
          treatmentCategory,
        },
      })
      successModal.current.open({
        ...values,
        ...transaction,
      })
    } catch (e) {
      const consumerError = e.response.data.data.consumer?.[0]

      if (consumerError === 'Consumer is registered in a different country.') {
        return {
          formError: t('Consumer is registered in a different country.'),
        }
      }

      return formError(e)
    }
  }

  formReset = () => {}

  handlePaymentSubmitWithMerhantLedFlow = async values => {
    const newTab = window.open('', '_blank')
    const { onSubmit, t } = this.props
    newTab.document.write(t('Loading...'))
    const normalizedValues = this.normalize(values)
    try {
      const { publicAccessToken } = await onSubmit(normalizedValues)
      this.merchantLedFlowModal.close()
      this.formReset()
      newTab.location.href = `${config.CONSUMER_APP_URL}/how-it-works/${publicAccessToken}`
    } catch (e) {
      return formError(e)
    }
  }

  getMaxTermOptions = ({ price = 0 }) => {
    if (price < 50000) {
      return this.loanOptions.slice(0, 5)
    }
    return this.loanOptions.slice(-6)
  }

  getAvailablePaymentOptions = ({ maxTerm, price }) => {
    if (!maxTerm) {
      return []
    }

    const availableOptions = this.loanOptions.filter(
      o =>
        o.numberOfMonths <= maxTerm &&
        (o.minimumLoanAmount ||
          (o.numberOfMonths > 12
            ? DEFAULT_TIER_2_MINIMUM_LOAN_AMOUNT
            : DEFAULT_TIER_1_MINIMUM_LOAN_AMOUNT)) <= price &&
        (o.maximumLoanAmount ||
          (o.numberOfMonths > 12
            ? DEFAULT_TIER_2_MAXIMUM_LOAN_AMOUNT
            : DEFAULT_TIER_1_MAXIMUM_LOAN_AMOUNT)) >= price
    )

    const lastThreeOptions = availableOptions.slice(-3)

    const interestFree12MonthsOption = availableOptions.find(
      o => o.numberOfMonths === 12 && o.interestRate === 0
    )

    if (
      maxTerm > 12 &&
      !lastThreeOptions.map(o => o.numberOfMonths).includes(12) &&
      interestFree12MonthsOption
    ) {
      return [interestFree12MonthsOption, ...lastThreeOptions.slice(1)]
    }

    return lastThreeOptions
  }

  render() {
    const {
      onFormChange,
      onSubmit,
      successModal,
      initialValues,
      titleOptions,
      merchant,
      t,
      country,
      ...rest
    } = this.props
    const {
      isSDC,
      isRetail,
      isHealthcare,
      isVet,
      flags: {
        is_direct_to_consumer: isDirectToConsumer,
        is_cooling_off_period_capable: isCoolingOffPeriodCapable,
        is_cooling_off_period_waiver_capable: isCoolingOffPeriodWaiverCapable,
        is_clinicians_treatments_enabled: isCliniciansTreatmentsEnabled,
        is_offer_internal_reference_capable: isOfferInternalReferenceCapable,
        is_offer_internal_reference_required: isOfferInternalReferenceRequired,
      },
      sector,
    } = merchant
    const andText = t(' and ')
    const titleLabel = isHealthcare
      ? t('Treatment')
      : isRetail
      ? t('Product')
      : t('Transaction name')

    const treatmentCategories = [...getCategoriesBySector(sector), t('Other')]

    return (
      <>
        <Form
          onSubmit={this.handleSubmit}
          initialValues={{
            ...initialValues,
            person: initialValues?.clinicianName,
            ...this.defaultInitialValues,
          }}
          mutators={{
            setFieldValue: (args, state, tools) => {
              const [field, value] = args
              tools.changeValue(state, field, () => value)
            },
          }}
          destroyOnUnregister
          keepDirtyOnReinitialize
        >
          {({
            handleSubmit,
            form: { reset, resetFieldState, getRegisteredFields },
            submitting,
            valid,
            validating,
            values,
            errors,
            form: {
              mutators: { setFieldValue },
            },
          }) => {
            this.formReset = () => {
              reset()
              // https://github.com/final-form/final-form/issues/317
              // reset() doesn't clean some metadata
              getRegisteredFields().forEach(field => {
                // Handle conditional fields
                if (getRegisteredFields().includes(field)) {
                  resetFieldState(field)
                }
              })
            }

            const handleMerchantLedFlowModalOpen = () => {
              if (!valid) {
                return handleSubmit()
              }
              this.merchantLedFlowModal.open(values)
            }

            const priceValidator = (value, values) => {
              const { numberOfMonths, maximumLoanAmount, minimumLoanAmount } =
                this.loanOptions.find(o => {
                  return o.numberOfMonths === values.maxTerm
                }) || {}

              const minPrice =
                minimumLoanAmount ||
                (numberOfMonths > 12
                  ? DEFAULT_TIER_2_MINIMUM_LOAN_AMOUNT
                  : DEFAULT_TIER_1_MINIMUM_LOAN_AMOUNT)
              const maxPrice =
                maximumLoanAmount ||
                (numberOfMonths > 12
                  ? DEFAULT_TIER_2_MAXIMUM_LOAN_AMOUNT
                  : DEFAULT_TIER_1_MAXIMUM_LOAN_AMOUNT)

              return valueRange(
                minPrice,
                maxPrice,
                t(`Please specify an amount between {{min}} and {{max}}`, {
                  min: format(minPrice),
                  max: format(maxPrice),
                })
              )(value)
            }

            const {
              title,
              merchantTreatment,
              maxTerm,
              skipInitialPaymentOptionsEmail,
              startedAt,
            } = values

            const isDateInCoolingOffPeriod =
              startedAt &&
              moment(startedAt).isBetween(
                moment().startOf('day'),
                moment().add(14, 'days')
              )

            const { interestRate: apr, rebateDays } =
              this.loanOptions.find(o => o.numberOfMonths === maxTerm) || {}
            const maxTermOptions = this.getMaxTermOptions(values)
            const options = this.getAvailablePaymentOptions(values)
            if (!maxTermOptions.find(o => o.numberOfMonths === maxTerm)) {
              const closest = maxTermOptions.reduce(
                (acc, curr) =>
                  Math.abs(curr.numberOfMonths - maxTerm) <
                  Math.abs(acc.numberOfMonths - maxTerm)
                    ? curr
                    : acc,
                maxTermOptions[0]
              )

              setFieldValue('maxTerm', closest.numberOfMonths)
            }

            return (
              <View bg="white" borderRadius="0 0 4px 4px" {...rest}>
                <form
                  onSubmit={async e => {
                    const error = await handleSubmit(e)
                    if (!error && valid) {
                      this.formReset()
                    }
                  }}
                >
                  <FormSpy onChange={this.handleFormSpyChange} />
                  <View p={[2, 7]} pb={[0, 2]} borderBottom={1}>
                    <Heading fontWeight="semibold" mb={5}>
                      {t('Transaction')}
                    </Heading>
                    {isCliniciansTreatmentsEnabled && !isDirectToConsumer && (
                      <Field
                        name="merchantClinician"
                        label={
                          <Prompt mb={1} fontWeight="semibold">
                            <Trans>Clinician</Trans>
                          </Prompt>
                        }
                        component={MerchantClinicianSelect}
                        maxWidth="100%"
                        validate={required}
                        containerProps={{ 'ug-id': 'clinician-field' }}
                      />
                    )}

                    {isCliniciansTreatmentsEnabled ? (
                      <>
                        <Field
                          name="merchantTreatment"
                          label={titleLabel}
                          component={MerchantTreatmentSelect}
                          maxWidth="100%"
                          validate={required}
                          containerProps={{ 'ug-id': 'treatment-field' }}
                        />
                        <FormSpy
                          subscription={{ values: true, active: true }}
                          onChange={p => {
                            if (p.active === 'merchantTreatment') {
                              const { merchantTreatment: mt } = p.values

                              if (
                                mt?.defaultPrice !== null &&
                                this.formCache.current.price !==
                                  mt?.defaultPrice
                              ) {
                                setFieldValue('price', undefined)
                                setTimeout(() => {
                                  setFieldValue('price', mt?.defaultPrice)
                                }, 100)
                              }

                              this.formCache.current.price =
                                mt?.defaultPrice || undefined
                            }
                          }}
                        />
                        {OTHER_VALUES.includes(
                          merchantTreatment?.treatment.name
                        ) && (
                          <ConditionalFields>
                            <Field
                              name="otherTitle"
                              clearable
                              label={t('Specify name')}
                              placeholder={t('Type your answer here...')}
                              component={TextInput}
                              maxWidth="100%"
                              validate={[required, lengthRangeFiveHundred]}
                            />
                            {!!treatmentCategories.length && (
                              <Field
                                name="treatmentCategory"
                                label={t('Specify category')}
                                component={NativeSelect}
                                placeholder={t('Select from the list...')}
                                options={treatmentCategories.map(o => ({
                                  value: o,
                                  label: o,
                                }))}
                                maxWidth="100%"
                                validate={required}
                              />
                            )}
                          </ConditionalFields>
                        )}
                      </>
                    ) : titleOptions ? (
                      <>
                        <Field
                          name="title"
                          label={titleLabel}
                          component={NativeSelect}
                          placeholder={t('Select from the list...')}
                          options={titleOptions.map(o => ({
                            ...o,
                            value: o.label,
                          }))}
                          maxWidth="100%"
                          validate={required}
                        />
                        {title === t('Other') && (
                          <Field
                            name="otherTitle"
                            clearable
                            placeholder={t('Please specify...')}
                            component={TextInput}
                            maxWidth="100%"
                            validate={[required, lengthRangeFiveHundred]}
                          />
                        )}
                      </>
                    ) : (
                      <Field
                        name="title"
                        clearable
                        label={titleLabel}
                        placeholder={t('Please specify...')}
                        component={TextInput}
                        maxWidth="100%"
                        validate={[required, lengthRangeFiveHundred]}
                      />
                    )}

                    <View ug-id="price-date-fieldgroup">
                      {isVet && (
                        <Field
                          label={t('Pet name')}
                          name="petName"
                          placeholder={t('E.g. Spot')}
                          clearable
                          component={TextInput}
                          maxWidth="100%"
                          minWidth={['auto', '220px']}
                          validate={[required]}
                        />
                      )}
                      <Field
                        name="price"
                        clearable
                        maxWidth="100%"
                        label={
                          <Prompt mb={1} fontWeight="semibold">
                            <Trans>
                              Price{' '}
                              <Prompt display="inline" color="ink.2">
                                (including VAT)
                              </Prompt>
                            </Trans>
                          </Prompt>
                        }
                        component={CurrencyInput}
                        validate={[required, priceValidator]}
                      />
                      {isCliniciansTreatmentsEnabled && !isDirectToConsumer && (
                        <Toggle>
                          {({ value, toggle }) =>
                            value ? (
                              <TreatmentStartDateField
                                isCoolingOffPeriodCapable={
                                  isCoolingOffPeriodCapable
                                }
                                isCoolingOffPeriodWaiverCapable={
                                  isCoolingOffPeriodWaiverCapable
                                }
                                label={
                                  <Flex justifyContent="flex-start" mb={1}>
                                    <Prompt mr={3} fontWeight="semibold">
                                      <Trans>
                                        Proposed start date{' '}
                                        <Prompt display="inline" color="ink.2">
                                          (optional)
                                        </Prompt>
                                      </Trans>
                                    </Prompt>
                                    <Tooltip2
                                      maxWidth="260px"
                                      title={t(
                                        'Indicate when you expect this treatment will start. This date does not need to be final and can easily be changed later.'
                                      )}
                                    >
                                      <View>
                                        <Icons.Questionmark cursor="pointer" />
                                      </View>
                                    </Tooltip2>
                                  </Flex>
                                }
                                description={
                                  isCoolingOffPeriodCapable &&
                                  isDateInCoolingOffPeriod ? (
                                    <Trans>
                                      This date falls within the 14-day
                                      cooling-off period.{' '}
                                      <Clickable
                                        display="inline"
                                        fontWeight="medium"
                                        onClick={() =>
                                          this.coolingOffModal.current.open()
                                        }
                                      >
                                        Learn more
                                      </Clickable>
                                    </Trans>
                                  ) : undefined
                                }
                              />
                            ) : (
                              <AddButton onClick={toggle} mt="-4px" mb={3}>
                                {t('Add start date')}
                              </AddButton>
                            )
                          }
                        </Toggle>
                      )}
                    </View>

                    {isHealthcare &&
                      !isDirectToConsumer &&
                      !isCliniciansTreatmentsEnabled && (
                        <Toggle>
                          {({ value, toggle }) =>
                            value || values.person ? (
                              <>
                                <Field
                                  name="person"
                                  clearable
                                  label={
                                    <Prompt mb={1} fontWeight="semibold">
                                      <Trans>
                                        Clinician name{' '}
                                        <Prompt display="inline" color="ink.2">
                                          (optional)
                                        </Prompt>
                                      </Trans>
                                    </Prompt>
                                  }
                                  component={TextInput}
                                  placeholder={t('E.g. Dr. John Smith')}
                                  maxWidth="100%"
                                  format={v => (v ? v.capitalize() : '')}
                                />
                                <TreatmentStartDateField
                                  isCoolingOffPeriodCapable={
                                    isCoolingOffPeriodCapable
                                  }
                                  isCoolingOffPeriodWaiverCapable={
                                    isCoolingOffPeriodWaiverCapable
                                  }
                                  label={
                                    <Flex justifyContent="flex-start" mb={1}>
                                      <Prompt mr={3} fontWeight="semibold">
                                        <Trans>
                                          Treatment start date{' '}
                                          <Prompt
                                            display="inline"
                                            color="ink.2"
                                          >
                                            (optional)
                                          </Prompt>
                                        </Trans>
                                      </Prompt>
                                      <TreatmentStartDateInfo />
                                    </Flex>
                                  }
                                  description={
                                    isCoolingOffPeriodCapable &&
                                    isDateInCoolingOffPeriod
                                      ? t(
                                          'This date falls within the next 14 days. The patient will need to waive their cancellation rights in order to receive treatment before the end of the 14-day cooling-off period.'
                                        )
                                      : undefined
                                  }
                                />
                              </>
                            ) : (
                              <AddButton onClick={toggle} mt="-4px" mb={3}>
                                {t('Add Clinician and Treatment date')}
                              </AddButton>
                            )
                          }
                        </Toggle>
                      )}

                    {isOfferInternalReferenceCapable && (
                      <Field
                        name="reference"
                        clearable
                        component={TextInput}
                        validate={
                          isOfferInternalReferenceRequired
                            ? required
                            : undefined
                        }
                        label={
                          <Prompt mb={1} fontWeight="semibold">
                            {isSDC ? t('Case') : t('Reference')}{' '}
                            {!isOfferInternalReferenceRequired && (
                              <Trans>
                                <Prompt display="inline" color="ink.2">
                                  (optional)
                                </Prompt>
                              </Trans>
                            )}
                          </Prompt>
                        }
                        maxWidth="100%"
                      />
                    )}
                  </View>

                  {maxTermOptions.length > 1 && (
                    <View p={[2, 7]} pb={[2, 6]} borderBottom={1}>
                      <Flex mb={5} justifyContent="flex-start">
                        <Heading fontWeight="semibold">
                          {t('Loan options')}
                        </Heading>
                      </Flex>
                      <Field
                        data-testid="loan-term"
                        containerProps={{ mb: 0, 'ug-id': 'max-term-field' }}
                        name="maxTerm"
                        validate={required}
                        component={MaxTerm}
                        apr={apr}
                        rebateDays={rebateDays}
                        renderItem={p => <TabbedRadioItem {...p} />}
                        flexDirection="row"
                        description={
                          !errors?.price &&
                          t(
                            `Customer will receive option for {{monthsCount}} months`,
                            {
                              count: options.length,
                              monthsCount: options
                                .map(
                                  (o, index) =>
                                    `${o.numberOfMonths}${
                                      index === options.length - 2
                                        ? andText
                                        : index === options.length - 1
                                        ? ''
                                        : ', '
                                    }`
                                )
                                .join(''),
                            }
                          )
                        }
                        items={maxTermOptions.map(o => ({
                          label: o.numberOfMonths,
                          value: o.numberOfMonths,
                        }))}
                      />
                    </View>
                  )}

                  <View
                    p={[2, 7]}
                    pb={[2, 6]}
                    ug-id="customer-details-fieldgroup"
                  >
                    <Heading fontWeight="semibold" mb={5}>
                      {t('Patient')}
                    </Heading>
                    <Field
                      name="consumerGender"
                      label={t('Title')}
                      validate={required}
                      component={Radio}
                      width={['100%', '50%']}
                      horizontal
                      items={[
                        { label: t('Mr'), value: 'm' },
                        { label: t('Ms'), value: 'f' },
                      ]}
                      containerProps={{
                        mr: [0, 5],
                      }}
                      renderItem={p => <TabbedRadioItem {...p} />}
                    />
                    <Flex
                      alignItems={['stretch', 'flex-start']}
                      flexDirection={['column', 'row']}
                      mb={5}
                    >
                      <Field
                        name="consumerFirstName"
                        clearable
                        label={t('First name')}
                        placeholder={t('E.g. John')}
                        component={TextInput}
                        maxWidth="100%"
                        validate={[required, name]}
                        format={v => (v ? v.capitalize() : '')}
                        containerProps={{
                          flex: '1',
                          mr: [0, 5],
                          maxWidth: ['auto', '244px'],
                          mb: 0,
                        }}
                      />
                      <Flex flex="1" alignItems="flex-start">
                        {merchant.isNL && (
                          <Field
                            name="consumerInfix"
                            clearable
                            label={t('Infix')}
                            placeholder={t('E.g. Van')}
                            component={TextInput}
                            maxWidth="100%"
                            format={v => (v ? v.capitalize() : '')}
                            containerProps={{
                              flex: '1',
                              mr: 5,
                              maxWidth: '100px',
                              mb: 0,
                            }}
                            minWidth="100px"
                          />
                        )}
                        <Field
                          name="consumerLastName"
                          clearable
                          label={t('Last name')}
                          placeholder={t('E.g. Smith')}
                          component={TextInput}
                          maxWidth="100%"
                          containerProps={{
                            flex: '1',
                            mb: 0,
                          }}
                          validate={[required, name]}
                          format={v => (v ? v.capitalize() : '')}
                        />
                      </Flex>
                    </Flex>

                    <Flex
                      alignItems={['stretch', 'flex-start']}
                      flexDirection={['column', 'row']}
                      mb={7}
                    >
                      <Field
                        data-no-track
                        name="consumerEmail"
                        clearable
                        label={t('Email address')}
                        placeholder={t('E.g. john@email.co.uk')}
                        component={TextInput}
                        maxWidth="100%"
                        validate={[required, email, emailPCA]}
                        containerProps={{
                          flex: '1',
                          mr: [0, 5],
                          mb: 0,
                        }}
                      />

                      <Field
                        data-no-track
                        label={t('Mobile number')}
                        component={PhoneInput}
                        name="consumerPhoneNumber"
                        clearable
                        maxWidth="100%"
                        validate={[required, phone, mobilePhonePCA]}
                        containerProps={{
                          flex: '1',
                          maxWidth: ['auto'],
                          mb: 0,
                        }}
                      />
                    </Flex>

                    {isRetail && (
                      <Field
                        name="skipInitialPaymentOptionsEmail"
                        component={Checkbox}
                        parse={v => !v}
                        format={v => !v}
                      >
                        {t('Send request by sms and email')}
                      </Field>
                    )}

                    <Button
                      width="100%"
                      loading={submitting}
                      disabled={validating}
                      data-testid="send-transaction-button"
                    >
                      {isRetail && skipInitialPaymentOptionsEmail
                        ? t('Create finance request')
                        : t('Send Finance Link')}
                    </Button>
                    {country.isUK && (
                      <Button
                        mt={4}
                        variant="secondary"
                        onClick={handleMerchantLedFlowModalOpen}
                        width="100%"
                        type="button"
                        disabled={validating}
                        rightIcon={props => <Icons.Popout {...props} />}
                        data-testid="apply-with-customer-button"
                      >
                        {t('Apply with customer now')}
                      </Button>
                    )}
                    <FormError />
                  </View>
                </form>
              </View>
            )
          }}
        </Form>
        <ApplyWithClientModal
          innerRef={merchantLedFlowModal => {
            this.merchantLedFlowModal = merchantLedFlowModal
          }}
          onSubmit={this.handlePaymentSubmitWithMerhantLedFlow}
        />
        <CoolingOffModal innerRef={this.coolingOffModal} />
      </>
    )
  }
}

PaymentForm.propTypes = {
  onFormChange: PropTypes.func,
  onSubmit: PropTypes.func,
  titleOptions: PropTypes.array,
  successModal: PropTypes.object,
  initialValues: PropTypes.object,
  merchant: PropTypes.object,
  t: PropTypes.func,
  country: PropTypes.object,
}

export default function (props) {
  const titleOptions = useTransactionTitles()
  const { data: merchant } = useMerchant()
  const { t } = useTranslation()
  const country = useCountry()
  return (
    <PaymentForm
      {...props}
      merchant={merchant}
      titleOptions={titleOptions}
      t={t}
      country={country}
    />
  )
}
