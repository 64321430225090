import React, { useRef, useState } from 'react'
import ScarfProps from '../types/ScarfProps'
import Popover, { PopoverProps } from './Popover'
import Text from './Text'
import View from './View'

export type Tooltip2Props = {
  children: ScarfProps['children']
  title?: string
  placement?: PopoverProps['placement']
  content?: ({
    ref,
    style,
    placement,
  }: {
    ref: React.Ref<any>
    style: React.CSSProperties
    placement: Tooltip2Props['placement']
  }) => React.ReactNode
  maxWidth?: ScarfProps['maxWidth']
}

function Tooltip2({
  children,
  title,
  content,
  placement = 'bottom',
  maxWidth = '100%',
}: Tooltip2Props) {
  const childRef = useRef<React.ReactNode>()
  const [state, setState] = useState({ hover: false })

  const { hover } = state

  return (
    <>
      {(title || content) && (
        <Popover
          open={hover}
          referenceElement={childRef}
          placement={placement}
          offset={[0, 4]}
        >
          {({ ref, style, placement }) =>
            title ? (
              <View
                ref={ref}
                style={style}
                data-placement={placement}
                bg="ink.1"
                p="6px"
                borderRadius="2px"
                boxShadow={1}
                maxWidth={maxWidth}
              >
                <Text fontSize="12px" color="white">
                  {title}
                </Text>
              </View>
            ) : (
              content?.({ ref, style, placement })
            )
          }
        </Popover>
      )}
      {/** @ts-ignore */}
      {React.cloneElement(children, {
        ref: (node: React.ReactNode) => {
          childRef.current = node
          // @ts-ignore
          const { ref } = children

          if (typeof ref === 'function') {
            ref(node)
          } else if (ref) {
            ref.current = node
          }

          return node
        },
        onPointerEnter: () => setState({ ...state, hover: true }),
        onPointerLeave: () => setState({ ...state, hover: false }),
      })}
    </>
  )
}

export default Tooltip2
