import { Tag } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { t } from 'i18n'

const states = {
  sent: {
    color: 'yellow',
    label: t('Sent'),
  },
  incomplete: {
    color: 'yellow',
    label: t('Signing up'),
  },
  active: {
    color: 'green',
    label: t('Active'),
  },
  failed: {
    color: 'grey',
    label: t('Contact support'),
  },
  inactive: {
    color: 'red',
    label: t('Paused'),
  },
}

const SubscriptionStateTag = ({ state, ...rest }) => {
  const currentState = states[state] || {}
  const color = currentState.color || 'grey'
  const label = currentState.label || state
  return (
    <Tag color={color} {...rest}>
      {label}
    </Tag>
  )
}

SubscriptionStateTag.propTypes = {
  state: PropTypes.string.isRequired,
}

export default SubscriptionStateTag
