import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const X = ({ color = '#FF6966', width = '22', height = '22', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 22 22" {...rest}>
    <g fill={color} mask="url(#b)">
      <path d="M11.121 10.414L9.354 8.646a.5.5 0 1 0-.708.708l1.768 1.767-1.768 1.768a.5.5 0 0 0 .708.707l1.767-1.768 1.768 1.768a.5.5 0 0 0 .707-.707l-1.768-1.768 1.768-1.767a.5.5 0 0 0-.707-.708l-1.768 1.768zM11 16a5 5 0 1 1 0-10 5 5 0 0 1 0 10z" />
    </g>
  </svg>
)

X.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default styled(X)``
