import { Button, Modal, Prompt, Title, View } from '@tabeo/scarf'
import FieldError from 'components/Form/FieldError'
import usePromise from 'hooks/usePromise'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import logosSvg from './assets/logos.svg'

function RedirectModal({ onConfirm, innerRef }) {
  const { t } = useTranslation()

  const [trigger, { pending, error }] = usePromise(handleConfirm)
  async function handleConfirm() {
    const newTab = window.open('', '_blank')
    newTab.document.write('Redirecting...')
    const url = await onConfirm()
    newTab.location.href = url
  }
  return (
    <Modal ref={innerRef}>
      {({ isOpen }) =>
        isOpen ? (
          <View
            maxWidth={['90%', '460px']}
            mx="auto"
            bg="white"
            borderRadius={1}
            boxShadow={1}
            p={[5, 10]}
            textAlign="center"
          >
            <img src={logosSvg} className="mx-auto mb-6" />
            <Title mb={2}>{t('Business verification')}</Title>
            <Prompt mb={6}>
              {t(
                'You will now be redirected to Stripe to complete verification.'
              )}
            </Prompt>
            <Button width="100%" mb={5} loading={pending} onClick={trigger}>
              {t('Continue')}
            </Button>
            {error && (
              <FieldError message={error?.message || t('There was an error')} />
            )}
          </View>
        ) : null
      }
    </Modal>
  )
}

RedirectModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  innerRef: PropTypes.object.isRequired,
}

export default RedirectModal
