'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../tslib.es6-1ef680b2.js');
require('i18next');
require('../i18n/index.js');
require('../config.js');
require('raven-js');
require('axios');
require('lodash.get');
var delay = require('./delay.js');
require('./jwt.js');
var api = require('./api.js');

var getSaga = function (uuid) { return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
    var response, e_1, saga;
    return tslib_es6.__generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 6]);
                return [4 /*yield*/, api.default.get("/sagas/".concat(uuid))];
            case 1:
                response = _a.sent();
                return [3 /*break*/, 6];
            case 2:
                e_1 = _a.sent();
                if (!(!e_1.response && !navigator.onLine)) return [3 /*break*/, 4];
                /**
                 * It's a network error, and we are offline
                 * Let's wait for online to recover
                 */
                return [4 /*yield*/, new Promise(function (resolve) {
                        window.addEventListener('online', function () {
                            resolve();
                        });
                    })];
            case 3:
                /**
                 * It's a network error, and we are offline
                 * Let's wait for online to recover
                 */
                _a.sent();
                return [2 /*return*/, getSaga(uuid)];
            case 4: throw e_1;
            case 5: return [3 /*break*/, 6];
            case 6:
                saga = response.saga;
                if (!(saga.generalStatus === 'running')) return [3 /*break*/, 8];
                return [4 /*yield*/, delay.default(250)];
            case 7:
                _a.sent();
                return [2 /*return*/, getSaga(uuid)];
            case 8:
                if (saga.generalStatus === 'failed') {
                    return [2 /*return*/, { error: saga.message, saga: saga }];
                }
                else {
                    return [2 /*return*/, { error: null, saga: saga }];
                }
        }
    });
}); };

exports.default = getSaga;
