import { Panel, View } from '@tabeo/scarf'
import Payments from 'components/Payments'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const PaymentsPanel = ({ data, ...rest }) => {
  const { t } = useTranslation()

  if (!data.isTabeoCollect || !data.isPOT) {
    return null
  }

  return (
    <Panel title={t('Payments to date')} data-testid="payments-panel" {...rest}>
      <View p={4}>
        <Payments offer={data} />
      </View>
    </Panel>
  )
}

PaymentsPanel.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PaymentsPanel
