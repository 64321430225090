import {
  AnimateHeight,
  Attributes,
  Caption,
  Flex,
  Text,
  useIntersection,
  View,
} from '@tabeo/scarf'
import useCurrency from 'hooks/useCurrency'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import dateWithEllapsedDays from 'utils/dateWithEllapsedDays'
import { ReactComponent as ConsumerRefundIcon } from './assets/consumer-refund.svg'
import { ReactComponent as FailedIcon } from './assets/failed.svg'
import { ReactComponent as MerchantRefundIcon } from './assets/merchant-refund.svg'
import { ReactComponent as NextScheduledIcon } from './assets/next-scheduled.svg'
import { ReactComponent as ScheduledIcon } from './assets/scheduled.svg'
import { ReactComponent as SucceededIcon } from './assets/succeeded.svg'
import { ReactComponent as UndefinedIcon } from './assets/undefined.svg'

const getTitle = payment => {
  const { index, type } = payment

  if (type === 'ref_con') {
    return 'Consumer refund'
  }

  if (type === 'ref_mer') {
    return 'Merchant refund'
  }

  if (index === 0) {
    return 'First payment'
  }

  if (type === 'invoice') {
    return 'Invoice'
  }

  if (type === 'ad_hoc') {
    return 'Ad-hoc'
  }

  return type
}

const getIcon = (payment, active, isNext) => {
  const { type, status } = payment

  if (type === 'ref_con') {
    return <ConsumerRefundIcon />
  }

  if (type === 'ref_mer') {
    return <MerchantRefundIcon />
  }

  if (['succeeded', 'caught_up'].includes(status)) {
    return <SucceededIcon />
  }

  if (status === 'scheduled') {
    if (isNext) {
      return <NextScheduledIcon />
    }

    return <ScheduledIcon />
  }

  if (['failed', 'part_paid'].includes(status)) {
    return <FailedIcon />
  }

  return <UndefinedIcon />
}

const getColors = (payment, active, isNext) => {
  const { type, status } = payment

  if (type === 'ref_con') {
    return {
      primary: 'sea.0',
      secondary: 'sea.1',
      bg: 'sea.6',
      border: 'sea.4',
      hoverPrimary: 'sea.0',
      hoverSecondary: 'sea.1',
      hoverBg: 'sea.5',
      hoverBorder: 'sea.4',
    }
  }

  if (type === 'ref_mer') {
    return {
      primary: 'blue.0',
      secondary: 'blue.1',
      bg: 'sky.3',
      border: 'sky.0',
      hoverPrimary: 'blue.0',
      hoverSecondary: 'blue.1',
      hoverBg: 'sky.2',
      hoverBorder: 'sky.0',
    }
  }

  if (['succeeded', 'caught_up'].includes(status)) {
    return {
      primary: 'blue.0',
      secondary: 'blue.1',
      bg: 'sky.3',
      border: 'sky.0',
      hoverPrimary: 'blue.0',
      hoverSecondary: 'blue.1',
      hoverBg: 'sky.2',
      hoverBorder: 'sky.0',
    }
  }

  if (['failed', 'part_paid'].includes(status)) {
    return {
      primary: 'red.0',
      secondary: 'red.1',
      bg: 'red.6',
      border: 'red.4',
      hoverPrimary: 'red.0',
      hoverSecondary: 'red.1',
      hoverBg: 'red.5',
      hoverBorder: 'red.4',
    }
  }

  if (isNext) {
    return {
      primary: 'teal.0',
      secondary: 'teal.1',
      bg: 'teal.6',
      border: 'teal.4',
      hoverPrimary: 'teal.0',
      hoverSecondary: 'teal.1',
      hoverBg: 'teal.5',
      hoverBorder: 'teal.4',
    }
  }

  return {
    primary: 'ink.0',
    secondary: 'ink.1',
    bg: 'white',
    border: 'sky.0',
    hoverPrimary: 'ink.0',
    hoverSecondary: 'ink.1',
    hoverBg: 'sky.3',
    hoverBorder: 'sky.0',
  }
}

function PaymentItem({ data, consumerGender, isNext }) {
  const [state, setState] = useState('closed')
  const { format } = useCurrency()
  const [ref, isVisible] = useIntersection({ once: true })

  const {
    scheduledAt,
    executedAt,
    amount,
    paidAmount,
    metadata,
    status,
    type,
    refundReason,
    retentionReason,
    refundedFeeAmount,
    lateRefundFeeAmount,
  } = data

  const executedBy = metadata?.triggered_by_role
  const overdueAmount = amount - paidAmount

  const hisHer = consumerGender === 'm' ? 'his' : 'her'

  const refundReasons = {
    mind_change: `Patient changed ${hisHer} mind`,
    lower_cost: 'Cost of treatment was lower than expected',
    dissatisfaction: 'Patient was unhappy with the treatment',
  }

  const {
    primary,
    secondary,
    bg,
    border,
    hoverPrimary,
    hoverSecondary,
    hoverBg,
    hoverBorder,
  } = getColors(data, state !== 'closed', isNext)

  const days = Math.ceil(moment(data.scheduledAt).diff(moment(), 'hours') / 24)

  return (
    <View
      ref={ref}
      _notLast={{ mb: 2 }}
      border={1}
      borderColor={border}
      _hover={{
        borderColor: hoverBorder,
      }}
      borderRadius={1}
      overflow="hidden"
      bg={bg}
      boxShadow={1}
    >
      <Flex
        role="group"
        p={3}
        _hover={{ bg: hoverBg }}
        cursor="pointer"
        transition="background 0.1s"
        onClick={() => setState(s => (s === 'closed' ? 'open' : 'closed'))}
      >
        <View display="flex">{getIcon(data, state !== 'closed', isNext)}</View>
        <Text
          fontSize={1}
          fontWeight="semibold"
          ml={2}
          color={primary}
          _groupHover={{ color: hoverPrimary }}
        >
          {moment(scheduledAt).format('DD MMM YYYY')}
          {isNext
            ? Math.abs(days) > 1
              ? ` (in ${days} days)`
              : ` (in ${days} day)`
            : ''}
        </Text>
        <Text
          fontSize={1}
          fontStyle="italic"
          color={secondary}
          _groupHover={{ color: hoverSecondary }}
          ml={2}
        >
          {getTitle(data)}
        </Text>
        <Flex flex={1} />

        {!!overdueAmount && overdueAmount !== amount && (
          <Text
            fontSize="14px"
            color={primary}
            _groupHover={{ color: hoverPrimary }}
            fontStyle="italic"
            textDecoration="line-through"
            mr={1}
          >
            {format(amount)}
          </Text>
        )}
        <Text
          fontSize="14px"
          fontWeight="semibold"
          color={primary}
          _groupHover={{ color: hoverPrimary }}
        >
          {format(Math.abs(overdueAmount || amount))}
        </Text>
      </Flex>
      {(['open'].includes(state) || isVisible) && (
        <AnimateHeight
          duration={200}
          height={['open'].includes(state) ? 'auto' : 0}
        >
          <View pt={1} pl={10} pr={3} pb={4}>
            <View>
              <Attributes
                sections={[
                  [
                    !['ref_con', 'ref_mer'].includes(type) && {
                      label: 'Scheduled for',
                      value: scheduledAt,
                      formattedValue: dateWithEllapsedDays(scheduledAt),
                    },
                    {
                      label: 'Executed on',
                      value: executedAt,
                      formattedValue: dateWithEllapsedDays(executedAt),
                    },
                    {
                      label: 'Executed by',
                      value: executedBy,
                      formattedValue:
                        executedBy?.charAt(0).toUpperCase() +
                        executedBy?.slice(1),
                    },
                  ],
                  ['failed', 'part_paid'].includes(status) && [
                    {
                      label: 'Amount due',
                      value: format(amount),
                    },
                    {
                      label: 'Amount paid',
                      value: format(paidAmount),
                    },
                  ],
                  ['ref_mer'].includes(type) && [
                    {
                      label: 'Fee refunded to merchant',
                      value: format(refundedFeeAmount),
                    },
                    {
                      label: 'Late refund fee',
                      value: format(lateRefundFeeAmount),
                    },
                  ],
                  ['ref_mer'].includes(type) && [
                    {
                      label: 'Reason for refund',
                      value: refundReason,
                      formattedValue: refundReasons[refundReason],
                    },
                    {
                      label: 'Reason why not full refund',
                      value: retentionReason,
                    },
                  ],
                ].filter(Boolean)}
              />
              {['ref_con'].includes(type) && (
                <Caption my={3}>
                  This will appear on the consumer’s statement within 5-10
                  business days.
                </Caption>
              )}
            </View>
          </View>
        </AnimateHeight>
      )}
    </View>
  )
}

PaymentItem.propTypes = {
  data: PropTypes.any,
  consumerGender: PropTypes.string,
  isNext: PropTypes.bool,
}

export default PaymentItem
