import React, { useContext } from 'react'
import ScarfProps from '../../../../types/ScarfProps'
import View from '../../../View'
import TicketContext from '../TicketContext'

function Highlight({ ...rest }: ScarfProps) {
  const { color, isResolved } = useContext(TicketContext)

  return (
    <View
      bg={isResolved ? 'transparent' : color}
      height="calc(100% + 2px)"
      width="5px"
      position="absolute"
      top="-1px"
      left="-1px"
      bottom="-1px"
      borderRadius="4px 0 0 4px"
      {...rest}
    />
  )
}

export default Highlight
