import { themeGet } from '@styled-system/theme-get'
import styled from 'styled-components'
import Text from '../../Text'

const Code = styled(Text)`
  font-family: ${themeGet('monospace')};
`

Code.defaultProps = {
  fontSize: 0,
  color: 'ink.2',
  bg: 'sky.2',
  px: 1,
  pb: '2px',
  borderRadius: 1,
  maxWidth: 'fit-content',
}

export default Code
