import { Component } from 'react'
import PropTypes from 'prop-types'

class Toggle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: !!props.defaultValue,
    }
  }

  render() {
    const { children } = this.props
    const { value } = this.state

    return children({
      value,
      toggle: () => this.setState(({ value }) => ({ value: !value })),
    })
  }
}

Toggle.propTypes = {
  children: PropTypes.func.isRequired,
  defaultValue: PropTypes.bool,
}

export default Toggle
