import { useTranslation } from 'react-i18next'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as XGray } from '../assets/x-gray.svg'

function Canceled() {
  const { t } = useTranslation()

  return (
    <ActionCard data-testid="cancelled-action-panel">
      <ActionCard.Header>
        <XGray className="h-6 w-6" />
        {t('Cancelled')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t('This payment was manually cancelled. No money has been collected.')}
      </ActionCard.Body>
    </ActionCard>
  )
}

export default Canceled
