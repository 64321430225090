import { pathToRegexp } from 'path-to-regexp'

export type Where = {
  and?: Where[]
  or?: Where[]
  in?: string[] | number[] | boolean[]
  equals?: string | number | boolean | null
  not?: Where
  some?: Where
  every?: Where
  none?: Where
  [key: string]:
    | Where
    | Where[]
    | string[]
    | number[]
    | boolean[]
    | string
    | number
    | boolean
    | undefined
    | null
}

type Data = any | ((value: any, op: 'equals' | 'in') => boolean)

export function check(where?: Where, data?: Data) {
  if (!where) {
    return false
  }

  const results: boolean[] = []

  Object.entries(where).forEach(([whereKey, whereValue]: [string, any]) => {
    if (whereKey === 'and') {
      return results.push(whereValue.every((w: any) => check(w, data)))
    }

    if (whereKey === 'or') {
      return results.push(whereValue.some((w: any) => check(w, data)))
    }

    if (whereKey === 'in') {
      if (typeof data === 'function') {
        return results.push(data(whereValue, 'in'))
      }

      return results.push(whereValue.includes(data))
    }

    if (whereKey === 'equals') {
      if (typeof data === 'function') {
        return results.push(data(whereValue, 'equals'))
      }

      return results.push(whereValue === data)
    }

    if (whereKey === 'not') {
      return results.push(!check(whereValue, data))
    }

    if (Array.isArray(data)) {
      if (whereKey === 'some') {
        return results.push(data.some((d: any) => check(whereValue, d)))
      }

      if (whereKey === 'every') {
        return results.push(data.every((d: any) => check(whereValue, d)))
      }

      if (whereKey === 'none') {
        return results.push(data.every((d: any) => !check(whereValue, d)))
      }
    }

    if (typeof whereValue === 'object') {
      if (Array.isArray(data)) {
        return results.push(check(whereValue, data))
      }

      return results.push(
        check(
          whereValue,
          typeof data === 'object' && data !== null && whereKey in data
            ? data[whereKey]
            : {}
        )
      )
    }
  })

  return results.every(Boolean)
}

export const path = (pathname: string) => (value: any, op: string) => {
  try {
    if (op === 'equals') {
      const regex = pathToRegexp(value)

      return !!regex.exec(pathname)
    }

    if (op === 'in') {
      return value.some((v: string) => {
        const regex = pathToRegexp(v)

        return !!regex.exec(pathname)
      })
    }
  } catch (error) {
    return false
  }

  return false
}
