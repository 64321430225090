import { View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import PaymentItem from './PaymentItem'

function getNormalizedPaymensFromPaymentPlan({ payments = [] }) {
  const { items } = payments.reduce(
    (mem, baseItem) => {
      const item = { ...baseItem }
      // Filter out ad_hoc payments when the amount is zero
      if (item.type === 'ad_hoc' && item.amount === 0) {
        return mem
      }

      // Handle payment indexes
      if (!['ref_mer', 'ref_con'].includes(item.type)) {
        item.index = mem.paymentIndex
        mem.paymentIndex += 1
      }

      mem.items.push(item)
      return mem
    },
    { items: [], paymentIndex: 0 }
  )
  return items
}

function Payments({ offer: { paymentPlan, consumerGender }, ...rest }) {
  if (!paymentPlan) {
    return null
  }
  const payments = getNormalizedPaymensFromPaymentPlan(paymentPlan)

  const nextPayment = payments.find(p => p.status === 'scheduled')

  return (
    <View {...rest}>
      {payments.map(payment => (
        <PaymentItem
          key={payment.id}
          consumerGender={consumerGender}
          data={payment}
          isNext={nextPayment?.id === payment.id}
        />
      ))}
    </View>
  )
}

Payments.propTypes = {
  offer: PropTypes.object.isRequired,
}

export default Payments
