import { Button, Flex, Panel, Prompt, Subheading, View } from '@tabeo/scarf'
import api from '@tabeo/sharpei/utils/api'
import { format } from '@tabeo/sharpei/utils/currency'
import AutoCopyText from 'components/AutoCopyText'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const PaymentOptions = ({
  data: {
    price,
    id: offerId,
    defaultRiskProfiles,
    isDeferredPaymentPlan,
    publicLink,
  },
}) => {
  const { t } = useTranslation()

  const options = useMemo(
    () =>
      defaultRiskProfiles
        ?.reduce(
          (acc, curr) => [
            ...acc,
            ...curr.loanOptions
              .filter(o => o.isAvailableForInitialPaymentPlan)
              .map(o => ({ ...o, riskProfile: curr })),
          ],
          []
        )
        .sort((a, b) => a.numberOfMonths - b.numberOfMonths),
    [defaultRiskProfiles]
  )
  const [paymentOptions, setPaymentOptions] = useState([])

  useEffect(() => {
    async function getAndSetPaymentOptions() {
      if (!options.length) {
        return
      }
      const payload = options.reduce((mem, item) => {
        mem[item.numberOfMonths] = {
          numberOfPayments: item.numberOfMonths,
          interestRate: item.interestRate,
          price,
          maxLoanAmount: price,
          firstPaymentAmount:
            item.riskProfile.name === 'tabeo_plus'
              ? parseInt(price * 0.2, 10)
              : undefined,
        }
        return mem
      }, {})

      const calculations = await api.post(`/utilities/amortize`, payload)

      const paymentPlans = options
        .map(i => ({
          ...calculations[i.numberOfMonths],
          value: i.numberOfMonths,
        }))
        .reverse()
      setPaymentOptions(paymentPlans)
    }
    getAndSetPaymentOptions()
  }, [options, price, setPaymentOptions])

  return (
    <Panel
      data-testid="payment-pannel"
      header={
        <>
          <Panel.Title>{t('Available payment options')}</Panel.Title>
          {!isDeferredPaymentPlan && (
            <Button
              as={Link}
              to={`/transactions/${offerId}/print`}
              sizeVariant="sm"
              variant="secondary"
            >
              {t('Print a copy')}
            </Button>
          )}
        </>
      }
    >
      <View bg="white">
        {paymentOptions.map((option, index) => (
          <Flex
            data-testid="payment-option"
            minHeight="50px"
            key={index}
            py={4}
            px={5}
            borderBottom={1}
          >
            <Prompt>
              {t('{{numberOfInvoices}} months, {{apr}}% APR', {
                numberOfInvoices: option.numberOfInvoices,
                apr: (option.apr * 100).toFixed(1),
              })}
            </Prompt>
            <Prompt>
              {t('{{amount}} per month', {
                amount: format(option.monthlyInvoicePaymentAmount),
              })}
            </Prompt>
          </Flex>
        ))}
        <View px={5} py={3}>
          <Subheading color="ink.2" mb={1}>
            {t('Application link')}
          </Subheading>
          <AutoCopyText value={publicLink} />
        </View>
      </View>
    </Panel>
  )
}

PaymentOptions.propTypes = {
  data: PropTypes.object,
}

export default PaymentOptions
