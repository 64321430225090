import { DataTable, GridTable, Title } from '@tabeo/scarf'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import Notifications from 'components/Notifications'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useBills } from 'resources/Bills'
import ListItem from './ListItem'

const Bills = () => {
  const { t } = useTranslation()

  const tabs = [
    { label: t('All'), statuses: [], group: 'a' },
    {
      label: t('Follow up'),
      statuses: ['new', 'reviewed'],
      group: 'b',
    },
    {
      label: t('Scheduled'),
      statuses: ['payment_source_assigned'],
      group: 'b',
    },
    { label: t('Card declined'), statuses: ['overdue'], group: 'b' },
    {
      label: t('Won'),
      statuses: [
        'paid',
        'merchant_proceeds_transferred',
        'merchant_payout_processed',
      ],
      group: 'c',
    },
    { label: t('Lost'), statuses: ['canceled'], group: 'c' },
  ]

  return (
    <LayoutSideNavbar>
      <Container>
        <Notifications mb={6} />
        <Title mb={2}>{t('Bills')}</Title>
        <DataTable
          resourceHook={useBills}
          tabs={tabs}
          paginationLimit={20}
          searchPlaceholder={t(`Search a bill by customer name or by ID`)}
        >
          {({ items }) => (
            <GridTable
              templateColumns={[
                'minmax(65px,auto) minmax(140px, 1fr) minmax(100px, auto) auto auto',
              ]}
              minWidth="650px"
            >
              {items.map(item => (
                <ListItem key={item.id} data={item} />
              ))}
            </GridTable>
          )}
        </DataTable>
      </Container>
    </LayoutSideNavbar>
  )
}

Bills.propTypes = {}

export default Bills
