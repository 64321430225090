import React from 'react'
import Svg, { Path, SvgProps } from './Base'

function Mail({ fill, ...rest }: SvgProps) {
  return (
    <Svg viewBox="0 0 25 24" {...rest}>
      <g transform="translate(.5)" fill="none" fillRule="evenodd">
        <Path
          stroke="none"
          fill={fill}
          d="M18.429 6H5.57C4.151 6 3 7.075 3 8.4v7.2C3 16.925 4.151 18 5.571 18H18.43c1.419 0 2.57-1.075 2.57-2.4V8.4C21 7.075 19.849 6 18.429 6zm0 1.2c.265.001.523.079.739.222l-6.429 4.014a1.363 1.363 0 01-1.427 0L4.884 7.422c.201-.134.44-.211.687-.222H18.43zm-7.856 5.232c.864.538 1.99.538 2.854 0l.392-.246L18.21 16.8H5.79l4.39-4.614.393.246zM4.286 8.52l4.815 3-4.565 4.8a1.148 1.148 0 01-.25-.72V8.52zm15.178 7.8l-4.565-4.8 4.815-3v7.08c0 .255-.088.503-.25.708v.012z"
        />
      </g>
    </Svg>
  )
}

export default Mail
