import { HTMLAttributes } from 'react'
import amexSvg from './assets/amex.svg'
import applePaySvg from './assets/apple-pay.svg'
import googlePaySvg from './assets/google-pay.svg'
import mastercardSvg from './assets/mastercard.svg'
import visaSvg from './assets/visa.svg'

type CardLogosProps = {
  visa?: boolean
  mastercard?: boolean
  amex?: boolean
  applePay?: boolean
  googlePay?: boolean
} & HTMLAttributes<HTMLDivElement>

function ImgWithOverlay(props: JSX.IntrinsicElements['img']) {
  return (
    <div className="relative overflow-hidden rounded">
      <div className="absolute inset-0 bg-white opacity-0 group-aria-disabled:opacity-75" />
      <img {...props} />
    </div>
  )
}

function CardLogos({
  visa = true,
  mastercard = true,
  amex = false,
  applePay = true,
  googlePay = true,
  ...rest
}: CardLogosProps) {
  return (
    <div className="flex gap-1" {...rest}>
      {visa && <ImgWithOverlay src={visaSvg} alt="visa" />}
      {mastercard && <ImgWithOverlay src={mastercardSvg} alt="mastercard" />}
      {applePay && <ImgWithOverlay src={applePaySvg} alt="apple pay" />}
      {googlePay && <ImgWithOverlay src={googlePaySvg} alt="google pay" />}
      {amex && <ImgWithOverlay src={amexSvg} alt="amex" />}
    </div>
  )
}

export default CardLogos
