import { Flex, Icons, Select, Text } from '@tabeo/scarf'
import Input from '@tabeo/scarf/dist/components/Form/Input'
import TreatmentOption from 'pages/Settings/Treatments/Modal/TreatmentOption'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useMerchant } from 'resources/Merchant'

export const OTHER_VALUES = [
  'Other',
  'Andere',
  'Otro',
  'Autre',
  'Altro',
  'Overige',
]

export const treatmentSort = (a: any, b: any) => {
  if (OTHER_VALUES.includes(a.treatment.name)) {
    return 1
  }

  if (OTHER_VALUES.includes(b.treatment.name)) {
    return -1
  }

  if (a.usage === b.usage) {
    return a.treatment.name.localeCompare(b.treatment.name)
  }

  return b.usage - a.usage
}

type MerchantTreatmentSelectProps = {
  input?: Input
} & React.ComponentProps<typeof Select>

const MerchantTreatmentSelect = React.forwardRef<
  any,
  MerchantTreatmentSelectProps
>(({ input, ...rest }, ref) => {
  const { data } = useMerchant()
  const { t } = useTranslation()
  const history = useHistory()

  if (!data) return null

  const { merchantTreatments } = data

  return (
    <Select
      ref={ref}
      loadOptions={async (q: string) => {
        const options = (
          q
            ? merchantTreatments
                .sort(treatmentSort)
                .filter(({ treatment: { name } }: any) =>
                  name.toLowerCase().includes(q.toLowerCase())
                )
            : merchantTreatments.sort(treatmentSort)
        ).map((mt: any) => ({
          value: mt,
        }))

        return { options }
      }}
      valueRenderer={({ treatment: t }: any) => t.name}
      // @ts-ignore
      optionComponent={TreatmentOption}
      autoload
      clearable
      clearRenderer={() => (
        <Icons.Clear mr={2} height="20px" width="20px" fill="ink.3" />
      )}
      placeholder={
        <Flex justifyContent="flex-start">
          <Icons.Search fill="#919EAB" mr={2} ml="-2px" />
          <Text color="ink.2">{t('Select from the list…')}</Text>
        </Flex>
      }
      selectMenuStyle={{
        maxHeight: '352px',
      }}
      {...rest}
      input={{
        ...input,
        onChange: (v: any) => {
          if (v === 'new') {
            history.push('/settings?tab=treatments&modal=new-treatment')
            return
          }

          return input.onChange(v)
        },
      }}
    />
  )
})

export default MerchantTreatmentSelect
