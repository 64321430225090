import {
  Button,
  CurrencyInput,
  Heading,
  Modal,
  Prompt,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import formError from '@tabeo/sharpei/utils/formError'
import { required, valueRange } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import LinkPaymentModal from 'components/LinkPayment/Modal/Modal'
import config from 'config'
import { useMemo, useRef } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'

const { CURRENCY } = config

const defaultInitialValues = {
  currency: CURRENCY,
}

export type LinkPaymentFormProps = {
  initialValues?: any
  onSubmit: (values: any) => any
  onFormChange: (change: any) => void
} & React.ComponentProps<typeof View>

const LinkPaymentForm = ({
  onFormChange,
  onSubmit,
  initialValues,
  ...rest
}: LinkPaymentFormProps) => {
  const { t } = useTranslation()

  const modal = useRef<Modal>()

  const handleSubmit = async (values: any) => {
    try {
      const payload = {
        amount: values.price,
        currency: values.currency,
      }
      const linkPayment = await onSubmit(payload)

      modal.current?.open({ id: linkPayment.id })
    } catch (e) {
      return formError(e)
    }
  }

  const priceValidator = valueRange(
    1_00,
    10_000_00,
    t(`Please specify an amount between {{min}} and {{max}}`, {
      min: format(1_00),
      max: format(10_000_00),
    })
  )

  // When intialValues are not memoized, the form is reinitialized on every render
  // and this causes an infinite loop for some reason. This is a workaround while NNTS is not ready.
  const memoizedInitialValues = useMemo(
    () => ({
      ...initialValues,
      ...defaultInitialValues,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(initialValues)]
  )

  return (
    <View bg="white" borderRadius="0 0 4px 4px" {...rest}>
      <LinkPaymentModal ref={modal} />
      <Form
        onSubmit={handleSubmit}
        initialValues={memoizedInitialValues}
        mutators={{
          setFieldValue: (args, state, tools) => {
            const [field, value] = args
            tools.changeValue(state, field, () => value)
          },
        }}
        destroyOnUnregister
      >
        {({
          handleSubmit,
          form: { reset, resetFieldState, getRegisteredFields },
          submitting,
          valid,
          validating,
        }) => {
          return (
            <form
              onSubmit={async e => {
                const error = await handleSubmit(e)
                if (!error && valid) {
                  reset()

                  // https://github.com/final-form/final-form/issues/317
                  // reset() doesn't clean some metadata
                  getRegisteredFields().forEach(field => {
                    // Handle conditional fields
                    if (getRegisteredFields().includes(field)) {
                      resetFieldState(field)
                    }
                  })
                }
              }}
            >
              <FormSpy
                subscription={{ values: true }}
                onChange={change => {
                  onFormChange({
                    ...change,
                  })
                }}
              />
              <View p={[2, 7]} pb={[2, 6]}>
                <Heading fontWeight="semibold" mb={5}>
                  {t('Transaction')}
                </Heading>

                <Field
                  name="price"
                  clearable
                  maxWidth="100%"
                  label={
                    <Prompt mb={1} fontWeight="semibold">
                      <Trans>
                        Price{' '}
                        <Prompt display="inline" color="ink.2">
                          (including VAT)
                        </Prompt>
                      </Trans>
                    </Prompt>
                  }
                  // @ts-ignore
                  component={CurrencyInput}
                  validate={[required, priceValidator]}
                />

                <Button width="100%" loading={submitting} disabled={validating}>
                  {t('Generate payment link')}
                </Button>
                <FormError />
              </View>
            </form>
          )
        }}
      </Form>
    </View>
  )
}

export default LinkPaymentForm
