import React, { useRef } from 'react'
import styled from 'styled-components'
import { Merge } from '../../../types/helpers'
import ScarfProps from '../../../types/ScarfProps'
import Flex from '../../Flex'
import * as Icons from '../../Icons'
import View from '../../View'
import mapFormToProps from '../mapFormToProps'
import Placeholder, { PlaceholderProps } from '../Placeholder'
import FileComponent, { FileItem, FileProps } from './File'

const HtmlFileInput = styled(View)<ScarfProps<'input'>>`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

HtmlFileInput.defaultProps = { as: 'input', type: 'file' }

const Container = styled(Flex)`
  :focus {
    outline: none;
  }
`

Container.defaultProps = {
  display: 'inline-flex',
  cursor: 'pointer',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
}

export type FileUploadProps = Merge<
  ScarfProps,
  {
    value: (File | FileItem)[]
    invalid?: PlaceholderProps['invalid']
    upload: FileProps['upload']
    accept?: string
    renderItem: FileProps['children']
    onChange: (files: (File | FileItem)[]) => void
  }
>

function FileUpload({
  onChange,
  value = [],
  invalid,
  onFocus,
  onBlur,
  upload,
  accept,
  renderItem,
  ...rest
}: FileUploadProps) {
  const ref = useRef({
    id: new Date().getTime() + Math.floor(Math.random() * 100),
  })

  const handleSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0]

    if (file) {
      onChange([...value, file])
    }
  }

  const handleChange = (file: FileItem | null, index: number) => {
    onFocus && onFocus()

    if (file) {
      onChange([...value.slice(0, index), file, ...value.slice(index + 1)])
    } else {
      onChange([...value.slice(0, index), ...value.slice(index + 1)])
    }

    onBlur && onBlur()
  }

  return (
    <Container maxWidth="100%" {...rest}>
      {!!value.length &&
        value.map((file, i) => (
          <FileComponent
            key={(file as FileItem)?.id || file.name}
            value={file}
            onChange={(v) => handleChange(v, i)}
            upload={upload}
            spacing={{
              mr: 2,
              mb: 2,
            }}
          >
            {renderItem}
          </FileComponent>
        ))}
      <HtmlFileInput
        id={`file-${ref.current.id}`}
        onChange={handleSelect}
        accept={accept}
      />
      <Placeholder
        as="label"
        // @ts-ignore
        htmlFor={`file-${ref.current.id}`}
        icon={(props) => <Icons.Clip {...props} />}
        cursor="pointer"
        invalid={invalid}
        mb={2}
        _hover={{
          bg: 'sky.3',
        }}
      >
        Upload document
      </Placeholder>
    </Container>
  )
}

export default mapFormToProps(FileUpload)
