'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var uuid = require('./uuid.js');

function getFingerprint() {
    var fingerprint = localStorage.getItem('fingerprint');
    if (!fingerprint) {
        fingerprint = uuid.default();
        localStorage.setItem('fingerprint', fingerprint);
    }
    return fingerprint;
}

exports.default = getFingerprint;
