import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'

function SmartLink({ href, children }: { href: string; children: ReactNode }) {
  if (href?.startsWith('http')) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )
  }

  return <Link to={href}>{children}</Link>
}

export default SmartLink
