import { Button, Caption, Icons, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

function RecordNotFound({
  title,
  subtitle,
  buttonText,
  buttonTo,
  buttonProps = {
    as: Link,
    to: buttonTo,
    leftIcon: p => <Icons.Plus light {...p} />,
  },
}) {
  return (
    <View
      bg="sky.3"
      textAlign="center"
      mb={5}
      p={6}
      border={1}
      borderRadius={1}
      borderStyle="dashed"
    >
      <Caption color="ink.1" fontWeight="semibold" mb={2}>
        {title}
      </Caption>
      <Caption mb={4}>{subtitle}</Caption>
      <Button variant="secondary" sizeVariant="sm" {...buttonProps}>
        {buttonText}
      </Button>
    </View>
  )
}

RecordNotFound.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  buttonTo: PropTypes.string,
  buttonProps: PropTypes.object,
}

export default RecordNotFound
