import { Button } from '@tabeo/scarf'
import useCountry from 'hooks/useCountry'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useOffer } from 'resources/Offer'

function InvoiceButton({ ...rest }) {
  const { data } = useOffer()
  const { id, confirmedDate, isVoided, countryCode } = data

  const { t } = useTranslation()
  const country = useCountry(countryCode)

  if (!country.isUK || !confirmedDate || isVoided) {
    return null
  }

  return (
    <Button
      variant="secondary"
      sizeVariant="sm"
      as="a"
      href={`/transactions/${id}/invoice`}
      target="_blank"
      rel="noopener noreferrer"
      width={['100%', 'auto']}
      {...rest}
    >
      {t('View invoice')}
    </Button>
  )
}

export default InvoiceButton
