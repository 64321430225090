import { Flex, Prompt, Subtitle, Title, View } from '@tabeo/scarf'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Header = ({
  consumerFirstName,
  consumerLastName,
  title,
  tag,
  reference,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <View {...rest}>
      <Flex flexDirection={['column', 'row']} mb={[reference ? 3 : 0, 0]}>
        <Flex
          flexWrap="wrap"
          alignItems="baseline"
          mb={[2, 0]}
          justifyContent={['center', 'space-between']}
        >
          <Title mr={2}>
            {consumerFirstName} {consumerLastName}
          </Title>
          <Subtitle textAlign={['center', 'left']}>{title}</Subtitle>
        </Flex>
        {tag}
      </Flex>
      {reference && (
        <Prompt textAlign={['center', 'left']}>
          {t('Case #{{reference}}', { reference })}
        </Prompt>
      )}
    </View>
  )
}

Header.propTypes = {
  consumerFirstName: PropTypes.string.isRequired,
  consumerLastName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tag: PropTypes.element.isRequired,
  reference: PropTypes.string,
}
export default Header
