import { useMobile } from '@tabeo/scarf'
import { Step } from '../types'
import { InvoiceStepper } from './invoice-stepper'
import { useNewTransactionFlow } from './provider'

function Stepper({ onNavigate }: { onNavigate?: (step: Step) => void }) {
  const { steps, currentStep, flowSteps, setCurrentStep, state } =
    useNewTransactionFlow()
  const isMobile = useMobile()

  return (
    <>
      {
        // Since invoices are not yet part of the flow, we need to display them separately
        !!state?.invoices?.references.length && (
          <InvoiceStepper>
            {state.invoices.references.join(', ')}
          </InvoiceStepper>
        )
      }

      {steps.map(step => {
        const stepIndex = steps.indexOf(step)

        const isLinkable =
          currentStep &&
          (isMobile
            ? stepIndex <= steps.indexOf(currentStep)
            : stepIndex < steps.indexOf(currentStep))
        const Component = isLinkable ? 'a' : 'span'

        const Stepper = flowSteps[stepIndex]?.stepper

        if (!Stepper) {
          return null
        }

        return (
          <Component
            className="block"
            key={step}
            onClick={() => {
              // Go to step if it is before the current step
              if (isLinkable) {
                setCurrentStep(step)
                onNavigate?.(step)
              }
            }}
          >
            <Stepper editable={isLinkable} active={step === currentStep} />
          </Component>
        )
      })}
    </>
  )
}

export default Stepper
