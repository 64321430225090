import React, { useContext } from 'react'
import ScarfProps from '../../../../types/ScarfProps'
import Text from '../../../Text'
import TicketContext from '../TicketContext'

function Title({ ...rest }: ScarfProps) {
  const { isResolved } = useContext(TicketContext)

  return (
    <Text
      fontWeight="normal"
      color={isResolved ? 'ink.2' : undefined}
      {...rest}
    />
  )
}

export default Title
