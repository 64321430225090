import React from 'react'
import PropTypes from 'prop-types'

import { View, Flex, Icons, Text } from '@tabeo/scarf'
import styled from 'styled-components'

const { X } = Icons

const Name = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    color: currentColor;
  }
`

Name.defaultProps = {
  fontSize: ['16px', '14px'],
  color: 'default.primary',
  flex: '1 1 auto',
  fontWeight: 'regular',
  px: 3,
  cursor: 'pointer',
  as: 'a',
  target: '_blank',
}

const Wrap = styled(Flex)`
  overflow: hidden;
  background-image: linear-gradient(to bottom, #f9fafb, #f4f6f8);
`

const Filepreview = ({ name, url, onRemove, ...rest }) => (
  <Wrap
    border={1}
    borderRadius={1}
    boxShadow={2}
    height={['53px', '42px']}
    maxWidth={['100%', '225px']}
    {...rest}
  >
    <View
      borderRight={1}
      bg="white"
      flex="0 0 auto"
      display="inline-flex"
      p={2}
    >
      <View
        as="svg"
        width={['42px', '31px']}
        height={['30px', '22px']}
        viewBox="0 0 31 22"
      >
        <path
          fill="#C4CDD5"
          fillRule="nonzero"
          d="M2.054 1.119a.933.933 0 0 0-.934.932v17.898c0 .515.419.932.934.932h26.892a.933.933 0 0 0 .934-.932V2.051a.933.933 0 0 0-.934-.932H2.054zm0-1.119h26.892C30.08 0 31 .918 31 2.05v17.9c0 1.132-.92 2.05-2.054 2.05H2.054A2.053 2.053 0 0 1 0 19.95V2.05C0 .919.92 0 2.054 0zm15.8 14.316l4.075-2.745a1.309 1.309 0 0 1 1.46-.001l7.363 4.94-.625.93-7.363-4.942a.187.187 0 0 0-.209 0l-4.723 3.182-6.896-4.963a.187.187 0 0 0-.206-.007L.853 16.767l-.586-.953 9.876-6.057a1.309 1.309 0 0 1 1.449.053l6.261 4.506zm.634-4.994a2.799 2.799 0 0 1-2.801-2.797 2.799 2.799 0 0 1 2.801-2.796 2.799 2.799 0 0 1 2.801 2.796 2.799 2.799 0 0 1-2.801 2.797zm0-1.119a1.68 1.68 0 0 0 1.68-1.678 1.68 1.68 0 0 0-1.68-1.678 1.68 1.68 0 0 0-1.68 1.678 1.68 1.68 0 0 0 1.68 1.678z"
        />
      </View>
    </View>
    <Name href={url}>{name}</Name>
    {onRemove && (
      <View flex="0 0 auto" onClick={onRemove} height="22px" cursor="pointer">
        <X fill="ink.2" width="22px" height="22px" mx={2} />
      </View>
    )}
  </Wrap>
)

Filepreview.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  onRemove: PropTypes.func,
}

export default Filepreview
