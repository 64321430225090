import React from 'react'
import ScarfProps from '../../types/ScarfProps'
import Flex from '../Flex'
import { useDataTable } from './context'

export type SelectionActionsProps = ScarfProps

function SelectionActions(props: SelectionActionsProps) {
  const { state } = useDataTable()

  if (!state.selected?.length) {
    return null
  }

  return (
    <Flex
      position={['relatice', 'absolute']}
      height={['auto', '52px']}
      mb={[4, 0]}
      px={[4, 2]}
      bg="white"
      width="100%"
      zIndex="20"
      {...props}
    />
  )
}

export default SelectionActions
