export const defaultPaymentOptions = [3, 6, 9, 10, 12].map(o => ({
  numberOfMonths: o,
  interestRate: 0,
  rebateDays: 0,
}))

export const paymentOptionsPerRiskProfile = {
  default: [
    {
      numberOfMonths: 3,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.05,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 6,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.06,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 9,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.0725,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 10,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.077,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 12,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.085,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 18,
      interestRate: 0.0,
      isRegulated: true,
      netFee: 0.11,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.0,
      isRegulated: true,
      netFee: 0.134,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 36,
      interestRate: 0.0,
      isRegulated: true,
      netFee: 0.186,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 48,
      interestRate: 0.0,
      isRegulated: true,
      netFee: 0.235,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 60,
      interestRate: 0.0,
      isRegulated: true,
      netFee: 0.282,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 12,
      interestRate: 0.059,
      isRegulated: true,
      netFee: 0.062,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 18,
      interestRate: 0.059,
      isRegulated: true,
      netFee: 0.0735,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.059,
      isRegulated: true,
      netFee: 0.085,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 36,
      interestRate: 0.059,
      isRegulated: true,
      netFee: 0.114,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 48,
      interestRate: 0.059,
      isRegulated: true,
      netFee: 0.145,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 60,
      interestRate: 0.059,
      isRegulated: true,
      netFee: 0.174,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.079,
      isRegulated: true,
      netFee: 0.068,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 36,
      interestRate: 0.079,
      isRegulated: true,
      netFee: 0.086,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 48,
      interestRate: 0.079,
      isRegulated: true,
      netFee: 0.11,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 60,
      interestRate: 0.079,
      isRegulated: true,
      netFee: 0.12,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 12,
      interestRate: 0.099,
      isRegulated: true,
      netFee: 0.045,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 18,
      interestRate: 0.099,
      isRegulated: true,
      netFee: 0.048,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.099,
      isRegulated: true,
      netFee: 0.052,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 26,
      interestRate: 0.099,
      isRegulated: true,
      netFee: 0.067,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 36,
      interestRate: 0.099,
      isRegulated: true,
      netFee: 0.063,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 48,
      interestRate: 0.099,
      isRegulated: true,
      netFee: 0.076,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 60,
      interestRate: 0.099,
      isRegulated: true,
      netFee: 0.097,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.109,
      isRegulated: true,
      netFee: 0.045,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 36,
      interestRate: 0.109,
      isRegulated: true,
      netFee: 0.053,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 48,
      interestRate: 0.109,
      isRegulated: true,
      netFee: 0.067,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 60,
      interestRate: 0.109,
      isRegulated: true,
      netFee: 0.081,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.119,
      isRegulated: true,
      netFee: 0.035,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 36,
      interestRate: 0.119,
      isRegulated: true,
      netFee: 0.04,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 48,
      interestRate: 0.119,
      isRegulated: true,
      netFee: 0.045,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 60,
      interestRate: 0.119,
      isRegulated: true,
      netFee: 0.05,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.129,
      isRegulated: true,
      netFee: 0.025,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 36,
      interestRate: 0.129,
      isRegulated: true,
      netFee: 0.03,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 48,
      interestRate: 0.129,
      isRegulated: true,
      netFee: 0.035,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 60,
      interestRate: 0.129,
      isRegulated: true,
      netFee: 0.04,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.149,
      isRegulated: true,
      netFee: 0.015,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 36,
      interestRate: 0.149,
      isRegulated: true,
      netFee: 0.005,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 48,
      interestRate: 0.149,
      isRegulated: true,
      netFee: 0.02,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 60,
      interestRate: 0.149,
      isRegulated: true,
      netFee: 0.03,
      rebateDays: 0,
      vat: 0,
    },
  ],
  tabeo_plus: [
    {
      numberOfMonths: 3,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.029,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 6,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.039,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 10,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.054,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 12,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.059,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 18,
      interestRate: 0.0,
      isRegulated: true,
      netFee: 0.069,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.0,
      isRegulated: true,
      netFee: 0.089,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 36,
      interestRate: 0.0,
      isRegulated: true,
      netFee: 0.109,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 12,
      interestRate: 0.099,
      isRegulated: true,
      netFee: 0.019,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 18,
      interestRate: 0.099,
      isRegulated: true,
      netFee: 0.019,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.099,
      isRegulated: true,
      netFee: 0.019,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 36,
      interestRate: 0.099,
      isRegulated: true,
      netFee: 0.019,
      rebateDays: 0,
      vat: 0,
    },
  ],
  profile_sdc_unfunded: [
    {
      numberOfMonths: 12,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.03,
      rebateDays: 0,
      vat: 0.2,
    },
  ],
  diamondwhites_unfunded: [
    {
      numberOfMonths: 12,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.03,
      rebateDays: 0,
      vat: 0.2,
    },
  ],
  uk_pl_unfunded: [
    {
      numberOfMonths: 12,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.03,
      rebateDays: 0,
      vat: 0.2,
    },
  ],
  smilewhites_unfunded: [
    {
      numberOfMonths: 3,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.03,
      rebateDays: 0,
      vat: 0.2,
    },
  ],
  impress_unfunded: [
    {
      numberOfMonths: 12,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.03,
      rebateDays: 0,
      vat: 0.2,
    },
  ],
  vinci_unfunded: [
    {
      numberOfMonths: 12,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.03,
      rebateDays: 0,
      vat: 0.2,
    },
  ],
  plusdent_unfunded: [
    {
      numberOfMonths: 12,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.03,
      rebateDays: 0,
      vat: 0.2,
    },
  ],
  nl_collect: [
    {
      numberOfMonths: 4,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.03,
      rebateDays: 0,
      vat: 0.21,
    },
  ],
  nl_ifinance: [
    {
      numberOfMonths: 12,
      interestRate: 0.099,
      isRegulated: true,
      netFee: 0.019,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.099,
      isRegulated: false,
      netFee: 0.019,
      rebateDays: 0,
      vat: 0,
    },
  ],
  it_core: [
    {
      numberOfMonths: 12,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.069,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.109,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 36,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.149,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 48,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.189,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 60,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.229,
      rebateDays: 0,
      vat: 0,
    },
  ],
  fr_core: [
    {
      numberOfMonths: 12,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.069,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.109,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 36,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.149,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 48,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.189,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 60,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.229,
      rebateDays: 0,
      vat: 0,
    },
  ],
  fr_sdc_core: [
    {
      numberOfMonths: 12,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.069,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.109,
      rebateDays: 0,
      vat: 0,
    },
  ],
  fr_collect: [
    {
      numberOfMonths: 12,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.03,
      rebateDays: 0,
      vat: 0.2,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.03,
      rebateDays: 0,
      vat: 0.2,
    },
  ],
  fr_sdc_collect: [
    {
      numberOfMonths: 12,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.03,
      rebateDays: 0,
      vat: 0.2,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.03,
      rebateDays: 0,
      vat: 0.2,
    },
  ],
  it_collect: [
    {
      numberOfMonths: 12,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.03,
      rebateDays: 0,
      vat: 0.22,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.03,
      rebateDays: 0,
      vat: 0.22,
    },
  ],
  in_house: [
    {
      numberOfMonths: 3,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.02,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 6,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.02,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 9,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.02,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 10,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.02,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 12,
      interestRate: 0.0,
      isRegulated: false,
      netFee: 0.02,
      rebateDays: 0,
      vat: 0,
    },
  ],
  near_prime: [
    {
      numberOfMonths: 3,
      interestRate: 0,
      isRegulated: false,
      netFee: 0.05,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 12,
      interestRate: 0,
      isRegulated: false,
      netFee: 0.12,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 12,
      interestRate: 0.099,
      isRegulated: true,
      netFee: 0.09,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 18,
      interestRate: 0.099,
      isRegulated: true,
      netFee: 0.12,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.099,
      isRegulated: true,
      netFee: 0.15,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 24,
      interestRate: 0.199,
      isRegulated: true,
      netFee: 0.09,
      rebateDays: 0,
      vat: 0,
    },
    {
      numberOfMonths: 36,
      interestRate: 0.199,
      isRegulated: true,
      netFee: 0.12,
      rebateDays: 0,
      vat: 0,
    },
  ],
}

export const feeCalculator = (riskProfileName, paymentOption, price = 0) => {
  const { numberOfMonths, interestRate = 0, rebateDays = 0 } = paymentOption
  const option = (
    paymentOptionsPerRiskProfile[riskProfileName] ||
    paymentOptionsPerRiskProfile.default
  ).find(
    f =>
      f.numberOfMonths === numberOfMonths &&
      f.interestRate === interestRate &&
      f.rebateDays === rebateDays
  ) || { numberOfMonths, interestRate, netFee: 0, rebateDays: 0, vat: 0 }
  option.fee = option.netFee * (1 + option.vat)
  option.netFeeAmount = +(option.netFee * price).toFixed(2)
  option.feeAmount = +(option.fee * price).toFixed(2)
  return option
}

export const getVisibleNearPrimeProfile = (
  loanOption,
  nearPrimeProfileOptions
) => {
  // With the Near Prime Risk Profile, vendors can only have a max. of 2 terms enabled
  // and one of them must be the 12 month option.
  let visibleNearPrimeOption
  const twelveMonthNearPrimeOption = nearPrimeProfileOptions.find(
    o => o.numberOfMonths === 12
  )
  const anyOtherNearPrimeOption = nearPrimeProfileOptions.find(
    o => o.numberOfMonths !== 12
  )

  // If only 1 options is enabled:
  // Anyone applying for [Prime] any option will be offered the [NEAR-PRIME] option they have enabled.
  if (nearPrimeProfileOptions.length === 1) {
    visibleNearPrimeOption = nearPrimeProfileOptions?.[0]

    // If 2 options are enabled:
    // Anyone applying for [PRIME] < =12 months 0% APR will be offered [NEAR-PRIME 12 months]
    // All other [Prime] options will be offered the other [NEAR-PRIME] enabled option.
  } else if (
    nearPrimeProfileOptions.length === 2 &&
    twelveMonthNearPrimeOption
  ) {
    const isSelectedOption12Months = loanOption?.numberOfMonths === 12
    const isSelectedOption0Interest = loanOption?.interestRate === 0
    const isSelectedOptionLessThan12Months = loanOption?.numberOfMonths < 12

    const isSelectedOption12MonthsAnd0Interest =
      isSelectedOption12Months && isSelectedOption0Interest

    visibleNearPrimeOption =
      isSelectedOption12MonthsAnd0Interest || isSelectedOptionLessThan12Months
        ? twelveMonthNearPrimeOption
        : anyOtherNearPrimeOption
  }

  return visibleNearPrimeOption
}
