import React from 'react'

export type TicketContextType = {
  color?: string
  isResolved?: boolean
  isStarHidden?: boolean
  isDeleteHidden?: boolean
}

const TicketContext = React.createContext<TicketContextType>({})

export default TicketContext
