import { Caption, DataTable, GridTable, Title } from '@tabeo/scarf'
import AmountFilter from 'components/AmountFilter'
import DateFilter from 'components/DateFilter'
import DatoNotifications from 'components/Notifications/DatoNotifications'
import PricingChanges from 'components/PricingChanges'
import TermsUpdate from 'components/TermsUpdate'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { usePayouts } from '../../resources/Payouts'
import ListItem from './ListItem'
import { mapStateToParams } from './utils'

const Payouts = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const filters = [
    {
      name: 'date',
      label: t('Date'),
      initialValue: '',
      component: DateFilter,
      daypickerProps: {
        modifiers: {
          disabled: {
            after: moment().toDate(),
          },
        },
      },
    },
    {
      name: 'amount',
      label: t('Amount'),
      initialValue: {
        min: '',
        max: '',
      },
      component: AmountFilter,
    },
  ]

  return (
    <LayoutSideNavbar>
      <Container>
        <DatoNotifications />
        <PricingChanges />
        <TermsUpdate />
        <Title mb={2}>{t('Payouts')}</Title>
        <DataTable
          // @ts-ignore
          resourceHook={usePayouts}
          paginationLimit={20}
          searchPlaceholder={t(`Search by payout ID ...`)}
          filters={filters}
          mapStateToParams={mapStateToParams}
          renderEmpty={() => (
            <Caption p={5} textAlign="center">
              {t('There are no payouts yet.')}
            </Caption>
          )}
          data-testid="payouts-table"
        >
          {({ items }) => (
            <GridTable
              templateColumns={[
                'auto minmax(140px, auto) 1fr minmax(240px, auto)',
              ]}
            >
              <DataTable.Head
                columns={[
                  {
                    label: t('Date'),
                    orderBy: 'transactionAt',
                    // cellProps: {
                    //   justifyContent: 'flex-end',
                    // },
                  },
                  {
                    label: t('Amount'),
                    // orderBy: 'amount',
                    cellProps: {
                      justifyContent: 'flex-end',
                    },
                  },
                  { label: t('Sender') },
                  { label: t('ID') },
                ]}
              />
              {items.map(item => (
                <ListItem
                  key={item.id}
                  data={item}
                  onClick={() => history.push(`/payouts/${item.id}`)}
                  cursor="pointer"
                  _hover={{
                    // @ts-ignore
                    '> *': {
                      bg: [null, 'sky.3'],
                      cursor: 'pointer',
                    },
                  }}
                  data-testid="payout-item"
                />
              ))}
            </GridTable>
          )}
        </DataTable>
      </Container>
    </LayoutSideNavbar>
  )
}

Payouts.propTypes = {}

export default Payouts
