import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import { PaymentRefund as PR } from '@tabeo/ts-types'

interface Params {
  id: string | number
}

const subResources = formatSubresources([
  'requester',
  'approver',
  'decliner',
  'canceler',
])

class Refund extends Resource<PR, Params> {
  async fetch() {
    const { refund } = await api.get(
      `/merchant/refunds/${this.params.id}?${subResources}`
    )

    this.data = {
      ...refund,
    }

    return this.data
  }

  approve = async (note?: string) => {
    const response = await api.put(
      `/merchant/refunds/${this.params.id}/approve`,
      {
        approvalNote: note,
      }
    )

    await this.fetch()
    return response
  }

  decline = async (note?: string) => {
    const response = await api.put(
      `/merchant/refunds/${this.params.id}/decline`,
      {
        declineNote: note,
      }
    )

    await this.fetch()
    return response
  }

  cancel = async () => {
    const response = await api.put(`/merchant/refunds/${this.params.id}/cancel`)

    await this.fetch()
    return response
  }

  static cancel = async (id?: number) => {
    const response = await api.put(`/merchant/refunds/${id}/cancel`)
    return response
  }
}

export default Refund
export const useRefund = createResourceHook(Refund)
