import ActionCard from 'components/ActionPanel/ActionCard'
import icon from 'components/ActionPanel/assets/approved-blue.svg'
import { useTranslation } from 'react-i18next'

function Succeeded() {
  const { t } = useTranslation()
  return (
    <ActionCard
      icon={icon}
      title={t('This payout has arrived')}
      body={t('This payout should have reached your bank account by now.')}
    />
  )
}

Succeeded.propTypes = {}

export default Succeeded
